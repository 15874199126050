import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//component

const Hero = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <div id="What is Up 4 Change?">
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: `url(/images/ChangeAbout.png)`,
          backgroundSize: "40%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: theme.spacing(6, 2, 2, 2),
        }}
      >
        <Typography
          align="center"
          variant="h4"
          color={"text.light"}
          fontWeight={"bold"}
        >
          What is Up 4 Change?
        </Typography>

        <Box display={"flex"} justifyContent={"center"} padding={2}>
          <Box
            sx={{
              border: `2px solid ${theme.palette.secondary.dark}`,
              width: "20%",
            }}
          ></Box>
        </Box>
        <Container maxWidth={"md"}>
          <Typography
            fontWeight={500}
            color={"text.light"}
            sx={{
              paddingBottom: 5,
            }}
            gutterBottom
          >
            Up 4 Change is a 'social roundup' donation platform that assist
            NFP's to immediately connect with donors to help increase the length
            of the relationship by keeping them engaged and connected.
          </Typography>
          <Typography
            fontWeight={500}
            color={"text.light"}
            sx={{
              paddingBottom: 5,
            }}
            gutterBottom
          >
            Donors can seamlessly donate their small change by connecting their
            every day transaction account and simply going about their every day
            life! All the small change from your transactions is automatically
            rounded up, collated and then donated to the charity to YOUR choice!
          </Typography>
          <Typography
            sx={{
              paddingBottom: 5,
            }}
            fontWeight={"bold"}
            color={"text.light"}
            gutterBottom
          >
            We are a social impact company- we operate for profit but we
            integrate our values of transparency, togetherness &amp; generosity
            in all we do.
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Hero;
