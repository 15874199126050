// export const domain =
//     process.env.NODE_ENV === `development`
//         ? `http://localhost:8000/v1`
//         : `https://upforchange.herokuapp.com/v1`

export const domain =
  process.env.REACT_APP_API_URL || "https://upforchange.herokuapp.com/v1";

export const API_URLS = {
  login: `${domain}/auth/login`,
  getAllCauses: `${domain}/organisation/getCausesByOrganisation`,
  getOrgDetails: `${domain}/organisation/getOrganisationDetail`,
  createCause: `${domain}/organisation/createCause`,
  updateCause: `${domain}/organisation/updateCause`,
  deleteCause: `${domain}/organisation/deleteCause`,
  getCause: `${domain}/organisation/getCause`,
  createFeed: `${domain}/organisation/createFeed`,
  updateFeed: `${domain}/organisation/updateFeed`,
  deleteFeed: `${domain}/organisation/deleteFeed`,
  getAllFeeds: `${domain}/organisation/organisationFeeds`,
  addOrgAccount: `${domain}/bank/addOrgAccount`,
  getOrgAccount: `${domain}/bank/getOrgAccount`,
  updateOrgAccount: `${domain}/bank/updateOrgAccount`,
  changePassword: `${domain}/auth/org-change-password`,
  updateOrgProfilePic: `${domain}/organisation/profile/update-pic`,
  updateOrgPicBase64: `${domain}/organisation/profile/update-pic-base64`,
  orgTransactions: `${domain}/transactions/org`,
  updateOrg: `${domain}/organisation/update`,
  interactionOnFeed: `${domain}/interaction/create`,
  getAllInteractionsByFeed: `${domain}/interaction`,
};
