import React, { Fragment, useState, useContext } from "react";

//package import

import PropTypes from "prop-types";
import {
  IconButton,
  useTheme,
  Box,
  Hidden,
  MenuItem,
  Menu,
  Button,
  Link,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// import {Menu as MenuIcon} from '@mui/icons-material'

//user import

import Logo from "app/svg/user/Logo";
import DropArrow from "app/svg/user/DropArrow";
import {
  nfpsRoutes,
  changeRoutes,
  aboutRoutes,
} from "app/components/user/Layout/navigation";
import { userRoutes } from "app/Services/user/routes/APP";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { Menu as MenuIcon, Star as StarIcon } from "@mui/icons-material";

import LogoutIcon from "app/svg/user/Logout";
import SettingsIcon from "app/svg/user/SettingsIcon";
import { context } from "app/context";
// import { context } from "../../context";

//component

const Topbar = ({
  handleClose,
  handleClose1,
  handleClose2,

  handleToggle,
  handleToggle1,
  handleToggle2,

  open,
  open1,
  open2,

  anchorRef,
  anchorRef1,
  anchorRef2,

  onSidebarOpen,
}) => {
  //hooks

  const theme = useTheme();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { user } = useContext(context);
  const { profile_pic } = user || {};

  const isLogged = isLoggedIn();
  const [anchorEl, setAnchorEl] = useState(null);

  //constants

  const openMenu = Boolean(anchorEl);

  //open profile menu

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //close profile menu

  const menuCloseHandler = () => {
    setAnchorEl(null);
  };

  //

  const logoutHandler = () => {
    localStorage.clear();
    navigate(userRoutes?.root);
  };

  //render

  return (
    <Box
      display={"flex"}
      justifyContent={{
        md: "flex-start",
        sm: "space-between",
        xs: "space-between",
      }}
      alignItems={"center"}
      width={"100%"}
      height={100}
      sx={{ justifyContent: "space-between" }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={theme.zIndex.appBar + 1}
        sx={{
          height: 200,
          padding: 0,
          margin: 0,
          width: 300,
          marginLeft: -3,
          backgroundColor: theme.palette.common.black,
          borderEndEndRadius: "100%",
          borderBottomLeftRadius: "100%",
          // borderRadius: "100%",
        }}
      >
        <Box
          component="a"
          display={"block"}
          underline="none"
          href="/"
          zIndex={theme.zIndex.appBar + 1}
          height={"100%"}
          marginTop={5}
          width={150}
        >
          <Logo width={"100%"} height={"100%"} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        height={"100%"}
        justifyContent={"center"}
      >
        <Hidden mdDown>
          <Box
            sx={{
              height: "100%",
              backgroundColor:
                pathname === userRoutes?.feed
                  ? theme.palette.secondary.main
                  : "inherit",
              display: "flex",
              alignItems: "center",
              color:
                pathname === userRoutes?.feed
                  ? theme.palette.primary.light
                  : theme.palette.common.white,
            }}
          >
            <Box
              component={Link}
              marginX={1.6}
              sx={{
                color: "inherit",
                textDecoration: "none",
                fontWeight: 700,
              }}
              href={userRoutes?.feed}
            >
              FEED
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              backgroundColor:
                pathname === userRoutes?.discover
                  ? theme.palette.secondary.main
                  : "inherit",
              display: "flex",
              alignItems: "center",
              color:
                pathname === userRoutes?.discover
                  ? theme.palette.primary.light
                  : theme.palette.common.white,
            }}
          >
            <Box
              component={Link}
              marginX={1.6}
              sx={{
                color: "inherit",
                textDecoration: "none",
                fontWeight: 700,
              }}
              href={userRoutes?.discover}
            >
              DISCOVER
            </Box>
          </Box>

          <Box marginX={1.6}>
            <Button
              onClick={handleToggle1}
              sx={{
                fontWeight: 700,
                fontSize: "1rem",
                color: theme.palette.common.white,
                textDecoration: "none",
                outline: "none",
                padding: 0,
                "$:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.light,
                },
              }}
              endIcon={<DropArrow />}
            >
              Not for profit's
            </Button>

            <Menu
              anchorEl={anchorRef1}
              open={open1}
              onClose={handleClose1}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.common.black,
                },
              }}
            >
              {nfpsRoutes?.map((subItem, index) => (
                <Box
                  key={`${index}-sub-menu-${subItem?.id}`}
                  sx={{
                    backgroundColor: theme.palette.common.black,
                  }}
                >
                  <Link
                    sx={{
                      color: theme.palette.common.white,
                      textDecoration: "none",
                    }}
                    href={subItem?.href ? subItem?.href : "#"}
                  >
                    <MenuItem
                      onClick={handleClose1}
                      key={`${index}-${subItem?.id}-sub-menu-`}
                      sx={{
                        textTransform: "uppercase",
                        paddingY: 2,
                      }}
                    >
                      {subItem?.title}
                    </MenuItem>
                  </Link>
                  {nfpsRoutes.length - 1 !== index && (
                    <Divider color="#383838" />
                  )}
                </Box>
              ))}
            </Menu>
          </Box>
          <Box marginX={1.6}>
            <Button
              onClick={handleToggle}
              sx={{
                fontWeight: 700,
                fontSize: "1rem",
                color: theme.palette.common.white,
                textDecoration: "none",
                outline: "none",
                padding: 0,

                "$:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.light,
                },
              }}
              endIcon={<DropArrow />}
            >
              CHANGEMAKERS
            </Button>

            <Menu
              anchorEl={anchorRef}
              open={open}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.common.black,
                },
              }}
            >
              {changeRoutes?.map((subItem, index) => (
                <Box
                  key={`${index}-sub-menu-${subItem?.id}`}
                  sx={{
                    backgroundColor: theme.palette.common.black,
                  }}
                >
                  <Link
                    sx={{
                      color: theme.palette.common.white,
                      textDecoration: "none",
                    }}
                    href={subItem?.href ? subItem?.href : "#"}
                  >
                    <MenuItem
                      onClick={handleClose}
                      key={`${index}-${subItem?.id}-sub-menu`}
                      sx={{
                        textTransform: "uppercase",
                        paddingY: 2,
                      }}
                    >
                      {subItem?.title}
                    </MenuItem>
                  </Link>
                  {changeRoutes?.length - 1 !== index && (
                    <Divider color="#383838" />
                  )}
                </Box>
              ))}
            </Menu>
          </Box>
          <Box marginX={1.6}>
            <Button
              onClick={handleToggle2}
              sx={{
                fontWeight: 700,
                fontSize: "1rem",
                color: theme.palette.common.white,
                textDecoration: "none",
                outline: "none",
                padding: 0,
                "$:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.light,
                },
              }}
              endIcon={<DropArrow />}
            >
              ABOUT
            </Button>

            <Menu
              anchorEl={anchorRef2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.common.black,
                },
              }}
            >
              {aboutRoutes?.map((item, index) => (
                <Box
                  key={`${index}-sub-menu-${item?.id}`}
                  sx={{
                    backgroundColor: theme.palette.common.black,
                  }}
                >
                  <Link
                    sx={{
                      color: theme.palette.common.white,
                      textDecoration: "none",
                    }}
                    href={item?.href}
                  >
                    <MenuItem
                      onClick={handleClose2}
                      key={`${index}-${item?.id}-sub-menu`}
                      sx={{
                        textTransform: "uppercase",
                        paddingY: 2,
                      }}
                    >
                      {item?.title}
                    </MenuItem>
                  </Link>
                  {aboutRoutes?.length - 1 !== index && (
                    <Divider color="#383838" />
                  )}
                </Box>
              ))}
            </Menu>
          </Box>

          <Box
            component={Link}
            marginX={1.6}
            sx={{
              color: theme.palette.common.white,
              textDecoration: "none",
              fontWeight: 700,
            }}
            href={userRoutes?.contactUs}
          >
            GET IN TOUCH
          </Box>
          {!isLogged ? (
            <Fragment>
              <Box marginX={1.6} color={"#ff9776"}>
                <Button
                  sx={{ padding: 0 }}
                  variant="text"
                  href={userRoutes?.logIn}
                >
                  LOG IN
                </Button>
              </Box>
              <Box marginX={1.6}>
                <Button variant="contained" href={userRoutes?.signUp}>
                  SIGN UP{" "}
                </Button>
              </Box>
            </Fragment>
          ) : (
            <>
              {/* <Box margin={2}>
                                <Button
                                    variant='contained'
                                    href={userRoutes?.user}
                                >
                                    LOGGED IN PORTAL
                                </Button>
                            </Box> */}
            </>
          )}
        </Hidden>
        <Hidden mdUp>
          <Box
            marginRight={{ xs: 1, sm: 2 }}
            color={theme.palette.common.white}
          >
            <IconButton
              onClick={onSidebarOpen}
              aria-label="Menu"
              color="inherit"
            >
              <MenuIcon color="inherit" />
            </IconButton>
          </Box>
        </Hidden>
      </Box>
      {isLogged && (
        <Box margin={2} color={theme.palette.primary.light}>
          <IconButton onClick={handleClick} color="inherit">
            {profile_pic ? (
              <Avatar src={profile_pic} alt={"profile"}></Avatar>
            ) : (
              <Avatar></Avatar>
            )}
          </IconButton>

          <Menu anchorEl={anchorEl} open={openMenu} onClose={menuCloseHandler}>
            <MenuItem onClick={menuCloseHandler}>
              <Box
                padding={1}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography fontWeight={"bold"}>My Account</Typography>
                <Box marginLeft={3}>
                  {profile_pic ? (
                    <Avatar src={profile_pic} alt={"profile"}></Avatar>
                  ) : (
                    <Avatar />
                  )}
                </Box>
              </Box>
            </MenuItem>
            <Divider />

            <Box padding={1} display={"flex"}>
              <Box width={20}>
                <SettingsIcon />
              </Box>
              <Typography marginLeft={2} fontWeight={"bold"}>
                Settings
              </Typography>
            </Box>
            <MenuItem
              onClick={() => {
                navigate(userRoutes?.user + userRoutes?.profile);
                menuCloseHandler();
              }}
              sx={{
                pl: 5.5,
              }}
              color={theme.palette.grey.A100}
            >
              My Profile
            </MenuItem>
            <Box padding={1} display={"flex"}>
              <Box width={20}>
                <img src="/images/Donation.png" alt="donation" />
              </Box>
              <Typography marginLeft={2} fontWeight={"bold"}>
                My Donations
              </Typography>
            </Box>
            <MenuItem
              onClick={() => {
                navigate(userRoutes?.user + userRoutes?.currentround);
                menuCloseHandler();
              }}
              sx={{
                pl: 5.5,
              }}
              color={theme.palette.grey.A100}
            >
              My Roundups
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(userRoutes?.user + userRoutes?.subscriptions);
                menuCloseHandler();
              }}
              sx={{
                pl: 5.5,
              }}
              color={theme.palette.grey.A100}
            >
              Reccurring donation subscriptions
            </MenuItem>

            <MenuItem
              onClick={() => {
                navigate(userRoutes?.user + userRoutes?.donationHistory);
                menuCloseHandler();
              }}
              sx={{
                pl: 5.5,
              }}
              color={theme.palette.grey.A100}
            >
              History
            </MenuItem>
            <MenuItem
              onClick={() => {
                menuCloseHandler();
                navigate(userRoutes?.user + userRoutes?.paymentInfo);
              }}
              sx={{
                pl: 5.5,
              }}
              color={theme.palette.grey.A100}
            >
              Payments info
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = userRoutes?.user + userRoutes?.cause;
                // navigate(userRoutes?.user + userRoutes?.cause)
                menuCloseHandler();
              }}
            >
              <Box padding={1} display={"flex"}>
                <Box width={20} color={theme.palette.secondary.main}>
                  <StarIcon color="inherit" />
                </Box>
                <Typography marginLeft={2} fontWeight={"bold"}>
                  My favourite causes
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={logoutHandler} color={theme.palette.grey[100]}>
              <Box padding={1} display={"flex"}>
                <Box width={20} color={theme.palette.secondary.main}>
                  <LogoutIcon color="inherit" />
                </Box>
                <Typography marginLeft={2} fontWeight={"bold"}>
                  Logout
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Topbar;
