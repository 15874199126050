import React, { Fragment } from "react";

//package import

import { Box, Container, Typography, useTheme, TextField, MenuItem,Button, } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

const NfpsSearch = () => {
  //hooks

  const theme = useTheme();

  //render
  const matches = useMediaQuery("(min-width:1025px)");

  return (
    <Fragment>
      <Box paddingY={3} bgcolor="black">
        <Typography
          color={"common.white"}
          align="center"
          fontWeight={"bold"}
          variant="h5"
        >
          Search below to find and register your NFP!
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            sx={{
              width: "20%",
              marginTop: 1,
              borderBottom: `2px solid ${theme.palette.common.white}`,
            }}
          ></Box>
        </Box>
        <Box paddingY={3}>
          <Container>
            <Box
              display={{ md: "flex", sm: "flex" }}
              justifyContent={{ sm: "center" }}
            >
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  // placeholder="KEYWORDS"
                  // sx={{
                  //   backgroundColor: theme.palette.common.white,
                  //   border: "none",
                  //   padding: 1,
                  // }}
                  // fullWidth
                  placeholder="KEYWORDS"
                  variant="standard" 
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: "19.8px 14px",
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                   
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                ></TextField>
              </Box>
              {matches && <img
                  src="/images/Line 74.svg"
                  style={{ backgroundColor:" white"}}
                />}
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  // placeholder="-CATEGORY-"
                  // sx={{
                  //   backgroundColor: theme.palette.common.white,
                  //   border: "none",
                  //   padding: 1,
                  // }}
                  // select
                  // SelectProps={{
                  //   displayEmpty: true,
                  //   MenuProps: {
                  //     color: "grey",
                  //   },
                  // }}
                  // value={""}
                  // fullWidth
                  placeholder="-CATEGORY-"
                  variant="standard" 
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: 1,
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                    padding: "19.8px 14px",
                  }}
                  select
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      color: "grey",
                    },
                  }}
                  value={""}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  <MenuItem value={""} disabled>
                    -CATEGORY-
                  </MenuItem>
                </TextField>
              </Box>
              {matches && <img
                  src="/images/Line 74.svg"
                  style={{ backgroundColor:" white"}}
                />}
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  // placeholder="-LOCATION-"
                  // sx={{
                  //   backgroundColor: theme.palette.common.white,
                  //   border: "none",
                  //   padding: 1,
                  // }}
                  // select
                  // SelectProps={{
                  //   displayEmpty: true,
                  // }}
                  // value={""}
                  // fullWidth
                  placeholder="-LOCATION-"
                  variant="standard" 
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: 1,
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                    padding: "19.8px 14px",
                  }}
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  value={""}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value={""} disabled>
                    -LOCATION-
                  </MenuItem>
                </TextField>
              </Box>
              <Box width={{ md: "10%", sm: "50%", xs: "100%" }}>
                <Box color={theme.palette.common.white} width={"100%"}>
                  <Button
                    bgcolor={theme.palette.primary.main}
                    variant={"contained"}
                    sx={{
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <SearchIcon color="inherit" width={"100%"} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Fragment>
  );
};

export default NfpsSearch;
