import React from 'react'

//package import

import {Paper, Typography, Box} from '@mui/material'
import {Link, useNavigate} from 'react-router-dom'

//user import

const CardItem = ({item}) => {
    const navigate = useNavigate()
    return (
        <Link to={`/discover?category=${item?.url}`}>
            <Paper
                sx={{
                    borderRadius: 4,
                    padding: 2,
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexDirection: 'column',
                }}
            >
                <Box display={'flex'} justifyContent={'center'}>
                    <Box
                        width={130}
                        height={130}
                        sx={{
                            backgroundImage: `url(${item?.bgImage})`,
                            backgroundSize: 'cover',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={item?.image}
                            alt={item?.text}
                            width={100}
                            height={100}
                        />
                    </Box>
                </Box>
                <Typography color={'common.black'} align='center'>
                    {item?.text}
                </Typography>
            </Paper>
        </Link>
    )
}

export default CardItem
