import React, { useState } from "react";

//package import

import {
  Box,
  Grid,
  Typography,
  useTheme,
  LinearProgress,
  Container,
  Button,
  Alert,
  Snackbar,
  Modal,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

//user import

import MoreIcon from "app/svg/user/orgProfile/MoreIcon";
import LoveIcon from "app/svg/user/orgProfile/LoveIcon";
import Goal1Icon from "app/svg/user/orgProfile/Goal1";
import VerifiedIcon from "app/svg/user/orgProfile/VerifiedIcon";
import SupportIcon from "app/svg/user/orgProfile/Support";
// import { userRoutes } from "../../services/routes/APP";
// import { StorageKeys } from "../../services/Storage";
import SupportModal from "../SupportModal";
// import { isLoggedIn } from "../../services/routes/APP/PrivateRoute";
import { useEffect } from "react";
import { orgTagsEnumsArr, CATEGORIES_OPTIONS } from "app/common/user/Enums";
import { userRoutes } from "app/Services/user/routes/APP";
import { StorageKeys } from "app/Services/user/Storage";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";

//component

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const OrgCard = ({ item, org }) => {
  //hooks
  const theme = useTheme();
  const navigate = useNavigate();

  console.log("====================================");
  console.log({ item, org });
  console.log("====================================");

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [img, setImg] = useState("");
  const [video, setVideo] = useState("");

  //support handler

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleOpen1 = (img) => {
    if (img) {
      if (open1 === false) {
        setOpen1(true);
      }
    } else {
      return;
    }
  };
  const handleOpen2 = (img) => {
    if (img) {
      if (open2 === false) {
        setOpen2(!open2);
      }
    } else {
      return;
    }
  };
  const handleOpen3 = (img) => {
    if (img) {
      if (open3 === false) {
        setOpen3(!open3);
      }
    } else {
      return;
    }
  };

  const abc = (category) => {
    console.log({ category });
    const ct = orgTagsEnumsArr.find((item) => item.value === category);

    console.log({ ct });
    return ct?.name || "N/A";
  };

  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(!open2);
  const handleClose3 = () => setOpen3(false);

  const supportHandler = () => {
    if (isLoggedIn()) {
      setOpen(true);
    } else {
      setAlertOpen(true);
      setAlertMsg("Please create a account or sign in");
      setAlertIcon("error");
    }
  };

  //modal close handler

  const modalCloseHandler = () => {
    setOpen(false);
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //learn more handler

  const learnMoreHandler = () => {
    sessionStorage.setItem(
      StorageKeys?.organisation,
      JSON.stringify({
        name: org?.name,
        image: org?.organisation_image[0],
        cause: org?.cause,
      })
    );
    navigate(userRoutes?.user + userRoutes?.orgCause + "/" + item?.id);
  };

  const handleOpen = () => setOpen(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const executeOnClick = () => {
    setIsExpanded(true);
  };

  //render
  const currentImg = org?.feeds?.length;

  useEffect(() => {
    const video_img_Formats = ["mp4"];

    let apiArray =
      org?.causes?.micro_goal1?.image &&
      org?.causes.micro_goal1.image.split(".");

    let source =
      apiArray && apiArray.length > 0 && apiArray[apiArray.length - 1];

    let img, video;

    if (video_img_Formats.includes(source)) {
      video = org?.causes?.micro_goal1?.image;
    } else {
      img = org?.causes?.micro_goal1?.image;
    }

    const video_img_Formats1 = ["mp4"];
    let apiArray1 =
      org?.causes?.micro_goal2?.image &&
      org.mcauses.micro_goal2.image.split(".");

    let source1 =
      apiArray1 && apiArray1.length > 0 && apiArray1[apiArray1.length - 1];

    let img1;
    let video1;
    if (video_img_Formats1.includes(source1)) {
      video1 = org?.causes?.micro_goal2?.image;
    } else {
      img1 = org?.causes?.micro_goal2?.image;
    }

    const video_img_Formats2 = ["mp4"];
    let apiArray2 =
      org?.causes?.micro_goal3?.image &&
      org.mcauses.micro_goal3.image.split(".");

    let source2 =
      apiArray2 && apiArray2.length > 0 && apiArray2[apiArray2.length - 1];

    let img2;
    let video2;
    if (video_img_Formats2.includes(source2)) {
      video2 = org?.causes?.micro_goal3?.image;
    } else {
      img2 = org?.causes?.micro_goal3?.image;
    }
  }, [org]);
  return (
    <Box
      bgcolor={theme.palette.common.white}
      // borderLeft={`2px solid ${theme.palette.primary.main}`}
    >
      <Grid container>
        {/* <Grid item xs={12} sm={12} md={6}>
          <img
            src={item?.cause_image}
            alt="Cause"
           width={"100%"}
           height={"100%"}
          ></img>
        </Grid> */}
        <Grid
          // className={classes.backgroundmobile}
          item
          md={6}
          lg={6}
          style={{
            backgroundImage: `url(${item?.cause_image})`,
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <Box></Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box padding={2}>
            {/* <Box display={"flex"} justifyContent={"flex-end"}>
              <MoreIcon />
            </Box>  */}
            <Box display={"flex"}>
              <Box>
                {/* {item?.organisation_tag === 'authorized' && (
                                    <VerifiedIcon
                                        width={'15px'}
                                        height={'15px'}
                                    />
                                )}
                                {item?.organisation_tag === 'ACN_verified' && (
                                    <VerifiedIcon
                                        color={theme.palette.secondary.main}
                                        width={'15px'}
                                        height={'15px'}
                                    />
                                )}
                                {item?.organisation_tag === 'DGR_verified' && (
                                    <VerifiedIcon
                                        color={theme.palette.primary.dark}
                                        width={'15px'}
                                        height={'15px'}
                                    />
                                )} */}
                {(item?.organisation_tag === "authorized" ||
                  item?.organisation_tag === "community_org") && (
                  <VerifiedIcon width={"15px"} height={"15px"} />
                )}
                {(item?.organisation_tag === "ACN_verified" ||
                  item?.organisation_tag === "ACNC_verified") && (
                  <VerifiedIcon
                    color={theme.palette.secondary.main}
                    width={"15px"}
                    height={"15px"}
                  />
                )}
                {(item?.organisation_tag === "DGR_verified" ||
                  item?.organisation_tag === "DRG_verified") && (
                  <VerifiedIcon
                    color={theme.palette.primary.dark}
                    width={"15px"}
                    height={"15px"}
                  />
                )}
              </Box>
              <Box>
                <Typography
                  fontWeight={"bold"}
                  paddingLeft={1}
                  paddingTop={0.6}
                  sx={{
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "10px",
                  }}
                >
                  {abc(item?.organisation_tag)}
                  {/* {item?.organisation_tag} */}
                </Typography>
              </Box>
            </Box>
            <Box style={{ paddingTop: "10px" }}>
              <Typography
                color={"text.red"}
                fontStyle={"italic"}
                sx={{
                  fontFamily: "Circular Std",

                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "14px",
                }}
              >
                {/* Category - {org?.category} */}
                Category - {CATEGORIES_OPTIONS[org?.category]}
                {/* <span style={{paddingLeft: '270px'}}>
                                    <LoveIcon />
                                </span> */}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              paddingY={1}
              //   alignItems={"center"}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "32px",
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  color={"text.liteGray"}
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "justify",
                  }}
                >
                  {/* {item?.description} */}
                  <ShowMoreText
                    lines={3}
                    more="see more"
                    less="show less"
                    onClick={executeOnClick}
                    expanded={false}
                    width={530}
                    truncatedEndingComponent={"... "}
                  >
                    {item?.description}
                  </ShowMoreText>
                </Typography>
              </Box>
            </Box>
            <Box>
              <LinearProgress
                variant="determinate"
                sx={{
                  height: 20,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor:
                      theme.palette.grey[
                        theme.palette.mode === "light" ? 200 : 800
                      ],
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.primary.main
                        : theme.palette.primary.main,
                  },
                }}
                value={25}
              />
            </Box>
            <Box display={"flex"} paddingY={1}>
              <Box width={"25%"} display={"flex"} justifyContent={"flex-end"}>
                <Box>
                  <Typography
                    align="center"
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "11px",
                    }}
                  >
                    Raised
                  </Typography>
                  <Typography
                    align="center"
                    color={theme.palette.primary.main}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "17px",
                      lineHeight: "15px",
                    }}
                  >
                    $2,533
                  </Typography>
                </Box>
              </Box>
              <Box width={"75%"} display={"flex"} justifyContent={"flex-end"}>
                <Box>
                  <Typography
                    align="right"
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "11px",
                    }}
                  >
                    Goal
                  </Typography>
                  <Typography
                    align="right"
                    color={theme.palette.primary.main}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "17px",
                      lineHeight: "15px",
                    }}
                  >
                    ${item?.fund_raising_goal}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box bgcolor={theme.palette.secondary.main} paddingY={1}>
            <Container>
              <Typography
                align="center"
                fontWeight={"bold"}
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Circular Std",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "17px",
                  lineHeight: "19px",
                }}
              >
                OUR GOALS
              </Typography>
              <Box paddingY={1}>
                <Grid container>
                  {/* first goal */}
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Box
                        onClick={() => handleOpen1(item?.micro_goal1?.image)}
                        width={45}
                        borderRadius={"100%"}
                      >
                        {item?.micro_goal1?.icon ? (
                          <img
                            src={item?.micro_goal1?.icon}
                            alt={item?.micro_goal1?.value}
                            width={"100%"}
                          />
                        ) : (
                          <Goal1Icon />
                        )}
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "18px",
                          lineHeight: "12px",
                        }}
                      >
                        {item?.micro_goal1?.value} {item?.micro_goal1?.name}
                      </Typography>
                      <Modal
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <img
                              src="/images/closepopup.svg"
                              width={"12px"}
                              onClick={handleClose1}
                              alt={"title"}
                            />
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {item?.micro_goal1?.icon ? (
                                          <img
                                            src={item?.micro_goal1?.icon}
                                            alt={item?.micro_goal1?.value}
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {item?.micro_goal1?.value}
                                        {item?.micro_goal1?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {img && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={img} type="video/mp4" />
                            </video>
                          )}
                          {img && (
                            <img
                              src={img}
                              alt="img"
                              width="100%"
                              height="70%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                    </Box>
                  </Grid>
                  {/* second goal */}
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Box
                        width={45}
                        onClick={() => handleOpen1(item?.micro_goal2?.image)}
                        borderRadius={"100%"}
                      >
                        {item?.micro_goal2?.icon ? (
                          <img
                            src={item?.micro_goal2?.icon}
                            alt={item?.micro_goal2?.value}
                            width={"100%"}
                          />
                        ) : (
                          <Goal1Icon />
                        )}
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "18px",
                          lineHeight: "12px",
                        }}
                      >
                        {item?.micro_goal2?.value} {item?.micro_goal2?.name}
                      </Typography>
                      <Modal
                        open={open2}
                        onClose={handleClose2}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <img
                              src="/images/closepopup.svg"
                              width={"12px"}
                              onClick={handleClose2}
                              alt={"title"}
                            />
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {item?.micro_goal2?.icon ? (
                                          <img
                                            src={item?.micro_goal2?.icon}
                                            alt={item?.micro_goal2?.value}
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {item?.micro_goal2?.value}
                                        {item?.micro_goal2?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {/* {video && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                          )} */}
                          {img && (
                            <img
                              src={img}
                              alt="img"
                              width="100%"
                              height="70%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                    </Box>
                  </Grid>
                  {/* third goal */}
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Box
                        width={45}
                        onClick={() => handleOpen1(item?.micro_goal3?.image)}
                        borderRadius={"100%"}
                      >
                        {item?.micro_goal3?.icon ? (
                          <img
                            src={item?.micro_goal3?.icon}
                            alt={item?.micro_goal3?.value}
                            width={"100%"}
                          />
                        ) : (
                          <Goal1Icon />
                        )}
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "18px",
                          lineHeight: "12px",
                        }}
                      >
                        {item?.micro_goal3?.value} {item?.micro_goal3?.name}
                      </Typography>
                      <Modal
                        open={open3}
                        onClose={handleClose3}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <img
                              src="/images/closepopup.svg"
                              width={"12px"}
                              onClick={handleClose3}
                              alt={"title"}
                            />
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {item?.micro_goal3?.icon ? (
                                          <img
                                            src={item?.micro_goal3?.icon}
                                            alt={item?.micro_goal3?.value}
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {item?.micro_goal3?.value}
                                        {item?.micro_goal3?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {/* {video && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                          )} */}
                          {img && (
                            <img
                              src={img}
                              alt="img"
                              width="100%"
                              height="70%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
          <Box paddingY={2}>
            {/* <Container> */}
            <Grid container justifyContent={"center"}>
              {/* <Grid item xs={6} sm={6} md={6}> */}
              {/* <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      {item?.organisation_tag === 'authorized' && <VerifiedIcon /> } 
                      {item?.organisation_tag === 'ACN_verified' && <VerifiedIcon color={theme.palette.secondary.main}/> } 
                      {item?.organisation_tag === 'DGR_verified' && <VerifiedIcon color={theme.palette.primary.dark}/> } 
                     
                    </Box>
                    <Typography fontWeight={"bold"} paddingLeft={1}>
                      {item?.organisation_tag}
                    </Typography>
                  </Box> */}
              {/* <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <VerifiedIcon color={theme.palette.secondary.main} />
                    </Box>
                    <Typography fontWeight={"bold"} paddingLeft={1}>
                      ACN Verified
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <VerifiedIcon color={theme.palette.primary.dark} />
                    </Box>
                    <Typography fontWeight={"bold"} paddingLeft={1}>
                      DGR Verified
                    </Typography>
                  </Box> */}
              {/* </Grid> */}
              {/* <Grid item xs={6} sm={6} md={6}> */}
              <Box paddingY={1}>
                <Button
                  variant="contained"
                  onClick={() => learnMoreHandler()}
                  fullWidth
                  sx={{
                    paddingX: { md: "50px", lg: "80px" },
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",

                    paddingBottom: "13px",
                  }}
                >
                  LEARN MORE
                </Button>
              </Box>
              &nbsp; &nbsp;
              <Box paddingY={1}>
                <Button
                  variant="contained"
                  startIcon={<SupportIcon />}
                  fullWidth
                  onClick={supportHandler}
                  sx={{
                    paddingX: { md: "50px", lg: "80px" },
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  SUPPORT
                </Button>
              </Box>
              {/* </Grid> */}
            </Grid>
            {/* </Container> */}
          </Box>
        </Grid>
      </Grid>
      {open && (
        <SupportModal
          closeHandler={modalCloseHandler}
          open={open}
          causeId={item?.id}
          organisationDoc={org}
        />
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrgCard;
