import React from 'react'
import {
    Box,
    TextField,
    Typography,
    Button,
    Link,
    Divider,
    useTheme,
} from '@mui/material'

function EmailScreen({handleChange, values, btnState, handleSubmit}) {
    const theme = useTheme()

    return (
        <div style={{textAlign: 'center', padding: '30px'}}>
            <Box marginBottom={3}>
                <h3 style={{margin: '0'}}>FORGOT PASSWORD</h3>
                <p style={{margin: '0', fontSize: '14px'}}>
                    Reset password in two quick steps
                </p>
            </Box>
            <Box marginBottom={3} sx={{textAlign: 'left'}}>
                <Typography
                    color={'common.black'}
                    fontWeight={'bold'}
                    gutterBottom
                >
                    Enter Email
                </Typography>
                <TextField
                    fullWidth
                    // variant={'outlined'}
                    // color='info'
                    placeholder='Enter Your Email'
                    name='email'
                    value={values?.email}
                    onChange={handleChange}
                ></TextField>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Button
                    variant='contained'
                    sx={{
                        boxShadow: theme.shadows[5],
                        width: '45%',
                    }}
                    disabled={btnState}
                    onClick={handleSubmit}
                    // type={'submit'}
                >
                    {btnState ? 'Processing' : 'Reset Password'}
                </Button>
            </Box>
        </div>
    )
}

export default EmailScreen
