import * as Yup from 'yup'

import {variables} from '../variables'

const {required, isAlphabets, isCard} = variables

export const addvalidation = Yup.object({
    name_as_per_card: Yup.string()
        .required(required)
        .matches(isAlphabets, {message: 'pease enter valid name'}),
    card_number: Yup.string()
        .required(required)
        .matches(isCard, 'please enter a valid card number'),
    expiry_month: Yup.string().required(required),
    expiry_year: Yup.string().required(required),
    cvv: Yup.string().min(3).max(4).required(required),
    billing_address: Yup.string().required(required),
})
export const addBankValidation = Yup.object({
    account_name: Yup.string()
        .required(required)
        .matches(isAlphabets, {message: 'pease enter valid name'}),
    account_number: Yup.string().required(required),
    // .matches(isCard, 'please enter a valid account number
    bsb_number: Yup.number().required(required),
})
