import React from "react";

const Round2 = ({ width, height }) => {
  return (
    <svg
      width={width ? width :"76"}
      height={height ? height :"68"}
      viewBox="0 0 76 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.4088 6.62629C66.6739 2.35321 61.4918 0 55.8174 0C47.8376 0 42.7845 4.80485 39.9514 8.83575C39.2165 9.88173 38.5917 10.9305 38.0663 11.9175C37.5408 10.9305 36.916 9.88173 36.1811 8.83575C33.348 4.80485 28.2949 0 20.3152 0C14.6407 0 9.45861 2.35337 5.7226 6.62645C2.15936 10.7027 0.197266 16.1619 0.197266 21.9985C0.197266 28.3517 2.65757 34.2608 7.94029 40.5945C12.6621 46.2553 19.4537 52.0903 27.3198 58.8471C30.2499 61.365 33.2817 63.9688 36.5077 66.8149L36.6048 66.9004C37.0237 67.2697 37.5444 67.4541 38.0663 67.4541C38.5881 67.4541 39.11 67.2695 39.5278 66.9004L39.6248 66.8149C42.8508 63.9688 45.8827 61.3652 48.814 58.8469C56.6789 52.0906 63.4704 46.2556 68.1922 40.5945C73.475 34.2607 75.9353 28.3517 75.9353 21.9985C75.9353 16.1619 73.9732 10.7027 70.4088 6.62629Z"
        fill="#FF3E3E"
      />
    </svg>
  );
};

export default Round2;
