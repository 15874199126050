import * as Yup from 'yup'

import {variables} from '../variables'

const {
    required,
    isAlphabet,
    alphabetsAllow,
    bankAccountRegex,
    isBankAccount,
    isBsbNumber,
} = variables

export const addValidation = Yup.object({
    account_name: Yup.string()
        .required(required)
        .matches(isAlphabet, {message: alphabetsAllow}),
    bank: Yup.string()
        .required(required)
        .matches(isAlphabet, {message: alphabetsAllow}),
    bsb_number: Yup.string().required(required).max(6, isBsbNumber),
    account_number: Yup.string()
        .required(required)
        .max(15)
        .matches(bankAccountRegex, {message: isBankAccount}),
    authorised_person_contact_name: Yup.string()
        .required(required)
        .matches(isAlphabet, {message: alphabetsAllow}),
})
