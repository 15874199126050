import React, { Fragment } from "react";

import Hero from "./Hero";
import YourSelf from "./YourSelf";
import Gift from "./Gift";
import GiftDiff from "./GiftDiff";

//component

const Features = () => {
  return (
    <Fragment>
      <Hero />
      <YourSelf />
      <Gift />
      <GiftDiff />
    </Fragment>
  );
};

export default Features;
