import React from "react";

//package import

import { Box, Grid, Typography, useTheme } from "@mui/material";

//user import

import { OurMissionIcon } from "app/svg/user/AboutUs";

//component

const Mission = () => {
  //hooks

  const theme = useTheme();

  //render
  return (
    <div id="OUR MISSION">
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: `url(/images/MissionEllipse.png)`,
          backgroundSize: "10%",
          backgroundPosition: "left top",
          backgroundRepeat: "no-repeat",
          padding: theme.spacing(6, 2, 2, 2),
        }}
      >
        <Typography
          align="center"
          variant="h3"
          color={"primary.light"}
          fontWeight={"bold"}
          sx={{
            textTransform: "uppercase",
          }}
        >
          Our mission
        </Typography>

        <Box display={"flex"} justifyContent={"center"} padding={2}>
          <Box
            sx={{
              border: `2px solid ${theme.palette.primary.light}`,
              width: "15%",
            }}
          ></Box>
        </Box>
        <Grid container>
          <Grid item xs={1} sm={1} md={2} />
          <Grid item xs={10} sm={10} md={8}>
            <Box marginBottom={4}>
              <Typography color={"primary.light"} gutterBottom>
                It's our mission to transform pocket change into a big change
                through an engaging digital experience. We connect donations
                directly to impact, breaking down barriers and building up
                supportive communities.
              </Typography>
            </Box>
            <Box>
              <Typography color={"primary.light"} gutterBottom>
                We put love and transparency back into donating, encouraging
                people to give more without asking for more. And the best part,
                you get a say in where your funds are directed!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box padding={2}>
              <OurMissionIcon />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Mission;
