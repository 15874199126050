import React from "react";

const VerifiedIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "36"}
      height={height ? height : "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18.3808"
        cy="18.3809"
        r="17.4241"
        fill={color ? color : "#161F3D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9994 12.8359C28.45 12.3967 28.45 11.6828 27.9994 11.2436C27.5499 10.8034 26.8204 10.8034 26.3698 11.2436L14.2072 23.1279L10.3915 19.4007C9.94207 18.9604 9.21143 18.9604 8.76199 19.4007C8.31139 19.841 8.31139 20.5537 8.76199 20.9929L13.3348 25.4621C13.3532 25.4813 13.3705 25.5004 13.3901 25.5184C13.6148 25.7392 13.9099 25.8484 14.2049 25.8484C14.2602 25.8484 14.3155 25.845 14.3708 25.8371C14.6094 25.8045 14.8399 25.6986 15.0231 25.5184C15.0427 25.5004 15.0611 25.4813 15.0784 25.461L27.9994 12.8359Z"
        fill="white"
      />
    </svg>
  );
};

export default VerifiedIcon;
