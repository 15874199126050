import { ToastContainer } from "react-toastify";
import MianRoutes from "./routes";
import ThemeProvider from "app/theme";
import { context } from "app/context";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { API_URLS } from "./Services/user/routes/API";
import { StorageKeys } from "./Services/user/Storage";
import { decodeToken } from "react-jwt";
import { simpleGet } from "./Services/user/API";
import { useNavigate } from "react-router-dom";

// ORG import
import { ORGANIZATION_APP_URLS } from "./Services/organization/Routes/APP";
import { OrgStorageKeys } from "./Services/organization/StorageKeys";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function App() {
  const navigate = useNavigate();
  const ContextProvider = context.Provider;
  const [orgDetails, setOrgDetails] = useState(null);

  // user side
  const [userDetail, setUserDetail] = useState(null);
  const [isRoundup, setIsRoundup] = useState(null);
  const [alertIcon, setAlertIcon] = useState("error");
  const [loginModal, setLoginModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  // set the user contex ======

  const token = localStorage.getItem(StorageKeys?.token);
  const decode = decodeToken(token);
  // const { sub: id } = decode;
  const sub = decode?.sub;
  const id = sub;

  //states

  // const [open, setOpen] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  // const [alertOpen, setAlertOpen] = useState(false);

  //get user details

  const getUserDetails = useCallback(
    (id) => {
      // setOpen(true);

      simpleGet(`${API_URLS?.getUserDetail}/${id}`)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.user?.role === "user") {
              setUserDetail(res?.data?.user);
              setIsRoundup(res?.data?.isRoundup);
              // setOpen(false);
            } else {
              localStorage.clear();
            }
          } else {
            // setOpen(false);
            // setAlertOpen(true);
            // setAlertMsg(res?.data?.message);
            // setAlertIcon("warning");
            localStorage.clear();
          }
        })
        .catch((err) => {
          const { response } = err;
          // setOpen(false);
          localStorage.clear();
          // setAlertOpen(true);
          // setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    },
    [setUserDetail]
  );

  //ger decode token

  const getDecodedToken = useCallback(() => {
    const user_info = JSON.parse(localStorage.getItem(StorageKeys?.user_info));
    if (id && user_info?.role === "user") {
      getUserDetails(id);
    }
  }, [id, getUserDetails]);

  useEffect(() => {
    id && getDecodedToken();
  }, [id, getDecodedToken]);

  //render
  // End set the user contex ======

  //  set the ORG contex ======

  const getOrgDetails = (id) => {
    // setOpen(true);

    simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${id}`)
      .then((res) => {
        if (res?.status === 200) {
          setOrgDetails(res?.data?.organisationDetails);
          // setOpen(false);
          if (res?.data?.organisationDetails?.isBank === false) {
            navigate(
              ORGANIZATION_APP_URLS?.org + ORGANIZATION_APP_URLS?.payment
            );
          } else {
            navigate(ORGANIZATION_APP_URLS?.org);
          }
        } else {
          // setOpen(false);
          // setAlertMsg(res?.data?.message);
          // setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        // setOpen(false);

        // setAlertMsg(response?.data?.message);
        // setAlertIcon("error");
      });
  };

  //ger decode token

  const ORGgetDecodedToken = useCallback(() => {
    const token = localStorage.getItem(OrgStorageKeys?.token);
    const decode = decodeToken(token);
    getOrgDetails(decode?.sub);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(OrgStorageKeys?.token);
    const org_info = JSON.parse(localStorage.getItem(OrgStorageKeys?.org_info));
    if (token && org_info?.role === "organization") {
      ORGgetDecodedToken();
    }
  }, [ORGgetDecodedToken]);

  // End set the ORG contex ======

  return (
    <>
      <Elements stripe={stripePromise}>
        <ThemeProvider>
          <ContextProvider
            value={{
              orgDetails,
              setOrgDetails,
              user: userDetail,
              setUser: setUserDetail,
              isRoundup,
              loginModalState: {
                setLoginModal: setLoginModal,
                loginModal: loginModal,
              },
              clientSecretState: {
                setClientSecret: setClientSecret,
                clientSecret: clientSecret,
              },
            }}
          >
            <ToastContainer theme="colored" />
            <MianRoutes />
          </ContextProvider>
        </ThemeProvider>
      </Elements>
    </>
  );
}

export default App;
