import React, { useContext, useState, useCallback, useEffect } from "react";

//package import

import {
  Box,
  Grid,
  Typography,
  useTheme,
  LinearProgress,
  Container,
  Button,
  Alert,
  Snackbar,
  Avatar,
  Modal,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from "@mui/icons-material";
import { linearProgressClasses } from "@mui/material/LinearProgress";

//user import

import SupportModal from "../SupportModal";

import Goal1Icon from "app/svg/user/orgProfile/Goal1";
import LoveIcon from "app/svg/user/orgProfile/LoveIcon";
import SupportIcon from "app/svg/user/orgProfile/Support";
import { userRoutes } from "app/Services/user/routes/APP";
import VerifiedIcon from "app/svg/user/orgProfile/VerifiedIcon";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { headersPost, simpleGet } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
// import { context } from "../../context";
// import { simpleGet } from "../../services/API";
// import { checkItemInList } from "../../utils/functions";
// import { CATEGORIES_OPTIONS, orgTagsEnumsArr } from "../../common/Enums";
import { checkItemInList } from "app/utils/user/functions";
import { orgTagsEnumsArr, CATEGORIES_OPTIONS } from "app/common/user/Enums";
import { context } from "app/context";

//component

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const OrgCard = ({
  cause,
  removeUserFeedFav,
  cardId,
  bahir,
  handleLoginOpen,
  user2,
  setSupportState,
  isFromMyCause,
}) => {
  const { organisation, category, id } = cause || {};
  const { id: orgId } = organisation || {};

  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const isLogin = isLoggedIn();
  const { user, setUser } = useContext(context);
  let { _id: user_Id, id: userrId, favCauses } = user || user2 || {};
  const userId = user_Id ? user_Id : userrId;

  //states
  const [data, setData] = useState({ org: {}, cause: {} });
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [favorite, setFavorite] = useState(false);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const matches = useMediaQuery("(min-width:1199px)");
  const matches2 = useMediaQuery("(max-width:480px)");

  const handleOpen1 = (img) => {
    if (img) {
      if (open1 === false) {
        setOpen1(true);
      }
    } else {
      return;
    }
  };

  const handleOpen2 = (img) => {
    if (img) {
      if (open2 === false) {
        setOpen2(!open2);
      }
    } else {
      return;
    }
  };

  const handleOpen3 = (img) => {
    if (img) {
      if (open3 === false) {
        setOpen3(!open3);
      }
    } else {
      return;
    }
  };

  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(!open2);
  const handleClose3 = () => setOpen3(false);

  //support handler

  const supportHandler = () => {
    if (isLogin) {
      setSupportState && setSupportState({ causeName: cause?.title });
      setOpen(true);
    } else {
      handleLoginOpen({ cause: id, org: organisation });
      setSupportState({
        cause: id,
        org: organisation,
        causeName: cause?.title,
      });
    }
  };

  //modal close handler

  const modalCloseHandler = () => {
    setOpen(false);
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const [, setIsExpanded] = useState(false);

  const executeOnClick = () => {
    setIsExpanded(true);
  };

  const video_img_Formats = ["mp4"];
  let apiArray =
    cause?.micro_goal1?.image && cause.micro_goal1.image.split(".");

  let source = apiArray && apiArray.length > 0 && apiArray[apiArray.length - 1];

  let video;
  let img;
  if (video_img_Formats.includes(source)) {
    video = cause?.micro_goal1?.image;
  } else {
    img = cause?.micro_goal1?.image;
  }

  const video_img_Formats1 = ["mp4"];
  let apiArray1 =
    cause?.micro_goal2?.image && cause.micro_goal2.image.split(".");

  let source1 =
    apiArray1 && apiArray1.length > 0 && apiArray1[apiArray1.length - 1];

  let video1;
  let img1;
  if (video_img_Formats1.includes(source1)) {
    video1 = cause?.micro_goal2?.image;
  } else {
    img1 = cause?.micro_goal2?.image;
  }

  const video_img_Formats2 = ["mp4"];
  let apiArray2 =
    cause?.micro_goal3?.image && cause.micro_goal3.image.split(".");

  let source2 =
    apiArray2 && apiArray2.length > 0 && apiArray2[apiArray2.length - 1];

  let video2;
  let img2;
  if (video_img_Formats2.includes(source2)) {
    video2 = cause?.micro_goal3?.image;
  } else {
    img2 = cause?.micro_goal3?.image;
  }

  const addUserFeedFav = async () => {
    try {
      const data = {
        userId: userId,
        causeId: id,
        orgId: orgId,
      };

      await headersPost(API_URLS.toggleFavCause, data).then((res) => {
        setAlertOpen(true);
        setAlertMsg(res?.data?.message);
        setAlertIcon("success");
        const isFav = checkItemInList(id, res?.data?.user?.favCauses);
        setFavorite(isFav ? true : false);
        isFromMyCause && window.location.reload();
      });
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      setAlertOpen(true);
      setAlertMsg(message || "Error found");
      setAlertIcon("error");
    }
  };

  const favoriteHandler = () => {
    if (isLogin && id) {
      addUserFeedFav();
    } else {
      setAlertOpen(true);
      setAlertMsg("Please create a account or sign in");
      setAlertIcon("error");
    }
  };
  const getOrgDetail = useCallback((orgId) => {
    simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${orgId}`)
      .then((res) => {
        setLoader(false);
        if (res?.status === 200 && res?.data?.status) {
          setData((prev) => ({
            ...prev,
            org: res?.data?.organisationDetails,
          }));
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setLoader(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, []);

  const getCauseDetails = useCallback(() => {
    setLoader(true);
    simpleGet(`${API_URLS?.getOrgCause}?causeId=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          setData({ cause: { ...res?.data?.cause } });
          getOrgDetail(res?.data?.cause?.organisation);
        } else {
          setLoader(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setLoader(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [id, getOrgDetail]);

  useEffect(() => {
    id && getCauseDetails();
  }, [id, getCauseDetails]);

  const learnMoreSingleCard = () => {
    navigate(`${userRoutes?.orgCause}?cause=${cause?.id}&isLogin=false`);
  };

  const calculateGoalPercen = (fund_raising_goal, fund_raised) => {
    if (fund_raising_goal === fund_raised) {
      return 100;
    } else {
      let percen = (fund_raised / fund_raising_goal) * 100;
      if (percen > 100) {
        percen = 100;
        return percen;
      } else {
        return percen;
      }
    }
  };
  useEffect(() => {
    let fund_raised = cause?.fund_raised;
    let fund_raising_goal = cause?.fund_raising_goal;

    setCountOfProgress(calculateGoalPercen(fund_raising_goal, fund_raised));
  }, [cause]);

  // console.log({ countOfProgress });

  //render
  const abc = (category) => {
    const ct = orgTagsEnumsArr.find((item) => item.value === category);

    // console.log({ ct });
    return ct?.name || "N/A";
  };

  useEffect(() => {
    user2 && setUser(user2);
  }, [setUser, user2]);

  useEffect(() => {
    const isFav = checkItemInList(id, favCauses);
    setFavorite(isFav ? true : false);
  }, [user, user2, id, favCauses]);

  // console.log({ FAVINORG: favCauses, user });

  return (
    <>
      <div id={cardId}>
        <Box bgcolor={theme.palette.common.white}>
          <Grid container>
            {!matches && (
              <Box
                sx={{
                  marginTop: "-50px",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box display={"flex"} justifyContent={"center"} pb={1}>
                  {organisation?.organisation_image?.length > 0 ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      component="img"
                      width={{
                        xs: "100px",
                        sm: "100px",
                        md: "100px",
                        lg: "100px",
                      }}
                      height={{
                        xs: "100px",
                        sm: "100px",
                        md: "100px",
                        lg: "100px",
                      }}
                      borderRadius={"100%"}
                      border={"5px solid #ff9776"}
                      src={organisation?.organisation_image}
                      alt={organisation?.name}
                      onClick={() => {
                        navigate(`${userRoutes?.orgProfile}/${orgId}`);
                      }}
                    />
                  ) : (
                    <Avatar></Avatar>
                  )}
                </Box>
                <Typography
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{
                    fontFamily: "Circular Std",

                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "23px",
                    color: "#ff9776",
                  }}
                >
                  {organisation?.name}
                </Typography>
                <Typography
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{
                    fontFamily: "Circular Std",

                    fontWeight: 500,
                    fontSize: "17px",
                    lineHeight: "20px",
                    fontStyle: "italic",
                    paddingLeft: "15px",
                  }}
                >
                  {/* {cause.title} */}
                </Typography>
              </Box>
            )}

            <Grid item xs={12} sm={12} md={12}>
              <Grid sx={{ display: "flex" }}>
                {matches && !matches2 && (
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      // height={"100%"}
                      sx={{
                        paddingTop: {
                          xs: 5,
                          sm: 5,
                          md: 5,
                          lg: 5,
                        },
                      }}
                    >
                      <Box display={"flex"} justifyContent={"center"} pb={1}>
                        {organisation?.organisation_image?.length > 0 ? (
                          <Box
                            sx={{ cursor: "pointer" }}
                            component="img"
                            width={{
                              xs: 50,
                              sm: 50,
                              md: "100px",
                              lg: "100px",
                            }}
                            height={{
                              xs: 50,
                              sm: 50,
                              md: "100px",
                              lg: "100px",
                            }}
                            borderRadius={"100%"}
                            border={"5px solid #ff9776"}
                            src={organisation?.organisation_image}
                            alt={organisation?.name}
                            onClick={() => {
                              navigate(`${userRoutes?.orgProfile}/${orgId}`);
                            }}
                          />
                        ) : (
                          <Avatar></Avatar>
                        )}
                      </Box>
                      <Typography
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{
                          fontFamily: "Circular Std",

                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "23px",
                          color: "#ff9776",
                          marginLeft: "10px",
                        }}
                      >
                        {organisation?.name}
                      </Typography>
                      <Typography
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{
                          fontFamily: "Circular Std",

                          fontWeight: 500,
                          fontSize: "17px",
                          lineHeight: "20px",
                          fontStyle: "italic",
                          paddingLeft: "15px",
                        }}
                      >
                        {/* {cause.title} */}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={8}>
                  <Box padding={2} paddingTop={"12px"}>
                    {!matches && (
                      <Box
                        marginTop={"100px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          color={"text.red"}
                          fontStyle={"italic"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontWeight: 500,

                            lineHeight: "14px",
                            display: "flex",
                          }}
                          fontSize={{
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                          }}
                        >
                          Category - {CATEGORIES_OPTIONS[category]}
                        </Typography>

                        <Box style={{ display: "flex" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "end",
                            }}
                          >
                            {(cause?.organisation_tag === "authorized" ||
                              cause?.organisation_tag === "community_org") && (
                              <VerifiedIcon width={"10px"} height={"10px"} />
                            )}
                            {(cause?.organisation_tag === "ACN_verified" ||
                              cause?.organisation_tag === "ACNC_verified") && (
                              <VerifiedIcon
                                color={theme.palette.secondary.main}
                                width={"10px"}
                                height={"10px"}
                              />
                            )}
                            {(cause?.organisation_tag === "DGR_verified" ||
                              cause?.organisation_tag === "DRG_verified") && (
                              <VerifiedIcon
                                color={theme.palette.primary.dark}
                                width={"10px"}
                                height={"10px"}
                              />
                            )}
                          </span>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              paddingLeft={"4px"}
                              component="span"
                              sx={{
                                fontFamily: "Circular Std",
                                fontStyle: "normal",
                                fontWeight: 700,

                                lineHeight: "10px",
                              }}
                              fontSize={{
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                              }}
                            >
                              {abc(cause?.organisation_tag)}
                              {/* Certified Organisation */}
                            </Typography>

                            {cause?.organisation_tag === "authorized" && (
                              <Tooltip title="a verified community organisation fundraising for a valuable cause but does not hold charitable status or tax deductibility on donations.">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "17px",
                                    width: "10px",
                                  }}
                                />
                              </Tooltip>
                            )}
                            {(cause?.organisation_tag === "ACN_verified" ||
                              cause?.organisation_tag === "ACNC_verified") && (
                              <Tooltip title=" An Australian registered charity with the ACNC. Tax deductibility may not be available.">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "17px",
                                    width: "10px",
                                  }}
                                />
                              </Tooltip>
                            )}
                            {(cause?.organisation_tag === "DGR_verified" ||
                              cause?.organisation_tag === "DRG_verified") && (
                              <Tooltip title=" An Australian registered charity with the ACNC that has been endorsed to receive tax deductible donations over $2.">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "17px",
                                    width: "10px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </span>
                        </Box>
                      </Box>
                    )}

                    {matches && !matches2 && (
                      <Box sx={{ display: "flex" }}>
                        <span style={{ marginTop: "3px" }}>
                          {(cause?.organisation_tag === "authorized" ||
                            cause?.organisation_tag === "community_org") && (
                            <VerifiedIcon width={"15px"} height={"15px"} />
                          )}
                          {(cause?.organisation_tag === "ACN_verified" ||
                            cause?.organisation_tag === "ACNC_verified") && (
                            <VerifiedIcon
                              color={theme.palette.secondary.main}
                              width={"15px"}
                              height={"15px"}
                            />
                          )}
                          {(cause?.organisation_tag === "DGR_verified" ||
                            cause?.organisation_tag === "DRG_verified") && (
                            <VerifiedIcon
                              color={theme.palette.primary.dark}
                              width={"15px"}
                              height={"15px"}
                            />
                          )}
                        </span>
                        <span>
                          <Typography
                            fontWeight={"bold"}
                            paddingLeft={"5px"}
                            component="span"
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,

                              lineHeight: "10px",
                            }}
                            fontSize={{
                              xs: "13px",
                              sm: "13px",
                              md: "12px",
                              lg: "12px",
                            }}
                          >
                            {abc(cause?.organisation_tag)}
                            {/* Certified Organisation */}
                          </Typography>

                          {cause?.organisation_tag === "authorized" && (
                            <Tooltip title="a verified community organisation fundraising for a valuable cause but does not hold charitable status or tax deductibility on donations.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                          {(cause?.organisation_tag === "ACN_verified" ||
                            cause?.organisation_tag === "ACNC_verified") && (
                            <Tooltip title=" An Australian registered charity with the ACNC. Tax deductibility may not be available.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                          {(cause?.organisation_tag === "DGR_verified" ||
                            cause?.organisation_tag === "DRG_verified") && (
                            <Tooltip title=" An Australian registered charity with the ACNC that has been endorsed to receive tax deductible donations over $2.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </span>
                      </Box>
                    )}
                    {matches && !matches2 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          color={"text.red"}
                          fontStyle={"italic"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Category - {CATEGORIES_OPTIONS[category]}
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color={"text.red"}
                          style={{ cursor: "pointer" }}
                          onClick={favoriteHandler}
                        >
                          {favorite ? (
                            <LoveIcon width={"23px"} height={"20px"} />
                          ) : (
                            <FavoriteBorderIcon
                              width={"23px"}
                              height={"20px"}
                            />
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      paddingY={1}
                      //   alignItems={"center"}
                    >
                      <Box>
                        <Typography
                          // variant="h4"
                          sx={{
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: {
                              xs: "18px",
                              sm: "18px",
                              md: "20px",
                              lg: "20px",
                            },
                            lineHeight: "32px",
                          }}
                        >
                          {cause?.title || "Austrlian Wildlife Conservatory"}
                        </Typography>
                        <Typography
                          color={"text.liteGray"}
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "justify",
                          }}
                          component={"span"}
                          variant={"body2"}
                        >
                          <ShowMoreText
                            lines={3}
                            more={
                              <Box onClick={learnMoreSingleCard}>see more</Box>
                            }
                            less="show less"
                            onClick={executeOnClick}
                            expanded={false}
                            width={250}
                            truncatedEndingComponent={"... "}
                          >
                            {cause?.description}
                          </ShowMoreText>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <LinearProgress
                        variant="determinate"
                        sx={{
                          height: {
                            xs: "7px",
                            sm: "7px",
                            md: "17px",
                            lg: "17px",
                          },
                          borderRadius: 5,
                          [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor:
                              theme.palette.grey[
                                theme.palette.mode === "light" ? 200 : 800
                              ],
                          },
                          [`& .${linearProgressClasses.bar}`]: {
                            borderRadius: 5,
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.primary.main,
                          },
                        }}
                        value={countOfProgress}
                      />
                    </Box>
                    <Box display={"flex"} paddingY={1}>
                      <Box
                        width={"25%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Box>
                          {matches && !matches2 && (
                            <Typography
                              align="center"
                              sx={{
                                fontFamily: "Circular Std",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "9px",
                                lineHeight: "11px",
                              }}
                            >
                              Raised
                            </Typography>
                          )}
                          <Typography
                            align="center"
                            color={theme.palette.primary.main}
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "12px",
                              lineHeight: "15px",
                            }}
                          >
                            $
                            {cause?.fund_raised
                              ? parseInt(cause?.fund_raised)
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        width={"75%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Box>
                          {matches && !matches2 && (
                            <Typography
                              align="right"
                              sx={{
                                fontFamily: "Circular Std",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "9px",
                                lineHeight: "11px",
                              }}
                            >
                              Goal
                            </Typography>
                          )}
                          <Typography
                            align="right"
                            color={theme.palette.primary.main}
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "12px",
                              lineHeight: "15px",
                            }}
                          >
                            ${cause?.fund_raising_goal}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box bgcolor={theme.palette.secondary.main} paddingY={1}>
                <Container>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "15px",
                      lineHeight: "19px",
                    }}
                  >
                    OUR GOALS
                  </Typography>
                  <Box paddingY={0.5}>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={4}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems={"center"}
                        >
                          <Box
                            onClick={() =>
                              handleOpen1(cause?.micro_goal1?.image)
                            }
                            width={55}
                            borderRadius={"100%"}
                          >
                            {cause?.micro_goal1?.icon ? (
                              <img
                                src={cause?.micro_goal1?.icon}
                                alt={cause?.micro_goal1?.value}
                                width={"100%"}
                              />
                            ) : (
                              <Goal1Icon />
                            )}
                          </Box>
                          <Modal
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Box textAlign={"right"}>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose1}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                              <Typography
                                align="center"
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "25px",
                                  lineHeight: "40px",

                                  paddingBottom: "15px",
                                }}
                              >
                                OUR GOALS
                              </Typography>
                              <Box
                                bgcolor={theme.palette.secondary.main}
                                paddingY={1}
                              >
                                <Container>
                                  <Box paddingY={0.2}>
                                    <Grid
                                      container
                                      sx={{
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Grid item xs={4} sm={4} md={4}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                          flexDirection={"column"}
                                          alignItems={"center"}
                                          // paddingLeft={"250px"}
                                        >
                                          <Box width={35} borderRadius={"100%"}>
                                            {cause?.micro_goal1?.icon ? (
                                              <img
                                                src={cause?.micro_goal1?.icon}
                                                alt={cause?.micro_goal1?.value}
                                                width={"100%"}
                                              />
                                            ) : (
                                              <Goal1Icon />
                                            )}
                                          </Box>
                                          <Typography
                                            align="center"
                                            sx={{
                                              fontFamily: "Circular Std",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: "11px",
                                              lineHeight: "12px",
                                            }}
                                          >
                                            {cause?.micro_goal1?.value}
                                            {cause?.micro_goal1?.name}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Container>
                              </Box>

                              {video && (
                                <video
                                  width={"100%"}
                                  height={"70%"}
                                  loop="true"
                                  controls="controls"
                                  autoplay="autoplay"
                                  muted
                                >
                                  <source src={video} type="video/mp4" />
                                </video>
                              )}
                              {img && (
                                <img
                                  src={img}
                                  alt="img"
                                  width="100%"
                                  height="70%"
                                  style={{
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </Box>
                          </Modal>
                          <Typography
                            align="center"
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "12px",
                            }}
                          >
                            {cause?.micro_goal1?.value}{" "}
                            {cause?.micro_goal1?.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          onClick={() => handleOpen2(cause?.micro_goal2?.image)}
                        >
                          <Box width={55} borderRadius={"100%"}>
                            {cause?.micro_goal2?.icon ? (
                              <img
                                src={cause?.micro_goal2?.icon}
                                alt={cause?.micro_goal2?.value}
                                width={"100%"}
                              />
                            ) : (
                              <Goal1Icon />
                            )}
                          </Box>
                          <Modal
                            open={open2}
                            onClose={handleClose2}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Box textAlign={"right"}>
                                {/* <img
                                                                    src='./images/closepopup.svg'
                                                                    width={
                                                                        '12px'
                                                                    }
                                                                    onClick={
                                                                        handleClose2
                                                                    }
                                                                    alt={
                                                                        'title'
                                                                    }
                                                                /> */}
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose2}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                              <Typography
                                align="center"
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "25px",
                                  lineHeight: "40px",

                                  paddingBottom: "15px",
                                }}
                              >
                                OUR GOALS
                              </Typography>
                              <Box
                                bgcolor={theme.palette.secondary.main}
                                paddingY={1}
                              >
                                <Container>
                                  <Box paddingY={0.2}>
                                    <Grid
                                      container
                                      sx={{
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Grid item xs={4} sm={4} md={4}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                          flexDirection={"column"}
                                          alignItems={"center"}
                                          // paddingLeft={"250px"}
                                        >
                                          <Box width={35} borderRadius={"100%"}>
                                            {cause?.micro_goal2?.icon ? (
                                              <img
                                                src={cause?.micro_goal2?.icon}
                                                alt={cause?.micro_goal2?.value}
                                                width={"100%"}
                                              />
                                            ) : (
                                              <Goal1Icon />
                                            )}
                                          </Box>
                                          <Typography
                                            align="center"
                                            sx={{
                                              fontFamily: "Circular Std",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: "11px",
                                              lineHeight: "12px",
                                            }}
                                          >
                                            {cause?.micro_goal2?.value}
                                            {cause?.micro_goal2?.name}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Container>
                              </Box>

                              {video1 && (
                                <video
                                  width={"100%"}
                                  height={"70%"}
                                  loop="true"
                                  controls="controls"
                                  autoplay="autoplay"
                                  muted
                                >
                                  <source src={video1} type="video/mp4" />
                                </video>
                              )}
                              {img1 && (
                                <img
                                  src={img1}
                                  alt="img"
                                  width="100%"
                                  height="70%"
                                  style={{
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </Box>
                          </Modal>
                          <Typography
                            align="center"
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "12px",
                            }}
                          >
                            {cause?.micro_goal2?.value}{" "}
                            {cause?.micro_goal2?.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          onClick={() => handleOpen3(cause?.micro_goal3?.image)}
                        >
                          <Box width={55} borderRadius={"100%"}>
                            {cause?.micro_goal3?.icon ? (
                              <img
                                src={cause?.micro_goal3?.icon}
                                alt={cause?.micro_goal3?.value}
                                width={"100%"}
                              />
                            ) : (
                              <Goal1Icon />
                            )}
                          </Box>
                          <Modal
                            open={open3}
                            onClose={handleClose3}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Box textAlign={"right"}>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose3}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                              <Typography
                                align="center"
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "25px",
                                  lineHeight: "40px",

                                  paddingBottom: "15px",
                                }}
                              >
                                OUR GOALS
                              </Typography>
                              <Box
                                bgcolor={theme.palette.secondary.main}
                                paddingY={1}
                              >
                                <Container>
                                  <Box paddingY={0.2}>
                                    <Grid
                                      container
                                      sx={{
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Grid item xs={4} sm={4} md={4}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                          flexDirection={"column"}
                                          alignItems={"center"}
                                        >
                                          <Box width={35} borderRadius={"100%"}>
                                            {cause?.micro_goal3?.icon ? (
                                              <img
                                                src={cause?.micro_goal3?.icon}
                                                alt={cause?.micro_goal3?.value}
                                                width={"100%"}
                                              />
                                            ) : (
                                              <Goal1Icon />
                                            )}
                                          </Box>
                                          <Typography
                                            align="center"
                                            sx={{
                                              fontFamily: "Circular Std",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: "11px",
                                              lineHeight: "12px",
                                            }}
                                          >
                                            {cause?.micro_goal3?.value}
                                            {cause?.micro_goal3?.name}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Container>
                              </Box>

                              {video2 && (
                                <video
                                  width={"100%"}
                                  height={"70%"}
                                  loop="true"
                                  controls="controls"
                                  autoplay="autoplay"
                                  muted
                                >
                                  <source src={video2} type="video/mp4" />
                                </video>
                              )}
                              {img2 && (
                                <img
                                  src={img2}
                                  alt="img"
                                  width="100%"
                                  height="65%"
                                  style={{
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </Box>
                          </Modal>
                          <Typography
                            align="center"
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "12px",
                            }}
                          >
                            {cause?.micro_goal3?.value}{" "}
                            {cause?.micro_goal3?.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
              <Box paddingY={1}>
                {/* <Container> */}
                <Grid container justifyContent={"center"}>
                  {!matches2 && (
                    <Box paddingY={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(
                            `${userRoutes?.orgCause}?cause=${cause?.id}&isLogin=false`
                          );
                        }}
                        fullWidth
                        sx={{
                          paddingX: {
                            md: "50px",
                            lg: "48px",
                          },
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "18px",

                          paddingBottom: "13px",
                        }}
                      >
                        LEARN MORE
                      </Button>
                    </Box>
                  )}
                  &nbsp; &nbsp;
                  <Box paddingY={1}>
                    <Button
                      variant="contained"
                      startIcon={<SupportIcon />}
                      fullWidth
                      onClick={supportHandler}
                      sx={{
                        fontFamily: "Circular Std",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "18px",
                      }}
                    >
                      SUPPORT
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {open && (
            <SupportModal
              closeHandler={modalCloseHandler}
              open={open}
              causeId={id}
              organisationDoc={organisation}
              causeTitle={cause?.title}
            />
          )}

          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={alertCloseHandler}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
          >
            <Alert
              onClose={alertCloseHandler}
              severity={alertIcon}
              sx={{ width: "100%" }}
            >
              {alertMsg}
            </Alert>
          </Snackbar>
        </Box>
      </div>
    </>
  );
};

export default OrgCard;
