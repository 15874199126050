import React, { useEffect, useState, useContext, useCallback } from "react";

//package imports

import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
  Box,
  useTheme,
  Typography,
} from "@mui/material";

//user imports

import "./landing.css";

import CauseCard from "./CauseCard";
import { headersGet, headersDel } from "app/Services/organization/API";
import { API_URLS } from "app/Services/organization/Routes/API";
import { context } from "app/context";
import useOrgRedirect from "app/utils/organization/hooks/useOrgRedirect";

//component

export default function LandingPage() {
  //hooks

  useOrgRedirect();

  const { orgDetails, setOrgDetails } = useContext(context);
  const theme = useTheme();

  //states

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setloading] = useState(true);

  //get all causes

  const getAllCauses = useCallback(() => {
    setOpen(true);
    headersGet(
      `${API_URLS?.getAllCauses}?page=${page}&limit=${5}&organisation=${
        orgDetails?.id
      }`
    )
      .then((res) => {
        setOpen(false);
        setloading(false);
        if (res?.status === 200) {
          setData(res?.data?.causes?.results);
          setTotalPages(res?.data?.causes?.totalPages);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
        setloading(false);
      });
  }, [page, orgDetails]);

  //life cycle hooks

  useEffect(() => {
    page && orgDetails && getAllCauses();
  }, [page, getAllCauses, orgDetails]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //del handler

  const delhandler = (id) => {
    setOpen(true);
    headersDel(`${API_URLS?.deleteCause}?causeId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && res?.data?.status) {
          setData((prev) => prev?.map((val) => val?.id !== id));
          setAlertOpen(true);
          setAlertMsg("Cause is deleted successfully.");
          setAlertIcon("success");
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //render

  return (
    <div style={{ paddingTop: "30px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="active-title">
              <p>ACTIVE CAUSES</p>
            </div>
            {!loading && data?.length > 0 ? (
              <div className="cards">
                {data?.map((card, index) => (
                  <CauseCard
                    card={card}
                    orgDetails={orgDetails}
                    delhandler={delhandler}
                    key={`${index}-card-cause-${card?.id}`}
                  />
                ))}
              </div>
            ) : (
              !loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  // display={"flex"}
                  // justifyContent={"center"}
                  // bgcolor={theme.palette.common.white}
                  // padding={5}
                  // borderRadius={1}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "40px",
                      width: "fit-content",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant={"h4"}
                      sx={{ color: "#000" }}
                    >
                      No Active Cause Found
                    </Typography>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <Box display={"flex"} justifyContent={"center"} marginY={3}>
        <Box bgcolor={theme.palette.common.white} padding={1} borderRadius={25}>
          <Pagination
            count={totalPages}
            color="primary"
            page={page}
            variant="contained"
            onChange={handleChangePage}
          />
        </Box>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
