import React, { Fragment } from "react";

//user imports

import LandingPage from "app/containers/organization/LandingPage/LandingPage";
import Transaction from "app/containers/organization/Transaction/Transaction";
import Payment from "app/containers/organization/Payment/Payment";
import Profile from "app/containers/organization/Profile/Profile";
import Feed from "app/containers/organization/Feed/Feed";
import Cause from "app/containers/organization/Cause/Cause";
import AddCause from "app/containers/organization/Cause/AddCause";
import ViewCause from "app/containers/organization/Cause/Viewcause";

import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import Navbar from "app/components/organization/Navbar";

//admin routes

export const OrgRoutes = [
  {
    id: 120,
    path: APP_URLS?.root,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <LandingPage />
      </Fragment>
    ),
  },
  {
    id: 121,
    path: APP_URLS?.feed,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <Feed />
      </Fragment>
    ),
  },
  {
    id: 122,
    path: APP_URLS?.transaction,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <Transaction />
      </Fragment>
    ),
  },
  {
    id: 123,
    path: APP_URLS?.payment,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <Payment />
      </Fragment>
    ),
  },
  {
    id: 124,
    path: APP_URLS?.profile,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <Profile />
      </Fragment>
    ),
  },
  {
    id: 125,
    path: APP_URLS?.cause,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <Cause />
      </Fragment>
    ),
  },
  {
    id: 126,
    path: APP_URLS?.addCause,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <AddCause />
      </Fragment>
    ),
  },
  {
    id: 126,
    path: APP_URLS?.editCause,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <AddCause isEdit={true} />
      </Fragment>
    ),
  },
  {
    id: 127,
    path: APP_URLS?.viewCause,
    exact: true,
    element: (
      <Fragment>
        <Navbar />
        <ViewCause />
      </Fragment>
    ),
  },
];
