import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//user import

import MotoIcon from "app/svg/user/Moto";
import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
//component

const bounceAnimation = keyframes`${bounce}`;
const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation} infinite;
`;
const YourSelf = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
        paddingY: 4,
        paddinX: 2,
      }}
    >
      <Container>
        <Box>
          <Typography
            variant="h5"
            color={"common.white"}
            align="center"
            fontWeight={"bold"}
          >
            You want to express yourself through giving
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={4}>
          <Box width={270} height={230}>
            <BouncyDiv>
              <MotoIcon
                width={"100%"}
                height={"100%"}
                color={theme.palette.secondary.light}
                sideColor={theme.palette.common.white}
              />
            </BouncyDiv>
          </Box>
        </Box>
        <Box marginBottom={2}>
          <Typography
            color={"common.white"}
            align="center"
            variant="h6"
            fontWeight={"bold"}
          >
            Capture your interests and values during onboarding, tailoring the
            NFP’s visible on the platform to those which fit YOUR beliefs. Be
            immersed in causes our passionate about and have a say where your
            funds are directed.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default YourSelf;
