import React from "react";

//user imports

import DonationHistoryPage from "app/components/user/DonationHistory";

//component

const DonationHistory = () => <DonationHistoryPage />;

export default DonationHistory;
