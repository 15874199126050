import React from "react";

//package import

import { Box, useTheme, Container, Typography } from "@mui/material";

//user import

import LogoIcon from "app/svg/user/Logo";
import AllDoneIcon from "app/svg/user/AllDoneIcon";

//component

const AllDone = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        backgroundImage: `url(/images/EllipseAllDone.png)`,
        minHeight: "100vh",
        backgroundSize: "20%",
        backgroundPosition: "right",
        padding: theme.spacing(2, 2, 2, 2),
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Box>
          <LogoIcon textColor={theme.palette.common.white} />
        </Box>
        <Box marginTop={2}>
          <Typography
            fontWeight={"bold"}
            align="center"
            color={"common.white"}
            variant="h4"
          >
            Great stuff! You're all done.
          </Typography>
          <Typography
            align="center"
            fontWeight={"bold"}
            color={"common.white"}
            variant="h4"
          >
            We will just compile everything for you now...
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} marginTop={4}>
          <Box width={"50%"}>
            <AllDoneIcon width={"100%"} height={"100%"} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AllDone;
