import React from 'react'

//package import

import {Box, Container, Typography, useTheme} from '@mui/material'
import Carousel from 'react-multi-carousel'

//user import

import CardItem from './CardItem'

//constants

const arr = [
    {
        id: 123,
        image: '/images/Connect1.png',
        text: 'Animals & Wildlife',
        bgImage: '/images/Connect1Bg.png',
        url: 'animals_and_wildlife',
    },
    {
        id: 124,
        image: '/images/Connect2.png',
        text: 'Environment & Conservation',
        bgImage: '/images/Connect2Bg.png',
        url: 'environment_and_conservation',
    },
    {
        id: 125,
        image: '/images/Connect3.png',
        text: 'Disaster Response (in Australia)',
        bgImage: '/images/Connect3Bg.png',
        url: 'disaster_response',
    },
    {
        id: 126,
        image: '/images/Connect4.png',
        text: 'People & Community',
        bgImage: '/images/Connect4Bg.png',
        url: 'people_and_community',
    },
    {
        id: 127,
        image: '/images/Connect5.svg',
        text: 'Health & Medical',
        bgImage: '/images/Connect5Bg.png',
        url: 'health_and_medical',
    },
    {
        id: 128,
        image: '/images/Connect6.svg',
        text: 'Arts & Culture',
        bgImage: '/images/Connect5Bg.png',
        url: 'arts_and_culture',
    },
    {
        id: 129,
        image: '/images/Connect7.svg',
        text: 'Religion & Religious Groups',
        bgImage: '/images/Connect5Bg.png',
        url: 'religion_and_religious_groups',
    },
    {
        id: 130,
        image: '/images/Connect8.svg',
        text: 'Overseas Aid & Development',
        bgImage: '/images/Connect5Bg.png',
        url: 'overseas_aid_and_development',
    },
]

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5,
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 4,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    },
}

//component

const Connect = () => {
    //hooks

    const theme = useTheme()

    //render

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.common.black,
                paddingY: 3,
            }}
        >
            <Typography variant='h4' align='center' color={'common.white'}>
                Find causes YOU care about and CONNECT with them NOW!
            </Typography>
            <Box display={'flex'} justifyContent={'center'} paddingY={2}>
                <Box
                    sx={{
                        border: `1px solid ${theme.palette.common.white}`,
                        width: '20%',
                    }}
                ></Box>
            </Box>
            <Container>
                <Carousel
                    responsive={responsive}
                    partialVisible
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={1000}
                    removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                >
                    {arr.map((item, index) => (
                        <Box
                            key={`${index}-connect-keys-${item?.id}`}
                            sx={{width: {xs: 355, sm: 355, md: 275, lg: 275}}}
                            height={275}
                        >
                            <CardItem item={item} />
                        </Box>
                    ))}
                </Carousel>
                <Box paddingY={2}>
                    <Carousel
                        responsive={responsive}
                        partialVisible
                        autoPlay={true}
                        infinite={true}
                        autoPlaySpeed={1000}
                        removeArrowOnDeviceType={[
                            'tablet',
                            'mobile',
                            'desktop',
                        ]}
                    >
                        {arr.map((item, index) => (
                            <Box
                                key={`${index}-connect-keys-${item?.id}`}
                                sx={{
                                    width: {xs: 355, sm: 355, md: 275, lg: 275},
                                }}
                                height={275}
                            >
                                <CardItem item={item} />
                            </Box>
                        ))}
                    </Carousel>
                </Box>
            </Container>
        </Box>
    )
}

export default Connect
