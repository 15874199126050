import React, { useState } from "react";

//package import

import {
  Accordion,
  Box,
  Container,
  Typography,
  useTheme,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add as AddIcon, Minimize as MinimizeIcon } from "@mui/icons-material";

//user import

//constants

const accorArray = [
  {
    id: 200,
    title: "How does U4C work?",
    desc: "Up 4 Change is a social roundup donation platform that connects you to Not-for-Profits through a centralised social feed, allowing you to connect and support charities of your choice through easy round up donations.  You can seamlessly donate your small change by connecting your everyday transaction account (credit or debit card, or bank account) and simply going about your everyday life! Coffee runs, late night Uber Eats and even paying the phone bill all helps to change the world! All the small change from your purchases, both online and in person is automatically rounded up to the nearest dollar, collated and then donated to the causes YOU care about.",
  },
  {
    id: 201,
    title: "What are roundups?",
    desc: "Round Up’s are all the small change left from your everyday transactions.  So if your grocery shopping is $54.80, your round up amount of .20c will go to your U4C account and sent to your favourite cause at the end of the month.  Donations are tax deductible to approved charities. Yes, you can change the world with your small change! ",
  },
  {
    id: 202,
    title: "How do I connect my roundups to a charity?",
    desc: "Simply select the charity or cause of your choice and press the ‘Support’ button.  Then, securely link your card or bank account, set your round up limits and every time you make a transaction, your spare change will be rounded up to the nearest dollar and automatically donated to your preferred cause! Or do a one time donation. Hassle free giving at its best! ",
  },
  {
    id: 203,
    title: "What are the fee's as a donor?",
    desc: `Using the U4C platform is absolutely free for donors!  There is a small % fee for organisations wishing to take advantage of the U4C platform- Contact Us to discuss.`,
  },
  {
    id: 204,
    title: "What are the security & safity features of u4c?",
    desc: "We never have access or save any of your card or account  information or passwords- its all encrypted and fully secure. We use the best payment processors and security to keep you and our charities safe online. ",
  },
];

//component

const Faqs = () => {
  //hooks

  const theme = useTheme();

  //states

  const [expanded, setExpanded] = useState(200);

  //accordian change

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //render
  return (
    <div id="FAQ">
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
        backgroundImage: `url(/images/FaqsEllipse.png)`,
        backgroundSize: "15%",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        padding: theme.spacing(6, 2, 2, 2),
      }}
    >
      <Typography
        align="center"
        variant="h3"
        color={"common.white"}
        fontWeight={"bold"}
        sx={{
          textTransform: "uppercase",
        }}
      >
        FAQ's
      </Typography>

      <Box display={"flex"} justifyContent={"center"} padding={2}>
        <Box
          sx={{
            border: `2px solid ${theme.palette.common.white}`,
            width: "5%",
          }}
        ></Box>
      </Box>
      <Container>
        {accorArray?.map((item) => (
          <Accordion
            sx={{
              backgroundColor: "transparent",
            }}
            elevation={0}
            expanded={expanded === item?.id}
            onChange={handleChange(item?.id)}
          >
            <AccordionSummary
              expandIcon={
                expanded === item?.id ? (
                  <MinimizeIcon
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                )
              }
              sx={{
                backgroundColor: theme.palette.primary.light,
              }}
            >
              <Box>
                <Typography
                  color={"common.white"}
                  fontWeight={"bold"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {item?.title}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.primary.main,
                marginX: theme.spacing(2),
              }}
            >
              <Box padding={2}>
                <Typography
                  color={"common.white"}
                  fontStyle={"italic"}
                  variant="h6"
                  fontWeight={500}
                >
                  {item?.desc}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
    </div>
  );
};

export default Faqs;
