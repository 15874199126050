import React, { useCallback, useState, useEffect, useContext } from "react";

//package import

import {
  Box,
  Typography,
  useTheme,
  Container,
  Grid,
  Button,
  Backdrop,
  Alert,
  CircularProgress,
  Snackbar,
  Pagination,
} from "@mui/material";

//user import
import { useLocation, useSearchParams } from "react-router-dom";
import OrgCard from "../Discover/OrgCard";

import { API_URLS } from "app/Services/user/routes/API";

// import { context } from "../../context";

import {
  CATEGORIES_ARRAY,
  CATEGORIES,
  USER_FEED_TYPES,
} from "app/common/user/Enums";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { userRoutes } from "app/Services/user/routes/APP";
import { headersDel, headersGet, simpleGet } from "app/Services/user/API";
import { context } from "app/context";

//component

const Discover = () => {
  //hooks
  const { pathname } = useLocation();
  const { user } = useContext(context);
  const theme = useTheme();
  const isLogIn = isLoggedIn();

  const { _id: userId } = user || {};
  const userFeedPath = pathname?.includes(userRoutes?.user);

  //states

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [api, setApi] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState(CATEGORIES.ANIMALS_AND_WILDLIFE);
  const [supportState, setSupportState] = useState({
    cause: "",
    org: "",
    causeName: "",
  });
  // //get all causes

  // const getAllCauses = useCallback(() => {
  //   setOpen(true);
  //   setApi(false);
  //   simpleGet(`${API_URLS?.getAllCauses}?page=${page}&limit=${10}&populate=${`organisation`}`)
  //     .then((res) => {
  //       setOpen(false);
  //       if (res?.status === 200) {
  //         setData(res?.data?.causes?.results);
  //         setTotalPages(res?.data?.causes?.totalPages);
  //       } else {
  //         setAlertOpen(true);
  //         setAlertMsg(res?.data?.message);
  //         setAlertIcon("warning");
  //       }
  //     })
  //     .catch((err) => {
  //       const { response } = err;
  //       setOpen(false);
  //       setAlertOpen(true);
  //       setAlertMsg(response?.data?.message);
  //       setAlertIcon("error");
  //     });
  // }, [page]);

  // //life cycle hooks

  // useEffect(() => {
  //   api && getAllCauses();
  // }, [api, getAllCauses]);

  //  get all causes

  const getAllCauses = useCallback(
    (url) => {
      setOpen(true);
      setApi(false);
      headersGet(url)
        .then((res) => {
          const { data, status } = res || {};
          setOpen(false);
          if (status === 200) {
            if (isLogIn && userFeedPath) {
              const { userFeeds } = data || {};
              const { totalPages, results } = userFeeds || {};
              setTotalPages(totalPages);
              const newCauses = results?.map((item) => {
                const { id: feedId, cause, ...rest } = item || {};
                return { feedId, ...cause, ...rest };
              });
              newCauses?.length ? setData(newCauses) : setData([]);
            } else {
              const { causes } = data || {};
              const { results, totalPages } = causes || {};
              setLocation("");
              results?.length ? setData(results) : setData([]);
              setTotalPages(totalPages);
            }
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    },
    [isLogIn, userFeedPath]
  );

  const getFavCauses = () => {
    if (isLogIn) {
      setOpen(true);
      setApi(false);
      headersGet(`${API_URLS.allFavCausesByUser}?userId=${userId}`)
        .then((res) => {
          // console.log("====================================");
          // console.log({ RESPONSE: res });
          // console.log("====================================");
          setOpen(false);
          setData(res?.data?.causes?.favCauses);
        })
        .catch((err) => {
          setOpen(false);
          console.log({ err });
        });
    }
  };

  const fetchFeeds = useCallback(() => {
    const homeUrl = `${
      API_URLS?.getAllCauses
    }?page=${page}&limit=${10}&category=${category}&populate=${`organisation`}${
      searchQuery?.length >= 3 ? `&searchQuery=${searchQuery}` : ``
    }${location ? `&location=${location}` : ""}`;

    const userUrl = `${
      API_URLS?.getAllUserFavFeed
    }?page=${page}&limit=${10}&user=${userId}&feedType=${
      USER_FEED_TYPES.CAUSE
    }&category=${category}${
      searchQuery?.length >= 3 ? `&searchQuery=${searchQuery}` : ""
    }`;
    if (isLogIn && userFeedPath) {
      userId && getAllCauses(userUrl);
    } else {
      getAllCauses(homeUrl);
    }
  }, [
    location,
    page,
    searchQuery,
    category,
    isLogIn,
    userId,
    getAllCauses,
    userFeedPath,
  ]);

  //life cycle hooks

  // useEffect(() => {
  //     api && fetchFeeds()
  // }, [api, fetchFeeds])

  useEffect(() => {
    userId && getFavCauses();
  }, [userId]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setApi(true);
  };

  const removeUserFeedFav = async (feedId) => {
    try {
      await headersDel(`${API_URLS?.delUserFavFeed}/${feedId}`);
      setApi(true);
      setAlertOpen(true);
      setAlertMsg("Feed is remove from your favorite successfully.");
      setAlertIcon("success");
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      setAlertOpen(true);
      setAlertMsg(message || "Error found");
      setAlertIcon("error");
    }
  };
  //render

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.info.light,
          padding: 4,
        }}
      >
        <Container>
          <Box marginTop={4}>
            <Typography color={"primary.main"} fontWeight={"bold"}>
              My Favourite Causes
            </Typography>
          </Box>
          <Box marginTop={2}>
            {data?.map((item, index) => {
              const itemNew = {
                ...item?.cause,
                organisation: item?.org,
              };
              const { organisation, title, cause_image } = itemNew || {};
              const { name } = organisation;

              return (
                <Box
                  sx={{
                    background: theme.palette.common.white,
                    marginBottom: 4,
                  }}
                  key={`${index}-org-card-${itemNew.id}`}
                >
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"stretch"}
                  >
                    {/* <Grid item md={3}> */}
                    {/* <Box
                        height={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                      > */}
                    {/* <Box>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            paddingBottom={2}
                          >
                            <Box component="img" src="/images/OrgProfile.png" />
                          </Box>
                          <Typography
                            align={"center"}
                            fontWeight={"bold"}
                            color={"primary.main"}
                            gutterBottom
                          >
                            {name}
                          </Typography>
                          <Typography
                            align={"center"}
                            fontWeight={"bold"}
                            fontStyle={"italic"}
                          >
                            {title}
                          </Typography>
                        </Box> */}
                    {/* <Box display={"flex"} flexWrap={"wrap"}>
                          <Box>
                            <Button
                              variant="contained"
                              sx={{
                                paddingX: 3.3,
                              }}
                            >
                              View
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                paddingX: 3.3,
                              }}
                              variant="contained"
                            >
                              Edit
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                paddingX: 3.3,
                              }}
                              variant="contained"
                            >
                              Remove
                            </Button>
                          </Box>
                        </Box> */}
                    {/* </Box> */}
                    {/* </Grid> */}
                    <Grid
                      item
                      md={6}
                      style={{
                        background: `url(${cause_image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                      }}
                    ></Grid>
                    <Grid item md={6}>
                      <OrgCard
                        cause={itemNew}
                        removeUserFeedFav={removeUserFeedFav}
                        isFromMyCause
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
          {/* <Box display={'flex'} justifyContent={'center'} marginY={3}>
                        <Box
                            bgcolor={theme.palette.common.white}
                            padding={1}
                            borderRadius={25}
                        >
                            <Pagination
                                count={totalPages}
                                color='primary'
                                page={page}
                                variant='contained'
                                onChange={handleChangePage}
                            />
                        </Box>
                    </Box> */}
        </Container>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Discover;
