import React from "react";

const Rectangle = ({ height, width }) => {
  return (
    <svg
      width={width ? width : "3"}
      height={height ? height : "108"}
      viewBox="0 0 3 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.882812"
        y="107.019"
        width="106.329"
        height="1.89873"
        transform="rotate(-90 0.882812 107.019)"
        fill="#E8E9EB"
      />
    </svg>
  );
};

export default Rectangle;
