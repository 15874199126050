import React, { useState, useEffect } from "react";
import { Dialog, Snackbar, Alert } from "@mui/material";
import EmailScreen from "./EmailScreen";

import OTPScreen from "./OTPScreen";
import { API_URLS } from "app/Services/user/routes/API";
import { simplePost } from "app/Services/user/API";

const initialValues = {
  email: "",
  otp: "",
  newPassword: "",
  confirmNewPassword: "",
};

function Index({ open = false, closeHandler = () => {} }) {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(initialValues);
  const [isProcessing, setIsProcessing] = useState(false);

  const [api, setApi] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleForgotPassword = () => {
    if (values.email) {
      const data = {
        email: values?.email,
      };
      setIsProcessing(true);

      simplePost(API_URLS?.forgotPassword, data)
        .then((res) => {
          setIsProcessing(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("success");
          setStep(2);
        })
        .catch((err) => {
          const { response } = err;
          setIsProcessing(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    } else {
      setAlertOpen(true);
      setAlertMsg("Please enter email");
      setAlertIcon("error");
    }
  };
  const handleVerifyCode = async () => {
    if (
      values.email &&
      values.otp &&
      values.newPassword &&
      values.confirmNewPassword
    ) {
      if (values?.newPassword !== values?.confirmNewPassword) {
        setAlertOpen(true);
        setAlertMsg(`Passwords don't match`);
        setAlertIcon("error");
      } else {
        const data = {
          email: values?.email,
          code: values?.otp,
          newPassword: values?.newPassword,
        };
        setIsProcessing(true);

        simplePost(API_URLS?.verifyCode, data)
          .then((res) => {
            setIsProcessing(false);
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("success");
            setTimeout(() => {
              closeHandler();
            }, 2000);
            // setStep(2)
          })
          .catch((err) => {
            const { response } = err;
            setIsProcessing(false);
            setAlertOpen(true);
            setAlertMsg(response?.data?.message);
            setAlertIcon("error");
          });
      }
    } else {
      setAlertOpen(true);
      setAlertMsg("One or more fields are empty");
      setAlertIcon("error");
    }
  };

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth={"sm"} fullWidth>
      {step === 1 && (
        <EmailScreen
          values={values}
          handleChange={handleChange}
          btnState={isProcessing}
          handleSubmit={handleForgotPassword}
        />
      )}
      {step === 2 && (
        <OTPScreen
          values={values}
          handleChange={handleChange}
          btnState={isProcessing}
          handleSubmit={handleVerifyCode}
          handleBack={handleBack}
          handleForgotPassword={handleForgotPassword}
        />
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default Index;
