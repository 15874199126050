import React, {
  useState,
  useContext,
  Fragment,
  useEffect,
  useCallback,
} from "react";

//package imports

import { Link } from "react-router-dom";
import {
  useTheme,
  Box,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";

//user imports

import "./cause.css";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { headersGet, headersDel } from "app/Services/organization/API";
import { API_URLS } from "app/Services/organization/Routes/API";
// import { context } from "../../context";
import CauseCard from "../LandingPage/CauseCard";
import { context } from "app/context";
import useOrgRedirect from "app/utils/organization/hooks/useOrgRedirect";

//component

export default function Cause() {
  //hooks
  useOrgRedirect();

  const { orgDetails, setOrgDetails } = useContext(context);
  const theme = useTheme();

  //states

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [api, setApi] = useState(true);

  //get all causes

  const getAllCauses = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(
      `${API_URLS?.getAllCauses}?page=${page}&limit=${10}&organisation=${
        orgDetails?.id
      }`
    )
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setData(res?.data?.causes?.results);
          setTotalPages(res?.data?.causes?.totalPages);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [page, orgDetails]);

  //life cycle hooks

  useEffect(() => {
    api && page && orgDetails && getAllCauses();
  }, [api, page, getAllCauses, orgDetails]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setApi(true);
  };

  //del handler

  const delhandler = (id) => {
    setOpen(true);
    headersDel(`${API_URLS?.deleteCause}?causeId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setData((prev) => prev?.map((val) => val?.id !== id));
          setAlertOpen(true);
          setAlertMsg("Cause is deleted successfully.");
          setAlertIcon("success");
          setApi(true);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //render

  return (
    <Box paddingTop={7.5} className="cause__main">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3">
            {data?.length > 0 ? (
              <Fragment>
                <Box marginBottom={2}>
                  <Link to={APP_URLS?.org + APP_URLS?.addCause}>
                    <button className="btn btn-pink create-cause-btn">
                      CREATE A CAUSE{" "}
                      <img
                        src="/images/create.svg"
                        alt="create"
                        style={{ paddingLeft: "20px" }}
                      />
                    </button>
                  </Link>
                </Box>
                <div className="active-title">
                  <p>ACTIVE CAUSES</p>
                </div>
                <div className="cards">
                  {data?.map((card, index) => (
                    <CauseCard
                      card={card}
                      delhandler={delhandler}
                      orgDetails={orgDetails}
                      key={`${index}-card-cause-${card?.id}`}
                    />
                  ))}
                </div>
              </Fragment>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <div className="cause-btn">
                  <p className="text-white my-3">No Cause Found</p>
                  <Link to={APP_URLS?.org + APP_URLS?.addCause}>
                    <button className="btn btn-pink create-cause-btn">
                      CREATE A CAUSE{" "}
                      <img
                        src="/images/create.svg"
                        alt="create"
                        style={{ paddingLeft: "20px" }}
                      />
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Box display={"flex"} justifyContent={"center"} marginY={3}>
        <Box bgcolor={theme.palette.common.white} padding={1} borderRadius={25}>
          <Pagination
            count={totalPages}
            color="primary"
            page={page}
            variant="contained"
            onChange={handleChangePage}
          />
        </Box>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
