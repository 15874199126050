// export const convertDataToExcel = (data=[]) =>{

import { formatDate, formatDateTime } from "app/common/admin/Functions";

const excelDataFormat = (data = []) => {
  let formatedData = [];

  data?.map((item) => {
    const { createdAt, pp_fees, organisation, cause, amount } = item || {};

    const formatedObj = {
      Date: `${formatDate(createdAt)} at ${formatDateTime(createdAt)}`,
      "PlateForm fees": pp_fees,
      Amount: amount,
      "Organisation Name": organisation?.company_name,
      Category: organisation?.category,
      "Cause Name": cause?.title,
    };

    formatedData.push(formatedObj);
  });
  return formatedData;
};

export default excelDataFormat;
