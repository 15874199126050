import React from "react";

const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3626 0.244629H3.2307C2.8155 0.244424 2.40432 0.326027 2.02066 0.484777C1.637 0.643527 1.28838 0.876313 0.994716 1.16983C0.701049 1.46336 0.468091 1.81186 0.309151 2.19544C0.150212 2.57902 0.0684051 2.99016 0.0684052 3.40537L0.0684052 20.5373C0.0684051 20.9525 0.150212 21.3636 0.309151 21.7472C0.468091 22.1308 0.701049 22.4793 0.994716 22.7728C1.28838 23.0663 1.637 23.2991 2.02066 23.4579C2.40432 23.6166 2.8155 23.6982 3.2307 23.698H11.6802L11.6943 15.3173H9.517C9.38104 15.3173 9.25064 15.2634 9.15436 15.1675C9.05808 15.0715 9.00378 14.9412 9.00337 14.8053L8.99321 12.1034C8.9929 12.0358 9.00595 11.9688 9.03163 11.9062C9.0573 11.8436 9.09509 11.7867 9.14281 11.7387C9.19054 11.6908 9.24726 11.6528 9.30973 11.6268C9.3722 11.6008 9.43919 11.5875 9.50683 11.5875H11.6802V8.97789C11.6802 5.9485 13.5307 4.29894 16.2325 4.29894H18.4496C18.5858 4.29894 18.7165 4.35305 18.8128 4.44938C18.9091 4.5457 18.9632 4.67635 18.9632 4.81257V7.09068C18.9632 7.2269 18.9091 7.35754 18.8128 7.45387C18.7165 7.55019 18.5858 7.60431 18.4496 7.60431H17.0893C15.6196 7.60431 15.335 8.30243 15.335 9.32422V11.5883H18.5645C18.6372 11.5882 18.709 11.6035 18.7753 11.6333C18.8416 11.663 18.9008 11.7064 18.949 11.7607C18.9973 11.8151 19.0334 11.879 19.0552 11.9483C19.0769 12.0176 19.0837 12.0907 19.075 12.1629L18.7529 14.8639C18.7382 14.9889 18.6781 15.1041 18.5839 15.1877C18.4898 15.2713 18.3683 15.3174 18.2424 15.3173H15.3499L15.335 23.698H20.3626C21.2006 23.6978 22.0041 23.3648 22.5965 22.7722C23.189 22.1796 23.5218 21.376 23.5218 20.5381V3.40537C23.5218 2.56736 23.189 1.76365 22.5966 1.17094C22.0042 0.578232 21.2006 0.245044 20.3626 0.244629Z"
        fill="white"
      />
    </svg>
    

  );
};

export default Facebook;
