import { Fragment, useState, useEffect } from "react";

//packages imports

import { useNavigate } from "react-router-dom";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";

//user import

import { formatCategory } from "app/common/organization/functions";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";

//component

const CauseCard = ({ card, orgContext, delhandler }) => {
  //hooks

  const navigate = useNavigate();
  const theme = useTheme();

  const [countOfProgress, setCountOfProgress] = useState(0);
  const calculateGoalPercen = (fund_raising_goal, fund_raised) => {
    if (fund_raising_goal === fund_raised) {
      return 100;
    } else {
      let percen = (fund_raised / fund_raising_goal) * 100;
      if (percen > 100) {
        percen = 100;
        return percen;
      } else {
        return percen;
      }

      // return ( fund_raised/fund_raising_goal)*100 ;
    }
  };
  useEffect(() => {
    let fund_raised = card?.fund_raised;
    let fund_raising_goal = card?.fund_raising_goal;

    // let  fund_raised= 10;
    // let  fund_raising_goal= 100;
    // console.log({
    //     causeId: calculateGoalPercen(fund_raising_goal, fund_raised),
    // })
    setCountOfProgress(calculateGoalPercen(fund_raising_goal, fund_raised));
  }, [card]);

  //render
  return (
    <Fragment>
      <div className="card my-4">
        <div className="card-body">
          <div className="main-area">
            <div className="card-img">
              <img
                src={card?.cause_image || "/images/mouse.jpg"}
                alt="card-img"
              />
            </div>
            <div className="card-data px-4 py-2">
              <div className="viewEdit">
                <Box
                  sx={{
                    textDecoration: "underline",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  mr={1}
                  onClick={() =>
                    navigate(`${APP_URLS?.org}${APP_URLS?.cause}/${card?.id}`)
                  }
                >
                  Edit
                </Box>
                <Box
                  sx={{
                    textDecoration: "underline",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => delhandler(card?.id)}
                >
                  Delete
                </Box>
              </div>
              <div className="information">
                <p className="category">
                  Category -{" "}
                  {card?.category
                    ? formatCategory(card?.category)
                    : formatCategory(orgContext?.category)}
                </p>
                <p className="title">{card?.title}</p>

                <p
                  className="description text"
                  style={{
                    lineHeight: "1.5em",
                    height: "7.3em",
                    overflow: "hidden",
                  }}
                >
                  {card?.description}
                </p>
                {/* <div className='progress w-100 mt-3'>
                                    <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{width: card.progress}}
                                        aria-valuenow={countOfProgress}
                                        aria-valuemin='0'
                                        aria-valuemax='100'
                                    ></div>
                                    
                                </div> */}
                <LinearProgress
                  variant="determinate"
                  sx={{
                    height: "12px",
                    borderRadius: 5,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor:
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      borderRadius: 5,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    },
                  }}
                  value={countOfProgress}
                />
                <div className="goal">
                  <div className="goal-achieved">
                    <p className="goal-head">Goal Acheived</p>
                    <p className="goal-price">{card?.achieved}</p>
                  </div>
                  <div className="goal-total">
                    <p className="goal-head">Goal</p>
                    <p className="goal-price">${card?.fund_raising_goal}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CauseCard;
