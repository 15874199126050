import React, { Fragment } from "react";

//package import

import { Box, Container, Typography, useTheme, Button } from "@mui/material";
// import { userRoutes } from "../../services/routes/APP";
import { userRoutes } from "app/Services/user/routes/APP";
import { useState } from "react";
import { PopupModal } from "react-calendly";

const Companies = () => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <Box
        bgcolor={theme.palette.secondary.main}
        paddingY={3}
        sx={{
          backgroundImage: `url(/images/ContactEllipse.png)`,
          backgroundSize: "25%",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Box paddingBottom={4} marginTop={5}>
            <Typography align={"center"}>
              <Typography
                component={"span"}
                variant="h4"
                fontWeight={"bold"}
                color={"primary.light"}
                sx={{
                  textTransform: "uppercase",
                  borderBottom: `2px solid black`,
                  paddingBottom: 1,
                  lineHeight: 1.5,
                }}
              >
                iS YOUR COMPANY UP 4 CHANGE?
              </Typography>
            </Typography>
          </Box>

          <Typography
            variant="h5"
            fontWeight={"bold"}
            color={"primary.light"}
            gutterBottom
            paddingBottom={3}
          >
            Do you have a social cause your business supports? Do you need help
            finding one?
          </Typography>
          <Typography paddingBottom={3} color={"primary.light"}>
            Up 4 Change can assists e-companies, small businesses and companies
            to find and adopt social causes that match your brand initiatives.
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            color="white"
            gutterBottom
            paddingBottom={3}
          >
            When it comes to social responsibility, consumers are looking for
            brands to show them – not just tell them – what they’re doing!
          </Typography>
          <Typography color={"primary.light"}>
            87% of consumers would buy a product based on a company's advocacy
            on an issue they care about, according to the
          </Typography>
          <Typography color="white" sx={{ textDecoration: "underline" }}>
            2017 Cone Communications CRS Study
          </Typography>
          <Typography paddingBottom={3} paddingTop={3} color={"primary.light"}>
            Businesses can’t just talk the talk anymore, they need to create
            action! Consumers are more likely to choose a product that is driven
            by a social mission; younger generations are looking to work for a
            company that has a greater purpose and investors are more and more
            concerned with wellbeing & sustainability.
          </Typography>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            color="white"
            gutterBottom
            paddingBottom={3}
          >
            Up 4 Change can assist in connecting your customers to your social
            cause in a seamless, tangible and easy way through our round up
            technology!
          </Typography>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.primary.light}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            To find out more Click Below!
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
            }}
            href={userRoutes?.contactUs}
          >
            CONTACT US
          </Button>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            To discuss your businesses requirements BOOK A CALL and we will
            discuss your
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center" }}
          >
            unique business needs and connect you with a worthwhile
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center" }}
          >
            social cause that your business can embrace.
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          {/* <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom: 5,
            }}
          >
            BOOK A CALL!
          </Button> */}

          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom: 5,
            }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            BOOK A CALL!
          </Button>
          <PopupModal
            url="https://calendly.com/up4change"
            onModalClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            rootElement={document.getElementById("root")}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Companies;
