import React from "react";

//package import

import { Route, Routes } from "react-router-dom";

// admin import
import AdminRoutes from "./admin/AdminRoutes";
import LoginPage from "app/containers/admin/Login";
import PrivateRoute from "app/Services/admin/Routes/APP/PrivateRoute";
import OrgPrivateRoute from "app/Services/organization/Routes/APP/OrgPrivateRoute";
import { APP_Urls } from "app/Services/admin/Routes/APP";
import { ORGANIZATION_APP_URLS } from "app/Services/organization/Routes/APP";

// ----- organization import  -----
// import { OrgRoutes } from "app/routes/organization/OrgRoutes";
import OrgPrivateRoutePaths from "app/Services/organization/Routes/APP/OrgPrivateRoutePaths";
import OrganizationLogin from "app/containers/organization/OrganizationLogin";
// ----- organization import  -----

// ----- user import  -----
import { userRoutes as UserprivateRoutes } from "app/Services/user/routes/APP";
import UserPrivateRoute from "app/Services/user/routes/APP/UserPrivateRoute";
import UserProtectedRoutes from "app/routes/user/UserProtectedRoutes";
import UserPublicRoutesPaths from "./user/UserPublicRoutesPaths";

// ----- user import  -----

//component

export default function MianRoutes() {
  return (
    <Routes>
      {/* ===== admin ====== */}
      <Route path={`admin/login`} element={<LoginPage />} />
      <Route
        path={`${APP_Urls?.admin}/*`}
        element={<PrivateRoute element={AdminRoutes} />}
      />
      {/* ===== admin ====== */}

      {/* ===== ORGANIZATION ====== */}

      <Route
        // path={ORGANIZATION_APP_URLS?.root}
        path="/org/login"
        caseSensitive
        element={<OrganizationLogin />}
      />
      <Route
        path={`${ORGANIZATION_APP_URLS?.org}/*`}
        element={<OrgPrivateRoute element={OrgPrivateRoutePaths} />}
      />

      {/* ===== ORGANIZATION ====== */}

      {/* ===== user ====== */}

      <Route
        path={`${UserprivateRoutes?.user}/*`}
        element={<UserPrivateRoute element={UserProtectedRoutes} />}
      />
      {UserPublicRoutesPaths?.map((item, index) => (
        <Route
          path={item?.path}
          element={item?.component}
          caseSensitive={item?.exact}
          key={`${index}-user-routes-${item?.id}-${item?.path}`}
        />
      ))}

      {/* ===== user ====== */}

      <Route
        path={`*`}
        element={
          <div
            style={{
              height: "110vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Page Not Found
          </div>
        }
      />
    </Routes>
  );
}
