import React from "react";

//package import

import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

//user impor

import Round1 from "app/svg/user/Round-1";
import LinkRoundUp from "app/svg/user/LinkRoundUp";

//component

const EasySteps = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        padding: 2,
      }}
    >
      <Container maxWidth={"lg"}>
        <Box>
          <Typography
            align="center"
            color={"common.white"}
            fontWeight={"bold"}
            variant="h5"
          >
            You can round up in 4 easy steps!
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <Box padding={1}>
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                  <Box height={"100%"} width={"60%"}>
                    <Round1 width={"100%"} height={"100%"} />
                  </Box>
                </Box>
                <Box>
                  <Typography fontWeight={"bold"} color={"white"} variant="h5">
                    1. Choose a cause you care about.
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant="body1"
                  >
                    Connect with causes you care about through a centralised
                    tailored social feed. Add them to your favourites!
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <Box padding={2}>
                  {/* <img
                    src={"/images/shelterVideo.png"}
                    alt="shleter video"
                    width={"100%"}
                    height={"auto"}
                  /> */}
                  <video
                    width={"100%"}
                    height={"100%"}
                    loop="true"
                    controls="controls"
                    autoplay="autoplay"
                    muted
                  >
                    <source
                      src="/images/up_4_change_-_charitable_round_up_app (1080p) (1).mp4"
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box padding={1}>
                <Box>
                  <Box padding={2} width={"60%"}>
                    <img
                      src={"/images/Coin.png"}
                      alt="Round"
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography fontWeight={"bold"} color={"white"} variant="h5">
                    4. Have a say in where your funds go!
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant="body1"
                  >
                    Select round up limits for each transaction as well as
                    monthly limits. You can also choose a one-off donation.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={false} sm={false} md={2} />
            <Grid item xs={12} sm={12} md={4}>
              <Box padding={1}>
                <Box padding={2} width={"100%"}>
                  <img
                    src={"/images/Round-Up.png"}
                    alt="Round"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
                <Box>
                  <Typography fontWeight={"bold"} color={"white"} variant="h5">
                    2. Tell us what amount you want to round up to.
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant="body1"
                  >
                    Select round up limits for each transaction as well as
                    monthly limits.You can also choose a one-off donation.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box padding={1}>
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                  <Box width={250} height={120}>
                    <LinkRoundUp width={"100%"} height={"100%"} />
                  </Box>
                </Box>
                <Box>
                  <Typography fontWeight={"bold"} color={"white"} variant="h5">
                    3. Securely link your everyday transaction account and spend
                    normally!
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant="body1"
                  >
                    Up 4 Change seamlessly rounds up your transactions in the
                    background to the nearest $1 and automatically donates your
                    spare change to your preferred charity.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={false} sm={false} md={2} />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default EasySteps;
