import { Box } from "@mui/material";
import { memo } from "react";

export const GetImageOrVideo = memo(({ image }) => {
  if (typeof image === "string" && image) {
    const type = image?.split(".").pop();
    switch (type) {
      case "jpeg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={image} alt={"feed post"} />
          </Box>
        );
      case "jpg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={image} alt={"feed post"} />
          </Box>
        );

      case "png":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={image} alt={"feed post"} />
          </Box>
        );

      case "mp4":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={image} controls />
          </Box>
        );

      case "ogg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={image} controls />
          </Box>
        );
      case "webm":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={image} controls />
          </Box>
        );
      default:
        return <></>;
    }
  } else {
    const type = image?.type || "";
    const url = image ? URL.createObjectURL(image) : "";
    switch (type) {
      case "image/jpeg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={url} alt={"feed post"} />
          </Box>
        );
      case "image/jpg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={url} alt={"feed post"} />
          </Box>
        );

      case "image/png":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <img width={"100%"} src={url} alt={"feed post"} />
          </Box>
        );

      case "video/mp4":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={url} controls />
          </Box>
        );

      case "video/ogg":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={url} controls />
          </Box>
        );
      case "video/webm":
        return (
          <Box sx={{ maxHeight: 200, overflowX: "hidden" }}>
            <video width={"100%"} src={url} controls />
          </Box>
        );
      default:
        return <></>;
    }
  }
});

export const RenderFeedVideoOrImage = ({ image, name }) => {
  if (typeof image === "string" && image) {
    const type = image?.split(".").pop();
    switch (type) {
      case "jpeg":
        return (
          <img
            src={image}
            alt={name}
            width="100%"
            // height="auto"
            style={{ maxHeight: "500px", objectFit: "contain" }}
            className="my-4"
          />
        );
      case "jpg":
        return (
          <img
            src={image}
            alt={name}
            width="100%"
            // height="auto"
            style={{ maxHeight: "500px", objectFit: "contain" }}
            className="my-4"
          />
        );

      case "png":
        return (
          <img
            src={image}
            alt={name}
            width="100%"
            // height="auto"
            style={{ maxHeight: "500px", objectFit: "contain" }}
            className="my-4 maxHeight-500px"
          />
        );

      case "mp4":
        return (
          <video
            src={image}
            alt={name}
            width="100%"
            height="auto"
            className="my-4"
            controls
          />
        );

      case "ogg":
        return (
          <video
            src={image}
            alt={name}
            width="100%"
            // height="auto"
            style={{ maxHeight: "500px", objectFit: "contain" }}
            className="my-4"
            controls
          />
        );
      case "webm":
        return (
          <video
            src={image}
            alt={name}
            width="100%"
            // height="auto"
            style={{ maxHeight: "500px", objectFit: "contain" }}
            className="my-4"
            controls
          />
        );
      default:
        return <></>;
    }
  }
};

export const getFirstDayOfNextMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
};

export const checkItemInList = (itemIdToCheck, items) => {
  const itemsArray = items || [];
  const isAlready = itemsArray.find((item) => {
    const currentItem = item?.cause?.id ? item.cause.id : item?.cause;
    if (currentItem === itemIdToCheck) {
      return item;
    }
  });
  return isAlready;
};
