import * as Yup from 'yup';

import { variables } from '../variables';

const { required } = variables;

export const q1validation = Yup.object({
  categories: Yup.array()
    .min(1, 'Please select atleast one category')
    .required(required),
});

export const q2validation = Yup.object({
  sub_categories_with_cat: Yup.array()
    .of(
      Yup.object().shape({
        sub_categories: Yup.array().of(Yup.string().required(required))
          .min(1, 'Please select atleast one sub-category against category')
          .required(required),
      })
    ).compact((v) =>
      v.sub_categories?.length > 0 ? true : false
    ).required(required)
});
