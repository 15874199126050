import React, { useState, useEffect } from "react";

//package import

import PropTypes from "prop-types";
import {
  Slide,
  Fab,
  Box,
  AppBar,
  useScrollTrigger,
  CssBaseline,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { KeyboardArrowUp } from "@mui/icons-material";

//user import

import ScrollTop from "../ScrollTop";
// import { UserTopbar as Topbar, UserSidebar as Sidebar } from '../../components';
import {
  UserTopbar as Topbar,
  UserSidebar as Sidebar,
} from "app/components/user";
import SignUpModal from "../SignUpModal";

//component

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

//component

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = (props) => {
  const { children } = props;

  //hooks

  const theme = useTheme();

  //states

  const [openSidebar, setOpenSidebar] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [anchorRef1, setAnchorRef1] = useState(null);
  const [signUpModal, setSignUpModal] = useState(false);

  //constants

  const open = Boolean(anchorRef);
  const open1 = Boolean(anchorRef1);

  //theme mode

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleClose1 = () => {
    setAnchorRef1(null);

    // setOpen(false);
  };

  const handleToggle1 = (event) => {
    setAnchorRef1(anchorRef ? null : event.currentTarget);
  };

  const handleListKeyDown1 = () => {
    setAnchorRef1(null);
  };

  //life cycle hook

  const handleClose = () => {
    setAnchorRef(null);

    // setOpen(false);
  };

  const handleToggle = (event) => {
    setAnchorRef(anchorRef ? null : event.currentTarget);
  };

  const handleListKeyDown = () => {
    setAnchorRef(null);
  };

  const signUpHanlder = () => {
    setSignUpModal(!signUpModal);
  };

  //render

  return (
    <div>
      <ScrollToTop />
      <CssBaseline />

      <HideOnScroll>
        <AppBar
          position={"fixed"}
          sx={{
            backgroundColor: theme?.palette.secondary.main,
            color: theme?.palette?.common?.white,
          }}
          elevation={0}
        >
          <Box>
            <Topbar
              onSidebarOpen={handleSidebarOpen}
              anchorRef={anchorRef}
              open={open}
              anchorRef1={anchorRef1}
              open1={open1}
              signUpHanlder={signUpHanlder}
              handleToggle={handleToggle}
              handleClose={handleClose}
              handleListKeyDown={handleListKeyDown}
              handleToggle1={handleToggle1}
              handleClose1={handleClose1}
              handleListKeyDown1={handleListKeyDown1}
            />
          </Box>
        </AppBar>
      </HideOnScroll>
      <Box height={100} id="back-to-top-anchor" />
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant="temporary"
        signUpHanlder={signUpHanlder}
        anchorRef={anchorRef}
        acornOpen={open}
        anchorRef1={anchorRef1}
        acornOpen1={open1}
        handleToggle={handleToggle}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
        handleToggle1={handleToggle1}
        handleClose1={handleClose1}
        handleListKeyDown1={handleListKeyDown1}
      />
      <main>{children}</main>

      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      <SignUpModal open={signUpModal} handleClose={signUpHanlder} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
