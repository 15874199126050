import React from "react";

//package import

import {
  IconButton,
  Box,
  Container,
  useTheme,
  Typography,
  Grid,
  Checkbox,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

//user import

import { MotoQ1Icon } from "app/svg/user/Questions";
import { CATEGORIES_ARRAY } from "app/common/user/Enums";
import { q1validation } from "app/Services/user/validations/Questions";

//contants

//form intial values

const initialValues = {
  categories: [],
};

//component

const QuestionOne = ({ increamentStep, setFormData }) => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();

  //move back

  const backHandler = () => {
    navigate(-1);
  };

  //submit handler

  const formSubmitHandler = (values) => {
    setFormData((prev) => ({ ...prev, categories: values.categories }));
    increamentStep();
  };

  //render

  return (
    <Box
      sx={{
        backgroundImage: `url(/images/Board1Ellipse.png)`,
        backgroundSize: "25%",
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
        minHeight: "90vh",
      }}
    >
      <Container>
        <Box marginTop={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box color={theme.palette.common.white}>
                <IconButton color="inherit" onClick={backHandler}>
                  <ArrowBackIcon color="inherit" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Box paddingY={2}>
                <Box>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    align="center"
                    color={"common.white"}
                  >
                    I'm Up 4 Change for causes aligned to
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    fontWeight={"bold"}
                    align="center"
                    color={"common.white"}
                  >
                    Click all that apply
                  </Typography>
                </Box>
                <Formik
                  onSubmit={formSubmitHandler}
                  initialValues={initialValues}
                  validationSchema={q1validation}
                >
                  {({
                    values,
                    handleSubmit,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box>
                        <FormControl
                          component="fieldset"
                          variant="standard"
                          error={
                            touched.categories && Boolean(errors.categories)
                          }
                        >
                          <FormGroup>
                            <Grid container>
                              {CATEGORIES_ARRAY?.map((item, index) => (
                                <>
                                  {item.value == "" ||
                                  item.value == "all" ? null : (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={3}
                                      key={`${item?.id}-question-${index}`}
                                    >
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <Checkbox
                                          name={item.value}
                                          checked={values.categories.includes(
                                            item.value
                                          )}
                                          icon={
                                            <Box
                                              margin={2}
                                              width={170}
                                              borderRadius={2}
                                              // component={Checkbox}
                                              height={120}
                                              display={"flex"}
                                              flexDirection={"column"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              sx={{
                                                backgroundColor:
                                                  theme.palette.common.white,
                                              }}
                                            >
                                              <Box
                                                width={{
                                                  md: 80,
                                                  xs: 100,
                                                }}
                                                height={{
                                                  md: 80,
                                                  xs: 83,
                                                }}
                                                padding={2}
                                              >
                                                <img
                                                  src={item?.url}
                                                  alt={item?.text}
                                                  width={"100%"}
                                                  height={"100%"}
                                                />
                                              </Box>
                                              <Box>
                                                <Typography
                                                  color={"common.black"}
                                                  fontWeight={"bold"}
                                                  align="center"
                                                  variant="body2"
                                                >
                                                  {item?.name}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          }
                                          checkedIcon={
                                            <Box
                                              margin={2}
                                              width={170}
                                              borderRadius={2}
                                              // component={Checkbox}
                                              height={120}
                                              display={"flex"}
                                              flexDirection={"column"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              sx={{
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                              }}
                                            >
                                              <Box
                                                width={{
                                                  md: 80,
                                                  xs: 100,
                                                }}
                                                height={{
                                                  md: 80,
                                                  xs: 83,
                                                }}
                                                padding={2}
                                              >
                                                <img
                                                  src={item?.url}
                                                  alt={item?.text}
                                                  width={"100%"}
                                                  height={"100%"}
                                                />
                                              </Box>
                                              <Box>
                                                <Typography
                                                  color={"common.white"}
                                                  fontWeight={"bold"}
                                                  align="center"
                                                  variant="body2"
                                                >
                                                  {item?.name}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          }
                                          onChange={() => {
                                            if (
                                              values.categories.includes(
                                                item.value
                                              )
                                            ) {
                                              const a =
                                                values?.categories?.filter(
                                                  (cat) => cat !== item.value
                                                );

                                              setFieldValue("categories", a);
                                            } else {
                                              const a = [
                                                ...values.categories,
                                                item.value,
                                              ];
                                              setFieldValue("categories", a);
                                            }
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  )}
                                </>
                              ))}
                            </Grid>
                          </FormGroup>
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                              marginBottom: theme.spacing(1),
                            }}
                          >
                            {touched.categories && errors.categories}
                          </FormHelperText>
                        </FormControl>

                        <Grid container>
                          <Grid item xs={false} sm={false} md={4}></Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              fullWidth
                            >
                              NEXT
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <Box width={"50%"}>
                                <MotoQ1Icon width={"100%"} />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default QuestionOne;
