import { userRoutes } from "app/Services/user/routes/APP";

export const pages = [
  {
    title: "FEED",
    id: "landing-page",
    href: userRoutes?.feed,
    popover: false,
  },
  {
    title: "DISCOVER",
    id: "discover",
    href: userRoutes?.discover,
    popover: false,
  },
  {
    title: "NFP's",
    id: "testing-center",
    href: userRoutes?.nfps,
    popover: true,
    subMenu: [
      {
        title: "- NFP",
        id: "testing-center-nfps",
        href: userRoutes?.nfps,
      },
      {
        title: "-	Authorised Organisations",
        id: "testing-center-Cambridge",
        href: userRoutes?.authOrganisation,
      },
      {
        title: "-	Companies",
        id: "testing-center-Norwich",
        href: userRoutes?.companies,
      },
    ],
  },
  {
    title: "CHANGE MAKERS",
    id: "rapid-verification",
    href: userRoutes?.changeMakers,
    popover: true,
    subMenu: [
      {
        title: "- Changemakers",
        id: "testing-Changemakers",
        href: userRoutes?.changeMakers,
      },
      {
        title: "- Features",
        id: "testing-center-Cambridge",
        href: userRoutes?.features,
      },
      {
        title: "- How do Round Up's work",
        id: "testing-center-Norwich",
        href: userRoutes?.roundUpWork,
      },
    ],
  },
  {
    title: "ABOUT US",
    id: "about-us",
    href: userRoutes?.aboutus,
    popover: false,
  },
  {
    title: "GET IN TOUCH",
    id: "contact-us",
    href: userRoutes?.contactUs,
    popover: false,
  },
  // {
  //   title: "SIGN UP",
  //   id: "sign-up",
  //   href: userRoutes?.help,
  //   popover: false,
  // },
];

export const nfpsRoutes = [
  {
    title: "- NFP",
    id: "testing-center-nfps",
    href: userRoutes?.nfps,
  },
  {
    title: "-	Community organisation",
    id: "testing-center-Cambridge",
    href: userRoutes?.authOrganisation,
  },
  {
    title: "-	Companies",
    id: "testing-center-Norwich",
    href: userRoutes?.companies,
  },
];

export const aboutRoutes = [
  {
    title: "What is Up 4 Change?",
    id: "a1",
    href: "/about-us#What is Up 4 Change?",
  },
  {
    title: "Our Vision",
    id: "a2",
    href: "/about-us#Our Vision",
  },
  {
    title: "What we believe in..",
    id: "a3",
    href: "/about-us#What we believe in..",
  },
  {
    title: "OUR MISSION",
    id: "a4",
    href: "/about-us#OUR MISSION",
  },
  {
    title: "FAQ'S",
    id: "a5",
    href: "/about-us#FAQ",
  },
];

export const changeRoutes = [
  {
    title: "- Changemakers",
    id: "testing-Changemakers",
    href: userRoutes?.changeMakers,
  },
  {
    title: "- Features",
    id: "testing-center-Cambridge",
    href: userRoutes?.features,
  },
  {
    title: "- How do Round Up's work",
    id: "testing-center-Norwich",
    href: userRoutes?.roundUpWork,
  },
];
