import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { useState, useContext, useEffect, useCallback } from "react";
//package import

import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Modal,
  LinearProgress,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { linearProgressClasses } from "@mui/material/LinearProgress";

//user import

import { formatCategory, formatDate } from "app/common/user/functions";

import { headersGet, headersUpdate } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
// import { API_URLS } from "../../services/routes/API";
import jsPDF from "jspdf";
import useMediaQuery from "@mui/material/useMediaQuery";
//constants

import { useNavigate } from "react-router-dom";
import useQuery from "app/utils/user/useQuery";
import { userRoutes } from "app/Services/user/routes/APP";
import { context } from "app/context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 570,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 190,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const styleRoundupPop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 190,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

//component

const CurrentRound = () => {
  //hooks
  const navigate = useNavigate();

  const theme = useTheme();
  const { user } = useContext(context);
  console.log("🚀 ~ CurrentRound ~ user:", user);
  const query = useQuery();
  const jobId = query.get("jobId");

  //states

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertIcon, setAlertIcon] = useState("error");
  const [currentDay] = useState("last_month");
  const [roundup, setRoundup] = useState(null);
  const [nextRoundupDate, setNextRoundupDate] = useState(null);
  const [showRoundupPop, setShowRoundupPop] = useState(
    jobId && jobId !== "null" ? true : false
  );
  const [isLoading, setIsloading] = useState(true);
  const [totalRoundupSoFar, setTotalRoundupSoFar] = useState(0);

  // console.log("====================================");
  // console.log({ roundup });
  // console.log("====================================");

  const startOfMonth = moment().startOf("month").format("DD MMMM, YYYY");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");
  const todayDay = new Date().getDate();
  // console.log("====================================");
  // console.log({ startOfMonth, endOfMonth });
  // console.log("====================================");
  // console.log("====================================");
  // console.log({ todayDay });
  // console.log("====================================");

  const consective7thDates = [];

  for (let i = 1; i <= 5; i++) {
    const nextDate = moment(startOfMonth, "DD MMMM, YYYY")
      .add(i * 7, "days")
      .format("DD MMMM, YYYY");
    consective7thDates.push(nextDate);
    // console.log("====================================");
    // console.log({ nextDate });
    // console.log("====================================");
  }
  useEffect(() => {
    if (todayDay > 1 && todayDay < 8) {
      setNextRoundupDate(consective7thDates[0]);
    } else if (todayDay > 7 && todayDay < 15) {
      setNextRoundupDate(consective7thDates[1]);
    } else if (todayDay > 14 && todayDay < 22) {
      setNextRoundupDate(consective7thDates[2]);
    } else if (todayDay > 21 && todayDay <= 31) {
      setNextRoundupDate(consective7thDates[3]);
    } else {
      setNextRoundupDate(consective7thDates[0]);
    }
  }, [consective7thDates, todayDay]);

  // const getHistory = useCallback(() => {
  //   const data = `page=${page}&limit=${10}&user=${_id}&currentDay=${currentDay}`;

  //   headersGet(`${API_URLS?.userTransactions}?${data}`)
  //     .then((res) => {
  //       const { data, status } = res;
  //       setOpen(false);

  //       if (status === 200) {
  //         const { results } = data || {};
  //         setData(results);
  //       } else {
  //         setAlertOpen(true);
  //         setAlertMsg(res?.data?.message);
  //         setAlertIcon('warning');
  //       }
  //     })
  //     .catch((err) => {
  //       const { response } = err;
  //       setOpen(false);
  //       setAlertOpen(true);
  //       setAlertMsg(response?.data?.message);
  //       setAlertIcon('error');
  //     });
  // }, [page, _id, currentDay]);

  // useEffect(() => {
  //     _id && getHistory()
  // }, [_id, getHistory])

  // useMemo(() => {
  //     if (data.length) {
  //         const amount = data.reduce((prev, current) => {
  //             const {amount} = current || {}
  //             return prev + amount
  //         }, 0)
  //         amount && setTotalAmount(amount)
  //     }
  // }, [data])

  const [open, setOpen1] = useState(false);
  const handleClose = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [open1, setOpen2] = useState(false);
  const handleClose1 = () => setOpen2(false);
  const handleOpen1 = () => setOpen2(true);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Donations History";
    const headers = [
      [
        "DATE",
        "Organisation",
        "CATEGORY",
        "CHARITY NAME",
        "TYPE",
        "DONATION AMOUNT",
      ],
    ];
    console.log({ data });

    const data1 = data.map((elt) => [
      elt.updatedAt,
      elt.organisation.name,
      elt.organisation.category,
      elt.organisation.company_name,
      elt.type,
      elt.amount,
    ]);

    // console.log({ data1 });
    let content = {
      startY: 50,
      head: headers,
      body: data1,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Donations History.pdf");
  };

  const matches = useMediaQuery("(min-width:900px)");
  const matches1 = useMediaQuery("(max-width:899px)");

  const [editOrg, setEditOrg] = useState(null);
  const handleCancelRoundup = async () => {
    if (editOrg?.id) {
      headersUpdate(`${API_URLS?.removeRoundupOrg}`, {
        userId: user?._id,
        orgId: editOrg?.id,
      })
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            setRoundup(res?.data?.roundup);
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("success");
            handleClose();
            handleClose1();
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const handleRoundupEdit = (org, amount) => {
    // console.log("====================================");
    // console.log({ org });
    // console.log("====================================");
    setEditOrg({ ...org?.organisation, amount });
    handleOpen();
  };
  const getUserRoundupDetails = useCallback(async () => {
    setOpen(true);
    if (user) {
      headersGet(`${API_URLS?.getUserRoundup}?userId=${user?._id}`)
        .then((res) => {
          const { data, status } = res;
          setOpen(false);
          setIsloading(false);
          if (status === 200) {
            setRoundup(data?.roundup);
            setTotalRoundupSoFar(data?.totalRoundup);
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setIsloading(false);
          if (response?.status === 401) {
            // removeToken()
            window.location.href = "/login";
          }
          // setAlertOpen(true)
          // setAlertMsg(response?.data?.message)
          // setAlertIcon('error')
        });
    }
  }, [user]);

  useEffect(() => {
    getUserRoundupDetails();
  }, [getUserRoundupDetails, user]);

  const totalRoundup = roundup?.organisations?.reduce((acc, org) => {
    return acc + org?.roundup?.amount;
  }, 0);

  //render

  return (
    <Box
      bgcolor={theme.palette.info.light}
      // padding={2}
      paddingTop={{ xs: "30px", sm: "30px", md: "80px", lg: "80px" }}
      minHeight={"100vh"}
    >
      {matches && (
        <Container>
          <Typography color={"text.red"} gutterBottom paddingBottom={2}>
            My RoundUps
          </Typography>

          <Paper
            sx={{
              padding: 2,
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box>
                <Typography
                  color={"#000"}
                  fontSize={"15px"}
                  fontWeight={"700"}
                  paddingTop={"50px"}
                >
                  Total Rounded Up this Week
                </Typography>
              </Box>
              <Box>
                <Typography
                  color={"text.red"}
                  fontSize={"40px"}
                  fontWeight={"700"}
                >
                  ${totalRoundup ? parseFloat(totalRoundup)?.toFixed(2) : 0}
                </Typography>
              </Box>
              <Box>
                <Typography color={"#000"} fontSize={"10px"} fontWeight={"500"}>
                  Your next roundup will process on{" "}
                  <strong>
                    {nextRoundupDate}
                    {/* {moment()
                                        .add(1, 'M')
                                        .startOf('month')
                                        .format('DD MMMM, YYYY')} */}
                  </strong>
                  {/* {getFirstDayOfNextMonth()} */}
                  {/* {JSON.stringify(JSON.stringify(
                                   
                                        date.getFullYear(),
                                        date.getMonth() + 1,
                                        1
                                    
                                ))} */}
                </Typography>
              </Box>
            </Box>
            {totalRoundupSoFar > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Typography>Total Round Up so far</Typography>
                  <Typography
                    color={"text.red"}
                    fontSize={"20px"}
                    fontWeight={"700"}
                  >
                    ${totalRoundupSoFar?.toFixed(2)}
                  </Typography>
                </Box>
                <Box>
                  <LinearProgress
                    variant="determinate"
                    sx={{
                      height: {
                        xs: "7px",
                        sm: "7px",
                        md: "17px",
                        lg: "17px",
                      },
                      borderRadius: 5,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor:
                          theme.palette.grey[
                            theme.palette.mode === "light" ? 200 : 800
                          ],
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? theme.palette.primary.main
                            : theme.palette.primary.main,
                      },
                    }}
                    value={100}
                  />
                </Box>
              </>
            )}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography
                color={"#000"}
                fontSize={"12px"}
                fontWeight={"700"}
                lineHeight={"24px"}
                paddingTop={"30px"}
              >
                Charities included in next round-up
              </Typography>
            </Box>

            <TableContainer sx={{ marginY: 2 }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell></TableCell> */}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {roundup &&
                    roundup?.organisations?.length > 0 &&
                    roundup?.organisations?.map((row, index) => {
                      const { name, category, organisation_image } =
                        row?.organisation || {};
                      return (
                        <TableRow key={`${index}-${row?.organisationId}`}>
                          <TableCell align="center">
                            {row?.roundup?.dateOfJoin &&
                              formatDate(row?.roundup?.dateOfJoin)}
                          </TableCell>

                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              <img
                                src={organisation_image}
                                width={"50px"}
                                height={"50px"}
                                alt="org-imge"
                                style={{
                                  border: "2px solid #FF9776",
                                  borderRadius: "50px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                {name}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {row?.cause?.title || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {formatCategory(category)}
                          </TableCell>

                          <TableCell align="center">
                            ${(row?.roundup?.amount).toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              onClick={() =>
                                handleRoundupEdit(row, row?.roundup?.amount)
                              }
                            >
                              View/Edit
                            </div>
                            <Button
                              onClick={() => {
                                navigate(
                                  userRoutes?.user +
                                    userRoutes?.basiqTransactionHistory
                                );
                              }}
                            >
                              View Transactions
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {isLoading && (
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                    colSpan={5}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    ${totalAmount}
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              paddingY={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  window.location.href = "/discover";
                }}
                style={{ padding: "7px" }}
              >
                Add Another RoundUp
              </Button>
            </Box>
            {open && (
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box textAlign={"right"}>
                    <img
                      src="/images/closepopup.svg"
                      width={"12px"}
                      onClick={handleClose}
                      alt={"close"}
                    />
                  </Box>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "40px",
                      paddingTop: "10px",
                    }}
                  >
                    View/Edit Round Up
                  </Typography>
                  <Box
                    sx={{
                      width: "90px",
                      position: "relative",
                      borderBottom: "1px solid #000",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  ></Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    {editOrg?.organisation_image ? (
                      <img
                        src={editOrg?.organisation_image}
                        alt="edit-org-img"
                        width={"75px"}
                        height={"75px"}
                        style={{
                          border: "2px solid #FF9776",
                          borderRadius: "50px",
                        }}
                      />
                    ) : null}
                  </Box>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "40px",
                      paddingTop: "10px",
                    }}
                  >
                    {editOrg?.name || "N/A"}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "13px",
                      paddingTop: "10px",
                      color: "#FF9776",
                      textDecoration: "underline",
                    }}
                  >
                    Category
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    {editOrg?.category || "N/A"}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "13px",
                      paddingTop: "10px",
                      color: "#FF9776",
                      textDecoration: "underline",
                    }}
                  >
                    {editOrg?.cause || "N/A"}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    {editOrg?.company_name || "N/A"}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "13px",
                      paddingTop: "10px",
                      color: "#FF9776",
                      textDecoration: "underline",
                    }}
                  >
                    Payment Type
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    RoundUp
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "13px",
                      paddingTop: "10px",
                      color: "#FF9776",
                      textDecoration: "underline",
                    }}
                  >
                    RoundUp Amount
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    ${editOrg?.amount}
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-evenly"}
                    paddingTop={"40px"}
                  >
                    <Box>
                      <Button
                        onClick={() => handleOpen1()}
                        variant="contained"
                        style={{ padding: "12px" }}
                      >
                        Cancel Round Up
                      </Button>
                    </Box>

                    {/* <Box>
                      <Button
                        variant="contained"
                        style={{
                          padding: "9px",
                          lineHeight: "15px",
                          fontSize: "13px",
                        }}
                      >
                        'SNOOZE ROUND UP’S <br></br>for 30 days?
                      </Button>
                    </Box> */}
                  </Box>
                </Box>
              </Modal>
            )}
            {open1 && (
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <Box textAlign={"right"}>
                    <img
                      src="/images/closepopup.svg"
                      width={"12px"}
                      onClick={handleClose1}
                      alt={"close"}
                    />
                  </Box>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      paddingTop: "20px",
                    }}
                  >
                    Cancel Round Up
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-evenly"}
                    paddingTop={"40px"}
                  >
                    <Box>
                      <Button variant="contained" onClick={handleCancelRoundup}>
                        Confirm
                      </Button>
                    </Box>

                    <Box>
                      <Button onClick={handleClose1} variant="contained">
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
            {showRoundupPop && (
              <Modal
                open={showRoundupPop}
                onClose={() => setShowRoundupPop(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{ ...styleRoundupPop, width: "36%", margin: "0 auto" }}
                >
                  {/* <Box textAlign={'right'}>
                                    <img
                                        src='/images/closepopup.svg'
                                        width={'12px'}
                                        onClick={() => setShowRoundupPop(false)}
                                        alt={'close'}
                                    />
                                </Box> */}
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "23px",
                      paddingTop: "20px",
                    }}
                  >
                    Congratulations!
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "15px",
                      paddingTop: "20px",
                    }}
                  >
                    You have successfuly setup the roundup
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-evenly"}
                    paddingTop={"40px"}
                  >
                    <Box>
                      <Button
                        onClick={() => setShowRoundupPop(false)}
                        variant="contained"
                      >
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </Paper>
          {/* <Box paddingY={2} display={'flex'} justifyContent={'center'}>
                    <Pagination
                        count={totalPages}
                        color='primary'
                        page={page}
                        variant='contained'
                        sx={{
                            [`& .${paginationItemClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                                ':hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },
                            [`& .${buttonClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                            },
                        }}
                        onChange={handleChangePage}
                    />
                </Box> */}
        </Container>
      )}

      {matches1 && (
        <Box
          bgcolor={theme.palette.info.light}
          padding={0}
          paddingTop={10}
          minHeight={"100vh"}
        >
          <Container style={{ width: "368px", padding: "0px" }}>
            <Paper
              sx={{
                padding: 2,
                borderRadius: "0px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Box>
                  <Typography
                    color={"#000"}
                    fontSize={"15px"}
                    fontWeight={"700"}
                    paddingTop={"50px"}
                  >
                    Total Rounded Up this Week
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={"text.red"}
                    fontSize={"40px"}
                    fontWeight={"700"}
                  >
                    ${parseFloat(totalRoundup)?.toFixed(2)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={"#000"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    paddingBottom={"14px"}
                    textAlign={"center"}
                  >
                    Your next roundup will process on <br></br>
                    <strong>{nextRoundupDate}</strong>
                  </Typography>
                </Box>
              </Box>
              {roundup &&
                roundup?.organisations?.length > 0 &&
                roundup?.organisations?.map((row, index) => {
                  const { name, category, organisation_image } =
                    row?.organisation || {};
                  return (
                    <ul
                      style={{
                        listStyleType: "none",
                        borderBottom: "1px solid #c4c4c4",
                        paddingInlineStart: "0px",
                      }}
                    >
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              marginRight: "13px",
                            }}
                          >
                            <img
                              src={organisation_image}
                              alt="organization-img"
                              style={{
                                width: "44px",
                                height: "44px",
                                border: "1px solid #ff9776",
                                borderRadius: "50%",
                              }}
                            ></img>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              fontFamily: "Circular Std",
                              fontWeight: "500",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#666666",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                color: "#000",
                                fontWeight: "700",
                              }}
                            >
                              {name}
                            </div>
                            <div>{formatCategory(category)}</div>
                            <div></div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Circular Std",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#666666",
                            // marginLeft: "15px",
                            flexDirection: "column",
                          }}
                        >
                          <div>${row?.roundup?.amount}</div>

                          <div
                            onClick={() =>
                              handleRoundupEdit(row, row?.roundup?.amount)
                            }
                            style={{
                              cursor: "pointer",
                              color: "#ff9776",
                              fontWeight: "700",
                            }}
                          >
                            View/Edit
                          </div>
                        </div>
                      </li>
                    </ul>
                  );
                })}

              <Box
                paddingY={3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    window.location.href = "/discover";
                  }}
                  style={{ padding: "7px" }}
                >
                  Add Another RoundUp
                </Button>
              </Box>
            </Paper>
          </Container>
        </Box>
      )}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>

    // <Box
    //   sx={{
    //     minHeight: "100vh",
    //     backgroundColor: theme.palette.info.light,
    //     paddingTop: 7,
    //   }}
    // >
    //  <Container maxWidth={"md"}>
    //     <Typography
    //       color={"primary.main"}
    //       fontWeight={"bold"}
    //       paddingBottom={2}
    //     >
    //       My Account - Payments Info
    //     </Typography>
    //     <Paper
    //       sx={{
    //         padding: 2,
    //       }}
    //     >
    //       <Typography align="center" fontWeight={"bold"}>
    //         Current Round Up
    //       </Typography>
    //       <Box
    //         display={"flex"}
    //         justifyContent={"center"}
    //         flexDirection={"column"}
    //         alignItems={"center"}
    //         paddingY={2}
    //       >
    //         <Box width={120} height={120}>
    //           <CardIcon />
    //         </Box>

    //         <Box display={"flex"} justifyContent={"center"}>
    //           <Typography fontWeight={"bold"} align="center" paddingY={1}>
    //             Card Ending in: XXXX XXXX XXXX {account?.number?.slice(-4)}
    //           </Typography>
    //           <Box width={20}>
    //             <EditIcon />
    //           </Box>
    //         </Box>
    //         <Box marginTop={2}>
    //           <Button variant="contained" onClick={roundHandler}>
    //             VIEW ROUNDUP
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Paper>
    //     <Box
    //       display={"flex"}
    //       justifyContent={"flex-end"}
    //       flexDirection={"column"}
    //       alignItems={"flex-end"}
    //     >
    //       <Box paddingY={1}>
    //         <Button
    //           variant="contained"
    //           startIcon={<AddIcon />}
    //           onClick={handleCardOpen}
    //         >
    //           Add Another Card
    //         </Button>
    //       </Box>
    //       <Box paddingY={1}>
    //         <Button
    //           variant="contained"
    //           startIcon={<AddIcon />}
    //           onClick={handleCardOpen}
    //         >
    //           Add Bank Account
    //         </Button>
    //       </Box>
    //     </Box>
    //   </Container>

    //   <CardModal open={openCardLink} handleClose={handleCardClose} />

    //   <Backdrop
    //     sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //     open={loader}
    //   >
    //     <CircularProgress color="inherit" />
    //   </Backdrop>
    //   <Snackbar
    //     open={alertOpen}
    //     autoHideDuration={6000}
    //     onClose={alertCloseHandler}
    //     anchorOrigin={{
    //       horizontal: "center",
    //       vertical: "top",
    //     }}
    //   >
    //     <Alert
    //       onClose={alertCloseHandler}
    //       severity={alertIcon}
    //       sx={{ width: "100%" }}
    //     >
    //       {alertMsg}
    //     </Alert>
    //   </Snackbar>
    // </Box>
  );
};

export default CurrentRound;
