import React, { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import { RiShareForwardLine } from "react-icons/ri";
import { FaRegCommentAlt, FaSearch, FaFilter } from "react-icons/fa";
//user imports
import "./content.css";
import FeedArea2 from "./FeedArea2";
import { simpleGet, headersDel } from "app/Services/admin/API";
import { API_Urls } from "app/Services/admin/Routes/API";
import { Toast } from "app/common/admin/alert";
import { CATEGORIES_ARRAY } from "app/common/admin/enum";

export default function ContentManagement() {
  const feedData = [
    {
      organisationName: "Organisation Name",
      cause: "Cause of the Organisation",
      time: "2h ago",
      logo: "/images/organ-logo.svg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna neque tincidunt pharetra ullamcorper et sapien. Arcu maecenas consectetur ipsum at egestas. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit.",
      video: "/images/videoplayback.mp4",
      likes: "233",
      comment: "46",
    },
    {
      organisationName: "Organisation Name",
      cause: "Cause of the Organisation",
      time: "2h ago",
      logo: "/images/organ-logo.svg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna neque tincidunt pharetra ullamcorper et sapien. Arcu maecenas consectetur ipsum at egestas. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit.",
      video: "/images/videoplayback.mp4",
      likes: "233",
      comment: "46",
    },
    {
      organisationName: "Organisation Name",
      cause: "Cause of the Organisation",
      time: "2h ago",
      logo: "/images/organ-logo.svg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna neque tincidunt pharetra ullamcorper et sapien. Arcu maecenas consectetur ipsum at egestas. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit. Ac faucibus leo maecenas massa cursus in eu et pretium. Volutpat, egestas tellus feugiat eget sit.",
      video: "/images/videoplayback.mp4",
      likes: "233",
      comment: "46",
    },
  ];

  const [feeds, setFeeds] = useState([]);
  const [feedsAll, setFeedsAll] = useState([]);
  const [api, setApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value?.length > 3) {
      setOpen(true);
      setApi(true);
      simpleGet(`${API_Urls?.query_feeds}?search=${e.target.value}`)
        .then((res) => {
          console.log({ res });
          setFeeds(res?.data?.feeds?.results);
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          Toast.error(response?.data?.message);
        });
    } else {
      setFeeds(feedsAll);
    }
  };

  const handleDelete = (feedId) => {
    console.log({ feedId });
    const confirmDel = window.confirm(`Are you sure to delete this feed?`);
    if (feedId && confirmDel) {
      setOpen(true);
      setApi(true);

      headersDel(`${API_Urls?.del_feed}?feedId=${feedId}`)
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            Toast.success(res?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            Toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          Toast.error(response?.data?.message);
        });
    }
  };

  const getAllFeeds = async () => {
    setOpen(true);
    setApi(false);
    simpleGet(`${API_Urls?.get_all_feeds}?page=1&limit=999999`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          console.log("====================================");
          console.log({ Feeds: res?.data?.feeds?.reverse() });
          console.log("====================================");
          setFeeds(res?.data?.feeds?.reverse());
          setFeedsAll(res?.data?.feeds?.reverse());
        } else {
          Toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message);
      });
  };

  useEffect(() => {
    getAllFeeds();
  }, []);

  // const feedArea = (feed, index) => {
  //     return (
  //         <div className='feed-box mb-3' key={index}>
  //             <div className='options'>
  //                 <div className='organisation-info'>
  //                     <div className='organisation-data'>
  //                         <div className='feed-logo-div'>
  //                             <img src={feed.logo} alt='organisation-logo' />
  //                         </div>
  //                         <div className='organisation-name'>
  //                             <p className='main-name'>
  //                                 {feed.organisationName}
  //                             </p>
  //                             <p className='main-cause'>{feed.cause}</p>
  //                             <p className='main-time'>{feed.time}</p>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className='option-view'>
  //                     <div className='dropdown'>
  //                         <button
  //                             type='button'
  //                             className='btn'
  //                             data-toggle='dropdown'
  //                         >
  //                             <BsThreeDots className='color-grey' />
  //                         </button>
  //                         <div className='dropdown-menu dropdown-menu-right'>
  //                             <a
  //                                 className='dropdown-item post'
  //                                 href='edit-post'
  //                             >
  //                                 EDIT POST
  //                             </a>
  //                             <hr className='my-2' />
  //                             <a
  //                                 className='dropdown-item post'
  //                                 href='delete-post'
  //                             >
  //                                 DELETE POST
  //                             </a>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className='feed-description'>
  //                 <p>{feed.description}</p>
  //             </div>

  //             <video
  //                 src={feed.video}
  //                 width='100%'
  //                 height='400'
  //                 controls
  //                 className='my-4'
  //             ></video>

  //             <div className='likes-comments'>
  //                 <div className='buttons'>
  //                     <button className='btn btn-darkBlue'>
  //                         <AiOutlineLike />
  //                         &nbsp; Like &nbsp; <span>{feed.likes}</span>
  //                     </button>
  //                     <button className='btn btn-darkBlue mx-3'>
  //                         <FaRegCommentAlt /> &nbsp;Comment &nbsp;
  //                         <span>{feed.comment}</span>
  //                     </button>
  //                     <button className='btn btn-darkBlue'>
  //                         <RiShareForwardLine /> &nbsp;Share
  //                     </button>
  //                 </div>
  //             </div>
  //         </div>
  //     )
  // }
  // const feedArea2 = (feed, index) => {
  //     let isVideo = false
  //     let media = null
  //     if (feed?.image?.length > 0) {
  //         media = feed?.image[0]
  //         let splitted = media.split('.')
  //         let ext = splitted[splitted.length - 1]
  //         console.log({ext})
  //         if (ext === 'mp4') isVideo = true
  //     }
  //     return (
  //         <div className='feed-box mb-3' key={feed?.id}>
  //             <div className='options'>
  //                 <div className='organisation-info'>
  //                     <div className='organisation-data'>
  //                         <div className='feed-logo-div'>
  //                             {feed?.organisation?.organisation_image
  //                                 ?.length > 0 && (
  //                                 <img
  //                                     src={
  //                                         feed?.organisation
  //                                             ?.organisation_image[0]
  //                                     }
  //                                     alt='organisation-logo'
  //                                 />
  //                             )}
  //                         </div>
  //                         <div className='organisation-name'>
  //                             <p className='main-name'>
  //                                 {feed?.title || 'N/A'}
  //                             </p>
  //                             <p className='main-cause'>
  //                                 <span>
  //                                     {feed?.organisation?.category || 'N/A'}
  //                                 </span>
  //                                 <span>{' | '}</span>
  //                                 <span>
  //                                     {feed?.organisation?.cause || 'N/A'}
  //                                 </span>
  //                             </p>
  //                             {moment}
  //                             <p className='main-time'>
  //                                 {moment(feed?.createdAt)?.fromNow(true)} ago
  //                             </p>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className='option-view'>
  //                     <div className='dropdown'>
  //                         <button
  //                             type='button'
  //                             className='btn'
  //                             data-toggle='dropdown'
  //                         >
  //                             <BsThreeDots className='color-grey' />
  //                         </button>
  //                         <div className='dropdown-menu dropdown-menu-right'>
  //                             <a
  //                                 className='dropdown-item post'
  //                                 href='edit-post'
  //                             >
  //                                 EDIT POST
  //                             </a>
  //                             <hr className='my-2' />
  //                             <div
  //                                 className='dropdown-item post'
  //                                 onClick={() => handleDelete(feed?.id)}
  //                             >
  //                                 DELETE POST
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className='feed-description'>
  //                 <p>{feed?.description}</p>
  //             </div>

  //             {media && isVideo && (
  //                 <video
  //                     src={media}
  //                     width='100%'
  //                     height='400'
  //                     controls
  //                     className='my-4'
  //                 ></video>
  //             )}

  //             {media && !isVideo && (
  //                 <div
  //                     className='feed-img-wrapper'
  //                     style={{
  //                         backgroundImage: `url(${media})`,
  //                         height: '400px',
  //                     }}
  //                 ></div>
  //             )}

  //             <div className='likes-comments'>
  //                 <div className='buttons'>
  //                     {/* <button className='btn btn-darkBlue'>
  //                         <AiOutlineLike />
  //                         &nbsp; Like &nbsp; <span>{feed.likes}</span>
  //                     </button>
  //                     <button className='btn btn-darkBlue mx-3'>
  //                         <FaRegCommentAlt /> &nbsp;Comment &nbsp;
  //                         <span>{feed.comment}</span>
  //                     </button>
  //                     <button className='btn btn-darkBlue'>
  //                         <RiShareForwardLine /> &nbsp;Share
  //                     </button> */}
  //                 </div>
  //             </div>
  //         </div>
  //     )
  // }

  return (
    <div className="container">
      <div className="row justify-content-center my-5">
        <div className="col-lg-8">
          <div className="content-search">
            <div className="search-icon">
              <p>
                <FaSearch />
              </p>
              <input
                type="search"
                placeholder="SEARCH"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <FaFilter className="color-grey" />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          {/* <div className='feeds'>{feedData.map(feedArea)}</div> */}
          <div className="feeds">
            {feeds?.map((feed, index) => {
              return (
                <FeedArea2
                  key={index}
                  feed={feed}
                  handleDelete={handleDelete}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
