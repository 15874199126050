export const APP_Urls = {
  root: "/",
  login: "/",
  admin: "/admin",
};

export const ADMIN_URLS = {
  ROOT: "/",
  USER_MANAGEMENT: `/user-management`,
  ORG_MANAGEMENT: `/organisation-management`,
  CONTENT_MANAGEMENT: `/content-management`,
  DONATION: `/donation`,
  ORG_BILLING_WITH_ID: "/organisation-billing/:id",
  ORG_BILLING: "/organisation-billing",
};
