import React from 'react';

//packages imports

import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//component

const SuccessModal = ({ show, handleClose }) => {
  //render

  return (
    <Modal show={show} onHide={handleClose} size='md'>
      <Modal.Header>
        <div className='d-flex justify-content-end'>
          <Link to='#/' onClick={handleClose}>
            <img
              src='/images/close.svg'
              alt='close'
              style={{ height: '15px' }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-body-md pb-3'>
          <div className='col-lg-12'>
            <div className='m-organ text-center'>
              <img src='/images/done.svg' alt='done' />
              <p className='m-fname'>Payment Method Added!</p>
              <div className='done'>
                <img src='/images/done-avatar.svg' alt='done-avatar' />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
