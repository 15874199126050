import { initializeApp } from "firebase/app";
import {
  signInWithPopup,
  GoogleAuthProvider,
  getAuth,
  signOut,
  FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Sign in with google
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log({ googleRes: res });
    console.log({ googleUser: user });
    return res;
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
  } catch (error) {
    const code = error?.code;
    console.log(error, code);
    alert(customSocialMessages(code));
  }
};

// Sign in with facebook
const facebookProvider = new FacebookAuthProvider();
const signInWithFacebook = async () => {
  try {
    const response = await signInWithPopup(auth, facebookProvider);
    console.log({ facebookResponse: response });
    return response;
  } catch (error) {
    const code = error?.code;
    console.log(error, code);
    alert(customSocialMessages(code));
    throw new Error(error);
  }
};

// logout
const logoutSocial = () => {
  signOut(auth);
};

const customSocialMessages = (code) => {
  switch (code) {
    case "auth/popup-closed-by-user":
      return "Popup closed by user";
    case "auth/account-exists-with-different-credential":
      return "This account exists with different account. Please login with that account";
    default:
      return code;
  }
};

export { auth, db, logoutSocial, signInWithFacebook, signInWithGoogle };
