import React from "react";

//user import

import { Layout } from "app/components/user";
import RoundUpWorkPage from "app/components/user/RoundUpWork";

//component

const RoundUpWork = () => {
  //render

  return (
    <Layout>
      <RoundUpWorkPage />
    </Layout>
  );
};

export default RoundUpWork;
