import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//component

const Vision = () => {
  //hooks

  const theme = useTheme();

  //render
  return (
    <div id="Our Vision">
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(/images/VisionEllipse.png)`,
        backgroundSize: "18%",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        padding: theme.spacing(6, 2, 2, 2),
      }}
    >
      <Typography
        align="center"
        variant="h3"
        color={"common.white"}
        fontWeight={"bold"}
      >
        Our Vision
      </Typography>

      <Box display={"flex"} justifyContent={"center"} padding={2}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.common.white}`,
            width: "10%",
          }}
        ></Box>
      </Box>
      <Container maxWidth={"md"}>
        <Box paddingY={2}>
          <Typography
            fontWeight={"bold"}
            align="center"
            color={"common.white"}
            variant="h6"
          >
            Our vision is a world where everyday people come together to create
            a global impact..
          </Typography>
        </Box>
        <Box paddingY={2}>
          <img
            src={"/images/VisionLink.png"}
            alt="VisionLink"
            width={"100%"}
            height={"auto"}
          />
        </Box>
      </Container>
    </Box>
    </div>
  );
};

export default Vision;
