import React, { Fragment, useState } from "react";

//packages import

import { Form, Row, Container, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";

//user import

import { ORGANIZATION_APP_URLS } from "app/Services/organization/Routes/APP";

import { API_URLS } from "app/Services/organization/Routes/API";
import { simplePost } from "app/Services/organization/API";
import { StorageKeys } from "app/Services/organization/StorageKeys";
import { addvalidation } from "app/Services/organization/validations/login";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Box, Typography, TextField } from "@mui/material";
import Up4ChangeLogo from "app/assets/organization/svg/Up4changeIcon";
import bgLogo from "app/assets/organization/images/bgup4change.png";

//constants

const initialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

//component

const LoginPage = () => {
  //hooks

  const navigate = useNavigate();

  //states
  const [checked, setChecked] = useState(true);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [showPassword, setShowPassword] = useState(false);

  //form handler

  const submitHandler = (values) => {
    const data = {
      email: values?.email,
      password: values?.password,
    };
    setOpen(true);

    simplePost(API_URLS?.login, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          localStorage.setItem(
            StorageKeys?.org_info,
            JSON.stringify(res?.data?.user)
          );
          if (res?.data?.user?.isBank) {
            window.location.href = "/org/";
            // navigate(
            //   // ORGANIZATION_APP_URLS?.org + ORGANIZATION_APP_URLS?.payment
            //   // ORGANIZATION_APP_URLS?.org
            // );
          } else {
            // navigate(ORGANIZATION_APP_URLS?.org);
            window.location.href = "/org/";
          }
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <div className="login-bg">
      <div className="position-relative ">
        <div style={{ position: "absolute", width: "20vw", height: "13.5vw" }}>
          <img
            src={bgLogo}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </div>

        <div className="position-absolute mt-3 ml-3">
          <Up4ChangeLogo textColor={"white"} />
        </div>
      </div>
      <div className="w-100" style={{ paddingTop: "18vh" }}>
        <div className="d-flex justify-content-center flex-column ">
          <div className="d-flex justify-content-center flex-column ">
            <div className={"text-left"}>
              <div className="mt-3">
                <Typography
                  color={"common.white"}
                  variant="h6"
                  align="center"
                  sx={{
                    marginTop: 5,
                  }}
                >
                  ORGANIZATION
                </Typography>
                <Typography
                  color={"common.white"}
                  variant="h4"
                  align="center"
                  sx={{
                    marginTop: 1,
                  }}
                >
                  SIGN IN
                </Typography>
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                  <Box
                    sx={{
                      border: `1px solid #FFD16B`,
                      width: "10%",
                    }}
                  ></Box>
                </Box>
              </div>
              <div className="mx-auto mt-4 col-11 col-md-5">
                <Formik
                  initialValues={initialValues}
                  onSubmit={submitHandler}
                  validationSchema={addvalidation}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-3"
                        controlId="validationCustom01"
                      >
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                          className="mb-2"
                        >
                          Enter Email
                        </Typography>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          color=""
                          type="email"
                          placeholder="Enter yours email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          sx={{
                            "& input::placeholder": {
                              color: "white",
                              fontSize: "17px",
                            },
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "& input": {
                              color: "white",
                            },
                            boxShadow: "0px 1px 3px 0px #e6ebf1",
                          }}
                        />

                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3 mt-3"
                        controlId="validationCustom02"
                      >
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                          className="mb-2 mt-4"
                        >
                          Password
                        </Typography>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          color=""
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          sx={{
                            "& input::placeholder": {
                              color: "white",
                              fontSize: "17px",
                            },
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "& input": {
                              color: "white",
                            },
                            boxShadow: "0px 1px 3px 0px #e6ebf1",
                          }}
                          InputProps={{
                            endAdornment: showPassword ? (
                              <Visibility
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{ color: "white" }}
                              />
                            ) : (
                              <VisibilityOff
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{ color: "white" }}
                              />
                            ),
                          }}
                        />

                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className=" mb-3">
                        {/* <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={
                                                                handleChecked
                                                            }
                                                            inputProps={{
                                                                'aria-label':
                                                                    'controlled',
                                                            }}
                                                        />
                                                    }
                                                    sx={{fontSize: '13px'}}
                                                    label='Remember me'
                                                /> */}

                        {/* <Form.Check
                                                    type='checkbox'
                                                    className={'label-left'}
                                                    label='remember me'
                                                    // value={values?.rememberMe}
                                                    checked={values?.rememberMe}
                                                    onChange={handleChange}
                                                /> */}
                      </Form.Group>
                      <div className="d-flex justify-content-center w-100">
                        <Button
                          variant="primary"
                          type={open ? "button" : "submit"}
                          disabled={open}
                          className="btn-block w-50 h6"
                          style={{
                            backgroundColor: "#FF9776",
                            border: "none",
                            height: "48px",
                            marginTop: "40px",
                          }}
                          onMouseOver={(e) => {
                            e.target.style.backgroundColor = "#66C9D2";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.backgroundColor = "#FF9776";
                          }}
                        >
                          {open ? (
                            <Fragment>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              <span className="visually-hidden">
                                Loading...
                              </span>{" "}
                            </Fragment>
                          ) : (
                            "Sign In"
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={alertCloseHandler}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
          >
            <Alert
              onClose={alertCloseHandler}
              severity={alertIcon}
              sx={{ width: "100%" }}
            >
              {alertMsg}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
