import React, { useCallback, useEffect, useState } from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";

//user import

import CardItem from "./CardItem";

import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { userRoutes } from "app/Services/user/routes/APP";
import { API_URLS } from "app/Services/user/routes/API";
import { simpleGet } from "app/Services/user/API";

//constants

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

//component

const SocialPlateform = () => {
  //hooks

  const theme = useTheme();

  const [api, setApi] = useState(true);
  const [data, setData] = useState([]);
  console.log("🚀 ~ SocialPlateform ~ data:", data);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  const getAllFeeds = useCallback(() => {
    setApi(false);
    let url = `${API_URLS?.getAllFeeds}`;
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${5}${
    //     category && category !== 'all' ? `&category=${category}` : ''
    // }${search && `&name=${search}`}`
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${1}${
    //     category && category !== 'all' ? `&category=${category}` : ''
    // }${search && `&name=${search}`}`
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${10}&category=${category}${search && `&name=${search}`
    //   }`;

    // console.log({
    //   condition: category !== "all" ? category : "",
    // });

    setOpen(true);
    simpleGet(url)
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          // if (res?.data?.results?.length === 0) {
          //   setHasMore(false);
          // } else {
          setData(res?.data?.results);
          // }
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
          setData([]);
        }
      })
      .catch((err) => {
        const { response } = err;
        // setAlertOpen(true)
        // setAlertMsg(response?.data?.message)
        // setAlertIcon('error')
        setData([]);
      });
  }, []);

  useEffect(() => {
    api && getAllFeeds();
  }, [api, getAllFeeds]);

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: theme.spacing(2),
      }}
    >
      <Container maxWidth={"md"}>
        <Typography
          align="center"
          color={"common.black"}
          fontWeight={"bold"}
          variant="h5"
        >
          {/* The first social platform for Not-for-Profits */}
          Stay connected with causes you care about through the interactive
          social feed
        </Typography>
        <Box display={"flex"} justifyContent={"center"} marginY={2}>
          <Box
            sx={{
              borderBottom: `2px solid ${theme.palette.common.black}`,
              width: "20%",
            }}
          ></Box>
        </Box>
        <Box className="custom-arrow-bg-white">
          <Carousel responsive={responsive}>
            {/* {[...Array(4)].map(() => (
              <Box marginX={6}>
                <CardItem />
              </Box>
            ))} */}
            {data &&
              data?.slice(0, 5).map((org, index) => (
                <Box marginX={6}>
                  <CardItem org={org} />
                </Box>
              ))}
          </Carousel>
        </Box>
        <Typography color="primary.light" padding={3} align="center">
          Through a centralised social feed charities can tell you all about the
          great work they are doing and exactly how your change is making a
          difference. It's so easy to find and connect with charities that align
          with your values by telling us what you care about, adding causes to
          your favourites and then supporting them through microdonations.
        </Typography>
      </Container>
    </Box>
  );
};

export default SocialPlateform;
