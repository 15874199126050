import React, { useContext, useState } from "react";

//package import

import {
  Box,
  Grid,
  Typography,
  useTheme,
  LinearProgress,
  Container,
  Button,
  Alert,
  Snackbar,
  Avatar,
  Modal,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { linearProgressClasses } from "@mui/material/LinearProgress";

//user import
import Goal1Icon from "app/svg/admin/orgProfile/Goal1";

import LoveIcon from "app/svg/admin/orgProfile/LoveIcon";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SupportIcon from "app/svg/admin/orgProfile/Support";
// import { userRoutes } from "../../services/routes/APP";
import VerifiedIcon from "app/svg/admin/orgProfile/VerifiedIcon";
// import { isLoggedIn } from "../../services/routes/APP/PrivateRoute";
// import { headersPost } from "../../services/API";
// import { API_URLS } from "../../services/routes/API";
// import { context } from "../../context";
// import { USER_FEED_TYPES } from "../../common/Enums";
// import { simpleGet } from "../../services/API";
import { useCallback, useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
//component
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

// const useStyles = makeStyles(theme => {
//     return {
//         backgroundmobile: {
//             [theme.breakpoints.down('sm')]: {
//                 minHeight: '300px',
//                 width: '100%',
//             },
//         },
//     }
// })

const OrgCard = ({ cause, cardId, bahir, org }) => {
  const { category, id, feedId } = cause || {};
  const { id: orgId } = org || {};
  const organisation = org || {};

  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  // const isLogin = isLoggedIn()
  // const {user} = useContext(context)
  // const {_id: userId} = user || {}

  //states
  const [data, setData] = useState({ org: {}, cause: {} });
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [favorite, setFavorite] = useState(false);
  // const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleOpen1 = (img) => {
    if (img) {
      if (open1 === false) {
        setOpen1(true);
      }
    } else {
      return;
    }
  };
  const handleOpen2 = (img) => {
    if (img) {
      if (open2 === false) {
        setOpen2(!open2);
      }
    } else {
      return;
    }
  };
  const handleOpen3 = (img) => {
    if (img) {
      if (open3 === false) {
        setOpen3(!open3);
      }
    } else {
      return;
    }
  };
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(!open2);
  const handleClose3 = () => setOpen3(false);

  //support handler

  //modal close handler

  const modalCloseHandler = () => {
    setOpen(false);
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const [, setIsExpanded] = useState(false);

  const executeOnClick = () => {
    setIsExpanded(true);
  };

  const video_img_Formats = ["mp4"];
  let apiArray =
    cause?.micro_goal1?.image && cause.micro_goal1.image.split(".");

  let source = apiArray && apiArray.length > 0 && apiArray[apiArray.length - 1];

  let video;
  let img;
  if (video_img_Formats.includes(source)) {
    video = cause?.micro_goal1?.image;
  } else {
    img = cause?.micro_goal1?.image;
  }

  const video_img_Formats1 = ["mp4"];
  let apiArray1 =
    cause?.micro_goal2?.image && cause.micro_goal2.image.split(".");

  let source1 =
    apiArray1 && apiArray1.length > 0 && apiArray1[apiArray1.length - 1];

  let video1;
  let img1;
  if (video_img_Formats1.includes(source1)) {
    video1 = cause?.micro_goal2?.image;
  } else {
    img1 = cause?.micro_goal2?.image;
  }

  const video_img_Formats2 = ["mp4"];
  let apiArray2 =
    cause?.micro_goal3?.image && cause.micro_goal3.image.split(".");

  let source2 =
    apiArray2 && apiArray2.length > 0 && apiArray2[apiArray2.length - 1];

  let video2;
  let img2;
  if (video_img_Formats2.includes(source2)) {
    video2 = cause?.micro_goal3?.image;
  } else {
    img2 = cause?.micro_goal3?.image;
  }

  const calculateGoalPercen = (fund_raising_goal, fund_raised) => {
    if (fund_raising_goal === fund_raised) {
      return 100;
    } else {
      let percen = (fund_raised / fund_raising_goal) * 100;
      if (percen > 100) {
        percen = 100;
        return percen;
      } else {
        return percen;
      }

      // return ( fund_raised/fund_raising_goal)*100 ;
    }
  };
  useEffect(() => {
    let fund_raised = cause?.fund_raised;
    let fund_raising_goal = cause?.fund_raising_goal;

    // let  fund_raised= 10;
    // let  fund_raising_goal= 100;
    console.log({
      causeId: calculateGoalPercen(fund_raising_goal, fund_raised),
    });
    setCountOfProgress(calculateGoalPercen(fund_raising_goal, fund_raised));
  }, [cause]);

  console.log({ countOfProgress });

  // setCountOfProgress(cause?.fund_raising_goal,cause?.fund_raised)
  //render

  return (
    <>
      <div id={cardId} style={{ marginTop: "30px" }}>
        <Grid
          container
          display={{
            xs: "block",
            sm: "block",
            md: "block",
            lg: "flex",
          }}
        >
          <Grid
            item
            className="backgroundmobile"
            md={6}
            lg={6}
            style={{
              backgroundImage: `url(${cause?.cause_image})`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
          >
            <Box></Box>
          </Grid>
          <Grid item md={6} lg={6}>
            <Box bgcolor={theme.palette.common.white} style={{ color: "#000" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid sx={{ display: "flex" }}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        // height={"100%"}
                        sx={{
                          paddingTop: {
                            xs: 5,
                            sm: 5,
                            md: 5,
                            lg: 5,
                          },
                        }}
                      >
                        <Box display={"flex"} justifyContent={"center"} pb={1}>
                          {organisation.organisation_image?.length > 0 ? (
                            <Box
                              component="img"
                              width={{
                                xs: 50,
                                sm: 50,
                                md: "100px",
                                lg: "100px",
                              }}
                              height={{
                                xs: 50,
                                sm: 50,
                                md: "100px",
                                lg: "100px",
                              }}
                              borderRadius={"100%"}
                              border={"5px solid #ff9776"}
                              src={organisation.organisation_image[0]}
                              alt={organisation.name}
                            />
                          ) : (
                            <Avatar></Avatar>
                          )}
                        </Box>
                        <Typography
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{
                            fontFamily: "Circular Std",

                            fontWeight: 700,
                            fontSize: "18px",
                            lineHeight: "23px",
                            color: "#ff9776",
                          }}
                        >
                          {organisation.name}
                        </Typography>
                        <Typography
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{
                            fontFamily: "Circular Std",

                            fontWeight: 500,
                            fontSize: "17px",
                            lineHeight: "20px",
                            fontStyle: "italic",
                            paddingLeft: "15px",
                          }}
                        >
                          {/* {cause.title} */}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box padding={2} paddingTop={"12px"}>
                        <Box display={"flex"} alignItems={"center"}>
                          <Box>
                            {cause?.organisation_tag === "authorized" && (
                              <VerifiedIcon width={"15px"} height={"15px"} />
                            )}
                            {cause?.organisation_tag === "ACN_verified" && (
                              <VerifiedIcon
                                color={theme.palette.secondary.main}
                                width={"15px"}
                                height={"15px"}
                              />
                            )}
                            {cause?.organisation_tag === "DGR_verified" && (
                              <VerifiedIcon
                                color={theme.palette.primary.dark}
                                width={"15px"}
                                height={"15px"}
                              />
                            )}
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            paddingLeft={1}
                            sx={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "12px",
                              lineHeight: "10px",
                            }}
                          >
                            {/* Certified Organisation */}
                            {cause?.organisation_tag}
                          </Typography>
                          {cause?.organisation_tag === "authorized" && (
                            <Tooltip title="a verified community organisation fundraising for a valuable cause but does not hold charitable status or tax deductibility on donations.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                          {cause?.organisation_tag === "ACN_verified" && (
                            <Tooltip title=" An Australian registered charity with the ACNC. Tax deductibility may not be available.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                          {cause?.organisation_tag === "DGR_verified" && (
                            <Tooltip title=" An Australian registered charity with the ACNC that has been endorsed to receive tax deductible donations over $2.">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "17px",
                                  paddingBottom: "5px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        {/* <Box display={"flex"} justifyContent={"flex-end"}>
                    <MoreIcon />
                  </Box> */}
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color={"text.red"}
                            fontStyle={"italic"}
                            sx={{
                              fontFamily: "Circular Std",

                              fontWeight: 500,
                              fontSize: "12px",
                              lineHeight: "14px",
                            }}
                          >
                            Category - {category}
                            {/* <Typography
                                                            component={'span'}
                                                            variant={'body2'}
                                                            style={{
                                                                paddingLeft:
                                                                    '60px',
                                                                cursor: 'pointer',
                                                            }}
                                                            // onClick={() =>
                                                            //     isLogin && !bahir
                                                            //         ? removeUserFeedFav(
                                                            //               feedId
                                                            //           )
                                                            //         : favoriteHandler()
                                                            // }
                                                        >
                                                            {favorite ? (
                                                                <LoveIcon
                                                                    width={
                                                                        '23px'
                                                                    }
                                                                    height={
                                                                        '20px'
                                                                    }
                                                                />
                                                            ) : (
                                                                <FavoriteBorderIcon
                                                                    width={
                                                                        '23px'
                                                                    }
                                                                    height={
                                                                        '20px'
                                                                    }
                                                                />
                                                            )}
                                                        </Typography> */}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          paddingY={1}
                          //   alignItems={"center"}
                        >
                          <Box>
                            <Typography
                              // variant="h4"
                              sx={{
                                fontFamily: "Circular Std",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "32px",
                              }}
                            >
                              {cause?.title ||
                                "Austrlian Wildlife Conservatory"}
                            </Typography>
                            <Typography
                              color={"text.liteGray"}
                              sx={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "20px",
                                textAlign: "justify",
                              }}
                              component={"span"}
                              variant={"body2"}
                            >
                              <ShowMoreText
                                lines={3}
                                more={
                                  <Box
                                  // onClick={
                                  //     learnMoreSingleCard
                                  // }
                                  >
                                    see more
                                  </Box>
                                }
                                less="show less"
                                onClick={executeOnClick}
                                expanded={false}
                                width={250}
                                truncatedEndingComponent={"... "}
                              >
                                {cause?.description}
                              </ShowMoreText>
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <LinearProgress
                            variant="determinate"
                            sx={{
                              height: "17px",

                              borderRadius: 5,
                              [`&.${linearProgressClasses.colorPrimary}`]: {
                                backgroundColor:
                                  theme.palette.grey[
                                    theme.palette.mode === "light" ? 200 : 800
                                  ],
                              },
                              [`& .${linearProgressClasses.bar}`]: {
                                borderRadius: 5,
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.main,
                              },
                            }}
                            value={countOfProgress}
                          />
                        </Box>
                        <Box display={"flex"} paddingY={1}>
                          <Box
                            width={"25%"}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Box>
                              <Typography
                                align="center"
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "9px",
                                  lineHeight: "11px",
                                }}
                              >
                                Raised
                              </Typography>
                              <Typography
                                align="center"
                                color={theme.palette.primary.main}
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                }}
                              >
                                ${cause?.fund_raised}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            width={"75%"}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Box>
                              <Typography
                                align="right"
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "9px",
                                  lineHeight: "11px",
                                }}
                              >
                                Goal
                              </Typography>
                              <Typography
                                align="right"
                                color={theme.palette.primary.main}
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                }}
                              >
                                ${cause?.fund_raising_goal}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box bgcolor={theme.palette.secondary.main} paddingY={1}>
                    <Container>
                      <Typography
                        align="center"
                        fontWeight={"bold"}
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "15px",
                          lineHeight: "19px",
                        }}
                      >
                        OUR GOALS
                      </Typography>
                      <Box paddingY={0.5}>
                        <Grid container>
                          <Grid item xs={4} sm={4} md={4}>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <Box
                                onClick={() =>
                                  handleOpen1(cause?.micro_goal1?.image)
                                }
                                width={45}
                                borderRadius={"100%"}
                              >
                                {cause?.micro_goal1?.icon ? (
                                  <img
                                    src={cause?.micro_goal1?.icon}
                                    alt={cause?.micro_goal1?.value}
                                    width={"100%"}
                                  />
                                ) : (
                                  <Goal1Icon />
                                )}
                              </Box>
                              <Modal
                                open={open1}
                                onClose={handleClose1}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Box textAlign={"right"}>
                                    <img
                                      src="./images/closepopup.svg"
                                      width={"12px"}
                                      onClick={handleClose1}
                                      alt={"title"}
                                    />
                                  </Box>
                                  <Typography
                                    align="center"
                                    fontWeight={"bold"}
                                    sx={{
                                      textTransform: "uppercase",
                                      fontFamily: "Circular Std",
                                      fontStyle: "normal",
                                      fontWeight: 700,
                                      fontSize: "25px",
                                      lineHeight: "40px",

                                      paddingBottom: "15px",
                                    }}
                                  >
                                    OUR GOALS
                                  </Typography>
                                  <Box
                                    bgcolor={theme.palette.secondary.main}
                                    paddingY={1}
                                  >
                                    <Container>
                                      <Box paddingY={0.2}>
                                        <Grid
                                          container
                                          sx={{
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={4} sm={4} md={4}>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"center"}
                                              flexDirection={"column"}
                                              alignItems={"center"}
                                              // paddingLeft={"250px"}
                                            >
                                              <Box
                                                width={35}
                                                borderRadius={"100%"}
                                              >
                                                {cause?.micro_goal1?.icon ? (
                                                  <img
                                                    src={
                                                      cause?.micro_goal1?.icon
                                                    }
                                                    alt={
                                                      cause?.micro_goal1?.value
                                                    }
                                                    width={"100%"}
                                                  />
                                                ) : (
                                                  <Goal1Icon />
                                                )}
                                              </Box>
                                              <Typography
                                                align="center"
                                                sx={{
                                                  fontFamily: "Circular Std",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                  fontSize: "11px",
                                                  lineHeight: "12px",
                                                }}
                                              >
                                                {cause?.micro_goal1?.value}
                                                {cause?.micro_goal1?.name}
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Container>
                                  </Box>

                                  {video && (
                                    <video
                                      width={"100%"}
                                      height={"70%"}
                                      loop="true"
                                      controls="controls"
                                      autoplay="autoplay"
                                      muted
                                    >
                                      <source src={video} type="video/mp4" />
                                    </video>
                                  )}
                                  {img && (
                                    <img
                                      src={img}
                                      alt="img"
                                      width="100%"
                                      height="70%"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Modal>
                              <Typography
                                align="center"
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "11px",
                                  lineHeight: "12px",
                                }}
                              >
                                {cause?.micro_goal1?.value}{" "}
                                {cause?.micro_goal1?.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              onClick={() =>
                                handleOpen2(cause?.micro_goal2?.image)
                              }
                            >
                              <Box width={45} borderRadius={"100%"}>
                                {cause?.micro_goal2?.icon ? (
                                  <img
                                    src={cause?.micro_goal2?.icon}
                                    alt={cause?.micro_goal2?.value}
                                    width={"100%"}
                                  />
                                ) : (
                                  <Goal1Icon />
                                )}
                              </Box>
                              <Modal
                                open={open2}
                                onClose={handleClose2}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Box textAlign={"right"}>
                                    <img
                                      src="./images/closepopup.svg"
                                      width={"12px"}
                                      onClick={handleClose2}
                                      alt={"title"}
                                    />
                                  </Box>
                                  <Typography
                                    align="center"
                                    fontWeight={"bold"}
                                    sx={{
                                      textTransform: "uppercase",
                                      fontFamily: "Circular Std",
                                      fontStyle: "normal",
                                      fontWeight: 700,
                                      fontSize: "25px",
                                      lineHeight: "40px",

                                      paddingBottom: "15px",
                                    }}
                                  >
                                    OUR GOALS
                                  </Typography>
                                  <Box
                                    bgcolor={theme.palette.secondary.main}
                                    paddingY={1}
                                  >
                                    <Container>
                                      <Box paddingY={0.2}>
                                        <Grid
                                          container
                                          sx={{
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={4} sm={4} md={4}>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"center"}
                                              flexDirection={"column"}
                                              alignItems={"center"}
                                              // paddingLeft={"250px"}
                                            >
                                              <Box
                                                width={35}
                                                borderRadius={"100%"}
                                              >
                                                {cause?.micro_goal2?.icon ? (
                                                  <img
                                                    src={
                                                      cause?.micro_goal2?.icon
                                                    }
                                                    alt={
                                                      cause?.micro_goal2?.value
                                                    }
                                                    width={"100%"}
                                                  />
                                                ) : (
                                                  <Goal1Icon />
                                                )}
                                              </Box>
                                              <Typography
                                                align="center"
                                                sx={{
                                                  fontFamily: "Circular Std",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                  fontSize: "11px",
                                                  lineHeight: "12px",
                                                }}
                                              >
                                                {cause?.micro_goal2?.value}
                                                {cause?.micro_goal2?.name}
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Container>
                                  </Box>

                                  {video1 && (
                                    <video
                                      width={"100%"}
                                      height={"70%"}
                                      loop="true"
                                      controls="controls"
                                      autoplay="autoplay"
                                      muted
                                    >
                                      <source src={video1} type="video/mp4" />
                                    </video>
                                  )}
                                  {img1 && (
                                    <img
                                      src={img1}
                                      alt="img"
                                      width="100%"
                                      height="70%"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Modal>
                              <Typography
                                align="center"
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "11px",
                                  lineHeight: "12px",
                                }}
                              >
                                {cause?.micro_goal2?.value}{" "}
                                {cause?.micro_goal2?.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              onClick={() =>
                                handleOpen3(cause?.micro_goal3?.image)
                              }
                            >
                              <Box width={45} borderRadius={"100%"}>
                                {cause?.micro_goal3?.icon ? (
                                  <img
                                    src={cause?.micro_goal3?.icon}
                                    alt={cause?.micro_goal3?.value}
                                    width={"100%"}
                                  />
                                ) : (
                                  <Goal1Icon />
                                )}
                              </Box>
                              <Modal
                                open={open3}
                                onClose={handleClose3}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Box textAlign={"right"}>
                                    <img
                                      src="./images/closepopup.svg"
                                      width={"12px"}
                                      onClick={handleClose3}
                                      alt={"title"}
                                    />
                                  </Box>
                                  <Typography
                                    align="center"
                                    fontWeight={"bold"}
                                    sx={{
                                      textTransform: "uppercase",
                                      fontFamily: "Circular Std",
                                      fontStyle: "normal",
                                      fontWeight: 700,
                                      fontSize: "25px",
                                      lineHeight: "40px",

                                      paddingBottom: "15px",
                                    }}
                                  >
                                    OUR GOALS
                                  </Typography>
                                  <Box
                                    bgcolor={theme.palette.secondary.main}
                                    paddingY={1}
                                  >
                                    <Container>
                                      <Box paddingY={0.2}>
                                        <Grid
                                          container
                                          sx={{
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={4} sm={4} md={4}>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"center"}
                                              flexDirection={"column"}
                                              alignItems={"center"}
                                              // paddingLeft={"250px"}
                                            >
                                              <Box
                                                width={35}
                                                borderRadius={"100%"}
                                              >
                                                {cause?.micro_goal3?.icon ? (
                                                  <img
                                                    src={
                                                      cause?.micro_goal3?.icon
                                                    }
                                                    alt={
                                                      cause?.micro_goal3?.value
                                                    }
                                                    width={"100%"}
                                                  />
                                                ) : (
                                                  <Goal1Icon />
                                                )}
                                              </Box>
                                              <Typography
                                                align="center"
                                                sx={{
                                                  fontFamily: "Circular Std",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                  fontSize: "11px",
                                                  lineHeight: "12px",
                                                }}
                                              >
                                                {cause?.micro_goal3?.value}
                                                {cause?.micro_goal3?.name}
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Container>
                                  </Box>

                                  {video2 && (
                                    <video
                                      width={"100%"}
                                      height={"70%"}
                                      loop="true"
                                      controls="controls"
                                      autoplay="autoplay"
                                      muted
                                    >
                                      <source src={video2} type="video/mp4" />
                                    </video>
                                  )}
                                  {img2 && (
                                    <img
                                      src={img2}
                                      alt="img"
                                      width="100%"
                                      height="65%"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Modal>
                              <Typography
                                align="center"
                                sx={{
                                  fontFamily: "Circular Std",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "11px",
                                  lineHeight: "12px",
                                }}
                              >
                                {cause?.micro_goal3?.value}{" "}
                                {cause?.micro_goal3?.name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  </Box>
                  <Box paddingY={1}>
                    {/* <Container> */}
                    <Grid container justifyContent={"center"}>
                      {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        {cause?.organisation_tag === 'authorized' &&  <VerifiedIcon width={"35px"} height={"35px"} />}
                        {cause?.organisation_tag === 'ACN_verified' &&  <VerifiedIcon   color={theme.palette.secondary.main} width={"35px"} height={"35px"} />}
                        {cause?.organisation_tag === 'DGR_verified' &&  <VerifiedIcon color={theme.palette.primary.dark} width={"35px"} height={"35px"} />}
                      </Box>
                      <Typography
                        fontWeight={"bold"}
                        paddingLeft={1}
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "12px",
                          lineHeight: "10px",
                        }}
                      > */}
                      {/* Certified Organisation */}
                      {/* {cause?.organisation_tag}
                      </Typography>
                    </Box> */}
                      {/* <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <VerifiedIcon
                          color={theme.palette.secondary.main}
                          width={"35px"}
                          height={"35px"}
                        />
                      </Box>
                      <Typography
                        fontWeight={"bold"}
                        paddingLeft={1}
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "8px",
                          lineHeight: "10px",
                        }}
                      >
                        ACN Verified
                      </Typography>
                    </Box>  */}
                      {/* <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <VerifiedIcon
                          color={theme.palette.primary.dark}
                          width={"35px"}
                          height={"35px"}
                        />
                      </Box>
                      <Typography
                        fontWeight={"bold"}
                        paddingLeft={1}
                        sx={{
                          fontFamily: "Circular Std",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "8px",
                          lineHeight: "10px",
                        }}
                      >
                        DGR Verified
                      </Typography>
                    </Box>  */}
                      {/* </Grid> */}
                      {/* <Grid item xs={6} sm={6} md={6} lg={6}> */}
                      <Box paddingY={1}>
                        <Button
                          variant="contained"
                          // onClick={() => {
                          //     isLogin
                          //         ? navigate(
                          //               `${userRoutes?.user}${userRoutes?.orgProfile}/${orgId}`
                          //           )
                          //         : navigate(
                          //               `${userRoutes?.orgCause}?cause=${cause?.id}&isLogin=false`
                          //           )
                          // }}
                          fullWidth
                          sx={{
                            paddingX: {
                              md: "50px",
                              lg: "48px",
                            },
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "18px",

                            paddingBottom: "13px",
                          }}
                        >
                          LEARN MORE
                        </Button>
                      </Box>
                      &nbsp; &nbsp;
                      {/* <Box paddingY={1}>
                                                <Button
                                                    variant='contained'
                                                    startIcon={<SupportIcon />}
                                                    fullWidth
                                                    // onClick={supportHandler}
                                                    sx={{
                                                        fontFamily:
                                                            'Circular Std',
                                                        fontStyle: 'normal',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '18px',
                                                    }}
                                                >
                                                    SUPPORT
                                                </Button>
                                            </Box> */}
                      {/* </Grid> */}
                    </Grid>
                    {/* </Container> */}
                  </Box>
                </Grid>
              </Grid>

              <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={alertCloseHandler}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "top",
                }}
              >
                <Alert
                  onClose={alertCloseHandler}
                  severity={alertIcon}
                  sx={{ width: "100%" }}
                >
                  {alertMsg}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OrgCard;

// import React, { useState } from "react";

// //package import

// import {
//   Box,
//   Grid,
//   Typography,
//   useTheme,
//   LinearProgress,
//   Container,
//   Button,
//   Alert,
//   Snackbar,
//   Avatar,
//   Modal,
// } from "@mui/material";
// import { linearProgressClasses } from "@mui/material/LinearProgress";
// import { useNavigate } from "react-router-dom";

// //user import

// import MoreIcon from "../../svg/orgProfile/MoreIcon";
// import LoveIcon from "../../svg/orgProfile/LoveIcon";
// import Goal1Icon from "../../svg/orgProfile/Goal1";
// import VerifiedIcon from "../../svg/orgProfile/VerifiedIcon";
// import SupportIcon from "../../svg/orgProfile/Support";
// import { userRoutes } from "../../services/routes/APP";
// import SupportModal from "../SupportModal";
// import { isLoggedIn } from "../../services/routes/APP/PrivateRoute";
// import ShowMoreText from "react-show-more-text";
// import { height } from "@mui/system";

// //component
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   height: 500,
//   bgcolor: "#fff",
//   borderRadius: "10px",
//   p: "8px 20px 32px 32px",
// };

// const OrgCard = ({ cause }) => {
//   const { organisation, category, id } = cause;
//   const { id: orgId } = organisation;

//   //hooks

//   const theme = useTheme();
//   const navigate = useNavigate();
//   const isLogin = isLoggedIn();

//   //states

//   const [open, setOpen] = useState(false);
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [alertMsg, setAlertMsg] = useState("");
//   const [alertIcon, setAlertIcon] = useState("error");

//   // const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [open1, setOpen1] = useState(false);
//   const [open2, setOpen2] = useState(false);
//   const [open3, setOpen3] = useState(false);

//   const handleOpen1 = (img) =>{
//     if(img){
//       if(open1 === false){
//       setOpen1(true)};
//     }else{

//       return
//     }
//   }
//   const handleOpen2 = (img) =>{
//     if(img){
//       if(open2 === false){
//       setOpen2(!open2)};
//     }else{

//       return
//     }
//   }
//   const handleOpen3 = (img) =>{
//     if(img){
//       if(open3 === false){
//       setOpen3(!open3)};
//     }else{

//       return
//     }
//   }
//   const handleClose1 = () => setOpen1(false);
//   const handleClose2 = ()=>{
//     console.log("openopophhryjyr", !open2)
//     setOpen2(!open2)
//     console.log("yoo", open2)

//   }
//   const handleClose3 = () => setOpen3(false);

//   //support handler

//   const supportHandler = () => {
//     if (isLogin) {
//       setOpen(true);
//     } else {
//       setAlertOpen(true);
//       setAlertMsg("Please create a account or sign in");
//       setAlertIcon("error");
//     }
//   };

//   //modal close handler

//   const modalCloseHandler = () => {
//     setOpen(false);
//   };

//   //alert close handler

//   const alertCloseHandler = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setAlertOpen(false);
//   };
//   const [isExpanded, setIsExpanded] = useState(false);

//   const executeOnClick = () => {
//     setIsExpanded(true);
//   };

//   const video_img_Formats = ["mp4"];
//   let apiArray =
//     cause?.micro_goal1?.image && cause.micro_goal1.image.split(".");

//   let source = apiArray && apiArray.length > 0 && apiArray[apiArray.length - 1];

//   let video;
//   let img;
//   if (video_img_Formats.includes(source)) {
//     video = cause?.micro_goal1?.image;
//   } else {
//     img = cause?.micro_goal1?.image;
//   }
//   const video_img_Formats1 = ["mp4"];
//   let apiArray1 =
//     cause?.micro_goal2?.image && cause.micro_goal2.image.split(".");

//   let source1 = apiArray1 && apiArray1.length > 0 && apiArray1[apiArray1.length - 1];

//   let video1;
//   let img1;
//   if (video_img_Formats1.includes(source1)) {
//     video1 = cause?.micro_goal2?.image;
//   } else {
//     img1 = cause?.micro_goal2?.image;
//   }
//   const video_img_Formats2 = ["mp4"];
//   let apiArray2 =
//     cause?.micro_goal3?.image && cause.micro_goal3.image.split(".");

//   let source2 = apiArray2 && apiArray2.length > 0 && apiArray2[apiArray2.length - 1];

//   let video2;
//   let img2;
//   if (video_img_Formats2.includes(source2)) {
//     video2 = cause?.micro_goal3?.image;
//   } else {
//     img2 = cause?.micro_goal3?.image;
//   }

//   //render

//   return (
//     <Box bgcolor={theme.palette.common.white}>
//       <Grid container>
//         <Grid item xs={12} sm={12} md={12}>
//           <Grid sx={{ display: "flex" }}>
//             <Grid item xs={12} sm={12} md={4}>
//               <Box
//                 // height={"100%"}
//                 sx={{ paddingTop: { xs: 5, sm: 5, md: 3, lg: 3 } }}
//               >
//                 <Box display={"flex"} justifyContent={"center"} pb={1}>
//                   {organisation.organisation_image?.length > 0 ? (
//                     <Box
//                       component="img"
//                       width={{ xs: 50, sm: 50, md: "100px", lg: "100px" }}
//                       height={{ xs: 50, sm: 50, md: "100px", lg: "100px" }}
//                       borderRadius={"100%"}
//                       border={"5px solid #ff9776"}
//                       src={organisation.organisation_image}
//                       alt={organisation.name}
//                     />
//                   ) : (
//                     <Avatar></Avatar>
//                   )}
//                 </Box>
//                 <Typography
//                   display={"flex"}
//                   justifyContent={"center"}
//                   sx={{
//                     fontFamily: "Circular Std",

//                     fontWeight: 700,
//                     fontSize: "18px",
//                     lineHeight: "23px",
//                     color: "#ff9776",
//                   }}
//                 >
//                   {organisation.name}

//                 </Typography>
//                 <Typography
//                   display={"flex"}
//                   justifyContent={"center"}
//                   sx={{
//                     fontFamily: "Circular Std",

//                     fontWeight: 500,
//                     fontSize: "17px",
//                     lineHeight: "20px",
//                     fontStyle: "italic",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {/* {cause.title} */}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item xs={12} sm={12} md={8}>
//               <Box padding={2}>
//                 {/* <Box display={"flex"} justifyContent={"flex-end"}>
//                   <MoreIcon />
//                 </Box> */}
//                 <Box sx={{ display: "flex" }}>
//                   <Typography
//                     color={"text.red"}
//                     fontStyle={"italic"}
//                     sx={{
//                       fontFamily: "Circular Std",

//                       fontWeight: 500,
//                       fontSize: "12px",
//                       lineHeight: "14px",
//                     }}
//                   >
//                     Category - {category}
//                     <Box component="span" sx={{ paddingLeft: { xs: '0px', sm: '0px', md: '60px', ld: '60px' } }}>
//                       <LoveIcon width={"23px"} height={"20px"} />
//                     </Box>
//                   </Typography>
//                 </Box>
//                 <Box
//                   display={"flex"}
//                   justifyContent={"space-between"}
//                   paddingY={1}
//                   //   alignItems={"center"}
//                 >
//                   <Box>
//                     <Typography
//                       // variant="h4"
//                       sx={{
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 700,
//                         fontSize: "20px",
//                         lineHeight: "32px",
//                       }}
//                     >
//                       {cause?.title || "Austrlian Wildlife Conservatory"}
//                     </Typography>
//                     <Typography
//                       color={"text.liteGray"}
//                       sx={{
//                         fontFamily: "Inter",
//                         fontStyle: "normal",
//                         fontWeight: 400,
//                         fontSize: "14px",
//                         lineHeight: "20px",
//                         textAlign: "justify",
//                       }}
//                     >
//                       <ShowMoreText
//                         lines={2}
//                         more={<Box onClick={handleOpen}>see more</Box>}
//                         less="show less"
//                         onClick={executeOnClick}
//                         expanded={false}
//                         width={224}
//                         truncatedEndingComponent={"... "}
//                       >
//                         {cause?.description}

//                       </ShowMoreText>
//                       <Modal
//                         open={open}
//                         onClose={handleClose}
//                         aria-labelledby="modal-modal-title"
//                         aria-describedby="modal-modal-description"
//                       >
//                         <Box>SEE MORE</Box>
//                       </Modal>
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Box>
//                   <LinearProgress
//                     variant="determinate"
//                     sx={{
//                       height: "17px",

//                       borderRadius: 5,
//                       [`&.${linearProgressClasses.colorPrimary}`]: {
//                         backgroundColor:
//                           theme.palette.grey[
//                             theme.palette.mode === "light" ? 200 : 800
//                           ],
//                       },
//                       [`& .${linearProgressClasses.bar}`]: {
//                         borderRadius: 5,
//                         backgroundColor:
//                           theme.palette.mode === "light"
//                             ? theme.palette.primary.main
//                             : theme.palette.primary.main,
//                       },
//                     }}
//                     value={25}
//                   />
//                 </Box>
//                 <Box display={"flex"} paddingY={1}>
//                   <Box
//                     width={"25%"}
//                     display={"flex"}
//                     justifyContent={"flex-end"}
//                   >
//                     <Box>
//                       <Typography
//                         align="center"
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 700,
//                           fontSize: "9px",
//                           lineHeight: "11px",
//                         }}
//                       >
//                         Raised
//                       </Typography>
//                       <Typography
//                         align="center"
//                         color={theme.palette.primary.main}
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 700,
//                           fontSize: "12px",
//                           lineHeight: "15px",
//                         }}
//                       >
//                         $2,533
//                       </Typography>
//                     </Box>
//                   </Box>
//                   <Box
//                     width={"75%"}
//                     display={"flex"}
//                     justifyContent={"flex-end"}
//                   >
//                     <Box>
//                       <Typography
//                         align="right"
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 700,
//                           fontSize: "9px",
//                           lineHeight: "11px",
//                         }}
//                       >
//                         Goal
//                       </Typography>
//                       <Typography
//                         align="right"
//                         color={theme.palette.primary.main}
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 700,
//                           fontSize: "12px",
//                           lineHeight: "15px",
//                         }}
//                       >
//                         ${cause?.fund_raising_goal}
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//           <Box bgcolor={theme.palette.secondary.main} paddingY={1}>
//             <Container>
//               <Typography
//                 align="center"
//                 fontWeight={"bold"}
//                 sx={{
//                   textTransform: "uppercase",
//                   fontFamily: "Circular Std",
//                   fontStyle: "normal",
//                   fontWeight: 700,
//                   fontSize: "15px",
//                   lineHeight: "19px",
//                 }}
//               >
//                 OUR GOALS
//               </Typography>
//               <Box paddingY={0.5}>
//                 <Grid container>
//                   <Grid item xs={4} sm={4} md={4}>
//                     <Box
//                       display={"flex"}
//                       justifyContent={"center"}
//                       flexDirection={"column"}
//                       alignItems={"center"}
//                     >
//                       <Box
//                         onClick={()=>handleOpen1(cause?.micro_goal1?.image)}
//                         width={45}
//                         borderRadius={"100%"}
//                       >
//                         {cause?.micro_goal1?.icon ? (
//                           <img
//                             src={cause?.micro_goal1?.icon}
//                             alt={cause?.micro_goal1?.value}
//                             width={"100%"}
//                           />
//                         ) : (
//                           <Goal1Icon />
//                         )}
//                       </Box>
//                       <Modal
//                         open={open1}
//                         onClose={handleClose1}
//                         aria-labelledby="modal-modal-title"
//                         aria-describedby="modal-modal-description"
//                       >
//                         <Box sx={style} >
//                         <Box textAlign={"right"}>
//                   <img src="./images/closepopup.svg" width={"12px"} onClick={handleClose1} alt={'title'} />
//                 </Box>
//                         <Typography
//                               align="center"
//                               fontWeight={"bold"}
//                               sx={{
//                                 textTransform: "uppercase",
//                                 fontFamily: "Circular Std",
//                                 fontStyle: "normal",
//                                 fontWeight: 700,
//                                 fontSize: "25px",
//                                 lineHeight: "40px",

//                                 paddingBottom:"15px"

//                               }}
//                             >
//                               OUR GOALS
//                             </Typography>
//                           <Box
//                             bgcolor={theme.palette.secondary.main}
//                             paddingY={1}

//                           >
//                             <Container>
//                               <Box paddingY={0.2}>
//                                 <Grid container sx={{justifyContent:"center"}}>
//                                   <Grid item xs={4} sm={4} md={4} >
//                                     <Box
//                                       display={"flex"}
//                                       justifyContent={"center"}
//                                       flexDirection={"column"}
//                                       alignItems={"center"}
//                                       // paddingLeft={"250px"}

//                                     >
//                                       <Box

//                                         width={35}
//                                         borderRadius={"100%"}
//                                       >
//                                         {cause?.micro_goal1?.icon ? (
//                                           <img
//                                             src={cause?.micro_goal1?.icon}
//                                             alt={cause?.micro_goal1?.value}
//                                             width={"100%"}
//                                           />
//                                         ) : (
//                                           <Goal1Icon />
//                                         )}
//                                       </Box>
//                                       <Typography
//                                         align="center"
//                                         sx={{
//                                           fontFamily: "Circular Std",
//                                           fontStyle: "normal",
//                                           fontWeight: 500,
//                                           fontSize: "11px",
//                                           lineHeight: "12px",
//                                         }}
//                                       >
//                                         {cause?.micro_goal1?.value}
//                                         {cause?.micro_goal1?.name}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                 </Grid>
//                               </Box>
//                             </Container>
//                           </Box>

//                           {video && (
//                             <video
//                               width={"100%"}
//                               height={"70%"}
//                               loop="true"
//                               controls="controls"
//                               autoplay="autoplay"
//                               muted
//                             >
//                               <source src={video} type="video/mp4" />
//                             </video>
//                           )}
//                           {img && (
//                             <img
//                               src={img}
//                               alt="img"
//                               width="100%"
//                               height="70%"
//                               style={{objectFit: "contain"}}
//                             />
//                           )}
//                         </Box>
//                       </Modal>
//                       <Typography
//                         align="center"
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 500,
//                           fontSize: "11px",
//                           lineHeight: "12px",
//                         }}
//                       >
//                         {cause?.micro_goal1?.value} {cause?.micro_goal1?.name}
//                       </Typography>
//                     </Box>
//                   </Grid>
//                   <Grid item xs={4} sm={4} md={4}>
//                     <Box
//                       display={"flex"}
//                       justifyContent={"center"}
//                       flexDirection={"column"}
//                       alignItems={"center"}
//                       onClick={()=>handleOpen2(cause?.micro_goal2?.image)}
//                     >
//                       <Box width={45} borderRadius={"100%"}>
//                         {cause?.micro_goal2?.icon ? (
//                           <img
//                             src={cause?.micro_goal2?.icon}
//                             alt={cause?.micro_goal2?.value}
//                             width={"100%"}
//                           />
//                         ) : (
//                           <Goal1Icon />
//                         )}
//                       </Box>
//                       <Modal
//                         open={open2}
//                         onClose={handleClose2}
//                         aria-labelledby="modal-modal-title"
//                         aria-describedby="modal-modal-description"
//                       >
//                         <Box sx={style}>
//                         <Box textAlign={"right"}>
//                   <img src="./images/closepopup.svg" width={"12px"} onClick={handleClose2} alt={'title'} />
//                 </Box>
//                         <Typography
//                               align="center"
//                               fontWeight={"bold"}
//                               sx={{
//                                 textTransform: "uppercase",
//                                 fontFamily: "Circular Std",
//                                 fontStyle: "normal",
//                                 fontWeight: 700,
//                                 fontSize: "25px",
//                                 lineHeight: "40px",

//                                 paddingBottom:"15px"

//                               }}
//                             >
//                               OUR GOALS
//                             </Typography>
//                           <Box
//                             bgcolor={theme.palette.secondary.main}
//                             paddingY={1}
//                           >
//                             <Container>
//                               <Box paddingY={0.2}>
//                                 <Grid container sx={{justifyContent:"center"}}>
//                                   <Grid item xs={4} sm={4} md={4}>
//                                     <Box
//                                       display={"flex"}
//                                       justifyContent={"center"}
//                                       flexDirection={"column"}
//                                       alignItems={"center"}
//                                       // paddingLeft={"250px"}

//                                     >
//                                       <Box

//                                         width={35}
//                                         borderRadius={"100%"}
//                                       >
//                                         {cause?.micro_goal2?.icon ? (
//                                           <img
//                                             src={cause?.micro_goal2?.icon}
//                                             alt={cause?.micro_goal2?.value}
//                                             width={"100%"}
//                                           />
//                                         ) : (
//                                           <Goal1Icon />
//                                         )}
//                                       </Box>
//                                       <Typography
//                                         align="center"
//                                         sx={{
//                                           fontFamily: "Circular Std",
//                                           fontStyle: "normal",
//                                           fontWeight: 500,
//                                           fontSize: "11px",
//                                           lineHeight: "12px",
//                                         }}
//                                       >
//                                         {cause?.micro_goal2?.value}
//                                         {cause?.micro_goal2?.name}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                 </Grid>
//                               </Box>
//                             </Container>
//                           </Box>

//                           {video1 && (
//                             <video
//                               width={"100%"}
//                               height={"70%"}
//                               loop="true"
//                               controls="controls"
//                               autoplay="autoplay"
//                               muted
//                             >
//                               <source src={video1} type="video/mp4" />
//                             </video>
//                           )}
//                           {img1 && (
//                             <img
//                               src={img1}
//                               alt="img"
//                               width="100%"
//                               height="70%"
//                               style={{objectFit: "contain"}}
//                             />
//                           )}
//                         </Box>
//                       </Modal>
//                       <Typography
//                         align="center"
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 500,
//                           fontSize: "11px",
//                           lineHeight: "12px",
//                         }}
//                       >
//                         {cause?.micro_goal2?.value} {cause?.micro_goal2?.name}
//                       </Typography>
//                     </Box>
//                   </Grid>
//                   <Grid item xs={4} sm={4} md={4}>
//                     <Box
//                       display={"flex"}
//                       justifyContent={"center"}
//                       flexDirection={"column"}
//                       alignItems={"center"}
//                       onClick={()=>handleOpen3(cause?.micro_goal3?.image)}
//                     >
//                       <Box width={45} borderRadius={"100%"}>
//                         {cause?.micro_goal3?.icon ? (
//                           <img
//                             src={cause?.micro_goal3?.icon}
//                             alt={cause?.micro_goal3?.value}
//                             width={"100%"}
//                           />
//                         ) : (
//                           <Goal1Icon />
//                         )}
//                       </Box>
//                       <Modal
//                         open={open3}
//                         onClose={handleClose3}
//                         aria-labelledby="modal-modal-title"
//                         aria-describedby="modal-modal-description"
//                       >
//                         <Box sx={style}>
//                         <Box textAlign={"right"}>
//                   <img src="./images/closepopup.svg" width={"12px"} onClick={handleClose3} alt={'title'} />
//                 </Box>
//                         <Typography
//                               align="center"
//                               fontWeight={"bold"}
//                               sx={{
//                                 textTransform: "uppercase",
//                                 fontFamily: "Circular Std",
//                                 fontStyle: "normal",
//                                 fontWeight: 700,
//                                 fontSize: "25px",
//                                 lineHeight: "40px",

//                                 paddingBottom:"15px"

//                               }}
//                             >
//                               OUR GOALS
//                             </Typography>
//                           <Box
//                             bgcolor={theme.palette.secondary.main}
//                             paddingY={1}
//                           >
//                             <Container>
//                               <Box paddingY={0.2}>
//                                 <Grid container sx={{justifyContent:"center"}}>
//                                   <Grid item xs={4} sm={4} md={4}>
//                                     <Box
//                                       display={"flex"}
//                                       justifyContent={"center"}
//                                       flexDirection={"column"}
//                                       alignItems={"center"}
//                                       // paddingLeft={"250px"}

//                                     >
//                                       <Box

//                                         width={35}
//                                         borderRadius={"100%"}
//                                       >
//                                         {cause?.micro_goal3?.icon ? (
//                                           <img
//                                             src={cause?.micro_goal3?.icon}
//                                             alt={cause?.micro_goal3?.value}
//                                             width={"100%"}
//                                           />
//                                         ) : (
//                                           <Goal1Icon />
//                                         )}
//                                       </Box>
//                                       <Typography
//                                         align="center"
//                                         sx={{
//                                           fontFamily: "Circular Std",
//                                           fontStyle: "normal",
//                                           fontWeight: 500,
//                                           fontSize: "11px",
//                                           lineHeight: "12px",
//                                         }}
//                                       >
//                                         {cause?.micro_goal3?.value}
//                                         {cause?.micro_goal3?.name}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                 </Grid>
//                               </Box>
//                             </Container>
//                           </Box>

//                           {video2 && (
//                             <video
//                               width={"100%"}
//                               height={"70%"}
//                               loop="true"
//                               controls="controls"
//                               autoplay="autoplay"
//                               muted
//                             >
//                               <source src={video2} type="video/mp4" />
//                             </video>
//                           )}
//                           {img2 && (
//                             <img
//                               src={img2}
//                               alt="img"
//                               width="100%"
//                               height="65%"
//                               style={{objectFit: "contain"}}
//                             />
//                           )}
//                         </Box>
//                       </Modal>
//                       <Typography
//                         align="center"
//                         sx={{
//                           fontFamily: "Circular Std",
//                           fontStyle: "normal",
//                           fontWeight: 500,
//                           fontSize: "11px",
//                           lineHeight: "12px",
//                         }}
//                       >
//                         {cause?.micro_goal3?.value} {cause?.micro_goal3?.name}
//                       </Typography>
//                     </Box>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Container>
//           </Box>
//           <Box paddingY={2}>
//             <Container>
//               <Grid container>
//                 <Grid item xs={6} sm={6} md={6} lg={6}>
//                   <Box display={"flex"} alignItems={"center"}>
//                     <Box>
//                       {cause?.organisation_tag === 'authorized' &&  <VerifiedIcon width={"35px"} height={"35px"} />}
//                       {cause?.organisation_tag === 'ACN_verified' &&  <VerifiedIcon   color={theme.palette.secondary.main} width={"35px"} height={"35px"} />}
//                       {cause?.organisation_tag === 'DGR_verified' &&  <VerifiedIcon color={theme.palette.primary.dark} width={"35px"} height={"35px"} />}
//                     </Box>
//                     <Typography
//                       fontWeight={"bold"}
//                       paddingLeft={1}
//                       sx={{
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 700,
//                         fontSize: "12px",
//                         lineHeight: "10px",
//                       }}
//                     >
//                       {/* Certified Organisation */}
//                       {cause?.organisation_tag}
//                     </Typography>
//                   </Box>
//                    {/* <Box display={"flex"} alignItems={"center"}>
//                     <Box>
//                       <VerifiedIcon
//                         color={theme.palette.secondary.main}
//                         width={"35px"}
//                         height={"35px"}
//                       />
//                     </Box>
//                     <Typography
//                       fontWeight={"bold"}
//                       paddingLeft={1}
//                       sx={{
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 700,
//                         fontSize: "8px",
//                         lineHeight: "10px",
//                       }}
//                     >
//                       ACN Verified
//                     </Typography>
//                   </Box>  */}
//                    {/* <Box display={"flex"} alignItems={"center"}>
//                     <Box>
//                       <VerifiedIcon
//                         color={theme.palette.primary.dark}
//                         width={"35px"}
//                         height={"35px"}
//                       />
//                     </Box>
//                     <Typography
//                       fontWeight={"bold"}
//                       paddingLeft={1}
//                       sx={{
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 700,
//                         fontSize: "8px",
//                         lineHeight: "10px",
//                       }}
//                     >
//                       DGR Verified
//                     </Typography>
//                   </Box>  */}
//                 </Grid>
//                 <Grid item xs={6} sm={6} md={6} lg={6}>
//                   <Box paddingY={1}>
//                     <Button
//                       variant="contained"
//                       onClick={() => {
//                         isLogin
//                           ? navigate(
//                               `${userRoutes?.user}${userRoutes?.orgProfile}/${orgId}`
//                             )
//                           : navigate(
//                               `${userRoutes?.orgCause}?cause=${cause?.id}`
//                             );
//                       }}
//                       fullWidth
//                       sx={{
//                         paddingX: { md: 0, lg: 0 },
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                         fontSize: "14px",
//                         lineHeight: "18px",
//                       }}
//                     >
//                       LEARN MORE
//                     </Button>
//                   </Box>
//                   <Box paddingY={1}>
//                     <Button
//                       variant="contained"
//                       startIcon={<SupportIcon />}
//                       fullWidth
//                       onClick={supportHandler}
//                       sx={{
//                         fontFamily: "Circular Std",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                         fontSize: "14px",
//                         lineHeight: "18px",
//                       }}
//                     >
//                       SUPPORT
//                     </Button>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Container>
//           </Box>
//         </Grid>
//       </Grid>
//       <SupportModal
//         closeHandler={modalCloseHandler}
//         open={open}
//         causeId={id}
//         organisation={orgId}
//       />
//       <Snackbar
//         open={alertOpen}
//         autoHideDuration={6000}
//         onClose={alertCloseHandler}
//         anchorOrigin={{
//           horizontal: "center",
//           vertical: "top",
//         }}
//       >
//         <Alert
//           onClose={alertCloseHandler}
//           severity={alertIcon}
//           sx={{ width: "100%" }}
//         >
//           {alertMsg}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default OrgCard;
