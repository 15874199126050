import React, { memo, useCallback, useEffect, useState } from "react";

//package import

import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Checkbox,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  FormGroup,
  Alert,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Formik, Form } from "formik";

//user import
// import { CATEGORIES_ARRAY } from "../../../../common/Enums";
import { q2validation } from "app/Services/user/validations/Questions";
import { CATEGORIES_ARRAY } from "app/common/user/Enums";

//constants

const initialValues = {
  sub_categories_with_cat: [],
};

//component

const QuestionTwo = ({
  decreamentStep,
  increamentStep,
  formData,
  setFormData,
  setAPI,
}) => {
  //hooks

  const theme = useTheme();
  //states

  const [formInitialValues, setFormInitialValues] = useState(initialValues);

  //submit handler

  const formSubmitHandler = (values) => {
    setFormData((prev) => ({
      ...prev,
      sub_categories_with_cat: values.sub_categories_with_cat,
    }));
    increamentStep();
    setAPI(true);
  };

  //set form initial values

  const setFormValues = useCallback(() => {
    const arr = formData?.categories?.map((item) => ({
      category: item,
      sub_categories: [],
    }));

    setFormInitialValues({
      sub_categories_with_cat: arr,
    });
  }, [formData]);

  //life cycle hook

  useEffect(() => {
    formData?.categories?.length > 0 && setFormValues();
  }, [formData, setFormValues]);

  // sub categories set handler

  const setCategoryWithSubCategories = (
    values,
    setFieldValue,
    subCat,
    category
  ) => {
    let arr = values?.sub_categories_with_cat?.map((cat) => {
      if (cat?.category === category?.value) {
        if (cat?.sub_categories?.includes(subCat?.value)) {
          return {
            ...cat,
            sub_categories: cat?.sub_categories?.filter(
              (item) => item !== subCat?.value
            ),
          };
        } else {
          return cat?.sub_categories?.push(subCat?.value) && cat;
        }
      } else {
        return cat;
      }
    });

    setFieldValue("sub_categories_with_cat", arr);
  };

  //render

  return (
    <Box>
      <Container maxWidth={"md"}>
        <Box marginTop={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Box color={theme.palette.common.white}>
                <IconButton color="inherit" onClick={decreamentStep}>
                  <ArrowBackIcon color="inherit" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Box paddingY={2}>
                <Box>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    align="center"
                    color={"common.white"}
                  >
                    I'm Up 4 Change for causes aligned to
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    fontWeight={"bold"}
                    align="center"
                    color={"common.white"}
                  >
                    Click all that apply
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Formik
          onSubmit={formSubmitHandler}
          initialValues={formInitialValues}
          enableReinitialize
          validationSchema={q2validation}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Box>
                {CATEGORIES_ARRAY?.filter((cat) =>
                  formData?.categories?.includes(cat?.value)
                )?.map((item, index) => (
                  <Box
                    key={`${index}-categories-map-${item?.id}-${item?.name}-${item?.value}`}
                  >
                    <Box display={"flex"} justifyContent={"center"}>
                      <Box
                        margin={2}
                        width={170}
                        borderRadius={2}
                        height={170}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          border: `2px solid ${theme.palette.common.white}`,
                        }}
                      >
                        <Box
                          width={{ xs: 100, md: 80 }}
                          height={{ xs: 100, md: 80 }}
                          padding={2}
                        >
                          <Box
                            component={"img"}
                            src={item?.url}
                            alt={item?.name}
                            width={"100%"}
                            height={"100%"}
                          />
                        </Box>
                        <Box>
                          <Typography
                            color={"common.black"}
                            fontWeight={"bold"}
                            align="center"
                            variant="body2"
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <Grid container>
                          {item?.sub_categories?.map((txt, i) => (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={3}
                              padding={1}
                              key={`${i}-${txt?.value}-${item?.id}`}
                            >
                              <Tooltip
                                title={txt?.name}
                                placement="top-end"
                                color={"primary"}
                                sx={{
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: theme.palette.primary.main,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: theme.palette.common.black,
                                  },
                                }}
                                arrow
                              >
                                <Box>
                                  <Checkbox
                                    sx={{
                                      width: "100%",
                                    }}
                                    name={txt?.value}
                                    checked={values?.sub_categories_with_cat?.find(
                                      (sub_cat) => sub_cat?.value === txt?.value
                                    )}
                                    icon={
                                      <Box
                                        bgcolor={theme.palette.common.white}
                                        color={theme.palette.common.black}
                                        width={"100%"}
                                        textAlign={"center"}
                                        padding={1}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {txt?.name}
                                      </Box>
                                    }
                                    checkedIcon={
                                      <Box
                                        bgcolor={theme.palette.primary.main}
                                        color={theme.palette.common.white}
                                        textAlign={"center"}
                                        width={"100%"}
                                        padding={1}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {txt?.name}
                                      </Box>
                                    }
                                    onChange={(e) =>
                                      setCategoryWithSubCategories(
                                        values,
                                        setFieldValue,
                                        txt,
                                        item,
                                        e?.target?.checked
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                      <FormHelperText
                        sx={{
                          textAlign: "center",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        {touched?.sub_categories_with_cat?.length > 0 &&
                          errors.sub_categories_with_cat?.length > 0 && (
                            <Snackbar
                              open={true}
                              autoHideDuration={3000}
                              anchorOrigin={{
                                horizontal: "center",
                                vertical: "top",
                              }}
                            >
                              <Alert severity="error" sx={{ width: "100%" }}>
                                Please select atleast one sub-category against
                                every category
                              </Alert>
                            </Snackbar>
                          )}
                      </FormHelperText>
                    </FormControl>
                    {item?.field && (
                      <Box>
                        <Typography color={"common.white"} align="center">
                          <Typography fontWeight={"bold"} component={"span"}>
                            Other{" "}
                          </Typography>
                          <Typography fontStyle={"italic"} component={"span"}>
                            ( Please Specify ){" "}
                          </Typography>
                        </Typography>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          marginY={2}
                        >
                          <TextField
                            variant={"outlined"}
                            color="info"
                            placeholder="_Type Here"
                          ></TextField>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box display={"flex"} justifyContent={"center"} padding={3}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    paddingX: 6,
                  }}
                >
                  Next
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default memo(QuestionTwo);
