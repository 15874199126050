import React from "react";

//user import

import { Layout } from "app/components/user";
import FeaturesPage from "app/components/user/Features";

//component

const Features = () => {
  //render

  return (
    <Layout>
      <FeaturesPage />
    </Layout>
  );
};

export default Features;
