import React from "react";

const Heart = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "78"}
      height={height ? height : "71"}
      viewBox="0 0 78 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.3091 6.97462C68.4619 2.47692 63.1249 0 57.2812 0C49.0626 0 43.8593 5.05743 40.9416 9.30022C40.1846 10.4012 39.5406 11.5051 39 12.544C38.4594 11.5051 37.8155 10.4012 37.0584 9.30022C34.1407 5.05743 28.9374 0 20.7188 0C14.8751 0 9.5381 2.47708 5.69079 6.97478C2.02115 11.2653 0 17.0115 0 23.1548C0 29.842 2.53393 36.0618 7.97444 42.7284C12.8369 48.6868 19.832 54.8286 27.9323 61.9405C30.9506 64.5908 34.072 67.3314 37.395 70.3271L37.4948 70.4172C37.9257 70.8058 38.4628 71 39 71C39.5372 71 40.0743 70.8057 40.5052 70.4172L40.605 70.3271C43.928 67.3314 47.0494 64.5909 50.0682 61.9403C58.168 54.8288 65.1633 48.6871 70.0256 42.7284C75.4661 36.0616 78 29.842 78 23.1548C78 17.0115 75.9788 11.2653 72.3091 6.97462Z"
        fill="#FF3E3E"
      />
    </svg>
  );
};

export default Heart;
