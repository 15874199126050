import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

//package imports

import { Form, Formik } from "formik";
import {
  FormControl,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//user imports

import { addValidation } from "app/Services/organization/validations/AddAccount";
import {
  headersPost,
  headersGet,
  headersUpdate,
} from "app/Services/organization/API";
import { API_URLS } from "app/Services/organization/Routes/API";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { context } from "app/context";

//constants

const initialValues = {
  account_name: "",
  bank: "",
  bsb_number: "",
  account_number: "",
  authorised_person_contact_name: "",
};

//component

const AddAccount = () => {
  //hooks

  const navigate = useNavigate();
  const { orgDetails, setOrgDetails } = useContext(context);

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [formInitials, setFormInitials] = useState(initialValues);
  const [api, setApi] = useState(true);

  //add account handler

  const addAccountHandler = (values) => {
    const data = {
      account_name: values?.account_name,
      bank: values?.bank,
      bsb_number: values?.bsb_number,
      organisation: orgDetails?.id,
      account_number: values?.account_number,
      authorised_person_contact_name: values?.authorised_person_contact_name,
    };

    setOpen(true);
    headersPost(API_URLS?.addOrgAccount, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 201) {
          setAlertOpen(true);
          setAlertMsg("Your Bank account is added successfully.");
          setAlertIcon("success");
          setApi(true);
          const updatedDetails = { ...orgDetails, isBank: true };
          setOrgDetails(updatedDetails);
          // window.location.href = APP_URLS?.org
          // navigate(APP_URLS?.org)
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        if (response?.status === 401) {
          localStorage.clear();
          // window.location.href = APP_URLS?.org
          navigate(APP_URLS?.root);
        } else {
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        }
      });
  };
  //update account handler

  const updateHandler = (values) => {
    const data = {
      account_name: values?.account_name,
      bank: values?.bank,
      bsb_number: values?.bsb_number,
      organisation: orgDetails?.id,
      account_number: values?.account_number,
      authorised_person_contact_name: values?.authorised_person_contact_name,
    };

    setOpen(true);
    headersUpdate(`${API_URLS?.updateOrgAccount}?accountId=${values?.id}`, data)
      .then((res) => {
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg("Your Bank account is updated successfully.");
        setAlertIcon("success");
        setApi(true);
        // window.location.href = APP_URLS?.org
        // navigate(APP_URLS?.org)
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        if (response?.status === 401) {
          localStorage.clear();
          navigate(APP_URLS?.root);
        } else {
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        }
      });
  };

  //form submit handler

  const submitHandler = (values) => {
    values?.id ? updateHandler(values) : addAccountHandler(values);
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const getAccountDetails = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(`${API_URLS?.getOrgAccount}?organisation=${orgDetails?.id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && !res?.data?.error) {
          setFormInitials((prev) => ({ ...prev, ...res?.data?.account }));
        } else {
          // setAlertOpen(true);
          // setAlertMsg(res?.data?.message);
          // setAlertIcon('warning');
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        if (response?.status === 401) {
          localStorage.clear();
          navigate(APP_URLS?.root);
        } else {
          // setAlertOpen(true)
          // setAlertMsg(response?.data?.message)
          // setAlertIcon('error')
        }
      });
  }, [orgDetails, navigate]);

  useEffect(() => {
    api && orgDetails?.id && getAccountDetails();
  }, [orgDetails, getAccountDetails, api]);

  //render

  return (
    <Fragment>
      <Formik
        initialValues={formInitials}
        onSubmit={submitHandler}
        validationSchema={addValidation}
        enableReinitialize
      >
        {({ errors, touched, handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="fields mb-3">
              <label htmlFor="exampleInputEmail1">ACCOUNT NAME</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={touched.account_name && Boolean(errors.account_name)}
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    name="account_name"
                    value={values?.account_name}
                    onChange={handleChange}
                    id="exampleInputEmail1"
                  />
                  <FormHelperText>
                    {touched.account_name && errors.account_name}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="fields mb-3">
              <label htmlFor="exampleInputEmail2">BANK OR INSTITUTION</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={touched.bank && Boolean(errors.bank)}
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    name="bank"
                    value={values?.bank}
                    onChange={handleChange}
                    id="exampleInputEmail2"
                  />
                  <FormHelperText>{touched.bank && errors.bank}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="fields mb-3">
              <label htmlFor="exampleInputEmail3">BSB NUMBER</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={touched.bsb_number && Boolean(errors.bsb_number)}
                >
                  <input
                    type="number"
                    className="form-control w-100"
                    name="bsb_number"
                    value={values?.bsb_number}
                    onChange={handleChange}
                    id="exampleInputEmail3"
                  />
                  <FormHelperText>
                    {touched?.bsb_number && errors?.bsb_number}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="fields mb-3">
              <label htmlFor="exampleInputEmail4">ACCOUNT NUMBER</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={
                    touched.account_number && Boolean(errors.account_number)
                  }
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    name="account_number"
                    value={values?.account_number}
                    onChange={handleChange}
                    id="exampleInputEmail4"
                  />
                  <p
                    style={{
                      fontSize: "13px",
                      marginLeft: "10px",
                    }}
                  >
                    Without spaces and dashes
                  </p>
                  {/* <FormHelperText>
                                        Without spaces and dashes
                                    </FormHelperText> */}
                  <FormHelperText>
                    {touched.account_number && errors.account_number}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="fields">
              <label htmlFor="exampleInputEmail5">
                AUTHORISED PERSON CONTACT NAME
              </label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={
                    touched.authorised_person_contact_name &&
                    Boolean(errors.authorised_person_contact_name)
                  }
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    name="authorised_person_contact_name"
                    value={values?.authorised_person_contact_name}
                    onChange={handleChange}
                    id="exampleInputEmail5"
                  />
                  <FormHelperText>
                    {touched.authorised_person_contact_name &&
                      errors.authorised_person_contact_name}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="text-center my-5">
              <button className="btn btn-pink py-2" type="submit">
                {values?.id ? "UPDATE" : "SAVE"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default AddAccount;
