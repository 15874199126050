import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { userRoutes } from "app/Services/user/routes/APP";

//package import
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Pagination,
  Tooltip,
  Modal,
  Switch,
} from "@mui/material";
import { buttonClasses } from "@mui/material/Button";
import { paginationItemClasses } from "@mui/material/PaginationItem";
import "./cardInfo.css";
import bank from "app/assets/user/images/bank.png";
import error from "app/assets/user/images/error.png";

//user import

import { formatCategory, formatDate } from "app/common/user/functions";
// import { context } from "../../context";

import {
  headersDel,
  headersGet,
  headersPost,
  headersUpdate,
} from "app/Services/user/API";

import { API_URLS } from "app/Services/user/routes/API";
import { DownloadIcon, PrintIcon } from "app/svg/user/DonationHistory";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { border } from "@mui/system";
import { Navigate } from "react-router-dom";
import { getFirstDayOfNextMonth } from "app/utils/user/functions";
import CardModal from "../PaymentInfo/CardModal";
import { Close as CloseIcon } from "@mui/icons-material";
import BankModal2 from "../PaymentInfo/BankModal2";
import { context } from "app/context";
//constants

const headings = [
  "Date",
  "Organisation",
  "Category",
  "Charity Name",
  "Type",
  "Donation Amount",
  "Action",
];
//component

function Index() {
  const theme = useTheme();
  const { user } = useContext(context);
  const { _id } = user || {};
  const navigate = useNavigate();
  const { clientSecretState } = useContext(context);
  const { clientSecret, setClientSecret } = clientSecretState || {};

  //states

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [alertIcon, setAlertIcon] = useState("error");
  const [currentDay, setCurrentDay] = useState("last_month");
  const [roundup, setRoundup] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelSubId, setCancelSubId] = useState(null);

  const [checked, setChecked] = useState(true);
  const [switches, setSwitches] = useState({});
  const [showCardSelectModal, setShowCardSelectModal] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [openCardLink, setOpenCardLink] = useState(false);
  const [openBankLink, setOpenBankLink] = useState(false);
  const [cardData, setCardData] = useState();
  const [bankData, setBankData] = useState();
  const [isLoading, setIsloading] = useState(true);

  const handleBankOpen = () => {
    handleLinkBank();
    setOpenBankLink(true);
  };

  const handleBankClose = () => {
    setOpenBankLink(false);
  };

  const handleCardOpen = () => {
    setOpenCardLink(true);
  };
  const handleCardClose = () => {
    setOpenCardLink(false);
  };

  const markCardDefault = async (cardId) => {
    console.log("====================================");
    console.log({ cardId });
    console.log("====================================");
    setOpen(true);
    setDefaultCardId(cardId);
    if (cardId) {
      const dataToSend = {
        userId: _id,
        accountId: cardId,
      };
      headersPost(`${API_URLS?.markCardDefault}`, dataToSend)
        .then((res) => {
          const { data, status } = res;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("success");
          window.location.reload();
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const handleLinkBank = () => {
    const { _id } = user || {};
    setOpen(true);
    const data = {
      addedByUser: _id,
    };

    headersPost(API_URLS?.stripeBankClientSecret)
      .then((res) => {
        setOpen(false);

        if (res?.status === 200) {
          // handleClose();
          console.log({ res });
          // setAlertOpen(true)
          // setAlertMsg(res?.data?.message)
          // setAlertIcon('success')
          setClientSecret(res?.data?.clientSecret);
          setTimeout(() => {
            handleBankOpen();
            handleClose();
          }, 10000000);
          // navigate(userRoutes?.user + userRoutes?.discover);
          // setAddress('')
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        if (response.status === 401) {
          localStorage.clear();
          navigate(userRoutes?.root);
        }
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  const handleDeleteCard = (card) => {
    const { id: cardId, is_default } = card || {};
    if (is_default) {
      setShowCardSelectModal(true);
    } else {
      const confirmDel = window.confirm(`Are you sure to delete this card?`);
      if (confirmDel) {
        deleteAccount(cardId);
      }
    }
  };

  const deleteAccount = async (cardId) => {
    console.log("====================================");
    console.log({ cardId });
    console.log("====================================");
    setOpen(true);
    if (cardId) {
      headersDel(`${API_URLS?.deleteAccount}?accountId=${cardId}`)
        .then((res) => {
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("success");
          window.location.reload();
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const handleToggleSwitches = (event, id) => {
    if (event.target.checked === true) {
      console.log({ id, event: event.target.checked });
      setSwitches((prev) => {
        const falsedState = Object.keys(prev)?.forEach((key) => {
          prev[key] = false;
        });
        return { falsedState, [id]: event.target.checked };
      });
      // run mark card default api
      markCardDefault(id);
    } else {
      console.log({ id, event: event.target.checked });
      // show card select modal
      setShowCardSelectModal(true);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    height: 570,
    bgcolor: "#fff",
    borderRadius: "10px",
    p: "8px 20px 32px 32px",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 190,
    bgcolor: "#fff",
    borderRadius: "10px",
    p: "8px 20px 32px 32px",
  };

  const getAccounts = useCallback(() => {
    // const data = `page=${page}&limit=${10}&user=${_id}&currentDay=${currentDay}&type=subscription`

    setOpen(true);
    headersGet(`${API_URLS?.getAccounts}?userId=${_id}`)
      .then((res) => {
        const { data, status } = res;
        setOpen(false);
        setIsloading(false);
        if (status === 200) {
          const { accounts, totalPages: pages } = data || {};
          setTotalPages(pages);
          setData(accounts);
          const filtersBank = accounts?.filter((item) => {
            return item?.is_bank;
          });
          setBankData(filtersBank);
          const filtersCard = accounts?.filter((item) => {
            return item?.is_bank == undefined;
          });
          setCardData(filtersCard);
          accounts?.forEach((acc) => {
            setSwitches((prev) => ({
              ...prev,
              [acc.id]: acc?.is_default || false,
            }));
          });
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [_id]);

  useEffect(() => {
    _id && getAccounts();
  }, [_id, getAccounts]);

  const [open, setOpen1] = useState(false);
  const handleClose = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [open1, setOpen2] = useState(false);
  const handleClose1 = () => setOpen2(false);
  const handleOpen1 = () => setOpen2(true);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const matches = useMediaQuery("(min-width:900px)");
  const matches1 = useMediaQuery("(max-width:899px)");

  const handleCardSelectModalOpen = () => {
    setShowCardSelectModal(true);
  };
  const handleCardSelectModalClose = () => {
    setShowCardSelectModal(false);
  };

  return (
    <Box
      bgcolor={theme.palette.info.light}
      // padding={2}
      // paddingTop={10}
      paddingTop={{ xs: "30px", sm: "30px", md: "80px", lg: "80px" }}
      minHeight={"100vh"}
    >
      {matches && (
        <Container>
          <Paper
            sx={{
              padding: 1,
            }}
          >
            {/* {isLoading && <Skeleton count={2} height='30px' />} */}
            {/* {!isLoading && cardData?.length === 0 && <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <img src="/images/Illustration.png" width={"220px"} />
                           
                        </Box>} */}
            {cardData?.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/Illustration.png" width={"220px"} />
              </Box>
            )}
            <TableContainer sx={{ marginY: 2 }}>
              <Table aria-label="simple table">
                {/* <TableHead>
                                <TableRow>
                                    {headings?.map((head, index) => (
                                        <TableCell
                                            key={`${index}-table-heading-${head}`}
                                            align={'center'}
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {head}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead> */}

                <TableBody>
                  {/* <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow> */}

                  {cardData?.map((row, index) => {
                    const {
                      user,
                      exp_year,
                      exp_month,
                      billing_address,
                      is_default,
                      id: cardId,
                      number: card_number,
                      brand,
                      account_name,
                      is_bank,
                    } = row || {};

                    return (
                      <TableRow key={`${index}-${row?.cardId}`}>
                        <TableCell align="center">
                          <CardBrandToRender brand={brand} />
                        </TableCell>
                        <TableCell align="center">{account_name}</TableCell>
                        <TableCell align="center">
                          {card_number?.substr(-4)}
                        </TableCell>
                        <TableCell align="center">
                          {`${exp_month}/${exp_year}`}
                        </TableCell>
                        <TableCell align="center" sx={{ maxWidth: "150px" }}>
                          {billing_address}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={!!switches[cardId]}
                            onChange={(e) => handleToggleSwitches(e, cardId)}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <div onClick={() => handleDeleteCard(row)}>
                            <img
                              src="/images/delete_icon.png"
                              width={20}
                              height={20}
                              style={{
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </TableCell>

                        {/* <TableCell align='center'>
                                                ${pp_fees ? pp_fees : 0} + $
                                                {amount +
                                                    (pp_fees
                                                        ? pp_fees
                                                        : 0)}{' '}
                                                = ${totalAmount?.toFixed(2)}
                                            </TableCell> */}
                        {/* <TableCell align='center'>
                                                $
                                                {amount +
                                                    (pp_fees ? pp_fees : 0)}
                                            </TableCell> */}
                      </TableRow>
                    );
                  })}

                  {isLoading && (
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                                        colSpan={5}
                                        align='right'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ${totalAmount?.toFixed(2)}
                                    </TableCell> */}
                  </TableRow>

                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                    colSpan={5}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    ${totalAmount}
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {showCardSelectModal && (
              <Modal
                open={showCardSelectModal}
                onClose={handleCardSelectModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  {/* <Box textAlign={'right'}>
                                        <img
                                            src='/images/closepopup.svg'
                                            width={'12px'}
                                            onClick={handleCardSelectModalClose}
                                            alt={'close'}
                                        />
                                    </Box> */}
                  <IconButton
                    aria-label="close"
                    onClick={handleCardSelectModalClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      paddingTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    Select a new primary payment method
                  </Typography>
                  {/* {data?.map((row, index) => {
                                        const {
                                            exp_year,
                                            exp_month,
                                            billing_address,
                                            is_default,

                                            id: cardId,
                                            number: card_number,
                                            brand,
                                            is_bank,
                                        } = row || {}
                                        return (
                                            <div
                                                key={`${index}--${row?.cardId}`}
                                                className='tf-card-item'
                                            >
                                                <div className='tf-card-item-left'>
                                                    <CardBrandToRender
                                                        brand={brand}
                                                    />
                                                    <div>
                                                        ****
                                                        {card_number?.substr(
                                                            -4
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    <Switch
                                                        checked={
                                                            !!switches[cardId]
                                                        }
                                                        onChange={e =>
                                                            handleToggleSwitches(
                                                                e,
                                                                cardId
                                                            )
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })} */}
                  {/* <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-evenly"}
                                        paddingTop={"40px"}
                                    >
                                        <Box>
                                            <Button
                                                variant='contained'
                                                onClick={
                                                    handleCardSelectModalClose
                                                }
                                            >
                                                Done
                                            </Button>
                                        </Box>
                                    </Box> */}
                </Box>
              </Modal>
            )}
          </Paper>
          {/* <Box paddingY={2} display={'flex'} justifyContent={'center'}>
                    <Pagination
                        count={totalPages}
                        color='primary'
                        page={page}
                        variant='contained'
                        sx={{
                            [`& .${paginationItemClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                                ':hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },
                            [`& .${buttonClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                            },
                        }}
                        onChange={handleChangePage}
                    />
                </Box> */}
        </Container>
      )}

      {matches && (
        <Container>
          <Box paddingY={1} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCardOpen}
            >
              Add Another Card
            </Button>
          </Box>
        </Container>
      )}

      <Box
        bgcolor={theme.palette.info.light}
        padding={0.5}
        // paddingTop={10}
        // minHeight={"100vh"}
      >
        {matches1 && (
          <Container padding={0}>
            <Paper
              sx={{
                padding: 2,
                width: "379px",
              }}
            >
              {data?.map((row, index) => {
                const {
                  user,
                  exp_year,
                  exp_month,
                  billing_address,
                  is_default,
                  id: cardId,
                  number: card_number,
                  brand,
                  account_name,
                } = row || {};
                return (
                  <ul
                    style={{
                      listStyleType: "none",
                      borderBottom: "1px solid #c4c4c4",
                      paddingInlineStart: "0px",
                    }}
                  >
                    <li
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        class="custom-radios"
                      >
                        {/* <Switch
                        checked={!!switches[cardId]}
                        onChange={(e) => handleToggleSwitches(e, cardId)}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      /> */}

                        <div class="py">
                          <label>
                            <input
                              type="radio"
                              class="option-input radio"
                              name="example"
                              onChange={(e) => handleToggleSwitches(e, cardId)}
                              checked={!!switches[cardId]}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          marginRight: "13px",
                          marginLeft: "13px",
                        }}
                      >
                        <CardBrandToRender brand={brand} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: "1",
                          marginRight: "40px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              fontFamily: "Circular Std",
                              fontWeight: "500",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#666666",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              <CardBrandNameToRender brand={brand} />
                            </div>
                            <div> {billing_address}</div>
                            <div></div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Circular Std",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#666666",
                            // marginLeft: "15px",
                            flexDirection: "column",
                          }}
                        >
                          <div></div>
                          {/* <div>{row?.cause?.title}</div> */}
                          <div>{account_name}</div>
                          <div> {card_number?.substr(-4)}</div>
                        </div>
                      </div>
                      <div
                        onClick={() => handleDeleteCard(row)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/images/delete_icon.png"
                          width={20}
                          height={20}
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                );
              })}
              <Box paddingY={1} sx={{ textAlign: "center" }}>
                <Button startIcon={<AddIcon />} onClick={handleCardOpen}>
                  Add Another Card
                </Button>
              </Box>
            </Paper>
          </Container>
        )}
      </Box>

      {matches && (
        <Container>
          <Paper
            sx={{
              padding: 1,
            }}
          >
            {/* {bankData?.length === 0 && <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <img src="/images/Illustration.png" width={"220px"} />
                        </Box>} */}
            {/* {isLoading && <Skeleton count={2} height='30px' />} */}
            {/* {!isLoading && bankData?.length === 0 && <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <img src="/images/Illustration.png" width={"220px"} />
                           
                        </Box>} */}
            {bankData?.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/Illustration.png" width={"220px"} />
              </Box>
            )}
            <TableContainer sx={{ marginY: 2 }}>
              <Table aria-label="simple table">
                {/* <TableHead>
                                <TableRow>
                                    {headings?.map((head, index) => (
                                        <TableCell
                                            key={`${index}-table-heading-${head}`}
                                            align={'center'}
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {head}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead> */}

                <TableBody>
                  {/* <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow> */}

                  {bankData?.map((row, index) => {
                    const {
                      user,
                      exp_year,
                      exp_month,
                      billing_address,
                      is_default,
                      id: cardId,
                      number: card_number,
                      brand,
                      account_name,
                      account_number,
                      bsb_number,
                      is_bank,
                    } = row || {};
                    return (
                      <TableRow key={`${index}-${row?.cardId}`}>
                        <TableCell align="center">
                          <img src={bank} width={"30px"} />
                        </TableCell>
                        <TableCell align="center">{account_name}</TableCell>
                        <TableCell align="center">{account_number}</TableCell>
                        <TableCell align="center">{bsb_number}</TableCell>
                        <TableCell
                          align="center"
                          sx={{ minWidth: "120px" }}
                        ></TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={!!switches[cardId]}
                            onChange={(e) => handleToggleSwitches(e, cardId)}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <div onClick={() => handleDeleteCard(row)}>
                            <img
                              src="/images/delete_icon.png"
                              width={20}
                              height={20}
                              style={{
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </TableCell>

                        {/* <TableCell align='center'>
                                                ${pp_fees ? pp_fees : 0} + $
                                                {amount +
                                                    (pp_fees
                                                        ? pp_fees
                                                        : 0)}{' '}
                                                = ${totalAmount?.toFixed(2)}
                                            </TableCell> */}
                        {/* <TableCell align='center'>
                                                $
                                                {amount +
                                                    (pp_fees ? pp_fees : 0)}
                                            </TableCell> */}
                      </TableRow>
                    );
                  })}

                  {isLoading && (
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                                        colSpan={5}
                                        align='right'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ${totalAmount?.toFixed(2)}
                                    </TableCell> */}
                  </TableRow>

                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                    colSpan={5}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    ${totalAmount}
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {showCardSelectModal && (
              <Modal
                open={showCardSelectModal}
                onClose={handleCardSelectModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  {/* <Box textAlign={'right'}>
                                        <img
                                            src='/images/closepopup.svg'
                                            width={'12px'}
                                            onClick={handleCardSelectModalClose}
                                            alt={'close'}
                                        />
                                    </Box> */}
                  <IconButton
                    aria-label="close"
                    onClick={handleCardSelectModalClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={error} />
                  </Box>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      paddingTop: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    To keep the subscriptions active, you need to add
                    alternative payment method
                  </Typography>

                  {/* {data?.map((row, index) => {
                                        const {
                                            exp_year,
                                            exp_month,
                                            billing_address,
                                            is_default,
                                            id: cardId,
                                            number: card_number,
                                            brand,
                                            is_bank,
                                        } = row || {}
                                        return (
                                            <div
                                                key={`${index}--${row?.cardId}`}
                                                className='tf-card-item'
                                            >
                                                <div className='tf-card-item-left'>
                                                    <CardBrandToRender
                                                        brand={brand}
                                                    />
                                                    <div>
                                                        ****
                                                        {card_number?.substr(
                                                            -4
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    <Switch
                                                        checked={
                                                            !!switches[cardId]
                                                        }
                                                        onChange={e =>
                                                            handleToggleSwitches(
                                                                e,
                                                                cardId
                                                            )
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}*/}
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-evenly"}
                    paddingTop={"40px"}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        onClick={handleCardSelectModalClose}
                      >
                        Okay
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </Paper>
          {/* <Box paddingY={2} display={'flex'} justifyContent={'center'}>
                    <Pagination
                        count={totalPages}
                        color='primary'
                        page={page}
                        variant='contained'
                        sx={{
                            [`& .${paginationItemClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                                ':hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },
                            [`& .${buttonClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                            },
                        }}
                        onChange={handleChangePage}
                    />
                </Box> */}
        </Container>
      )}

      {matches && (
        <Container>
          <Box paddingY={1} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleBankOpen}
            >
              Add Another Bank
            </Button>
          </Box>
        </Container>
      )}

      <Box
        bgcolor={theme.palette.info.light}
        padding={0.5}
        paddingTop={10}
        minHeight={"100vh"}
      >
        {matches1 && (
          <Container padding={0}>
            <Paper
              sx={{
                padding: 2,
                width: "379px",
              }}
            >
              {data?.map((row, index) => {
                const {
                  user,
                  exp_year,
                  exp_month,
                  billing_address,
                  is_default,
                  id: cardId,
                  number: card_number,
                  brand,
                  account_name,
                } = row || {};
                return (
                  <ul
                    style={{
                      listStyleType: "none",
                      borderBottom: "1px solid #c4c4c4",
                      paddingInlineStart: "0px",
                    }}
                  >
                    <li
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        class="custom-radios"
                      >
                        {/* <Switch
                        checked={!!switches[cardId]}
                        onChange={(e) => handleToggleSwitches(e, cardId)}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      /> */}

                        <div class="py">
                          <label>
                            <input
                              type="radio"
                              class="option-input radio"
                              name="example"
                              onChange={(e) => handleToggleSwitches(e, cardId)}
                              checked={!!switches[cardId]}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          marginRight: "13px",
                          marginLeft: "13px",
                        }}
                      >
                        <CardBrandToRender brand={brand} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: "1",
                          marginRight: "40px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              fontFamily: "Circular Std",
                              fontWeight: "500",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#666666",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              <CardBrandNameToRender brand={brand} />
                            </div>
                            <div> {billing_address}</div>
                            <div></div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Circular Std",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#666666",
                            // marginLeft: "15px",
                            flexDirection: "column",
                          }}
                        >
                          <div></div>
                          {/* <div>{row?.cause?.title}</div> */}
                          <div>{account_name}</div>
                          <div> {card_number?.substr(-4)}</div>
                        </div>
                      </div>
                      <div
                        onClick={() => handleDeleteCard(row)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/images/delete_icon.png"
                          width={20}
                          height={20}
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                );
              })}
              <Box paddingY={1} sx={{ textAlign: "center" }}>
                <Button startIcon={<AddIcon />} onClick={handleCardOpen}>
                  Add Another Card
                </Button>
              </Box>
            </Paper>
          </Container>
        )}
      </Box>

      {openCardLink && (
        <CardModal open={openCardLink} handleClose={handleCardClose} isReload />
      )}
      {openBankLink && (
        <BankModal2
          open={openBankLink}
          handleClose={handleBankClose}
          isReload
        />
      )}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const CardBrandToRender = ({ brand }) => {
  let imgSrc = null;
  if (brand === "Visa") imgSrc = "/images/Visa.png";
  if (brand === "MasterCard") imgSrc = "/images/Mastercard.png";
  if (!brand) {
    return null;
  } else {
    return (
      <img
        src={imgSrc}
        width={45}
        height={45}
        style={{ objectFit: "contain" }}
      />
    );
  }
};
const CardBrandNameToRender = ({ brand }) => {
  let name = null;
  if (brand === "Visa") name = "Visa";
  if (brand === "MasterCard") name = "Mastercard";
  if (!brand) {
    return null;
  } else {
    return <span>{name}</span>;
  }
};

export default Index;
