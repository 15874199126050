import React from "react";

//package import

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon, Edit as EditIcon } from "@mui/icons-material";

//component

const ConfirmModal = ({ handleClose, open }) => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontWeight={"bold"} align="center">
          Cancel Round Up
        </Typography>

        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Box padding={1}>
            <Button
              variant="contained"
              sx={{
                fontSize: 12,
                paddingX: 2,
              }}
              onClick={handleClose}
            >
              Confirm
            </Button>
          </Box>
          <Box padding={1}>
            <Button
              variant="contained"
              sx={{
                fontSize: 12,
                paddingX: 2,
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
