import React, { useState, useEffect, useContext, useCallback } from "react";

//package imports

import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Pagination,
  useTheme,
  Typography,
} from "@mui/material";

//user import

import "./feed.css";
import { API_URLS } from "app/Services/organization/Routes/API";
// import { API_URLS } from "../../Routes/API";
import { simpleGet, headersDel } from "app/Services/organization/API";
import CreateFeedModal from "./CreateFeed";
import FeedCard from "./FeedCard";
import { context } from "app/context";
import useOrgRedirect from "app/utils/organization/hooks/useOrgRedirect";

//component

export default function Feed() {
  //hooks
  useOrgRedirect();

  const { orgDetails, setOrgDetails } = useContext(context);
  const theme = useTheme();

  //states

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [search] = useState("");
  const [api, setApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState(null);

  //functions

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //get all feeds of org

  const getAllFeeds = useCallback(() => {
    setApi(false);
    let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${10}&organisation=${
      orgDetails?.id
    }${search && `&name=${search}`}`;
    setOpen(true);
    simpleGet(url)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && res?.data?.status) {
          const newData = res?.data?.causes?.results.sort();
          setData(newData);
          setTotalPages(res?.data?.causes?.totalPages);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [page, search, orgDetails]);

  useEffect(() => {
    api && orgDetails && getAllFeeds();
  }, [api, orgDetails, getAllFeeds]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setApi(true);
  };

  //edit handler

  const editHandler = (data) => {
    setSelected(data);
    setIsEdit(true);
    handleShow();
  };

  const addHandler = () => {
    setSelected(null);
    setIsEdit(false);
    handleShow();
  };

  //view handler

  const delHandler = (id) => {
    setOpen(true);
    headersDel(`${API_URLS?.deleteFeed}?feedId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setData((prev) => prev?.filter((val) => val.id !== id));
          setAlertOpen(true);
          setAlertMsg("Feed is deleted successfully.");
          setAlertIcon("success");
          setApi(true);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //render

  return (
    <div style={{ paddingTop: "30px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3">
            <div className="active-title">
              <button
                className="btn btn-pink create-post-btn"
                onClick={addHandler}
              >
                CREATE A POST{" "}
                <img
                  src="/images/create.svg"
                  alt="create"
                  style={{ paddingLeft: "20px" }}
                />
              </button>
            </div>
            <div className="feeds">
              {data &&
                data?.length > 0 &&
                data?.map((feed, organisation, index) => (
                  <FeedCard
                    feed={feed}
                    key={`${index}-org-${feed?.organisation_name}-${feed?.id}`}
                    editHandler={editHandler}
                    delHandler={delHandler}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <Box display={"flex"} justifyContent={"center"} marginY={3}>
        {data?.length > 0 ? (
          totalPages >= 1 && (
            <Box
              bgcolor={theme.palette.common.white}
              padding={1}
              borderRadius={25}
            >
              <Pagination
                count={totalPages}
                color="primary"
                page={page}
                variant="contained"
                onChange={handleChangePage}
              />
            </Box>
          )
        ) : (
          <Box
            bgcolor={theme.palette.common.white}
            padding={5}
            borderRadius={1}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h4"}
              sx={{ color: "#000" }}
            >
              No Feed Found
            </Typography>
          </Box>
        )}
      </Box>
      {show && (
        <CreateFeedModal
          show={show}
          handleClose={handleClose}
          isEdit={isEdit}
          selected={selected}
          setApi={setApi}
        />
      )}

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
