import * as Yup from 'yup'

import {variables} from '../variables'

const {required, IsEmail} = variables

export const contactValidation = Yup.object({
    email: Yup.string().required(required).email(IsEmail),
    name: Yup.string().required(required),
    organisation: Yup.string().required(required),
    description: Yup.string().required(required),
})
