import React, { useState, useEffect, useCallback } from "react";

//packages imports

import { useParams } from "react-router-dom";

//user imports

import OrgBillingCard from "./OrgBillingCard";
// import { API_Urls } from "../../Services/Routes/API";
import OrgBankDetailModal from "./OrgBankDetailModal";
import OrgCard from "./OrgCard";
import { Toast } from "app/common/admin/alert";
import { API_Urls } from "app/Services/admin/Routes/API";
import { headersDel, headersGet, simpleGet } from "app/Services/admin/API";

//component

export default function OrganisationBilling() {
  //hooks

  const { id } = useParams();

  //states

  const [show, setShow] = useState(false);
  const [, setOpen] = useState(false);
  const [orgDetail, setOrgDetail] = useState(null);
  const [api, setApi] = useState(true);
  // const [causes, setCauses] = useState([])
  //modal handler

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //get org detail

  const getOrgDetail = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(`${API_Urls?.get_org_detail}?organisationId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setOrgDetail(res?.data?.organisationDetails);
        } else {
          Toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message);
      });
  }, [id]);

  // const getAllCauses = useCallback(() => {
  //     setOpen(true)
  //     setApi(false)
  //     simpleGet(
  //         `${API_Urls?.get_causes_by_org}?organisation=${id}&page=1&limit=9999`
  //     )
  //         .then(res => {
  //             setOpen(false)
  //             if (res?.status === 200) {
  //                 console.log('====================================')
  //                 console.log({res})
  //                 console.log('====================================')
  //                 setCauses(res?.data?.causes)
  //             } else {
  //                 Toast.error(res?.data?.message)
  //             }
  //         })
  //         .catch(err => {
  //             const {response} = err
  //             setOpen(false)
  //             Toast.error(response?.data?.message)
  //         })
  // }, [id])

  useEffect(() => {
    id && api && getOrgDetail();
  }, [id, api, getOrgDetail]);

  // useEffect(() => {
  //     id && api && getAllCauses()
  // }, [id, api, getAllCauses])

  //del post

  const delPostHandler = (id) => {
    setOpen(true);

    headersDel(`${API_Urls?.del_org_cause}?causeId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && res?.data?.status) {
          setApi(true);
          Toast.success("Organisation cause is deleted successfully.");
        } else {
          Toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message);
      });
  };

  //render
  return (
    <div className="container">
      <div className="row justify-content-center my-5">
        <div className="col-lg-12">
          <div className="text-center">
            <div className="organisationType-logo">
              <img
                src={
                  orgDetail?.organisation_image?.length > 0
                    ? orgDetail?.organisation_image[0]
                    : "/images/mouse.jpg"
                }
                alt="organ-type-logo"
                width={"100%"}
              />
            </div>
            <p className="organisation-name-type pt-2">{orgDetail?.name}</p>
            <p className="organisation-name-type-cause">{orgDetail?.cause}</p>
            <div className="billing-btn">
              <button className="btn btn-pink" onClick={handleShow}>
                VIEW BILLING DETAILS
              </button>
            </div>
            <div className="cards-billing">
              {orgDetail?.causes?.map((card, index) => (
                <>
                  <OrgCard
                    cause={card}
                    org={orgDetail}
                    delPostHadler={delPostHandler}
                    cardId={card?.id}
                    bahir={true}
                    key={`${index}-card-${card?.id}`}
                  />
                  {/* <OrgBillingCard
                                        card={card}
                                        org={orgDetail}
                                        delPostHadler={delPostHandler}
                                        key={`${index}-card-${card?.id}`}
                                    /> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <OrgBankDetailModal
        show={show}
        handleClose={handleClose}
        id={id}
        orgDetail={orgDetail}
      />
    </div>
  );
}
