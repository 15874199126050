import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

//package import

import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Pagination,
  Tooltip,
} from "@mui/material";
import { buttonClasses } from "@mui/material/Button";
import { paginationItemClasses } from "@mui/material/PaginationItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
//user import

import { DownloadIcon, PrintIcon } from "app/svg/user/DonationHistory";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fontSize } from "@mui/system";
import {
  formatCategory,
  formatDateTime,
  formatDateTimeOnly,
  formatDate,
} from "app/common/user/functions";
import { headersGet } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import { context } from "app/context";

//constants

const headings = [
  "Date",
  "Organisation",
  "Category",
  "Cause Name",
  "Type",
  "Card Info",
  "Donation Amount",
  "Fees",
  "Total",
];

//component

const DonationHistory = () => {
  const theme = useTheme();
  const { user } = useContext(context);
  const { _id } = user || {};

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [alertIcon, setAlertIcon] = useState("error");
  const [currentDay, setCurrentDay] = useState("last_month");
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [exportTriggered, setExportTriggered] = useState(false);

  console.log("🚀 ~ DonationHistory ~ allData:", allData);

  const getHistory = useCallback(
    (fetchAllPages = false) => {
      setOpen(true);

      const fetchPage = (pageNum) => {
        const data = `page=${pageNum}&limit=${10}&user=${_id}&currentDay=${currentDay}`;
        return headersGet(`${API_URLS?.userTransactions}?${data}`).then(
          (res) => {
            const { data, status } = res;
            if (status === 200) {
              const { results, totalPages: pages } = data || {};
              return { results, pages };
            } else {
              throw new Error(res?.data?.message);
            }
          }
        );
      };

      if (fetchAllPages) {
        const fetchAll = async () => {
          try {
            let allResults = [];
            let currentPage = 1;
            let totalPageCount = 1;

            while (currentPage <= totalPageCount) {
              const { results, pages } = await fetchPage(currentPage);
              allResults = [...allResults, ...results];
              totalPageCount = pages;
              currentPage++;
            }

            setAllData(allResults);
            setOpen(false);
          } catch (err) {
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(err.message);
            setAlertIcon("error");
          }
        };

        fetchAll();
      } else {
        fetchPage(page)
          .then(({ results, pages }) => {
            setData(results);
            setTotalPages(pages);
            setIsLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(err.message);
            setAlertIcon("error");
          });
      }
    },
    [page, _id, currentDay]
  );

  useEffect(() => {
    _id && getHistory();
  }, [_id, getHistory]);

  useMemo(() => {
    if (data.length) {
      const amount = data.reduce((prev, current) => {
        const { amount } = current || {};
        return prev + amount;
      }, 0);
      amount && setTotalAmount(amount);
    }
  }, [data]);

  // Watch for allData to be populated and export PDF if triggered
  useEffect(() => {
    if (exportTriggered && allData.length > 0) {
      generatePDF();
      setExportTriggered(false); // Reset the trigger
    }
  }, [allData, exportTriggered]);

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const exportPDF = () => {
    setExportTriggered(true); // Set the trigger to true
    getHistory(true); // Fetch all pages before exporting
  };

  const generatePDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Donations History";
    const headers = [
      [
        "DATE",
        "Organisation",
        "CATEGORY",
        "CAUSE NAME",
        "TYPE",
        "DONATION AMOUNT",
        "FEES",
        "TOTAL",
      ],
    ];

    const data1 = allData.map((elt) => [
      formatDate(elt.updatedAt),
      elt.organisation.name,
      formatCategory(elt.organisation.category),
      elt.organisation.company_name,
      formatCategory(elt.type),
      elt.amount.toFixed(2),
      elt?.pp_fees?.toFixed(2),
      (
        parseFloat(elt.amount) + (elt?.pp_fees ? parseFloat(elt.pp_fees) : 0)
      ).toFixed(2),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data1,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Donations History.pdf");
  };

  const matches = useMediaQuery("(min-width:1025px)");
  const matches1 = useMediaQuery("(max-width:700px)");

  const handleCurrectDay = (e) => {
    setCurrentDay(e.target.value);
    setPage(1);
  };

  return (
    <Box
      bgcolor={theme.palette.info.light}
      padding={2}
      paddingTop={10}
      minHeight={"100vh"}
    >
      <Container>
        <Typography color={"text.red"} gutterBottom paddingBottom={2}>
          My Account - Donations History
        </Typography>

        <Paper
          sx={{
            padding: 2,
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <TextField select value={currentDay} onChange={handleCurrectDay}>
                <MenuItem value={"last_day"}>Last Day</MenuItem>
                <MenuItem value={"last_week"}>Last Week</MenuItem>
                <MenuItem value={"last_month"}>Last Month</MenuItem>
              </TextField>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {/* <Box paddingY={1}>
                <Tooltip
                  title="Request Tax Statement"
                  placement="top-end"
                  color={"primary"}
                  enterTouchDelay={0}
                  arrow
                >
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{
                      paddingX: 2,
                      textTransform: "initial",
                    }}
                  >
                    {matches && <>Request Tax Statement</>}
                    {matches1 && <>RTS</>}
                  </Button>
                </Tooltip>
              </Box> */}
              <Box>
                <IconButton onClick={() => exportPDF()}>
                  <DownloadIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <TableContainer sx={{ marginY: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headings?.map((head, index) => (
                    <TableCell
                      key={`${index}-table-heading-${head}`}
                      align={"center"}
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {!isLoading && data && data?.length === 0 && (
                  <>
                    <TableRow>
                      <TableCell align="center" colSpan={9}>
                        No Data Found!
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {data &&
                  data?.length > 0 &&
                  data?.map((row, index) => {
                    const {
                      amount,
                      organisation,
                      createdAt,
                      pp_fees,
                      type,
                      cause,
                      bank,
                    } = row || {};
                    const { category, company_name, name } = organisation;
                    const { title } = cause || {};
                    // const {number} = bank
                    return (
                      <TableRow key={`${index}-${row?.date}`}>
                        <TableCell align="center">
                          {formatDate(createdAt)}
                          <br></br>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#9094a2",
                            }}
                          >
                            {formatDateTimeOnly(createdAt)}
                          </span>
                        </TableCell>
                        <TableCell align="center">{name}</TableCell>
                        <TableCell align="center">
                          {formatCategory(category)}
                        </TableCell>
                        <TableCell align="center">
                          {/* {company_name} */}
                          {title || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {formatCategory(type)}
                        </TableCell>
                        <TableCell align="center">
                          {bank?.number?.substr(-4) ||
                            bank?.account_number ||
                            "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          ${amount?.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          ${pp_fees ? pp_fees?.toFixed(2) : 0}
                        </TableCell>
                        <TableCell align="center">
                          $
                          {(
                            parseFloat(amount) +
                            (pp_fees ? parseFloat(pp_fees) : 0)
                          )?.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {isLoading && (
                  <TableRow>
                    <TableCell align="center">
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#9094a2",
                        }}
                      >
                        <Skeleton count={1} height="30px" />
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                  </TableRow>
                )}
                {data && data?.length > 0 && (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    <TableCell
                      colSpan={5}
                      align="right"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      ${totalAmount?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box paddingY={2} display={"flex"} justifyContent={"center"}>
          <Pagination
            count={totalPages}
            color="primary"
            page={page}
            variant="contained"
            sx={{
              [`& .${paginationItemClasses.root}`]: {
                backgroundColor: theme.palette.common.white,
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              },
              [`& .${buttonClasses.root}`]: {
                backgroundColor: theme.palette.common.white,
              },
            }}
            onChange={handleChangePage}
          />
        </Box>
      </Container>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DonationHistory;
