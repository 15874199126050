import React from "react";

//user import

import AuthOrganisation from "app/components/user/AuthOrganisation";
import { Layout } from "app/components/user";

const AuthOrg = () => {
  return (
    <Layout>
      <AuthOrganisation />
    </Layout>
  );
};

export default AuthOrg;
