import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useContext,
} from "react";

//package imports

import {
  Box,
  Typography,
  useTheme,
  TextField,
  Container,
  Grid,
  Avatar,
  Backdrop,
  Alert,
  CircularProgress,
  Snackbar,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import { useLocation, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Search as SearchIcon } from "@mui/icons-material";
// components
import OrgCard from "./OrgCard";
import LoginModal from "../LoginModal";
import SupportModal from "../SupportModal";
import { FunnelIcon } from "app/svg/user/Discover";
// services
// import { context } from "../../context";

import useQuery from "app/utils/user/useQuery";
import { API_URLS } from "app/Services/user/routes/API";
import { headersDel, simpleGet } from "app/Services/user/API";
import { userRoutes } from "app/Services/user/routes/APP";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { CATEGORIES_ARRAY, CATEGORIES } from "app/common/user/Enums";
import { context } from "app/context";

//constants

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const categoriesSelectInitial = {
  all: false,
  animals_and_wildlife: false,
  environment_and_conservation: false,
  people_and_community: false,
  health_and_medical: false,
  disaster_response: false,
  arts_and_culture: false,
  religion_and_religious_groups: false,
  overseas_aid_and_development: false,
};

const useStyles = makeStyles((theme) => {
  return {
    backgroundmobile: {
      [theme.breakpoints.down("sm")]: {
        height: "187px",
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        height: "250px",
        width: "100%",
      },
      [theme.breakpoints.down("lg")]: {
        height: "300px",
        width: "100%",
      },
    },
  };
});

//component

const Discover = ({ bahir }) => {
  const [categoriesSelectState, setCategoriesSelectState] = useState(
    categoriesSelectInitial
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [isFromHome, setIsFromHome] = useState(false);
  const [isFromLoginPopup, setIsFromLoginPopup] = useState(false);
  const [user2, setUser2] = useState(null);

  //hooks
  const theme = useTheme();
  const classes = useStyles();
  const isLogIn = isLoggedIn();
  const { pathname } = useLocation();
  const { user, isRoundup, loginModalState } = useContext(context);
  const [queryParams] = useSearchParams();

  let newCategoriesArray = CATEGORIES_ARRAY;

  const { isOnboard } = user || {};

  useEffect(() => {
    if (user2 === null && (!bahir || isLogIn) && isOnboard) {
      newCategoriesArray = CATEGORIES_ARRAY.filter((cat) => {
        const catVal = cat.value;
        if (user?.category[catVal]?.length > 0) {
          return catVal;
        }
      });
    }
  }, [bahir, user, isLogIn, user2]);

  useEffect(() => {
    if (user2 === null && (!bahir || isLogIn) && isOnboard) {
      const stateNewCatArr = newCategoriesArray.map((newCat) => {
        const catVal = newCat?.value;
        setCategoriesSelectState((prevState) => {
          return {
            ...prevState,
            [catVal]: !prevState[catVal],
          };
        });
        setSelectedCategories((prevState) => {
          return [...prevState, catVal];
        });
        // if (Array.isArray(category)) {
        setCategory((prevState) => {
          return [...prevState, newCat?.value];
        });
        // } else {
        //     setCategory([newCat?.value])
        // }
        return catVal;
      });
      // console.log("====================================");
      // console.log({ stateNewCatArr });
      // console.log("====================================");
      if (stateNewCatArr?.length > 0) {
        setApi(true);
      }
    }
  }, [newCategoriesArray, bahir, user, isLogIn, user2, isOnboard]);

  const query = useQuery();
  const target = query.get("target");
  const jobId = query.get("jobId");
  const searchQueryParam = query.get("searchQuery");
  const categoryParam = query.get("category");
  const locationParam = query.get("location");
  // console.log("====================================");
  // console.log({ jobId });
  // console.log("====================================");

  // console.log({ target });
  //de structuring

  const { _id: userId } = user || {};
  const userFeedPath = pathname?.includes(userRoutes?.user);

  //states

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [api, setApi] = useState(true);
  // const [category, setCategory] = useState(
  //     !bahir ? newCategoriesArray[0]?.value : CATEGORIES.ALL
  // )
  // console.log({
  //   condition: !bahir ? newCategoriesArray[0]?.value : CATEGORIES.ALL,
  // });
  const [searchQuery, setSearchQuery] = useState("");
  const [location, setLocation] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [supportState, setSupportState] = useState({
    cause: "",
    org: "",
    causeName: "",
  });

  const handleLoginOpen = (supportSt) => {
    setLoginModal(true);
    setSupportState(supportSt);
  };

  const handleLoginClose = () => {
    setLoginModal(false);
  };

  const handleLoginModalSuccess = (user, supportSt) => {
    setIsFromLoginPopup(true);
    setUser2(user);
    setSupportState(supportSt);
    setSupportOpen(true);
  };

  //get all causes

  const getAllCauses = () => {
    setOpen(true);
    setApi(false);

    //  category && category !== 'all'
    //      ? `&category=${
    //            Array.isArray(category) && category?.length > 0
    //                ? category.join(',')
    //                : category
    //        }`
    //      : ''
    // console.log("YE WALA CHALA HA", category);
    simpleGet(
      `${API_URLS?.getAllCauses}?page=1&limit=${5}&populate=organisation${
        // }?populate=${`organisation`}${
        searchQuery?.length >= 3 ? `&searchQuery=${searchQuery}` : ""
      }${
        category && category.length > 0 ? `&category=${category.join(",")}` : ""
      }`
    )
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setData(res?.data?.causes?.results);
          setTotalPages(res?.data?.causes?.totalPages);
        } else {
          // setAlertOpen(true)
          // setAlertMsg(res?.data?.message)
          // setAlertIcon('warning')
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        // setAlertOpen(true)
        // setAlertMsg(response?.data?.message)
        // setAlertIcon('error')
      })
      .finally(() => {
        const targetEl = document.getElementById(target);
        const targetElPos = targetEl && targetEl.getBoundingClientRect();
        if (targetElPos) {
          window.scrollTo(targetElPos.x, targetElPos.y);
        }
      });
  };
  //life cycle hooks
  useEffect(() => {
    if (api && (searchQueryParam || categoryParam || locationParam)) {
      // console.log("PARAM WALI CALL");
      setIsFromHome(true);
      setOpen(true);
      setApi(false);
      simpleGet(
        `${API_URLS?.getAllCauses}?page=1&limit=${999}&populate=organisation${
          // }?populate=${`organisation`}${
          searchQueryParam?.length >= 3
            ? `&searchQuery=${searchQueryParam}`
            : ""
        }${categoryParam ? `&category=${categoryParam}` : ""}`
      )
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            setData(res?.data?.causes?.results);
            setTotalPages(res?.data?.causes?.totalPages);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
    if (api && (!searchQueryParam || !categoryParam || !locationParam)) {
      // console.log("WITHOUT PARAM CALL");
      setIsFromHome(false);
      if (user2 === null) getAllCauses();
    }
  }, [api, getAllCauses]);

  const alertCloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setApi(true);
  };

  //category change handler

  const categoryChangeHandler = (e, cat) => {
    const catVal = cat?.value;
    // console.log("====================================");
    // console.log({ e, cat });
    // console.log("====================================");
    // setCategory(cat?.value)

    if (cat?.value && cat?.value !== "all") {
      setCategoriesSelectState((prevState) => {
        return {
          ...prevState,
          [catVal]: !prevState[catVal],
          all: false,
        };
      });
      // if (Array.isArray(category)) {
      if (!category?.includes(cat?.value)) {
        setCategory((prevState) => {
          return [...prevState, cat?.value];
        });
      } else {
        setCategory((prevState) => {
          return prevState?.filter((curCat) => cat?.value !== curCat);
        });
      }
      // } else {
      //     setCategory([cat?.value])
      // }
      if (!selectedCategories?.includes(cat?.value)) {
        setSelectedCategories((prevState) => {
          return [...prevState, cat?.value];
        });
      } else {
        setSelectedCategories((prevState) => {
          return prevState?.filter((curCat) => cat?.value !== curCat);
        });
      }
    } else {
      setCategory([]);
      setSelectedCategories([]);
      setCategoriesSelectState({ ...categoriesSelectInitial, all: true });
    }
    setHasMore(true);
    setPage(1);
    setApi(true);
  };

  const searchHandler = (value) => {
    // console.log({ value });
    setSearchQuery(value);
    if (value && value?.length > 3) {
      setApi(true);
    }
    if (value === "") setApi(true);
  };

  // remove fav

  const removeUserFeedFav = async (feedId) => {
    try {
      await headersDel(`${API_URLS?.delUserFavFeed}/${feedId}`);
      setApi(true);
      setAlertOpen(true);
      setAlertMsg("Feed is remove from your favorite successfully.");
      setAlertIcon("success");
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      setAlertOpen(true);
      setAlertMsg(message || "Error found");
      setAlertIcon("error");
    }
  };

  useMemo(() => {
    const loc = queryParams.get("location");
    const cat = queryParams.get("category");
    const search = queryParams.get("search");
    loc && setLocation(loc);
    search && setSearchQuery(search);
    cat &&
      setCategory((prevState) => {
        return [...prevState, cat];
      });
  }, [queryParams]);

  const fetchMoreData = () => {
    // setApi(true)
    if (user2 === null) {
      if (!searchQuery) {
        setPage((prevPage) => {
          return prevPage + 1;
        });
        setOpen(true);
        setApi(false);

        simpleGet(
          `${
            API_URLS?.getAllCauses
          }?page=${page}&limit=${5}&populate=organisation${
            category && category.length > 0
              ? `&category=${category.join(",")}`
              : ""
          }`
        )
          .then((res) => {
            setOpen(false);
            if (res?.status === 200) {
              if (res?.data?.causes?.results.length === 0) {
                setHasMore(false);
              } else {
                if (page === 1) {
                  setData(res?.data?.causes?.results);
                } else {
                  setData((prevData) => {
                    return [...prevData, ...res?.data?.causes?.results];
                  });
                }
                setTotalPages(res?.data?.causes?.totalPages);
              }
            } else {
              setAlertOpen(true);
              setAlertMsg(res?.data?.message);
              setAlertIcon("warning");
            }
          })
          .catch((err) => {
            const { response } = err;
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(response?.data?.message);
            setAlertIcon("error");
          });
      }
      if (isFromHome) {
        setPage((prevPage) => {
          return prevPage + 1;
        });
        setOpen(true);
        setApi(false);

        simpleGet(
          `${
            API_URLS?.getAllCauses
          }?page=${page}&limit=${5}&populate=organisation${
            categoryParam ? `&category=${categoryParam}` : ""
          }${searchQueryParam ? `&searchQuery=${searchQueryParam}` : ""}`
        )
          .then((res) => {
            setOpen(false);
            if (res?.status === 200) {
              if (res?.data?.causes?.results.length === 0) {
                setHasMore(false);
              } else {
                if (page === 1) {
                  setData(res?.data?.causes?.results);
                } else {
                  setData((prevData) => {
                    return [...prevData, ...res?.data?.causes?.results];
                  });
                }
                setTotalPages(res?.data?.causes?.totalPages);
              }
            } else {
              setAlertOpen(true);
              setAlertMsg(res?.data?.message);
              setAlertIcon("warning");
            }
          })
          .catch((err) => {
            const { response } = err;
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(response?.data?.message);
            setAlertIcon("error");
          });
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.common.black,
        }}
      >
        <Box paddingY={3}>
          <Container>
            <Box
              display={{ md: "flex", sm: "flex" }}
              justifyContent={{ sm: "center" }}
              paddingTop={{
                lg: "0px",
                md: "0px",
                xs: "20px",
                sm: "20px",
              }}
            >
              <Box
                width={{
                  md: "60%",
                  ld: "60%",
                  xs: "100%",
                  sm: "100%",
                }}
                pt={2}
              >
                <TextField
                  placeholder="SEARCH FOR CHARITY, NOT FOR PROFIT OR ORGANISATION HERE"
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{
                          color: theme.palette.grey.A400,
                        }}
                      />
                    ),
                    endAdornment: <FunnelIcon />,
                  }}
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    fontFamily: "Montserrat",
                    fontStyle: "italic",
                    border: "none",
                    padding: 1,
                  }}
                  value={searchQuery}
                  onChange={(event) => searchHandler(event.target.value)}
                  fullWidth
                ></TextField>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box
          width={"100%"}
          sx={{
            backgroundColor: theme.palette.primary.light,
            paddingY: 3,
          }}
        >
          <Container>
            <Box className="custom-arrow-bg-white">
              <Carousel responsive={responsive}>
                {CATEGORIES_ARRAY?.map((item, index) => (
                  <Box
                    key={`${item?.id}-car-${index}`}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Checkbox
                      name={"category"}
                      // checked={category === item.value}
                      checked={categoriesSelectState[item?.value] === true}
                      icon={
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{
                            height: 80,
                            width: 80,
                            borderRadius: 10,
                            alignItems: "center",
                            backgroundColor: theme.palette.common.white,
                          }}
                        >
                          <Avatar src={item?.url}></Avatar>
                        </Box>
                      }
                      checkedIcon={
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{
                            height: 80,
                            width: 80,
                            borderRadius: 10,
                            alignItems: "center",
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Avatar src={item?.url}></Avatar>
                        </Box>
                      }
                      onChange={(e) => categoryChangeHandler(e, item)}
                    ></Checkbox>
                    <Typography color={"white"} variant="caption" marginTop={1}>
                      {item?.name}
                    </Typography>
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Container>
        </Box>
        <Box padding={5}>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={8}>
              <Typography
                display={"flex"}
                justifyContent={"left"}
                sx={{
                  fontFamily: "Circular Std",

                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "23px",
                  color: "#ff9776",
                  paddingBottom: "30px",
                }}
                onClick={() => {
                  window.location.href = userRoutes?.user + userRoutes?.cause;
                  // navigate(userRoutes?.user + userRoutes?.cause)
                }}
              >
                My Favourites
              </Typography>
              <Fragment>
                <InfiniteScroll
                  dataLength={data}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4 style={{ color: "white" }}>Loading...</h4>}
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {data?.map((item, index) => {
                    const { organisation, title, organisation_tag } =
                      item || {};
                    const { name, organisation_image } = organisation || {};

                    return (
                      <Box
                        sx={{
                          background: theme.palette.common.white,
                          marginBottom: 2,
                        }}
                        key={`${(index * 2) / 12}-org-card-${item?.id}`}
                      >
                        <Grid container display={"flex"}>
                          <Grid
                            container
                            display={{
                              xs: "block",
                              sm: "block",
                              md: "block",
                              lg: "flex",
                            }}
                          >
                            <Grid
                              className={classes.backgroundmobile}
                              item
                              md={12}
                              lg={6}
                              style={{
                                backgroundImage: `url(${item?.cause_image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "top center",
                              }}
                            ></Grid>
                            <Grid item md={12} lg={6}>
                              <OrgCard
                                cause={item}
                                removeUserFeedFav={removeUserFeedFav}
                                key={`${index}-org-card-${item?.id}`}
                                cardId={item?.id}
                                bahir={bahir}
                                handleLoginOpen={handleLoginOpen}
                                user2={user2}
                                setSupportState={setSupportState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </InfiniteScroll>
              </Fragment>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {supportOpen && (
        <SupportModal
          closeHandler={() => setSupportOpen(false)}
          open={supportOpen}
          causeId={supportState?.cause}
          organisationDoc={supportState?.org}
          causeTitle={supportState?.causeName}
        />
      )}
      {loginModal && (
        <LoginModal
          open={loginModal}
          closeHandler={handleLoginClose}
          handleLoginModalSuccess={handleLoginModalSuccess}
          supportState={supportState}
        />
      )}

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Discover;
