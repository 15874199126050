import React, { Fragment, useState, useEffect, useCallback } from "react";

//packages import

import {
  Form,
  Row,
  Container,
  Col,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { Box, Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import bgLogo from "app/assets/admin/images/bgup4change.png";
import { APP_Urls } from "app/Services/admin/Routes/APP";
import { API_Urls } from "app/Services/admin/Routes/API";
import { headersPost } from "app/Services/admin/API";
import { StorageKeys } from "app/Services/admin/StorageKeys";
import { addValidation } from "app/Services/admin/validations/login";
import Up4changeLogo from "app/svg/admin/Up4changeLogo";
import { toast } from "react-toastify";

//constants

const initialValues = {
  email: "",
  password: "",
};

//component

const LoginPage = () => {
  //hooks

  const navigate = useNavigate();
  const token = localStorage.getItem(StorageKeys.token);

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [showPassword, setShowPassword] = useState(false);

  //form handler

  const submitHandler = (values) => {
    try {
      const data = {
        email: values?.email,
        password: values?.password,
      };
      setOpen(true);
      headersPost(API_Urls?.login, data)
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
            localStorage.setItem(
              StorageKeys?.admin_info,
              JSON.stringify(res?.data?.user)
            );
            navigate(APP_Urls?.admin);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);

          toast.error(response?.data?.message);
          setAlertIcon("danger");
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };

  const navigateDashboard = useCallback(() => {
    navigate(APP_Urls?.admin);
  }, [navigate]);

  useEffect(() => {
    token && navigateDashboard();
  }, [token, navigateDashboard]);

  //render

  return (
    <div className="login-bg ">
      <div>
        <div style={{ position: "absolute", width: "20vw", height: "13.5vw" }}>
          <img
            src={bgLogo}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </div>

        <div className="position-absolute mt-3 ml-3">
          <Up4changeLogo textColor={"white"} />
        </div>
      </div>
      <div className="w-100 " style={{ paddingTop: "17vh" }}>
        <div className="d-flex justify-content-center flex-column ">
          {/* <div className="w-25 mx-auto ">
            {alertOpen && (
              <Alert
                variant={alertIcon}
                onClose={() => setAlertOpen(false)}
                show={alertOpen}
                dismissible
              >
                {alertMsg}
              </Alert>
            )}
          </div> */}

          <div className="mt-3">
            {/* <p className="h1 text-center">Sign In</p> */}
            <Typography
              color={"common.white"}
              variant="h6"
              align="center"
              sx={{
                marginTop: 5,
              }}
            >
              ADMIN
            </Typography>
            <Typography
              color={"common.white"}
              variant="h4"
              align="center"
              sx={{
                marginTop: 1,
              }}
            >
              SIGN IN
            </Typography>
            <Box display={"flex"} justifyContent={"center"} paddingY={2}>
              <Box
                sx={{
                  border: `1px solid #FFD16B`,
                  width: "10%",
                }}
              ></Box>
            </Box>
          </div>
          <div className="mx-auto mt-4 col-11 col-md-5">
            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              validationSchema={addValidation}
            >
              {({ values, touched, errors, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Typography
                      color={"common.white"}
                      fontWeight={"bold"}
                      gutterBottom
                      className="mb-2"
                    >
                      Enter Email
                    </Typography>
                    <TextField
                      fullWidth
                      variant={"outlined"}
                      color="info"
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      sx={{
                        "& input::placeholder": {
                          color: "currentcolor",
                          fontSize: "17px",
                        },
                        "& input": {
                          color: "white",
                        },
                        boxShadow: "0px 1px 3px 0px #e6ebf1",
                      }}
                    ></TextField>

                    <Form.Control.Feedback type={"invalid"}>
                      {errors?.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3 " controlId="validationCustom02">
                    <Typography
                      color={"common.white"}
                      fontWeight={"bold"}
                      gutterBottom
                      className="mt-4 mb-2"
                    >
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      variant={"outlined"}
                      color="info"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      sx={{
                        "& input::placeholder": {
                          color: "currentcolor",
                          fontSize: "17px",
                        },
                        "& input": {
                          color: "white",
                        },
                        boxShadow: "0px 1px 3px 0px #e6ebf1",
                      }}
                      InputProps={{
                        endAdornment: showPassword ? (
                          <Visibility
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ),
                      }}
                    ></TextField>

                    <Form.Control.Feedback type={"invalid"}>
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type={open ? "button" : "submit"}
                      disabled={open}
                      className="btn-block w-50 h6 "
                      style={{
                        backgroundColor: "#FF9776",
                        border: "none",
                        height: "48px",
                        marginTop: "40px",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#66C9D2";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor = "#FF9776";
                      }}
                    >
                      {open ? (
                        <Fragment>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          <span className="visually-hidden">Loading...</span>{" "}
                        </Fragment>
                      ) : (
                        "Sign In"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
