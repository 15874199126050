import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Pagination,
  useTheme,
  Avatar,
  Tooltip,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
//user imports
import "./transaction.css";
import { API_URLS } from "app/Services/organization/Routes/API";
import { headersGet } from "app/Services/organization/API";
import TransactionModel from "./TransactionModel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  formatCategory,
  formatDate,
  formatDateTimeOnly,
} from "app/common/organization/functions";
import { context } from "app/context";
import useOrgRedirect from "app/utils/organization/hooks/useOrgRedirect";

export default function Transaction() {
  //hooks
  useOrgRedirect();

  const headings = [
    "Date",
    "Organisation",
    "Category",
    "Charity Name",
    "Type",
    "Donation Amount",
  ];

  const { orgDetails } = useContext(context);
  const theme = useTheme();

  //destructing

  const { id } = orgDetails || {};

  //states

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertIcon, setAlertIcon] = useState("error");
  const [transaction, setTransaction] = useState({});
  const [currentDay, setCurrentDay] = useState("last_month");
  // model handlers

  const handleClose = () => setShow(false);
  const handleShow = (t) => {
    setTransaction(t);
    setShow(true);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Donations History";
    const headers = [
      [
        "DATE",
        "Organisation",
        "CATEGORY",
        "CHARITY NAME",
        "TYPE",
        "DONATION AMOUNT",
        "FEES",
        "TOTAL",
      ],
    ];

    const data1 = data.map((elt) => [
      elt.updatedAt,
      elt.organisation.name,
      elt.organisation.category,
      elt.organisation.company_name,
      elt.type,
      elt.amount,
      elt?.pp_fees,
      elt?.pp_fees ? elt?.amount + elt.pp_fees : elt.amount,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data1,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Donations History.pdf");
  };

  const matches = useMediaQuery("(min-width:701px)");
  const matches1 = useMediaQuery("(max-width:700px)");

  const tableArea = (table, index) => {
    const { amount, user, cause, organisation, type, createdAt, id, pp_fees } =
      table || {};
    const { name } = user || {};
    const { category, name: orgName, organisation_image } = organisation || {};
    const { title } = cause || {};
    return (
      <tr key={id}>
        <td>
          {formatDate(createdAt)}
          <br></br>
          <span
            style={{
              fontSize: "12px",
              color: "#9094a2",
            }}
          >
            {formatDateTimeOnly(createdAt)}
          </span>
        </td>
        <td>
          <div className="organ">
            {/* <div className='logo-div'>
                            {organisation_image?.length > 0 ? (
                                <img
                                    src={`${organisation_image[0]}`}
                                    alt='organisation-logo'
                                />
                            ) : (
                                <Avatar></Avatar>
                            )}
                        </div> */}
            <div className="name-div">
              <p>{orgName}</p>
            </div>
          </div>
        </td>
        <td>{formatCategory(category || "---")}</td>
        <td>{title || "N/A"}</td>
        <td>{name || "---"}</td>
        <td>{formatCategory(type) || "---"}</td>
        <td>${amount.toFixed(2)}</td>
        <td>${pp_fees}</td>
        <td>
          ${pp_fees ? (amount + pp_fees)?.toFixed(2) : amount?.toFixed(2)}
        </td>
        <td className="view">
          <Box component={"a"} onClick={() => handleShow(table)}>
            View
          </Box>
        </td>
      </tr>
    );
  };

  //get transactions

  const getTransaction = useCallback(
    (values) => {
      setOpen(true);

      headersGet(
        `${
          API_URLS?.orgTransactions
        }?organisation=${id}&page=${page}&limit=${10}&currentDay=${currentDay}`
      )
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            setData(res?.data?.results);
            setTotalPages(res?.data?.totalPages);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;

          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    },
    [id, page, currentDay]
  );

  useEffect(() => {
    id && getTransaction();
  }, [id, getTransaction]);

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 my-5">
            <div className="active-title">
              <p>TRANSACTION HISTORY</p>
            </div>
            <div className="transaction-table mb-5">
              <div className="transaction-head">
                <div className="drop-down">
                  <Box>
                    <TextField
                      select
                      value={currentDay}
                      onChange={(e) => setCurrentDay(e.target.value)}
                    >
                      <MenuItem value={"last_day"}>Last Day</MenuItem>
                      <MenuItem value={"last_week"}>Last Week</MenuItem>
                      <MenuItem value={"last_month"}>Last Month</MenuItem>
                    </TextField>
                  </Box>
                  {/* <div className='dropdown'>
                                        <button
                                            className='btn dropdown-toggle'
                                            type='button'
                                            id='dropdownMenuButton'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Last Month
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='dropdownMenuButton'
                                        >
                                            <a
                                                className='dropdown-item'
                                                href='#/'
                                            >
                                                Last 2 Months
                                            </a>
                                            <a
                                                className='dropdown-item'
                                                href='#/'
                                            >
                                                Last Year
                                            </a>
                                        </div>
                                    </div> */}
                </div>
                <div className="printing-saving">
                  {/* <button className="btn btn-pink">
                    Request Tax Statement
                  </button> */}
                  {/* <Tooltip
                                        title='Request Tax Statement'
                                        placement='top-end'
                                        // color={"primary"}
                                        enterTouchDelay={0}
                                        arrow
                                    >
                                        <Button
                                            variant='contained'
                                            // color={"primary"}
                                            sx={{
                                                backgroundColor: '#ff9776',
                                                paddingX: 2,
                                                textTransform: 'initial',
                                                paddingY: 1,
                                            }}
                                            style={{backgroundColor: '#ff9776'}}
                                        >
                                            {matches && (
                                                <>Request Tax Statement</>
                                            )}
                                            {matches1 && <>RTS</>}
                                        </Button>
                                    </Tooltip> */}
                  <div className="px-3"></div>
                  <img
                    src="/images/download.svg"
                    alt="download"
                    className=""
                    onClick={() => exportPDF()}
                  />
                  <div className="px-2"></div>
                  <img src="/images/printer.svg" alt="print" className="" />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table mt-4">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Organisation</th>
                      <th scope="col">Category</th>
                      <th scope="col">Cause Name</th>
                      <th scope="col">Donor Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Donation Amount</th>
                      <th scope="col">Fees</th>
                      <th scope="col">Total</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map(tableArea)
                    ) : (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan={10}>No transaction found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {data && data?.length > 0 && (
              <Box display={"flex"} justifyContent={"center"} marginY={3}>
                <Box
                  bgcolor={theme.palette.common.white}
                  padding={1}
                  borderRadius={25}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={page}
                    variant="contained"
                    onChange={handleChangePage}
                  />
                </Box>
              </Box>
            )}
          </div>
        </div>
      </div>

      <TransactionModel
        show={show}
        handleClose={handleClose}
        transaction={transaction}
      />

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
