import React, { Fragment } from "react";

//package import

import PropTypes from "prop-types";
import {
  Button,
  useTheme,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Link,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

//user import

import { userRoutes } from "app/Services/user/routes/APP";
import {
  nfpsRoutes,
  changeRoutes,
} from "app/components/user/Layout/navigation";
import DropArrow from "app/svg/user/DropArrow";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";

//component

const SidebarNav = ({
  onClose,
  handleClose,
  open,
  handleToggle,
  anchorRef,
  handleClose1,
  open1,
  handleToggle1,
  anchorRef1,
  signUpHanlder,
}) => {
  //states

  const theme = useTheme();
  const isLogged = isLoggedIn();
  //render

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        onClick={() => onClose()}
        color={theme.palette.common.white}
      >
        <IconButton color="inherit">
          <CloseIcon fontSize="small" color="inherit" />
        </IconButton>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.feed}
        >
          FEED
        </Box>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.discover}
        >
          DISCOVER
        </Box>
      </Box>

      <Box margin={2}>
        <Button
          onClick={handleToggle1}
          sx={{
            fontWeight: 500,
            fontSize: "1rem",
            color: theme.palette.common.white,
            textDecoration: "none",
            outline: "none",
            padding: 0,
            "$:hover": {
              cursor: "pointer",
              color: theme.palette.primary.light,
            },
          }}
          endIcon={<DropArrow />}
        >
          NFP's
        </Button>

        <Menu
          anchorEl={anchorRef1}
          open={open1}
          onClose={handleClose1}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.common.black,
            },
          }}
        >
          {nfpsRoutes?.map((subItem, index) => (
            <Box
              key={`${index}-sub-menu-${subItem?.id}`}
              sx={{
                backgroundColor: theme.palette.common.black,
              }}
            >
              <Link
                sx={{
                  color: theme.palette.common.white,
                  textDecoration: "none",
                }}
                href={subItem?.href ? subItem?.href : "#"}
              >
                <MenuItem
                  onClick={handleClose1}
                  key={`${index}-${subItem?.id}-sub-menu-`}
                  sx={{
                    textTransform: "uppercase",
                    paddingY: 2,
                  }}
                >
                  {subItem?.title}
                </MenuItem>
              </Link>
              {nfpsRoutes.length - 1 !== index && <Divider color="#383838" />}
            </Box>
          ))}
        </Menu>
      </Box>
      <Box margin={2}>
        <Button
          onClick={handleToggle}
          sx={{
            fontWeight: 500,
            fontSize: "1rem",
            color: theme.palette.common.white,
            textDecoration: "none",
            outline: "none",
            padding: 0,
            "$:hover": {
              cursor: "pointer",
              color: theme.palette.primary.light,
            },
          }}
          endIcon={<DropArrow />}
        >
          CHANGE MAKERS
        </Button>

        <Menu
          anchorEl={anchorRef}
          open={open}
          onClose={handleClose}
          style={{ marginTop: -430 }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.common.black,
            },
          }}
        >
          {changeRoutes?.map((subItem, index) => (
            <Box
              key={`${index}-sub-menu-${subItem?.id}`}
              sx={{
                backgroundColor: theme.palette.common.black,
              }}
            >
              <Link
                sx={{
                  color: theme.palette.common.white,
                  textDecoration: "none",
                }}
                href={subItem?.href ? subItem?.href : "#"}
              >
                <MenuItem
                  onClick={handleClose}
                  key={`${index}-${subItem?.id}-sub-menu`}
                  sx={{
                    textTransform: "uppercase",
                    paddingY: 2,
                  }}
                >
                  {subItem?.title}
                </MenuItem>
              </Link>
              {changeRoutes?.length - 1 !== index && (
                <Divider color="#383838" />
              )}
            </Box>
          ))}
        </Menu>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.aboutus}
        >
          ABOUT US
        </Box>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.contactUs}
        >
          GET IN TOUCH
        </Box>
      </Box>
      {!isLogged ? (
        <Fragment>
          <Box margin={2}>
            <Button variant="contained" href={userRoutes?.signUp}>
              SIGN UP{" "}
            </Button>
          </Box>
          <Box margin={2}>
            <Button variant="contained" href={userRoutes?.login}>
              SIGN IN
            </Button>
          </Box>
        </Fragment>
      ) : (
        <Box margin={2}>
          <Button variant="contained" href={userRoutes?.user}>
            LOGGED IN PORTAL
          </Button>
        </Box>
      )}
    </Box>
  );
};

SidebarNav.propTypes = {
  // pages: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  // anchorRef: PropTypes.oneOfType([
  //   // Either a function
  //   PropTypes.func,
  //   // Or the instance of a DOM native element (see the note about SSR)
  //   PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  // ]).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleListKeyDown: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SidebarNav;
