import React, { Fragment } from "react";

//package import

import { Typography, Box, useTheme, Container } from "@mui/material";
import styled, { keyframes } from 'styled-components';
import {shake} from 'react-animations';
//component

const Hero = () => {
  //hooks

  const theme = useTheme();


  const shakeXAnimation = keyframes`${shake}`
  const ShakeDiv = styled.div`
    animation: 1s ${shakeXAnimation} infinite;
  `;
 
  //render

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(/images/FeaturesEllipse.png)`,
          backgroundSize: "25%",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          padding: theme.spacing(6, 0, 2, 2),
          //   minHeight: "100vh",
        }}
      >
        <Container>
          <Box padding={2}>
            <Typography
              variant="h4"
              align="center"
              fontWeight={"bold"}
              color={"primary.light"}
              sx={{
                textTransform: "uppercase",
              }}
            >
              U4C features designed for you!
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              sx={{
                border: `2px solid ${theme.palette.primary.light}`,
                width: "20%",
              }}
            ></Box>
          </Box>

          <Box padding={2}>
            <Typography
              variant="h6"
              align="center"
              fontWeight={"bold"}
              color={"primary.light"}
              sx={{
                textTransform: "uppercase",
              }}
            >
              You want giving to be easy, hassle free and fun!
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
            <Box
              width={{ md: 240, sm: 180, xs: 80 }}
              height={{ md: 250, sm: 190, xs: 90 }}
            >
              <ShakeDiv>
              <img
                src={"/images/FeaturesCoin.png"}
                alt={"FeaturesCoin"}
                width={"100%"}
                height={"100%"}
              />
            </ShakeDiv>
            </Box>
          </Box>
          <Box>
            <Typography
              color={"primary.light"}
              fontWeight={"bold"}
              variant="h6"
              align="center"
              paddingBottom={4}
            >
              U4C allows you to automate your giving, making it so easy you can
              literally do it in your sleep!
            </Typography>
            <Typography
              color={"primary.light"}
              fontWeight={"bold"}
              variant="h6"
              align="center"
              paddingBottom={4}
            >
              Link your debit card to the platform and U4C will do the rest by
              rounding up a small amount on each transaction. Lots of little
              donations makes for a big impact. Watch video feeds, share to your
              social media and connect in real time to causes through QR codes.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Hero;
