import React from "react";

const HeartIcon = () => {
  return (
    <svg
      width="239"
      height="214"
      viewBox="0 0 239 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M221.56 21.0221C209.775 7.46563 193.422 0 175.516 0C150.335 0 134.389 15.2435 125.449 28.0316C123.13 31.35 121.158 34.6774 119.5 37.8085C117.842 34.6774 115.87 31.35 113.551 28.0316C104.611 15.2435 88.6653 0 63.4844 0C45.578 0 29.2252 7.46612 17.4358 21.0226C6.19162 33.9545 0 51.274 0 69.7907C0 89.9464 7.76376 108.693 24.434 128.787C39.3342 146.746 60.7658 165.258 85.5882 186.694C94.8345 194.682 104.402 202.943 114.582 211.972L114.888 212.243C116.21 213.415 117.853 214 119.5 214C121.147 214 122.794 213.414 124.112 212.243L124.418 211.972C134.598 202.943 144.166 194.683 153.416 186.693C178.234 165.259 199.666 146.747 214.566 128.787C231.236 108.693 239 89.9464 239 69.7907C239 51.274 232.808 33.9545 221.56 21.0221Z"
        fill="#FF3E3E"
      />
    </svg>
  );
};

export default HeartIcon;
