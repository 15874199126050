import React from "react";

//package import

import { Box } from "@mui/material";

//user import

import NonProfit from "./NonProfit";
import Donor from "./Donor";
import NfpsSearch from "./NfpsSearch"
import NfpsContact from "./NfpsContact"

//component

const Nfps = () => {
  //render

  return (
    <Box>
      <NonProfit />
      <Donor />
      <NfpsSearch />
      <NfpsContact />
    </Box>
  );
};

export default Nfps;
