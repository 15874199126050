//packages import
import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Box,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
//user import
import {
  CallIcon,
  FacebookIcon,
  MessageIcon,
  InstagramIcon,
  YouTubeIcon,
} from "app/svg/user/ContactUs";

import { contactValidation } from "app/Services/user/validations/contactUs";
import { simplePost } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";

//constants

const contactArray = [
  {
    id: "contact-mail",
    link: "mailto:info@up4change.com.au",
    text: "info@up4change.com.au",
    icon: <MessageIcon width={40} height={40} />,
  },
  {
    id: "contact-tel",
    link: "tel:0448 512 230",
    text: "0448 512 230",
    icon: <CallIcon width={40} height={40} />,
  },
  {
    id: "contact-facebook",
    link: "https://www.facebook.com/up4changeAU",
    text: "Facebook",
    icon: <FacebookIcon width={40} height={40} />,
  },
  {
    id: "contact-instagram",
    link: "https://www.instagram.com/up4changeau/?hl=en",
    text: "Instagram",
    icon: <InstagramIcon width={40} height={40} />,
  },
  {
    id: "contact-YouTube",
    link: "https://www.youtube.com/channel/UCeAUXe-3iGl2G5NrALg_OZQ",
    text: "YouTube",
    icon: <YouTubeIcon width={40} height={40} />,
  },
];

const initialValues = {
  name: "",
  email: "",
  charity: false,
  changes: false,
  description: "",
  organisation: "",
};

//component

const ContactUs = () => {
  //hooks

  const theme = useTheme();

  //states

  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertIcon, setAlertIcon] = useState("error");

  //submit handler

  const submitHandler = (values) => {
    const { name, description, organisation, email } = values || {};

    const data = { name, description, organisation, email };
    setOpen(true);
    simplePost(API_URLS?.contactUs, data)
      .then((res) => {
        setOpen(false);
        const { status: statusCode, data } = res || {};
        const { message, status } = data || {};
        if (statusCode === 200 && status) {
          setAlertOpen(true);
          setAlertMsg(message);
          setAlertIcon("success");
        } else {
          setAlertOpen(true);
          setAlertMsg(message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response || {};
        const { message } = data || {};
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(message);
        setAlertIcon("error");
      });
  };

  const alertCloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Grid container>
        <Grid item xs={false} sm={false} md={1}></Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box
            bgcolor={theme.palette.secondary.main}
            padding={3}
            paddingTop={{ md: 5, sm: 6, xs: 7 }}
            minHeight={{ sm: "0vh", xs: "0vh", lg: "110vh" }}
            sx={{
              backgroundImage: `url(/images/ContactEllipse.png)`,
              backgroundSize: "100%",
              backgroundPosition: "left bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Typography
              color={"common.white"}
              variant="h4"
              align="center"
              fontWeight={"bold"}
              sx={{
                textTransform: "uppercase",
              }}
            >
              Contact Us
            </Typography>
            <Box display={"flex"} justifyContent={"center"} paddingY={2}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.common.white}`,
                  width: "45%",
                }}
              ></Box>
            </Box>
            <Box>
              {contactArray?.map((item, index) => (
                <Box
                  key={`${index}-${item?.id}-sidebar`}
                  display={"flex"}
                  alignItems={"center"}
                  paddingX={2}
                  paddingY={1}
                >
                  <Box>{item?.icon}</Box>
                  <Box paddingLeft={1}>
                    <Link
                      href={item?.link}
                      color={"common.white"}
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 17,
                        textDecoration: "none",
                        wordBreak: "keep-all",
                        borderBottom: `2px solid ${theme.palette.common.white}`,
                      }}
                    >
                      {item?.text}
                    </Link>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Box
            minHeight={"110vh"}
            sx={{
              backgroundImage: `url(/images/contactBg.png)`,
              backgroundSize: "95% 90%",
              backgroundPosition: "right bottom",
              backgroundRepeat: "no-repeat",
              padding: theme.spacing(6, 2, 2, 2),
            }}
          >
            <Container maxWidth={"md"}>
              <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={contactValidation}
              >
                {({ errors, values, touched, handleSubmit, handleChange }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        paddingX: {
                          md: 10,
                          sm: 4,
                          xs: 2,
                        },
                      }}
                    >
                      <Typography
                        color={"common.white"}
                        variant="h4"
                        align="center"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        enquiry form
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        paddingY={2}
                      >
                        <Box
                          sx={{
                            border: `1px solid ${theme.palette.common.white}`,
                            width: "20%",
                          }}
                        ></Box>
                      </Box>
                      <Box marginBottom={3}>
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                        >
                          Enter Name
                        </Typography>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          name={"name"}
                          value={values.name}
                          onChange={handleChange}
                          helperText={touched.name && errors.name}
                          error={touched.name && Boolean(errors.name)}
                          color="info"
                        ></TextField>
                      </Box>
                      <Box marginBottom={3}>
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                        >
                          Enter Organisation
                        </Typography>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          color="info"
                          name={"organisation"}
                          value={values.organisation}
                          onChange={handleChange}
                          helperText={
                            touched.organisation && errors.organisation
                          }
                          error={
                            touched.organisation && Boolean(errors.organisation)
                          }
                        ></TextField>
                      </Box>
                      <Box marginBottom={3}>
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                        >
                          Enter Email
                        </Typography>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          color="info"
                          name={"email"}
                          value={values.email}
                          onChange={handleChange}
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        ></TextField>
                      </Box>
                      <Box marginBottom={3}>
                        <Typography
                          color={"common.white"}
                          fontWeight={"bold"}
                          gutterBottom
                        >
                          Description
                        </Typography>
                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          variant={"outlined"}
                          color="info"
                          name={"description"}
                          value={values.description}
                          onChange={handleChange}
                          helperText={touched.description && errors.description}
                          error={
                            touched.description && Boolean(errors.description)
                          }
                        ></TextField>
                      </Box>
                      <Box marginBottom={2}>
                        <FormControlLabel
                          sx={{
                            color: theme.palette.common.white,
                          }}
                          control={
                            <Checkbox
                              sx={{
                                color: theme.palette.common.white,
                              }}
                            />
                          }
                          label="I am a Charity or Not for Profit organisation interested in using the Up 4 Change platform."
                        />
                      </Box>
                      <Box marginBottom={2}>
                        <FormControlLabel
                          sx={{
                            color: theme.palette.common.white,
                          }}
                          control={
                            <Checkbox
                              sx={{
                                color: theme.palette.common.white,
                              }}
                            />
                          }
                          label="I am looking to get involved making change!"
                        />
                      </Box>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            boxShadow: theme.shadows[5],
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactUs;
