import React, { useState, useEffect, useCallback } from "react";

//package import

import {
  Box,
  Container,
  Typography,
  useTheme,
  CircularProgress,
  Alert,
  Snackbar,
  Backdrop,
} from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";
import useQuery from "app/utils/user/useQuery";

//user import

import OrgCard from "./OrgCard";
import SupportModal from "../SupportModal";
import { API_URLS } from "app/Services/user/routes/API";
import { simpleGet } from "app/Services/user/API";

//component

const Cause = () => {
  //hooks

  const theme = useTheme();
  const params = useParams();
  const query = useQuery();
  console.log({ query });
  const cId = query.get("cause");
  const isLogin = query.get("isLogin");

  // console.log({params});
  // const [queryParams] = useSearchParams()
  // const [queryParams] = useParams()
  // const id = queryParams.get('cause')
  let { id } = params;
  console.log({ params });
  // if (isLogin == 'false') {
  id = cId ? cId : id;
  // }
  console.log({ isLogin });

  //states

  //states

  const [data, setData] = useState({ org: {}, cause: {} });
  const [loader, setLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //states

  const [open, setOpen] = useState(false);

  //model close handler

  const closeHandler = () => {
    setOpen(false);
  };

  const modalOpen = () => {
    setOpen(true);
  };

  //get org detail

  const getOrgDetail = useCallback((orgId) => {
    simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${orgId}`)
      .then((res) => {
        setLoader(false);
        if (res?.status === 200 && res?.data?.status) {
          setData((prev) => ({
            ...prev,
            org: res?.data?.organisationDetails,
          }));
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setLoader(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, []);

  //get org details

  //get cause details

  const getCauseDetails = useCallback(() => {
    setLoader(true);
    simpleGet(`${API_URLS?.getOrgCause}?causeId=${id}`)
      .then((res) => {
        if (res?.status === 200) {
          setData({ cause: { ...res?.data?.cause } });
          getOrgDetail(res?.data?.cause?.organisation);
        } else {
          setLoader(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setLoader(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [id, getOrgDetail]);

  //life cycle hook

  useEffect(() => {
    console.log({ id });
    id && getCauseDetails();
  }, [id, getCauseDetails]);

  //alert close handler

  //   const alertCloseHandler = (event, reason) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }
  //   }

  //get org detail

  // const getOrgDetail = useCallback(orgId => {
  //     simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${orgId}`)
  //         .then(res => {
  //             setLoader(false)
  //             if (res?.status === 200 && res?.data?.status) {
  //                 setData(prev => ({
  //                     ...prev,
  //                     org: res?.data?.organisationDetails,
  //                 }))
  //             } else {
  //                 setAlertOpen(true)
  //                 setAlertMsg(res?.data?.message)
  //                 setAlertIcon('warning')
  //             }
  //         })
  //         .catch(err => {
  //             const {response} = err
  //             setLoader(false)
  //             setAlertOpen(true)
  //             setAlertMsg(response?.data?.message)
  //             setAlertIcon('error')
  //         })
  // }, [])

  //get org details

  //get cause details

  // const getCauseDetails = useCallback(() => {
  //     setLoader(true)
  //     simpleGet(`${API_URLS?.getOrgCause}?causeId=${id}`)
  //         .then(res => {
  //             if (res?.status === 200 && res?.data?.status) {
  //                 setData({cause: {...res?.data?.cause}})
  //                 getOrgDetail(res?.data?.cause?.organisation)
  //             } else {
  //                 setLoader(false)
  //                 setAlertOpen(true)
  //                 setAlertMsg(res?.data?.message)
  //                 setAlertIcon('warning')
  //             }
  //         })
  //         .catch(err => {
  //             const {response} = err
  //             setLoader(false)
  //             setAlertOpen(true)
  //             setAlertMsg(response?.data?.message)
  //             setAlertIcon('error')
  //         })
  // }, [id, getOrgDetail])

  //life cycle hook

  useEffect(() => {
    id && getCauseDetails();
  }, [id, getCauseDetails]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  console.log({ data });

  //render

  return (
    <Box padding={2} bgcolor={theme.palette.info.light}>
      <Container>
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={120} height={120}>
            <Box
              component={"img"}
              borderRadius={"100%"}
              border={"5px solid #ff9776"}
              src={
                (data?.org?.organisation_image &&
                  data?.org?.organisation_image[0]) ||
                "/images/OrgLogo.png"
              }
              alt="org logo"
              width={"100%"}
              height={"100%"}
            />
          </Box>
        </Box>
        <Typography
          color={"text.red"}
          fontWeight={"bold"}
          align="center"
          paddingTop={1}
        >
          {data?.org?.name}
        </Typography>
        <Typography color={"white"} align="center">
          {/* {data?.org?.cause} */}
        </Typography>
        <OrgCard modalOpen={modalOpen} data={data} />
      </Container>
      {open && (
        <SupportModal
          open={open}
          closeHandler={closeHandler}
          causeId={id}
          organisationDoc={data?.org}
        />
      )}
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default Cause;
