import React from "react";

//package import

import { Navigate, useLocation } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";

//user imports

import { StorageKeys } from "../../StorageKeys";
import { APP_Urls } from "./index";
import { useEffect, useState } from "react";

//component

export const PrivateRoute = ({ element: Component, ...rest }) => {
  //hooks
  const location = useLocation();
  const [auth, setauth] = useState(true);
  useEffect(() => {
    setauth(getAuth());
  }, [location]);
  //   const token = localStorage.getItem(StorageKeys?.token);
  const userinfo = JSON.parse(localStorage.getItem(StorageKeys?.admin_info));

  //return component

  return auth && userinfo?.role === "admin" ? (
    <Component {...rest} />
  ) : (
    <Navigate to={APP_Urls?.root} />
  );
};

//auth hook

// export const useAuth = () => {
//     const token = localStorage.getItem(StorageKeys?.token)

//     const {pathname} = useLocation()

//     if (token) {
//         //checking token is expired or not
//         const expired = isExpired(token)

//         if (!expired) {
//             //decoding token is expired or not
//             const decodedToken = decodeToken(token)
//             if (decodedToken) {
//                 return true
//                 // if (verifyRole(decodedToken?.role, pathname)) {
//                 //   return true;
//                 // } else {
//                 //   removeToken();
//                 //   return false;
//                 // }
//             }
//             removeToken()
//             return false
//         }
//         removeToken()
//         return false
//     }
//     return false
// }
export const getAuth = () => {
  const token = localStorage.getItem(StorageKeys?.token);

  try {
    const { exp } = decodeToken(token);
    // console.log({ exp, decode: decodeToken(token) });
    if (Date.now() >= exp * 1000) {
      //   console.log("Date greater");÷
      removeToken();
      return false;
    }
  } catch (err) {
    removeToken();
    return false;
  }
  return true;
};

export const removeToken = () => {
  return localStorage.removeItem(StorageKeys?.token);
};

export default PrivateRoute;
