import React from "react";
import { NoDataIcon } from "app/svg/user/NoData";

const NoData = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "#E3EEFA",
          fontSize: 120,
          display: "flex",
          alignItems: "center",
        }}
      >
        <NoDataIcon />
      </div>

      <div
        style={{
          marginTop: 8,
        }}
      >
        <h5>No Data Found</h5>
      </div>
    </div>
  );
};

export default NoData;
