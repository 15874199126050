import React from 'react';
import { Link } from 'react-router-dom';
import { BsThreeDots, BsFillCheckCircleFill } from 'react-icons/bs';

export default function ViewCause() {
  let iconStylesB = { color: '#161F3D', fontSize: '18px' };
  let iconStylesY = { color: '#F9CC69', fontSize: '18px' };
  let iconStylesS = { color: '#66C9D2', fontSize: '18px' };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="box py-5">
            <div className="viewCause-option-view">
              <div className="dropdown w-0">
                <button
                  className="border-0 bg-white"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <BsThreeDots />
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#/">
                    EDIT
                  </a>
                  <div className="border-top my-1 mx-4"></div>
                  <a className="dropdown-item" href="#/">
                    PREVIEW
                  </a>
                  <div className="border-top my-1 mx-4"></div>
                  <a className="dropdown-item" href="#/">
                    SHOW QR CODE
                  </a>
                  <div className="border-top my-1 mx-4"></div>
                  <a className="dropdown-item" href="#/">
                    PRINT
                  </a>
                </div>
              </div>
            </div>
            <div className="cause-info">
              <p className="cause-cat">Category - Wildlife</p>
              <p className="cause-head py-3">Austrlian Wildlife Conservatory</p>
              <p className="cause-donation">DONATION GOAL</p>
            </div>

            <div className="creature-bar my-4">
              <div className="emoji">
                <img src="/images/wildlife.svg" alt="wildlife" />
                <p>
                  Wildlife <br /> Translocation
                </p>
              </div>
              <div className="emoji">
                <img src="/images/control.svg" alt="control" />
                <p>
                  Feral Cat <br /> Control{' '}
                </p>
              </div>
              <div className="emoji">
                <img src="/images/research.svg" alt="research" />
                <p>
                  Ecological <br /> Research
                </p>
              </div>
            </div>

            <div className="viewCause-desc my-3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac elit vitae tincidunt rhoncus massa
                et pharetra mauris. Molestie ullamcorper risus pulvinar a, accumsan, volutpat congue. Aenean
                ac feugiat libero, cursus. Amet quam eu lorem euismod ut. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Ac elit vitae tincidunt rhoncus massa et pharetra mauris.
                Molestie.
              </p>
              <ul className="ml-4">
                <li>
                  <span>
                    <BsFillCheckCircleFill style={iconStylesB} />
                  </span>{' '}
                  &nbsp; Certified Organisation
                </li>
                <li>
                  <span>
                    <BsFillCheckCircleFill style={iconStylesY} />
                  </span>{' '}
                  &nbsp; ACN Verified
                </li>
                <li>
                  <span>
                    <BsFillCheckCircleFill style={iconStylesS} />
                  </span>{' '}
                  &nbsp; DGR Verified
                </li>
              </ul>
            </div>
            <video
              src="/images/videoplayback.mp4"
              width="100%"
              height="400"
              controls
              className="my-4"></video>
            <div className="text-center my-3">
              <Link to="/transaction">
                <button className="btn btn-pink">VIEW TRANSACTION HISTORY</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
