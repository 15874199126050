import React from "react";

//package import

import { BsPlusLg } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
// import Pagination from '../../Components/Pagination'
import Pagination from "rc-pagination";
import "../Donation/style.css";

//component

const AllOrg = ({
  handleShow,
  totalOrg,
  userTableView,
  totalPages,
  page,
  handlePagination,
  search,
  setSearch,
  totalResults,
}) => {
  //render

  return (
    <div
      className="tab-pane fade show active"
      id="nav-roundup"
      role="tabpanel"
      aria-labelledby="nav-roundup-tab"
    >
      <div className="row organ-table-bar">
        <div className="col-lg-4 col-12 col-sm-12 col-md-6 mb-3">
          <button className="btn btn-yellow" onClick={handleShow}>
            <BsPlusLg /> &nbsp; ADD Organisation
          </button>
        </div>
        <div className="col-lg-4 col-12 col-sm-12 col-md-6 mb-3">
          <p style={{ textTransform: "uppercase" }}>
            LIST OF ALL Organisations
          </p>
        </div>
        <div className="col-lg-4 col-12 col-sm-12 col-md-12 mb-3">
          <div className="organ-search-bar">
            <input
              className="form-control search"
              type="search"
              placeholder="Search"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <p>
              <MdSearch />
            </p>
          </div>
        </div>
      </div>
      <div className="table-responsive organisation-table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Organisation NAME</th>
              <th scope="col">BUSINESS EMAIL</th>
              <th scope="col">Organisation TYPE</th>
              <th scope="col">CATEGORY</th>
              <th scope="col">SUB CATEGORY</th>
              {/* <th scope='col'>BILLING DETAILS</th> */}
              <th scope="col">SIGNUP DATE</th>
              <th scope="col">CURRENT STATUS</th>
              <th scope="col">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {totalOrg && totalOrg.length > 0 ? (
              totalOrg?.map(userTableView)
            ) : (
              <>
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={9}>
                    No Data Found!
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      {/* <Pagination
                page={page}
                handlePagination={handlePagination}
                totalPages={totalPages}
            /> */}
      <span style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          className="ant-pagination"
          current={page}
          defaultCurrent={3}
          total={totalResults}
          onChange={handlePagination}
        />
      </span>
    </div>
  );
};

export default AllOrg;
