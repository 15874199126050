import React from "react";

//package imports

//user imports

import { Layout } from "app/components/user";
import {
  Hero,
  FreeGiving,
  SocialPlateform,
  SimpleSteps,
  Connect,
  Mission,
} from "app/components/user/Home";

//component

const Home = () => {
  //render

  return (
    <Layout>
      <Hero />
      <FreeGiving />
      {/* <Shelter /> */}

      <SimpleSteps />
      <SocialPlateform />
      {/* <Features /> */}
      <Connect />
      {/* <FavCauses /> */}
      <Mission />
    </Layout>
  );
};

export default Home;
