import React from "react";

//user import

import { Layout } from "app/components/user";
import Nfps from "app/components/user/Nfps";

//component

const NFPS = () => {
  //render

  return (
    <Layout>
      <Nfps />
    </Layout>
  );
};

export default NFPS;
