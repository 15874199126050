import React from "react";

const CallIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "46"}
      height={height ? height : "46"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M33.4955 35C20.699 35.018 10.9865 25.19 11 12.5045C11 11.675 11.672 11 12.5 11H16.46C17.2025 11 17.834 11.546 17.9435 12.281C18.2047 14.0436 18.7174 15.7596 19.466 17.3765L19.6205 17.7095C19.7243 17.9336 19.7466 18.1869 19.6836 18.4256C19.6206 18.6644 19.4763 18.8737 19.2755 19.0175C18.0485 19.8935 17.5805 21.656 18.536 23.03C19.7345 24.755 21.245 26.267 22.97 27.4655C24.3455 28.4195 26.108 27.9515 26.9825 26.726C27.1261 26.5246 27.3356 26.3797 27.5747 26.3164C27.8139 26.2532 28.0676 26.2755 28.292 26.3795L28.6235 26.5325C30.2405 27.2825 31.9565 27.7955 33.719 28.0565C34.454 28.166 35 28.7975 35 29.5415V33.5C35 33.6971 34.9611 33.8923 34.8857 34.0744C34.8102 34.2564 34.6996 34.4219 34.5601 34.5612C34.4207 34.7005 34.2551 34.8109 34.073 34.8862C33.8908 34.9615 33.6956 35.0002 33.4985 35H33.4955Z"
        fill={color ? color : "#230D4D"}
      />
    </svg>
  );
};

export default CallIcon;
