import React from "react";

//package import

import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";

//user import

import shadows from "./shadows";
import palette from "./palette";
import { liteThemeColors } from "./color";
import { useDarkMode } from "./useTheme";

const getTheme = (mode = "light") =>
  responsiveFontSizes(
    createTheme({
      palette: palette(mode),
      layout: {
        contentWidth: 1236,
      },
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Circular Std", sans-serif',
        button: {
          fontWeight: "bold",
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },

      components: {
        MuiButton: {
          styleOverrides: {
            label: {
              fontWeight: 800,
            },

            root: {
              borderRadius: 0,
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 10,
              paddingTop: 10,
            },
          },
        },
        MuiTextField: {
          variants: [
            {
              props: {
                variant: "outlined",
                color: "info",
              },
              style: {
                color:
                  mode === "light"
                    ? liteThemeColors.white
                    : liteThemeColors.white,
                borderColor:
                  mode === "light"
                    ? liteThemeColors.white
                    : liteThemeColors.white,
                ":hover": {
                  borderColor:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                },
                ".MuiInputBase-root:hover": {
                  borderColor:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                  color:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                },
                ".MuiInputBase-root": {
                  borderColor:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                  color:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                },
                ".MuiInputBase-root fieldset": {
                  borderColor:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                  color:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                },
                ".MuiInputBase-input:hover": {
                  borderColor:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                  color:
                    mode === "light"
                      ? liteThemeColors.white
                      : liteThemeColors.white,
                },
              },
            },
          ],
        },
      },
    })
  );

const Theme = ({ children }) => {
  const [themeMode] = useDarkMode();
  return <ThemeProvider theme={getTheme(themeMode)}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes?.node,
};

export default Theme;
