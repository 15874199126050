import React, { useCallback, useContext, useEffect, useState } from "react";

//package import

import {
  useTheme,
  Box,
  Container,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//user import

import Chart from "../Donut/Chart";
// import { context } from "../../context";
import { MotoIcon } from "app/svg/user/Profile";
import { COLORS } from "app/common/user/constants";
import { RectangleIcon } from "app/svg/user/Donut";
import { userRoutes } from "app/Services/user/routes/APP";
// import { makeSubCategoryArray } from "../../common/functions";
import GetSatrtedIcon from "app/svg/user/GetSatrted";
import { makeSubCategoryArray } from "app/common/user/functions";
import { context } from "app/context";
//component

const Profile = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useContext(context);

  //states

  const [catsPercentage, setCatsPercentage] = useState([]);

  //get categories percentage

  const getCategory = useCallback(() => {
    const arr =
      user?.sub_category_percentage?.length > 0 &&
      makeSubCategoryArray(user?.sub_category_percentage);
    arr?.length && setCatsPercentage(arr);
  }, [user]);

  useEffect(() => {
    user?._id && getCategory();
  }, [user, getCategory]);

  const clickHandler = () => {
    navigate(userRoutes?.user + userRoutes?.onboardingQ1);
  };

  //render

  return (
    <Box bgcolor={theme.palette.info.light}>
      <Box paddingY={3}>
        <Container maxWidth={"md"}>
          <Typography color={"text.red"}>My Account - Profile</Typography>
          <Box marginY={2}>
            <Box bgcolor={theme.palette.common.white} padding={2}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    navigate(userRoutes?.user + userRoutes?.profileEdit)
                  }
                >
                  <Box
                    component={"span"}
                    borderBottom={`2px solid ${theme.palette.common.white}`}
                  >
                    Edit
                  </Box>
                </Button>
              </Box>
              {user?.isOnboard ? (
                <>
                  <Container>
                    <Box width={"100%"} height={500}>
                      <Chart
                        textColor={theme.palette.common.black}
                        catsPercentage={catsPercentage}
                      />
                    </Box>
                    <Grid container>
                      {catsPercentage?.map((item, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <Box
                              display={"flex"}
                              justifyContent={"space-around"}
                            >
                              {index !== 0 && (
                                <Box height={"100%"}>
                                  <RectangleIcon height={"100%"} />
                                </Box>
                              )}
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                flexDirection={"column"}
                              >
                                <Box
                                  sx={{
                                    height: 24,
                                    width: 24,
                                    borderRadius: "100%",
                                    backgroundColor:
                                      COLORS[index % COLORS.length],
                                  }}
                                ></Box>
                                <Box marginY={1}>
                                  <Typography
                                    variant="h3"
                                    align="center"
                                    fontWeight={"bold"}
                                    color={"common.black"}
                                  >
                                    {item?.value} %
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography color={"common.black"}>
                                    {item?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>
                </>
              ) : (
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ paddingTop: { xs: 5, sm: 5, md: 0, lg: 0 } }}>
                    <Typography
                      color={"primary.light"}
                      variant="h4"
                      fontWeight={"bold"}
                      align="center"
                    >
                      Hey Changemaker,
                    </Typography>
                    <Typography
                      color={"primary.light"}
                      variant="h4"
                      fontWeight={"bold"}
                      align="center"
                    >
                      {/* ready to shake up the world? */}
                      You have not Selected any Cause?
                    </Typography>
                  </Box>
                  <Box>
                    {/* <Typography color={"primary.light"} align="center" gutterBottom>
                Let us know what causes you’re interested in and we’ll get busy
                creating a personalised feed just for you!
              </Typography>
              <Typography color={"primary.light"} align="center" gutterBottom>
                Our resident Changemakers Shake, Rattle &amp; Roll will be
                helping you on your journey.
              </Typography> */}
                    <Typography
                      color={"primary.light"}
                      fontWeight={"bold"}
                      align="center"
                      variant="h6"
                      marginTop={2}
                    >
                      Ready to start making change? Click Below!
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} marginTop={2}>
                    <Box>
                      <GetSatrtedIcon />
                    </Box>
                  </Box>
                  <Box
                    textAlign={"center"}
                    sx={{ paddingX: { xs: 2, sm: 2, md: 10, lg: 10 } }}
                  >
                    <Button
                      variant="contained"
                      // fullWidth
                      sx={{ paddingY: 2 }}
                      onClick={clickHandler}
                    >
                      GET STARTED
                    </Button>
                  </Box>
                </Grid>
              )}
            </Box>
            <Box
              bgcolor={theme.palette.secondary.main}
              padding={theme.spacing(2, 2, 0, 2)}
            >
              <Container>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography variant="h4" color={"black"}>
                        Rounds-ups
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight={"bold"}
                        color={"primary.light"}
                      >
                        {user?.recent_round_up}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <MotoIcon width={"100%"} height={100} />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
