import React from "react";

import Orgprofile from "app/components/user/Orgprofile";
// import { Layout } from "../components";

const OrgProfile = () => {
  return (
    // <Layout>
    <Orgprofile />
    // </Layout>
  );
};

export default OrgProfile;
