import React, { useRef, useState, useContext, useEffect } from "react";

//package import

import { Formik } from "formik";
import { Modal, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  Box,
} from "@mui/material";

//user imports

import { API_URLS } from "app/Services/organization/Routes/API";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { ToFormData } from "app/utils/organization/FormData";
import { GetImageOrVideo } from "app/utils/organization/Functions";
import { headersPost, headersUpdate } from "app/Services/organization/API";
import {
  addValidation,
  updateValidation,
} from "app/Services/organization/validations/CreateFeed";
import "./feed.css";
import { context } from "app/context";

//constants

const formValues = {
  image: "",
  title: "",
  description: "",
  hashtag: "",
};

//component

const CreateFeed = ({ show, handleClose, isEdit, selected, setApi }) => {
  //hooks

  const hiddenFileInput = useRef(null);
  // const {orgDetails: orgContext, setOrgDetails} = useContext(context)
  const navigate = useNavigate();

  // destructure
  const { orgDetails, setOrgDetails } = useContext(context);
  const { id, name, organisation_image } = orgDetails || {};

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [formData, setFormData] = useState(formValues);

  //handle click

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  //add feed handler
  // console.log({formData})

  const addFeedHandler = (values) => {
    const data = {
      image: values?.image,
      organisation_name: orgDetails?.company_name,
      title: values?.title,
      description: values?.description,
      hashtag: tags.join(" "),
      // hashtag: values?.hashtag,
    };

    const formData = ToFormData(data);
    setOpen(true);

    headersPost(`${API_URLS?.createFeed}/${id}`, formData)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setAlertOpen(true);
          setAlertMsg("Feed is created successfully");
          setAlertIcon("success");
          setTimeout(() => {
            handleClose();
            setApi(true);
            // window.location.href = APP_URLS?.org_feed
            // navigate(APP_URLS?.org_feed)
          }, 2000);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //update feed handler

  const updateFeedHandler = (values) => {
    const data = values?.image
      ? {
          image: values?.image,
          title: values?.title,
        }
      : {
          title: values?.title,
        };

    const formData = ToFormData(data);
    setOpen(true);

    headersUpdate(`${API_URLS?.updateFeed}?feedId=${selected?.id}`, formData)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setAlertOpen(true);
          setAlertMsg("Feed is updated successfully.");
          setAlertIcon("success");
          setApi(true);
          setTimeout(() => {
            handleClose();
          }, 30);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //alert close handler

  const alertCloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //use effect
  // console.log({values})
  useEffect(() => {
    selected &&
      setFormData((prev) => ({
        ...prev,
        ...selected,
        image: isEdit ? selected?.image[0] : "",
      }));

    return () => {
      setFormData(formValues);
    };
  }, [selected, isEdit]);

  //form submit handler

  const submitHandler = (values) => {
    if (isEdit) {
      updateFeedHandler(values);
    } else {
      addFeedHandler(values);
    }
  };

  const [errMsg, setErrMsg] = useState(" ");

  const handleError = () => {
    setErrMsg(
      "supported formats (image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm)"
    );
  };

  const [tags, setTags] = useState([]);

  function handleKeyDown(e, setFieldValue) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    setFieldValue("hashtag", "");
    e.target.value = "";
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  //render

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header id="feed-modal-header">
        <div className="d-flex justify-content-between align-items-center w-100 py-3">
          <p> </p>
          <p className="create-post">
            {(isEdit && "Edit a") || "Create a"} Post
          </p>
          <Link to="#/" onClick={handleClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={formData}
          onSubmit={submitHandler}
          enableReinitialize
          validationSchema={isEdit ? updateValidation : addValidation}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              {/* <Form onSubmit={handleSubmit}> */}
              <div className="modal-body-md">
                <div className="col-lg-12 px-0">
                  {/* <div className='m-feed'>
                                        <div className='m-feed-logo-div'>
                                            {organisation_image?.length > 0 ? (
                                                <img
                                                    src={organisation_image[0]}
                                                    alt='organisation-logo'
                                                    className='m-feed-logo-div'
                                                />
                                            ) : (
                                                <Avatar></Avatar>
                                            )}
                                        </div>
                                        <div className='m-feed-name-div'>
                                            <p>{name}</p>
                                        </div>
                                    </div> */}
                  <div
                    className="form-group mx-5"
                    style={{ marginTop: "20px" }}
                  >
                    <input
                      className="form-control feed-form"
                      id="exampleFormControlTextarea1"
                      placeholder="TITLE"
                      name={"title"}
                      value={values?.title}
                      onChange={handleChange}
                    ></input>
                    {/* {errors.title && touched.title && <div>{errors.title}</div>} */}
                    <FormControl error={touched.title && Boolean(errors.title)}>
                      <FormHelperText>
                        {touched.title && errors.title}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="form-group mx-5">
                    <textarea
                      className="form-control feed-form"
                      id="exampleFormControlTextarea1"
                      placeholder="ADD A DESCRIPTION"
                      rows="3"
                      name={"description"}
                      value={values?.description}
                      onChange={handleChange}
                    ></textarea>
                    <FormControl error={touched.title && Boolean(errors.title)}>
                      <FormHelperText>
                        {touched.title && errors.title}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  {/* <div className="form-group mx-5">
                    <input
                      className="form-control feed-form"
                      id="exampleFormControlTextarea1"
                      placeholder="HASHTAGS"
                     
                      name={"hashtag"}
                      value={values?.hashtag}
                      onChange={handleChange}
                    ></input>
                    <FormControl error={touched.title && Boolean(errors.title)}>
                      <FormHelperText>
                        {touched.title && errors.title}
                      </FormHelperText>
                    </FormControl>
                  </div> */}

                  {/* <div className='tags-input-container form-group mx-5'>
                                        {tags.map((tag, index) => (
                                            <div
                                                className='tag-item'
                                                key={index}
                                            >
                                                <span className='text'>
                                                    {tag}
                                                </span>
                                                <span
                                                    className='close'
                                                    onClick={() =>
                                                        removeTag(index)
                                                    }
                                                >
                                                    &times;
                                                </span>
                                            </div>
                                        ))}
                                        <input
                                            className='tags-input form-control feed-form'
                                            id='exampleFormControlTextarea1'
                                            placeholder='HASHTAGS'
                                            onKeyDown={e =>
                                                handleKeyDown(e, setFieldValue)
                                            }
                                            name={'hashtag'}
                                            value={values?.hashtag}
                                            onChange={handleChange}
                                        />
                                        <FormControl
                                            error={
                                                touched.title &&
                                                Boolean(errors.title)
                                            }
                                        >
                                            <FormHelperText>
                                                {touched.title && errors.title}
                                            </FormHelperText>
                                        </FormControl>
                                    </div> */}

                  <GetImageOrVideo image={values?.image} />
                  <div className="upload my-3">
                    <button
                      className="btn btn-upload py-3 w-100"
                      onClick={handleClick}
                      type={"button"}
                    >
                      <p>Add Image/Video</p>
                      <img
                        src="/images/upload.svg"
                        alt="upload"
                        className="py-3"
                      />
                      <p>Upload</p>
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      accept="image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm"
                      onChange={(event) => {
                        let img = event.target.files[0];

                        let array = [
                          "image/png",
                          "image/jpeg",
                          "image/jpg",
                          "video/mp4",
                          "video/ogg",
                          "video/webm",
                        ];
                        if (img && array.includes(img.type) === false) {
                          setErrMsg(
                            "supported formats (image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm)"
                          );
                        } else {
                          setErrMsg("");
                        }
                        setFieldValue("image", img);
                      }}
                      style={{ display: "none" }}
                    />
                    {errMsg && errMsg !== " " && (
                      <div className="alert alert-danger" role="alert">
                        {" "}
                        {errMsg}{" "}
                      </div>
                    )}
                    {/* <Box padding={1}>supported formats (image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm) </Box> */}
                  </div>
                  <FormControl error={touched.image && Boolean(errors.image)}>
                    <FormHelperText>
                      {touched.image && errors.image}
                    </FormHelperText>
                  </FormControl>

                  <button
                    className="btn btn-pink w-100"
                    type="submit"
                    style={{
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                    onClick={handleSubmit}
                  >
                    POST
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Modal>
  );
};

export default CreateFeed;
