import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";

//packages import

import { Form } from "react-bootstrap";
import { Formik } from "formik";

//user import

import axios from "axios";
import { simpleGet } from "app/Services/admin/API";
import { API_Urls } from "app/Services/admin/Routes/API";
import { nameSchema } from "app/Services/admin/validations/AddOrg";

//constants

const initialValues = {
  name: "",
};

//component

export default function AddOrganisation({
  setFormValues,
  formValues,
  handleNext,
}) {
  //states

  const [, setSelected] = useState("Type");
  const [active, setActive] = useState(false);
  const [formInitial, setFormInitial] = useState(initialValues);

  const [selectedOption, setSelectedOption] = useState(null);

  const [options, setOptions] = useState([]);

  //life cycle hooks

  useEffect(() => {
    formValues?.name && setFormInitial(formValues);
    return () => {
      setFormInitial(initialValues);
    };
  }, [formValues]);

  //form submit handler

  const formSubmitHandler = (values) => {
    setFormValues((prev) => ({
      ...prev,
      name: values?.name,
    }));
    handleNext();
  };
  let cancelToken;
  const handleTextChanged = async (e, setFieldValue) => {
    const value = e.target.value;
    setFieldValue("name", value);

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelling previous request");
    }
    cancelToken = axios.CancelToken.source();
    const url = `${API_Urls?.searching_orgs}&q=${value}`;
    value &&
      simpleGet(url, { cancelToken: cancelToken.token })
        .then((res) => {
          if (res?.status === 200) {
            console.log({ res });
            setOptions(res?.data?.result?.records);
          } else {
          }
        })
        .catch((err) => {
          const { response } = err;
        });
  };
  const handleOptionSelect = (e, option, setFieldValue) => {
    setSelected(option);
    setActive(false);
    setFieldValue("name", option?.Charity_Legal_Name);
    setFormValues({
      name: option?.Charity_Legal_Name || "",
      abn_number: option?.ABN || "",
      category: "",
      organisation_type: "",
      company_name: option?.Charity_Legal_Name || "",
      sub_category: "",
      email: "",
      organisation_image: "",
      description: "",
      cause: "",
      password: "password123",
    });
  };

  // const getAllOrgs = useCallback(() => {
  //     const url = `${API_Urls?.searching_orgs}`
  //     fetch(url)
  //         .then(res => {
  //             res.json()
  //         })
  //         .then(res => {
  //             console.log({res})
  //         })
  //         .catch(err => {
  //             console.log({err})
  //         })
  //     // simpleGet(url)
  //     //     .then(res => {
  //     //         if (res?.status === 200) {
  //     //             console.log({res})
  //     //         } else {
  //     //         }
  //     //     })
  //     //     .catch(err => {
  //     //         const {response} = err
  //     //     })
  // }, [])

  // useEffect(() => {
  //     getAllOrgs()
  // }, [])

  //render

  return (
    <div>
      <Formik
        initialValues={formInitial}
        onSubmit={formSubmitHandler}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="d-flex align-items-center">
                    <p className="modal-organ-add-title">Organisation Name</p>
                    {/* <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options2}
                                        /> */}
                    <div className="modal-manage-titled">
                      <Form.Group controlId="validation01">
                        <Form.Control
                          type="text"
                          placeholder="Enter Organisation name"
                          name="name"
                          value={values.name}
                          // onChange={handleChange}
                          onChange={(e) => handleTextChanged(e, setFieldValue)}
                          isInvalid={!!errors.name}
                          onFocus={() => setActive(!active)}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.name}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <div className="dropdown">
                        {active && (
                          <div className="dropdown-content">
                            {options &&
                              options.length > 0 &&
                              options.map((option, index) => (
                                <div
                                  key={`${index}-options`}
                                  onClick={(e) =>
                                    handleOptionSelect(e, option, setFieldValue)
                                  }
                                  className="dropdown-item"
                                >
                                  {option?.Charity_Legal_Name}
                                </div>
                              ))}
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="instruction my-4">
                    <p>
                      *Organisation Not Found in Charity List you have to enter
                      organisation information manually
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center my-4">
              <button className={"btn btn-pink"} type={"submit"}>
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
