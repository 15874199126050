import React, { Fragment } from "react";

//packages imports

import { useLocation } from "react-router-dom";

//user import

import Cause from "app/components/user/Cause";
import { Layout } from "app/components/user";

const OrgCause = () => {
  const { pathname } = useLocation();

  return (
    <Fragment>
      {pathname?.includes("/user") ? (
        <Cause />
      ) : (
        <Layout>
          <Cause />
        </Layout>
      )}
    </Fragment>
  );
};

export default OrgCause;
