import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";

//package imports

import {
  Tooltip,
  Backdrop,
  CircularProgress,
  Snackbar,
  FormControl,
  FormHelperText,
  Box,
  MenuItem,
  Alert,
  Avatar,
  Modal,
  Button,
  Typography,
} from "@mui/material";
import { Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

//user import

import {
  orgTagsEnumsArr,
  CATEGORIES_ARRAY,
  getCategorySubCategories,
  AUS_CITIES,
  AUS_STATES,
} from "app/common/organization/Enums";

import GoalModal from "./GoalModal";
// import { context } from "../../context";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { API_URLS } from "app/Services/organization/Routes/API";
import { ToFormData } from "app/utils/organization/FormData";
import CauseCreationModal from "./CauseCreationModal";
import { GetImageOrVideo } from "app/utils/organization/Functions";
import { BootstrapInput } from "app/common/organization/Components";
import { headersGet, headersPost } from "app/Services/organization/API";
import { addValidation } from "app/Services/organization/validations/CreateCause";
import { Link } from "react-router-dom";
import { context } from "app/context";

import CloseIcon from "@mui/icons-material/Close";

//constants

const initialValues = {
  organisation: "",
  title: "",
  // email: '',
  org_email: "",
  description: "",
  organisation_tag: "",
  micro_goal1_name: "",
  micro_goal1_value: "",
  micro_goal1_icon: "",
  micro_goal1_image: "",
  micro_goal2_name: "",
  micro_goal2_value: "",
  micro_goal2_icon: "",
  micro_goal2_image: "",
  micro_goal3_name: "",
  micro_goal3_value: "",
  micro_goal3_icon: "",
  micro_goal3_image: "",
  fund_raising_goal: "",
  category: "",
  sub_category: [],
  company_name: "",
  abn_number: "",
  cause_image: "",
  org_image: "",
  location: "",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 140,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

//component

export default function AddCause({ isEdit }) {
  // console.log("🚀 ~ AddCause ~ isEdit:", isEdit);
  //hooks

  const hiddenFileInput = useRef(null);
  const hiddenCauseInput = useRef(null);
  const hiddenOrgIconInput = useRef(null);
  const hiddenIconInput = useRef(null);
  const { orgDetails } = useContext(context);
  // console.log("🚀 ~ AddCause ~ orgDetails:", orgDetails);
  const { id } = useParams();
  const navigate = useNavigate();
  const { organisation_image } = orgDetails || {};

  //states

  const [show, setShow] = useState(false);
  const [gShow, setgShow] = useState(false);
  const [goal, setGoal] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [formValues, setFormValues] = useState(initialValues);
  const [causeId, setCauseId] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  //functions

  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);

  const handlegClose = () => setgShow(false);
  const handlegShow = (g) => {
    setGoal(g);
    setgShow(true);
  };

  //cause creation modal handler

  const handleClose = () => {
    setShow(false);
    navigate(APP_URLS?.org);
  };
  const handleShow = () => setShow(true);

  const handleCauseClick = (event) => {
    event.preventDefault();
    hiddenCauseInput.current.click();
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleIconClick = () => {
    hiddenIconInput.current.click();
  };

  //constants

  const longText = `
  Micro Goals are the 3 most important goals your charity is working on right now. Donors love to see where their money is going to- yo can also upload photos or videos to show them how their money is being spent. You can change your goals at any time. 
`;

  //add cause handler

  const addCauseHandler = (values) => {
    const data = {
      title: values?.title,
      description: values?.description,
      organisation_tag: values?.organisation_tag,
      micro_goal1_name: values?.micro_goal1_name,
      micro_goal1_icon: values?.micro_goal1_icon,
      micro_goal1_image: values?.micro_goal1_image,
      micro_goal1_value: values?.micro_goal1_value,
      micro_goal2_icon: values?.micro_goal2_icon,
      micro_goal2_image: values?.micro_goal2_image,
      micro_goal2_name: values?.micro_goal2_name,
      micro_goal2_value: values?.micro_goal2_value,
      micro_goal3_icon: values?.micro_goal3_icon,
      micro_goal3_image: values?.micro_goal3_image,
      micro_goal3_name: values?.micro_goal3_name,
      micro_goal3_value: values?.micro_goal3_value,
      fund_raising_goal: values?.fund_raising_goal?.toString(),
      category: values?.category,
      sub_category: values?.sub_category,
      cause_image: values?.cause_image,
      location: values?.location,
    };
    const formData = ToFormData(data);
    setOpen(true);
    headersPost(API_URLS?.createCause, formData)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          causeCreationModal(res?.data?.data?._id);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //update cause handler

  const updateCauseHandler = (values) => {
    const data = {
      title: values?.title,
      // email: values?.email,
      description: values?.description,
      organisation_tag: values?.organisation_tag,
      micro_goal1_name: values?.micro_goal1_name,
      micro_goal1_icon: values?.micro_goal1_icon,
      micro_goal1_image: values?.micro_goal1_image,
      micro_goal1_value: values?.micro_goal1_value,
      micro_goal2_icon: values?.micro_goal2_icon,
      micro_goal2_image: values?.micro_goal2_image,
      micro_goal2_name: values?.micro_goal2_name,
      micro_goal2_value: values?.micro_goal2_value,
      micro_goal3_icon: values?.micro_goal3_icon,
      micro_goal3_image: values?.micro_goal3_image,
      micro_goal3_name: values?.micro_goal3_name,
      micro_goal3_value: values?.micro_goal3_value,
      fund_raising_goal: values?.fund_raising_goal?.toString(),
      category: values?.category,
      sub_category: values?.sub_category,
      cause_image: values?.cause_image,
      location: values?.location,
    };
    const formData = ToFormData(data);

    setOpen(true);
    headersPost(`${API_URLS?.updateCause}/${id}`, formData)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          causeCreationModal(res?.data?.updatedCause?.id);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //form submit handler

  const submitHandler = (values) => {
    isEdit === true ? updateCauseHandler(values) : addCauseHandler(values);
  };

  const causeCreationModal = (id) => {
    setCauseId(id);
    handleShow();
  };

  const formInitialHandler = useCallback(() => {
    orgDetails &&
      setFormValues((prev) => ({
        ...prev,
        organisation: orgDetails?.id,
        org_email: orgDetails?.name,
        category: orgDetails?.category,
        abn_number: orgDetails?.abn_number,
        company_name: orgDetails?.company_name,
        organisation_tag: orgDetails?.organisation_type,
      }));
    const { category } = orgDetails || {};
    const subCat = category && getCategorySubCategories(category);
    const { sub_categories } = subCat || {};
    sub_categories && setSubCategories(sub_categories);
    setOpen(false);
  }, [orgDetails]);

  useEffect(() => {
    orgDetails && formInitialHandler();

    return () => {
      setFormValues(initialValues);
    };
  }, [orgDetails, formInitialHandler]);

  const getCause = useCallback(() => {
    setOpen(false);
    headersGet(`${API_URLS?.getCause}?causeId=${id}`)
      .then((res) => {
        setOpen(false);
        const { data, status } = res;
        const { status: dataStatus, cause } = data || {};
        if (status === 200 && dataStatus) {
          console.log({ cause });
          const { title, organisation_tag, organisation_type } = cause;
          setFormValues((prev) => ({
            ...prev,
            ...res?.data?.cause,
            // email,
            title,
            organisation_tag: organisation_tag,
            micro_goal1_name: res?.data?.cause?.micro_goal1?.name,
            micro_goal1_value: res?.data?.cause?.micro_goal1?.value,
            micro_goal1_icon: res?.data?.cause?.micro_goal1?.icon,
            micro_goal1_image: res?.data?.cause?.micro_goal1?.image,
            micro_goal2_name: res?.data?.cause?.micro_goal2?.name,
            micro_goal2_value: res?.data?.cause?.micro_goal2?.value,
            micro_goal2_icon: res?.data?.cause?.micro_goal2?.icon,
            micro_goal2_image: res?.data?.cause?.micro_goal2?.image,
            micro_goal3_name: res?.data?.cause?.micro_goal3?.name,
            micro_goal3_value: res?.data?.cause?.micro_goal3?.value,
            micro_goal3_icon: res?.data?.cause?.micro_goal3?.icon,
            micro_goal3_image: res?.data?.cause?.micro_goal3?.image,
          }));
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [id]);

  useEffect(() => {
    isEdit && id && getCause();
  }, [id, isEdit, getCause]);

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const [errMsg, setErrMsg] = useState(" ");

  const handleError = () => {
    setErrMsg(
      "supported formats (image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm)"
    );
  };

  //render

  // remove the sub cat

  const removesubCat = (values, setFieldValue, index) => () => {
    const updatedSubCategories = [...values.sub_category];
    updatedSubCategories.splice(index, 1);
    setFieldValue("sub_category", updatedSubCategories);
  };

  return (
    <div className="container my-5 justify-content-start">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="box" style={{ paddingLeft: "0px" }}>
            <Box textAlign={"right"} onClick={() => setOpen1(true)}>
              <img src="/images/close.png" width={"25px"} alt={"close"} />
            </Box>

            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1}>
                <Box textAlign={"right"}>
                  <img
                    src="/images/closepopup.svg"
                    width={"12px"}
                    onClick={handleClose1}
                    alt={"close"}
                  />
                </Box>
                <Typography
                  align="center"
                  fontWeight={"bold"}
                  sx={{
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    paddingTop: "0px",
                    color: "black",
                    lineHeight: "20px",
                  }}
                >
                  Are you sure you want to cancel creating this cause?
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-evenly"}
                  paddingTop={"10px"}
                >
                  <Box>
                    <Link to={APP_URLS?.org + APP_URLS?.cause}>
                      <Button variant="contained">Confirm</Button>
                    </Link>
                  </Box>

                  <Box>
                    <Button onClick={handleClose1} variant="contained">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
            <p className="cause-main">
              Please enter the details below to create an cause
            </p>
            <Formik
              initialValues={formValues}
              onSubmit={submitHandler}
              enableReinitialize
              validationSchema={addValidation}
              validateOnChange
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="text-center mt-5">
                    <div className="profile-logo-div mx-auto d-block">
                      {organisation_image?.length > 0 ? (
                        <img
                          src={organisation_image[0]}
                          alt="organisation-logo"
                          className="profile-logo-div mx-auto d-block"
                        />
                      ) : (
                        <Avatar
                          sx={{
                            height: "100%",
                            width: "100%",
                          }}
                        ></Avatar>
                      )}
                    </div>
                    <Form.Group
                      className="fields mb-3"
                      controlId="validationCustom01"
                    >
                      <Form.Control
                        type="file"
                        ref={hiddenOrgIconInput}
                        name={`org_image`}
                        style={{ display: "none" }}
                        accept={
                          "image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm"
                        }
                        onChange={(e) => {
                          setFieldValue(`org_image`, e.currentTarget.files[0]);
                        }}
                        isInvalid={!!errors.org_image}
                      />
                    </Form.Group>
                    {!!errors.org_image && (
                      <FormControl
                        error={touched.org_image && Boolean(errors.org_image)}
                        fullWidth
                        sx={{
                          margin: 0,
                          marginBottom: 2,
                        }}
                      >
                        <FormHelperText sx={{ textAlign: "center" }}>
                          {touched.org_image && errors.org_image}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </div>

                  <div className="row justify-content-center mt-5">
                    <div className="col-lg-11">
                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Organisation NAME</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation name"
                          name="org_email"
                          value={values.org_email}
                          disabled
                          onChange={handleChange}
                          isInvalid={!!errors.org_email}
                        />
                      </Form.Group>
                      {!!errors.org_email && (
                        <FormControl
                          error={touched.org_email && Boolean(errors.org_email)}
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.org_email && errors.org_email}
                          </FormHelperText>
                        </FormControl>
                      )}
                      {/* <Form.Group
                                                className='fields mb-3'
                                                controlId='validationCustom02'
                                            >
                                                <Form.Label>
                                                    {' '}
                                                    EMAIL/BUSINESS EMAIL
                                                </Form.Label>
                                                <Form.Control
                                                    className='field'
                                                    type='email'
                                                    placeholder='Enter organisation email'
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                />
                                            </Form.Group>
                                            {!!errors.email && (
                                                <FormControl
                                                    error={
                                                        touched.email &&
                                                        Boolean(errors.email)
                                                    }
                                                    fullWidth
                                                    sx={{
                                                        margin: 0,
                                                        marginBottom: 2,
                                                    }}
                                                >
                                                    <FormHelperText
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {touched.email &&
                                                            errors.email}
                                                    </FormHelperText>
                                                </FormControl>
                                            )} */}

                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom02"
                      >
                        <Form.Label>CHARITY CAUSE</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation charity cause"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                      </Form.Group>
                      {!!errors.title && (
                        <FormControl
                          error={touched.title && Boolean(errors.title)}
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.title && errors.title}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom03"
                      >
                        <Form.Label>DESCRIPTION</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation DESCRIPTION"
                          name="description"
                          value={values.description}
                          as={"textarea"}
                          rows={5}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                        />
                      </Form.Group>
                      {!!errors.description && (
                        <FormControl
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.description && errors.description}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label>CATEGORY</Form.Label>
                            <Form.Control
                              className="field"
                              type="text"
                              placeholder="Enter a category"
                              name="category"
                              as={"select"}
                              value={values.category}
                              onChange={handleChange}
                              disabled
                              isInvalid={!!errors.category}
                            >
                              <option key={"empty"} value={""}>
                                select a category
                              </option>
                              {CATEGORIES_ARRAY?.map((item, index) => (
                                <option
                                  key={`${index}-categories-company-${item.value}`}
                                  value={item.value}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          {!!errors.category && (
                            <FormControl
                              error={
                                touched.category && Boolean(errors.category)
                              }
                              fullWidth
                              sx={{
                                margin: 0,
                                marginBottom: 2,
                              }}
                            >
                              <FormHelperText
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {touched.category && errors.category}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </div>
                        <div className="col-md-6">
                          {/* <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label className={"text-uppercase"}>
                              {" "}
                              SUB Category
                            </Form.Label>

                            <Form.Control
                              className="field "
                              type="text"
                              name="sub_category"
                              as={"select"}
                              value={values.sub_category}
                              onChange={handleChange}
                              isInvalid={!!errors.sub_category}
                            >
                              <option disabled value={""}>
                                select a sub category
                              </option>
                              {subCategories?.map((item, index) => (
                                <option
                                  key={`${index}-sub-categories-company-${item.value}`}
                                  value={item.value}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group> */}
                          <Box className="fields mb-3">
                            <Form.Label className={"text-uppercase"}>
                              {" "}
                              SUB Category
                            </Form.Label>
                            <Box className="field">
                              <Box width={"100%"}>
                                <FormControl
                                  error={
                                    touched.sub_category &&
                                    Boolean(errors.sub_category)
                                  }
                                  fullWidth
                                >
                                  <BootstrapInput
                                    fullWidth
                                    name="sub_category"
                                    value={values?.sub_category}
                                    onChange={handleChange}
                                    multiple
                                    SelectDisplayProps={{}}
                                    className="mui_sub_cat_select"
                                  >
                                    {subCategories &&
                                      subCategories?.length > 0 &&
                                      subCategories?.map((item, index) => (
                                        <MenuItem
                                          key={`${index}-sub-categories-company-${item.value}`}
                                          value={item.value}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                  </BootstrapInput>
                                  <FormHelperText>
                                    {touched.sub_category &&
                                      errors.sub_category}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            className="show_sub_category"
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              textTransform: "capitalize",
                              justifyContent: "end",
                              flexWrap: "wrap",
                              marginBottom: "10px",
                              width: "70%",
                              marginLeft: "auto",
                              "& .cat_item": {
                                background: "#ff9776",
                                padding: "8px",
                                borderRadius: "5px",
                                color: "#fff",
                                position: "relative",
                              },
                              "& .close_icon": {
                                position: "absolute",
                                right: 0,
                                top: "-10px",
                                color: "#fff",
                                background: "#1c0a04",
                                borderRadius: "20px",
                                fontSize: "15px",
                                cursor: "pointer",
                              },
                            }}
                          >
                            {values?.sub_category &&
                              values?.sub_category?.map((val, index) => {
                                return (
                                  <>
                                    <div key={val + index} className="cat_item">
                                      <p>{val.replace(/_/g, " ")}</p>
                                      <CloseIcon
                                        className="close_icon"
                                        onClick={removesubCat(
                                          values,
                                          setFieldValue,
                                          index
                                        )}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                          </Box>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label className={"text-uppercase"}>
                              Organisation TAG
                            </Form.Label>
                            <Form.Control
                              className="field orgazation-tag-div"
                              type="text"
                              name="organisation_tag"
                              as={"select"}
                              value={values.organisation_tag}
                              onChange={handleChange}
                              isInvalid={!!errors.organisation_tag}
                              // disabled={true}
                            >
                              <option key={"empty"} value={""}>
                                select a organisation tag
                              </option>
                              {orgTagsEnumsArr?.map((item, index) => (
                                <option
                                  key={`${index}-org-tags-enums-${item?.value}`}
                                  value={item?.value}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          {!!errors.organisation_tag && (
                            <FormControl
                              error={
                                touched.organisation_tag &&
                                Boolean(errors.organisation_tag)
                              }
                              fullWidth
                              sx={{
                                margin: 0,
                                marginBottom: 2,
                              }}
                            >
                              <FormHelperText
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {touched.organisation_tag &&
                                  errors.organisation_tag}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label>LOCATION</Form.Label>
                            <Form.Control
                              className="field"
                              type="text"
                              placeholder="Enter a category"
                              name="location"
                              as={"select"}
                              value={values.location}
                              onChange={handleChange}
                              isInvalid={!!errors.location}
                            >
                              <option key={"empty"} value={""}>
                                select a location
                              </option>
                              {AUS_STATES?.map((item, index) => (
                                <option
                                  key={`${index}-categories-company-${item.value}`}
                                  value={item.value}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          {!!errors.location && (
                            <FormControl
                              error={
                                touched.location && Boolean(errors.location)
                              }
                              fullWidth
                              sx={{
                                margin: 0,
                                marginBottom: 2,
                              }}
                            >
                              <FormHelperText
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {touched.location && errors.location}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <div className="fields">
                            <label htmlFor="exampleInputEmail1"> </label>
                            <div className="grey-box">
                              {/* <Form.Group
                                className="fields mb-3"
                                controlId="validationCustom02"
                              >
                                <Form.Label>COMPANY NAME</Form.Label>
                                <Form.Control
                                  className="field"
                                  type="text"
                                  placeholder="Enter company name"
                                  name="company_name"
                                  value={values.company_name}
                                  onChange={handleChange}
                                  isInvalid={!!errors.company_name}
                                  disabled
                                />
                              </Form.Group> */}
                              <Form.Group
                                className="fields mb-3"
                                controlId="validationCustom02"
                              >
                                <Form.Label>ABN</Form.Label>
                                <Form.Control
                                  className="field"
                                  type="text"
                                  placeholder="Enter company ABN"
                                  name="abn_number"
                                  value={values.abn_number}
                                  onChange={handleChange}
                                  isInvalid={!!errors.abn_number}
                                  disabled
                                />
                              </Form.Group>
                              {/* <Form.Group
                                className="fields mb-3"
                                controlId="validationCustom02"
                              >
                                <Form.Label>CATEGORY</Form.Label>
                                <Form.Control
                                  className="field"
                                  type="text"
                                  placeholder="Enter a category"
                                  name="category"
                                  as={"select"}
                                  value={values.category}
                                  onChange={handleChange}
                                  isInvalid={!!errors.category}
                                  disabled
                                >
                                  <option key={"empty"} value={""}>
                                    select a category
                                  </option>
                                  {CATEGORIES_ARRAY?.map((item, index) => (
                                    <option
                                      key={`${index}-categories-company-${item?.value}`}
                                      value={item?.value}
                                    >
                                      {item?.name}
                                    </option>
                                  ))}
                                </Form.Control>

                                <Form.Control.Feedback type={"invalid"}>
                                  {errors?.category}
                                </Form.Control.Feedback>
                              </Form.Group> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div style={{width: "365px", height: "365px"}}> */}
                      <div
                        className="row justify-content-end"
                        style={{
                          maxHeight: "450px",
                          paddingRight: "6px",
                          paddingTop: "15px",
                        }}
                      >
                        <div className="col-lg-9">
                          <GetImageOrVideo image={values?.cause_image} />
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="fields mb-3 pt-3">
                        <label htmlFor="validationCustomUsername"> </label>
                        <div className="field">
                          <div className="upload my-3 w-100">
                            {/* <Form.Group
                              className="fields mb-3"
                              controlId="validationCustom01"
                            >
                              <label htmlFor="image__uplaod">
                                <span className="btn btn-upload py-3 w-100">
                                  <p>Add Image</p>
                                  <img
                                    src="/images/upload.svg"
                                    alt="upload"
                                    className="py-3"
                                  />
                                  <p>Upload</p>
                                </span>

                                <input
                                  type="file"
                                  id="image__uplaod"
                                  style={{ display: "none" }}
                                  accept="image/png, image/jpeg, image/jpg,"
                                  name={`cause_image`}
                                />
                              </label>
                            </Form.Group> */}

                            <Form.Group
                              className="fields mb-3"
                              controlId="validationCustom01"
                            >
                              <button
                                className="btn btn-upload py-3 w-100"
                                onClick={handleCauseClick}
                                type={"button"}
                              >
                                <p>Add Image</p>
                                <img
                                  src="/images/upload.svg"
                                  alt="upload"
                                  className="py-3"
                                />
                                <p>Upload</p>
                              </button>
                              <Form.Control
                                type="file"
                                ref={hiddenCauseInput}
                                // accept='image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm'
                                accept="image/png, image/jpeg, image/jpg,"
                                name={`cause_image`}
                                style={{
                                  display: "none",
                                }}
                                onChange={(e) => {
                                  let img = e.currentTarget.files[0];
                                  let array = [
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                  ];
                                  // let array =
                                  //     [
                                  //         'image/png',
                                  //         'image/jpeg',
                                  //         'image/jpg',
                                  //         'video/mp4',
                                  //         'video/ogg',
                                  //         'video/webm',
                                  //     ]
                                  // console.log({ imggggggg: img });

                                  if (
                                    img &&
                                    array.includes(img.type) === false
                                  ) {
                                    setErrMsg(
                                      "supported formats (image/png, image/jpeg, image/jpg)"
                                    );
                                  } else {
                                    setErrMsg("");
                                  }
                                  const maxSize = 4 * 1024 * 1024; // 4MB
                                  if (img?.size > maxSize) {
                                    setErrMsg(
                                      "File size should be less than 4MB"
                                    );
                                  }
                                  setFieldValue(`cause_image`, img);
                                  // console.log("cause_image", img);
                                }}
                                isInvalid={!!errors.cause_image}
                              />
                            </Form.Group>
                            {errMsg && errMsg !== " " && (
                              <div className="alert alert-danger" role="alert">
                                {" "}
                                {errMsg}{" "}
                              </div>
                            )}
                            {/* <Box padding={1}>supported formats (image/png, image/jpeg, image/jpg, video/mp4, video/ogg, video/webm) </Box> */}
                            {!!errors.cause_image && (
                              <FormControl
                                error={
                                  touched.cause_image &&
                                  Boolean(errors.cause_image)
                                }
                                fullWidth
                                sx={{
                                  margin: 0,
                                  marginBottom: 2,
                                }}
                              >
                                <FormHelperText
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  {touched.cause_image && errors.cause_image}
                                </FormHelperText>
                              </FormControl>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label className={"text-uppercase"}>
                              FUNDRAISING GOAL
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text
                                id="basic-addon3"
                                className="h-100"
                              >
                                $
                              </InputGroup.Text>
                              <Form.Control
                                className="field orgazation-tag-div"
                                type="number"
                                name="fund_raising_goal"
                                placeholder="enter a fund raising goal"
                                aria-describedby="basic-addon3"
                                value={values.fund_raising_goal}
                                onChange={handleChange}
                                isInvalid={!!errors.fund_raising_goal}
                              ></Form.Control>
                            </InputGroup>
                          </Form.Group>
                          {!!errors.fund_raising_goal && (
                            <FormControl
                              error={
                                touched.fund_raising_goal &&
                                Boolean(errors.fund_raising_goal)
                              }
                              fullWidth
                              sx={{
                                margin: 0,
                                marginBottom: 2,
                              }}
                            >
                              <FormHelperText
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {touched.fund_raising_goal &&
                                  errors.fund_raising_goal}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </div>
                      </div>

                      <div className="fields mb-3">
                        <label htmlFor="validationCustomUsername"> </label>
                        <div className="field">
                          <div className="error-info">
                            <p>
                              Please Add 3 Micro Goals{" "}
                              <Tooltip
                                title={longText}
                                placement="right-end"
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                arrow
                              >
                                <button
                                  className="border-0 bg-white"
                                  type={"button"}
                                >
                                  <img src="/images/error.svg" alt="error" />
                                </button>
                              </Tooltip>
                            </p>
                          </div>
                          <div className="goals">
                            <p>MICRO GOAL 1</p>
                            <div className="btn" onClick={() => handlegShow(1)}>
                              {values?.micro_goal1_name ? (
                                <p className="btn-link primary">View/Edit</p>
                              ) : (
                                <img
                                  src="/images/color-create.svg"
                                  alt="goal1"
                                />
                              )}
                            </div>
                          </div>
                          <div className="goals">
                            <p>MICRO GOAL 2</p>
                            <div className="btn" onClick={() => handlegShow(2)}>
                              {values?.micro_goal2_name ? (
                                <p className="btn-link primary">View/Edit</p>
                              ) : (
                                <img
                                  src="/images/color-create.svg"
                                  alt="goal2"
                                />
                              )}
                            </div>
                          </div>
                          <div className="goals">
                            <p>MICRO GOAL 3</p>
                            <div className="btn" onClick={() => handlegShow(3)}>
                              {values?.micro_goal3_name ? (
                                <p className="btn-link primary">View/Edit</p>
                              ) : (
                                <img
                                  src="/images/color-create.svg"
                                  alt="goal3"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <GoalModal
                    gShow={gShow}
                    handlegClose={handlegClose}
                    handleChange={handleChange}
                    hiddenFileInput={hiddenFileInput}
                    handleClick={handleClick}
                    values={values}
                    errors={errors}
                    touched={touched}
                    goal={goal}
                    setFieldValue={setFieldValue}
                    handleIconClick={handleIconClick}
                    hiddenIconInput={hiddenIconInput}
                  />
                  <div className="text-center">
                    <button
                      className="btn btn-pink py-2 px-5 my-5"
                      type={"submit"}
                    >
                      SUBMIT
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <CauseCreationModal
        show={show}
        handleClose={handleClose}
        causeId={causeId}
      />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
