import moment from "moment";

export const getArrayFromObject = (obj) => {
  return Object.values(obj).map((item) => ({
    name: item
      ?.replace(/_/g, " ")
      ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
    value: item,
  }));
};

export const formatCategory = (item) =>
  item
    ?.replace(/_/g, " ")
    ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const formatDate = (date)=>{
  return moment(date).format('DD/MM/yyyy')
}

export const formatDateTimeOnly = (date) => {
  return moment(date).format('hh:mm A')
 
}