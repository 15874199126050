import React, { Fragment, useState, useContext } from "react";
import "./loginModal.css";

//packages import

import { dividerClasses } from "@mui/material/Divider";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useTheme,
  Button,
  Link,
  Divider,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Modal,
  Dialog,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Close as CloseIcon } from "@mui/icons-material";
//user import
import ReCAPTCHA from "react-google-recaptcha";
import { fb, go } from "app/svg/user/signUp";
import Logo from "app/svg/user/Logo";

import { API_URLS } from "app/Services/user/routes/API";
import { addvalidation } from "app/Services/user/validations/login";

import { useAuthState } from "react-firebase-hooks/auth";
import { Verified } from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import ForgotPassword from "../ForgotPassword";
import { userRoutes } from "app/Services/user/routes/APP";
import { simplePost } from "app/Services/user/API";
import { StorageKeys } from "app/Services/user/Storage";
import useQuery from "app/utils/user/useQuery";
import { context } from "app/context";
import { signInWithGoogle, auth, signInWithFacebook } from "app/firebase";

//constants

const initialValues = {
  email: "",
  password: "",
};

// import $ from 'jquery'

// const loginModalStyles = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 700,
//     // height: 190,
//     bgcolor: '#fff',
//     borderRadius: '10px',
//     p: '8px 20px 32px 32px',
//     overflow: 'scroll',
// }

const LoginModal = ({
  open: modalOpen,
  closeHandler,
  handleLoginModalSuccess,
  supportState,
}) => {
  const { setUser, loginModalState } = useContext(context);
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoginModal, loginModal } = loginModalState || {};

  console.log("====================================");
  console.log({ loginModaInLogin: loginModal });
  console.log("====================================");

  const [user, loading, error] = useAuthState(auth);
  console.log({ user, loading, error });

  const query = useQuery();
  const referBack = query.get("referBack");
  const target = query.get("target");
  const isPopup = query.get("isPopup");
  const [showPassword, setShowPassword] = useState(false);
  console.log({ target });
  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [verified, setVerified] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  //navigate handler

  const handleCloseForgotPass = () => {
    setOpenForgotPass(false);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setVerified(true);
  };

  const clickHandler = (user, isSocial = false) => {
    const { isOnboard } = user;
    // setUser(user)
    handleLoginModalSuccess(user, supportState);
    if (referBack) {
      let referBackUrl = referBack;
      if (target) {
        referBackUrl = `${referBack}?target=${target}`;
      }
      console.log({ referBackUrl });
      navigate(referBackUrl);
    } else if (isPopup) {
      setOpen(false);
      setAlertOpen(true);
      setAlertMsg("Successfully logged in!");
      setAlertIcon("success");
      setLoginModal(false);
    } else {
      if (isSocial || isOnboard) {
        closeHandler();
        // navigate(userRoutes?.user + userRoutes?.discover)
      } else {
        navigate(userRoutes?.user + userRoutes?.getStarted);
      }
    }
  };

  //form submit handler

  const submitHandler = (values) => {
    const data = {
      email: values?.email,
      password: values?.password,
    };
    setOpen(true);

    simplePost(API_URLS?.login, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          clickHandler(res?.data?.user);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };
  //aleert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSocialLogin = async (google = false, facebook = false) => {
    let response;
    if (google === true) {
      response = await signInWithGoogle();
    }
    if (facebook === true) {
      response = await signInWithFacebook();
    }
    const accessToken = response?.user?.accessToken;
    setOpen(true);

    simplePost(API_URLS?.socialLogin, { accessToken })
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          clickHandler(res?.data?.user, true);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
    // if(response) {
    //   console.log(response);
    //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
    //   navigate(userRoutes?.user + userRoutes?.getStarted);
    // }
  };

  // const signUpWithGoogle = async () => {
  //     const response = await signInWithGoogle()
  //     const accessToken = response?.user?.accessToken
  //     setOpen(true)

  //     simplePost(API_URLS?.socialLogin, {accessToken})
  //         .then(res => {
  //             setOpen(false)
  //             if (res?.status === 200) {
  //                 localStorage.setItem(StorageKeys?.token, res?.data?.tokens)
  //                 clickHandler(res?.data?.user)
  //             } else {
  //                 setAlertOpen(true)
  //                 setAlertMsg(res?.data?.message)
  //                 setAlertIcon('warning')
  //             }
  //         })
  //         .catch(err => {
  //             const {response} = err
  //             setOpen(false)
  //             setAlertOpen(true)
  //             setAlertMsg(response?.data?.message)
  //             setAlertIcon('error')
  //         })
  //     // if(response) {
  //     //   console.log(response);
  //     //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
  //     //   navigate(userRoutes?.user + userRoutes?.getStarted);
  //     // }
  // }

  // const signUpWithFacebook = async () => {
  //     const response = await signInWithFacebook()
  //     console.log({fbRes: response})
  //     // const accessToken = response?.user?.accessToken
  //     // setOpen(true)

  //     // simplePost(API_URLS?.socialLogin, {accessToken})
  //     //     .then(res => {
  //     //         setOpen(false)
  //     //         if (res?.status === 200) {
  //     //             localStorage.setItem(StorageKeys?.token, res?.data?.tokens)
  //     //             clickHandler(res?.data?.user)
  //     //         } else {
  //     //             setAlertOpen(true)
  //     //             setAlertMsg(res?.data?.message)
  //     //             setAlertIcon('warning')
  //     //         }
  //     //     })
  //     //     .catch(err => {
  //     //         const {response} = err
  //     //         setOpen(false)
  //     //         setAlertOpen(true)
  //     //         setAlertMsg(response?.data?.message)
  //     //         setAlertIcon('error')
  //     //     })
  //     // if(response) {
  //     //   console.log(response);
  //     //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
  //     //   navigate(userRoutes?.user + userRoutes?.getStarted);
  //     // }
  // }
  //render
  console.log("====================================");
  console.log({ modalOpen });
  console.log("====================================");
  return (
    <Dialog open={modalOpen} onClose={closeHandler} maxWidth={"sm"} fullWidth>
      {/* <Modal
                    open={modalOpen}
                    onClose={closeHandler}
                    // aria-labelledby='modal-modal-title'
                    // aria-describedby='modal-modal-description'
                > */}
      <Box>
        <Fragment>
          {/* <Box
                            sx={{
                                backgroundColor: theme.palette.info.light,
                                backgroundImage: `url(/images/EllipseDonut.png)`,
                                backgroundRepeat: 'no-repeat',

                                backgroundSize: '20%',
                            }}
                        > */}
          {/* <Box style={{display: 'flex' , justifyContent: "flex-end"}}>
                        <IconButton
                                aria-label='close'
                                onClick={closeHandler}
                                
                                sx={{
                                    
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: theme => theme.palette.grey[500],
                                    float: 'right',
                                
                                    
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            </Box> */}
          <Typography
            color={"common.black"}
            variant="h6"
            align="center"
            sx={{
              marginTop: 5,
            }}
          >
            USER
          </Typography>
          <Typography
            color={"common.black"}
            variant="h4"
            align="center"
            sx={{
              marginTop: 1,
            }}
          >
            SIGN IN
          </Typography>
          <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Box
              sx={{
                border: `1px solid #FFD16B`,
                width: "10%",
              }}
            ></Box>
          </Box>
          {/* </Box> */}
          <Box
            sx={{
              backgroundColor: "#fff",
              // backgroundImage: `url(/images/EllipseSignUp.png)`,
              // backgroundPosition: 'right bottom',
              // backgroundSize: '35%',
              // backgroundRepeat: 'no-repeat',
            }}
          >
            <Grid container>
              {/* <Grid item xs={false} sm={false} md={1}></Grid> */}

              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Container>
                    <Box
                      sx={{
                        paddingX: {
                          md: 10,
                          sm: 4,
                          xs: 2,
                        },
                        paddingY: {
                          md: 2,
                          sm: 2,
                          xs: 2,
                        },
                      }}
                    >
                      <Formik
                        initialValues={initialValues}
                        onSubmit={submitHandler}
                        validationSchema={addvalidation}
                      >
                        {({
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Box marginBottom={3}>
                              <Typography
                                color={"common.black"}
                                fontWeight={"bold"}
                                gutterBottom
                              >
                                Enter Email
                              </Typography>
                              <TextField
                                fullWidth
                                variant={"outlined"}
                                color="info"
                                placeholder="Enter Your Email"
                                name="email"
                                value={values?.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={touched.email && Boolean(errors.email)}
                                className="tf-light-input"
                              ></TextField>
                            </Box>
                            <Box marginBottom={3}>
                              <Typography
                                color={"common.black"}
                                fontWeight={"bold"}
                                gutterBottom
                              >
                                Password
                              </Typography>
                              <TextField
                                fullWidth
                                variant={"outlined"}
                                color="info"
                                // type='password'
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Your Password"
                                name="password"
                                value={values?.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                className="tf-light-input"
                                error={
                                  touched.password && Boolean(errors.password)
                                }
                                InputProps={{
                                  endAdornment: showPassword ? (
                                    <VisibilityIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  ),
                                }}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //       <IconButton
                                //         onClick={()=>setShowPassword(!showPassword)}
                                //         // onMouseDown={}
                                //       >
                                //         {showPassword ? <Visibility /> : <VisibilityOff />}
                                //       </IconButton>
                                //     </InputAdornment>
                                //   }
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              paddingBottom={"20px"}
                            >
                              <ReCAPTCHA
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onChange}
                              />
                            </Box>
                            <Box display={"flex"} justifyContent={"center"}>
                              <Button
                                variant="contained"
                                sx={{
                                  boxShadow: theme.shadows[5],
                                  width: "45%",
                                }}
                                type={"submit"}
                                disabled={!verified}
                              >
                                Sign In
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>

                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={3}
                      >
                        <Typography color={"common.black"}>
                          Don't remember password?&nbsp;
                        </Typography>

                        <Link
                          color={"common.black"}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenForgotPass(true)}
                        >
                          Forgot Password
                        </Link>
                      </Box>
                      {/* <Box
                                                display={'flex'}
                                                justifyContent={'center'}
                                                marginTop={3}
                                            >
                                                <Typography
                                                    color={'common.black'}
                                                >
                                                    Don't have an account?&nbsp;
                                                </Typography>
                                                <Link
                                                    color={'common.black'}
                                                    href={userRoutes?.signUp}
                                                >
                                                    Sign Up
                                                </Link>
                                            </Box> */}

                      <Box
                        paddingTop={2}
                        color={"common.black"}
                        width={400}
                        margin="auto"
                      >
                        <Divider
                          sx={{
                            [`&.${dividerClasses.root}`]: {
                              "&::before": {
                                borderTop: "thin solid white",
                              },
                              "&::after": {
                                borderTop: "thin solid white",
                              },
                            },
                          }}
                        >
                          OR
                        </Divider>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={3}
                      >
                        <Button
                          // sx={{
                          //     backgroundColor:
                          //         '#475993',
                          //     width: '70%',
                          // }}
                          // variant='contained'
                          sx={{ padding: "0px" }}
                          startIcon={<img src={fb} />}
                          onClick={() => handleSocialLogin(false, true)}
                        >
                          {/* <Typography>
                                                        Sign In via Facebbok
                                                    </Typography> */}
                        </Button>

                        <Button
                          // sx={{
                          //     backgroundColor:
                          //         '#ffffff',
                          //     color: '#000000',
                          //     width: '70%',
                          // }}
                          // variant='contained'
                          sx={{
                            padding: "0px",
                            marginLeft: "10px",
                          }}
                          startIcon={<img src={go} />}
                          onClick={() => handleSocialLogin(true, false)}
                        >
                          {/* <Typography>
                                                        Sign In via Google
                                                    </Typography> */}
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {openForgotPass && (
            <ForgotPassword
              open={openForgotPass}
              closeHandler={handleCloseForgotPass}
            />
          )}
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={alertCloseHandler}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
          >
            <Alert
              onClose={alertCloseHandler}
              severity={alertIcon}
              sx={{ width: "100%" }}
            >
              {alertMsg}
            </Alert>
          </Snackbar>
        </Fragment>
        {/* <Box textAlign={'right'}>
                                    <img
                                        src='/images/closepopup.svg'
                                        width={'12px'}
                                        onClick={() => setShowRoundupPop(false)}
                                        alt={'close'}
                                    />
                                </Box> */}
        {/* <iframe
                        width={'100%'}
                        height={'500'}
                        title='Login'
                        style={{overflowY: 'auto'}}
                        src={`${window.location.origin}/login?isPopup=true`}
                    /> */}
        {/* <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-evenly'}
                        paddingTop={'40px'}
                    >
                        <Box>
                            <Button onClick={closeHandler} variant='contained'>
                                Close
                            </Button>
                        </Box>
                    </Box> */}
      </Box>
      {/* </Modal> */}
      {/* <Dialog open={open} onClose={closeHandler} maxWidth={'sm'} fullWidth>
            <iframe
                width={'100%'}
                height={'500'}
                title='Login'
                style={{overflowY: 'auto'}}
                src={`${window.location.origin}/login`}
            />
        </Dialog> */}
    </Dialog>
  );
};

export default LoginModal;
