import sadImage from "app/assets/admin/images/sad.png";
import hahaImage from "app/assets/admin/images/haha.png";
import likeImage from "app/assets/admin/images/like.png";
import angryImage from "app/assets/admin/images/angry.png";
import loveImage from "app/assets/admin/images/love.png";
import wowImage from "app/assets/admin/images/wow.png";

export const getReactionImage = (reaction) => {
  switch (reaction) {
    case "angry":
      return angryImage;
    case "love":
      return loveImage;
    case "sad":
      return sadImage;
    case "haha":
      return hahaImage;
    case "like":
      return likeImage;
    case "wow":
      return wowImage;
    default:
      return likeImage;
  }
};
