export const USER_DOMAIN =
  process.env.REACT_APP_USER_PORTAL_URL || "https://up4change-tf.web.app";

export const ORGANIZATION_APP_URLS = {
  root: "/",
  org: "/org",
  feed: "/feed",
  org_feed: "/org/feed",
  transaction: "/transaction",
  payment: "/payment",
  profile: "/profile",
  cause: "/cause",
  editCause: "/cause/:id",
  addCause: "/add-cause",
  viewCause: `/view-cause`,
  orgCause: `/org-cause`,
};
