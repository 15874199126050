import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { tada } from "react-animations";
//user import

import { GiftIcon } from "app/svg/user/Features";

//component

const Gift = () => {
  //hooks

  const theme = useTheme();

  const tadaAnimation = keyframes`${tada}`;
  const TadaDiv = styled.div`
    animation: 1s ${tadaAnimation} infinite;
  `;
  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        paddingY: 4,
        paddinX: 2,
      }}
    >
      <Container>
        <Box>
          <Typography
            variant="h5"
            color={"common.white"}
            align="center"
            fontWeight={"bold"}
          >
            You want to be able to connect to causes and likeminded people
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={4}>
          <Box width={200} height={160}>
            <TadaDiv>
              <GiftIcon
                width={"100%"}
                height={"100%"}
                color={theme.palette.secondary.light}
                sideColor={theme.palette.common.white}
              />
            </TadaDiv>
          </Box>
        </Box>
        <Box marginBottom={2}>
          <Typography
            color={"common.white"}
            align="center"
            variant="h6"
            fontWeight={"bold"}
            paddingTop={6}
          >
            Learn about new organisations and see your funds at work through a
            central social feed. Direct your funds to your favourite charities
            ‘micro goals’ so they know what’s important to you! Like, share,
            comment and interact with others in the social feed that have
            similar interests.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Gift;
