import React, { useState, useEffect } from "react";
import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import {
  ReactionBarSelector,
  ReactionCounter,
  FacebookSelector,
  FacebookCounter,
  FacebookCounterReaction,
  FacebookSelectorEmoji,
} from "@charkour/react-reactions";
import { simpleGet } from "app/Services/admin/API";
import { API_Urls } from "app/Services/admin/Routes/API";
import "./feedArea2.css";

import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  Alert,
  Snackbar,
  IconButton,
  Modal,
  Dialog,
} from "@mui/material";
import {
  Favorite as FavoriteIcon,
  MonetizationOn as MonetizationOnIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { getReactionImage } from "app/utils/admin/reactions";

const convertInteractionsToCounter = (interactionsData) => {
  let counter = 0;
  const newArr = [];
  if (interactionsData.length > 0) {
    for (let i = 0; i < interactionsData.length; i++) {
      const emojiObj = {
        emoji: interactionsData[i].interaction, // String name of reaction
        by: interactionsData[i]?.user?.name, // String of persons name
        profile_pic: interactionsData[i]?.user?.profile_pic,
      };
      newArr.push(emojiObj);
      counter++;
    }
  }
  return newArr;
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 600, lg: 600 },
  height: { xs: 350, sm: 400, md: 500, lg: 600 },
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

function FeedArea2({ feed, handleDelete }) {
  const { id: feedId } = feed || {};
  let isVideo = false;
  let media = null;
  if (feed?.image?.length > 0) {
    media = feed?.image[0];
    let splitted = media.split(".");
    let ext = splitted[splitted.length - 1];
    console.log({ ext });
    if (ext === "mp4") isVideo = true;
  }

  const [showSocialInteractions, setShowSocialInteractions] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [myInteraction, setMyInteraction] = useState(null);
  const [interactionsEmojis, setInteractionsEmojis] = useState([]);
  const [showInteractionsModal, setShowInteractionsModal] = useState(false);

  console.log("====================================");
  console.log({ interactionsEmojis });
  console.log("====================================");

  const handleCounterClick = () => {
    setShowInteractionsModal(true);
  };

  useEffect(async () => {
    try {
      const response = await simpleGet(
        `${API_Urls?.getAllInteractionsByFeed}/${feedId}`
      );
      console.log({ response });
      setInteractions(response?.data?.interactions);
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      // setAlertOpen(true)
      // setAlertMsg(message || 'Error found')
      // setAlertIcon('error')
    }
  }, [feed]);

  useEffect(() => {
    setInteractionsEmojis(convertInteractionsToCounter(interactions));
  }, [interactions]);

  return (
    <div className="feed-box mb-3">
      <div className="options">
        <div className="organisation-info">
          <div className="organisation-data">
            <div className="feed-logo-div">
              {feed?.organisation?.organisation_image?.length > 0 && (
                <img
                  src={feed?.organisation?.organisation_image[0]}
                  alt="organisation-logo"
                />
              )}
            </div>
            <div className="organisation-name">
              <p className="main-name">{feed?.title || "N/A"}</p>
              <p className="main-cause">
                <span>{feed?.organisation?.category || "N/A"}</span>
                <span>{" | "}</span>
                <span>{feed?.organisation?.cause || "N/A"}</span>
              </p>
              {moment}
              <p className="main-time">
                {moment(feed?.createdAt)?.fromNow(true)} ago
              </p>
            </div>
          </div>
        </div>
        <div className="option-view">
          <div className="dropdown">
            <button type="button" className="btn" data-toggle="dropdown">
              <BsThreeDots className="color-grey" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item post" href="edit-post">
                EDIT POST
              </a>
              <hr className="my-2" />
              <div
                className="dropdown-item post"
                onClick={() => handleDelete(feed?.id)}
              >
                DELETE POST
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feed-description">
        <p>{feed?.description}</p>
      </div>

      {media && isVideo && (
        <video
          src={media}
          width="100%"
          height="400"
          controls
          className="my-4"
        ></video>
      )}

      {media && !isVideo && (
        <div
          className="feed-img-wrapper"
          style={{
            backgroundImage: `url(${media})`,
            height: "400px",
          }}
        ></div>
      )}

      <div style={{ margin: "10px" }}>
        <FacebookCounter
          counters={interactionsEmojis}
          user={true}
          onClick={handleCounterClick}
        />
      </div>

      <div className="likes-comments">
        <div className="buttons">
          {/* <button className='btn btn-darkBlue'>
                            <AiOutlineLike />
                            &nbsp; Like &nbsp; <span>{feed.likes}</span>
                        </button>
                        <button className='btn btn-darkBlue mx-3'>
                            <FaRegCommentAlt /> &nbsp;Comment &nbsp;
                            <span>{feed.comment}</span>
                        </button>
                        <button className='btn btn-darkBlue'>
                            <RiShareForwardLine /> &nbsp;Share
                        </button> */}
        </div>
      </div>
      <Modal
        open={showInteractionsModal}
        onClose={() => setShowInteractionsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Box textAlign={"right"}>
            <IconButton
              aria-label="close"
              onClick={() => setShowInteractionsModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* <img
                                                    src='/images/closepopup.svg'
                                                    width={'12px'}
                                                    onClick={handleClose1}
                                                    alt={'close'}
                                                /> */}
          </Box>
          <Typography
            align="center"
            fontWeight={"bold"}
            sx={{
              // fontFamily: 'Circular Std',
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22px",
              paddingTop: "20px",
              textAlign: "left",
              color: "#000",
            }}
          >
            Reactions ({interactions.length})
          </Typography>
          <hr />
          <div className="interaction-table">
            {interactionsEmojis &&
              interactionsEmojis.length > 0 &&
              interactionsEmojis.map((reaction, reactionIdx) => {
                console.log({
                  helloIMG: getReactionImage(reaction?.emoji),
                });
                return (
                  <div key={reactionIdx} className="interaction-col">
                    <div className="interaction-pic-wrapper">
                      {reaction?.profile_pic ? (
                        <>
                          <div
                            style={{
                              position: "relative",
                              marginRight: "30px",
                            }}
                          >
                            <img
                              src={reaction?.profile_pic}
                              alt="User"
                              className="interaction-pic"
                            />
                            <img
                              className="interaction-pic-react"
                              src={getReactionImage(reaction?.emoji)}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                              marginRight: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="interaction-picNot">
                              {reaction?.by && reaction?.by[0]}
                            </div>
                            <img
                              className="interaction-pic-react"
                              src={getReactionImage(reaction?.emoji)}
                            />
                          </div>
                        </>
                      )}
                      {/* {reaction?.profile_pic ? } */}
                    </div>
                    <p style={{ color: "#000" }}>
                      <strong>{reaction?.by}</strong>
                    </p>
                  </div>
                );
              })}
          </div>
          {/* <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-evenly'}
                            paddingTop={'40px'}
                        >
                            <Box>
                                <Button
                                    variant='contained'
                                    // onClick={() => submitHandler(values)}
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Box>

                            <Box>
                                <Button
                                    onClick={() =>
                                        setShowInteractionsModal(false)
                                    }
                                    variant='contained'
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box> */}
        </Box>
      </Modal>
    </div>
  );
}

export default FeedArea2;
