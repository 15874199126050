import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

//package import

import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Pagination,
  Tooltip,
} from "@mui/material";
import { buttonClasses } from "@mui/material/Button";
import { paginationItemClasses } from "@mui/material/PaginationItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
//user import

import {
  formatCategory,
  formatDateTime,
  formatDateTimeOnly,
  formatDate,
} from "app/common/user/functions";

import { headersGet } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import { DownloadIcon, PrintIcon } from "app/svg/user/DonationHistory";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fontSize } from "@mui/system";
import { context } from "app/context";

//constants

const headings = [
  "DATE",
  "NAME",
  "DIRECTION",
  "AMOUNT",
  "DESCRIPTION",
  "Round OFF VALUE",
];

//component

const BasiqTransactionHistory = () => {
  //hooks

  const theme = useTheme();
  const { user } = useContext(context);
  // console.log('🚀 ~ BasiqTransactionHistory ~ user----------------:', user);
  const { _id } = user || {};
  const { basiqTransactions = [] } = user || {};

  //states

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentBasiqTransactions, setCurrentBasiqTransactions] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [alertIcon, setAlertIcon] = useState("error");
  const [currentDay, setCurrentDay] = useState("last_month");
  const [isLoading, setIsLoading] = useState(true);
  const transactionsPerPage = 10;

  const updateTotalPages = (transactions) => {
    const total = Math.ceil(transactions.length / transactionsPerPage);
    setTotalPages(total);
  };

  useEffect(() => {
    const total = Math.ceil(basiqTransactions.length / transactionsPerPage);
    setTotalPages(total);
    console.log("Total Pages:", total);
  }, [basiqTransactions]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Donations History";
    const headers = [
      ["DATE", "NAME", "DIRECTION", "AMOUNT", "DESCRIPTION", "BALANCE"],
    ];
    // console.log({ data });

    // const data1 = data.map((elt) => [
    const data1 = user?.basiqTransactions?.map((elt) => [
      elt.postDate,
      elt.name,
      elt.direction,
      elt.amount,
      elt.description,
      elt.roundOff,
    ]);

    // console.log({ data1 });
    let content = {
      startY: 50,
      head: headers,
      body: data1,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Donations History.pdf");
  };

  const matches = useMediaQuery("(min-width:1025px)");
  const matches1 = useMediaQuery("(max-width:700px)");

  const getAmountRoundUp = (amount) => {
    const nextWholeNumber = Math.ceil(amount);

    const roundedUpAmount = nextWholeNumber - amount;
    // console.log('🚀 ~ getAmountRoundUp ~ roundedUpAmount:', roundedUpAmount.toFixed(2));

    return parseFloat(roundedUpAmount.toFixed(2));
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    // console.log('Current Page:', value);
  };

  const getCurrentTransactions = () => {
    const startIndex = (page - 1) * transactionsPerPage;
    const endIndex = startIndex + transactionsPerPage;
    const currentTransactions = basiqTransactions.slice(startIndex, endIndex);
    // console.log(`Transactions for Page ${page}:`, currentTransactions);
    return currentTransactions;
  };

  //render

  return (
    <Box
      bgcolor={theme.palette.info.light}
      padding={2}
      paddingTop={10}
      minHeight={"100vh"}
    >
      <Container>
        <Typography color={"text.red"} gutterBottom paddingBottom={2}>
          My Account - Basiq Transactions History
        </Typography>

        <Paper
          sx={{
            padding: 2,
          }}
        >
          <Box display={"flex"} justifyContent={"flex-end"}>
            {/* <Box>
              <TextField select value={currentDay} onChange={(e) => setCurrentDay(e.target.value)}>
                {console.log(currentDay)}
                <MenuItem value={'last_day'}>Last Day</MenuItem>
                <MenuItem value={'last_week'}>Last Week</MenuItem>
                <MenuItem value={'last_month'}>Last Month</MenuItem>
              </TextField>
            </Box> */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {/* <Box paddingY={1}>
                <Tooltip
                  title="Request Tax Statement"
                  placement="top-end"
                  color={"primary"}
                  enterTouchDelay={0}
                  arrow
                >
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{
                      paddingX: 2,
                      textTransform: "initial",
                    }}
                  >
                    {matches && <>Request Tax Statement</>}
                    {matches1 && <>RTS</>}
                  </Button>
                </Tooltip>
              </Box> */}
              {/* <Box>
                <IconButton onClick={() => exportPDF()}>
                  <DownloadIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </Box> */}
            </Box>
          </Box>
          <TableContainer sx={{ marginY: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headings?.map((head, index) => (
                    <TableCell
                      key={`${index}-table-heading-${head}`}
                      align={"center"}
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {getCurrentTransactions()?.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      No Data Found!
                    </TableCell>
                  </TableRow>
                )}

                {getCurrentTransactions()?.length > 0 &&
                  getCurrentTransactions().map((row, index) => {
                    const {
                      postDate,
                      account,
                      direction,
                      amount,
                      description,
                      balance,
                    } = row || {};
                    //   const { category, company_name, name } = organisation;
                    //   const { title } = cause || {};
                    // const {number} = bank
                    return (
                      <TableRow key={`${index}-${row?.date}`}>
                        <TableCell align="center">
                          {formatDate(postDate)}
                          {/* <br></br>
                        <span
                          style={{
                            fontSize: '12px',
                            color: '#9094a2',
                          }}>
                          {formatDateTimeOnly(postDate)}
                        </span> */}
                        </TableCell>
                        <TableCell align="center">{user?.name}</TableCell>
                        <TableCell align="center">{direction}</TableCell>
                        {/* <TableCell align="center">{formatCategory(category)}</TableCell> */}
                        <TableCell align="center">
                          {/* {company_name} */}
                          {amount || "N/A"}
                        </TableCell>
                        <TableCell align="center">{description}</TableCell>
                        <TableCell align="center">
                          {getAmountRoundUp(amount)}
                        </TableCell>

                        {/* <TableCell align="center">{formatCategory(type)}</TableCell>
                      <TableCell align="center">
                        {bank?.number?.substr(-4) || bank?.account_number || 'N/A'}
                      </TableCell>
                      <TableCell align="center">${amount?.toFixed(2)}</TableCell>
                      <TableCell align="center">${pp_fees ? pp_fees?.toFixed(2) : 0}</TableCell>
                      <TableCell align="center">
                        ${(parseInt(amount) + (pp_fees ? parseInt(pp_fees) : 0))?.toFixed(2)}
                      </TableCell> */}
                      </TableRow>
                    );
                  })}

                {/* {isLoading && (
                  <TableRow>
                    <TableCell align="center">
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#9094a2',
                        }}>
                        <Skeleton count={1} height="30px" />
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton count={1} height="30px" />
                    </TableCell>
                  </TableRow>
                )} */}
                {/* <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}>
                  
                  <TableCell
                    colSpan={5}
                    align="right"
                    sx={{
                      fontWeight: 'bold',
                    }}>
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 'bold',
                    }}>
                    ${totalAmount?.toFixed(2)}
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box paddingY={2} display={"flex"} justifyContent={"center"}>
          <Pagination
            count={totalPages}
            color="primary"
            page={page}
            variant="contained"
            sx={{
              [`& .${paginationItemClasses.root}`]: {
                backgroundColor: theme.palette.common.white,
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              },
              [`& .${buttonClasses.root}`]: {
                backgroundColor: theme.palette.common.white,
              },
            }}
            onChange={handleChangePage}
          />
        </Box>
      </Container>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BasiqTransactionHistory;
