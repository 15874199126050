import React from "react";

const Download = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "32"}
      height={height ? height : "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 0H13.5C12.6688 0 12 0.66875 12 1.5V12H6.51875C5.40625 12 4.85 13.3438 5.6375 14.1313L15.1437 23.6437C15.6125 24.1125 16.3813 24.1125 16.85 23.6437L26.3688 14.1313C27.1562 13.3438 26.6 12 25.4875 12H20V1.5C20 0.66875 19.3313 0 18.5 0ZM0 23.5V30.5C0 31.3312 0.668751 32 1.5 32H30.5C31.3313 32 32 31.3312 32 30.5V23.5C32 22.6688 31.3313 22 30.5 22H21.3313L18.2688 25.0625C17.0125 26.3188 14.9875 26.3188 13.7313 25.0625L10.6688 22H1.5C0.668751 22 0 22.6688 0 23.5ZM7.75 29C7.75 28.3125 8.3125 27.75 9 27.75C9.6875 27.75 10.25 28.3125 10.25 29C10.25 29.6875 9.6875 30.25 9 30.25C8.3125 30.25 7.75 29.6875 7.75 29ZM3.75 29C3.75 28.3125 4.3125 27.75 5 27.75C5.6875 27.75 6.25 28.3125 6.25 29C6.25 29.6875 5.6875 30.25 5 30.25C4.3125 30.25 3.75 29.6875 3.75 29Z"
        fill="black"
      />
    </svg>
  );
};

export default Download;
