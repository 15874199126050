import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";

export const BootstrapInput = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  legend: {
    display: "none",
    width: "0px !important",
  },
  input: {
    border: "none !important",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    border: "none !important",
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    fontSize: 16,
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  "&.mui_sub_cat_select": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "337px",
  },
}));
