import * as Yup from 'yup';

import { variables } from '../variables';

const { required, password_match } = variables || {};

export const addValidation = Yup.object({
  old_password: Yup.string().required(required),
  new_password: Yup.string().required(required),
  re_new_password: Yup.string()
    .required(required)
    .oneOf([Yup.ref('new_password')], password_match),
});
