import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
//user import

import { ConnectIcon } from "app/svg/user/Features";

//component

const Gift = () => {
  //hooks
  const pulseAnimation = keyframes`${pulse}`;
  const PulseDiv = styled.div`
    animation: 1s ${pulseAnimation} infinite;
  `;
  const theme = useTheme();

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: 4,
        paddinX: 2,
      }}
    >
      <Container>
        <Box>
          <Typography
            variant="h5"
            color={"common.black"}
            align="center"
            fontWeight={"bold"}
          >
            You want to be able to connect to causes and likeminded people
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={4}>
          <Box width={270} height={230}>
            <PulseDiv>
              <ConnectIcon
                width={"100%"}
                height={"100%"}
                color={theme.palette.secondary.light}
                sideColor={theme.palette.common.white}
              />
            </PulseDiv>
          </Box>
        </Box>
        <Box marginBottom={2}>
          <Typography
            color={"common.black"}
            align="center"
            variant="h6"
            fontWeight={"bold"}
          >
            Learn about new organisations and see your funds at work through a
            central social feed. Direct your funds to your favourite charities
            ‘micro goals’ so they know what’s important to you! Like, share,
            comment and interact with others in the social feed that have
            similar interests.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Gift;
