import React, { useState, useEffect, useCallback } from "react";

//packages import

import { FaSearch } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import { format } from "date-fns";

//user import

import "./user.css";

import UserModal from "./UserModal";

// import DataProgress from "../../Components/Progress";
// import Pagination from "../../Components/Pagination";
import Pagination from "rc-pagination";
import "../Donation/style.css";
import moment from "moment";
import { useLayoutEffect } from "react";
import { API_Urls } from "app/Services/admin/Routes/API";
import { headersGet, headersUpdate } from "app/Services/admin/API";
import DataProgress from "app/components/admin/Progress";
import {
  removeToken,
  useAuth,
} from "app/Services/admin/Routes/APP/PrivateRoute";
import { Toast } from "app/common/admin/alert";

//component

export default function UserManagement() {
  //states

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersBackup, setUsersBackup] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [api, setApi] = useState(true);
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  //close user modal handler

  const handleClose = () => {
    setUser(null);
    setShow(false);
  };

  //open user modal handler

  const handleShow = (row) => {
    setUser(row);
    setShow(true);
  };

  // search section

  // const handleSearch = (e) => {
  //   setSearch(e.target.value);
  //   if (e.target.value?.length > 3 || e.target.value?.length === 0) {
  //     setOpen(true);
  //     setApi(false);
  //     headersGet(
  //       `${API_Urls?.get_users}?role=${"user"}&page=${page}&limit=${10}${
  //         e.target.value && `&searchQuery=${e.target.value}`
  //       }`
  //     )
  //       .then((res) => {
  //         setOpen(false);
  //         if (res?.status === 200) {
  //           setUsers(res?.data?.results);
  //           setUsersBackup(res?.data?.results);
  //           setTotalPages(res?.data?.totalPages);
  //         } else {
  //           setAlertOpen(true);
  //           setAlertMsg(res?.data?.message);
  //           setAlertIcon("warning");
  //         }
  //       })
  //       .catch((err) => {
  //         const { response } = err;

  //         if (response?.status === 401) {
  //         } else {
  //           setOpen(false);
  //           setAlertOpen(true);
  //           setAlertMsg(response?.data?.message);
  //           setAlertIcon("danger");
  //         }
  //       });
  //   }
  // };

  const DEBOUNCE_DELAY = 400;
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    const searchQuery = search?.trim();

    const timeoutId = setTimeout(() => {
      setOpen(true);
      setApi(false);
      headersGet(
        `${API_Urls?.get_users}?role=${"user"}&page=${page}&limit=${10}${
          searchQuery && `&searchQuery=${searchQuery}`
        }`
      )
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            setUsers(res?.data?.results);
            setUsersBackup(res?.data?.results);
            setTotalPages(res?.data?.totalPages);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("danger");
        });
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [search]);

  // End search section

  const userTableView = (row, index) => {
    return (
      <tr key={`${index}-${row?.id}`}>
        <td>
          {row?.id?.slice(0, 4)}...{row?.id?.slice(20, 24)}
        </td>
        <td>{row?.name}</td>
        <td>{row?.email}</td>
        <td>{row?.phone_number}</td>
        {/* <td>
                    {row?.recent_round_up}
                    <br />
                    <span>
                        <a href='#/' className='text-view'>
                            View
                        </a>
                    </span>
                </td> */}
        <td>
          {/* {row?.dateOfJoin
                        ? format(new Date(row?.dateOfJoin), 'dd, MMMM, yyyy')
                        : ''} */}
          {row?.dateOfJoin ? moment(row?.dateOfJoin).format("DD/MM/YY") : ""}
          <br></br>
          <span style={{ fontSize: "12px", color: "#9094a2" }}>
            {row?.dateOfJoin ? moment(row?.dateOfJoin).format("hh:mm A") : ""}
          </span>
        </td>
        <td>
          <span
            className={
              row?.isblock === true
                ? "btn badge badge-pill badge-danger px-4 py-2"
                : "btn badge badge-pill badge-success px-3 py-2"
            }
            onClick={() => updateUser(!row.isblock, row.id)}
          >
            {row?.isblock === true ? "In-Active" : "Active"}
          </span>
        </td>
        <td>
          <a href="#/" onClick={() => handleShow(row)} className="manage">
            Manage
          </a>
        </td>
      </tr>
    );
  };

  const getAllUserHandler = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(
      `${API_Urls?.get_users}?role=${"user"}&page=${page}&limit=${10}${
        search && `&searchQuery=${search}`
      }`
    )
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setUsers(res?.data?.results);
          setUsersBackup(res?.data?.results);
          setTotalPages(res?.data?.totalPages);
          setTotalResults(res?.data?.totalResults);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        if (response?.status === 401) {
        } else {
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("danger");
        }
      });
  }, [page, search]);

  //life cycle

  useEffect(() => {
    api && getAllUserHandler();
  }, [api, getAllUserHandler]);

  //handle pagination

  const handlePagination = (p) => {
    setPage(p);
    setApi(true);
  };

  //render

  //update organisation api

  const updateUser = (uActive, id) => {
    const data = {
      isblock: uActive,
    };

    setOpen(true);

    headersUpdate(`${API_Urls?.update_user}/${id}`, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          // setAlertOpen(true);
          // setAlertMsg('User is updated successfully');
          // setAlertIcon('success');
          Toast.success("User is updated successfully");
          setApi(false);
          getAllUserHandler();
        } else {
          // setAlertOpen(true);
          // setAlertMsg(res?.data?.message);
          // setAlertIcon('warning');
          Toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        // setAlertOpen(true);
        // setAlertMsg(response?.data?.message);
        // setAlertIcon('danger');
        Toast.error(response?.data?.message);
      });
  };

  //get all user api

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          {alertOpen && (
            <Alert
              variant={alertIcon}
              onClose={() => setAlertOpen(false)}
              show={alertOpen}
              dismissible
            >
              {alertMsg}
            </Alert>
          )}
          <div className="row table-bar">
            <div className="col-lg-6 col-12 col-sm-12 col-md-12">
              <p className="title-bar-heading">LIST OF ALL USERS</p>
            </div>
            <div className="col-lg-6 col-12 col-sm-12 col-md-12">
              <div className="user-search-icon">
                <p>
                  <FaSearch className="searchIcon" />
                </p>
                <input
                  type="search"
                  placeholder="SEARCH"
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">NAME</th>
                  <th scope="col">EMAIL</th>
                  <th scope="col">PHONE NO</th>
                  {/* <th scope='col'>PAYMENT METHOD</th> */}
                  <th scope="col">DATE OF JOIN</th>
                  <th scope="col">CURRENT STATUS</th>
                  <th scope="col">MANAGE USER</th>
                </tr>
              </thead>
              <tbody>
                {open ? (
                  <DataProgress rows={8} cols={8} />
                ) : (
                  users?.map(userTableView)
                )}
              </tbody>
            </table>
          </div>

          {/* <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePagination}
          /> */}
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              className="ant-pagination"
              current={page}
              defaultCurrent={3}
              total={totalResults}
              onChange={handlePagination}
            />
          </span>
          <UserModal show={show} handleClose={handleClose} user={user} />
        </div>
      </div>
    </div>
  );
}
