import React from 'react'
import {Modal} from 'react-bootstrap'

const DonationModal = ({show, handleClose, data}) => {

    console.log({data});
    const {id, amount, organisation, user, cause, charges, subscription} = data || {}
    const {name, organisation_image, company_name  , category} = organisation || {}
    const {name: userName} = user || {}
    const {title} = cause || {}
    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Body>
                <div className='modal-body-md my-4'>
                    <div className='col-lg-12'>
                        <div className='m-organ text-center'>
                            <div className='logo-div mx-auto d-block' style={{maxWidth: "30%"}}>                                {organisation_image?.length > 0 ? (
                                    <img
                                        src={organisation_image[0]}
                                        alt='organisation-logo'
                                        width={"100%"}
                                    />
                                ) : (
                                    <div>
                                        {name?.splice(0, 1)?.toUpperCase()}
                                    </div>
                                )}
                            </div>
                            <div className='m-name-div'>
                                <p>{name || ''}</p>
                            </div>
                            <p className='m-fname'>{title || ''}</p>
                        </div>

                        <div className='my-4 m-table'>
                            <table className='table'>
                                <tbody>
                                    {
                                        <tr key={id}>
                                            <td>{id}</td>
                                            <td>{userName || '---'}</td>
                                            {/* <td>
                                                {charges ||
                                                    subscription ||
                                                    '---'}
                                            </td> */}
                                            <td>{category}</td>
                                            <td>{title || '---'}</td>
                                            <td>${amount}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className='text-center my-3'>
                            <button className='btn btn-pink py-2'>
                                Export
                            </button>
                        </div> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DonationModal
