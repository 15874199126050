import React, { useContext, useState } from "react";

//package import

import {
  Box,
  Container,
  Paper,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

//user import

import ViewEditModal from "./ViewEditModal";
import { EditIcon } from "app/svg/user/Profile";
import ConfirmationModal from "./ConfirmationModal";
import BasiqModal from "../Basiq";
// import { context } from "../../context";
// import { headersGet } from "../../services/API";
import { API_URLS } from "app/Services/user/routes/API";
import { headersGet } from "app/Services/user/API";
import { NoDataFound } from "app/common/user/components";
import { context } from "app/context";

//component

const Payment = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useContext(context);
  const { basiqId } = user || {};

  //state
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [open, setOpen] = useState(false);
  const [basiqOpen, setBasiqOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [data] = useState([]);

  //open  edit/view modal

  const handleClickOpen = () => {
    setOpen(true);
  };

  //close  edit/view modal

  const handleClose = () => {
    setOpen(false);
  };

  //open confirm modal

  const handleConfirmOpen = () => {
    setOpenConfirm(true);
  };

  //close  confirm modal

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const addRoundUpHandler = () => {
    if (basiqId) {
      const url = `${API_URLS?.getBasiqRoute2}?serverToken=false&basiqId=${basiqId}`;
      setLoader(false);

      headersGet(url)
        .then((res) => {
          const { data, status } = res;
          const { data: basiqData, status: basiqStatus, message } = data || {};
          const { access_token } = basiqData || {};
          setLoader(false);
          if (status === 200 && access_token && basiqStatus) {
            setToken(access_token);
            setBasiqOpen(true);
          } else {
            setAlertOpen(true);
            setAlertMsg(message || "");
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err || {};
          const { message } = response || {};
          setLoader(false);
          setAlertOpen(true);
          setAlertMsg(message);
          setAlertIcon("error");
        });
    } else {
      setLoader(false);
      setAlertOpen(true);
      setAlertMsg("Please create a user or login ");
      setAlertIcon("error");
    }
  };

  //render

  return (
    <Box
      bgcolor={theme.palette.info.light}
      padding={2}
      paddingTop={10}
      minHeight={"100vh"}
    >
      <Container>
        <Typography color={"text.red"} gutterBottom paddingBottom={2}>
          My Account - Payments Info - RoundUp
        </Typography>

        <Paper
          sx={{
            padding: 2,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"}>
                Total Rounded Up this month
              </Typography>

              <Box paddingLeft={1}>
                <EditIcon />
              </Box>
            </Box>
            {data?.length > 0 && (
              <>
                <Typography
                  paddingY={2}
                  color={"primary.main"}
                  variant="h3"
                  fontWeight={"bold"}
                  align="center"
                >
                  $25.00
                </Typography>
                <Typography
                  paddingY={2}
                  component={"p"}
                  variant="caption"
                  align="center"
                >
                  Your next roundup will process on Monday May 12, 2021
                </Typography>{" "}
              </>
            )}
          </Box>
          <Box>
            <Typography align="center" fontWeight={"bold"} variant="h6">
              Charities included in next round-up
            </Typography>
          </Box>
          <TableContainer sx={{ marginY: 2 }}>
            <Table>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow key={`${index}-${row?.date}`}>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row?.date}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Box width={50} height={50}>
                          <img
                            src="/images/charityLogo.png"
                            alt="charity Logo"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Box>
                        <Box pl={2}>{row?.org}</Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row?.category}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row?.charity_name}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row?.amount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      <Button variant="text" onClick={handleClickOpen}>
                        <Typography
                          component={"span"}
                          borderBottom={`1px solid ${theme.palette.common.black}`}
                          color={"black"}
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          View/Edit
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {!data?.length && <NoDataFound />}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign={"center"} paddingY={2}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={addRoundUpHandler}
            >
              Add Another RoundUp
            </Button>
          </Box>
        </Paper>
      </Container>
      <ViewEditModal
        open={open}
        handleClose={handleClose}
        handleConfirmOpen={handleConfirmOpen}
      />

      <ConfirmationModal open={openConfirm} handleClose={handleConfirmClose} />

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      {basiqOpen && (
        <BasiqModal
          open={basiqOpen}
          closeHandler={() => setBasiqOpen(!basiqOpen)}
          token={token}
          basiqId={basiqId}
        />
      )}
    </Box>
  );
};

export default Payment;
