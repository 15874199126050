import React, { useState, useEffect, useCallback, useContext } from "react";

//package import

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { decodeToken, isExpired } from "react-jwt";

//user imports

import { StorageKeys } from "app/Services/organization/StorageKeys";
// import { APP_URLS } from "./index";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";

// import { context } from "app/context";
//component

// import { API_URLS } from "app/Services/organization/Routes/API";
// import { simpleGet } from "app/Services/organization/API";

export const OrgPrivateRoute = ({ element: Component, ...rest }) => {
  //hooks
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const auth = useAuth();
  const token = localStorage.getItem(StorageKeys?.token);
  const org_info = JSON.parse(localStorage.getItem(StorageKeys?.org_info));

  //return component

  // const { setOrgDetails } = useContext(context);
  // //states

  // const [open, setOpen] = useState(false);
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  // const [alertIcon, setAlertIcon] = useState("error");
  // const [userDetails, setuserDetails] = useState(null);

  // //get org details

  // const getOrgDetails = (id) => {
  //   setOpen(true);

  //   simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${id}`)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         setOrgDetails(res?.data?.organisationDetails);
  //         setOpen(false);

  //         console.log({
  //           isBank: res?.data?.organisationDetails?.isBank,
  //         });
  //         setuserDetails(res?.data?.organisationDetails);
  //         if (res?.data?.organisationDetails?.isBank === false) {
  //           // console.log("IF");

  //           navigate(APP_URLS?.org + APP_URLS?.payment);
  //         } else {
  //           // console.log("ELSE");
  //           // navigate(APP_URLS?.org);
  //           navigate(APP_URLS?.org + APP_URLS?.payment);
  //         }
  //       } else {
  //         setOpen(false);
  //         setAlertOpen(true);
  //         setAlertMsg(res?.data?.message);
  //         setAlertIcon("warning");
  //       }
  //     })
  //     .catch((err) => {
  //       const { response } = err;

  //       setOpen(false);
  //       setAlertOpen(true);
  //       setAlertMsg(response?.data?.message);
  //       setAlertIcon("error");
  //     });
  // };

  // //ger decode token

  // const getDecodedToken = useCallback(() => {
  //   const token = localStorage.getItem(StorageKeys?.token);
  //   const decode = decodeToken(token);
  //   getOrgDetails(decode?.sub);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem(StorageKeys?.token);
  //   if (token) {
  //     getDecodedToken();
  //   }
  // }, [getDecodedToken]);

  // ======== whenever bank account is not added page not changed =====
  // useEffect(() => {
  //   if (!userDetails?.isBank) {
  //     navigate(APP_URLS?.org + APP_URLS?.payment);
  //   }
  // }, [userDetails, pathname]);
  // ========= whenever bank account is not added page not changed ====

  return token && org_info.role === "organization" ? (
    <Component {...rest} />
  ) : (
    <Navigate to={APP_URLS?.root} />
  );
};

//auth hook

export const useAuth = () => {
  const token = localStorage.getItem(StorageKeys?.token);

  const { pathname } = useLocation();

  if (token) {
    //checking token is expired or not
    const expired = isExpired(token);

    if (!expired) {
      //decoding token is expired or not
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        return true;
        // if (verifyRole(decodedToken?.role, pathname)) {
        //   return true;
        // } else {
        //   removeToken();
        //   return false;
        // }
      }
      removeToken();
      return false;
    }
    removeToken();
    return false;
  }
  return false;
};

export const removeToken = () => {
  return localStorage.removeItem(StorageKeys?.token);
};

export default OrgPrivateRoute;
