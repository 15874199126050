import React from "react";

//package import

import { Modal } from "react-bootstrap";
import { Link } from "@mui/material";
import QRCode from "qrcode.react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

//user import
import {
  ORGANIZATION_APP_URLS as APP_URLS,
  USER_DOMAIN,
} from "app/Services/organization/Routes/APP";

//component

const CauseCreationModal = ({ show, handleClose, causeId }) => {
  //render
  const Copylink = (c) => {
    const cp = navigator.clipboard.writeText(c);
    return cp;
  };
  const baseURL = window.location.origin;

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop={"static"}>
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100 py-3">
          <p> </p>
          <p className="create-post">Thanks! Your cause is created!</p>
          <Link to="#/" onClick={handleClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="link-bg">
                  <p>{`${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`}</p>
                  <a
                    href="#/"
                    onClick={() =>
                      Copylink(
                        `${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`
                      )
                    }
                  >
                    Copy Link
                  </a>
                </div>
                <div className="text-center">
                  <div className="share-social my-4">
                    <p>Share On Social Media</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <FacebookShareButton
                        url={`${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`}
                      >
                        <img src="/images/fb.svg" alt="facebook" />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={`${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`}
                      >
                        <img
                          src="/images/whatsapp.png"
                          alt="whatsapp"
                          width={"60px"}
                        />
                      </WhatsappShareButton>
                      <TwitterShareButton
                        url={`${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`}
                      >
                        <img src="/images/twitter.svg" alt="twitter" />
                      </TwitterShareButton>
                      {/* <img src="/images/pinterest.svg" alt="pinterest" />
                      <img src="/images/linkedin.svg" alt="linkedin" />
                      <img src="/images/insta.svg" alt="insta" /> */}
                    </div>
                  </div>

                  <div className="share-social mt-5">
                    <p>Scan QR Code</p>
                  </div>
                  <div className="qr-code">
                    <div>
                      <QRCode
                        value={`${USER_DOMAIN}${APP_URLS?.orgCause}?cause=${causeId}`}
                        style={{ height: "200px", width: "200px" }}
                      />
                      <div className="row justify-content-center">
                        <div className="col-lg-5">
                          <div className="scan-link">
                            {/* <a href="#/">Scan QR Code</a> */}
                            {/* <a href="#/">Download QR Code</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="share-social mt-5">
                    <p>PRINT</p>
                    <img
                      src="/images/printer.svg"
                      alt="print"
                      className="px-0"
                    />
                  </div> */}

                  <Link
                    href={`${baseURL}${APP_URLS?.orgCause}?cause=${causeId}`}
                    target={"_blank"}
                  >
                    <div className="btn btn-pink my-5">VIEW CAUSE</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CauseCreationModal;
