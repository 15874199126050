import * as Yup from "yup";

import { variables } from "../variables";

const { required, IsEmail } = variables;

export const nameSchema = Yup.object({
  name: Yup.string().required(required),
});

export const typeSchema = Yup.object({
  organisation_type: Yup.string().required(required),
});

export const detailSchema = Yup.object({
  name: Yup.string().required(required),
  abn_number: Yup.string().required(required),
  category: Yup.string().required(required),
  organisation_type: Yup.string().required(required),
  company_name: Yup.string().required(required),
});

export const mainSchema = Yup.object({
  name: Yup.string().required(required),
  abn_number: Yup.string().required(required),
  category: Yup.string().required(required),
  organisation_type: Yup.string().required(required),
  company_name: Yup.string().required(required),
  sub_category: Yup.string().required(required),
  email: Yup.string().required(required).email(IsEmail),
  // organisation_image: Yup.string().required(required),
  organisation_image: Yup.mixed()
    .required()
    .test(
      "fileSize",
      "File size should be less than 4MB",
      (value) => !value || value.size <= 4194304
    )
    .test(
      "fileType",
      "Only PNG and JPG files are allowed",
      (value) => !value || ["image/png", "image/jpeg"].includes(value.type)
    ),
  description: Yup.string().required(required),
  cause: Yup.string().required(required),
  password: Yup.string(),
});
