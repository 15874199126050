import { useState, useEffect } from "react";

export const useDarkMode = () => {
  const [themeMode, setTheme] = useState("light");

  const setMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    themeMode === "light" ? setMode("dark") : setMode("light");
  };

  useEffect(() => {
    const localTheme = localStorage.getItem("themeMode");
    localTheme ? setTheme(localTheme) : setMode("light");
  },[]);

  return [themeMode, themeToggler];
};
