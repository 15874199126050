import React from "react";

//user imports

// import { Layout } from "../components";
import { Layout } from "app/components/user";
import {
  Hero,
  Vision,
  WeBelieve,
  Mission,
  Faqs,
} from "app/components/user/AboutUs";

//component

const AboutUs = () => {
  //render

  return (
    <Layout>
      <Hero />
      <Vision />
      <WeBelieve />
      <Mission />
      <Faqs />
    </Layout>
  );
};

export default AboutUs;
