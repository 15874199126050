import React, { Fragment } from 'react'
import { ProgressBar } from 'react-bootstrap'

const DataProgress = ({ rows = 1, cols = 1 }) => {
  return (
    <Fragment>
      {[...Array(rows)].map(() => (
        <tr>
          {[...Array(cols)].map(() => (
            <td>
              <ProgressBar animated now={100} />
            </td>
          ))}

        </tr>
      ))}
    </Fragment>
  )
}

export default DataProgress