import React from "react";
import NoData from "app/components/admin/NoData";
import DataProgress from "app/components/admin/Progress";
import {
  formatCategory,
  formatDate,
  formatDateTimeOnly,
} from "app/common/admin/Functions";

const DonationTable = ({ data = [], handleShow, open = false }) => {
  return (
    <div className="table-responsive donation-table">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Organisation</th>
            <th scope="col">Category</th>
            <th scope="col">Cause Name</th>
            <th scope="col">Donation Amount</th>
            <th scope="col">Fees</th>
            <th scope="col">Total</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {open ? (
            <DataProgress rows={8} cols={6} />
          ) : data?.length <= 0 ? (
            <tr>
              <td colSpan={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 96,
                    paddingTop: 40,
                  }}
                >
                  <NoData />
                </div>
              </td>
            </tr>
          ) : (
            data?.map((row) => {
              const { id, amount, organisation, createdAt, pp_fees, cause } =
                row || {};
              const { category, name, organisation_image, company_name } =
                organisation || {};
              const { title } = cause || {};
              return (
                <tr key={id}>
                  <td>
                    {formatDate(createdAt)}
                    <br></br>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#9094a2",
                      }}
                    >
                      {formatDateTimeOnly(createdAt)}
                    </span>
                  </td>
                  <td>
                    <div className="organisation-icon-name">
                      {/* <div className='organisation-icon'>
                                                {organisation_image?.length >
                                                0 ? (
                                                    <img
                                                        src={
                                                            organisation_image[0]
                                                        }
                                                        alt='organisation-logo'
                                                    />
                                                ) : (
                                                    <div>
                                                        {name
                                                            ?.splice(0, 1)
                                                            ?.toUpperCase()}
                                                    </div>
                                                )}
                                            </div> */}
                      <p>{name} </p>
                    </div>
                  </td>
                  <td>{formatCategory(category)}</td>
                  <td>{title || "N/A"}</td>
                  <td>
                    <b>{amount?.toFixed(2)}</b>
                  </td>
                  <td>
                    <b>${pp_fees?.toFixed(2)}</b>
                  </td>
                  <td>
                    <b>
                      $
                      {pp_fees
                        ? (amount + pp_fees)?.toFixed(2)
                        : amount?.toFixed(2)}
                    </b>
                  </td>
                  <td>
                    <a
                      href="#/"
                      className="manage"
                      onClick={() => handleShow(row)}
                    >
                      View
                    </a>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DonationTable;
