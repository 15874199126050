import React from "react";

const PrintIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5577 5.0769H4.60096C3.38071 5.0769 2.21044 5.56165 1.34759 6.42449C0.484743 7.28734 0 8.45761 0 9.67787V22.2115C0 23.3897 0.468028 24.5196 1.30112 25.3527C2.13422 26.1858 3.26413 26.6538 4.44231 26.6538H5.07692V29.8015C5.07692 30.6498 5.4139 31.4633 6.01373 32.0632C6.61356 32.663 7.4271 33 8.27538 33H24.7246C25.5729 33 26.3864 32.663 26.9863 32.0632C27.5861 31.4633 27.9231 30.6498 27.9231 29.8015V26.6538H28.5577C29.7359 26.6538 30.8658 26.1858 31.6989 25.3527C32.532 24.5196 33 23.3897 33 22.2115V9.51921C33 8.34104 32.532 7.21112 31.6989 6.37803C30.8658 5.54493 29.7359 5.0769 28.5577 5.0769ZM25.3846 29.8015C25.384 29.9764 25.3142 30.1439 25.1906 30.2675C25.067 30.3912 24.8995 30.4609 24.7246 30.4615H8.27538C8.10054 30.4609 7.93302 30.3912 7.80939 30.2675C7.68575 30.1439 7.61601 29.9764 7.61538 29.8015V17.16C7.61601 16.9851 7.68575 16.8176 7.80939 16.694C7.93302 16.5703 8.10054 16.5006 8.27538 16.5H24.7246C24.8995 16.5006 25.067 16.5703 25.1906 16.694C25.3142 16.8176 25.384 16.9851 25.3846 17.16V29.8015ZM27.4471 12.6859C27.0588 12.7172 26.6703 12.6285 26.3339 12.4319C25.9976 12.2354 25.7297 11.9404 25.5663 11.5867C25.4029 11.2331 25.3519 10.8378 25.4202 10.4543C25.4886 10.0708 25.6729 9.71745 25.9484 9.44199C26.2238 9.16653 26.5772 8.9822 26.9607 8.91388C27.3442 8.84556 27.7394 8.89653 28.0931 9.05992C28.4467 9.2233 28.7417 9.49124 28.9383 9.82757C29.1349 10.1639 29.2235 10.5524 29.1923 10.9408C29.1561 11.3915 28.9606 11.8147 28.6408 12.1344C28.321 12.4542 27.8979 12.6497 27.4471 12.6859Z"
        fill="black"
      />
      <path
        d="M23.4809 0H9.51935C8.45161 0.00158994 7.42005 0.387085 6.61295 1.08612C5.80586 1.78516 5.27705 2.75112 5.12305 3.80769H27.8772C27.7232 2.75112 27.1944 1.78516 26.3873 1.08612C25.5802 0.387085 24.5486 0.00158994 23.4809 0Z"
        fill="black"
      />
    </svg>
  );
};

export default PrintIcon;
