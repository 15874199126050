export const userRoutes = {
  root: "/",
  user: "/user",
  aboutus: "/about-us",
  contactUs: "/contact-us",
  feed: "/feed",
  discover: "/discover",
  nfps: "/nfps",
  changeMakers: "/change-makers",
  features: "/features",
  roundUpWork: "/round-up-work",
  authOrganisation: "/auth-organisation",
  companies: "/companies",
  onboardingQ1: "/onboarding-questions",
  getStarted: "/get-started",
  allDone: "/all-done",
  donut: "/donut",
  signUp: "/user/sign-up",
  profile: "/profile",
  profileEdit: "/profile-edit",
  orgProfile: "/org-profile",
  orgProfileWithId: "/org-profile/:id",
  orgCause: "/org-cause",
  orgCauseWithId: "/org-cause/:id",
  cause: "/my-cause",
  donationHistory: "/donation-history",
  basiqTransactionHistory: "/basiq-transaction-history",
  paymentInfo: "/payment-info",
  cardsInfo: "/cards",
  paymentRound: "/payment-round",
  currentround: "/current-round",
  logIn: "/user/login",
  subscriptions: "/subscriptions",
};
