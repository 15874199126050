import React, { useState, useContext } from "react";

//package import

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
  Box,
  Backdrop,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
// import { context } from "../../context";
import { useNavigate } from "react-router-dom";
import { userRoutes } from "app/Services/user/routes/APP";
import { API_URLS } from "app/Services/user/routes/API";
import { headersPost } from "app/Services/user/API";
import { context } from "app/context";

//component

const CardLinkModal = ({
  handleClose,
  open,
  handleCardOpen,
  handleBankOpen,
}) => {
  const [loader, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const navigate = useNavigate();
  const { user, clientSecretState } = useContext(context);
  const { clientSecret, setClientSecret } = clientSecretState || {};

  const handleLinkBank = () => {
    const { _id } = user || {};
    setOpen(true);
    const data = {
      addedByUser: _id,
    };

    headersPost(API_URLS?.stripeBankClientSecret)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          // handleClose();
          console.log({ res });
          // setAlertOpen(true)
          // setAlertMsg(res?.data?.message)
          // setAlertIcon('success')
          setClientSecret(res?.data?.clientSecret);
          setTimeout(() => {
            handleBankOpen();
            handleClose();
          }, 1000);
          // navigate(userRoutes?.user + userRoutes?.discover);
          // setAddress('')
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        if (response.status === 401) {
          localStorage.clear();
          navigate(userRoutes?.root);
        }
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{ width: "40%", margin: "auto" }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontWeight={"bold"} align="center">
          Link Bank Account OR a Card
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box mt={2}>
            <Button
              variant="contained"
              onClick={() => {
                handleCardOpen();
                handleClose();
              }}
            >
              Link a Card
            </Button>
          </Box>
          <Box mt={2}>
            <Button variant="contained" onClick={handleLinkBank}>
              Link a Bank
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default CardLinkModal;
