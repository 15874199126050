import React from "react";

const DropArrow = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 9L0.0717952 0.75L13.9282 0.750001L7 9Z" fill="white" />
    </svg>
  );
};

export default DropArrow;
