import React, { useState, useEffect, useCallback, useContext } from "react";

//package imports

import {
  Backdrop,
  CircularProgress,
  Snackbar,
  FormControl,
  FormHelperText,
  Alert,
} from "@mui/material";
import { Form } from "react-bootstrap";
import { Formik } from "formik";

//user import

import { API_URLS } from "app/Services/organization/Routes/API";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { ToFormData } from "app/utils/organization/FormData";
import { headersPost, headersUpdate } from "app/Services/organization/API";
import {
  addValidation,
  editValidation,
} from "app/Services/organization/validations/CreateCause";
import { context } from "app/context";

//constants

const initialValues = {
  description: "",
  name: "",
  cause: "",
};

//component

export default function ProfileInformation() {
  //hooks
  const { orgDetails, setOrgDetails } = useContext(context);

  console.log({ orgDetails, setOrgDetails });
  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [formValues, setFormValues] = useState(initialValues);

  //functions

  //form submit handler

  const formInitialHandler = useCallback(() => {
    orgDetails &&
      setFormValues((prev) => ({
        ...prev,
        organisation: orgDetails?.id,
        name: orgDetails?.name,
        category: orgDetails?.category,
        email: orgDetails?.email,
        sub_category: orgDetails?.sub_category,
        cause: orgDetails?.cause,
        description: orgDetails?.description,
      }));

    setOpen(false);
  }, [orgDetails]);

  useEffect(() => {
    orgDetails && formInitialHandler();

    return () => {
      setFormValues(initialValues);
    };
  }, [orgDetails, formInitialHandler]);

  const submitHandler = (values, { resetForm }) => {
    const data = {
      description: values.description,
      name: values.name,
      cause: values.cause,
    };
    setOpen(true);
    headersUpdate(`${API_URLS?.updateOrg}/${orgDetails?.id}`, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && !res?.data?.error) {
          setAlertOpen(true);
          setAlertMsg("Your Profile Information is changed successfully.");
          setOrgDetails(res?.data);
          setAlertIcon("success");
          resetForm();
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        if (response?.status === 401) {
          localStorage.clear();
          // navigate(APP_URLS?.root);
        } else {
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        }
      });
  };

  // useEffect(() => {
  //   isEdit && id && getCause();
  // }, [id, isEdit, getCause]);

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <div className="container my-5 justify-content-start">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div style={{ paddingLeft: "0px" }}>
            <Formik
              initialValues={formValues}
              onSubmit={submitHandler}
              enableReinitialize
              validationSchema={editValidation}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row justify-content-left ">
                    <div className="col-lg-11">
                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Organisation NAME</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                      </Form.Group>
                      {!!errors.name && (
                        <FormControl
                          error={touched.name && Boolean(errors.name)}
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.name && errors.name}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom02"
                      >
                        <Form.Label> EMAIL/BUSINESS EMAIL</Form.Label>
                        <Form.Control
                          className="field"
                          type="email"
                          placeholder="Enter organisation email"
                          name="email"
                          value={orgDetails?.email}
                          // onChange={handleChange}
                          // isInvalid={!!errors.email}
                          disabled
                        />
                      </Form.Group>
                      {/* {!!errors.email && (
                        <FormControl
                          error={touched.email && Boolean(errors.email)}
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.email && errors.email}
                          </FormHelperText>
                        </FormControl>
                      )} */}

                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom02"
                      >
                        <Form.Label>CHARITY CAUSE</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation charity cause"
                          name="cause"
                          value={values.cause}
                          onChange={handleChange}
                          isInvalid={!!errors.cause}
                        />
                      </Form.Group>
                      {!!errors.cause && (
                        <FormControl
                          error={touched.cause && Boolean(errors.cause)}
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.cause && errors.cause}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <Form.Group
                        className="fields mb-3"
                        controlId="validationCustom03"
                      >
                        <Form.Label>DESCRIPTION</Form.Label>
                        <Form.Control
                          className="field"
                          type="text"
                          placeholder="Enter organisation DESCRIPTION"
                          name="description"
                          value={values.description}
                          as={"textarea"}
                          rows={5}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                        />
                      </Form.Group>
                      {!!errors.description && (
                        <FormControl
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          fullWidth
                          sx={{
                            margin: 0,
                            marginBottom: 2,
                          }}
                        >
                          <FormHelperText
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {touched.description && errors.description}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label>CATEGORY</Form.Label>
                            <Form.Control
                              className="field"
                              type="text"
                              placeholder="Enter a category"
                              name="category"
                              as={"select"}
                              // value={values.category}
                              // onChange={handleChange}
                              disabled
                              // isInvalid={!!errors.category}
                            >
                              <option key={"empty"} value={""}>
                                {orgDetails?.category}
                              </option>
                            </Form.Control>
                          </Form.Group>
                          {/* {!!errors.category && (
                            <FormControl
                              error={
                                touched.category && Boolean(errors.category)
                              }
                              fullWidth
                              sx={{
                                margin: 0,
                                marginBottom: 2,
                              }}
                            >
                              <FormHelperText
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {touched.category && errors.category}
                              </FormHelperText>
                            </FormControl>
                          )} */}
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="fields mb-3"
                            controlId="validationCustom02"
                          >
                            <Form.Label className={"text-uppercase"}>
                              {" "}
                              SUB Category
                            </Form.Label>

                            <Form.Control
                              className="field "
                              type="text"
                              name="sub_category"
                              as={"select"}
                              // value={values.sub_category}
                              // onChange={handleChange}
                              // isInvalid={!!errors.sub_category}
                              disabled
                            >
                              <option key={"empty"} value={""}>
                                {orgDetails?.sub_category}
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-pink py-2 px-5 my-5"
                      type={"submit"}
                    >
                      SUBMIT
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
