import React from "react";

const FacebookIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "46"}
      height={height ? height : "46"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 23.0838C8 30.5413 13.4163 36.7425 20.5 38V27.1663H16.75V23H20.5V19.6663C20.5 15.9163 22.9163 13.8337 26.3337 13.8337C27.4163 13.8337 28.5837 14 29.6663 14.1663V18H27.75C25.9163 18 25.5 18.9163 25.5 20.0837V23H29.5L28.8337 27.1663H25.5V38C32.5837 36.7425 38 30.5425 38 23.0838C38 14.7875 31.25 8 23 8C14.75 8 8 14.7875 8 23.0838Z"
        fill={color ? color : "#230D4D"}
      />
    </svg>
  );
};

export default FacebookIcon;
