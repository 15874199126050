import React, { useContext, useState } from "react";

//package import

import PropTypes from "prop-types";
import {
  IconButton,
  useTheme,
  Box,
  Hidden,
  MenuItem,
  Menu,
  Link,
  Divider,
  Avatar,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu as MenuIcon, Star as StarIcon } from "@mui/icons-material";

//user import

import Logo from "app/svg/user/Logo";
import LogoutIcon from "app/svg/user/Logout";
import SettingsIcon from "app/svg/user/SettingsIcon";
import { userRoutes } from "app/Services/user/routes/APP";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { context } from "app/context";

//component

const Topbar = ({ onSidebarOpen }) => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useContext(context);
  const { profile_pic } = user || {};

  //states

  const [anchorEl, setAnchorEl] = useState(null);

  //constants

  const openMenu = Boolean(anchorEl);

  //open profile menu

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //close profile menu

  const menuCloseHandler = () => {
    setAnchorEl(null);
  };

  //

  const logoutHandler = () => {
    localStorage.clear();
    navigate(userRoutes?.root);
  };

  //render

  return (
    <Box
      display={"flex"}
      justifyContent={{
        md: "flex-start",
        sm: "space-between",
        xs: "space-between",
      }}
      alignItems={"center"}
      width={"100%"}
      height={100}
    >
      <Box
        marginX={{ sm: 2, xs: 2 }}
        display={{ xs: "inherit", sm: "inherit", md: "none" }}
        color={theme.palette.primary.light}
      >
        <IconButton onClick={onSidebarOpen} color="inherit">
          <MenuIcon color={"inherit"} />
        </IconButton>
      </Box>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={theme.zIndex.appBar + 1}
        sx={{
          height: 200,
          padding: 0,
          margin: 0,
          width: 300,
          marginLeft: -3,
          backgroundColor: theme.palette.secondary.main,
          borderEndEndRadius: "100%",
          borderBottomLeftRadius: "100%",
          // borderRadius: "100%",
        }}
      >
        <Box
          component="a"
          display={"block"}
          underline="none"
          href={userRoutes?.root}
          zIndex={theme.zIndex.appBar + 1}
          height={"100%"}
          marginTop={5}
          width={150}
        >
          <Logo
            width={"100%"}
            height={"100%"}
            textColor={theme.palette.primary.light}
          />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        display="flex"
        alignItems={"center"}
        height={"100%"}
        justifyContent={"center"}
        sx={{
          "& a": {
            color: "#000 !important",
          },
          "& a.actove_menu": {
            color: "#F9CC69 !important",
            "&:hover": {
              color: "#F9CC69 !important",
            },
          },
        }}
      >
        <Hidden mdDown>
          <Box
            sx={{
              height: "100%",
              backgroundColor:
                pathname === userRoutes?.user + userRoutes?.feed
                  ? theme.palette.primary.light
                  : "inherit",
              display: "flex",
              alignItems: "center",
              color:
                pathname === userRoutes?.user + userRoutes?.feed
                  ? theme.palette.secondary.main
                  : theme.palette.primary.light,
            }}
          >
            <Box
              component={Link}
              marginX={2}
              sx={{
                color: "inherit",
                textDecoration: "none",
                fontWeight: "bold",
              }}
              href={userRoutes?.user + userRoutes?.feed}
              className={`${
                pathname === userRoutes?.user + userRoutes?.feed
                  ? "actove_menu"
                  : ""
              }`}
            >
              FEED
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              backgroundColor:
                pathname === userRoutes?.user + userRoutes?.discover
                  ? theme.palette.primary.light
                  : "inherit",
              display: "flex",
              alignItems: "center",
              color:
                pathname === userRoutes?.user + userRoutes?.discover
                  ? theme.palette.secondary.main
                  : theme.palette.primary.light,
            }}
          >
            <Box
              component={Link}
              marginX={2}
              sx={{
                color: "inherit",
                textDecoration: "none",
                fontWeight: "bold",
              }}
              href={userRoutes?.user + userRoutes?.discover}
              className={`${
                pathname === userRoutes?.user + userRoutes?.discover
                  ? "actove_menu"
                  : ""
              }`}
            >
              DISCOVER
            </Box>
          </Box>
          {/* <Box
                        sx={{
                            height: '100%',
                            backgroundColor:
                                pathname ===
                                userRoutes?.user + userRoutes?.currentround
                                    ? theme.palette.primary.light
                                    : 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            color:
                                pathname ===
                                userRoutes?.user + userRoutes?.currentround
                                    ? theme.palette.secondary.main
                                    : theme.palette.primary.light,
                        }}
                    >
                        <Box
                            component={Link}
                            marginX={2}
                            sx={{
                                color: 'inherit',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                            }}
                            href={userRoutes?.user + userRoutes?.currentround}
                        >
                            ROUND-UPS
                        </Box>
                    </Box> */}
          {/* <Box
                        sx={{
                            height: '100%',
                            backgroundColor:
                                pathname ===
                                userRoutes?.user + userRoutes?.donationHistory
                                    ? theme.palette.primary.light
                                    : 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            color:
                                pathname ===
                                userRoutes?.user + userRoutes?.donationHistory
                                    ? theme.palette.secondary.main
                                    : theme.palette.primary.light,
                        }}
                    >
                        <Box
                            component={Link}
                            marginX={2}
                            sx={{
                                color: 'inherit',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                            }}
                            href={
                                userRoutes?.user + userRoutes?.donationHistory
                            }
                        >
                            DONATION HISTORY
                        </Box>
                    </Box> */}
        </Hidden>
        {isLoggedIn() && (
          <Box margin={2} color={theme.palette.primary.light}>
            <IconButton onClick={handleClick} color="inherit">
              {profile_pic ? (
                <Avatar src={profile_pic} alt={"profile"}></Avatar>
              ) : (
                <Avatar></Avatar>
              )}
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={menuCloseHandler}
            >
              <MenuItem onClick={menuCloseHandler}>
                <Box
                  padding={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"}>My Account</Typography>
                  <Box marginLeft={3}>
                    {profile_pic ? (
                      <Avatar src={profile_pic} alt={"profile"}></Avatar>
                    ) : (
                      <Avatar />
                    )}
                  </Box>
                </Box>
              </MenuItem>
              <Divider />

              <Box padding={1} display={"flex"}>
                <Box width={20}>
                  <SettingsIcon />
                </Box>
                <Typography marginLeft={2} fontWeight={"bold"}>
                  Settings
                </Typography>
              </Box>
              <MenuItem
                onClick={() => {
                  navigate(userRoutes?.user + userRoutes?.profile);
                  menuCloseHandler();
                }}
                sx={{
                  pl: 5.5,
                }}
                color={theme.palette.grey.A100}
              >
                My Profile
              </MenuItem>
              <Box padding={1} display={"flex"}>
                <Box width={20}>
                  <img src="/images/Donation.png" alt="donation" />
                </Box>
                <Typography marginLeft={2} fontWeight={"bold"}>
                  My Donations
                </Typography>
              </Box>
              <MenuItem
                onClick={() => {
                  navigate(userRoutes?.user + userRoutes?.currentround);
                  menuCloseHandler();
                }}
                sx={{
                  pl: 5.5,
                }}
                color={theme.palette.grey.A100}
              >
                My Roundups
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(userRoutes?.user + userRoutes?.subscriptions);
                  menuCloseHandler();
                }}
                sx={{
                  pl: 5.5,
                }}
                color={theme.palette.grey.A100}
              >
                Reccurring donation subscriptions
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navigate(userRoutes?.user + userRoutes?.donationHistory);
                  menuCloseHandler();
                }}
                sx={{
                  pl: 5.5,
                }}
                color={theme.palette.grey.A100}
              >
                History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  menuCloseHandler();
                  navigate(userRoutes?.user + userRoutes?.paymentInfo);
                }}
                sx={{
                  pl: 5.5,
                }}
                color={theme.palette.grey.A100}
              >
                Payments info
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href = userRoutes?.user + userRoutes?.cause;
                  // navigate(
                  //     userRoutes?.user + userRoutes?.cause
                  // )
                  menuCloseHandler();
                }}
              >
                <Box padding={1} display={"flex"}>
                  <Box width={20} color={theme.palette.secondary.main}>
                    <StarIcon color="inherit" />
                  </Box>
                  <Typography marginLeft={2} fontWeight={"bold"}>
                    My favourite causes
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={logoutHandler} color={theme.palette.grey[100]}>
                <Box padding={1} display={"flex"}>
                  <Box width={20} color={theme.palette.secondary.main}>
                    <LogoutIcon color="inherit" />
                  </Box>
                  <Typography marginLeft={2} fontWeight={"bold"}>
                    Logout
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        )}
        {/* {isLoggedIn() && (
                    <Box marginX={2} color={theme.palette.primary.light}>
                        <IconButton onClick={handleClick} color='inherit'>
                            {profile_pic ? (
                                <Avatar
                                    src={profile_pic}
                                    alt={'profile'}
                                ></Avatar>
                            ) : (
                                <Avatar></Avatar>
                            )}
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={menuCloseHandler}
                        >
                            <MenuItem onClick={menuCloseHandler}>
                                <Box
                                    padding={1}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Typography fontWeight={'bold'}>
                                        My Account
                                    </Typography>
                                    <Box marginLeft={3}>
                                        {profile_pic ? (
                                            <Avatar
                                                src={profile_pic}
                                                alt={'profile'}
                                            ></Avatar>
                                        ) : (
                                            <Avatar />
                                        )}
                                    </Box>
                                </Box>
                            </MenuItem>
                            <Divider />

                            <Box padding={1} display={'flex'}>
                                <Box width={20}>
                                    <SettingsIcon />
                                </Box>
                                <Typography marginLeft={2} fontWeight={'bold'}>
                                    Settings
                                </Typography>
                            </Box>
                            <MenuItem
                                onClick={() => {
                                    navigate(
                                        userRoutes?.user + userRoutes?.profile
                                    )
                                    menuCloseHandler()
                                }}
                                sx={{
                                    pl: 5.5,
                                }}
                                color={theme.palette.grey.A100}
                            >
                                My Profile
                            </MenuItem>
                            <Box padding={1} display={'flex'}>
                                <Box width={20}>
                                    <img
                                        src='/images/Donation.png'
                                        alt='donation'
                                    />
                                </Box>
                                <Typography marginLeft={2} fontWeight={'bold'}>
                                    My Donations
                                </Typography>
                            </Box>
                            <MenuItem
                                onClick={() => {
                                    navigate(
                                        userRoutes?.user +
                                            userRoutes?.donationHistory
                                    )
                                    menuCloseHandler()
                                }}
                                sx={{
                                    pl: 5.5,
                                }}
                                color={theme.palette.grey.A100}
                            >
                                History
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    menuCloseHandler()
                                    navigate(
                                        userRoutes?.user +
                                            userRoutes?.paymentInfo
                                    )
                                }}
                                sx={{
                                    pl: 5.5,
                                }}
                                color={theme.palette.grey.A100}
                            >
                                Payments info
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    navigate(
                                        userRoutes?.user + userRoutes?.cause
                                    )
                                    menuCloseHandler()
                                }}
                            >
                                <Box padding={1} display={'flex'}>
                                    <Box
                                        width={20}
                                        color={theme.palette.secondary.main}
                                    >
                                        <StarIcon color='inherit' />
                                    </Box>
                                    <Typography
                                        marginLeft={2}
                                        fontWeight={'bold'}
                                    >
                                        My Impact
                                    </Typography>
                                </Box>
                            </MenuItem>
                            <MenuItem
                                onClick={logoutHandler}
                                color={theme.palette.grey[100]}
                            >
                                <Box padding={1} display={'flex'}>
                                    <Box
                                        width={20}
                                        color={theme.palette.secondary.main}
                                    >
                                        <LogoutIcon color='inherit' />
                                    </Box>
                                    <Typography
                                        marginLeft={2}
                                        fontWeight={'bold'}
                                    >
                                        Logout
                                    </Typography>
                                </Box>
                            </MenuItem>
                        </Menu>
                    </Box>
                )} */}
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Topbar;
