import React from "react";

//user import

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

//user import

import AddOrganisation from "app/components/admin/AddOrganisation/AddOrganiztion";
import OrganisationDetail from "app/components/admin/AddOrganisation/OrganisationDetail";
import OrganisationType from "app/components/admin/AddOrganisation/OrganisationType";
import OrganisationName from "app/components/admin/AddOrganisation/OrganisationName";

//component

const OrgModal = ({
  show,
  handleClose,
  activeStep,
  handleBack,
  handleNext,
  setFormValues,
  formValues,
  createOrg,
  open,
  handleEditOrg,
  editOrg,
}) => {
  //get active step

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddOrganisation
            setFormValues={setFormValues}
            formValues={formValues}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <OrganisationName
            setFormValues={setFormValues}
            formValues={formValues}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <OrganisationType
            setFormValues={setFormValues}
            formValues={formValues}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <OrganisationDetail
            setFormValues={setFormValues}
            formValues={formValues}
            handleNext={handleNext}
            createOrg={createOrg}
            editOrg={editOrg}
            handleEditOrg={handleEditOrg}
            open={open}
          />
        );
      default:
        return "Unknown Step";
    }
  }

  //render

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop={"static"}>
      <Modal.Header>
        <Link
          to="#/"
          className={activeStep === 0 ? "d-none" : "d-block"}
          onClick={handleBack}
        >
          <FaAngleLeft />
        </Link>
        <Link to="#/" onClick={handleClose}>
          <img src="/images/close.svg" alt="close" style={{ height: "15px" }} />
        </Link>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md h-300 overflow-y-auto">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="add-head py-5">
                  <p>
                    {activeStep === 1
                      ? "Add New Organisation"
                      : "Add Organisation"}
                  </p>
                </div>
                <div>
                  {activeStep === 4 ? (
                    <h1>Thank You</h1>
                  ) : (
                    <>
                      <div>{getStepContent(activeStep)}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrgModal;
