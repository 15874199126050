import React from "react";

//user imports

import BasiqTransactionHistoryPage from "app/components/user/BasiqTransactionHistory";

//component

const BasiqTransactionHistory = () => <BasiqTransactionHistoryPage />;

export default BasiqTransactionHistory;
