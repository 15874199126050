import React from "react";

//package import

import { Route, Routes } from "react-router-dom";

//user imports

import PrivateUserRoutesPaths from "./PrivateUserRoutesPaths";

import Layout from "app/components/user/UserLayout";

//component

const UserProtectedRoutes = () => {
  //render

  return (
    <Layout>
      <Routes>
        {PrivateUserRoutesPaths.map((item, index) => (
          <Route
            path={item?.path}
            key={`${index}-admin-routes-${item?.id}`}
            caseSensitive={item?.exact}
            element={item?.component}
          />
        ))}
      </Routes>
    </Layout>
  );
};

export default UserProtectedRoutes;
