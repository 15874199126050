import * as Yup from "yup";

import { variables } from "../variables";

const { required, IsEmail } = variables;

export const addvalidation = Yup.object({
  email: Yup.string().required(required).email(IsEmail),
  password: Yup.string().required(required),
});
