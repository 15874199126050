import React from "react";
// import Button from "react-bootstrap/Button";
import {
  Box,

  IconButton,
 
 
} from '@mui/material'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Download from "./Download"
export const Export = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName ) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // <Button variant="warning" onClick={(e) => exportToCSV(csvData, fileName)}>
    //   Export data
    // </Button>
   <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                <IconButton onClick={(e) => exportToCSV(csvData, fileName)}>
                                    <Download />
                                </IconButton>
    </Box> 
  );
};
