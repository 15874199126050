import React from "react";

const Like = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8448 7.85931L15.0179 7.63731C15.2628 7.3231 15.3966 6.94077 15.3966 6.54489C15.3966 5.93173 15.0411 5.324 14.4325 4.97742L14.8448 7.85931ZM14.8448 7.85931L14.9279 8.12824M14.8448 7.85931L14.9279 8.12824M14.9279 8.12824C14.9792 8.29428 15.0062 8.47086 15.0096 8.65328C15.0086 9.04761 14.8749 9.42829 14.6308 9.74136L14.4578 9.96335L14.5409 10.2323C14.5927 10.4 14.6188 10.5736 14.6188 10.7511C14.6188 11.147 14.4849 11.5294 14.24 11.8436L14.0669 12.0656L14.1501 12.3345C14.2019 12.5023 14.228 12.6758 14.228 12.8533C14.228 13.5734 13.7868 14.2335 13.0827 14.525L13.0827 14.525L13.0798 14.5262C12.9926 14.5629 12.8942 14.5829 12.7888 14.5829H4.41115M14.9279 8.12824L4.41115 14.5829M4.41115 14.5829V7.37315L6.01801 1.72565L6.01812 1.72528C6.21017 1.04843 6.85957 0.557754 7.60996 0.557757L7.61191 0.55775C7.81568 0.557014 8.01704 0.596875 8.20341 0.674445C8.38975 0.752003 8.5566 0.865371 8.69413 1.00669L8.69495 1.00753C8.97676 1.29585 9.1178 1.66704 9.09891 2.059C9.0989 2.05918 9.09889 2.05937 9.09888 2.05955L8.98565 4.31111L8.95716 4.87764H9.5244H14.0518L14.0523 4.87764C14.1869 4.87752 14.3182 4.91236 14.4325 4.97741L4.41115 14.5829ZM1.37146 14.5358V7.86449C1.37146 7.856 1.37413 7.84675 1.38453 7.83665C1.39523 7.82626 1.4123 7.81743 1.43619 7.81743H2.12397V14.5829H1.43619C1.4123 14.5829 1.39523 14.5741 1.38453 14.5637C1.37413 14.5536 1.37146 14.5443 1.37146 14.5358Z"
        stroke="white"
        strokeWidth="1.07886"
      />
    </svg>
  );
};

export default Like;
