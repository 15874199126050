import React from "react";

//user import

import CardsInfoPage from "app/components/user/CardsInfo";

const CardsInfo = () => {
  return <CardsInfoPage />;
};

export default CardsInfo;
