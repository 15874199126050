import moment from 'moment'

export const getArrayFromObject = obj => {
    return Object.values(obj).map(item => ({
        name: item
            ?.replace(/_/g, ' ')
            ?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        value: item,
    }))
}

export const formatDate = date => {
    return moment(date).format('DD/MM/YY')
}
export const formatDateTime = date => {
    return moment(date).format('hh:mm A')
}
 

export const formatDateTimeOnly = (date) => {
    return moment(date).format('hh:mm A')
   
  }




export const formatCategory = item =>
    item
        ?.replace(/_/g, ' ')
        ?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

export const isFile = input => {
    if ('File' in window && input instanceof File) return true
    else return false
}

export const isBlob = input => {
    if ('Blob' in window && input instanceof Blob) return true
    else return false
}
