import { ausCities, ausStates } from "./Aus-Cities";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const COLORS = [
  "#FFD240",
  "#66C9D2",
  "#FF9776",
  "#161F3D",
  "#F9CC69",
  "#7551F1",
  "#230D4D",
  "#24202D80",
];

export const AUS_CITIES = ausCities;
export const AUS_STATES = ausStates;
