import * as Yup from 'yup'

import {variables} from '../variables'

const {required, IsEmail, isNumberString, IsPhone, password_match} = variables

export const singUpValidation = Yup.object({
    email: Yup.string().required(required).email(IsEmail),
    password: Yup.string().required(required),
    con_password: Yup.string()
        .required(required)
        .oneOf([Yup.ref('password')], password_match),
    name: Yup.string().required(required),
    surname: Yup.string().optional(),
    phone_number: Yup.string()
        .required(required)
        .min(9, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
    // .matches(isNumberString, {message: IsPhone}),
})
