import React from "react";

//package import

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Toast } from "app/common/admin/alert";
import { API_Urls } from "app/Services/admin/Routes/API";
import { headersUpdate } from "app/Services/admin/API";

//component

const UserModal = ({ show, handleClose, user }) => {
  // console.log({ user });

  const blockUser = async () => {
    headersUpdate(`${API_Urls?.block_user}/${user?.id}`, { isblock: true })
      .then((res) => {
        // console.log("====================================");
        // console.log({ res });
        // console.log("====================================");
        Toast.success(res?.data);
      })
      .catch((err) => {
        console.log({ err });
        Toast.error("There was an error in blocking the user");
      });
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p> </p>
          <Link to="#/" onClick={handleClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Name:</p>
                  <p className="modal-manage-titled">{user?.name}</p>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Email:</p>
                  <p className="modal-manage-titled">{user?.email}</p>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Phone No:</p>
                  <p className="modal-manage-titled">{user?.phone_number}</p>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Recent RoundUp:</p>
                  <div className="modal-manage-titled">
                    <div className="d-flex align-items-center justify-content-around">
                      <div className="roundup">
                        <img src="/images/mouse.jpg" alt="charity-img" />
                      </div>
                      <p>Charity Name</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Date Of Join:</p>
                  <p className="modal-manage-titled">
                    {user?.dateOfJoin
                      ? format(new Date(user?.dateOfJoin), "dd, MMMM, yyyy")
                      : ""}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <p className="modal-manage-title">Current Status:</p>
                  <p className="modal-manage-titled">
                    <span
                      className={
                        user?.isblock === true
                          ? "badge badge-pill badge-danger px-4 py-2"
                          : "badge badge-pill badge-success px-3 py-2"
                      }
                    >
                      {user?.isblock === true ? "In-Active" : "Active"}
                    </span>
                  </p>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center mb-5">
                  <p className="modal-manage-title">Donations History:</p>
                  <p className="modal-manage-titled">
                    <span>
                      <a href="#/" className="text-view">
                        View
                      </a>
                    </span>
                  </p>
                </div> */}
                <div>
                  <div className="text-center">
                    <button className="btn btn-danger" onClick={blockUser}>
                      BLOCK USER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
