import React from "react";

const SupportIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 17.0625C15.9371 17.0625 19.9375 13.2439 19.9375 8.53125C19.9375 3.81855 15.9371 0 11 0C6.06289 0 2.0625 3.81855 2.0625 8.53125C2.0625 13.2439 6.06289 17.0625 11 17.0625ZM10.0461 3.99492V3.30586C10.0461 2.92852 10.3641 2.625 10.7594 2.625H11.2363C11.6316 2.625 11.9496 2.92852 11.9496 3.30586V4.00312C12.6156 4.03594 13.2602 4.25332 13.7973 4.63477C14.0379 4.80293 14.0637 5.13926 13.8488 5.33613L13.1484 5.97188C12.9852 6.12363 12.7402 6.12773 12.5469 6.01289C12.3148 5.87344 12.057 5.80371 11.782 5.80371H10.1105C9.72383 5.80371 9.41016 6.14004 9.41016 6.5543C9.41016 6.89062 9.625 7.19004 9.93008 7.27617L12.607 8.04316C13.7113 8.35898 14.4848 9.37207 14.4848 10.5082C14.4848 11.9027 13.3504 13.0307 11.9453 13.0676V13.7566C11.9453 14.134 11.6273 14.4375 11.232 14.4375H10.7551C10.3598 14.4375 10.0418 14.134 10.0418 13.7566V13.0594C9.37578 13.0266 8.73125 12.8092 8.19414 12.4277C7.95352 12.2596 7.92773 11.9232 8.14258 11.7264L8.84297 11.0906C9.00625 10.9389 9.25117 10.9348 9.44453 11.0496C9.67656 11.1891 9.93437 11.2588 10.2094 11.2588H11.8809C12.2676 11.2588 12.5812 10.9225 12.5812 10.5082C12.5812 10.1719 12.3664 9.87246 12.0613 9.78633L9.38437 9.01934C8.28008 8.70352 7.50664 7.69043 7.50664 6.5543C7.51094 5.15977 8.64102 4.03184 10.0461 3.99492ZM20.625 14.4375H19.2285C18.3863 15.5039 17.3121 16.3939 16.0918 17.0625H18.8332C19.0609 17.0625 19.2457 17.2102 19.2457 17.3906V18.0469C19.2457 18.2273 19.0609 18.375 18.8332 18.375H3.1625C2.93477 18.375 2.75 18.2273 2.75 18.0469V17.3906C2.75 17.2102 2.93477 17.0625 3.1625 17.0625H5.90391C4.68359 16.3939 3.61367 15.5039 2.76719 14.4375H1.375C0.614453 14.4375 0 15.024 0 15.75V19.6875C0 20.4135 0.614453 21 1.375 21H20.625C21.3855 21 22 20.4135 22 19.6875V15.75C22 15.024 21.3855 14.4375 20.625 14.4375Z"
        fill="white"
      />
    </svg>
  );
};

export default SupportIcon;
