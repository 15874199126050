import React, { useState, useEffect, useCallback } from "react";
// import ReactExport from "react-export-excel";
import { Alert } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
//user import
import "./donation.css";
import DonationTable from "./DonationTable";
import DonationModal from "./DonationModal";
// import { transactionTypes } from "../../common/enum";
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
import { Export } from "./Export";
// import Pagination from "../../Components/Pagination";
import { Box, IconButton } from "@mui/material";
import excelDataFormat from "app/utils/admin/excelDataFormat";

import Pagination from "rc-pagination";
import "./style.css";
import Download from "./Download";
import { transactionTypes } from "app/common/admin/enum";
import { headersGet } from "app/Services/admin/API";
import { API_Urls } from "app/Services/admin/Routes/API";

// const ExcelFile = ExcelFile;
// const ExcelSheet = ExcelSheet;
// const ExcelColumn = ExcelColumn;
export default function Donation() {
  //states

  const [page, setPage] = useState(1);
  const [api, setApi] = useState(true);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  console.log("🚀 ~ Donation ~ totalPages:", totalPages);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertIcon, setAlertIcon] = useState("error");
  const [tab, setTab] = useState(transactionTypes.roundUp);
  const [modalData, setModalData] = useState(null);
  const [fixedTransaction, setFixedTransaction] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [data, setData] = useState(null);
  const [excelData, setExcelData] = useState(null);
  //show modal

  const handleClose = () => {
    setModalData(null);
    setShow(false);
  };

  const handleShow = (mData) => {
    setModalData(mData);
    setShow(true);
  };

  //get transaction

  const getTransactions = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(
      `${API_Urls?.getTransactions}?page=${page}&limit=${10}&type=${tab}`
    )
      .then((res) => {
        setOpen(false);
        const { status, data } = res || {};
        console.log("🚀 ~ .then ~ data:", data);
        if (status === 200) {
          setData(data?.results);
          console.log({ data, a: data.results });
          const { results, totalPages, totalResults } = data || {};
          setFixedTransaction(results);
          setTotalPages(totalPages);
          setTotalResults(totalResults);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("danger");
      });
  }, [page, tab]);

  useEffect(() => {
    api && tab && getTransactions();
  }, [api, tab, getTransactions]);

  const getExcelTransactions = useCallback(() => {
    setOpen(true);
    setApi(false);
    headersGet(
      `${
        API_Urls?.getTransactions
      }?page=${page}&limit=${999999999999999}&type=${tab}`
    )
      .then((res) => {
        setOpen(false);
        const { status, data } = res || {};
        if (status === 200) {
          setExcelData(data?.results);
          console.log({ data, a: data.results });
          const { results, totalPages, totalResults } = data || {};
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("danger");
      });
  }, [page, tab]);

  useEffect(() => {
    api && tab && getExcelTransactions();
  }, [api, tab, getExcelTransactions]);

  const handlePagination = (p) => {
    setPage(p);
    console.log({ p });
    setApi(true);
  };

  const tabHandler = (value) => {
    setTab(value);
    setApi(true);
  };

  //   const exportPDF = () => {
  //     const unit = 'pt'
  //     const size = 'A4' // Use A1, A2, A3 or A4
  //     const orientation = 'portrait' // portrait or landscape

  //     const marginLeft = 40
  //     const doc = new jsPDF(orientation, unit, size)

  //     doc.setFontSize(15)

  //     const title = 'ROUNDUP History'
  //     const headers = [
  //         [
  //             'DATE',
  //             'Organisation',
  //             'CATEGORY',
  //             'CAUSE NAME',
  //             'DONATION AMOUNT',
  //             'FEES',

  //         ],
  //     ]
  //     console.log("llllllllllll",{data})

  //     const data1 = data?.results?.map(elt => [
  //         elt?.createdAt,
  //         elt?.organisation?.name,
  //         elt?.organisation?.category,
  //         elt?.organisation?.cause,
  //         elt?.amount,
  //         elt?.pp_fees
  //       ])

  //     let content = {
  //         startY: 50,
  //         head: headers,
  //         body: data1,
  //     }

  //     doc.text(title, marginLeft, 40)
  //     doc.autoTable(content)
  //     doc.save('ROUNDUP History.pdf')
  // }

  return (
    <div className="container my-5 donation">
      {alertOpen && (
        <Alert
          variant={alertIcon}
          onClose={() => setAlertOpen(false)}
          show={alertOpen}
          dismissible
        >
          {alertMsg}
        </Alert>
      )}
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <section id="tabs">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className="nav-item nav-link active"
                  id="nav-roundup-tab"
                  data-toggle="tab"
                  href="#nav-roundup"
                  role="tab"
                  aria-controls="nav-roundup"
                  aria-selected="true"
                  onClick={() => tabHandler(transactionTypes.roundUp)}
                >
                  ROUNDUP DONATIONS HISTORY
                </a>
                <a
                  className="nav-item nav-link mx-3"
                  id="nav-fixed-tab"
                  data-toggle="tab"
                  href="#nav-fixed"
                  role="tab"
                  aria-controls="nav-fixed"
                  aria-selected="false"
                  onClick={() => tabHandler(transactionTypes.oneTime)}
                >
                  FIXED DONATIONS HISTORY
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-recurring-tab"
                  data-toggle="tab"
                  href="#nav-recurring"
                  role="tab"
                  aria-controls="nav-recurring"
                  aria-selected="false"
                  onClick={() => tabHandler(transactionTypes.subscription)}
                >
                  RECURRING PAYMENTS HISTORY
                </a>
              </div>
            </nav>
            <div className="tab-content px-3 px-sm-0" id="nav-tabContent">
              {/* round ups transactions */}
              <div
                className="tab-pane fade show active"
                id="nav-roundup"
                role="tabpanel"
                aria-labelledby="nav-roundup-tab"
              >
                <div className="row table-bar">
                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                    <p className="title-bar-heading">ROUNDUP HISTORY</p>
                  </div>
                  {/* <div className="col-lg-6 col-12 col-sm-12 col-md-12">
                    <div className="user-search-icon">
                      <p>
                        <FaSearch className="searchIcon" />
                      </p>
                      <input type="search" placeholder="SEARCH" />
                    </div>
                  </div> */}
                  {/* <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                <IconButton onClick={() => exportPDF()}>
                                    <Download />
                                </IconButton>
                            </Box> */}
                  <Export
                    csvData={excelDataFormat(excelData)}
                    fileName="Roundup History"
                  />
                  {/* <ExcelFile element={<button>Download Data</button>}>
                <ExcelSheet  name="Employees">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Wallet Money" value="amount"/>
                    <ExcelColumn label="Gender" value="sex"/>
                   
                                 
                </ExcelSheet>
                
            </ExcelFile> */}
                </div>
                <DonationTable
                  data={fixedTransaction}
                  handleShow={handleShow}
                  open={open}
                />
                {/* {!open && fixedTransaction?.length > 0 && <Pagination page={page} totalPages={totalPages} handlePagination={handlePagination} />} */}
                {!open && fixedTransaction?.length > 0 && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      className="ant-pagination"
                      current={page}
                      defaultCurrent={3}
                      total={totalPages}
                      onChange={handlePagination}
                    />
                  </span>
                )}
              </div>
              {/* fixed transactions */}
              <div
                className="tab-pane fade"
                id="nav-fixed"
                role="tabpanel"
                aria-labelledby="nav-fixed-tab"
              >
                <div className="row table-bar">
                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                    <p className="title-bar-heading">FIXED DONATION HISTORY</p>
                  </div>
                  {/* <div className="col-lg-6 col-12 col-sm-12 col-md-12">
                    <div className="user-search-icon">
                      <p>
                        <FaSearch className="searchIcon" />
                      </p>
                      <input type="search" placeholder="SEARCH" />
                    </div>
                  </div> */}
                  <Export
                    csvData={excelDataFormat(excelData)}
                    fileName="Fixed Donation History"
                  />
                </div>
                <DonationTable
                  data={fixedTransaction}
                  handleShow={handleShow}
                  open={open}
                />
                {/* {!open && fixedTransaction?.length > 0 && <Pagination page={page} totalPages={totalPages} handlePagination={handlePagination} />} */}
                {!open && fixedTransaction?.length > 0 && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      className="ant-pagination"
                      current={page}
                      defaultCurrent={3}
                      total={totalResults}
                      onChange={handlePagination}
                    />
                  </span>
                )}
              </div>
              {/* recurring transactions */}
              <div
                className="tab-pane fade"
                id="nav-recurring"
                role="tabpanel"
                aria-labelledby="nav-recurring-tab"
              >
                <div className="row table-bar">
                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                    <p className="title-bar-heading">
                      RECURRING PAYMENT HISTORY
                    </p>
                  </div>
                  {/* <div className="col-lg-6 col-12 col-sm-12 col-md-12">
                    <div className="user-search-icon">
                      <p>
                        <FaSearch className="searchIcon" />
                      </p>
                      <input type="search" placeholder="SEARCH" />
                    </div>
                  </div> */}
                  <Export
                    csvData={excelDataFormat(excelData)}
                    fileName="Recurring Payment History"
                  />
                </div>
                <DonationTable
                  data={fixedTransaction}
                  handleShow={handleShow}
                  open={open}
                />
                {!open && fixedTransaction?.length > 0 && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      className="ant-pagination"
                      current={page}
                      defaultCurrent={3}
                      total={totalResults}
                      onChange={handlePagination}
                    />
                  </span>
                )}
              </div>
            </div>
          </section>
          <DonationModal
            show={show}
            data={modalData}
            handleClose={handleClose}
          />
        </div>
      </div>
    </div>
  );
}
