import React, { Fragment } from "react";

//package imports

import { useLocation } from "react-router-dom";

//user import

import MyCause from "app/components/user/MyCause";
import { Layout } from "app/components/user";

const MyCauses = () => {
  //hooks

  const { pathname } = useLocation();

  //render

  return (
    <Fragment>
      {pathname?.includes("/user") ? (
        <MyCause />
      ) : (
        <Layout>
          <MyCause />
        </Layout>
      )}
    </Fragment>
  );
};

export default MyCauses;
