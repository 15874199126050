export const ausCities = [
  {
    city: "Adelaide",
    city_ascii: "Adelaide",
    lat: -34.9275,
    lng: 138.6,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "admin",
    population: 1295714,
    id: 1036538171,
  },
  {
    city: "Adelaide River",
    city_ascii: "Adelaide River",
    lat: -13.2381,
    lng: 131.1061,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 353,
    id: 1036136750,
  },
  {
    city: "Albany",
    city_ascii: "Albany",
    lat: -35.0228,
    lng: 117.8814,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 29373,
    id: 1036047365,
  },
  {
    city: "Albury",
    city_ascii: "Albury",
    lat: -36.0806,
    lng: 146.9158,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 4804,
    id: 1036076542,
  },
  {
    city: "Alice Springs",
    city_ascii: "Alice Springs",
    lat: -23.7,
    lng: 133.8667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 23726,
    id: 1036830397,
  },
  {
    city: "Alstonville",
    city_ascii: "Alstonville",
    lat: -28.8317,
    lng: 153.4344,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5739,
    id: 1036379241,
  },
  {
    city: "Andamooka",
    city_ascii: "Andamooka",
    lat: -30.447,
    lng: 137.166,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 316,
    id: 1036260794,
  },
  {
    city: "Andergrove",
    city_ascii: "Andergrove",
    lat: -21.0931,
    lng: 149.186,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 9419,
    id: 1036907443,
  },
  {
    city: "Ararat",
    city_ascii: "Ararat",
    lat: -37.2833,
    lng: 142.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 8297,
    id: 1036674088,
  },
  {
    city: "Armidale",
    city_ascii: "Armidale",
    lat: -30.5,
    lng: 151.65,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 23352,
    id: 1036975829,
  },
  {
    city: "Atherton",
    city_ascii: "Atherton",
    lat: -17.2658,
    lng: 145.478,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 7331,
    id: 1036456869,
  },
  {
    city: "Australind",
    city_ascii: "Australind",
    lat: -33.28,
    lng: 115.726,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 14539,
    id: 1036182351,
  },
  {
    city: "Ayr",
    city_ascii: "Ayr",
    lat: -19.5744,
    lng: 147.4066,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 8738,
    id: 1036228772,
  },
  {
    city: "Bacchus Marsh",
    city_ascii: "Bacchus Marsh",
    lat: -37.675,
    lng: 144.4389,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6394,
    id: 1036524751,
  },
  {
    city: "Bairnsdale",
    city_ascii: "Bairnsdale",
    lat: -37.8333,
    lng: 147.6167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7580,
    id: 1036432237,
  },
  {
    city: "Ballarat",
    city_ascii: "Ballarat",
    lat: -37.5608,
    lng: 143.8475,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 98543,
    id: 1036567186,
  },
  {
    city: "Ballina",
    city_ascii: "Ballina",
    lat: -28.8333,
    lng: 153.5333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8655,
    id: 1036040102,
  },
  {
    city: "Bannockburn",
    city_ascii: "Bannockburn",
    lat: -38.05,
    lng: 144.1667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5283,
    id: 1036085175,
  },
  {
    city: "Banora Point",
    city_ascii: "Banora Point",
    lat: -28.2225,
    lng: 153.5386,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 16167,
    id: 1036608972,
  },
  {
    city: "Barcaldine",
    city_ascii: "Barcaldine",
    lat: -23.5555,
    lng: 145.2888,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 1422,
    id: 1036284609,
  },
  {
    city: "Bargara",
    city_ascii: "Bargara",
    lat: -24.8205,
    lng: 152.4625,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 7485,
    id: 1036308552,
  },
  {
    city: "Barwon Heads",
    city_ascii: "Barwon Heads",
    lat: -38.25,
    lng: 144.5167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 14165,
    id: 1036797778,
  },
  {
    city: "Batemans Bay",
    city_ascii: "Batemans Bay",
    lat: -35.7081,
    lng: 150.1744,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 1530,
    id: 1036272805,
  },
  {
    city: "Bathurst",
    city_ascii: "Bathurst",
    lat: -33.42,
    lng: 149.5778,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 34303,
    id: 1036446465,
  },
  {
    city: "Beaudesert",
    city_ascii: "Beaudesert",
    lat: -27.988,
    lng: 152.9958,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6395,
    id: 1036443236,
  },
  {
    city: "Bedourie",
    city_ascii: "Bedourie",
    lat: -24.35,
    lng: 139.4667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 122,
    id: 1036680924,
  },
  {
    city: "Beerwah",
    city_ascii: "Beerwah",
    lat: -26.8583,
    lng: 152.9588,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6769,
    id: 1036411138,
  },
  {
    city: "Benalla",
    city_ascii: "Benalla",
    lat: -36.5519,
    lng: 145.9817,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 10330,
    id: 1036934047,
  },
  {
    city: "Bendigo",
    city_ascii: "Bendigo",
    lat: -36.75,
    lng: 144.2667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 95587,
    id: 1036537913,
  },
  {
    city: "Berri",
    city_ascii: "Berri",
    lat: -34.2833,
    lng: 140.6,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 4088,
    id: 1036554717,
  },
  {
    city: "Bicheno",
    city_ascii: "Bicheno",
    lat: -41.8667,
    lng: 148.2833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 943,
    id: 1036189642,
  },
  {
    city: "Biloela",
    city_ascii: "Biloela",
    lat: -24.4002,
    lng: 150.5133,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5758,
    id: 1036014905,
  },
  {
    city: "Birdsville",
    city_ascii: "Birdsville",
    lat: -25.8989,
    lng: 139.352,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 140,
    id: 1036830335,
  },
  {
    city: "Blaxland",
    city_ascii: "Blaxland",
    lat: -33.75,
    lng: 150.6167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7451,
    id: 1036026558,
  },
  {
    city: "Bli Bli",
    city_ascii: "Bli Bli",
    lat: -26.618,
    lng: 153.037,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 7801,
    id: 1036863296,
  },
  {
    city: "Bomaderry",
    city_ascii: "Bomaderry",
    lat: -34.848,
    lng: 150.605,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6661,
    id: 1036102200,
  },
  {
    city: "Bongaree",
    city_ascii: "Bongaree",
    lat: -27.0813,
    lng: 153.1636,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 13649,
    id: 1036269345,
  },
  {
    city: "Bordertown",
    city_ascii: "Bordertown",
    lat: -36.3118,
    lng: 140.7702,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 2953,
    id: 1036115980,
  },
  {
    city: "Boulia",
    city_ascii: "Boulia",
    lat: -22.9,
    lng: 139.9,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 301,
    id: 1036512474,
  },
  {
    city: "Bourke",
    city_ascii: "Bourke",
    lat: -30.1,
    lng: 145.9333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 1909,
    id: 1036074667,
  },
  {
    city: "Bowen",
    city_ascii: "Bowen",
    lat: -20.0102,
    lng: 148.2416,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 10377,
    id: 1036979225,
  },
  {
    city: "Bowral",
    city_ascii: "Bowral",
    lat: -34.4792,
    lng: 150.4181,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 10335,
    id: 1036253752,
  },
  {
    city: "Brisbane",
    city_ascii: "Brisbane",
    lat: -27.4678,
    lng: 153.0281,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "admin",
    population: 2360241,
    id: 1036192929,
  },
  {
    city: "Broken Hill",
    city_ascii: "Broken Hill",
    lat: -31.95,
    lng: 141.4667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 17814,
    id: 1036216956,
  },
  {
    city: "Broome",
    city_ascii: "Broome",
    lat: -17.9619,
    lng: 122.2361,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 4042,
    id: 1036047905,
  },
  {
    city: "Bullsbrook",
    city_ascii: "Bullsbrook",
    lat: -31.663,
    lng: 116.03,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 5448,
    id: 1036370763,
  },
  {
    city: "Bunbury",
    city_ascii: "Bunbury",
    lat: -33.3272,
    lng: 115.6369,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 71090,
    id: 1036561011,
  },
  {
    city: "Bundaberg",
    city_ascii: "Bundaberg",
    lat: -24.8661,
    lng: 152.3494,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 70540,
    id: 1036377333,
  },
  {
    city: "Burketown",
    city_ascii: "Burketown",
    lat: -17.7167,
    lng: 139.5667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 238,
    id: 1036956849,
  },
  {
    city: "Burnie",
    city_ascii: "Burnie",
    lat: -41.0636,
    lng: 145.8753,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 27174,
    id: 1036394657,
  },
  {
    city: "Burpengary",
    city_ascii: "Burpengary",
    lat: -27.1442,
    lng: 153,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6433,
    id: 1036894671,
  },
  {
    city: "Busselton",
    city_ascii: "Busselton",
    lat: -33.6478,
    lng: 115.3458,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 25329,
    id: 1036911902,
  },
  {
    city: "Byron Bay",
    city_ascii: "Byron Bay",
    lat: -28.6483,
    lng: 153.6178,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5521,
    id: 1036606974,
  },
  {
    city: "Caboolture",
    city_ascii: "Caboolture",
    lat: -27.0667,
    lng: 152.967,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 26433,
    id: 1036273434,
  },
  {
    city: "Cairns",
    city_ascii: "Cairns",
    lat: -16.92,
    lng: 145.78,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 146778,
    id: 1036016939,
  },
  {
    city: "Calliope",
    city_ascii: "Calliope",
    lat: -24.0061,
    lng: 151.1988,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5101,
    id: 1036428332,
  },
  {
    city: "Caloundra",
    city_ascii: "Caloundra",
    lat: -26.7986,
    lng: 153.1289,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 41293,
    id: 1036266942,
  },
  {
    city: "Camooweal",
    city_ascii: "Camooweal",
    lat: -19.9167,
    lng: 138.117,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 208,
    id: 1036815011,
  },
  {
    city: "Campbelltown",
    city_ascii: "Campbelltown",
    lat: -34.0733,
    lng: 150.8261,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 12566,
    id: 1036474450,
  },
  {
    city: "Canberra",
    city_ascii: "Canberra",
    lat: -35.2931,
    lng: 149.1269,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Australian Capital Territory",
    capital: "primary",
    population: 381488,
    id: 1036142029,
  },
  {
    city: "Cannonvale",
    city_ascii: "Cannonvale",
    lat: -20.2787,
    lng: 148.6994,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5716,
    id: 1036428480,
  },
  {
    city: "Carnarvon",
    city_ascii: "Carnarvon",
    lat: -24.8672,
    lng: 113.6611,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 208,
    id: 1036344209,
  },
  {
    city: "Casino",
    city_ascii: "Casino",
    lat: -28.8667,
    lng: 153.05,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 10914,
    id: 1036855900,
  },
  {
    city: "Castlemaine",
    city_ascii: "Castlemaine",
    lat: -37.0636,
    lng: 144.2172,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6757,
    id: 1036572269,
  },
  {
    city: "Ceduna",
    city_ascii: "Ceduna",
    lat: -32.1167,
    lng: 133.6667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 2157,
    id: 1036725988,
  },
  {
    city: "Central Coast",
    city_ascii: "Central Coast",
    lat: -33.3,
    lng: 151.2,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 297713,
    id: 1036067845,
  },
  {
    city: "Cessnock",
    city_ascii: "Cessnock",
    lat: -32.8342,
    lng: 151.3555,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 15177,
    id: 1036822046,
  },
  {
    city: "Charleville",
    city_ascii: "Charleville",
    lat: -26.4016,
    lng: 146.2383,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 3335,
    id: 1036575498,
  },
  {
    city: "Charters Towers",
    city_ascii: "Charters Towers",
    lat: -20.0765,
    lng: 146.2614,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 8126,
    id: 1036733088,
  },
  {
    city: "Chinchilla",
    city_ascii: "Chinchilla",
    lat: -26.7383,
    lng: 150.6283,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6612,
    id: 1036824282,
  },
  {
    city: "Clare",
    city_ascii: "Clare",
    lat: -33.8333,
    lng: 138.6,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 3327,
    id: 1036842122,
  },
  {
    city: "Clifton Springs",
    city_ascii: "Clifton Springs",
    lat: -38.15,
    lng: 144.5667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7519,
    id: 1036319529,
  },
  {
    city: "Cloncurry",
    city_ascii: "Cloncurry",
    lat: -20.7047,
    lng: 140.5052,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 2719,
    id: 1036672657,
  },
  {
    city: "Cobram",
    city_ascii: "Cobram",
    lat: -35.9667,
    lng: 145.65,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6014,
    id: 1036438159,
  },
  {
    city: "Coffs Harbour",
    city_ascii: "Coffs Harbour",
    lat: -30.3022,
    lng: 153.1189,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 25752,
    id: 1036320442,
  },
  {
    city: "Colac",
    city_ascii: "Colac",
    lat: -38.3403,
    lng: 143.5847,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9048,
    id: 1036633113,
  },
  {
    city: "Collie",
    city_ascii: "Collie",
    lat: -33.363,
    lng: 116.156,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 7587,
    id: 1036553705,
  },
  {
    city: "Cooma",
    city_ascii: "Cooma",
    lat: -36.2317,
    lng: 149.1178,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6681,
    id: 1036070931,
  },
  {
    city: "Cooranbong",
    city_ascii: "Cooranbong",
    lat: -33.074,
    lng: 151.451,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5449,
    id: 1036555720,
  },
  {
    city: "Cootamundra",
    city_ascii: "Cootamundra",
    lat: -34.6483,
    lng: 148.0344,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6782,
    id: 1036618399,
  },
  {
    city: "Corinda",
    city_ascii: "Corinda",
    lat: -27.4833,
    lng: 153.1,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 11019,
    id: 1036501460,
  },
  {
    city: "Corowa",
    city_ascii: "Corowa",
    lat: -35.9942,
    lng: 146.3844,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5482,
    id: 1036866154,
  },
  {
    city: "Cowell",
    city_ascii: "Cowell",
    lat: -33.6833,
    lng: 136.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 990,
    id: 1036921929,
  },
  {
    city: "Cowra",
    city_ascii: "Cowra",
    lat: -33.8183,
    lng: 148.6578,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 10063,
    id: 1036204877,
  },
  {
    city: "Cranbourne",
    city_ascii: "Cranbourne",
    lat: -38.0996,
    lng: 145.2834,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 460491,
    id: 1036685862,
  },
  {
    city: "Currumbin",
    city_ascii: "Currumbin",
    lat: -28.158,
    lng: 153.469,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 9121,
    id: 1036194178,
  },
  {
    city: "Dalby",
    city_ascii: "Dalby",
    lat: -27.1813,
    lng: 151.2661,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 12719,
    id: 1036053707,
  },
  {
    city: "Dandenong",
    city_ascii: "Dandenong",
    lat: -37.981,
    lng: 145.215,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 29906,
    id: 1036214023,
  },
  {
    city: "Darley",
    city_ascii: "Darley",
    lat: -37.6547,
    lng: 144.437,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 8372,
    id: 1036031812,
  },
  {
    city: "Darwin",
    city_ascii: "Darwin",
    lat: -12.4381,
    lng: 130.8411,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "admin",
    population: 136828,
    id: 1036497565,
  },
  {
    city: "Deniliquin",
    city_ascii: "Deniliquin",
    lat: -35.5331,
    lng: 144.9667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7862,
    id: 1036325219,
  },
  {
    city: "Devonport",
    city_ascii: "Devonport",
    lat: -41.18,
    lng: 146.3503,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 23046,
    id: 1036033175,
  },
  {
    city: "Dromana",
    city_ascii: "Dromana",
    lat: -38.338,
    lng: 144.965,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5803,
    id: 1036001258,
  },
  {
    city: "Drouin",
    city_ascii: "Drouin",
    lat: -38.1333,
    lng: 145.85,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 12349,
    id: 1036564546,
  },
  {
    city: "Dubbo",
    city_ascii: "Dubbo",
    lat: -32.2569,
    lng: 148.6011,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 38943,
    id: 1036855042,
  },
  {
    city: "Dunsborough",
    city_ascii: "Dunsborough",
    lat: -33.6167,
    lng: 115.1,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 5320,
    id: 1036428544,
  },
  {
    city: "East Ballina",
    city_ascii: "East Ballina",
    lat: -28.8594,
    lng: 153.5872,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5571,
    id: 1036094141,
  },
  {
    city: "Echuca",
    city_ascii: "Echuca",
    lat: -36.1333,
    lng: 144.75,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 14043,
    id: 1036292989,
  },
  {
    city: "Eidsvold",
    city_ascii: "Eidsvold",
    lat: -25.3667,
    lng: 151.1333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 574,
    id: 1036235197,
  },
  {
    city: "Emerald",
    city_ascii: "Emerald",
    lat: -23.5208,
    lng: 148.1619,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 14356,
    id: 1036124660,
  },
  {
    city: "Emerald",
    city_ascii: "Emerald",
    lat: -37.9331,
    lng: 145.437,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5778,
    id: 1036823239,
  },
  {
    city: "Emu Plains",
    city_ascii: "Emu Plains",
    lat: -33.7483,
    lng: 150.6678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8421,
    id: 1036920187,
  },
  {
    city: "Esperance",
    city_ascii: "Esperance",
    lat: -33.8611,
    lng: 121.8919,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 2144,
    id: 1036299314,
  },
  {
    city: "Exmouth",
    city_ascii: "Exmouth",
    lat: -21.9331,
    lng: 114.1281,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 2514,
    id: 1036006022,
  },
  {
    city: "Forbes",
    city_ascii: "Forbes",
    lat: -33.3817,
    lng: 148.0011,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8432,
    id: 1036523834,
  },
  {
    city: "Forster",
    city_ascii: "Forster",
    lat: -32.1806,
    lng: 152.5117,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 13740,
    id: 1036685795,
  },
  {
    city: "Frankston",
    city_ascii: "Frankston",
    lat: -38.158,
    lng: 145.135,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 36097,
    id: 1036126828,
  },
  {
    city: "Fremantle",
    city_ascii: "Fremantle",
    lat: -32.0569,
    lng: 115.7439,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 7459,
    id: 1036824217,
  },
  {
    city: "Gatton",
    city_ascii: "Gatton",
    lat: -27.5611,
    lng: 152.2755,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 7101,
    id: 1036434682,
  },
  {
    city: "Gawler",
    city_ascii: "Gawler",
    lat: -34.5981,
    lng: 138.745,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 650,
    id: 1036309493,
  },
  {
    city: "Geelong",
    city_ascii: "Geelong",
    lat: -38.15,
    lng: 144.35,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 184182,
    id: 1036870987,
  },
  {
    city: "Georgetown",
    city_ascii: "Georgetown",
    lat: -18.3,
    lng: 143.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 348,
    id: 1036412066,
  },
  {
    city: "Geraldton",
    city_ascii: "Geraldton",
    lat: -28.7744,
    lng: 114.6089,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 31982,
    id: 1036811875,
  },
  {
    city: "Gingin",
    city_ascii: "Gingin",
    lat: -31.34,
    lng: 115.91,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 852,
    id: 1036686561,
  },
  {
    city: "Gisborne",
    city_ascii: "Gisborne",
    lat: -37.49,
    lng: 144.5889,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 8999,
    id: 1036388639,
  },
  {
    city: "Gladstone",
    city_ascii: "Gladstone",
    lat: -23.8427,
    lng: 151.2555,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 33418,
    id: 1036070755,
  },
  {
    city: "Glass House Mountains",
    city_ascii: "Glass House Mountains",
    lat: -26.9,
    lng: 152.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5065,
    id: 1036172029,
  },
  {
    city: "Glen Innes",
    city_ascii: "Glen Innes",
    lat: -29.7317,
    lng: 151.7511,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6155,
    id: 1036276799,
  },
  {
    city: "Glenbrook",
    city_ascii: "Glenbrook",
    lat: -33.765,
    lng: 150.6267,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5051,
    id: 1036560336,
  },
  {
    city: "Gold Coast",
    city_ascii: "Gold Coast",
    lat: -28.0167,
    lng: 153.4,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 638090,
    id: 1036153217,
  },
  {
    city: "Goondiwindi",
    city_ascii: "Goondiwindi",
    lat: -28.5461,
    lng: 150.3097,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6355,
    id: 1036452066,
  },
  {
    city: "Goonellabah",
    city_ascii: "Goonellabah",
    lat: -28.8167,
    lng: 153.3167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 12900,
    id: 1036897159,
  },
  {
    city: "Gordonvale",
    city_ascii: "Gordonvale",
    lat: -17.0936,
    lng: 145.7866,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6671,
    id: 1036505934,
  },
  {
    city: "Goulburn",
    city_ascii: "Goulburn",
    lat: -34.7547,
    lng: 149.6186,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 22419,
    id: 1036347537,
  },
  {
    city: "Gracemere",
    city_ascii: "Gracemere",
    lat: -23.4391,
    lng: 150.4558,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 11315,
    id: 1036779939,
  },
  {
    city: "Grafton",
    city_ascii: "Grafton",
    lat: -29.6833,
    lng: 152.9333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 10408,
    id: 1036615468,
  },
  {
    city: "Griffith",
    city_ascii: "Griffith",
    lat: -34.29,
    lng: 146.04,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 18196,
    id: 1036563313,
  },
  {
    city: "Gunnedah",
    city_ascii: "Gunnedah",
    lat: -30.9667,
    lng: 150.25,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 9726,
    id: 1036500021,
  },
  {
    city: "Gympie",
    city_ascii: "Gympie",
    lat: -26.19,
    lng: 152.6655,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 10803,
    id: 1036391013,
  },
  {
    city: "Halls Creek",
    city_ascii: "Halls Creek",
    lat: -18.23,
    lng: 127.67,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 1499,
    id: 1036444511,
  },
  {
    city: "Hamilton",
    city_ascii: "Hamilton",
    lat: -37.7333,
    lng: 142.0167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9974,
    id: 1036699883,
  },
  {
    city: "Healesville",
    city_ascii: "Healesville",
    lat: -37.6561,
    lng: 145.5139,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7461,
    id: 1036553015,
  },
  {
    city: "Helensburgh",
    city_ascii: "Helensburgh",
    lat: -34.1889,
    lng: 150.9811,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6383,
    id: 1036359360,
  },
  {
    city: "Hervey Bay",
    city_ascii: "Hervey Bay",
    lat: -25.29,
    lng: 152.84,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 52073,
    id: 1036833938,
  },
  {
    city: "Highfields",
    city_ascii: "Highfields",
    lat: -27.4633,
    lng: 151.9458,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 9474,
    id: 1036399510,
  },
  {
    city: "Hobart",
    city_ascii: "Hobart",
    lat: -42.8806,
    lng: 147.325,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "admin",
    population: 222356,
    id: 1036679838,
  },
  {
    city: "Horsham",
    city_ascii: "Horsham",
    lat: -36.7167,
    lng: 142.2,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 14543,
    id: 1036654340,
  },
  {
    city: "Howard Springs",
    city_ascii: "Howard Springs",
    lat: -12.4922,
    lng: 131.053,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 5132,
    id: 1036674070,
  },
  {
    city: "Hughenden",
    city_ascii: "Hughenden",
    lat: -20.8438,
    lng: 144.1986,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 1136,
    id: 1036738932,
  },
  {
    city: "Ingham",
    city_ascii: "Ingham",
    lat: -18.65,
    lng: 146.1667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 4426,
    id: 1036238542,
  },
  {
    city: "Innisfail",
    city_ascii: "Innisfail",
    lat: -17.5238,
    lng: 146.0311,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 1145,
    id: 1036930229,
  },
  {
    city: "Inverell",
    city_ascii: "Inverell",
    lat: -29.7667,
    lng: 151.1167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 11660,
    id: 1036652902,
  },
  {
    city: "Inverloch",
    city_ascii: "Inverloch",
    lat: -38.6334,
    lng: 145.7278,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5437,
    id: 1036393902,
  },
  {
    city: "Ipswich",
    city_ascii: "Ipswich",
    lat: -27.6167,
    lng: 152.7667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 163000,
    id: 1036244388,
  },
  {
    city: "Irymple",
    city_ascii: "Irymple",
    lat: -34.2333,
    lng: 142.1667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5325,
    id: 1036990765,
  },
  {
    city: "Ivanhoe",
    city_ascii: "Ivanhoe",
    lat: -32.8983,
    lng: 144.3,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 327,
    id: 1036571526,
  },
  {
    city: "Jimboomba",
    city_ascii: "Jimboomba",
    lat: -27.83,
    lng: 153.0313,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 13201,
    id: 1036214142,
  },
  {
    city: "Kalbarri",
    city_ascii: "Kalbarri",
    lat: -27.71,
    lng: 114.16,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 1557,
    id: 1036429141,
  },
  {
    city: "Kalgoorlie",
    city_ascii: "Kalgoorlie",
    lat: -30.7489,
    lng: 121.4658,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 30841,
    id: 1036087904,
  },
  {
    city: "Kangaroo Flat",
    city_ascii: "Kangaroo Flat",
    lat: -36.7833,
    lng: 144.233,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 10394,
    id: 1036769935,
  },
  {
    city: "Karratha",
    city_ascii: "Karratha",
    lat: -20.7364,
    lng: 116.8464,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 15828,
    id: 1036951388,
  },
  {
    city: "Karumba",
    city_ascii: "Karumba",
    lat: -17.4838,
    lng: 140.8397,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 531,
    id: 1036926106,
  },
  {
    city: "Katanning",
    city_ascii: "Katanning",
    lat: -33.6908,
    lng: 117.5553,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 3687,
    id: 1036411379,
  },
  {
    city: "Katherine",
    city_ascii: "Katherine",
    lat: -14.4667,
    lng: 132.2667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 1488,
    id: 1036323110,
  },
  {
    city: "Katoomba",
    city_ascii: "Katoomba",
    lat: -33.715,
    lng: 150.312,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7964,
    id: 1036674004,
  },
  {
    city: "Kelso",
    city_ascii: "Kelso",
    lat: -33.4186,
    lng: 149.6056,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8968,
    id: 1036270756,
  },
  {
    city: "Kempsey",
    city_ascii: "Kempsey",
    lat: -31.0833,
    lng: 152.8333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 474,
    id: 1036897003,
  },
  {
    city: "Kiama",
    city_ascii: "Kiama",
    lat: -34.6708,
    lng: 150.8542,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6881,
    id: 1036754695,
  },
  {
    city: "Kiama Downs",
    city_ascii: "Kiama Downs",
    lat: -34.6317,
    lng: 150.8511,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5004,
    id: 1036560277,
  },
  {
    city: "Kilmore",
    city_ascii: "Kilmore",
    lat: -37.2833,
    lng: 144.95,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7958,
    id: 1036740563,
  },
  {
    city: "Kimba",
    city_ascii: "Kimba",
    lat: -33.1333,
    lng: 136.417,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 629,
    id: 1036035341,
  },
  {
    city: "Kingaroy",
    city_ascii: "Kingaroy",
    lat: -26.5408,
    lng: 151.8394,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 10020,
    id: 1036463308,
  },
  {
    city: "Kingoonya",
    city_ascii: "Kingoonya",
    lat: -30.9,
    lng: 135.3,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 13,
    id: 1036942792,
  },
  {
    city: "Kingscliff",
    city_ascii: "Kingscliff",
    lat: -28.2617,
    lng: 153.5769,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7464,
    id: 1036796141,
  },
  {
    city: "Kingston",
    city_ascii: "Kingston",
    lat: -42.9769,
    lng: 147.3083,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 10409,
    id: 1036297213,
  },
  {
    city: "Kingston South East",
    city_ascii: "Kingston South East",
    lat: -36.8167,
    lng: 139.85,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1393,
    id: 1036261175,
  },
  {
    city: "Kununurra",
    city_ascii: "Kununurra",
    lat: -15.7736,
    lng: 128.7386,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 5308,
    id: 1036556231,
  },
  {
    city: "Kurri Kurri",
    city_ascii: "Kurri Kurri",
    lat: -32.8167,
    lng: 151.4833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6044,
    id: 1036219161,
  },
  {
    city: "Kwinana",
    city_ascii: "Kwinana",
    lat: -32.2394,
    lng: 115.7702,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 20086,
    id: 1036753478,
  },
  {
    city: "Kyabram",
    city_ascii: "Kyabram",
    lat: -36.3167,
    lng: 145.05,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7331,
    id: 1036000064,
  },
  {
    city: "Kyneton",
    city_ascii: "Kyneton",
    lat: -37.247,
    lng: 144.455,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6951,
    id: 1036250832,
  },
  {
    city: "Lakes Entrance",
    city_ascii: "Lakes Entrance",
    lat: -37.8667,
    lng: 147.9833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6071,
    id: 1036994006,
  },
  {
    city: "Lara",
    city_ascii: "Lara",
    lat: -38.0167,
    lng: 144.4167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 16355,
    id: 1036754750,
  },
  {
    city: "Launceston",
    city_ascii: "Launceston",
    lat: -41.4419,
    lng: 147.145,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 106153,
    id: 1036170383,
  },
  {
    city: "Laverton",
    city_ascii: "Laverton",
    lat: -28.628,
    lng: 122.403,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 871,
    id: 1036624366,
  },
  {
    city: "Leeton",
    city_ascii: "Leeton",
    lat: -34.5667,
    lng: 146.4,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8623,
    id: 1036102538,
  },
  {
    city: "Lennox Head",
    city_ascii: "Lennox Head",
    lat: -28.7983,
    lng: 153.5844,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6407,
    id: 1036688711,
  },
  {
    city: "Leongatha",
    city_ascii: "Leongatha",
    lat: -38.4833,
    lng: 145.95,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5654,
    id: 1036910660,
  },
  {
    city: "Leonora",
    city_ascii: "Leonora",
    lat: -28.88,
    lng: 121.33,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 781,
    id: 1036140404,
  },
  {
    city: "Leopold",
    city_ascii: "Leopold",
    lat: -38.1892,
    lng: 144.4644,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 12814,
    id: 1036263278,
  },
  {
    city: "Lismore",
    city_ascii: "Lismore",
    lat: -28.8167,
    lng: 153.2833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 3579,
    id: 1036024174,
  },
  {
    city: "Lithgow",
    city_ascii: "Lithgow",
    lat: -33.4833,
    lng: 150.15,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5201,
    id: 1036591538,
  },
  {
    city: "Longreach",
    city_ascii: "Longreach",
    lat: -23.4422,
    lng: 144.2491,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 2970,
    id: 1036622618,
  },
  {
    city: "Lysterfield",
    city_ascii: "Lysterfield",
    lat: -37.93,
    lng: 145.301,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6663,
    id: 1036662782,
  },
  {
    city: "Mackay",
    city_ascii: "Mackay",
    lat: -21.1411,
    lng: 149.1858,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 3659,
    id: 1036507374,
  },
  {
    city: "Maffra",
    city_ascii: "Maffra",
    lat: -37.95,
    lng: 146.983,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5280,
    id: 1036194694,
  },
  {
    city: "Maitland",
    city_ascii: "Maitland",
    lat: -32.7167,
    lng: 151.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 1885,
    id: 1036034945,
  },
  {
    city: "Mandurah",
    city_ascii: "Mandurah",
    lat: -32.5289,
    lng: 115.7231,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 7837,
    id: 1036773118,
  },
  {
    city: "Manjimup",
    city_ascii: "Manjimup",
    lat: -34.2411,
    lng: 116.1464,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 4349,
    id: 1036022434,
  },
  {
    city: "Mareeba",
    city_ascii: "Mareeba",
    lat: -16.9833,
    lng: 145.4167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 11079,
    id: 1036797275,
  },
  {
    city: "Margaret River",
    city_ascii: "Margaret River",
    lat: -33.955,
    lng: 115.075,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 7654,
    id: 1036193817,
  },
  {
    city: "Maryborough",
    city_ascii: "Maryborough",
    lat: -25.5375,
    lng: 152.7019,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 15406,
    id: 1036205623,
  },
  {
    city: "Maryborough",
    city_ascii: "Maryborough",
    lat: -37.05,
    lng: 143.735,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7921,
    id: 1036781601,
  },
  {
    city: "McMinns Lagoon",
    city_ascii: "McMinns Lagoon",
    lat: -12.5329,
    lng: 131.05,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 5025,
    id: 1036673821,
  },
  {
    city: "Meekatharra",
    city_ascii: "Meekatharra",
    lat: -26.5931,
    lng: 118.4911,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 708,
    id: 1036608457,
  },
  {
    city: "Melbourne",
    city_ascii: "Melbourne",
    lat: -37.8136,
    lng: 144.9631,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "admin",
    population: 4529500,
    id: 1036533631,
  },
  {
    city: "Melton",
    city_ascii: "Melton",
    lat: -37.6833,
    lng: 144.5833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 54455,
    id: 1036007778,
  },
  {
    city: "Meningie",
    city_ascii: "Meningie",
    lat: -35.6883,
    lng: 139.338,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1118,
    id: 1036445634,
  },
  {
    city: "Merimbula",
    city_ascii: "Merimbula",
    lat: -36.8983,
    lng: 149.9011,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 3544,
    id: 1036933197,
  },
  {
    city: "Merredin",
    city_ascii: "Merredin",
    lat: -31.482,
    lng: 118.279,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 2850,
    id: 1036578320,
  },
  {
    city: "Merrimac",
    city_ascii: "Merrimac",
    lat: -28.05,
    lng: 153.3667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7071,
    id: 1036000674,
  },
  {
    city: "Mildura",
    city_ascii: "Mildura",
    lat: -34.1889,
    lng: 142.1583,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 32738,
    id: 1036624348,
  },
  {
    city: "Millicent",
    city_ascii: "Millicent",
    lat: -37.5967,
    lng: 140.3524,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 5109,
    id: 1036760447,
  },
  {
    city: "Mittagong",
    city_ascii: "Mittagong",
    lat: -34.45,
    lng: 150.45,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5767,
    id: 1036443307,
  },
  {
    city: "Moe",
    city_ascii: "Moe",
    lat: -38.1722,
    lng: 146.2678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 8778,
    id: 1036922580,
  },
  {
    city: "Mooroopna",
    city_ascii: "Mooroopna",
    lat: -36.3833,
    lng: 145.35,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7942,
    id: 1036788714,
  },
  {
    city: "Moranbah",
    city_ascii: "Moranbah",
    lat: -22.0016,
    lng: 148.0533,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 8735,
    id: 1036916453,
  },
  {
    city: "Morawa",
    city_ascii: "Morawa",
    lat: -29.2111,
    lng: 116.009,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 532,
    id: 1036194388,
  },
  {
    city: "Moree",
    city_ascii: "Moree",
    lat: -29.465,
    lng: 149.8344,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 9311,
    id: 1036377829,
  },
  {
    city: "Morwell",
    city_ascii: "Morwell",
    lat: -38.2333,
    lng: 146.4,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 13771,
    id: 1036750619,
  },
  {
    city: "Moss Vale",
    city_ascii: "Moss Vale",
    lat: -34.55,
    lng: 150.3833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8579,
    id: 1036872626,
  },
  {
    city: "Mount Barker",
    city_ascii: "Mount Barker",
    lat: -35.0667,
    lng: 138.85,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 13842,
    id: 1036530325,
  },
  {
    city: "Mount Barker",
    city_ascii: "Mount Barker",
    lat: -34.63,
    lng: 117.6669,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 2741,
    id: 1036651498,
  },
  {
    city: "Mount Cotton",
    city_ascii: "Mount Cotton",
    lat: -27.6188,
    lng: 153.221,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6835,
    id: 1036481380,
  },
  {
    city: "Mount Eliza",
    city_ascii: "Mount Eliza",
    lat: -38.189,
    lng: 145.092,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 17888,
    id: 1036041769,
  },
  {
    city: "Mount Evelyn",
    city_ascii: "Mount Evelyn",
    lat: -37.783,
    lng: 145.385,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9702,
    id: 1036617292,
  },
  {
    city: "Mount Gambier",
    city_ascii: "Mount Gambier",
    lat: -37.8294,
    lng: 140.7828,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 25512,
    id: 1036932780,
  },
  {
    city: "Mount Isa",
    city_ascii: "Mount Isa",
    lat: -20.7333,
    lng: 139.5,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 118,
    id: 1036386100,
  },
  {
    city: "Mount Magnet",
    city_ascii: "Mount Magnet",
    lat: -28.06,
    lng: 117.846,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 470,
    id: 1036327720,
  },
  {
    city: "Mudgee",
    city_ascii: "Mudgee",
    lat: -32.6125,
    lng: 149.5872,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 9830,
    id: 1036213564,
  },
  {
    city: "Murray Bridge",
    city_ascii: "Murray Bridge",
    lat: -35.117,
    lng: 139.267,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 14560,
    id: 1036345943,
  },
  {
    city: "Murwillumbah",
    city_ascii: "Murwillumbah",
    lat: -28.3333,
    lng: 153.3833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7032,
    id: 1036989894,
  },
  {
    city: "Muswellbrook",
    city_ascii: "Muswellbrook",
    lat: -32.2654,
    lng: 150.8885,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 12075,
    id: 1036253543,
  },
  {
    city: "Nairne",
    city_ascii: "Nairne",
    lat: -35.0333,
    lng: 138.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 5042,
    id: 1036560818,
  },
  {
    city: "Nambour",
    city_ascii: "Nambour",
    lat: -26.6269,
    lng: 152.9591,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 11187,
    id: 1036804920,
  },
  {
    city: "Nambucca Heads",
    city_ascii: "Nambucca Heads",
    lat: -30.6414,
    lng: 152.9906,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6327,
    id: 1036453067,
  },
  {
    city: "Naracoorte",
    city_ascii: "Naracoorte",
    lat: -36.955,
    lng: 140.7429,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 5960,
    id: 1036098836,
  },
  {
    city: "Narangba",
    city_ascii: "Narangba",
    lat: -27.2015,
    lng: 152.9655,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 18573,
    id: 1036559054,
  },
  {
    city: "Narrabri",
    city_ascii: "Narrabri",
    lat: -30.3317,
    lng: 149.7678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7606,
    id: 1036714493,
  },
  {
    city: "Narre Warren North",
    city_ascii: "Narre Warren North",
    lat: -37.982,
    lng: 145.314,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7674,
    id: 1036476634,
  },
  {
    city: "Narrogin",
    city_ascii: "Narrogin",
    lat: -32.936,
    lng: 117.178,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 4274,
    id: 1036221961,
  },
  {
    city: "Nelson Bay",
    city_ascii: "Nelson Bay",
    lat: -32.715,
    lng: 152.1511,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 11060,
    id: 1036777604,
  },
  {
    city: "Nerang",
    city_ascii: "Nerang",
    lat: -27.9956,
    lng: 153.322,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 16864,
    id: 1036082899,
  },
  {
    city: "New Norfolk",
    city_ascii: "New Norfolk",
    lat: -42.7828,
    lng: 147.0594,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 5432,
    id: 1036880683,
  },
  {
    city: "Newcastle",
    city_ascii: "Newcastle",
    lat: -32.9167,
    lng: 151.75,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 152984,
    id: 1036468001,
  },
  {
    city: "Newman",
    city_ascii: "Newman",
    lat: -23.3539,
    lng: 119.7319,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 7238,
    id: 1036587511,
  },
  {
    city: "Norseman",
    city_ascii: "Norseman",
    lat: -32.1961,
    lng: 121.778,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 581,
    id: 1036257263,
  },
  {
    city: "North Mackay",
    city_ascii: "North Mackay",
    lat: -21.1216,
    lng: 149.1783,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5933,
    id: 1036075287,
  },
  {
    city: "North Tamborine",
    city_ascii: "North Tamborine",
    lat: -27.9694,
    lng: 153.1992,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 7349,
    id: 1036063516,
  },
  {
    city: "Northam",
    city_ascii: "Northam",
    lat: -31.6531,
    lng: 116.6661,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 6548,
    id: 1036868267,
  },
  {
    city: "Nowra",
    city_ascii: "Nowra",
    lat: -34.8808,
    lng: 150.6075,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 9193,
    id: 1036984536,
  },
  {
    city: "Nuriootpa",
    city_ascii: "Nuriootpa",
    lat: -34.4667,
    lng: 138.9833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 6314,
    id: 1036952732,
  },
  {
    city: "Oatlands",
    city_ascii: "Oatlands",
    lat: -42.3,
    lng: 147.3706,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 683,
    id: 1036926323,
  },
  {
    city: "Ocean Shores",
    city_ascii: "Ocean Shores",
    lat: -28.5092,
    lng: 153.5375,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5137,
    id: 1036584832,
  },
  {
    city: "Onslow",
    city_ascii: "Onslow",
    lat: -21.6333,
    lng: 115.1167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 857,
    id: 1036255184,
  },
  {
    city: "Orange",
    city_ascii: "Orange",
    lat: -33.2833,
    lng: 149.1,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 38097,
    id: 1036760396,
  },
  {
    city: "Ouyen",
    city_ascii: "Ouyen",
    lat: -35.0667,
    lng: 142.317,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 1191,
    id: 1036764173,
  },
  {
    city: "Pakenham",
    city_ascii: "Pakenham",
    lat: -38.0712,
    lng: 145.4878,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 46421,
    id: 1036063031,
  },
  {
    city: "Palmwoods",
    city_ascii: "Palmwoods",
    lat: -26.6883,
    lng: 152.9597,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5676,
    id: 1036016511,
  },
  {
    city: "Pannawonica",
    city_ascii: "Pannawonica",
    lat: -21.635,
    lng: 116.336,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 695,
    id: 1036914573,
  },
  {
    city: "Parkes",
    city_ascii: "Parkes",
    lat: -33.133,
    lng: 148.176,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 11408,
    id: 1036977270,
  },
  {
    city: "Penola",
    city_ascii: "Penola",
    lat: -37.3786,
    lng: 140.8362,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1592,
    id: 1036121853,
  },
  {
    city: "Penrith",
    city_ascii: "Penrith",
    lat: -33.7511,
    lng: 150.6942,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 13295,
    id: 1036796452,
  },
  {
    city: "Perth",
    city_ascii: "Perth",
    lat: -31.9522,
    lng: 115.8589,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "admin",
    population: 2039200,
    id: 1036178956,
  },
  {
    city: "Peterborough",
    city_ascii: "Peterborough",
    lat: -32.9667,
    lng: 138.8333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1497,
    id: 1036104272,
  },
  {
    city: "Pine Creek",
    city_ascii: "Pine Creek",
    lat: -13.8231,
    lng: 131.833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 328,
    id: 1036858004,
  },
  {
    city: "Point Vernon",
    city_ascii: "Point Vernon",
    lat: -25.2538,
    lng: 152.8177,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5699,
    id: 1036205903,
  },
  {
    city: "Port Augusta",
    city_ascii: "Port Augusta",
    lat: -32.4925,
    lng: 137.7658,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 6562,
    id: 1036945368,
  },
  {
    city: "Port Denison",
    city_ascii: "Port Denison",
    lat: -29.2833,
    lng: 114.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 1410,
    id: 1036873405,
  },
  {
    city: "Port Douglas",
    city_ascii: "Port Douglas",
    lat: -16.4834,
    lng: 145.4652,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 3504,
    id: 1036053723,
  },
  {
    city: "Port Hedland",
    city_ascii: "Port Hedland",
    lat: -20.31,
    lng: 118.6011,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 4180,
    id: 1036509606,
  },
  {
    city: "Port Lincoln",
    city_ascii: "Port Lincoln",
    lat: -34.7322,
    lng: 135.8586,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 14120,
    id: 1036558150,
  },
  {
    city: "Port Macquarie",
    city_ascii: "Port Macquarie",
    lat: -31.4333,
    lng: 152.9,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 44830,
    id: 1036051032,
  },
  {
    city: "Port Pirie",
    city_ascii: "Port Pirie",
    lat: -33.1858,
    lng: 138.0169,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 195,
    id: 1036571606,
  },
  {
    city: "Portland",
    city_ascii: "Portland",
    lat: -38.3333,
    lng: 141.6,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9712,
    id: 1036439594,
  },
  {
    city: "Pottsville",
    city_ascii: "Pottsville",
    lat: -28.3869,
    lng: 153.5608,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6704,
    id: 1036001080,
  },
  {
    city: "Proserpine",
    city_ascii: "Proserpine",
    lat: -20.4017,
    lng: 148.5814,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 3562,
    id: 1036150859,
  },
  {
    city: "Quakers Hill",
    city_ascii: "Quakers Hill",
    lat: -33.7344,
    lng: 150.8789,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 27080,
    id: 1036046868,
  },
  {
    city: "Queanbeyan",
    city_ascii: "Queanbeyan",
    lat: -35.3533,
    lng: 149.2342,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6237,
    id: 1036846465,
  },
  {
    city: "Queenstown",
    city_ascii: "Queenstown",
    lat: -42.0667,
    lng: 145.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 1790,
    id: 1036082142,
  },
  {
    city: "Quilpie",
    city_ascii: "Quilpie",
    lat: -26.6161,
    lng: 144.2675,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 564,
    id: 1036696232,
  },
  {
    city: "Ravensthorpe",
    city_ascii: "Ravensthorpe",
    lat: -33.5831,
    lng: 120.049,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 498,
    id: 1036878618,
  },
  {
    city: "Raymond Terrace",
    city_ascii: "Raymond Terrace",
    lat: -32.7615,
    lng: 151.7441,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 12820,
    id: 1036570825,
  },
  {
    city: "Red Cliffs",
    city_ascii: "Red Cliffs",
    lat: -34.3075,
    lng: 142.1881,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5060,
    id: 1036463286,
  },
  {
    city: "Redlynch",
    city_ascii: "Redlynch",
    lat: -16.8833,
    lng: 145.7,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 8645,
    id: 1036596124,
  },
  {
    city: "Richmond",
    city_ascii: "Richmond",
    lat: -33.5983,
    lng: 150.7511,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5482,
    id: 1036934091,
  },
  {
    city: "Richmond",
    city_ascii: "Richmond",
    lat: -20.7305,
    lng: 143.1425,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 648,
    id: 1036156498,
  },
  {
    city: "Rochedale",
    city_ascii: "Rochedale",
    lat: -27.6,
    lng: 153.133,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 15317,
    id: 1036018437,
  },
  {
    city: "Rockhampton",
    city_ascii: "Rockhampton",
    lat: -23.375,
    lng: 150.5117,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 1953,
    id: 1036768986,
  },
  {
    city: "Roebourne",
    city_ascii: "Roebourne",
    lat: -20.7667,
    lng: 117.15,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 981,
    id: 1036752479,
  },
  {
    city: "Roma",
    city_ascii: "Roma",
    lat: -26.5733,
    lng: 148.7869,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6848,
    id: 1036698836,
  },
  {
    city: "Rutherford",
    city_ascii: "Rutherford",
    lat: -32.715,
    lng: 151.5317,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 11884,
    id: 1036471917,
  },
  {
    city: "Rye",
    city_ascii: "Rye",
    lat: -38.377,
    lng: 144.838,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 8416,
    id: 1036066082,
  },
  {
    city: "Sale",
    city_ascii: "Sale",
    lat: -38.1,
    lng: 147.0667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 13673,
    id: 1036960753,
  },
  {
    city: "Sanctuary Point",
    city_ascii: "Sanctuary Point",
    lat: -35.1036,
    lng: 150.6267,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 7225,
    id: 1036336388,
  },
  {
    city: "Sarina",
    city_ascii: "Sarina",
    lat: -21.4225,
    lng: 149.2175,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5522,
    id: 1036990230,
  },
  {
    city: "Scone",
    city_ascii: "Scone",
    lat: -32.0483,
    lng: 150.8678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5624,
    id: 1036918224,
  },
  {
    city: "Scottsdale",
    city_ascii: "Scottsdale",
    lat: -41.1667,
    lng: 147.5167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 2373,
    id: 1036807079,
  },
  {
    city: "Seymour",
    city_ascii: "Seymour",
    lat: -37.03,
    lng: 145.13,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 6327,
    id: 1036919223,
  },
  {
    city: "Shepparton",
    city_ascii: "Shepparton",
    lat: -36.3833,
    lng: 145.4,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 31197,
    id: 1036030548,
  },
  {
    city: "Singleton",
    city_ascii: "Singleton",
    lat: -32.5667,
    lng: 151.1697,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5000,
    id: 1036947514,
  },
  {
    city: "Smithfield Heights",
    city_ascii: "Smithfield Heights",
    lat: -16.8314,
    lng: 145.691,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5303,
    id: 1036797461,
  },
  {
    city: "Smithton",
    city_ascii: "Smithton",
    lat: -40.844,
    lng: 145.12,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 3881,
    id: 1036207188,
  },
  {
    city: "Somerville",
    city_ascii: "Somerville",
    lat: -38.226,
    lng: 145.177,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 11336,
    id: 1036275983,
  },
  {
    city: "South Grafton",
    city_ascii: "South Grafton",
    lat: -29.715,
    lng: 152.9344,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6068,
    id: 1036377966,
  },
  {
    city: "Southern Cross",
    city_ascii: "Southern Cross",
    lat: -31.25,
    lng: 119.35,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 680,
    id: 1036931490,
  },
  {
    city: "Southport",
    city_ascii: "Southport",
    lat: -27.9667,
    lng: 153.4,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 31908,
    id: 1036417513,
  },
  {
    city: "Springwood",
    city_ascii: "Springwood",
    lat: -33.7036,
    lng: 150.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8475,
    id: 1036106857,
  },
  {
    city: "Stawell",
    city_ascii: "Stawell",
    lat: -37.05,
    lng: 142.7667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5736,
    id: 1036073093,
  },
  {
    city: "Strathalbyn",
    city_ascii: "Strathalbyn",
    lat: -35.2667,
    lng: 138.9,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 6504,
    id: 1036708974,
  },
  {
    city: "Streaky Bay",
    city_ascii: "Streaky Bay",
    lat: -32.8,
    lng: 134.2167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1378,
    id: 1036134944,
  },
  {
    city: "Sunbury",
    city_ascii: "Sunbury",
    lat: -37.5811,
    lng: 144.7139,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 36084,
    id: 1036519781,
  },
  {
    city: "Swan Hill",
    city_ascii: "Swan Hill",
    lat: -35.3333,
    lng: 143.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9864,
    id: 1036220501,
  },
  {
    city: "Sydney",
    city_ascii: "Sydney",
    lat: -33.865,
    lng: 151.2094,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "admin",
    population: 4840600,
    id: 1036074917,
  },
  {
    city: "Tahmoor",
    city_ascii: "Tahmoor",
    lat: -34.2233,
    lng: 150.5928,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5067,
    id: 1036345521,
  },
  {
    city: "Tamworth",
    city_ascii: "Tamworth",
    lat: -31.0833,
    lng: 150.9167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 180,
    id: 1036233388,
  },
  {
    city: "Tannum Sands",
    city_ascii: "Tannum Sands",
    lat: -23.9474,
    lng: 151.3675,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 5145,
    id: 1036139879,
  },
  {
    city: "Taree",
    city_ascii: "Taree",
    lat: -31.9,
    lng: 152.45,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 16197,
    id: 1036855717,
  },
  {
    city: "Thargomindah",
    city_ascii: "Thargomindah",
    lat: -28,
    lng: 143.8167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 270,
    id: 1036793708,
  },
  {
    city: "Theodore",
    city_ascii: "Theodore",
    lat: -24.95,
    lng: 150.0833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 438,
    id: 1036423364,
  },
  {
    city: "Three Springs",
    city_ascii: "Three Springs",
    lat: -29.5333,
    lng: 115.717,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 381,
    id: 1036669084,
  },
  {
    city: "Tom Price",
    city_ascii: "Tom Price",
    lat: -22.6939,
    lng: 117.795,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 3005,
    id: 1036464109,
  },
  {
    city: "Toowoomba",
    city_ascii: "Toowoomba",
    lat: -27.5667,
    lng: 151.95,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 135631,
    id: 1036765315,
  },
  {
    city: "Torquay",
    city_ascii: "Torquay",
    lat: -38.3333,
    lng: 144.3167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 13258,
    id: 1036765457,
  },
  {
    city: "Townsville",
    city_ascii: "Townsville",
    lat: -19.25,
    lng: 146.8167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 178649,
    id: 1036500020,
  },
  {
    city: "Traralgon",
    city_ascii: "Traralgon",
    lat: -38.1958,
    lng: 146.5403,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 24933,
    id: 1036938861,
  },
  {
    city: "Tumby Bay",
    city_ascii: "Tumby Bay",
    lat: -34.3667,
    lng: 136.1,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 1631,
    id: 1036529327,
  },
  {
    city: "Tumut",
    city_ascii: "Tumut",
    lat: -35.3039,
    lng: 148.2233,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6230,
    id: 1036777587,
  },
  {
    city: "Tuncurry",
    city_ascii: "Tuncurry",
    lat: -32.175,
    lng: 152.4989,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6186,
    id: 1036846186,
  },
  {
    city: "Tweed Heads",
    city_ascii: "Tweed Heads",
    lat: -28.1833,
    lng: 153.55,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 8176,
    id: 1036571941,
  },
  {
    city: "Ulladulla",
    city_ascii: "Ulladulla",
    lat: -35.3486,
    lng: 150.4678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 15278,
    id: 1036687220,
  },
  {
    city: "Ulverstone",
    city_ascii: "Ulverstone",
    lat: -41.1667,
    lng: 146.1667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 14109,
    id: 1036014544,
  },
  {
    city: "Urraween",
    city_ascii: "Urraween",
    lat: -25.2955,
    lng: 152.8219,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6969,
    id: 1036945468,
  },
  {
    city: "Victor Harbor",
    city_ascii: "Victor Harbor",
    lat: -35.55,
    lng: 138.6167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 4233,
    id: 1036536547,
  },
  {
    city: "Wagga Wagga",
    city_ascii: "Wagga Wagga",
    lat: -35.1189,
    lng: 147.3689,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 67364,
    id: 1036273188,
  },
  {
    city: "Wagin",
    city_ascii: "Wagin",
    lat: -33.3167,
    lng: 117.35,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 1495,
    id: 1036851354,
  },
  {
    city: "Wallan",
    city_ascii: "Wallan",
    lat: -37.4167,
    lng: 144.9833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 11074,
    id: 1036357414,
  },
  {
    city: "Wallaroo",
    city_ascii: "Wallaroo",
    lat: -33.9167,
    lng: 137.6167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 3481,
    id: 1036743246,
  },
  {
    city: "Wangaratta",
    city_ascii: "Wangaratta",
    lat: -36.3583,
    lng: 146.3125,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 17377,
    id: 1036395326,
  },
  {
    city: "Warragul",
    city_ascii: "Warragul",
    lat: -38.15,
    lng: 145.9333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 15757,
    id: 1036504084,
  },
  {
    city: "Warrnambool",
    city_ascii: "Warrnambool",
    lat: -38.3833,
    lng: 142.4833,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 29661,
    id: 1036291219,
  },
  {
    city: "Warwick",
    city_ascii: "Warwick",
    lat: -28.2152,
    lng: 152.0352,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 12222,
    id: 1036412726,
  },
  {
    city: "Waterford",
    city_ascii: "Waterford",
    lat: -27.6906,
    lng: 153.1332,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6431,
    id: 1036727537,
  },
  {
    city: "Wauchope",
    city_ascii: "Wauchope",
    lat: -31.45,
    lng: 152.7333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6312,
    id: 1036327296,
  },
  {
    city: "Weipa",
    city_ascii: "Weipa",
    lat: -12.6167,
    lng: 141.8667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 3291,
    id: 1036501067,
  },
  {
    city: "Wentworth Falls",
    city_ascii: "Wentworth Falls",
    lat: -33.7164,
    lng: 150.3772,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6076,
    id: 1036272828,
  },
  {
    city: "Whittlesea",
    city_ascii: "Whittlesea",
    lat: -37.5139,
    lng: 145.114,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5611,
    id: 1036742059,
  },
  {
    city: "Whyalla",
    city_ascii: "Whyalla",
    lat: -33.0333,
    lng: 137.5667,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 21501,
    id: 1036961366,
  },
  {
    city: "Wilcannia",
    city_ascii: "Wilcannia",
    lat: -31.565,
    lng: 143.3678,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 745,
    id: 1036134474,
  },
  {
    city: "Windorah",
    city_ascii: "Windorah",
    lat: -25.4206,
    lng: 142.6547,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 115,
    id: 1036272226,
  },
  {
    city: "Wingham",
    city_ascii: "Wingham",
    lat: -31.85,
    lng: 152.367,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5374,
    id: 1036745521,
  },
  {
    city: "Winton",
    city_ascii: "Winton",
    lat: -22.3913,
    lng: 143.0381,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 875,
    id: 1036031036,
  },
  {
    city: "Wodonga",
    city_ascii: "Wodonga",
    lat: -36.1214,
    lng: 146.8881,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 18948,
    id: 1036788540,
  },
  {
    city: "Wollert",
    city_ascii: "Wollert",
    lat: -37.5833,
    lng: 145.0333,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 9060,
    id: 1036068932,
  },
  {
    city: "Wollongong",
    city_ascii: "Wollongong",
    lat: -34.4331,
    lng: 150.8831,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 261896,
    id: 1036502269,
  },
  {
    city: "Wonthaggi",
    city_ascii: "Wonthaggi",
    lat: -38.6056,
    lng: 145.5917,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 4965,
    id: 1036415078,
  },
  {
    city: "Woodend",
    city_ascii: "Woodend",
    lat: -37.363,
    lng: 144.526,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 5806,
    id: 1036376029,
  },
  {
    city: "Woolgoolga",
    city_ascii: "Woolgoolga",
    lat: -30.115,
    lng: 153.2011,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5290,
    id: 1036143859,
  },
  {
    city: "Woomera",
    city_ascii: "Woomera",
    lat: -31.1496,
    lng: 136.8,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "South Australia",
    capital: "",
    population: 450,
    id: 1036392822,
  },
  {
    city: "Worragee",
    city_ascii: "Worragee",
    lat: -34.9144,
    lng: 150.6386,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 5068,
    id: 1036339460,
  },
  {
    city: "Wyndham",
    city_ascii: "Wyndham",
    lat: -15.4825,
    lng: 128.123,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 780,
    id: 1036474435,
  },
  {
    city: "Wynyard",
    city_ascii: "Wynyard",
    lat: -41,
    lng: 145.7167,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Tasmania",
    capital: "",
    population: 6001,
    id: 1036260821,
  },
  {
    city: "Yamba",
    city_ascii: "Yamba",
    lat: -29.44,
    lng: 153.3594,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6076,
    id: 1036259324,
  },
  {
    city: "Yanchep",
    city_ascii: "Yanchep",
    lat: -31.55,
    lng: 115.634,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Western Australia",
    capital: "",
    population: 8859,
    id: 1036796651,
  },
  {
    city: "Yarrawonga",
    city_ascii: "Yarrawonga",
    lat: -36.0167,
    lng: 146,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Victoria",
    capital: "",
    population: 7930,
    id: 1036458763,
  },
  {
    city: "Yass",
    city_ascii: "Yass",
    lat: -34.8203,
    lng: 148.9136,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 6506,
    id: 1036799517,
  },
  {
    city: "Yeppoon",
    city_ascii: "Yeppoon",
    lat: -23.1288,
    lng: 150.7444,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Queensland",
    capital: "",
    population: 6334,
    id: 1036180170,
  },
  {
    city: "Young",
    city_ascii: "Young",
    lat: -34.3,
    lng: 148.3,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "New South Wales",
    capital: "",
    population: 10295,
    id: 1036073044,
  },
  {
    city: "Yulara",
    city_ascii: "Yulara",
    lat: -25.2406,
    lng: 130.9889,
    country: "Australia",
    iso2: "AU",
    iso3: "AUS",
    admin_name: "Northern Territory",
    capital: "",
    population: 1099,
    id: 1036245584,
  },
];

export const ausStates = [
  { value: "ACT", name: "Australian Capital Territory" },
  { value: "NSW", name: "New South Wales" },
  { value: "NT ", name: "Northern Territory" },
  { value: "QLD", name: "Queensland" },
  { value: "SA ", name: "South Australia" },
  { value: "TAS", name: "Tasmania" },
  { value: "VIC", name: "Victoria" },
  { value: "WA ", name: "Western Australia" }
]

export default ausCities
