import React, { useContext, useState, useRef } from "react";

//package import

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Box,
  Container,
  Grid,
  TextField,
  MenuItem,
  Button,
  Backdrop,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

//user import

// import { userRoutes } from "../../services/routes/APP";
import { userRoutes } from "app/Services/user/routes/APP";
import { MenuProps } from "app/common/user/constants";
import { MONTHS_ARRAY } from "app/common/user/Enums";
import { addvalidation } from "app/Services/user/validations/card";
// import { context } from "../../context";
// import { headersPost } from "../../services/API";
import { headersPost } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { context } from "app/context";

//constants

const initialValues = {
  name_as_per_card: "",
  card_number: "",
  expiry_month: "",
  expiry_year: "",
  cvv: "",
  billing_address: "",
  currency: "aud",
  description: "one time payment",
};

// function cc_format(value) {
//   const v = value
//     .replace(/\s+/g, '')
//     .replace(/[^0-9]/gi, '')
//     .substr(0, 16);
//   const parts = [];

//   for (let i = 0; i < v.length; i += 4) {
//     parts.push(v.substr(i, 4));
//   }

//   return parts.length > 1 ? parts.join(' ') : value;
// }

const formatCardNumber = (value) => {
  const cleaned = value.replace(/\D+/g, "");
  const match = cleaned.match(/.{1,4}/g);
  const formatted = match ? match.join(" ") : "";
  return formatted;
};

//component

const CardModal = ({
  handleClose,
  open,
  isReload,
  donationHandleState,
  paymentMethodState,
  fromvalues,
  orgId = "",
}) => {
  const cardNumRef = useRef(null);
  //hooks

  const navigate = useNavigate();
  const { user } = useContext(context);

  const {
    submitHandler: handleDonation,
    formikValsTemp,
    isRoundupAction,
    addRoundUpHandler,
    markCardDefault,
  } = donationHandleState || {};

  const { setData, cardData, setCardData, data } = paymentMethodState || {};
  const paymentData = data || [];

  //states

  const [loader, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  const [cardNumber, setCardNumber] = useState("");

  const handleCardFormat = (e, setFieldValue) => {
    const formattedValue = formatCardNumber(e.target.value);
    setFieldValue("card_number", formattedValue);
  };

  //form submit handler

  const submitHandler = async (values) => {
    const { _id: u_id, id: uid } = user || {};
    const data = {
      name_as_per_card: values?.name_as_per_card,
      card_number: values?.card_number.replace(/\s+/g, ""),
      exp_month: values?.expiry_month,
      exp_year: `${values?.expiry_year}`,
      cvv: values?.cvv,
      billing_address: values?.billing_address,
      addedByUser: u_id || uid,
      organisation: orgId,
    };

    setOpen(true);

    try {
      const res = await headersPost(API_URLS?.addCardDetail, data);
      console.log("🚀 ~ submitHandler ~ res:------", res);
      setOpen(false);
      if (res?.status === 201 || 200) {
        if (!isReload) {
          const newCardId = res?.data?.saveCard?._id || res?.data?.saveCard?.id;
          // Mark the card as default
          const markDefaultRes = await markCardDefault(newCardId);
          console.log(
            "🚀 ~ submitHandler ~ markDefaultRes:----",
            markDefaultRes
          );
          // 6:49 15aug add the pp_fees in the object
          const updatedValues = {
            ...values,
            pp_fees: fromvalues?.pp_fees,
            amount: fromvalues?.amount,
          };
          const reee = await handleDonation(updatedValues);
          console.log("🚀 ~ submitHandler ~ reee-----:", reee);

          if (markDefaultRes?.status !== 201) {
            throw new Error("Failed to mark the card as default");
          }
        }

        setAlertOpen(false);
        setAlertMsg(res?.data?.message);
        setAlertIcon("success");

        // working one

        // handleClose();

        // if (!isReload && paymentData?.length === 0) {
        //   if (isRoundupAction) {
        //     await addRoundUpHandler(formikValsTemp);
        //   } else {
        //     await handleDonation(values);
        //   }
        // } else {
        //   await handleDonation(values);
        // }

        setTimeout(async () => {
          handleClose();
          if (!isReload && paymentData?.length === 0) {
            if (isRoundupAction === true) {
              console.log("🚀 ~ setTimeout ~ values:if", formikValsTemp);
              await addRoundUpHandler(formikValsTemp);
            } else {
              await handleDonation(values);
              console.log("🚀 ~ setTimeout ~ values:else", values);
            }
          }
          isReload && window.location.reload();
        }, 2000);

        // if (isReload) {
        //   window.location.reload();
        // }

        setAddress("");
        setCardData((prevCardData) => [...prevCardData, res?.data?.saveCard]);
      } else {
        setAlertOpen(true);
        setAlertMsg(res?.data?.message);
        setAlertIcon("warning");
        console.log("🚀 ~ submitHandler ~ res:------else part", res);
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.clear();
        navigate(userRoutes?.root);
      }
      setOpen(false);
      setAlertOpen(true);
      setAlertMsg(response?.data?.message);
      setAlertIcon("error");
    }
  };

  //aleert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const [address, setAddress] = useState("");
  const handleSelect = async (value, setFieldValue) => {
    setAddress(value);
    setFieldValue("billing_address", value);
    console.log({ value, setFieldValue });
  };
  const handleAutoCompleteChange = (value, setFieldValue) => {
    setAddress(value);
    setFieldValue("billing_address", value);
    console.log({ value, setFieldValue });
  };

  // const handleCardFormat = (e, setFieldValue) => {
  //   console.log({ cardVal: cc_format(e.target.value) });
  //   setFieldValue('card_number', cc_format(e.target.value));
  // };

  /*global google*/

  const searchOptions = {
    componentRestrictions: { country: ["us", "au"] },
    types: ["address"],
  };

  //render

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontWeight={"bold"} align="center">
          Add Credit/Debit Card
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "20px 0px" }}>
        <Box style={{ padding: { xs: 0, sm: 0, md: 2, lg: 2 } }}>
          <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={addvalidation}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Container>
                  <Grid container alignItems={"stretch"}>
                    <Grid item xs={6} sm={6} md={4}>
                      <Box
                        display={"flex"}
                        alignItems={{
                          xs: "normal",
                          sm: "normal",
                          md: "center",
                          lg: "center",
                        }}
                        height={{
                          xs: "0",
                          sm: "0",
                          md: "100%",
                          lg: "100%",
                        }}
                        justifyContent={{
                          xs: "normal",
                          sm: "normal",
                          md: "flex-end",
                          lg: "flex-end",
                        }}
                      >
                        <Box>
                          <Typography
                            align="right"
                            fontWeight={"bold"}
                            variant="caption"
                            sx={{
                              padding: 1,
                              textTransform: "uppercase",
                            }}
                          >
                            Name as per card
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box paddingY={1}>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          placeholder="Enter Your Name on as per card"
                          name="name_as_per_card"
                          value={values?.name_as_per_card}
                          onChange={handleChange}
                          helperText={
                            touched.name_as_per_card && errors.name_as_per_card
                          }
                          error={
                            touched.name_as_per_card &&
                            Boolean(errors.name_as_per_card)
                          }
                        ></TextField>
                      </Box>
                    </Grid>
                    <Grid item xs={7} sm={7} md={4}>
                      <Box
                        display={"flex"}
                        alignItems={{
                          xs: "normal",
                          sm: "normal",
                          md: "center",
                          lg: "center",
                        }}
                        height={{
                          xs: "0",
                          sm: "0",
                          md: "100%",
                          lg: "100%",
                        }}
                        justifyContent={{
                          xs: "normal",
                          sm: "normal",
                          md: "flex-end",
                          lg: "flex-end",
                        }}
                      >
                        <Box>
                          <Typography
                            align="right"
                            fontWeight={"bold"}
                            variant="caption"
                            sx={{
                              padding: 1,
                              textTransform: "uppercase",
                            }}
                          >
                            CARD NUMBER
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box paddingY={1}>
                        <TextField
                          fullWidth
                          type={"text"}
                          variant={"outlined"}
                          placeholder="Enter Your card number"
                          // ref={cardNumRef}
                          name="card_number"
                          value={values?.card_number}
                          // value={cardFormat}
                          // onChange={handleChange}
                          onChange={(e) => handleCardFormat(e, setFieldValue)}
                          // onChange={(e) => {
                          //   handleChange(e);
                          //   const formattedValue = formatCardNumber(e.target.value);
                          //   setFieldValue('card_number', formattedValue);
                          // }}
                          // onChange={(e)=>handleCardFormat(e,setFieldValue)}
                          helperText={touched.card_number && errors.card_number}
                          error={
                            touched.card_number && Boolean(errors.card_number)
                          }
                        ></TextField>
                        {/* <input type="text" value={values?.card_number} /> */}
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <Box
                        display={"flex"}
                        alignItems={{
                          xs: "normal",
                          sm: "normal",
                          md: "center",
                          lg: "center",
                        }}
                        height={{
                          xs: "0",
                          sm: "0",
                          md: "100%",
                          lg: "100%",
                        }}
                        justifyContent={{
                          xs: "normal",
                          sm: "normal",
                          md: "flex-end",
                          lg: "flex-end",
                        }}
                      >
                        <Box>
                          <Typography
                            align="right"
                            fontWeight={"bold"}
                            variant="caption"
                            sx={{
                              padding: 1,
                              textTransform: "uppercase",
                            }}
                          >
                            Expiry date
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box paddingY={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={6} md={4}>
                            <TextField
                              fullWidth
                              select
                              SelectProps={{
                                displayEmpty: true,
                                MenuProps: MenuProps,
                              }}
                              name="expiry_month"
                              value={values?.expiry_month}
                              onChange={handleChange}
                              helperText={
                                touched.expiry_month && errors.expiry_month
                              }
                              error={
                                touched.expiry_month &&
                                Boolean(errors.expiry_month)
                              }
                            >
                              <MenuItem value={""} disabled>
                                Month
                              </MenuItem>
                              {MONTHS_ARRAY.map((item, index) => (
                                <MenuItem
                                  value={item.id}
                                  key={`${index}-month-array-${item.id}-${item.name}`}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4}>
                            <TextField
                              fullWidth
                              select
                              SelectProps={{
                                displayEmpty: true,
                                MenuProps: MenuProps,
                              }}
                              type={"text"}
                              name="expiry_year"
                              value={values?.expiry_year}
                              onChange={handleChange}
                              helperText={
                                touched.expiry_year && errors.expiry_year
                              }
                              error={
                                touched.expiry_year &&
                                Boolean(errors.expiry_year)
                              }
                            >
                              <MenuItem value={""} disabled>
                                Expiry year
                              </MenuItem>
                              {[...Array(50)].map((item, index) => (
                                <MenuItem
                                  value={new Date().getFullYear() + index}
                                >
                                  {new Date().getFullYear() + index}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <Box
                        display={"flex"}
                        alignItems={{
                          xs: "normal",
                          sm: "normal",
                          md: "center",
                          lg: "center",
                        }}
                        height={{
                          xs: "0",
                          sm: "0",
                          md: "100%",
                          lg: "100%",
                        }}
                        justifyContent={{
                          xs: "normal",
                          sm: "normal",
                          md: "flex-end",
                          lg: "flex-end",
                        }}
                      >
                        <Box>
                          <Typography
                            align="right"
                            fontWeight={"bold"}
                            variant="caption"
                            sx={{
                              padding: 1,
                              textTransform: "uppercase",
                            }}
                          >
                            CVV
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      <Box paddingY={1}>
                        <TextField
                          placeholder="cvv"
                          fullWidth
                          name="cvv"
                          type={"number"}
                          value={values?.cvv}
                          onChange={handleChange}
                          helperText={touched.cvv && errors.cvv}
                          error={touched.cvv && Boolean(errors.cvv)}
                        ></TextField>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      display={{
                        xs: "block",
                        sm: "block",
                        md: "flex",
                        lg: "flex",
                      }}
                      sx={{
                        alignItems: "start",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={{ paddingTop: "25px" }}
                      >
                        <Box
                          display={"flex"}
                          alignItems={{
                            xs: "normal",
                            sm: "normal",
                            md: "center",
                            lg: "center",
                          }}
                          height={{
                            xs: "0",
                            sm: "0",
                            md: "100%",
                            lg: "100%",
                          }}
                          justifyContent={{
                            xs: "normal",
                            sm: "normal",
                            md: "flex-end",
                            lg: "flex-end",
                          }}
                        >
                          <Box>
                            <Typography
                              align="right"
                              fontWeight={"bold"}
                              variant="caption"
                              sx={{
                                padding: 1,
                                textTransform: "uppercase",
                              }}
                            >
                              billing address
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <Box paddingY={1}>
                          <PlacesAutocomplete
                            value={address}
                            searchOptions={searchOptions}
                            onChange={(e) =>
                              handleAutoCompleteChange(e, setFieldValue)
                            }
                            onSelect={(e) => handleSelect(e, setFieldValue)}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <TextField
                                  // multiline
                                  // rows={3}
                                  fullWidth
                                  placeholder="please enter you billing address"
                                  name="billing_address"
                                  value={values?.billing_address}
                                  onChange={handleChange}
                                  {...getInputProps()}
                                  helperText={
                                    touched.billing_address &&
                                    errors.billing_address
                                  }
                                  error={
                                    touched.billing_address &&
                                    Boolean(errors.billing_address)
                                  }
                                ></TextField>
                                {/* <input {... getInputProps({placeholder: "Type Address"})}/> */}
                                <div>
                                  {loading ? <div>...loading</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#66c9d2"
                                        : "#fff",
                                      zIndex: 1111111,
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"center"} paddingY={3}>
                    <Box>
                      <Button variant="contained" type="submit">
                        SAVE
                      </Button>
                    </Box>
                  </Box>
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default CardModal;
