import React, { useState, useEffect } from "react";

//package imports

import { BsThreeDots } from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentAlt } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";

//user import

import { renderFeedVideoOrImage } from "app/utils/organization/Functions";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import {
  ReactionBarSelector,
  ReactionCounter,
  FacebookSelector,
  FacebookCounter,
  FacebookCounterReaction,
  FacebookSelectorEmoji,
} from "@charkour/react-reactions";
// import {API_URLS} from '../../Routes/API'
import { API_URLS } from "app/Services/organization/Routes/API";
import { simpleGet } from "app/Services/organization/API";
import { getReactionImage } from "app/utils/organization/reactions";

import "./feedCard.css";
//component

const convertInteractionsToCounter = (interactionsData) => {
  let counter = 0;
  const newArr = [];
  if (interactionsData.length > 0) {
    for (let i = 0; i < interactionsData.length; i++) {
      const emojiObj = {
        emoji: interactionsData[i].interaction, // String name of reaction
        by: interactionsData[i]?.user?.name, // String of persons name
        profile_pic: interactionsData[i]?.user?.profile_pic,
      };
      newArr.push(emojiObj);
      counter++;
    }
  }
  return newArr;
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 600, lg: 600 },
  height: { xs: 350, sm: 400, md: 500, lg: 600 },
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const FeedCard = ({ feed, editHandler, delHandler }) => {
  //render
  const { id: feedId } = feed || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isExpanded, setIsExpanded] = React.useState(false);

  const executeOnClick = () => {
    setIsExpanded(true);
  };

  const matches = useMediaQuery("(min-width:701px)");
  const matches1 = useMediaQuery("(max-width:700px)");

  const formatDateTime = (date) => {
    return moment(date).format("DD MMM yy hh:mm A");
  };

  const [showSocialInteractions, setShowSocialInteractions] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [myInteraction, setMyInteraction] = useState(null);
  const [interactionsEmojis, setInteractionsEmojis] = useState([]);
  const [showInteractionsModal, setShowInteractionsModal] = useState(false);

  const handleCounterClick = () => {
    setShowInteractionsModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (feedId) {
        try {
          const response = await simpleGet(
            `${API_URLS?.getAllInteractionsByFeed}/${feedId}`
          );
          if (response?.status === 200 || response?.status === 201) {
            setInteractions(response?.data?.interactions || []);
          }
        } catch (error) {
          const { response } = error || {};
          const { data } = response || {};
          const { message } = data || {};
          // Handle error here, e.g., setting state or displaying a message
          // setAlertOpen(true);
          // setAlertMsg(message || 'Error found');
          // setAlertIcon('error');
        }
      }
    };
    fetchData();
  }, [feed]);

  useEffect(() => {
    setInteractionsEmojis(convertInteractionsToCounter(interactions));
  }, [interactions]);

  return (
    <div className="feed-box mb-3">
      <div className="options">
        <div className="organisation-info">
          <div className="organisation-data">
            <div className="feed-logo-div">
              <img
                src={
                  feed?.organisation?.organisation_image?.[0] ||
                  "/images/organ-logo.svg"
                }
                alt="organisation-logo"
                width="100%"
                style={{
                  border: "3px solid #FF9776",
                  borderRadius: "100%",
                  height: "65px",
                }}
              />
              {/* <img src={"/images/organ-logo.svg"} alt="organisation-logo" /> */}
            </div>

            <div className="organisation-name">
              <p className="main-name">{feed?.organisation_name}</p>
              <Typography
                variant="body2"
                textAlign={"justify"}
                fontWeight={500}
                textTransform={"capitalize"}
              >
                {feed?.organisation?.category}
              </Typography>

              <p className="main-cause">
                {/* <ShowMoreText
                  lines={2}
                  more="see more"
                  less="show less"
                  onClick={executeOnClick}
                  expanded={false}
                  width={770}
                  truncatedEndingComponent={"... "}
                >
                  {feed?.title}
                </ShowMoreText> */}
                {formatDateTime(feed?.createdAt)}
              </p>

              {/* <p className="main-cause">{feed?.title}</p> */}
              {/* <p className="main-time">{feed?.time}</p> */}
            </div>
            {matches1 && (
              <div className="option-view">
                <BsThreeDots onClick={handleClick} />
              </div>
            )}
          </div>
        </div>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ marginLeft: "-27px" }}
        >
          <MenuItem onClick={handleClose}>
            <Box
              sx={{
                textDecoration: "underline",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              mr={1}
              onClick={() => editHandler(feed)}
            >
              Edit
            </Box>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <Box
              sx={{
                textDecoration: "underline",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => delHandler(feed?.id)}
            >
              Delete
            </Box>
          </MenuItem>
        </Menu>
      </div>
      {/* <div className="feed-description">
        
          {feed?.title}
       
      </div>
      <div className="feed-description">
        <ShowMoreText
          lines={1}
          more="see more"
          less="show less"
          onClick={executeOnClick}
          expanded={false}
          width={1700}
          truncatedEndingComponent={"... "}
          
        >
          {feed?.description}
        </ShowMoreText>
      </div>
      <div className="feed-description">
        
          {feed?.hashtag}
        
      </div> */}
      <Box paddingBottom={0} marginTop={3}>
        <Typography variant="body2" textAlign={"justify"} fontWeight={"bold"}>
          {feed?.title}
        </Typography>
        <Typography variant="body2" textAlign={"justify"}>
          {feed?.description}
        </Typography>
        <Typography variant="body2" textAlign={"justify"} fontStyle={"italic"}>
          {feed?.hashtag}
        </Typography>
      </Box>
      {feed?.image[0] ? (
        renderFeedVideoOrImage(feed?.image[0])
      ) : (
        <video
          src={"/images/videoplayback.mp4"}
          width="100%"
          height="400"
          controls
          className="my-4"
        ></video>
      )}

      <div style={{ margin: "10px" }}>
        <FacebookCounter
          counters={interactionsEmojis}
          user={true}
          onClick={handleCounterClick}
        />
      </div>

      <div className="likes-comments">
        {/* <div className="buttons">
          <button className="btn btn-darkBlue like-btn">
            <AiOutlineLike className="like-icon" />
            {matches && <>&nbsp; Like &nbsp;</>}{" "}
            <span className="likes-count">{feed.likes}</span>
          </button>
          <button className="btn btn-darkBlue comment-btn">
            <FaRegCommentAlt className="comment-icon" />{" "}
            {matches && <>&nbsp; Comment &nbsp;</>}
            <span className="comment-count">{feed.comment}</span>
          </button>
          <button className="btn btn-darkBlue share-btn">
            <RiShareForwardLine className="share-icon" />{" "}
            {matches && <>&nbsp; Share &nbsp;</>}
            <span className="share-count">{feed.share}</span>
          </button>
        </div> */}
        {matches && (
          <div className="FviewEdit">
            <Box
              sx={{
                textDecoration: "underline",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              mr={1}
              onClick={() => editHandler(feed)}
            >
              Edit
            </Box>
            <Box
              sx={{
                textDecoration: "underline",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => delHandler(feed?.id)}
            >
              Delete
            </Box>
          </div>
        )}
      </div>
      <Modal
        open={showInteractionsModal}
        onClose={() => setShowInteractionsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Box textAlign={"right"}>
            <IconButton
              aria-label="close"
              onClick={() => setShowInteractionsModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* <img
                                                    src='/images/closepopup.svg'
                                                    width={'12px'}
                                                    onClick={handleClose1}
                                                    alt={'close'}
                                                /> */}
          </Box>
          <Typography
            align="center"
            fontWeight={"bold"}
            sx={{
              // fontFamily: 'Circular Std',
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22px",
              paddingTop: "20px",
              textAlign: "left",
              color: "#000",
            }}
          >
            Reactions ({interactions.length})
          </Typography>
          <hr />
          <div className="interaction-table">
            {interactionsEmojis &&
              interactionsEmojis?.length > 0 &&
              interactionsEmojis?.map((reaction, reactionIdx) => {
                console.log({
                  helloIMG: getReactionImage(reaction?.emoji),
                });
                return (
                  <div key={reactionIdx} className="interaction-col">
                    <div className="interaction-pic-wrapper">
                      {reaction?.profile_pic ? (
                        <>
                          <div
                            style={{
                              position: "relative",
                              marginRight: "30px",
                            }}
                          >
                            <img
                              src={reaction?.profile_pic}
                              alt="User"
                              className="interaction-pic"
                            />
                            <img
                              className="interaction-pic-react"
                              src={getReactionImage(reaction?.emoji)}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                              marginRight: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="interaction-picNot">
                              {reaction?.by && reaction?.by[0]}
                            </div>
                            <img
                              className="interaction-pic-react"
                              src={getReactionImage(reaction?.emoji)}
                            />
                          </div>
                        </>
                      )}
                      {/* {reaction?.profile_pic ? } */}
                    </div>
                    <p style={{ color: "#000" }}>
                      <strong>{reaction?.by}</strong>
                    </p>
                  </div>
                );
              })}
          </div>
          {/* <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-evenly'}
                            paddingTop={'40px'}
                        >
                            <Box>
                                <Button
                                    variant='contained'
                                    // onClick={() => submitHandler(values)}
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Box>

                            <Box>
                                <Button
                                    onClick={() =>
                                        setShowInteractionsModal(false)
                                    }
                                    variant='contained'
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box> */}
        </Box>
      </Modal>
    </div>
  );
};

export default FeedCard;
