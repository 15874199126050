export const liteThemeColors = {
  //info colors

  infoLite: "#24202d",

  //gray

  100: "#838383",

  //primary colors

  primary: "#FF9776",
  primaryLite: "#161F3D",
  primaryDark: "#66C9D2",

  //secondary colors

  secondary: "#F9CC69",
  secondaryLite: "#7551F1",
  secondaryDark: "#230D4D",

  //text colors

  textMain: "#000",
  textLight: "#230D4D",
  textDark: "#FF9776",
  grayText: "#FF9776",
  redText: "#FC8965",
  liteGrayText: "#24202D80",

  //links colors

  linkPrimary: "#fff",
  linkSecondary: "#fff",

  //common colors

  white: "#fff",
  black: "#000",
  divider: "rgba(0, 0, 0, 0.12)",
  cardShadow: "rgba(23, 70, 161, .11)",
  bgGradient:
    "linear-gradient(180deg, rgba(3, 14, 32, 0.89) 0%, rgba(40, 92, 188, 0.91) 100%)",
};
