import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from "react";
//package import
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Modal,
  Container,
  Switch,
} from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { typographyClasses } from "@mui/material/Typography";
import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import t from "app/assets/user/images/t.png";
import c from "app/assets/user/images/c.png";
//user import

import InfoIcon from "app/svg/user/orgProfile/Info";
import { IOSSwitch } from "app/common/user/components";
import { headersGet, headersPost, headersUpdate } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";

import CardLinkModal from "../PaymentInfo/CardLinkModal";
import CardModal from "../PaymentInfo/CardModal";
import BasiqModal from "../Basiq";
// import { context } from "../../context";
import BankModal from "../PaymentInfo/BankModal2";
import useMediaQuery from "@mui/material/useMediaQuery";
import bank from "app/assets/user/images/bank.png";
import { context } from "app/context";

//constants
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "493px",
  height: "360px",
  bgcolor: "#fff",
  borderRadius: "10px",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 600, lg: 600 },
  height: { xs: 220, sm: 220, md: 190, lg: 190 },
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 600, lg: 600 },
  height: { xs: 220, sm: 220, md: 240, lg: 240 },
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 450,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const styleRoundupPop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 190,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const marks = [
  {
    value: 0,
    label: "$1",
  },
  {
    value: 25,
    label: "$2",
  },
  {
    value: 50,
    label: "$5",
  },
  {
    value: 100,
    label: "$10",
  },
];

const marksMonthly = [
  {
    value: 0,
    label: "No Limit",
  },
  {
    value: 25,
    label: "$20",
  },
  {
    value: 50,
    label: "$50",
  },
  {
    value: 80,
    label: "$80",
  },
  {
    value: 100,
    label: "$100",
  },
];

const amountsArr = [
  { name: "$20", value: 20 },
  { name: "$50", value: 50 },
  { name: "$100", value: 100 },
  { name: "$200", value: 200 },
  { name: "Other", value: "other" },
];

const initialValues = {
  amount: "",
  currency: "aud",
  description: "one time payment",
  isSubscription: false,
  pp_fees: false,
};

function valueText(value) {
  return `$${value}`;
}

//component

const SupportModal = ({
  closeHandler,
  open,
  feedId,
  causeId,
  causeTitle,
  organisationDoc,
}) => {
  //hooks
  // console.log({causeTitle, causeId})
  const theme = useTheme();
  const { user, isRoundup } = useContext(context);
  const { basiqId } = user || {};
  let { _id: user_Id } = user || {};
  let { id: userIdd } = user || {};
  const userId = user_Id ? user_Id : userIdd;
  const { id: organisation } = organisationDoc || {};
  // console.log({user})
  const { _id } = user || {};

  //states
  const [oneTime, setOneTime] = useState(false);
  const [isRoundupAction, setIsRoundupAction] = useState(false);
  const [oneTimeDonation, setOneTimeDonation] = useState("other");
  const [loader, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);

  const [loader2, setLoader2] = useState(false);
  const [token, setToken] = useState("");
  const [basiqOpen, setBasiqOpen] = useState(false);

  //states
  const [amount, setAmount] = useState(0);
  const [openBtn, setOpenBtn] = useState(false);
  const [openCardLink, setOpenCardLink] = useState(false);
  const [openBankLink, setOpenBankLink] = useState(false);
  const [isRoundupAdded, setIsRoundupAdded] = useState(false);
  const [formikValsTemp, setFormikValsTemp] = useState({});
  const [cardData, setCardData] = useState();
  const [bankData, setBankData] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [showCardSelectModal, setShowCardSelectModal] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [data, setData] = useState([]);
  const [switches, setSwitches] = useState({});

  const matches = useMediaQuery("(min-width:900px)");
  const matches1 = useMediaQuery("(max-width:899px)");
  // let basiqConfig = {
  //     containerId: 'basiq-control',
  //     userID: basiqId,
  //     token: token,
  // }

  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);
  const handleOpen2 = () => setOpen2(true);

  const [open3, setOpen3] = useState(false);
  const handleClose3 = () => setOpen3(false);
  const handleOpen3 = () => {
    if (data.length === 0) {
      setOpenBtn(true);
    } else {
      setOpen3(true);
    }
  };

  const [open4, setOpen4] = useState(false);
  const handleClose4 = () => setOpen4(false);
  const handleOpen4 = () => setOpen4(true);

  const [open5, setOpen5] = useState(false);
  const handleClose5 = () => setOpen5(false);
  const handleOpen5 = () => setOpen5(true);

  //open  button modal handler

  const handleClickOpen = () => {
    setOpenBtn(true);
  };

  //close button modal handler

  const handleClose = () => {
    setOpenBtn(false);
  };

  //open card  modal handler

  const handleCardOpen = () => {
    setOpenCardLink(true);
  };
  const handleBankOpen = () => {
    setOpenBankLink(true);
  };

  //close card  modal handler

  const handleCardClose = () => {
    setOpenCardLink(false);
  };
  const handleBankClose = () => {
    setOpenBankLink(false);
  };

  //form submit handler
  // const handleRoundUpOpen = () => {
  //     setBasiqOpen(true)
  // }

  const submitHandler = (values) => {
    setOpen1(true);
    setFormikValsTemp(values);
    setIsRoundupAction(false);
    setIsSubscription(false);
    handleClose4();
    handleClose5();
    // console.log({organisation, causeId, feedId})
    if ((causeId || feedId) && organisation) {
      const { currency, description, amount, isSubscription, pp_fees } =
        values || {};
      console.log("🚀 ~ submitHandler ~ values:", values);

      const data = {
        amount:
          oneTimeDonation === "other"
            ? amount?.toString()
            : oneTimeDonation?.toString(),
        currency: currency,
        description: description,
        ...(feedId &&
          feedId !== null && {
            feed: feedId,
          }),
        ...(causeId &&
          causeId !== null && {
            cause: causeId,
          }),
        organisation,
        pp_fees,
      };

      setOpen(true);
      isSubscription ? subscriptionPayment(data) : oneTimePayment(data);
    } else {
      setAlertOpen(true);
      setAlertMsg(`Please select feed or cause`);
      setAlertIcon("error");
    }
  };

  const makeChange = (values) => {
    const { pp_fees, amount: amountFormik } = values;

    //  if(oneTimeDonation === "other"){
    //    amountFormik?.toString()
    //  }else{
    //    oneTimeDonation?.toString()
    //  }

    // console.log({pp_fees, amountFormik})

    let amnt = oneTimeDonation === "other" ? amountFormik : oneTimeDonation;
    if (pp_fees === true) {
      let a = amnt * 0.08;
      setAmount(a + amnt);
    } else {
      setAmount(amnt);
    }
    if (!amnt) {
      setAlertOpen(true);
      setAlertMsg("Please enter amount");
      setAlertIcon("warning");
    } else if (amnt < 2) {
      setAlertOpen(true);
      setAlertMsg("Please donate at least $2");
      setAlertIcon("warning");
    } else {
      setOpen1(true);
    }
  };

  const oneTimePayment = (data) => {
    setIsPaymentSuccess(false);
    setOpen1(false);
    headersPost(API_URLS?.chargeCustomerOneTime, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          // console.log(res.data.message)
          setAlertIcon("success");
          setIsPaymentSuccess(true);
          setOneTime(false);
          setTimeout(() => {
            setAlertOpen(false);
            setAlertMsg("");
            closeHandler();
          }, 3000);
        } else {
          setIsPaymentSuccess(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        let msgToCheck = "Account not found. Please add a card";
        let msg = response?.data?.message.toLowerCase();
        if (msg === msgToCheck.toLocaleLowerCase()) {
          handleClickOpen();
          setAlertMsg("Please provide payment information");
        } else {
          setAlertMsg(response?.data?.message);
        }
        // closeHandler()
        setOpen(false);
        setAlertOpen(true);
        setIsPaymentSuccess(false);
        setAlertIcon("error");
      });
  };

  const subscriptionPayment = (data) => {
    handleClose1();
    setIsSubscription(true);
    headersPost(API_URLS?.chargeSubscription, data)
      .then((res) => {
        setOpen(false);
        // console.log('outSide200')
        if (res?.status === 200) {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);

          // console.log({msg: res?.data?.message})
          setAlertIcon("success");
          setIsPaymentSuccess(true);
          setTimeout(() => {
            setAlertOpen(false);
            setAlertMsg("");
            closeHandler();
          }, 3000);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        console.log("🚀 ~ subscriptionPayment ~ response:", response);

        let msgToCheck = "Account not found. Please add a card";
        let msg = response?.data?.message?.toLowerCase();
        if (msg === msgToCheck.toLocaleLowerCase()) {
          handleClickOpen();
          setAlertMsg("Please provide payment information");
        } else {
          setAlertMsg(response?.data?.message);
        }
        // closeHandler()
        setOpen(false);
        setAlertOpen(true);
        setIsPaymentSuccess(false);
        setAlertIcon("error");
      });
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const addRoundUpHandler = (values) => {
    setFormikValsTemp(values);
    setIsRoundupAction(true);
    handleClose2();
    let { pp_fees } = values || {};
    if (basiqId) {
      if (isRoundup === true) {
        const url = `${API_URLS?.addRoundupOrg}`;
        setLoader2(true);
        const dataToSend = {
          userId: userId,
          orgId: organisation,
          causeId: causeId,
          pp_fees,
        };
        setOpen2(false);
        headersUpdate(url, dataToSend)
          .then((res) => {
            const { data, status } = res;
            // console.log('====================================')
            // console.log({data})
            // console.log('====================================')
            setLoader2(false);
            if (status === 200) {
              setLoader2(false);
              setAlertOpen(true);
              setAlertMsg(data?.message);
              setAlertIcon("success");
            }
            setIsRoundupAdded(true);
            setTimeout(() => {
              closeHandler();
            }, 4000);
          })
          .catch((err) => {
            const { response } = err || {};
            // const {message} = response || {}
            setLoader2(false);
            setAlertOpen(true);
            setAlertMsg(response?.data?.message);
            setAlertIcon("error");
            if (response?.status === 404) {
              // show card modal
              // console.log('====================================')
              // console.log({response})
              // console.log('====================================')
              handleClickOpen();
            }
          });
      } else {
        const url = `${
          API_URLS?.getBasiqRoute
        }?serverToken=false&basiqId=${basiqId}&userId=${userId}&orgId=${organisation}&causeId=${causeId}&pp_fees=${
          pp_fees ? true : false
        }`;
        setLoader2(true);

        headersGet(url)
          .then((res) => {
            const { data, status } = res;
            const {
              data: basiqData,
              status: basiqStatus,
              message,
            } = data || {};
            const { access_token } = basiqData || {};
            setLoader2(false);
            if (status === 200 && access_token && basiqStatus) {
              setToken(access_token);
              setBasiqOpen(true);
            } else {
              setAlertOpen(true);
              setAlertMsg(message || "");
              setAlertIcon("warning");
            }
          })
          .catch((err) => {
            const { response } = err || {};
            const { message } = response || {};
            setLoader2(false);
            setAlertOpen(true);
            setAlertMsg(message);
            setAlertIcon("error");
            if (response?.status === 404) {
              // show card modal
              handleClickOpen();
              setAlertMsg(response?.data?.message);
              // console.log('====================================')
              // console.log({response})
              // console.log('====================================')
            }
          });
      }
    } else {
      setLoader2(false);
      setAlertOpen(true);
      setAlertMsg("Please create a user or login ");
      setAlertIcon("error");
    }
  };
  //
  const markCardDefault = async (cardId) => {
    // console.log('====================================')
    // console.log({cardId})
    // console.log('====================================')
    setOpen(true);
    setDefaultCardId(cardId);
    if (cardId) {
      const dataToSend = {
        userId: _id,
        accountId: cardId,
      };
      headersPost(`${API_URLS?.markCardDefault}`, dataToSend)
        .then((res) => {
          const { data, status } = res;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("success");
          handleCardClose();
          // window.location.reload();
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
          handleCardClose();
        });
    }
  };

  const handleLinkBank = () => {
    const { _id } = user || {};
    setOpen(true);
    const data = {
      addedByUser: _id,
    };
  };

  const handleToggleSwitches = (event, id) => {
    if (event.target.checked === true) {
      // console.log({id, event: event.target.checked})
      setSwitches((prev) => {
        const falsedState = Object.keys(prev)?.forEach((key) => {
          prev[key] = false;
        });
        return { falsedState, [id]: event.target.checked };
      });
      // run mark card default api
      markCardDefault(id);
    } else {
      // console.log({id, event: event.target.checked})
      // show card select modal
      setShowCardSelectModal(true);
    }
  };

  const getAccounts = useCallback(() => {
    // const data = `page=${page}&limit=${10}&user=${_id}&currentDay=${currentDay}&type=subscription`

    setOpen(true);
    headersGet(
      `${API_URLS?.getAccounts}?userId=${_id}&orgId=${encodeURIComponent(
        organisation
      )}`
    )
      .then((res) => {
        const { data, status } = res;
        setOpen(false);
        setIsloading(false);
        if (status === 200) {
          const { accounts, totalPages: pages } = data || {};

          setData(accounts);
          const filtersBank = accounts?.filter((item) => {
            return item?.is_bank;
          });
          setBankData(filtersBank);
          const filtersCard = accounts?.filter((item) => {
            return item?.is_bank == undefined;
          });
          setCardData(filtersCard);
          accounts?.forEach((acc) => {
            setSwitches((prev) => ({
              ...prev,
              [acc.id]: acc?.is_default || false,
            }));
          });
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [_id]);

  useEffect(() => {
    _id && getAccounts();
  }, [_id, getAccounts]);

  //render

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth={"sm"} fullWidth>
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        // validationSchema={addvalidation}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <DialogTitle sx={{ m: 0, p: 2 }}>
                <Typography align="center">
                  <Typography
                    sx={{
                      borderBottom: `2px solid ${theme.palette.common.black}`,
                    }}
                    component={"span"}
                    paddingBottom={1}
                    fontWeight={"bold"}
                  >
                    {/* Add RoundUp */}
                    Support
                  </Typography>
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeHandler}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Box display={"flex"} justifyContent={"center"} paddingTop={2}>
                {matches && (
                  <Button
                    variant={!oneTime ? "contained" : "outlined"}
                    color={"primary"}
                    onClick={() => setOneTime(false)}
                  >
                    ROUND UP
                  </Button>
                )}

                {matches && (
                  <Button
                    variant={oneTime ? "contained" : "outlined"}
                    color={"primary"}
                    onClick={() => setOneTime(true)}
                  >
                    ONE TIME
                  </Button>
                )}
                {/* {console.log({matches, matches1})} */}
                {matches1 && (
                  <Button
                    variant={!oneTime ? "contained" : "outlined"}
                    color={"primary"}
                    onClick={() => setOneTime(false)}
                    sx={{
                      padding: "10px 20px",
                    }}
                  >
                    ROUND UP
                  </Button>
                )}

                {matches1 && (
                  <Button
                    variant={oneTime ? "contained" : "outlined"}
                    color={"primary"}
                    onClick={() => setOneTime(true)}
                    sx={{
                      padding: "10px 20px",
                    }}
                  >
                    ONE TIME
                  </Button>
                )}
              </Box>
              <DialogContent
                padding={{
                  sm: "20px 0px",
                  xs: "20px 0px",
                  md: "20px 24px",
                  lg: "20px 24px",
                }}
              >
                <Box paddingY={0}>
                  {!oneTime && (
                    <Fragment>
                      {/* <Typography
                      align="center"
                      fontWeight={"bold"}
                      color={theme.palette.primary.main}
                    >
                      ROUND-UPS LIMIT PER TRANSACTION <InfoIcon />
                    </Typography>
                    <Box padding={4}>
                      <Slider
                        aria-label="Custom marks"
                        defaultValue={25}
                        getAriaValueText={valueText}
                        step={0}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    </Box> */}
                      {/* <Typography
                      align="center"
                      fontWeight={"bold"}
                      color={theme.palette.primary.main}
                    >
                      ROUND-UPS MONTHLY LIMIT CAP <InfoIcon />
                    </Typography>
                    <Box padding={2}>
                      <Slider
                        aria-label="Custom marks"
                        defaultValue={20}
                        getAriaValueText={valueText}
                        step={0}
                        valueLabelDisplay="auto"
                        marks={marksMonthly}
                      />
                    </Box> */}
                      <Container>
                        <Box display={"flex"}>
                          <Box
                            style={{
                              paddingTop: "15px",
                            }}
                          >
                            <img
                              src="/images/link_accounts.svg"
                              width={"25px"}
                            ></img>
                          </Box>
                          <Box paddingLeft={2}>
                            <Box padding={1}>
                              <Typography
                                fontSize={{
                                  sm: "16px",
                                  xs: "16px",
                                  lg: "17px",
                                  md: "17px",
                                }}
                                fontWeight={{
                                  sm: "500",
                                  xs: "500",
                                  lg: "900",
                                  md: "900",
                                }}
                                lineHeight={"35px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                              >
                                Link Accounts
                              </Typography>
                              <Typography
                                fontSize={{
                                  sm: "12px",
                                  xs: "12px",
                                  lg: "14px",
                                  md: "14px",
                                }}
                                fontWeight={400}
                                lineHeight={"20px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                                color={"rgba(0, 0, 0, 0.7)"}
                              >
                                Connect your credit or debit card(s) so the app
                                knows what to round up.
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box display={"flex"}>
                          <Box
                            style={{
                              paddingTop: "15px",
                            }}
                          >
                            <img src="/images/Group.svg" width={"25px"}></img>
                          </Box>
                          <Box paddingLeft={2}>
                            <Box padding={1}>
                              <Typography
                                fontSize={{
                                  sm: "16px",
                                  xs: "16px",
                                  lg: "17px",
                                  md: "17px",
                                }}
                                fontWeight={{
                                  sm: "500",
                                  xs: "500",
                                  lg: "900",
                                  md: "900",
                                }}
                                lineHeight={"35px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                              >
                                Spend Like Normal
                              </Typography>
                              <Typography
                                fontSize={{
                                  sm: "12px",
                                  xs: "12px",
                                  lg: "14px",
                                  md: "14px",
                                }}
                                fontWeight={400}
                                lineHeight={"20px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                                color={"rgba(0, 0, 0, 0.7)"}
                              >
                                The app will automatically track purchases made
                                with your linked accounts.
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box display={"flex"}>
                          <Box
                            style={{
                              paddingTop: "10px",
                            }}
                          >
                            <img src="/images/donate.svg" width={"25px"}></img>
                          </Box>
                          <Box paddingLeft={2}>
                            <Box padding={1}>
                              <Typography
                                fontSize={{
                                  sm: "16px",
                                  xs: "16px",
                                  lg: "17px",
                                  md: "17px",
                                }}
                                fontWeight={{
                                  sm: "500",
                                  xs: "500",
                                  lg: "900",
                                  md: "900",
                                }}
                                lineHeight={"35px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                              >
                                Donate!
                              </Typography>
                              <Typography
                                fontSize={{
                                  sm: "12px",
                                  xs: "12px",
                                  lg: "14px",
                                  md: "14px",
                                }}
                                fontWeight={400}
                                lineHeight={"20px"}
                                fontFamily={"Circular Std"}
                                fontStyle={"normal"}
                                color={"rgba(0, 0, 0, 0.7)"}
                              >
                                Each month, your “RoundUps” are totaled and the
                                sum is donated to your selected nonprofits via
                                your payment method on file.
                                <p
                                  style={{
                                    marginBlockStart: "2px",
                                    marginBlockEnd: "0em",
                                  }}
                                >
                                  * $5 minimum donation per nonprofit
                                </p>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Fragment>
                  )}
                  {/* <Box display={"flex"} justifyContent={"center"} paddingTop={"20px"}>
                  <Box display={"flex"}>
                    <Typography fontWeight={"bold"}>
                      I’d like to make a One-Time donation
                    </Typography>
                    <Box paddingLeft={2}>
                      <IOSSwitch
                        checked={oneTime}
                        onChange={() => setOneTime(!oneTime)}
                      />
                    </Box>
                  </Box>
                </Box>  */}
                  {oneTime && (
                    <Fragment>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        paddingTop={"94px"}
                        paddingBottom={"67px"}
                      >
                        {amountsArr?.map((item) => (
                          <Box>
                            <Checkbox
                              onChange={() => setOneTimeDonation(item.value)}
                              checked={item.value === oneTimeDonation}
                              checkedIcon={
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  sx={{
                                    backgroundColor:
                                      theme.palette.secondary.main,
                                    width: 45,
                                    height: 45,
                                    borderRadius: "100%",
                                  }}
                                >
                                  <Typography color={"common.white"}>
                                    {item?.name}
                                  </Typography>
                                </Box>
                              }
                              icon={
                                <Box
                                  sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    width: 45,
                                    height: 45,
                                    borderRadius: "100%",
                                  }}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Typography color={"common.white"}>
                                    {item?.name}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Box>
                        ))}
                      </Box>
                      <Box>
                        {oneTimeDonation === "other" && (
                          <Box textAlign={"center"}>
                            <Typography fontWeight={"bold"} gutterBottom>
                              Type Amount
                            </Typography>
                            <TextField
                              size="small"
                              value={values?.amount}
                              name={"amount"}
                              type={"number"}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    sx={{
                                      backgroundColor: theme.palette.grey.A100,
                                    }}
                                  >
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              // sx={{
                              //   width: {xs:"150px" , sm:"150px" , md:"500" ,lg:"500"}
                              // }}
                            ></TextField>
                          </Box>
                        )}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        paddingX={2}
                        paddingTop={3}
                        sx={{
                          width: "100%",
                          label: {
                            width: "100%",
                          },
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isSubscription"
                              checked={values?.isSubscription}
                              onChange={handleChange}
                            />
                          }
                          label="Do you wish to make this a reoccurring monthly donation?"
                          sx={{
                            [`.${typographyClasses.root}`]: {
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                              },
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </Box>
                    </Fragment>
                  )}
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    paddingX={2}
                    paddingTop={0}
                    sx={{
                      width: "100% !important",
                      label: {
                        width: "100% !important",
                      },
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="pp_fees"
                          checked={values?.pp_fees}
                          onChange={handleChange}
                        />
                      }
                      label={`I’d like to cover the 8% platform and processing fees on behalf of my not-for-profit so 100% of my donation goes to my cause`}
                      sx={{
                        [`.${typographyClasses.root}`]: {
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                          },
                          width: "100%",
                          fontWeight: "bold",
                          textAlign: "justify",
                        },
                      }}
                    />
                  </Box>

                  <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    {!oneTime && (
                      <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => handleOpen2()}
                        disabled={loader2}
                      >
                        {loader2 ? "Processing" : "SIGN UP TO ROUNDUP!"}
                      </Button>
                    )}
                    <Modal
                      open={open2}
                      onClose={handleClose2}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className="conform__popup"
                    >
                      <Box sx={{ ...style2, width: "36%", margin: "auto" }}>
                        <Box textAlign={"right"}>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose2}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Typography
                          align="center"
                          fontWeight={"bold"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "18px",
                              lg: "18px",
                            },
                            paddingTop: "30px",
                          }}
                        >
                          Your everyday transactions will be rounded up to the
                          nearest dollar, collated and then weekly charged to
                          your card/account and forwarded onto your chosen
                          cause.
                        </Typography>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-evenly"}
                          paddingTop={"40px"}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              onClick={() => addRoundUpHandler(values)}
                              sx={{
                                padding: {
                                  xs: "10px 20px",
                                  sm: "10px 20px",
                                  md: "10px 50px",
                                  lg: "10px 50px",
                                },
                              }}
                            >
                              Confirm
                            </Button>
                          </Box>

                          <Box>
                            <Button
                              onClick={handleClose2}
                              variant="contained"
                              sx={{
                                padding: {
                                  xs: "10px 20px",
                                  sm: "10px 20px",
                                  md: "10px 50px",
                                  lg: "10px 50px",
                                },
                              }}
                            >
                              Close
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>

                    {oneTime && (
                      <Button
                        variant="contained"
                        color={"primary"}
                        //   type={"submit"}
                        onClick={() => makeChange(values)}
                      >
                        MAKE CHANGE!
                      </Button>
                    )}
                    <Modal
                      open={open1}
                      onClose={handleClose1}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className="donate_model"
                    >
                      <Box
                        sx={{
                          ...style1,
                          width: "36%",
                          margin: "auto",
                        }}
                        className="donate_model_item"
                      >
                        <Box textAlign={"right"}>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose1}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          {/* <img
                                                    src='/images/closepopup.svg'
                                                    width={'12px'}
                                                    onClick={handleClose1}
                                                    alt={'close'}
                                                /> */}
                        </Box>
                        <Typography
                          align="center"
                          fontWeight={"bold"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "18px",
                            paddingTop: "20px",
                          }}
                        >
                          Are you sure you want to donate {amount}?
                        </Typography>

                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-evenly"}
                          paddingTop={"40px"}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (data?.length === 0) {
                                  submitHandler(values);
                                } else {
                                  // setOpen3(true);
                                  // new
                                  setOpen3(false);
                                  setOpen5(true);
                                }
                              }}
                              sx={{
                                padding: {
                                  xs: "10px 20px",
                                  sm: "10px 20px",
                                  md: "10px 50px",
                                  lg: "10px 50px",
                                },
                              }}
                            >
                              Donate
                            </Button>
                          </Box>

                          <Box>
                            <Button
                              onClick={handleClose1}
                              variant="contained"
                              sx={{
                                padding: {
                                  xs: "10px 20px",
                                  sm: "10px 20px",
                                  md: "10px 50px",
                                  lg: "10px 50px",
                                },
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>

                    <Modal
                      open={open5}
                      onClose={handleClose5}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          ...style3,
                          height: "auto",
                        }}
                      >
                        <Box textAlign={"right"}>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose5}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Typography
                          align="center"
                          fontWeight={"bold"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "18px",
                            paddingTop: "20px",
                            marginBottom: "50px",
                          }}
                        >
                          Debit Card/ Credit Card
                        </Typography>

                        {cardData?.map((row, index) => {
                          const {
                            user,
                            exp_year,
                            exp_month,
                            billing_address,
                            is_default,
                            id,
                            _id,
                            number: card_number,
                            brand,
                            account_name,
                          } = row || {};
                          const cardId = id ? id : _id;
                          return (
                            <ul
                              style={{
                                listStyleType: "none",
                                borderBottom: "1px solid #c4c4c4",
                                paddingInlineStart: "0px",
                              }}
                            >
                              <li
                                style={{
                                  display: "flex",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "13px",
                                    marginLeft: "13px",
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <CardBrandToRender brand={brand} />
                                </div>
                                <div
                                  style={{
                                    display: "flex",

                                    justifyContent: "space-between",
                                    flex: "1",
                                    marginRight: "40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        fontFamily: "Circular Std",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "20px",
                                        color: "#666666",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "#000",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <CardBrandNameToRender brand={brand} />
                                      </div>
                                      <div>
                                        {" "}
                                        ****
                                        {card_number?.substr(-4)}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Switch
                                      checked={!!switches[cardId]}
                                      onChange={(e) =>
                                        handleToggleSwitches(e, cardId)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                        <Box paddingY={1} sx={{ textAlign: "center" }}>
                          <Button
                            sx={{
                              padding: {
                                xs: "10px 20px",
                                sm: "10px 20px",
                                md: "10px 60px",
                                lg: "10px 60px",
                              },
                              fontSize: "10px",
                              marginTop: "50px",
                            }}
                            variant="contained"
                            onClick={() => submitHandler(values)}
                          >
                            Confirm
                          </Button>
                        </Box>
                        <Box paddingY={1} sx={{ textAlign: "center" }}>
                          <Button
                            startIcon={<AddIcon />}
                            sx={{
                              padding: {
                                xs: "10px 20px",
                                sm: "10px 20px",
                                md: "10px 20px",
                                lg: "10px 20px",
                              },
                              fontSize: "10px",
                            }}
                            variant="contained"
                            onClick={handleCardOpen}
                          >
                            Add Another Card
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                    {open3 && (
                      <CardLinkModal
                        open={open3}
                        handleClose={handleClose3}
                        handleCardOpen={handleOpen5}
                        handleBankOpen={handleOpen4}
                      />
                    )}

                    <Modal
                      open={open4}
                      onClose={handleClose4}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style3}>
                        <Box textAlign={"right"}>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose4}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Typography
                          align="center"
                          fontWeight={"bold"}
                          sx={{
                            fontFamily: "Circular Std",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "18px",
                            paddingTop: "20px",
                            marginBottom: "50px",
                          }}
                        >
                          Bank Accounts
                        </Typography>

                        {bankData?.map((row, index) => {
                          const {
                            user,
                            exp_year,
                            exp_month,
                            billing_address,
                            is_default,
                            id,
                            _id,
                            number: card_number,
                            brand,
                            account_name,
                            account_number,
                          } = row || {};
                          const cardId = id ? id : _id;
                          return (
                            <ul
                              style={{
                                listStyleType: "none",
                                borderBottom: "1px solid #c4c4c4",
                                paddingInlineStart: "0px",
                              }}
                            >
                              <li
                                style={{
                                  display: "flex",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "13px",
                                    marginLeft: "13px",
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <img src={bank} width={"30px"} />
                                </div>
                                <div
                                  style={{
                                    display: "flex",

                                    justifyContent: "space-between",
                                    flex: "1",
                                    marginRight: "40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        fontFamily: "Circular Std",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "20px",
                                        color: "#666666",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "#000",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {/* <CardBrandNameToRender brand={brand} /> */}
                                        National Austrailian Bank
                                      </div>
                                      <div>
                                        {`*****${account_number}` || "****"}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Switch
                                      checked={!!switches[cardId]}
                                      onChange={(e) =>
                                        handleToggleSwitches(e, cardId)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                        <Box paddingY={1} sx={{ textAlign: "center" }}>
                          <Button
                            sx={{
                              padding: {
                                xs: "10px 20px",
                                sm: "10px 20px",
                                md: "10px 60px",
                                lg: "10px 60px",
                              },
                              fontSize: "10px",
                              marginTop: "50px",
                            }}
                            variant="contained"
                            onClick={() => submitHandler(values)}
                          >
                            Confirm
                          </Button>
                        </Box>
                        <Box paddingY={1} sx={{ textAlign: "center" }}>
                          <Button
                            startIcon={<AddIcon />}
                            sx={{
                              padding: {
                                xs: "10px 20px",
                                sm: "10px 20px",
                                md: "10px 20px",
                                lg: "10px 20px",
                              },
                              fontSize: "10px",
                            }}
                            variant="contained"
                            onClick={handleBankOpen}
                          >
                            Add Another Bank
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                    {openBtn && (
                      <CardLinkModal
                        open={openBtn}
                        handleClose={handleClose}
                        handleCardOpen={handleCardOpen}
                        handleBankOpen={handleBankOpen}
                      />
                    )}

                    {openCardLink && (
                      <CardModal
                        open={openCardLink}
                        handleClose={handleCardClose}
                        fromvalues={values}
                        orgId={organisation}
                        donationHandleState={{
                          submitHandler: submitHandler,
                          formikValsTemp: formikValsTemp,
                          isRoundupAction: isRoundupAction,
                          addRoundUpHandler: addRoundUpHandler,
                          markCardDefault: markCardDefault,
                        }}
                        paymentMethodState={{
                          setData: setData,
                          cardData: cardData,
                          setCardData: setCardData,
                          data: data,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </DialogContent>
            </Form>
            {openBankLink && (
              <BankModal
                open={openBankLink}
                handleClose={handleBankClose}
                donationHandleState={{
                  submitHandler: data.length ? submitHandler : "",
                  formikValsTemp: formikValsTemp,
                  isRoundupAction: isRoundupAction,
                  addRoundUpHandler: addRoundUpHandler,
                }}
                paymentMethodState={{
                  setData: setData,
                  bankData: bankData,
                  setBankData: setBankData,
                  data: data,
                }}
              />
            )}
          </>
        )}
      </Formik>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isPaymentSuccess && (
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={alertCloseHandler}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
        >
          <Alert
            onClose={alertCloseHandler}
            severity={alertIcon}
            sx={{ width: "100%" }}
          >
            {/* <div dangerouslySetInnerHTML={{__html:  alertMsg}} /> */}
            {alertMsg}
          </Alert>
        </Snackbar>
      )}
      {isPaymentSuccess && (
        <Modal
          open={alertOpen}
          onClose={alertCloseHandler}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box style={{ textAlign: "center", padding: "30px" }}>
              {/* <CheckCircleOutlineIcon
                                style={{color: 'green', fontSize: '100px'}}
                            /> */}
              <img src={t} />
              <Typography
                sx={{
                  marginTop: "10px",
                }}
              >
                You have successfully{" "}
                {isSubscription ? "subscribed" : "donated"} for cause
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Circular Std",

                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "23px",
                  color: "#ff9776",
                  marginTop: "15px",
                  marginBottom: "10px",
                }}
              >
                {causeTitle}
              </Typography>
              <img src={c} />
            </Box>
            {/* <p style={{textAlign: 'center'}}>{alertMsg}</p> */}
          </Box>
        </Modal>
      )}
      {/* {isRoundupAdded && (
                <Modal
                    open={isRoundupAdded}
                    onClose={() => setIsRoundupAdded(false)}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                >
                    <Box sx={style}>
                        <h3>
                            Roundup has been successfuly added for this
                            organisation
                        </h3>
                    </Box>
                </Modal>
            )} */}
      {isRoundupAdded && (
        <Modal
          open={isRoundupAdded}
          onClose={() => setIsRoundupAdded(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleRoundupPop}>
            {/* <Box textAlign={'right'}>
                                    <img
                                        src='/images/closepopup.svg'
                                        width={'12px'}
                                        onClick={() => setShowRoundupPop(false)}
                                        alt={'close'}
                                    />
                                </Box> */}
            <Typography
              align="center"
              fontWeight={"bold"}
              sx={{
                fontFamily: "Circular Std",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "23px",
                paddingTop: "20px",
              }}
            >
              Congratulations!
            </Typography>
            <Typography
              align="center"
              fontWeight={"bold"}
              sx={{
                fontFamily: "Circular Std",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "15px",
                paddingTop: "20px",
              }}
            >
              You have successfuly setup the roundup
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-evenly"}
              paddingTop={"40px"}
            >
              <Box>
                <Button
                  onClick={() => {
                    setIsRoundupAdded(false);
                    closeHandler();
                  }}
                  variant="contained"
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {basiqOpen && (
        <BasiqModal
          open={basiqOpen}
          closeHandler={() => setBasiqOpen(!basiqOpen)}
          token={token}
          basiqId={basiqId}
        />
      )}
    </Dialog>
  );
};

const CardBrandToRender = ({ brand }) => {
  let imgSrc = null;
  if (brand === "Visa") imgSrc = "/images/Visa.png";
  if (brand === "MasterCard") imgSrc = "/images/Mastercard.png";
  if (!brand) {
    return null;
  } else {
    return (
      <img
        src={imgSrc}
        width={45}
        height={45}
        alt="test-img"
        style={{ objectFit: "contain" }}
      />
    );
  }
};
const CardBrandNameToRender = ({ brand }) => {
  let name = null;
  if (brand === "Visa") name = "Visa";
  if (brand === "MasterCard") name = "Mastercard";
  if (!brand) {
    return null;
  } else {
    return <span>{name}</span>;
  }
};

export default SupportModal;
