import * as Yup from 'yup'

import {variables} from '../variables'

const {required} = variables

export const addValidation = Yup.object({
    // image: Yup.mixed().required(required),
    title: Yup.string().required(required),
    image: Yup.mixed()
        .required()
        .test(
            1000,
            'File size should be less than 10MB',
            value => value.size <= 10000000
        ),
})

export const updateValidation = Yup.object({
    title: Yup.string().required(required),
})
