import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Toast = {
  success: (message) =>
    toast.success(message, {
      position: "top-right",
    }),

  error: (message) =>
    toast.error(message, {
      position: "top-right",
    }),

  warn: (message) =>
    toast.warn(message, {
      position: "top-right",
    }),

  info: (message) =>
    toast.info(message, {
      position: "top-right",
    }),

  default: (message) =>
    toast(message, {
      position: "top-right",
      className: "foo-bar",
    }),
};
