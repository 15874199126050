import React from "react";

//package import

import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

//user import

import MotoWithHearts from "app/svg/user/MotoWithHearts";
import MotoLike from "app/svg/user/MotoLike";
import MotoSetting from "app/svg/user/MotoSetting";
import Moto from "app/svg/user/Moto";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";
//component

const Features = () => {
  //hooks

  const theme = useTheme();

  const bounceAnimation = keyframes`${bounce}`;

  const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;
  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: 2,
      }}
    >
      <Container>
        <Typography
          align="center"
          variant="h4"
          color={"common.black"}
          fontWeight={"bold"}
        >
          App Features
        </Typography>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Box
            sx={{
              borderBottom: `2px solid ${theme.palette.common.black}`,
              width: "10%",
            }}
          ></Box>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <MotoWithHearts width={170} />
              </BouncyDiv>
            </Box>
            <Box marginBottom={6}>
              <Typography variant="h4" color={"primary.light"}>
                Find charities that match your values
              </Typography>
              <Typography align="right" color={"common.black"} gutterBottom>
                Capture your interests and values during onboarding, tailoring
                the charities visible on the platform to those which fit their
                beliefs.
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <MotoLike width={170} />
              </BouncyDiv>
            </Box>
            <Box>
              <Typography variant="h4" color={"primary.light"}>
                Discover the great work being done
              </Typography>
              <Typography align="right" color={"common.black"}>
                Learn about new organisations and see your funds at work through
                a central social feed. Direct your funds to your favorite
                charities 'micro goals' so they know what's important to you!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box paddingX={2}>
              <img src="/images/logoPhone.png" alt="logoPhone" width={"100%"} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <MotoSetting width={170} />
              </BouncyDiv>
            </Box>
            <Box marginBottom={6}>
              <Typography variant="h4" color={"primary.light"}>
                Automate your giving
              </Typography>
              <Typography align="right" color={"common.black"} gutterBottom>
                Link your debit card to the platform and round up a small amount
                on each transaction. Lots of little donations makes for a big
                impact.
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <Moto width={110} color={theme.palette.secondary.light} />
              </BouncyDiv>
            </Box>
            <Box>
              <Typography variant="h4" color={"primary.light"}>
                Work together with others
              </Typography>
              <Typography align="right" color={"common.black"}>
                Join a group of people all working together towards a common
                goal. Whether you’re part of a school or another organisation,
                you can pool resources and make massive change! It’s common
                cents!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
