import { getArrayFromObject } from "./Functions";

export const orgTagsEnums = {
  community_org: "community_org",
  acnc_verified: "ACNC_verified",
  drg_verified: "DRG_verified",
  cause: "cause",
};

export const orgTagsEnumsArr = [
  {
    name: "ACNC Registered Charity",
    value: orgTagsEnums.acnc_verified,
  },
  {
    name: "DRG Approved Charity",
    value: orgTagsEnums.drg_verified,
  },
  {
    name: "Community Organisation",
    value: orgTagsEnums.community_org,
  },
  // {
  //     name: 'Cause',
  //     value: orgTagsEnums.cause,
  // },
];

export const CATEGORIES = {
  ANIMALS_AND_WILDLIFE: "animals_and_wildlife",
  ENVIRONMENT_AND_CONSERVATION: "environment_and_conservation",
  PEOPLE_AND_COMMUNITY: "people_and_community",
  HEALTH_AND_MEDICAL: "health_and_medical",
  DISASTER_RESPONSE: "disaster_response",
  ARTS_AND_CULTURE: "arts_and_culture",
  RELIGION_AND_RELIGIOUS_GROUPS: "religion_and_religious_groups",
  OVERSEAS_AID_AND_DEVELOPEMT: "overseas_aid_and_development",
};

export const ANIMALS_AND_WILDLIFE_SUB_CATEGORIES = {
  ANIMALS_WELFARE: "animal_welfare",
  WILDLIFE_SUPPORT: "wildlife_support",
  ANIMALS_SUPPORTING_PEOPLE: "animals_supporting_people",
  DISASTER_RESPONSE_ANIMALS: "disaster_response_animals",
};

export const ANIMALS_AND_WILDLIFE_SUB_CATEGORIES_ARRAY = getArrayFromObject(
  ANIMALS_AND_WILDLIFE_SUB_CATEGORIES
);

export const ENVIRONMENT_AND_CONSERVATION_SUB_CATEGORIES = {
  HABITAT_CONSERVATION: "habitat_conservation",
  PARKS_AND_FORESTS: "parks_and_forests",
  FLORA_REGENERATION_TREE_PLATING: "flora_regeneration_tree_planting",
  ENVIRONMENTAL_CAUSES: "environmental_causes",
};

export const ENVIRONMENT_AND_CONSERVATION_SUB_CATEGORIES_ARRAY =
  getArrayFromObject(ENVIRONMENT_AND_CONSERVATION_SUB_CATEGORIES);

export const PEOPLE_AND_COMMUNITY_SUB_CATEGORIES = {
  AGED_CARE_AND_SENIORS: "aged_care_and_seniors",
  CHILDRENS_CHARITIES: "childrens_charities",
  HOMELESSNESS_AND_AFFORDABLE_HOUSING: "homelessness_and_affordable_housing",
  DOMESTICE_AND_FAMILY_VIOLENCE: "domestic_and_family_violence",
  DISABILITY_SUPPORT: "disability_support",
  ASYLUM_SEEKERS_AND_REFUGEES: "asylum_seekers_and_refugees",
  YOUTH_AND_YOUNG_PEOPLE: "youth_and_young_people",
  EMPLOYMENT_SERVICES: "employment_services",
  FAMILIES: "families",
  INDIGENOUS: "indigenous",
  LAW_JUSTICE_AND_HUMAN_RIGHTS: "law_justice_and_human_rights",
  LGBTIQ: "lgbtiq",
  MEN: "men",
  VETERANS_EXSERVICE_MEN_AND_WOMEN: "veterans_exservice_men_and_women",
  FOOD_SERVICES_AND_SUPPORT: "food_services_and_support",
  SPORTING_FACILITES_AND_COMMUNITY_CLUBS:
    "sporting_facilities_and_community_clubs",
};

export const PEOPLE_AND_COMMUNITY_SUB_CATEGORIES_ARRAY = getArrayFromObject(
  PEOPLE_AND_COMMUNITY_SUB_CATEGORIES
);

export const HEALTH_AND_MEDICAL_SUB_CATEGORIES = {
  MEDICAL_AND_CANCER_RESEARCH: "medical_and_cancer_research",
  MENTAL_HEALTH: "mental_health",
  HEALTH_AND_ILLNESS_SERVICES: "health_and_illness_services",
  DISABILITY_AND_MEDICAL_SUPPORT: "disability_and_medical_support",
  CHILDRENS_HEALTH_AND_MEDICAL_SUPPORT: "childrens_health_and_medical_support",
  BLINDNESS_AND_DEAFNESS: "blindness_and_deafness",
  DIABETES: "diabetes",
  DRUG_ALCOHOL_AND_ADDICTION: "drug_alcohol_and_addiction",
  HOSPITALS_AND_MEDICAL_CENTERS: "hospitals_and_medical_centres",
  HEART_AND_LUNG_DISEASE: "heart_and_lung_disease",
  HIV_AND_AIDS: "hiv_and_aids",
  PALLIATIVE_CARE: "palliative_care",
  SAFETY_RESCUE_AND_FIRST_AID: "safety_rescue_and_first_aid",
};

export const HEALTH_AND_MEDICAL_SUB_CATEGORIES_ARRAY = getArrayFromObject(
  HEALTH_AND_MEDICAL_SUB_CATEGORIES
);

export const DISASTER_RESPONSE = {
  BUSHFIRE_RELIEF_AND_RECOVERY: "bushfire_relief_and_recovery",
  FLOOD_RELIEF_AND_RECOVERY: "flood_relief_and_recovery",
  EARTHQUAKE_RESPONSE_AND_RECOVERY: "earthquake_response_and_recovery",
  DROUGHT_RESPONSE_AND_RECOVERY: "drought_response_and_recovery",
};

export const DISASTER_RESPONSE_ARRAY = getArrayFromObject(DISASTER_RESPONSE);

export const ARTS_AND_CULTURE = {
  LIABRARIES_AND_MUSEUMS: "libraries_and_museums",
  ARTS: "arts",
  CULTURE: "culture",
  SPORT_AND_RECREATIONY: "sport_and_recreationy",
  SCIENCE_AND_TECHNOLOGY: "science_and_technology",
  SOCIAL_AND_ENTERPRISE: "social_and_enterprise",
};

export const ARTS_AND_CULTURE_ARRAY = getArrayFromObject(ARTS_AND_CULTURE);

export const RELIGION_AND_RELIGIOUS_GROUPS = {
  CATHOLIC: "catholic",
  ORTHODOX_CHRISTIAN: "orthodox_christian",
  OTHER_CHRISTIAN: "other_christian",
  ISLAM: "islam",
  BUDDHISM: "buddhism",
  HINDUISM: "hinduism",
};

export const RELIGION_AND_RELIGIOUS_GROUPS_ARRAY = getArrayFromObject(
  RELIGION_AND_RELIGIOUS_GROUPS
);

export const OVERSEAS_AID_AND_DEVELOPMENT = {
  DISASTER_RELIEF: "disaster_relief",
  ENVIRONMENT_AND_CONSERVATION: "environment_and_conservation",
  ANIMALS_AND_WILDLIFE_SUPPORT: "animals_and_wildlife_support",
  PEOPLE: "people",
  HEALTH: "health",
  ARTS_AND_CULTURE: "arts_and_culture",
  RELIGION_AND_RELIGIOUS_GROUPS: "religion_and_religious_groups",
};

export const OVERSEAS_AID_AND_DEVELOPMENT_ARRAY = getArrayFromObject(
  OVERSEAS_AID_AND_DEVELOPMENT
);

export const CATEGORIES_OPTIONS = {
  animals_and_wildlife: "Animals & Wildlife",
  arts_and_culture: "Arts & Culture",
  disaster_response: "Disaster Response",
  environment_and_conservation: "Environment & Conservation",
  health_and_medical: "Health & Medical",
  overseas_aid_and_development: "Overseas Aid & Development",
  people_and_community: "People & Community",
  religion_and_religious_groups: "Religion & Religious Groups",
};

export const CATEGORIES_ARRAY = [
  {
    name: "Animals & Wildlife",
    value: CATEGORIES.ANIMALS_AND_WILDLIFE,
    sub_categories: ANIMALS_AND_WILDLIFE_SUB_CATEGORIES_ARRAY,
  },
  {
    name: "Arts & Culture",
    value: CATEGORIES.ARTS_AND_CULTURE,
    sub_categories: ARTS_AND_CULTURE_ARRAY,
  },
  {
    name: "Disaster Response",
    value: CATEGORIES.DISASTER_RESPONSE,
    sub_categories: DISASTER_RESPONSE_ARRAY,
  },
  {
    name: "Environment & Conservation",
    value: CATEGORIES.ENVIRONMENT_AND_CONSERVATION,
    sub_categories: ENVIRONMENT_AND_CONSERVATION_SUB_CATEGORIES_ARRAY,
  },
  {
    name: "Health & Medical",
    value: CATEGORIES.HEALTH_AND_MEDICAL,
    sub_categories: HEALTH_AND_MEDICAL_SUB_CATEGORIES_ARRAY,
  },
  {
    name: "Overseas Aid & Development",
    value: CATEGORIES.OVERSEAS_AID_AND_DEVELOPEMT,
    sub_categories: OVERSEAS_AID_AND_DEVELOPMENT_ARRAY,
  },
  {
    name: "People & Community",
    value: CATEGORIES.PEOPLE_AND_COMMUNITY,
    sub_categories: PEOPLE_AND_COMMUNITY_SUB_CATEGORIES_ARRAY,
  },
  {
    name: "Religion & Religious Groups",
    value: CATEGORIES.RELIGION_AND_RELIGIOUS_GROUPS,
    sub_categories: RELIGION_AND_RELIGIOUS_GROUPS_ARRAY,
  },
];

export const getCategorySubCategories = (sub) =>
  CATEGORIES_ARRAY?.find((val) => val?.value === sub);

export const transactionTypes = {
  oneTime: "one_time",
  subscription: "subscription",
  roundUp: "round_up",
};
