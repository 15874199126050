import React, { useContext, useMemo, useCallback, useState } from "react";

//package import

import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Link,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

//user import

import Chart from "./Chart";

// import { COLORS } from "../../common/constants";
// import { makeSubCategoryArray } from "../../common/functions";
import { DonutIcon, LetStartIcon, RectangleIcon } from "app/svg/user/Donut";
import { COLORS } from "app/common/user/constants";
import { makeSubCategoryArray } from "app/common/user/functions";
import { userRoutes } from "app/Services/user/routes/APP";
import { context } from "app/context";

//component

const Donut = () => {
  //hooks

  const { user } = useContext(context);
  const theme = useTheme();
  const navigate = useNavigate();

  //states

  const [catsPercentage, setCatsPercentage] = useState([]);

  //de structure

  const { _id, id, sub_category_percentage } = user || {};

  const getCategory = useCallback(() => {
    const arr = makeSubCategoryArray(sub_category_percentage);
    arr?.length && setCatsPercentage(arr);
  }, [sub_category_percentage]);
  //

  useMemo(() => {
    (_id || id) && sub_category_percentage?.length && getCategory();
  }, [_id, id, sub_category_percentage, getCategory]);

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        // backgroundImage: `url(/images/EllipseDonut.png)`,
        minHeight: "100vh",
        backgroundSize: "20%",
        backgroundPosition: "left top",
        backgroundRepeat: "no-repeat",
        padding: theme.spacing(2, 2, 2, 2),
      }}
    >
      {/* <Box>
        <LogoIcon textColor={theme.palette.primary.light} />
      </Box> */}
      <Container maxWidth={"md"}>
        <Typography
          color={"common.white"}
          fontWeight={"bold"}
          variant="h4"
          align="center"
          sx={{ paddingTop: { xs: 6, sm: 6, md: 0, lg: 0 } }}
        >
          Based on your selections we can see you are Up 4 Change in these
          areas.
        </Typography>
        <Grid container alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} sm={12} md={8}>
            <Box width={"135%"} height={500}>
              <Chart
                textColor={theme.palette.common.white}
                catsPercentage={catsPercentage}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              height: "100%",
            }}
          >
            <Tooltip
              title="this will be saved in your profile so you can track your impact as you start making change!"
              placement="top-end"
              color={"primary"}
              sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: theme.palette.primary.main,
                },
                [`& .${tooltipClasses.arrow}`]: {
                  color: theme.palette.common.black,
                },
              }}
              arrow
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  marginTop: {
                    xs: "-20%",
                    sm: "-20%",
                    md: "20%",
                    lg: "20%",
                  },
                }}
                alignItems={"flex-end"}
              >
                <DonutIcon />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container>
          {catsPercentage?.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  {index !== 0 && (
                    <Box height={"100%"}>
                      <RectangleIcon height={"100%"} />
                    </Box>
                  )}
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    margin={"auto"}
                  >
                    <Box
                      sx={{
                        height: 24,
                        width: 24,
                        borderRadius: "100%",
                        backgroundColor: COLORS[index % COLORS.length],
                      }}
                    ></Box>
                    <Box marginY={1}>
                      <Typography
                        variant="h3"
                        align="center"
                        fontWeight={"bold"}
                        color={"common.white"}
                      >
                        {item?.value} %
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color={"common.white"}>
                        {item?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box marginTop={2}>
            <LetStartIcon />
          </Box>
          <Box>
            <Button
              variant="contained"
              color={"primary"}
              onClick={() => navigate(userRoutes?.user + userRoutes?.discover)}
            >
              YES, LET’S GO
            </Button>
          </Box>
          <Box marginY={2}>
            <Link
              href={`${userRoutes?.user}${userRoutes?.onboardingQ1}`}
              color={"common.white"}
            >
              NO, LET ME SELECT AGAIN
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Donut;
