import React from "react";

//user import

import { Layout } from "app/components/user";
import ChangeMakersPage from "app/components/user/ChangeMakers";

//component

const ChangeMakers = () => {
  //render

  return (
    <Layout>
      <ChangeMakersPage />
    </Layout>
  );
};

export default ChangeMakers;
