const domain = process.env.REACT_APP_API_URL || 'http://localhost:8000/v1'
// const domain = 'http://localhost:8000/v1'

export const API_Urls = {
    login: `${domain}/auth/login`,
    get_users: `${domain}/users/getUsers`,
    block_user: `${domain}/auth/blockUser`,
    reset_password: `${domain}/auth/reset-password`,
    logout: `${domain}/auth/logout`,
    forget_password: `${domain}/auth/forgot-password`,
    set_new_password: `${domain}/auth/verify-code`,
    update_user: `${domain}/users`,
    get_user_detail: `${domain}/users`,
    create_org: `${domain}/organisation/create`,
    get_all_org: `${domain}/organisation/getAll`,
    get_all_feeds: `${domain}/organisation/allFeeds`,
    del_feed: `${domain}/organisation/deleteFeed`,
    query_feeds: `${domain}/user-feed/queryFeeds`,
    get_causes_by_org: `${domain}/organisation/getCausesByOrganisation`,
    get_org_detail: `${domain}/organisation/getOrganisationDetail`,
    update_org: `${domain}/organisation/update`,
    del_org: `${domain}/organisation/delete`,
    del_org_cause: `${domain}/organisation/deleteCause`,
    get_org_bank_details: `${domain}/bank/getOrgAccount`,
    getTransactions: `${domain}/transactions/all`,
    searching_orgs: `https://data.gov.au/data/api/3/action/datastore_search?resource_id=eb1e6be4-5b13-4feb-b28e-388bf7c26f93`,
    search_orgs: `https://data.gov.au/api/v0/search/organisations?`,
    interactionOnFeed: `${domain}/interaction/create`,
    getAllInteractionsByFeed: `${domain}/interaction`,
}
