import React, { Fragment } from "react";

//user import

import PaymentRoundPage from "app/components/user/PaymentRound";

const PaymentRound = () => {
  return (
    <Fragment>
      <PaymentRoundPage />
    </Fragment>
  );
};

export default PaymentRound;
