import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { context } from "app/context";

const useOrgRedirect = () => {
  const { orgDetails } = useContext(context);
  const navigate = useNavigate();

  useEffect(() => {
    if (orgDetails && !orgDetails.isBank) {
      navigate("/org/payment");
    }
  }, [orgDetails, navigate]);
};

export default useOrgRedirect;
