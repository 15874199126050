import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.01565 11.1511L0.0247958 15.4274C-0.00938527 15.5837 -0.00821352 15.7457 0.0282254 15.9015C0.0646643 16.0573 0.13545 16.2031 0.235411 16.328C0.335372 16.453 0.461984 16.554 0.605997 16.6237C0.75001 16.6935 0.907786 16.7302 1.0678 16.7312C1.14236 16.7387 1.21749 16.7387 1.29204 16.7312L5.59443 15.7403L13.855 7.51102L9.24494 2.91138L1.01565 11.1511Z"
        fill="black"
      />
      <path
        d="M16.4523 3.39111L13.3754 0.31425C13.1731 0.112984 12.8994 0 12.614 0C12.3287 0 12.0549 0.112984 11.8526 0.31425L10.1421 2.02478L14.7469 6.62964L16.4575 4.91911C16.5576 4.8185 16.6369 4.69914 16.6908 4.56786C16.7447 4.43658 16.7722 4.29595 16.7717 4.15403C16.7712 4.01211 16.7428 3.87167 16.688 3.74076C16.6331 3.60985 16.553 3.49103 16.4523 3.39111Z"
        fill="black"
      />
    </svg>
  );
};

export default EditIcon;
