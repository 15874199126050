import axios from "axios";
import { StorageKeys } from "../StorageKeys";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(StorageKeys?.token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle the error
    const { status, data } = error?.response;
    if (
      status === 403 ||
      data?.message === "Unauthorized Access to an operation"
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export function headersPost(url, data) {
  return axiosInstance.post(url, data);
}

export function simplePost(url, data) {
  return axiosInstance.post(url, data);
}

export function simpleGet(url, options) {
  return axiosInstance.get(url, options);
}
export function headersGet(url) {
  return axiosInstance.get(url);
}

export function headersDel(url) {
  return axiosInstance.delete(url);
}

export function headersUpdate(url, data) {
  return axiosInstance.put(url, data);
}
