import React, { Fragment, useState, useEffect } from "react";

//packages import

import { Form } from "react-bootstrap";
import { Formik } from "formik";

//user import
import { FaAngleDown } from "react-icons/fa";
import { typeSchema } from "app/Services/admin/validations/AddOrg";
import { orgTagsEnumsArr } from "app/common/admin/enum";

//constants

const initialValues = {
  organisation_type: "",
};

//component

export default function OrganisationName({
  formValues,
  setFormValues,
  handleNext,
}) {
  //states

  const [selected, setSelected] = useState("Type");
  const [active, setActive] = useState(false);
  const [forminitial, setForminitial] = useState(initialValues);

  //constants

  const options = [
    "Organisation Name 1",
    "Organisation Name 2",
    "Organisation Name 3",
  ];

  //life cycle hooks

  useEffect(() => {
    formValues?.name && setForminitial(formValues);
    return () => {
      setForminitial(initialValues);
    };
  }, [formValues]);

  //form submit handler

  const formSubmitHandler = (values) => {
    setFormValues((prev) => ({
      ...prev,
      organisation_type: values?.organisation_type,
    }));
    handleNext();
  };

  //render

  return (
    <Fragment>
      <Formik
        initialValues={forminitial}
        onSubmit={formSubmitHandler}
        validationSchema={typeSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="container">
              <div className="row justify-content-center my-30">
                <div className="col-lg-10">
                  {/* <div className='d-flex align-items-center'>
                                        <p className='modal-organ-add-title'>
                                            Organisation Type
                                        </p>
                                        <div className='modal-manage-titled'>
                                            <Form.Group controlId='validation01'>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Enter company type'
                                                    name='organisation_type'
                                                    value={
                                                        values.organisation_type
                                                    }
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.organisation_type
                                                    }
                                                    // onFocus={() => setActive(!active)}
                                                />
                                                <Form.Control.Feedback
                                                    type={'invalid'}
                                                >
                                                    {errors?.organisation_type}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <div className='dropdown'>
                                                {active && (
                                                    <div className='dropdown-content'>
                                                        {options.map(
                                                            (option, index) => (
                                                                <div
                                                                    key={`${index}-options-${option}`}
                                                                    onClick={e => {
                                                                        setSelected(
                                                                            option
                                                                        )
                                                                        setActive(
                                                                            false
                                                                        )
                                                                    }}
                                                                    className='dropdown-item'
                                                                >
                                                                    {option}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="d-flex align-items-center mb-3">
                    <p className="modal-organ-add-title">Organisation Type</p>
                    <div className="modal-manage-titled">
                      <div className="dropdown">
                        <div
                          className="dropdown-btn"
                          onClick={() => setActive(!active)}
                        >
                          <div>
                            {values?.organisation_type
                              ? values?.organisation_type
                              : "Organisation type"}
                          </div>
                          <div>
                            <FaAngleDown />
                          </div>
                        </div>
                        {active && (
                          <div className="dropdown-content">
                            {orgTagsEnumsArr?.map((option, index) => (
                              <div
                                key={`${index}-category-details-${option?.value}`}
                                onClick={() => {
                                  setFieldValue(
                                    "organisation_type",
                                    option?.value
                                  );
                                  setActive(false);
                                }}
                                className="dropdown-item"
                              >
                                {option?.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="invalid-feedback d-block">
                        {errors?.organisation_type}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center my-4">
              <button className={"btn btn-pink"} type={"submit"}>
                ADD
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
