import React, { useState, useContext, useEffect } from "react";
import "./feedcard.css";
//package imports

import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  Alert,
  Snackbar,
  IconButton,
  Modal,
  Dialog,
} from "@mui/material";
import {
  Favorite as FavoriteIcon,
  MonetizationOn as MonetizationOnIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

//user imports
import LoginModal from "../LoginModal";
// import LikeIcon from "../../svg/Like";
import LikeIcon from "app/svg/user/Like";
import CommentIcon from "app/svg/user/Comment";
import ShareIcon from "app/svg/user/Share";
import SupportModal from "../SupportModal";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useNavigate } from "react-router";
import {
  ReactionBarSelector,
  ReactionCounter,
  FacebookSelector,
  FacebookCounter,
  FacebookCounterReaction,
  FacebookSelectorEmoji,
} from "@charkour/react-reactions";

import { userRoutes } from "app/Services/user/routes/APP";
import { USER_FEED_TYPES } from "app/common/user/Enums";
import { API_URLS } from "app/Services/user/routes/API";
import { headersPost, simpleGet } from "app/Services/user/API";
import { formatCategory, formatDateTime } from "app/common/user/functions";
import { RenderFeedVideoOrImage } from "app/utils/user/functions";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { getReactionImage } from "app/utils/user/reactions";
import { context } from "app/context";

const convertInteractionsToCounter = (interactionsData) => {
  let counter = 0;
  const newArr = [];
  if (interactionsData.length > 0) {
    for (let i = 0; i < interactionsData.length; i++) {
      const emojiObj = {
        emoji: interactionsData[i].interaction, // String name of reaction
        by: interactionsData[i]?.user?.name, // String of persons name
        profile_pic: interactionsData[i]?.user?.profile_pic,
      };
      newArr.push(emojiObj);
      counter++;
    }
  }
  return newArr;
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 600, lg: 600 },
  height: { xs: 350, sm: 400, md: 500, lg: 600 },
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

//component

const FeedCard = ({ org, removeUserFeedFav, feedId, bhair }) => {
  const { organisation, updatedAt, id } = org || {};
  const { category, id: organisationId } = organisation || {};
  //hooks
  const matches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isLogIn = isLoggedIn();
  const { user } = useContext(context);
  const { _id: userId } = user || {};

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [showSocialInteractions, setShowSocialInteractions] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [myInteraction, setMyInteraction] = useState(null);
  const [interactionsEmojis, setInteractionsEmojis] = useState([]);
  const [showInteractionsModal, setShowInteractionsModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  // console.log('====================================')
  // console.log({interactionsEmojis})
  // console.log('====================================')

  //support handler
  const navigate = useNavigate();

  const supportHandler = () => {
    window.location.reload();
  };

  const handleCloseLogin = () => {
    setLoginModal(false);
  };

  //alert close handler

  const alertCloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //modal close handler

  const modalCloseHandler = () => {
    setOpen(false);
  };

  //fav handler

  const favoriteHandler = () => {
    if (isLogIn && id) {
      addUserFeedFav();
    } else {
      setAlertOpen(true);
      setAlertMsg("Please create a account or sign in");
      setAlertIcon("error");
    }
  };

  //fav handler

  const addUserFeedFav = async () => {
    try {
      const data = {
        user: userId,
        feed: id,
        feedType: USER_FEED_TYPES.FEED,
        organisation: organisationId,
      };
      await headersPost(API_URLS?.createFavFeed, data);
      setAlertOpen(true);
      setAlertMsg("Feed is added to your favorite successfully.");
      setAlertIcon("success");
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      setAlertOpen(true);
      setAlertMsg(message || "Error found");
      setAlertIcon("error");
    }
  };

  const handleReactionSelect = async (reaction, postId) => {
    // console.log({reaction, postId})
    setShowSocialInteractions(false);
    setMyInteraction(reaction);
    try {
      const data = {
        user: userId,
        feed: postId,
        interaction: reaction,
      };
      const response = await headersPost(API_URLS?.interactionOnFeed, data);
      // console.log({response})
      response?.data?.interactions &&
        setInteractions(response?.data?.interactions);
      setAlertOpen(true);
      setAlertMsg(response.data.message);
      setAlertIcon("success");
    } catch (error) {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};
      setAlertOpen(true);
      setAlertMsg(message || "Error found");
      setAlertIcon("error");
    }
  };

  const handleCounterClick = () => {
    setShowInteractionsModal(true);
  };

  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        const response = await simpleGet(
          `${API_URLS?.getAllInteractionsByFeed}/${feedId}`
        );
        setInteractions(response?.data?.interactions);
      } catch (error) {
        const { response } = error || {};
        const { data } = response || {};
        const { message } = data || {};
        // Handle the error as needed
        // setAlertOpen(true)
        // setAlertMsg(message || 'Error found')
        // setAlertIcon('error')
      }
    };

    if (feedId) {
      fetchInteractions();
    }
  }, [feedId]);

  useEffect(() => {
    setInteractionsEmojis(convertInteractionsToCounter(interactions));
  }, [interactions]);

  // console.log('====================================')
  // console.log({interactions})
  // console.log('====================================')

  //render

  return (
    <div id={feedId}>
      <Box
        bgcolor={theme.palette.common.white}
        padding={2}
        boxShadow={theme.shadows[10]}
        // width={{  xs:  "100%",  sm:  "100%",  md:  "100%",  lg:  "100%"  }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            // xs={3}
            // sm={3}
            // md={1}
            // lg={1}
          >
            <img
              // src={'/images/organ-logo.svg'}
              src={
                org?.organisation?.organisation_image[0] ||
                "/images/organ-logo.svg"
              }
              alt="video"
              width={"70px"}
              height={"70px"}
              style={{
                borderRadius: "100%",
                border: "3px solid #ff9776",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              marginLeft: {
                xs: "-16px",
                sm: "-16px",
                md: "0px",
                lg: "0px",
              },
            }}
          >
            <Typography
              variant="h6"
              color={"text.red"}
              sx={{
                fontWeight: "600",
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "14px",
                  ld: "14px",
                },
                marginLeft: "20px",
              }}
            >
              {org?.organisation_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "14px",
                  ld: "14px",
                },
                marginLeft: "20px",
              }}
            >
              {formatCategory(category)}{" "}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: {
                  xs: "9px",
                  sm: "9px",
                  md: "12px",
                  ld: "12px",
                },
                marginLeft: "20px",
                color: "#9f9f9f",
              }}
            >
              {formatDateTime(updatedAt)}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} lg={6}>
                        <Box display={'flex'} justifyContent={'right'}>
                            <MoreHorizIcon sx={{ color: "#E4E4E4", fontSize: "50px" }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'row',
                                    sm: 'column-reverse',
                                    md: 'row',
                                    ld: 'row',
                                },
                                marginTop: {
                                    xs: '-14px',
                                    sm: '-14px',
                                    md: '0px',
                                    ld: '0px',
                                },
                                paddingLeft: {
                                    xs: '20px',
                                    sm: '20px',
                                    md: '0px',
                                    ld: '0px',
                                },
                            }}
                            justifyContent={'right'}
                        > */}
          {/* <Button
                                variant='contained'
                                sx={{
                                    boxShadow: theme.shadows[3],
                                    padding: {
                                        xs: '0px',
                                        sm: '0px',
                                        md: '10px 20px 10px 20px',
                                        lg: '10px 20px 10px 20px',
                                    },
                                    width: {
                                        xs: '60%',
                                        sm: '60%',
                                        md: '30%',
                                        lg: '30%',
                                    },
                                    height: {
                                        xs: '35px',
                                        sm: '35px',
                                        md: '50px',
                                        ld: '50px',
                                    },
                                    marginTop: {
                                        xs: '10px',
                                        sm: '10px',
                                        md: '0px',
                                        ld: '0px',
                                    },
                                    marginLeft: {
                                        xs: '0px',
                                        sm: '0px',
                                        md: '0px',
                                        ld: '0px',
                                    },
                                    fontSize: {
                                        xs: '10px',
                                        sm: '10px',
                                        md: '13px',
                                        ld: '13px',
                                    },
                                }}
                                onClick={supportHandler}
                            >
                                <MonetizationOnIcon
                                    sx={{
                                        paddingRight: {
                                            xs: '0',
                                            sm: '0',
                                            md: '2',
                                            lg: '2',
                                        },
                                        fontSize: {
                                            xs: '15px',
                                            sm: '15px',
                                            md: '20px',
                                            lg: '20px',
                                        },
                                    }}
                                />{' '}
                                SUPPORT
                            </Button> */}
          {/* <Box
                                sx={{
                                    marginLeft: {
                                        xs: '0px',
                                        sm: '0px',
                                        md: '0px',
                                        lg: '0px',
                                    },
                                    paddingLeft: 2,
                                }}
                            > */}
          {/* <IconButton
                                    onClick={() =>
                                        isLogIn && !bhair
                                            ? removeUserFeedFav(feedId)
                                            : favoriteHandler()
                                    }
                                >
                                    <FavoriteIcon
                                        sx={{
                                            fontSize: '40px',
                                            color: theme.palette.primary.main,
                                        }}
                                    />
                                </IconButton> */}
          {/* </Box>
                        </Box>
                    </Grid> */}
        </Grid>
        <Box paddingBottom={0} marginTop={3}>
          <Typography
            variant="body2"
            textAlign={"justify"}
            fontWeight={"bold"}
            color="text.secondary"
            gutterBottom
          >
            {org?.title}
          </Typography>
          <Typography
            variant="body2"
            textAlign={"justify"}
            color="text.secondary"
            gutterBottom
          >
            {org?.description}
          </Typography>
          <Typography
            variant="body2"
            textAlign={"justify"}
            fontStyle={"italic"}
            color="text.secondary"
            gutterBottom
          >
            {org?.hashtag}
          </Typography>
        </Box>
        <Grid>
          <Box sx={{ marginTop: 1 }}>
            {org?.image?.length ? (
              <RenderFeedVideoOrImage image={org?.image[0]} name={org?.name} />
            ) : (
              <video
                src={"/images/videoplayback.mp4"}
                width="100%"
                height="auto"
                controls
                className="my-4"
              ></video>
            )}
          </Box>
        </Grid>
        <Grid sx={{ marginBottom: "10px" }}>
          <FacebookCounter
            counters={interactionsEmojis}
            user={true}
            onClick={handleCounterClick}
          />
        </Grid>
        {/* {userId && ( */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={4}
            sm={4}
            md={6}
            lg={3}
            sx={{ position: "relative", marginTop: "30px" }}
          >
            <div
              style={{
                marginTop: "20px",
                position: "absolute",
                bottom: "50px",
                left: "10px",
                zIndex: "1000",
              }}
              onMouseEnter={() => {
                setShowSocialInteractions(true);
              }}
            >
              {showSocialInteractions && (
                <FacebookSelector
                  onSelect={(e) => handleReactionSelect(e, feedId)}
                />
              )}
            </div>
            <Box
              backgroundColor={theme.palette.primary.light}
              variant="contained"
              startIcon={<LikeIcon />}
              // endIcon={<>133</>}
              // endIcon={matches && <>{interactions?.length}</>}
              sx={{
                paddingY: { xs: 1, sm: 1, md: 1, lg: 1 },
                fontWeight: {
                  xs: 300,
                  sm: 300,
                  md: 600,
                  lg: 600,
                },
              }}
              component={Button}
              fullWidth
              onClick={() => {
                !isLogIn && setLoginModal(true);
              }}
              onMouseEnter={() => {
                isLogIn && setShowSocialInteractions(true);
              }}
              onMouseLeave={() => {
                isLogIn &&
                  setTimeout(() => {
                    setShowSocialInteractions(false);
                  }, 1500);
              }}
            >
              {matches && <>{myInteraction || "Like"}</>}
            </Box>
          </Grid>
          <Modal
            open={loginModal}
            onClose={() => setLoginModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <LoginModal
                open={loginModal}
                closeHandler={handleCloseLogin}
                handleLoginModalSuccess={supportHandler}
              />
            </Box>
          </Modal>
          {/* <Grid item xs={4} sm={4} md={6} lg={3}>
                        <Box
                            backgroundColor={theme.palette.primary.light}
                            startIcon={<CommentIcon />}
                            sx={{
                                paddingY: {xs: 1, sm: 1, md: 1, lg: 1},
                                fontWeight: {
                                    xs: 300,
                                    sm: 300,
                                    md: 600,
                                    lg: 600,
                                },
                            }}
                            variant='contained'
                            endIcon={matches && <>23</>}
                            component={Button}
                            fullWidth
                        >
                            {matches && <>Comment</>}
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={6} lg={3}>
                        <Box
                            backgroundColor={theme.palette.primary.light}
                            component={Button}
                            startIcon={<ShareIcon />}
                            variant='contained'
                            endIcon={matches && <>3</>}
                            fullWidth
                            sx={{
                                paddingY: {xs: 1, sm: 1, md: 1, lg: 1},
                                fontWeight: {
                                    xs: 300,
                                    sm: 300,
                                    md: 600,
                                    lg: 600,
                                },
                            }}
                        >
                            {matches && <>Share</>}
                        </Box>
                    </Grid> */}
        </Grid>
        {/* )}  */}

        <SupportModal
          closeHandler={modalCloseHandler}
          open={open}
          feedId={id}
          organisation={organisationId}
        />
        <Modal
          open={showInteractionsModal}
          onClose={() => setShowInteractionsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <Box textAlign={"right"}>
              <IconButton
                aria-label="close"
                onClick={() => setShowInteractionsModal(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* <img
                                                    src='/images/closepopup.svg'
                                                    width={'12px'}
                                                    onClick={handleClose1}
                                                    alt={'close'}
                                                /> */}
            </Box>
            <Typography
              align="center"
              fontWeight={"bold"}
              sx={{
                // fontFamily: 'Circular Std',
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "22px",
                paddingTop: "20px",
                textAlign: "left",
              }}
            >
              Reactions ({interactions.length})
            </Typography>
            <hr />
            <div className="interaction-table">
              {interactionsEmojis &&
                interactionsEmojis.length > 0 &&
                interactionsEmojis.map((reaction, reactionIdx) => {
                  return (
                    <div key={reactionIdx} className="interaction-col">
                      <div className="interaction-pic-wrapper">
                        {reaction?.profile_pic ? (
                          <>
                            <div
                              style={{
                                position: "relative",
                                marginRight: "30px",
                              }}
                            >
                              <img
                                src={reaction?.profile_pic}
                                alt="User"
                                className="interaction-pic"
                              />
                              <img
                                className="interaction-pic-react"
                                src={getReactionImage(reaction?.emoji)}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                position: "relative",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="interaction-picNot">
                                {reaction?.by && reaction?.by[0]}
                              </div>
                              <img
                                className="interaction-pic-react"
                                src={getReactionImage(reaction?.emoji)}
                              />
                            </div>
                          </>
                        )}
                        {/* {reaction?.profile_pic ? } */}
                      </div>
                      <p style={{ color: "#000" }}>
                        <strong>{reaction?.by}</strong>
                      </p>
                    </div>
                  );
                })}
            </div>
            {/* <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-evenly'}
                            paddingTop={'40px'}
                        >
                            <Box>
                                <Button
                                    variant='contained'
                                    // onClick={() => submitHandler(values)}
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Box>

                            <Box>
                                <Button
                                    onClick={() =>
                                        setShowInteractionsModal(false)
                                    }
                                    variant='contained'
                                    sx={{
                                        padding: {
                                            xs: '10px 20px',
                                            sm: '10px 20px',
                                            md: '10px 50px',
                                            lg: '10px 50px',
                                        },
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box> */}
          </Box>
        </Modal>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={alertCloseHandler}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
        >
          <Alert
            onClose={alertCloseHandler}
            severity={alertIcon}
            sx={{ width: "100%" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default FeedCard;
