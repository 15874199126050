import React from "react";

//package import

import {
  Box,
  Container,
  Typography,
  useTheme,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//user import

import LogoIcon from "app/svg/user/Logo";
import GetSatrtedIcon from "app/svg/user/GetSatrted";
import { userRoutes } from "app/Services/user/routes/APP";

//component

const GetStarted = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  //navigate handler

  const clickHandler = () => {
    navigate(userRoutes?.user + userRoutes?.onboardingQ1);
  };

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: `url(/images/getStarted.png)`,
        minHeight: "100vh",
        backgroundSize: "cover",
        padding: theme.spacing(2, 2, 2, 2),
      }}
    >
      <Container>
        {/* <Box>
          <LogoIcon textColor={theme.palette.primary.light} />
        </Box> */}
        <Grid container>
          <Grid item xs={false} sm={3} md={3}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ paddingTop: { xs: 5, sm: 5, md: 0, lg: 0 } }}>
              <Typography
                color={"primary.light"}
                variant="h4"
                fontWeight={"bold"}
                align="center"
              >
                Hey Changemaker,
              </Typography>
              <Typography
                color={"primary.light"}
                variant="h4"
                fontWeight={"bold"}
                align="center"
              >
                ready to shake up the world?
              </Typography>
            </Box>
            <Box>
              <Typography color={"primary.light"} align="center" gutterBottom>
                Let us know what causes you’re interested in and we’ll get busy
                creating a personalised feed just for you!
              </Typography>
              <Typography color={"primary.light"} align="center" gutterBottom>
                Our resident Changemakers Shake, Rattle &amp; Roll will be
                helping you on your journey.
              </Typography>
              <Typography
                color={"primary.light"}
                fontWeight={"bold"}
                align="center"
                variant="h6"
                marginTop={2}
              >
                Ready to start making change? Click Below!
              </Typography>
            </Box>
            <Box textAlign={"center"} marginTop={2}>
              <Box>
                <GetSatrtedIcon />
              </Box>
            </Box>
            <Box
              textAlign={"center"}
              sx={{ paddingX: { xs: 2, sm: 2, md: 10, lg: 10 } }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{ paddingY: 2 }}
                onClick={clickHandler}
              >
                GET STARTED
              </Button>
            </Box>
          </Grid>
          <Grid item xs={false} sm={3} md={3}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GetStarted;
