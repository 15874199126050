import React, { useState, useEffect, useCallback } from "react";

//packages import

import { GoPrimitiveDot } from "react-icons/go";

//user import

import OrgModal from "./OrgModal";
// import { Toast } from "../../common/alert";

import PendingOrg from "./PendingOrg";
import AllOrg from "./AllOrg";
import { useNavigate } from "react-router-dom";

import { API_Urls } from "app/Services/admin/Routes/API";
import { ADMIN_URLS, APP_Urls } from "app/Services/admin/Routes/APP";
import { headersGet, headersPost, headersUpdate } from "app/Services/admin/API";
import { Toast } from "app/common/admin/alert";
import { formatDate, formatDateTime } from "app/common/admin/Functions";
import {
  orgTagsEnumsArr,
  CATEGORIES_ARRAY,
  getCategorySubCategories,
  AUS_CITIES,
  AUS_STATES,
  CATEGORIES_OPTIONS,
} from "app/common/admin/enum";
//constants
import "./organisation.css";
import "../Donation/donation.css";

const initialValues = {
  name: "",
  abn_number: "",
  category: "",
  organisation_type: "",
  company_name: "",
  sub_category: "",
  email: "",
  organisation_image: "",
  description: "",
  cause: "",
  password: "password123",
};

//component

export default function OrganisationManagement() {
  //states

  const [activeStep, setActiveStep] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [totalOrg, setTotalOrg] = useState([]);
  const [open, setOpen] = useState(false);
  const [api, setApi] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [editOrg, setEditOrg] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();

  //constants

  const userTableView = (row, index) => {
    const handleActionSelect = (e, dt) => {
      const value = e.target.value;

      if (value == "edit") {
        setEditOrg(dt?.id);
        setFormValues({
          ...dt,
          sub_category: dt?.sub_category[0],
          organisation_image: dt?.organisation_image[0],
        });
        handleShow();
      }
      if (value == "view") {
        navigate(`${APP_Urls?.admin}${ADMIN_URLS?.ORG_BILLING}/${dt?.id}`);
      }
    };
    const {
      id,
      company_name,
      email,
      name,
      createdAt,
      isActive,
      organisation_type,
      category,
      sub_category,
    } = row || {};

    return (
      <tr key={`${index}-orgs-${id}`}>
        <td>
          {id?.slice(0, 4)}...{id?.slice(20, 24)}
        </td>
        <td>{name}</td>
        <td>{email}</td>
        <td>{organisation_type || "N/A"}</td>
        {/* <td>{category || 'N/A'}</td> */}
        <td>{CATEGORIES_OPTIONS[category] || "N/A"}</td>

        <td>{sub_category || "N/A"}</td>
        {/* <td>
                    <br />
                    <span>
                        <a
                            href={`${APP_Urls?.admin}${ADMIN_URLS?.ORG_BILLING}/${id}`}
                            className='text-view'
                        >
                            View
                        </a>
                    </span>
                </td> */}
        <td>
          {formatDate(createdAt)}
          <br></br>
          <span style={{ fontSize: "12px", color: "#9094a2" }}>
            {formatDateTime(createdAt)}
          </span>
        </td>
        <td>
          <span
            className={
              isActive === true
                ? "badge btn badge-pill badge-success px-4 py-2"
                : isActive === false
                ? "badge badge-pill btn badge-danger px-3 py-2"
                : "badge badge-pill btn badge-pending px-3 py-2"
            }
            onClick={() => updateOrg(!isActive, id, email)}
          >
            {isActive ? "Active" : isActive === false ? "InActive" : "Pending"}
          </span>
        </td>
        <td>
          {/* <a
                        href={`${APP_Urls?.admin}${ADMIN_URLS?.ORG_BILLING}/${id}`}
                        className='manage'
                    >
                        VIEW/EDIT
                    </a> */}
          <select name="actions" onChange={(e) => handleActionSelect(e, row)}>
            <option value="">Select an action</option>
            <option value="view">View</option>
            <option value="edit">Edit</option>
          </select>
        </td>
      </tr>
    );
  };

  //update organisation api

  const updateOrg = (uActive, id, email) => {
    const data = {
      isActive: uActive,
      email,
    };

    setOpen(true);

    headersUpdate(`${API_Urls?.update_org}/${id}`, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          Toast.success("Organisation is updated successfully");
          setApi(true);
        } else {
          Toast.error(res?.data?.message || "");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message || "");
      });
  };

  const handleEditOrg = (orgId, data) => {
    const dataToSend = {
      name: data?.name,
      organisation_type: data?.organisation_type,
      company_name: data?.company_name,
      category: data?.category,
      email: data?.email,
      cause: data?.cause,
      description: data?.description,
      abn_number: data?.abn_number?.toString(),
      organisation_image: data?.organisation_image,
      sub_category: [data?.sub_category],
    };
    setOpen(true);
    headersUpdate(`${API_Urls?.update_org}/${orgId}`, dataToSend)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          Toast.success("Organisation is updated successfully");
          setApi(true);
          handleClose();
        } else {
          Toast.error(res?.data?.message || "");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message || "");
      });
  };

  //next active step

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  //previous active step

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //get all organisations

  const getAllOrgs = useCallback(() => {
    const url = `${API_Urls?.get_all_org}?page=${page}&perPage=${10}${
      search && `&searchQuery=${search}`
    }`;

    setOpen(true);
    setApi(false);
    headersGet(url)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setTotalPages(res?.data?.totalPages);
          setTotalOrg(res?.data?.results);
          setTotalResults(res?.data?.totalResults);
        } else {
          Toast.error(res?.data?.message || "");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(response?.data?.message || "");
      });
  }, [page]);

  //life cycle hooks

  useEffect(() => {
    api && getAllOrgs();
  }, [api, getAllOrgs]);

  // ====== set the search ======

  const DEBOUNCE_DELAY = 400;
  useEffect(() => {
    const searchQuery = search?.trim();
    const timeoutId = setTimeout(() => {
      const url = `${API_Urls?.get_all_org}?page=${page}&perPage=${10}${
        search && `&searchQuery=${searchQuery}`
      }`;

      setOpen(true);
      setApi(false);
      headersGet(url)
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            setTotalPages(res?.data?.totalPages);
            setTotalOrg(res?.data?.results);
            setTotalResults(res?.data?.totalResults);
          } else {
            Toast.error(res?.data?.message || "");
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          Toast.error(response?.data?.message || "");
        });
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [search]);

  // ====== End set the search ======

  //handle pagination

  const handlePagination = (p) => {
    setPage(p);
    setApi(true);
  };

  //create org

  const createOrg = (data) => {
    setOpen(true);

    headersPost(API_Urls?.create_org, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          Toast.success("Organisation is created successfully");
          setFormValues(initialValues);
          handleClose();
          setApi(true);
          setActiveStep(0);
        } else {
          Toast.error(
            res?.data?.message || "something went wrong while creating."
          );
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        Toast.error(
          response?.data?.message || "something went wrong while creating."
        );
      });
  };

  //render

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <section id="tabs">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {/* <a
                  className="nav-item nav-link active"
                  id="nav-roundup-tab"
                  data-toggle="tab"
                  href="#nav-roundup"
                  role="tab"
                  aria-controls="nav-roundup"
                  aria-selected="true"
                >
                  LIST OF Organisation
                </a> */}
                {/* <a
                                    className='nav-item nav-link mx-3'
                                    id='nav-fixed-tab'
                                    data-toggle='tab'
                                    href='#nav-fixed'
                                    role='tab'
                                    aria-controls='nav-fixed'
                                    aria-selected='false'
                                >
                                    PENDING Organisation{' '}
                                    <GoPrimitiveDot className='text-pink' />
                                </a> */}
              </div>
            </nav>

            <div className="tab-content px-3 px-sm-0" id="nav-tabContent">
              <AllOrg
                totalOrg={totalOrg}
                userTableView={userTableView}
                totalPages={totalPages}
                page={page}
                handleShow={handleShow}
                editOrg={editOrg}
                search={search}
                setSearch={setSearch}
                handlePagination={handlePagination}
                totalResults={totalResults}
              />
              {/* <PendingOrg
                                userTableData={totalOrg}
                                userTableView={userTableView}
                                totalPages={totalPages}
                                page={page}
                                handlePagination={handlePagination}
                                totalResults={totalResults}
                            /> */}
            </div>
          </section>
          {show && (
            <OrgModal
              show={show}
              handleClose={handleClose}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              formValues={formValues}
              setFormValues={setFormValues}
              createOrg={createOrg}
              open={open}
              editOrg={editOrg}
              handleEditOrg={handleEditOrg}
            />
          )}
        </div>
      </div>
    </div>
  );
}
