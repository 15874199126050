import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
} from "react";

//package import

import {
  useTheme,
  Box,
  Container,
  Typography,
  Avatar,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Checkbox,
  Pagination,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import { useLocation } from "react-router-dom";
// import useQuery from "../../utils/useQuery";

import InfiniteScroll from "react-infinite-scroll-component";
//components

import FeedCard from "./FeedCard";

//user imports

import useQuery from "app/utils/user/useQuery";
import {
  CATEGORIES_ARRAY,
  CATEGORIES,
  USER_FEED_TYPES,
} from "app/common/user/Enums";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";
import { userRoutes } from "app/Services/user/routes/APP";
import { API_URLS } from "app/Services/user/routes/API";
import { simpleGet } from "app/Services/user/API";
import { context } from "app/context";

//constants

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const categoriesSelectInitial = {
  all: false,
  animals_and_wildlife: false,
  environment_and_conservation: false,
  people_and_community: false,
  health_and_medical: false,
  disaster_response: false,
  arts_and_culture: false,
  religion_and_religious_groups: false,
  overseas_aid_and_development: false,
};

//component

const FeedPage = ({ bhair }) => {
  //hooks

  const theme = useTheme();
  const isLogIn = isLoggedIn();
  const { user } = useContext(context);
  const { pathname } = useLocation();
  const { _id: userId } = user || {};
  const userFeedPath = pathname?.includes(userRoutes?.user);
  const [hasMore, setHasMore] = useState(true);
  //states

  const [search] = useState("");
  const [api, setApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [categoriesSelectState, setCategoriesSelectState] = useState(
    categoriesSelectInitial
  );

  const [selectedCategories, setSelectedCategories] = useState([]);
  //get all feeds

  const query = useQuery();
  const target = query.get("target");

  // const getAllFeeds = useCallback(
  //     url => {
  //         setApi(false)
  //         setOpen(true)
  //         simpleGet(url)
  //             .then(res => {
  //                 setOpen(false)
  //                 const {data, status} = res || {}
  //                 const {
  //                     status: dataStatus,
  //                     userFeeds,
  //                     feeds,
  //                     totalPages,
  //                 } = data || {}
  //                 if (status === 200 && dataStatus) {
  //                     if (isLogIn && userFeedPath) {
  //                         const {totalPages: userTotalPages, results} =
  //                             userFeeds || {}
  //                         const newFeeds = results?.map(item => {
  //                             const {feed, id: feedId, ...rest} = item || {}
  //                             return {feedId, ...feed, ...rest}
  //                         })
  //                         const newResults = newFeeds?.sort().reverse()
  //                         setData(newResults)
  //                         setTotalPages(userTotalPages)
  //                     } else {
  //                         const newData = feeds?.sort().reverse()
  //                         setData(newData)
  //                         setTotalPages(totalPages)
  //                     }
  //                     const targetEl = document.getElementById(target)

  //                     const targetElPos =
  //                         targetEl && targetEl.getBoundingClientRect()
  //                     if (targetElPos) {
  //                         window.scrollTo(targetElPos.x, targetElPos.y)
  //                     }
  //                 } else {
  //                     setAlertOpen(true)
  //                     setAlertMsg(res?.data?.message)
  //                     setAlertIcon('warning')
  //                     setData([])
  //                     setTotalPages(1)
  //                 }
  //             })
  //             .catch(err => {
  //                 const {response} = err
  //                 setOpen(false)
  //                 setAlertOpen(true)
  //                 setAlertMsg(response?.data?.message)
  //                 setAlertIcon('error')
  //                 setData([])
  //                 setTotalPages(1)
  //             })
  //             .finally(() => {})
  //     },
  //     [isLogIn, userFeedPath]
  // )

  // const fetchFeeds = useCallback(() => {
  //     const homeUrl = `${
  //         API_URLS?.getAllFeeds
  //     }?page=${page}&limit=${50}&category=${category}${
  //         search && `&name=${search}`
  //     }`
  //     const userUrl = `${
  //         API_URLS?.getAllUserFavFeed
  //     }?page=${page}&limit=${50}&user=${userId}&feedType=${
  //         USER_FEED_TYPES.FEED
  //     }&category=${category}`
  //     if (isLogIn && userFeedPath) {
  //         userId && getAllFeeds(userUrl)
  //     } else {
  //         getAllFeeds(homeUrl)
  //     }
  // }, [page, search, category, isLogIn, userId, getAllFeeds, userFeedPath])

  // //life cycle hook

  // useEffect(() => {
  //     api && fetchFeeds()
  // }, [api, fetchFeeds])

  // //alert close handler

  // const alertCloseHandler = (_, reason) => {
  //     if (reason === 'clickaway') {
  //         return
  //     }
  //     setAlertOpen(false)
  // }

  // //category change handler

  // const categoryChangeHandler = cat => {
  //     setCategory(cat)
  //     setApi(true)
  // }

  // //page change handler

  // const handleChangePage = (_, newPage) => {
  //     setPage(newPage)
  //     setApi(true)
  // }

  // // remove fav

  // const removeUserFeedFav = async feedId => {
  //     try {
  //         await headersDel(`${API_URLS?.delUserFavFeed}/${feedId}`)
  //         setApi(true)
  //         setAlertOpen(true)
  //         setAlertMsg('Feed is remove from your favorite successfully.')
  //         setAlertIcon('success')
  //     } catch (error) {
  //         const {response} = error || {}
  //         const {data} = response || {}
  //         const {message} = data || {}
  //         setAlertOpen(true)
  //         setAlertMsg(message || 'Error found')
  //         setAlertIcon('error')
  //     }
  // }

  // //render

  //get all feeds

  let newCategoriesArray = CATEGORIES_ARRAY;

  const { isOnboard } = user || {};
  useEffect(() => {
    if (isOnboard) {
      newCategoriesArray = CATEGORIES_ARRAY.filter((cat) => {
        const catVal = cat.value;
        console.log("test", user?.category[catVal]);
        // if (user?.category[catVal]?.length > 0) {
        //     setCategoriesSelectState({
        //         ...categoriesSelectState,
        //         [catVal]: true,
        //     })
        //     setSelectedCategories([...selectedCategories, catVal])
        //     if (Array.isArray(category)) {
        //         setCategory([...category, cat?.value])
        //     } else {
        //         setCategory([cat?.value])
        //     }
        // }
        if (user?.category[catVal]?.length > 0) {
          return catVal;
        }
      });
      console.log("====================================");
      console.log({ newCategoriesArray });
      console.log("====================================");
    }
  }, [newCategoriesArray, user, isLogIn]);

  useEffect(() => {
    if (isOnboard) {
      const stateNewCatArr = newCategoriesArray.map((newCat) => {
        const catVal = newCat?.value;
        setCategoriesSelectState((prevState) => {
          return {
            ...prevState,
            [catVal]: !prevState[catVal],
          };
        });
        setSelectedCategories((prevState) => {
          return [...prevState, catVal];
        });
        // if (Array.isArray(category)) {
        setCategory((prevState) => {
          return [...prevState, newCat?.value];
        });
        // } else {
        //     setCategory([newCat?.value])
        // }
        return catVal;
      });
      console.log("====================================");
      console.log({ stateNewCatArr });
      console.log("====================================");
      if (stateNewCatArr?.length > 0) {
        setApi(true);
      }
    }
  }, [newCategoriesArray, user, isLogIn]);

  const getAllFeeds = useCallback(() => {
    setPage((prevPage) => {
      return prevPage + 1;
    });
    setApi(false);
    let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${10}${
      category && category.length > 0 ? `&category=${category.join(",")}` : ""
    }`;
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${5}${
    //     category && category !== 'all' ? `&category=${category}` : ''
    // }${search && `&name=${search}`}`
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${1}${
    //     category && category !== 'all' ? `&category=${category}` : ''
    // }${search && `&name=${search}`}`
    // let url = `${API_URLS?.getAllFeeds}?page=${page}&limit=${10}&category=${category}${search && `&name=${search}`
    //   }`;

    console.log({
      condition: category !== "all" ? category : "",
    });

    setOpen(true);
    simpleGet(url)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && res?.data?.status) {
          if (res?.data?.results?.length === 0) {
            setHasMore(false);
          } else {
            if (page === 1) {
              setData(res?.data?.results);
            } else {
              setData((prevData) => {
                return [...prevData, ...res?.data?.results];
              });
            }
          }

          setTotalPages(res?.data?.totalPages);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
          setData([]);
          setTotalPages(1);
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        // setAlertOpen(true)
        // setAlertMsg(response?.data?.message)
        // setAlertIcon('error')
        setData([]);
        setTotalPages(1);
      });
  }, [page, search, category]);

  //life cycle hook

  const fetchMoreData = () => {
    getAllFeeds();
  };

  useEffect(() => {
    api && getAllFeeds();
  }, [api, getAllFeeds]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //category change handler

  // const categoryChangeHandler = cat => {
  //     setCategory(cat)
  //     setApi(true)
  // }

  const categoryChangeHandler = (e, cat) => {
    const catVal = cat?.value;
    // setCategory(cat?.value)

    if (cat?.value && cat?.value !== "all") {
      setCategoriesSelectState((prevState) => {
        return {
          ...prevState,
          [catVal]: !prevState[catVal],
          all: false,
        };
      });
      // if (Array.isArray(category)) {
      if (!category?.includes(cat?.value)) {
        setCategory((prevState) => {
          return [...prevState, cat?.value];
        });
      } else {
        setCategory((prevState) => {
          return prevState?.filter((curCat) => cat?.value !== curCat);
        });
      }
      // } else {
      //     setCategory([cat?.value])
      // }
      if (!selectedCategories?.includes(cat?.value)) {
        setSelectedCategories((prevState) => {
          return [...prevState, cat?.value];
        });
      } else {
        setSelectedCategories((prevState) => {
          return prevState?.filter((curCat) => cat?.value !== curCat);
        });
      }
    } else {
      setCategory([]);
      setSelectedCategories([]);
      setCategoriesSelectState({ ...categoriesSelectInitial, all: true });
    }
    setHasMore(true);
    setPage(1);
    setApi(true);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setApi(true);
  };

  //render

  return (
    <Fragment>
      <Box bgcolor={theme.palette.info.light}>
        <Box paddingY={7}>
          <Container maxWidth={"lg"}>
            <Box marginY={2}>
              <Box bgcolor={theme.palette.common.white} padding={2}>
                <Box className="custom-arrow-bg-white">
                  {/* <Carousel responsive={responsive}>
                                        {CATEGORIES_ARRAY?.map(
                                            (item, index) => (
                                                <Box
                                                    key={`${item?.id}-car-${index}`}
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                >
                                                    <Checkbox
                                                        name={'category'}
                                                        // checked={
                                                        //     category ===
                                                        //     item.value
                                                        // }
                                                        checked={
                                                            categoriesSelectState[
                                                                item?.value
                                                            ] === true
                                                        }
                                                        icon={
                                                            <Box
                                                                display={'flex'}
                                                                justifyContent={
                                                                    'center'
                                                                }
                                                                sx={{
                                                                    height: 80,
                                                                    width: 80,
                                                                    borderRadius: 10,
                                                                    alignItems:
                                                                        'center',
                                                                    backgroundColor:
                                                                        theme
                                                                            .palette
                                                                            .common
                                                                            .white,
                                                                    boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                                                                }}
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        item?.url
                                                                    }
                                                                ></Avatar>
                                                            </Box>
                                                        }
                                                        checkedIcon={
                                                            <Box
                                                                display={'flex'}
                                                                justifyContent={
                                                                    'center'
                                                                }
                                                                sx={{
                                                                    height: 80,
                                                                    width: 80,
                                                                    borderRadius: 10,
                                                                    alignItems:
                                                                        'center',
                                                                    backgroundColor:
                                                                        theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                    boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                                                                }}
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        item?.url
                                                                    }
                                                                ></Avatar>
                                                            </Box>
                                                        }
                                                        // onChange={() =>
                                                        //     categoryChangeHandler(
                                                        //         item.value
                                                        //     )
                                                        // }
                                                        onChange={e =>
                                                            categoryChangeHandler(e, item)
                                                        }
                                                    />
                                                    <Typography
                                                        color={'black'}
                                                        variant='caption'
                                                        marginTop={1}
                                                    >
                                                        {item?.name}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Carousel> */}

                  <Carousel responsive={responsive}>
                    {CATEGORIES_ARRAY &&
                      CATEGORIES_ARRAY?.map((item, index) => (
                        <Box
                          key={`${item?.id}-car-${index}`}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Checkbox
                            name={"category"}
                            // checked={category === item.value}
                            checked={
                              categoriesSelectState[item?.value] === true
                            }
                            icon={
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{
                                  height: 80,
                                  width: 80,
                                  borderRadius: 10,
                                  alignItems: "center",
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                                }}
                              >
                                <Avatar src={item?.url}></Avatar>
                              </Box>
                            }
                            checkedIcon={
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{
                                  height: 80,
                                  width: 80,
                                  borderRadius: 10,
                                  alignItems: "center",
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              >
                                <Avatar src={item?.url}></Avatar>
                              </Box>
                            }
                            onChange={(e) => categoryChangeHandler(e, item)}
                          ></Checkbox>
                          <Typography
                            color={"black"}
                            variant="caption"
                            marginTop={1}
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                      ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box bgcolor={theme.palette.info.light}>
        <Box
          width={{ xs: "100%", sm: "100%", md: "60%", lg: "60%" }}
          sx={{ marginLeft: "50%", transform: "translateX(-50%)" }}
        >
          <Container maxWidth={"lg"}>
            <Typography
              color={"text.red"}
              variant="h4"
              fontWeight={"bold"}
              marginBottom={2}
            >
              YOUR FAVORITE FEEDS
            </Typography>
            {/* <Box sx={{textAlign:"center"}}> */}
            <InfiniteScroll
              dataLength={data}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4 style={{ color: "white" }}>Loading...</h4>}
              endMessage={
                <p
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {data &&
                data?.map((org, index) => (
                  <Box
                    sx={{ paddingBottom: 5 }}
                    key={`${index}-org-${org?.id}`}
                  >
                    <FeedCard
                      org={org}
                      // removeUserFeedFav={removeUserFeedFav}
                      feedId={org.id}
                      bhair={bhair}
                    />
                  </Box>
                ))}
            </InfiniteScroll>
            {/* </Box> */}
            {/* <Box
                            display={'flex'}
                            justifyContent={'center'}
                            paddingY={3}
                        >
                            <Box
                                bgcolor={theme.palette.common.white}
                                padding={1}
                                borderRadius={25}
                            >
                                <Pagination
                                    count={totalPages}
                                    color='primary'
                                    page={page}
                                    variant='contained'
                                    onChange={handleChangePage}
                                />
                            </Box>
                        </Box> */}
          </Container>
        </Box>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default FeedPage;
