import React, { Fragment } from "react";

//package import

import Hero from "./Hero";
import EasySteps from "./EasySteps";
import Last from "./Last";

//component

const RoundUpWork = () => {
  return (
    <Fragment>
      <Hero />
      <EasySteps />
      <Last />
    </Fragment>
  );
};

export default RoundUpWork;
