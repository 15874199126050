import React, { useState, useEffect, useContext } from "react";

//package import

import {
  Box,
  Container,
  Typography,
  useTheme,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

//user import
import { userRoutes } from "app/Services/user/routes/APP";
import { makeQuestionObject } from "app/common/user/functions";
import QuestionOne from "./QuestionOne";
import QuestionTwo from "./QuestionTwo";
import QuestionThree from "./QuestionThree";
import QuestionFour from "./QuestionFour";
// import { context } from "../../../context";
import { API_URLS } from "app/Services/user/routes/API";
import { headersPost } from "app/Services/user/API";
import { context } from "app/context";

//constants

const initialValues = {
  categories: [],
  sub_categories_with_cat: [],
  fund_categories: [],
  charity_categories: [],
};

//component

const BoardingQ1 = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const { user, setUser } = useContext(context);
  console.log("====================================");
  console.log({ user });
  console.log("====================================");

  //states

  const [steps, setSteps] = useState(1);
  const [api, setAPI] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //step increamentor

  const increamentStep = () => {
    steps <= 3 && setSteps((prev) => prev + 1);
  };

  //step decreamentor

  const decreamentStep = () => {
    steps > 1 && setSteps((prev) => prev - 1);
  };

  //component against steps

  const getComponent = () => {
    const getQuestionComponent = {
      1: (
        <QuestionOne
          increamentStep={increamentStep}
          setFormData={setFormData}
        />
      ),
      2: (
        <QuestionTwo
          increamentStep={increamentStep}
          decreamentStep={decreamentStep}
          formData={formData}
          setFormData={setFormData}
          setAPI={setAPI}
        />
      ),
      // 3: (
      //   <QuestionThree
      //     increamentStep={increamentStep}
      //     decreamentStep={decreamentStep}
      //     setFormData={setFormData}
      //   />
      // ),
      // 4: (
      //   <QuestionFour
      //     increamentStep={increamentStep}
      //     decreamentStep={decreamentStep}
      //     setFormData={setFormData}
      //     setAPI={setAPI}
      //   />
      // ),
    };
    return getQuestionComponent[steps];
  };

  //get background colors

  const getBackground = () => {
    const getQuestionComponent = {
      1: theme.palette.primary.light,
      2: theme.palette.primary.light,
      // 3: theme.palette.secondary.main,
      // 4: theme.palette.primary.light,
    };
    return getQuestionComponent[steps];
  };

  //get logo color

  const getLogoColor = () => {
    const getQuestionComponent = {
      1: theme.palette.common.white,
      2: theme.palette.common.white,
      // 3: theme.palette.primary.light,
      // 4: theme.palette.common.white,
    };
    return getQuestionComponent[steps];
  };

  //get sx

  const getSx = () => {
    const getQuestionComponent = {
      1: {
        backgroundColor: getBackground(),

        paddingTop: { md: 4, sm: 2, xs: 1 },
      },
      2: {
        backgroundColor: getBackground(),
        backgroundImage: "url(/images/Onboarding2.png)",
        backgroundSize: "cover",
        // paddingTop: { md: 4, sm: 2, xs: 1 },
      },
      // 3: {
      //   backgroundColor: getBackground(),

      //   paddingTop: { md: 4, sm: 2, xs: 1 },
      // },
      // 4: {
      //   backgroundColor: getBackground(),
      //   backgroundImage: 'url(/images/Q4Bg.png)',
      //   backgroundSize: '60%',
      //   backgroundPosition: 'left bottom',
      //   backgroundRepeat: 'no-repeat',
      // },
    };
    return getQuestionComponent[steps];
  };

  const addCategoryHandler = () => {
    const categories = makeQuestionObject(formData?.sub_categories_with_cat);

    console.log({ categories });
    const data = {
      category: categories,
    };
    setOpen(true);

    headersPost(
      `${API_URLS?.addUserCategories}?userId=${user?._id || user?.id}`,
      data
    )
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          console.log("====================================");
          console.log({ Res: res });
          console.log("====================================");
          setUser(res?.data?.category);
          setTimeout(() => {
            // navigate(userRoutes?.user + userRoutes?.donut)
            window.location.href = userRoutes?.user + userRoutes?.donut;
          }, 1000);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        if (response?.status === 401) {
        } else {
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        }
      });
  };
  //alert handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //life cycle hook for api

  useEffect(() => {
    api && addCategoryHandler();
  }, [api]);

  //render

  return (
    <Box sx={getSx()}>
      <Container>
        {/* <Box
          width={{ md: 180, sm: 180, xs: 150 }}
          height={{ md: 110, sm: 100, xs: 80 }}
        >
          <Logo width={'100%'} height={'100%'} textColor={getLogoColor()} />
        </Box> */}
        <Box sx={{ paddingTop: { xs: 7, sm: 7, md: 0, lg: 0 } }}>
          <Typography color={"common.white"} align="center">
            Question {steps} of 2
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            marginTop={{ md: 3, sm: 2, xs: 1.5 }}
            width={{ md: "50%", sm: "70%", xs: "80%" }}
          >
            <LinearProgress
              variant="determinate"
              sx={{
                height: 10,
                borderRadius: 5,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor:
                    theme.palette.grey[
                      theme.palette.mode === "light" ? 200 : 800
                    ],
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.main
                      : theme.palette.primary.main,
                },
              }}
              value={(steps - 1) * 50}
            />
          </Box>
        </Box>
      </Container>

      {getComponent()}
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BoardingQ1;
