import React from "react";

//package import

import PropTypes from "prop-types";
import { Box, Link, useTheme, IconButton } from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

//user import

import { userRoutes } from "app/Services/user/routes/APP";

//component

const SidebarNav = ({
  onClose,
  handleClose,
  open,
  handleToggle,
  anchorRef,
  handleClose1,
  open1,
  handleToggle1,
  anchorRef1,
  signUpHanlder,
}) => {
  //states

  const theme = useTheme();
  //render

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        onClick={() => onClose()}
        color={theme.palette.common.white}
      >
        <IconButton color="inherit">
          <CloseIcon fontSize="small" color="inherit" />
        </IconButton>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.feed}
        >
          FEED
        </Box>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.discover}
        >
          DISCOVER
        </Box>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.discover}
        >
          ROUND-UPS
        </Box>
      </Box>
      <Box margin={2}>
        <Box
          component={Link}
          marginX={2}
          sx={{
            color: theme.palette.common.white,
            textDecoration: "none",
          }}
          href={userRoutes?.contactUs}
        >
          DONATION HISTORY
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  // pages: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  // anchorRef: PropTypes.oneOfType([
  //   // Either a function
  //   PropTypes.func,
  //   // Or the instance of a DOM native element (see the note about SSR)
  //   PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  // ]).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleListKeyDown: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SidebarNav;
