import React from 'react'
import {
    Box,
    TextField,
    Typography,
    Button,
    Link,
    Divider,
    useTheme,
} from '@mui/material'

function OTPScreen({
    handleChange,
    values,
    btnState,
    handleSubmit,
    handleBack,
    handleForgotPassword,
}) {
    const theme = useTheme()

    return (
        <div style={{textAlign: 'center', padding: '30px'}}>
            <Box marginBottom={3}>
                <h3 style={{margin: '0'}}>OTP Verification</h3>
                <p style={{margin: '0', fontSize: '14px'}}>
                    Enter the OTP received at your mail
                </p>
            </Box>
            <Box marginBottom={3} sx={{textAlign: 'left'}}>
                <Typography
                    color={'common.black'}
                    fontWeight={'bold'}
                    gutterBottom
                >
                    OTP
                </Typography>
                <TextField
                    fullWidth
                    // variant={'outlined'}
                    // color='info'
                    type='number'
                    placeholder='Enter otp received in your email'
                    name='otp'
                    value={values?.otp}
                    onChange={handleChange}
                ></TextField>
                {/* <Typography
                    color={'#FF9776'}
                    fontWeight={'bold'}
                    gutterBottom
                    sx={{cursor: 'pointer', marginTop: '10px'}}
                    onClick={handleForgotPassword}
                >
                    Resend OTP
                </Typography> */}
            </Box>
            <Box marginBottom={3} sx={{textAlign: 'left'}}>
                <Typography
                    color={'common.black'}
                    fontWeight={'bold'}
                    gutterBottom
                >
                    New Password
                </Typography>
                <TextField
                    fullWidth
                    // variant={'outlined'}
                    // color='info'
                    type='password'
                    placeholder='Enter new password'
                    name='newPassword'
                    value={values?.newPassword}
                    onChange={handleChange}
                ></TextField>
            </Box>
            <Box marginBottom={3} sx={{textAlign: 'left'}}>
                <Typography
                    color={'common.black'}
                    fontWeight={'bold'}
                    gutterBottom
                >
                    Confirm New Password
                </Typography>
                <TextField
                    fullWidth
                    // variant={'outlined'}
                    // color='info'
                    type='password'
                    placeholder='Enter new password again'
                    name='confirmNewPassword'
                    value={values?.confirmNewPassword}
                    onChange={handleChange}
                ></TextField>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Button
                    variant='contained'
                    sx={{
                        boxShadow: theme.shadows[5],
                        width: '45%',
                    }}
                    disabled={btnState}
                    onClick={handleSubmit}
                    // type={'submit'}
                >
                    {btnState ? 'Processing' : 'Submit'}
                </Button>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                sx={{marginTop: '10px'}}
            >
                <Button
                    variant='outlined'
                    sx={{
                        boxShadow: theme.shadows[5],
                        width: '45%',
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Box>
        </div>
    )
}

export default OTPScreen
