import React from "react";

//user import

import { Layout } from "app/components/user";
import ContactPage from "app/components/user/ContactUs";

//component

const ContactUs = () => {
  //render

  return (
    <Layout>
      <ContactPage />
    </Layout>
  );
};

export default ContactUs;
