export const variables = {
    productPrice: 'Product Price must have 2 digits after decimal or less',
    password_match: 'Passwords must match',
    required: 'This field is required',
    IsEmail: 'Invalid Email Address',
    phoneRegExp: /^[a-zA-Z0-9\-().\s]{9,13}$/,
    notRefund: 'Please accept non-refundable conditions',
    terms: 'Please accept Terms & Conditions',
    dateRegex: /[0-9]{4}[-][0-9]{2}[-][0-9]{2}/g,
    IsNum: 'Must be a Number',
    IsPhone: 'Phone number is not valid',
    numReg: /^[0-9]+$/gi,
    IsDate: 'Invalid date',
    selectAtleastOne: 'Please select atleast one value',
    selectOneProduct: 'Please select atleast one product',
    isValidString: /^[a-zA-Z]+$/,
    isNumberString: /^[0-9a-zA-Z]+$/g,
    isNhsnumber: 'Please enter Valid NHS number',
    IsUrl: 'Please Enter a Valid Url',
    fileNotSupport: 'File is not supported',
    priorityMin: 'Priority must be greater than 0',
    priorityMax: 'Priority must be less than 1000',
    trimString: 'Please remove space before and after a string',
    isAlphabets: /^[aA-zZ\s]+$/,
    alphabetsAllow: 'Only alphabets are allowed for this field',
    bankAccountRegex: /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/,
    isAlphabet: /^[^\s].([A-Za-z]+\s)*[A-Za-z]+$/,
    isBankAccount: 'Please provide a correct bank account',
    isBsbNumber: 'Please Enter valid BSB number',
}

export const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'image/png',
]
