import React, { useState, useEffect, useContext } from "react";

//user imports

import "./payment.css";
import SuccessModal from "./SuccessModal";
import AddAccount from "../Profile/AddAccount";
import ChangePassword from "../Profile/ChangePassword";
import { context } from "app/context";

//component

export default function Payment() {
  //states

  const [show, setShow] = useState(false);
  const { orgDetails, setOrgDetails } = useContext(context);
  console.log("🚀 ~ Payment ~ orgDetails:", orgDetails);

  //modal handlers

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //render

  return (
    <div style={{ overflowY: "auto", paddingTop: 100 }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3">
            <div className="active-title">
              <p>PAYMENT</p>
            </div>
            <div className="box mb-5">
              <div className="box-main-texts">
                <p className="box-heading">ADD YOUR PREFERRED BANK ACCOUNT</p>
                <p className="box-paragraph">
                  For donations to be sent at the end of each month.
                </p>
              </div>

              <div className="row justify-content-center mt-5">
                <div className="col-lg-11">
                  <AddAccount />
                </div>
              </div>
            </div>{" "}
            <div className="box">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <p className="profile-fname text-center my-4">
                    CHANGE PASSWORD
                  </p>
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal show={show} handleClose={handleClose} />
    </div>
  );
}
