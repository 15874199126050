import React from "react";

const YouTubeIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "46"}
      height={height ? height : "46"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M35.4558 17.0555C35.3087 16.5305 35.022 16.0552 34.6262 15.6801C34.2193 15.2934 33.7206 15.0168 33.1771 14.8764C31.1431 14.3403 22.9942 14.3403 22.9942 14.3403C19.597 14.3017 16.2007 14.4717 12.8244 14.8494C12.2809 15.0001 11.7831 15.2829 11.3752 15.6725C10.9745 16.0581 10.6842 16.5335 10.5326 17.0545C10.1684 19.0167 9.99141 21.0091 10.0041 23.0048C9.99109 24.9987 10.1676 26.9904 10.5326 28.9551C10.681 29.4738 10.9702 29.9471 11.372 30.3294C11.7738 30.7118 12.2742 30.9879 12.8244 31.1342C14.8854 31.6692 22.9942 31.6692 22.9942 31.6692C26.3957 31.7079 29.7964 31.5379 33.1771 31.1601C33.7206 31.0197 34.2193 30.7432 34.6262 30.3565C35.0269 29.9742 35.3118 29.4987 35.4548 28.981C35.8285 27.0196 36.0102 25.0264 35.9974 23.0297C36.0255 21.0245 35.844 19.0219 35.4558 17.0545V17.0555ZM20.4036 26.7131V19.2975L27.1835 23.0058L20.4036 26.7131Z"
        fill={color ? color : "#230D4D"}
      />
    </svg>
  );
};

export default YouTubeIcon;
