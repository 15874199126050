import React, { Fragment, useState, useContext } from "react";

//packages import

import { dividerClasses } from "@mui/material/Divider";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useTheme,
  Button,
  Link,
  Divider,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";

//user import
import ReCAPTCHA from "react-google-recaptcha";
import { Facebook, Google } from "app/svg/user/signUp";
import Logo from "app/svg/user/Logo";

import { userRoutes } from "app/Services/user/routes/APP";
import { simplePost } from "app/Services/user/API";
import { StorageKeys } from "app/Services/user/Storage";
import { API_URLS } from "app/Services/user/routes/API";
import { addvalidation } from "app/Services/user/validations/login";
import useQuery from "app/utils/user/useQuery";

import { useAuthState } from "react-firebase-hooks/auth";
import { Verified } from "@mui/icons-material";
// import { context } from "../../context";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { InputAdornment } from "@mui/material";

import { IconButton } from "@mui/material";
import ForgotPassword from "../ForgotPassword";
import { context } from "app/context";
import { signInWithGoogle, auth, signInWithFacebook } from "app/firebase";
//constants

const initialValues = {
  email: "",
  password: "",
};

//component

const SignUpPage = () => {
  //hooks

  const { setUser, loginModalState } = useContext(context);
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoginModal, loginModal } = loginModalState || {};
  const [user, loading, error] = useAuthState(auth);

  // console.log({ user, loading, error });

  const query = useQuery();
  const referBack = query.get("referBack");
  const target = query.get("target");
  const isPopup = query.get("isPopup");
  const [showPassword, setShowPassword] = useState(false);
  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [verified, setVerified] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  //navigate handler

  const handleCloseForgotPass = () => {
    setOpenForgotPass(false);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setVerified(true);
  };

  const clickHandler = (user) => {
    const { isOnboard } = user;
    setUser(user);
    if (referBack) {
      let referBackUrl = referBack;
      if (target) {
        referBackUrl = `${referBack}?target=${target}`;
      }
      console.log({ referBackUrl });
      navigate(referBackUrl);
    } else if (isPopup) {
      setOpen(false);
      setAlertOpen(true);
      setAlertMsg("Successfully logged in!");
      setAlertIcon("success");
      setLoginModal(false);
    } else {
      if (isOnboard) {
        navigate(userRoutes?.user + userRoutes?.discover);
      } else {
        navigate(userRoutes?.user + userRoutes?.getStarted);
      }
    }
  };

  //form submit handler

  const submitHandler = (values) => {
    try {
      const data = {
        email: values?.email,
        password: values?.password,
      };

      setOpen(true);

      simplePost(API_URLS?.login, data)
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
            localStorage.setItem(
              StorageKeys?.user_info,
              JSON.stringify(res?.data?.user)
            );
            clickHandler(res?.data?.user);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            // setAlertIcon("warning");
          }
        })
        .catch((err) => {
          console.log("🚀 ~ submitHandler ~ err:", err);
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          // setAlertIcon("error");
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };
  //aleert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSocialLogin = async (google = false, facebook = false) => {
    let response;
    if (google === true) {
      response = await signInWithGoogle();
    }
    if (facebook === true) {
      response = await signInWithFacebook();
    }
    const accessToken = response?.user?.accessToken;
    setOpen(true);

    simplePost(API_URLS?.socialLogin, { accessToken })
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          localStorage.setItem(
            StorageKeys?.user_info,
            JSON.stringify(res?.data?.user)
          );
          clickHandler(res?.data?.user);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ handleSocialLogin ~ err:", err);
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
    // if(response) {
    //   console.log(response);
    //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
    //   navigate(userRoutes?.user + userRoutes?.getStarted);
    // }
  };

  // const signUpWithGoogle = async () => {
  //     const response = await signInWithGoogle()
  //     const accessToken = response?.user?.accessToken
  //     setOpen(true)

  //     simplePost(API_URLS?.socialLogin, {accessToken})
  //         .then(res => {
  //             setOpen(false)
  //             if (res?.status === 200) {
  //                 localStorage.setItem(StorageKeys?.token, res?.data?.tokens)
  //                 clickHandler(res?.data?.user)
  //             } else {
  //                 setAlertOpen(true)
  //                 setAlertMsg(res?.data?.message)
  //                 setAlertIcon('warning')
  //             }
  //         })
  //         .catch(err => {
  //             const {response} = err
  //             setOpen(false)
  //             setAlertOpen(true)
  //             setAlertMsg(response?.data?.message)
  //             setAlertIcon('error')
  //         })
  //     // if(response) {
  //     //   console.log(response);
  //     //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
  //     //   navigate(userRoutes?.user + userRoutes?.getStarted);
  //     // }
  // }

  // const signUpWithFacebook = async () => {
  //     const response = await signInWithFacebook()
  //     console.log({fbRes: response})
  //     // const accessToken = response?.user?.accessToken
  //     // setOpen(true)

  //     // simplePost(API_URLS?.socialLogin, {accessToken})
  //     //     .then(res => {
  //     //         setOpen(false)
  //     //         if (res?.status === 200) {
  //     //             localStorage.setItem(StorageKeys?.token, res?.data?.tokens)
  //     //             clickHandler(res?.data?.user)
  //     //         } else {
  //     //             setAlertOpen(true)
  //     //             setAlertMsg(res?.data?.message)
  //     //             setAlertIcon('warning')
  //     //         }
  //     //     })
  //     //     .catch(err => {
  //     //         const {response} = err
  //     //         setOpen(false)
  //     //         setAlertOpen(true)
  //     //         setAlertMsg(response?.data?.message)
  //     //         setAlertIcon('error')
  //     //     })
  //     // if(response) {
  //     //   console.log(response);
  //     //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
  //     //   navigate(userRoutes?.user + userRoutes?.getStarted);
  //     // }
  // }
  //render

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: theme.palette.info.light,
          backgroundImage: `url(/images/EllipseDonut.png)`,
          backgroundRepeat: "no-repeat",

          backgroundSize: "20%",
        }}
      >
        <Box
          padding={theme.spacing(2, 2, 2, 2)}
          component="a"
          display={"block"}
          underline="none"
          href="/"
        >
          <Logo textColor={theme.palette.common.white} />
        </Box>
        <Typography
          color={"common.white"}
          variant="h6"
          align="center"
          sx={{
            marginTop: 5,
          }}
        >
          USER
        </Typography>
        <Typography
          color={"common.white"}
          variant="h4"
          align="center"
          sx={{
            marginTop: 1,
          }}
        >
          SIGN IN
        </Typography>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Box
            sx={{
              border: `1px solid #FFD16B`,
              width: "10%",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.info.light,
          backgroundImage: `url(/images/EllipseSignUp.png)`,
          backgroundPosition: "right bottom",
          backgroundSize: "35%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          <Grid item xs={false} sm={false} md={1}></Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box minHeight={"90vh"}>
              <Container maxWidth={"md"}>
                <Box
                  sx={{
                    paddingX: { md: 10, sm: 4, xs: 2 },
                    paddingY: { md: 2, sm: 2, xs: 2 },
                  }}
                >
                  <Formik
                    initialValues={initialValues}
                    onSubmit={submitHandler}
                    validationSchema={addvalidation}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Enter Email
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            placeholder="Enter Your Email"
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                            error={touched.email && Boolean(errors.email)}
                          ></TextField>
                        </Box>
                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Password
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            // type='password'
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Your Password"
                            name="password"
                            value={values?.password}
                            onChange={handleChange}
                            helperText={touched.password && errors.password}
                            error={touched.password && Boolean(errors.password)}
                            InputProps={{
                              endAdornment: showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ),
                            }}
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //       <IconButton
                            //         onClick={()=>setShowPassword(!showPassword)}
                            //         // onMouseDown={}
                            //       >
                            //         {showPassword ? <Visibility /> : <VisibilityOff />}
                            //       </IconButton>
                            //     </InputAdornment>
                            //   }
                          />
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          paddingBottom={"20px"}
                        >
                          <ReCAPTCHA
                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={onChange}
                          />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                          <Button
                            variant="contained"
                            sx={{
                              boxShadow: theme.shadows[5],
                              width: "45%",
                              "&.Mui-disabled": {
                                backgroundColor: "#FF9776",
                                cursor: "not-allowed",
                                color: "#fff",
                              },
                            }}
                            type={"submit"}
                            disabled={!verified}
                          >
                            Sign In
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>

                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Typography color={"common.white"}>
                      Don't remember password?&nbsp;
                    </Typography>

                    <Link
                      color={"common.white"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setOpenForgotPass(true)}
                    >
                      Forgot Password
                    </Link>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Typography color={"common.white"}>
                      Don't have an account?&nbsp;
                    </Typography>
                    <Link color={"common.white"} href={userRoutes?.signUp}>
                      Sign Up
                    </Link>
                  </Box>

                  <Box
                    paddingTop={2}
                    color={"common.white"}
                    width={400}
                    margin="auto"
                  >
                    <Divider
                      sx={{
                        [`&.${dividerClasses.root}`]: {
                          "&::before": {
                            borderTop: "thin solid white",
                          },
                          "&::after": {
                            borderTop: "thin solid white",
                          },
                        },
                      }}
                    >
                      OR
                    </Divider>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                      sx={{
                        backgroundColor: "#475993",
                        width: "70%",
                      }}
                      variant="contained"
                      startIcon={<Facebook />}
                      onClick={() => handleSocialLogin(false, true)}
                    >
                      <Typography>Sign In via Facebbok</Typography>
                    </Button>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                      sx={{
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        width: "70%",
                      }}
                      variant="contained"
                      startIcon={<Google />}
                      onClick={() => handleSocialLogin(true, false)}
                    >
                      <Typography>Sign In via Google</Typography>
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openForgotPass && (
        <ForgotPassword
          open={openForgotPass}
          closeHandler={handleCloseForgotPass}
        />
      )}
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SignUpPage;
