import {Box} from '@mui/material'
import {memo} from 'react'

export const GetImageOrVideo = memo(({image}) => {
    if (typeof image === 'string' && image) {
        const type = image?.split('.').pop()
        switch (type) {
            case 'jpeg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={image}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )
            case 'jpg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={image}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )

            case 'png':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={image}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )

            case 'mp4':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={image}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )

            case 'ogg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={image}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )
            case 'webm':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={image}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )
            default:
                return <></>
        }
    } else {
        const type = image?.type || ''
        const url = image ? URL.createObjectURL(image) : ''
        switch (type) {
            case 'image/jpeg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={url}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )
            case 'image/jpg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={url}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )

            case 'image/png':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <img
                            width={'100%'}
                            src={url}
                            alt={'feed post'}
                            height={'450px'}
                        />
                    </Box>
                )

            case 'video/mp4':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={url}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )

            case 'video/ogg':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={url}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )
            case 'video/webm':
                return (
                    <Box sx={{overflow: 'hidden'}}>
                        <video
                            width={'100%'}
                            src={url}
                            controls
                            height={'450px'}
                        />
                    </Box>
                )
            default:
                return <></>
        }
    }
})

export const renderFeedVideoOrImage = image => {
    if (typeof image === 'string' && image) {
        const type = image?.split('.').pop()
        switch (type) {
            case 'jpeg':
                return <img width={'100%'} src={image} alt={'feed post'} />
            case 'jpg':
                return <img width={'100%'} src={image} alt={'feed post'} />

            case 'png':
                return <img width={'100%'} src={image} alt={'feed post'} />

            case 'mp4':
                return <video width={'100%'} src={image} controls />

            case 'ogg':
                return <video width={'100%'} src={image} controls />
            case 'webm':
                return <video width={'100%'} src={image} controls />
            default:
                return <></>
        }
    }
}

export const isFile = input => {
    if ('File' in window && input instanceof File) return true
    else return false
}

export const isBlob = input => {
    if ('Blob' in window && input instanceof Blob) return true
    else return false
}
