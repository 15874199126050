import React, { Fragment } from "react";

//package import

import { Box, Grid, Typography, useTheme } from "@mui/material";

//user import

import HeartLogo from "app/svg/user/Heart";
import MotoStraight from "app/svg/user/MotoStraight";
import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import AnimationOnScroll from "react-animation-on-scroll";
import "animate.css/animate.min.css";
// import "animate.css/animate.compat.css"
//component

const FreeGiving = () => {
  //hooks

  const theme = useTheme();

  const bounceAnimation = keyframes`${bounce}`;

  const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;

  //render

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          padding: theme.spacing(2, 2, 0, 2),
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              We
            </AnimationOnScroll>
          </Typography>
          <Box padding={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              <HeartLogo width={50} />
            </AnimationOnScroll>
          </Box>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              hassle free giving!
            </AnimationOnScroll>
          </Typography>
        </Box>
        <Grid container display={"flex"}>
          <Grid item xs={false} sm={false} md={2} />
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              color={"common.white"}
              fontWeight={"bold"}
              align="center"
            >
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Up 4 Change is a 'social roundup' donation platform that
                provides a simple way to connect to causes that YOU care about.
              </AnimationOnScroll>
            </Typography>
            <Typography
              color={"common.white"}
              fontWeight={"bold"}
              align="center"
            >
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                And… we make it so easy to help make real change you can do it
                in your sleep!
              </AnimationOnScroll>
            </Typography>
          </Grid>
          <Grid item xs={false} sm={false} md={2} paddingLeft={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={6}
              animateIn="animate__bounceOutUp"
            >
              <MotoStraight
                width={100}
                height={120}
                color={theme.palette.primary.main}
              />
            </AnimationOnScroll>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: theme.spacing(2, 2, 0, 2),
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              We
            </AnimationOnScroll>
          </Typography>
          <Box padding={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              <HeartLogo width={50} />
            </AnimationOnScroll>
          </Box>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              generous people
            </AnimationOnScroll>
          </Typography>
        </Box>
        <Grid container display={"flex"}>
          <Grid item xs={false} sm={false} md={2} paddingRight={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={6}
              animateIn="animate__bounceOutUp"
            >
              <MotoStraight
                width={100}
                height={120}
                color={theme.palette.secondary.main}
              />{" "}
            </AnimationOnScroll>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              color={"common.white"}
              fontWeight={"bold"}
              align="center"
            >
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                We put love and transparency back into donating, encouraging
                people to give more without asking for more.
              </AnimationOnScroll>
            </Typography>
            <Typography
              color={"common.white"}
              fontWeight={"bold"}
              align="center"
            >
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                And the best part, you can see where your money is being
                utilised!
              </AnimationOnScroll>
            </Typography>
          </Grid>
          <Grid item xs={false} sm={false} md={2}></Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          padding: theme.spacing(2, 2, 0, 2),
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              We
            </AnimationOnScroll>
          </Typography>
          <Box padding={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              <HeartLogo width={50} />
            </AnimationOnScroll>
          </Box>
          <Typography variant="h3" color={"common.white"} fontWeight={"bold"}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={1}
              animateIn="animate__fadeInUp"
            >
              global change
            </AnimationOnScroll>
          </Typography>
        </Box>

        <Typography align="center" color={"text.light"} fontWeight={"bold"}>
          <AnimationOnScroll
            initiallyVisible={false}
            duration={1}
            animateIn="animate__fadeInUp"
          >
            Feel like your small change can't make a difference?
          </AnimationOnScroll>
        </Typography>
        <Typography
          variant="h4"
          color={"text.light"}
          fontWeight={"bold"}
          sx={{
            textTransform: "uppercase",
          }}
          align="center"
        >
          <AnimationOnScroll
            initiallyVisible={false}
            duration={1}
            animateIn="animate__fadeInUp"
          >
            <i>Think again!</i>
          </AnimationOnScroll>
        </Typography>
        <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
          <Box
            sx={{
              borderBottom: `2px solid ${theme.palette.text.secondary}`,
              width: "10%",
            }}
          ></Box>
        </Box>
        <Grid container display={"flex"}>
          <Grid item xs={false} sm={false} md={2}></Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              variant="h5"
              color={"text.light"}
              fontWeight={"bold"}
              align="center"
            >
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Small change can create a massive impact when we all work
                together!
              </AnimationOnScroll>
            </Typography>
            <Typography variant="h6" color={"text.light"} align="center">
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                By combining all of our comment cents we can create global
                change.
              </AnimationOnScroll>
            </Typography>
          </Grid>
          <Grid item xs={false} sm={false} md={2} paddingLeft={2}>
            <AnimationOnScroll
              initiallyVisible={false}
              duration={6}
              animateIn="animate__bounceOutUp"
            >
              <MotoStraight
                width={100}
                height={120}
                color={theme.palette.secondary.light}
              />{" "}
            </AnimationOnScroll>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FreeGiving;
