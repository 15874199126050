import React from "react";

const LoveIcon = () => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.103 2.25938C22.8206 0.802382 21.0416 0 19.0938 0C16.3542 0 14.6198 1.63832 13.6472 3.01275C13.3949 3.3694 13.1802 3.72701 13 4.06352C12.8198 3.72701 12.6052 3.3694 12.3528 3.01275C11.3802 1.63832 9.6458 0 6.90625 0C4.95838 0 3.17936 0.802433 1.89693 2.25943C0.673715 3.64931 0 5.51076 0 7.50085C0 9.66713 0.844644 11.682 2.65814 13.8416C4.27893 15.7718 6.61065 17.7614 9.31074 20.0653C10.3169 20.9238 11.3573 21.8116 12.465 22.782L12.4983 22.8112C12.6419 22.9371 12.8209 23 13 23C13.1791 23 13.3581 22.937 13.5017 22.8112L13.535 22.782C14.6427 21.8116 15.6831 20.9238 16.6894 20.0652C19.3893 17.7614 21.7211 15.7718 23.3419 13.8416C25.1554 11.6819 26 9.66713 26 7.50085C26 5.51076 25.3263 3.64931 24.103 2.25938Z"
        fill="#FC8965"
      />
    </svg>
    // <svg
    //   width="26"
    //   height="23"
    //   viewBox="0 0 24 21"

    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M11.6873 5.65087L10.8559 4.09847C10.7094 3.82501 10.5367 3.5375 10.3356 3.25333L10.3356 3.25331C9.5511 2.14471 8.24117 0.943134 6.20888 0.943134C4.72762 0.943134 3.38654 1.54877 2.41338 2.65438L11.6873 5.65087ZM11.6873 5.65087L12.5187 4.09847M11.6873 5.65087L12.5187 4.09847M12.5187 4.09847C12.6651 3.82509 12.8379 3.53756 13.039 3.25331L12.5187 4.09847ZM3.11199 11.8374L3.112 11.8374C4.50761 13.4995 6.53451 15.2327 8.98275 17.3217L8.98276 17.3217L8.98483 17.3234C9.84582 18.0581 10.7393 18.8205 11.6873 19.6492C12.6322 18.8232 13.523 18.0631 14.3815 17.3306L14.392 17.3216L14.392 17.3216C16.8401 15.2327 18.867 13.4995 20.2626 11.8374L20.2626 11.8374C21.7941 10.0136 22.4315 8.40947 22.4315 6.74344C22.4315 5.16509 21.8975 3.71827 20.9612 2.65436C19.9881 1.54876 18.647 0.943134 17.1657 0.943134C15.1335 0.943134 13.8235 2.14467 13.0391 3.25325L3.11199 11.8374ZM3.11199 11.8374C1.58055 10.0137 0.943134 8.40948 0.943134 6.74344C0.943134 5.16509 1.47707 3.71828 2.41334 2.65442L3.11199 11.8374Z"
    //     stroke="#FC8965"
    //     stroke-width="1.88627"
    //   />
    // </svg>
  );
};

export default LoveIcon;
