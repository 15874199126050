import React, { useState, Fragment, useEffect } from "react";
//packages import

import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { FaAngleDown } from "react-icons/fa";
import { detailSchema } from "app/Services/admin/validations/AddOrg";
import {
  CATEGORIES_ARRAY,
  getCategorySubCategories,
} from "app/common/admin/enum";

//user import

//constants

const initialValues = {
  name: "",
  abn_number: "",
  category: "",
  sub_category: "",
  organisation_type: "",
  company_name: "",
};

//component

export default function OrganisationType({
  setFormValues,
  formValues,
  handleNext,
}) {
  //states

  const [active, setActive] = useState(false);
  const [formInitial, setFormInitial] = useState(initialValues);
  const [subCategories, setSubCategories] = useState([]);
  const [sub_active, setSubActive] = useState(false);
  const [selected_category, setSelectedCategory] = useState(null);
  //life cycle hooks

  useEffect(() => {
    formValues?.name && setFormInitial(formValues);
    formValues?.category && setSubCategory(formValues?.category);
    return () => {
      setFormInitial(initialValues);
    };
  }, [formValues]);

  useEffect(() => {
    console.log({ selected_category });
    if (selected_category) {
      setSubCategory(selected_category);
    }
  }, [selected_category]);

  //form submit handler

  const formSubmitHandler = (values) => {
    setFormValues((prev) => ({
      ...prev,
      name: values?.name,
      abn_number: values?.abn_number,
      category: values?.category,
      sub_category: values?.sub_category,
      organisation_type: values?.organisation_type,
      company_name: values?.company_name,
    }));

    handleNext();
  };

  const setSubCategory = (category) => {
    const subCat = getCategorySubCategories(category);
    const { sub_categories } = subCat || {};
    sub_categories && setSubCategories(sub_categories);
  };

  //render

  return (
    <Fragment>
      <Formik
        initialValues={formInitial}
        onSubmit={formSubmitHandler}
        validationSchema={detailSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="container">
              <div className="row justify-content-center my-30 ">
                <div className="col-lg-10">
                  <div className="text-center">
                    {/* <div className='organisationType-logo'>
                                            <img
                                                src='/images/mouse.jpg'
                                                alt='organ-type-logo'
                                            />
                                        </div> */}
                    {/* <p className='organisation-name-type py-5'>
                                            {values?.name}
                                        </p> */}

                    {/* <div className='d-flex align-items-center mb-3'>
                                            <p className='modal-organ-add-title'>
                                                Organisation TYPE
                                            </p>
                                            <div className='modal-manage-titled'>
                                                <Form.Group controlId='validation01'>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='Enter organisation type'
                                                        name='organisation_type'
                                                        value={
                                                            values.organisation_type
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.organisation_type
                                                        }
                                                        disabled={true}
                                                        // onFocus={() => setActive(!active)}
                                                    />
                                                    <Form.Control.Feedback
                                                        type={'invalid'}
                                                    >
                                                        {
                                                            errors?.organisation_type
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div> */}

                    <div className="d-flex align-items-center mb-3 w-100">
                      <p className="modal-organ-add-title w-25">COMPANY NAME</p>
                      <div className="modal-manage-titled w-75">
                        <Form.Group controlId="validation02">
                          <Form.Control
                            type="text"
                            placeholder="Enter company name"
                            name="company_name"
                            value={values.company_name}
                            onChange={handleChange}
                            isInvalid={!!errors.company_name}
                            // onFocus={() => setActive(!active)}
                          />
                          <Form.Control.Feedback type={"invalid"}>
                            {errors?.company_name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 w-100">
                      <p className="modal-organ-add-title w-25">ABN NUMBER</p>
                      <div className="modal-manage-titled w-75">
                        <Form.Group controlId="validation03">
                          <Form.Control
                            type="number"
                            placeholder="Enter ABN number"
                            name="abn_number"
                            value={values.abn_number}
                            onChange={handleChange}
                            isInvalid={!!errors.abn_number}
                            // onFocus={() => setActive(!active)}
                          />
                          <Form.Control.Feedback type={"invalid"}>
                            {errors?.abn_number}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3 w-100">
                      <p className="modal-organ-add-title w-25">CATEGORY</p>
                      <div className="modal-manage-titled w-75">
                        <div className="dropdown">
                          <div
                            className="dropdown-btn "
                            onClick={(e) => setActive(!active)}
                          >
                            <div>
                              {values?.category
                                ? values?.category?.replace(/_/g, " ")
                                : "please select a category"}
                            </div>
                            <div>
                              <FaAngleDown />
                            </div>
                          </div>
                          {active && (
                            <div className="dropdown-content">
                              {CATEGORIES_ARRAY?.map((option, index) => (
                                <div
                                  key={`${index}-categories-${option?.value}`}
                                  onClick={(e) => {
                                    setSelectedCategory(option?.value);
                                    setFieldValue("category", option?.value);
                                    setActive(false);
                                  }}
                                  className="dropdown-item"
                                >
                                  {option?.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="invalid-feedback d-block">
                          {errors?.category}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 w-100">
                      <p className="modal-organ-add-title w-25">SUB CATEGORY</p>
                      <div className="modal-manage-titled w-75">
                        <div className="dropdown">
                          <div
                            className="dropdown-btn"
                            onClick={() => setSubActive(!sub_active)}
                          >
                            <div>
                              {values?.sub_category
                                ? values?.sub_category
                                : "select a sub category"}
                            </div>
                            <div>
                              <FaAngleDown />
                            </div>
                          </div>
                          {sub_active && (
                            <div className="dropdown-content">
                              {subCategories?.map((option, index) => (
                                <div
                                  key={`${index}-sub_categories-${option.name}`}
                                  onClick={() => {
                                    setFieldValue("sub_category", option.value);
                                    setSubActive(false);
                                  }}
                                  className="dropdown-item"
                                >
                                  {option.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="invalid-feedback d-block">
                          {errors?.sub_category}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center my-4">
              <button className={"btn btn-pink"} type={"submit"}>
                ADD
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
