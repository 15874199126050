import React, { Fragment } from "react";

//package import

import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
//user import

import { CoinIcon } from "app/svg/user/ChangeMakers";

//const

const changeMakersArray = [
  {
    id: 1234,
    name: "Lauren West",
    url: "/images/ChangeMaker1.png",
  },
  {
    id: 1235,
    name: "Natalie Howell ",
    url: "/images/ChangeMaker2.png",
  },
  {
    id: 1236,
    name: "Nathan Anderson",
    url: "/images/ChangeMaker3.png",
  },
  {
    id: 1237,
    name: "Patrick Clarke",
    url: "/images/ChangeMaker4.png",
  },
];

//component

const RoundUpwork = () => {
  //hooks

  const theme = useTheme();

  //render
  const matches = useMediaQuery("(min-width:1025px)");
  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          backgroundImage: `url(/images/ChangeEllipse.png)`,
          backgroundSize: "25%",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          padding: theme.spacing(6, 0, 2, 2),
          minHeight: "100vh",
        }}
      >
        <Grid container>
          <Grid item xs={false} sm={false} md={2} />
          <Grid item xs={12} sm={12} md={6}>
            <Box padding={2}>
              <Typography
                variant="h4"
                align="center"
                fontWeight={"bold"}
                color={"common.white"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Thanks for being a changemaker!
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                sx={{
                  border: `2px solid ${theme.palette.common.white}`,
                  width: "60%",
                }}
              ></Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Box>
                <CoinIcon />
              </Box>
            </Box>
            <Typography
              marginBottom={2}
              marginTop={2}
              fontWeight={"bold"}
              color={"common.white"}
              variant="h5"
            >
              A changemaker is someone who desires change in the world and makes
              it happen!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                padding: 2,
                // borderRadius: 2,
                borderTopLeftRadius: 7,
                borderBottomLeftRadius: 7,
              }}
            >
              <Grid container>
                {changeMakersArray?.map((ch, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={6}
                    key={`${index}-changes-makers-${ch?.id}`}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      padding={2}
                    >
                      <Box
                        width={{ md: 160, sm: 150, sm: 150, lg: 170 }}
                        height={{ md: 160, sm: 150, sm: 150, lg: 170 }}
                      >
                        <img
                          src={ch?.url}
                          alt={"asdd"}
                          width={"100%"}
                          height={"100%"}
                        />
                      </Box>
                      <Typography fontWeight={"bold"}>{ch?.name}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={false} sm={false} md={2} />
          <Grid item xs={12} sm={12} md={7}>
            <Box marginTop={2} marginBottom={2}>
              <Typography
                color={"common.white"}
                variant="h6"
                marginBottom={2}
                gutterBottom
              >
                Many of us desire change and even want to help but just don’t
                know how to go about it, or feel that the problems of society
                are just too large to make a real difference.
              </Typography>
              <Typography color={"common.white"} variant="h6">
                Up 4 Change helps connects you to causes YOU care about and
                enables you to make collective change NOW!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box paddingY={3} bgcolor={theme.palette.primary.main}>
        <Typography
          color={"common.white"}
          align="center"
          fontWeight={"bold"}
          variant="h5"
        >
          SEARCH FOR ORGANISATION TO SUPPORT
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            sx={{
              width: "20%",
              marginTop: 1,
              borderBottom: `2px solid ${theme.palette.common.white}`,
            }}
          ></Box>
        </Box>
        <Box paddingY={3}>
          <Container>
            <Box
              display={{ md: "flex", sm: "flex" }}
              justifyContent={{ sm: "center" }}
            >
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  placeholder="KEYWORDS"
                  variant="standard"
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: "19.8px 14px",
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                ></TextField>
              </Box>
              {matches && (
                <img
                  src="/images/Line 74.svg"
                  style={{ backgroundColor: " white" }}
                />
              )}
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  placeholder="-CATEGORY-"
                  variant="standard"
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: 1,
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                    padding: "19.8px 14px",
                  }}
                  select
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      color: "grey",
                    },
                  }}
                  value={""}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  <MenuItem value={""} disabled>
                    -CATEGORY-
                  </MenuItem>
                </TextField>
              </Box>
              {matches && (
                <img
                  src="/images/Line 74.svg"
                  style={{ backgroundColor: " white" }}
                  alt={"changes"}
                />
              )}
              <Box width={{ md: "30%", sm: "50%", xs: "100%" }}>
                <TextField
                  placeholder="-LOCATION-"
                  variant="standard"
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    border: "none",
                    padding: 1,
                    fontFamily: "Circular Std",
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "450",
                    lineHeight: "20px",
                    letterSpacing: "1px",
                    padding: "19.8px 14px",
                  }}
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  value={""}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value={""} disabled>
                    -LOCATION-
                  </MenuItem>
                </TextField>
              </Box>
              <Box width={{ md: "10%", sm: "50%", xs: "100%" }}>
                <Box color={theme.palette.common.white} width={"100%"}>
                  <Button
                    color="secondary"
                    variant={"contained"}
                    sx={{
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <SearchIcon color="inherit" width={"100%"} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          height: "20vh",
        }}
      ></Box>
    </Fragment>
  );
};

export default RoundUpwork;
