import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//user import

import { HeroIcon } from "app/svg/user/UpWorks";

//component

const Hero = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: `url(/images/ContactEllipse.png)`,
        backgroundSize: "20%",
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
        padding: theme.spacing(6, 0, 2, 2),
        paddingY: 4,
        paddinX: 2,
      }}
    >
      <Container>
        <Box paddingTop={{ xs: 5 }}>
          <Typography
            variant="h4"
            color={"secondary.dark"}
            align="center"
            fontWeight={"bold"}
            sx={{
              textTransform: "uppercase",
            }}
          >
            How do round ups work?
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.secondary.dark}`,
              width: "25%",
            }}
          ></Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={4}>
          <Box width={270} height={230}>
            <HeroIcon
              width={"100%"}
              height={"100%"}
              color={theme.palette.secondary.light}
              sideColor={theme.palette.common.white}
            />
          </Box>
        </Box>
        <Box marginBottom={2}>
          <Typography
            color={"text.light"}
            align="center"
            variant="h6"
            fontWeight={"bold"}
          >
            By simply going about your everyday life! Making change shouldn’t be
            difficult!
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
