import * as Yup from "yup";

import { variables } from "../variables";

const { required } = variables;

export const addValidation = Yup.object({
  organisation: Yup.string().required(required),
  title: Yup.string().required(required),
  // email: Yup.string().required(required).email(IsEmail),
  org_email: Yup.string(),
  description: Yup.string().required(required),
  organisation_tag: Yup.string().required(required),
  micro_goal1_name: Yup.string(),
  micro_goal1_value: Yup.string().nullable(),
  micro_goal1_icon: Yup.string(),
  micro_goal1_image: Yup.string(),
  micro_goal2_name: Yup.string(),
  micro_goal2_value: Yup.string().nullable(),
  micro_goal2_icon: Yup.string(),
  micro_goal2_image: Yup.string(),
  micro_goal3_name: Yup.string(),
  micro_goal3_value: Yup.string().nullable(),
  micro_goal3_icon: Yup.string(),
  micro_goal3_image: Yup.string(),
  fund_raising_goal: Yup.string().required(required),
  category: Yup.string().required(required),
  sub_category: Yup.array().min(1, required).required(),
  // cause_image: Yup.string().required(required),
  cause_image: Yup.mixed()
    .required(required)
    .test(
      "fileSize",
      "File size should be less than 4MB",
      (value) => value && value.size <= 4 * 1024 * 1024
    ),
  location: Yup.string().required(required),
});

export const editValidation = Yup.object({
  // organisation: Yup.string().required(required),
  name: Yup.string().required(required),
  cause: Yup.string().required(required),
  description: Yup.string().required(required),
});
