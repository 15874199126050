import React, { useCallback, useEffect, useState } from "react";

//package import

import { Navigate, useLocation } from "react-router-dom";
import { decodeToken, isExpired } from "react-jwt";

//user imports

import { StorageKeys } from "../../Storage";
import { userRoutes } from "./index";
import { simpleGet } from "../../API";
import { API_URLS } from "../API";

//component

export const UserPrivateRoute = ({ element: Component, ...rest }) => {
  //hooks

  // const auth = useAuth();
  const token = localStorage.getItem(StorageKeys?.token);
  const user_info = JSON.parse(localStorage.getItem(StorageKeys?.user_info));

  // set the contex API

  const decode = decodeToken(token);
  const sub = decode?.sub;
  const id = sub;

  //states

  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isRoundup, setIsRoundup] = useState(null);
  const [alertIcon, setAlertIcon] = useState("error");
  const [loginModal, setLoginModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  //get user details

  const getUserDetails = useCallback(
    (id) => {
      setOpen(true);

      simpleGet(`${API_URLS?.getUserDetail}/${id}`)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.user?.role === "user") {
              setUserDetail(res?.data?.user);
              setIsRoundup(res?.data?.isRoundup);
              setOpen(false);
            } else {
              localStorage.clear();
            }
          } else {
            setOpen(false);
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
            localStorage.clear();
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          localStorage.clear();
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    },
    [setUserDetail]
  );

  //ger decode token

  const getDecodedToken = useCallback(() => {
    getUserDetails(id);
  }, [id, getUserDetails]);

  useEffect(() => {
    id && getDecodedToken();
  }, [id, getDecodedToken]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // End set the contex API

  //return component

  return token && user_info?.role === "user" ? (
    <Component {...rest} />
  ) : (
    <Navigate to={userRoutes?.root} />
  );
};

//auth hook

export const useAuth = () => {
  const token = localStorage.getItem(StorageKeys?.token);

  const { pathname } = useLocation();

  if (token) {
    //checking token is expired or not
    const expired = isExpired(token);

    if (!expired) {
      //decoding token is expired or not
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        return true;
        // if (verifyRole(decodedToken?.role, pathname)) {
        //   return true;
        // } else {
        //   removeToken();
        //   return false;
        // }
      }
      removeToken();
      return false;
    }
    removeToken();
    return false;
  }
  return false;
};

export const removeToken = () => {
  return localStorage.removeItem(StorageKeys?.token);
};

export const isLoggedIn = () => {
  const token = localStorage.getItem(StorageKeys?.token);
  return token ? true : false;
};

export default UserPrivateRoute;
