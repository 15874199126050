import React, { useContext, useState, useRef } from "react";

//package import

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Box,
  Container,
  Grid,
  TextField,
  MenuItem,
  Button,
  Backdrop,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  useStripe,
  useElements,
  AuBankAccountElement,
} from "@stripe/react-stripe-js";

//user import

// import { userRoutes } from "../../services/routes/APP";
import { userRoutes } from "app/Services/user/routes/APP";
import { MenuProps } from "app/common/user/components";

import BecsForm from "./BecsForm";
import { headersPost } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import {
  addBankValidation,
  addvalidation,
} from "app/Services/user/validations/card";
import { context } from "app/context";

//constants

const initialValues = {
  account_number: "",
  bsb_number: "",
  account_name: "",
};

//component

const BankModal = ({
  handleClose,
  open,
  isReload,
  donationHandleState,
  paymentMethodState,
}) => {
  //hooks
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { user, clientSecretState } = useContext(context);
  const { clientSecret, setClientSecret } = clientSecretState || {};
  //states

  const {
    submitHandler: handleDonation,
    formikValsTemp,
    isRoundupAction,
    addRoundUpHandler,
  } = donationHandleState || {};
  const { setData, bankData, data, setBankData } = paymentMethodState || {};
  const paymentData = data || [];

  const [loader, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //form submit handler

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setOpen(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const auBankAccount = elements.getElement(AuBankAccountElement);

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name and email. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = event.target["accountholder-name"];
    const email = event.target.email;

    const result = await stripe.confirmAuBecsDebitSetup(clientSecret, {
      payment_method: {
        au_becs_debit: auBankAccount,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      },
    });

    console.log({ result });

    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
      setOpen(false);
    } else {
      // Show a confirmation message to your customer.
      // The SetupIntent is in the 'succeeded' state.
      const data = {
        setupIntent: result.setupIntent,
      };

      headersPost(API_URLS?.setupIntentSuccess, data)
        .then((res) => {
          setOpen(false);
          if (res?.status === 201) {
            // handleClose();
            console.log({ res });
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("success");
            setTimeout(() => {
              handleClose();
              if (!isReload && paymentData?.length === 0) {
                if (isRoundupAction === true) {
                  addRoundUpHandler && addRoundUpHandler(formikValsTemp);
                } else {
                  handleDonation && handleDonation(formikValsTemp);
                }
              }
              isReload && window.location.reload();
            }, 2000);
            setBankData((prevData) => {
              return [...prevData, res?.data?.saveCard];
            });
            // setData(bankData)
            // navigate(userRoutes?.user + userRoutes?.discover);
            // setAddress('')
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;
          if (response.status === 401) {
            localStorage.clear();
            navigate(userRoutes?.root);
          }
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const submitHandler = (values) => {
    const { _id } = user || {};
    const data = {
      account_name: values?.account_name,
      account_number: values?.account_number,
      bsb_number: values?.bsb_number,
      // billing_address: values?.billing_address,
      addedByUser: _id,
    };

    setOpen(true);

    headersPost(API_URLS?.addBankDetail, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 201) {
          // handleClose();
          console.log({ res });
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("success");
          setTimeout(() => {
            handleClose();
          }, 2000);
          // navigate(userRoutes?.user + userRoutes?.discover);
          // setAddress('')
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        if (response.status === 401) {
          localStorage.clear();
          navigate(userRoutes?.root);
        }
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //aleert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontWeight={"bold"} align="center">
          Add Bank Account
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box padding={2}>
          <BecsForm onSubmit={handleSubmit} disabled={!stripe} />
        </Box>
      </DialogContent>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default BankModal;
