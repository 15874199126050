import React from "react";

//user import
import { Questions } from "app/components/user/OnBoarding";

//component

const BoardingQs = () => {
  //render

  return <Questions />;
};

export default BoardingQs;
