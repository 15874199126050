import React from "react";

//user import
import ProfilePage from "app/components/user/ProfileEdit";

//component

const Profile = () => {
  return <ProfilePage />;
};

export default Profile;
