import React from "react";

//package import

import {
  Dialog,
  Box,
  IconButton,
  useTheme,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

//user import

import LogoIcon from "app/svg/user/Logo";

//component

const SignUpModal = ({ open, handleClose }) => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: "#F9CC69",
          padding: { md: 4, sm: 3, xs: 2 },
          maxWidth: { md: `55%`, sm: "90%", xs: "90%" },
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.common.black,
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
        <LogoIcon textColor={"#230D4D"} />
      </Box>
      <Box paddingX={2}>
        <Typography variant="h6" align="center">
          Transforming pocket change into big change!
        </Typography>
      </Box>
      <Box>
        <Typography align="center">
          Stay connected and start making change NOW!
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={false} sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8}>
          <Box>
            <Box paddingBottom={2}>
              <Typography gutterBottom>Enter Name</Typography>
              <TextField
                sx={{
                  backgroundColor: theme.palette.common.white,
                  border: "none",
                }}
                fullWidth
              ></TextField>
            </Box>
            <Box paddingBottom={2}>
              <Typography gutterBottom>Enter Organisation</Typography>
              <TextField
                sx={{
                  backgroundColor: theme.palette.common.white,
                  border: "none",
                }}
                fullWidth
              ></TextField>
            </Box>
            <Box paddingBottom={2}>
              <Typography gutterBottom>Enter Email</Typography>
              <TextField
                sx={{
                  backgroundColor: theme.palette.common.white,
                  border: "none",
                }}
                fullWidth
              ></TextField>
            </Box>
            <Box>
              <FormControlLabel
                value="start"
                sx={{
                  fontSize: 12,
                }}
                control={
                  <Checkbox
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                }
                label={
                  <Typography variant="caption">
                    I am a Charity or Not for Profit organisation interested in
                    using the Up 4 Change platform.
                  </Typography>
                }
                labelPlacement="end"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                }
                label={
                  <Typography variant="caption">
                    I am looking to get invloved making change!
                  </Typography>
                }
                labelPlacement="end"
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"} paddingY={2}>
              <Button variant="contained">Submit</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={false} sm={1} md={2} />
      </Grid>
    </Dialog>
  );
};

export default SignUpModal;
