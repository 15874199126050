import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//component

const Last = () => {
  //hooks

  const theme = useTheme();
  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        paddingY: 4,
        paddinX: 2,
      }}
    >
      <Container maxWidth={"md"}>
        <Box marginBottom={2}>
          <Typography
            fontWeight={"bold"}
            align="center"
            color={"common.white"}
            variant="h5"
          >
            The collective power of all of our common cents can go towards
            helping those that really need it!
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            color={"common.white"}
            align="center"
            fontWeight={"bold"}
            sx={{
              textTransform: "uppercase",
            }}
          >
            Are YOU Up 4 Change? We are!
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Box
            sx={{
              border: `2px solid ${theme.palette.common.white}`,
              width: "35%",
            }}
          ></Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Last;
