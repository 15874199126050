import React, { useState, Fragment, useEffect } from "react";

//packages import

import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { FaAngleDown } from "react-icons/fa";

//user import

import { isFile } from "app/common/admin/Functions";
import {
  CATEGORIES_ARRAY,
  getCategorySubCategories,
} from "app/common/admin/enum";
import { ToFormData } from "app/utils/admin/FormData";
import { mainSchema } from "app/Services/admin/validations/AddOrg";

//constants

const initialValues = {
  name: "",
  abn_number: "",
  category: "",
  organisation_type: "",
  company_name: "",
  sub_category: "",
  email: "",
  organisation_image: "",
  description: "",
  cause: "",
  password: "password",
};

//component

export default function OrganisationDetail({
  formValues,
  createOrg,
  open,
  editOrg,
  handleEditOrg,
}) {
  //states

  const [active, setActive] = useState(false);
  const [sub_active, setSubActive] = useState(false);
  const [formInitial, setFormInitial] = useState(initialValues);
  const [subCategories, setSubCategories] = useState([]);

  //life cycle hooks

  useEffect(() => {
    formValues?.name && setFormInitial(formValues);
    formValues?.category && setSubCategory(formValues?.category);
    return () => {
      setFormInitial(initialValues);
    };
  }, [formValues]);

  //form submit handler

  const formSubmitHandler = (values) => {
    if (editOrg) {
      handleEditOrg(editOrg, values);
    } else {
      const data = {
        name: values?.name,
        abn_number: values?.abn_number,
        category: values?.category,
        organisation_type: values?.organisation_type,
        company_name: values?.company_name,
        sub_category: values?.sub_category,
        email: values?.email,
        organisation_image: values?.organisation_image,
        description: values?.description,
        cause: values?.cause,
        password: values?.password,
        role: "organisation",
      };
      const formData = ToFormData(data);
      createOrg(formData);
    }
  };

  const setSubCategory = (category) => {
    const subCat = getCategorySubCategories(category);
    const { sub_categories } = subCat || {};
    sub_categories && setSubCategories(sub_categories);
  };

  //render

  return (
    <Formik
      initialValues={formInitial}
      onSubmit={formSubmitHandler}
      validationSchema={mainSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
          className="add_organisation_form"
        >
          <div className="container">
            <div className="row justify-content-center my-30">
              <div className="col-lg-10">
                <div className="text-center">
                  <div className="organisationType-logo">
                    <img
                      src={
                        isFile(values?.organisation_image)
                          ? URL.createObjectURL(values?.organisation_image)
                          : values?.organisation_image
                          ? values?.organisation_image
                          : "/images/mouse.jpg"
                      }
                      alt="organ-type-logo"
                    />
                  </div>
                  <div className="form-group">
                    <label className="btn btn-pink my-3">
                      Upload
                      <input
                        type="file"
                        size="60"
                        accept="image/*"
                        className="d-none form-control"
                        onChange={(e) => {
                          setFieldValue(
                            "organisation_image",
                            e.currentTarget.files[0]
                          );
                        }}
                        name="organisation_image"
                      />
                    </label>
                    <div className="invalid-feedback d-block">
                      {errors?.organisation_image}
                    </div>
                  </div>
                  {!values?.organisation_image && (
                    <p className="organisation-file">
                      <small>
                        Choose a file png, jpg file with <br />
                        maximum 4MB file size
                      </small>
                    </p>
                  )}

                  <div className="d-flex align-items-center my-3 add_org_inputitem">
                    <p className="modal-organ-add-title">EMAIL</p>
                    <div className="modal-manage-titled">
                      <Form.Group controlId="validation01">
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          // onFocus={() => setActive(!active)}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  {/* <div className='d-flex align-items-center mb-3'>
                                        <p className='modal-organ-add-title'>
                                            Organisation NAME
                                        </p>
                                        <div className='modal-manage-titled'>
                                            <Form.Group controlId='validation02'>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Enter organisation type'
                                                    name='organisation_type'
                                                    value={
                                                        values.organisation_type
                                                    }
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.organisation_type
                                                    }
                                                    // onFocus={() => setActive(!active)}
                                                />
                                                <Form.Control.Feedback
                                                    type={'invalid'}
                                                >
                                                    {errors?.organisation_type}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div> */}
                  {/* <div className='d-flex align-items-center mb-3'>
                                        <p className='modal-organ-add-title'>
                                            CATEGORY
                                        </p>
                                        <div className='modal-manage-titled'>
                                            <div className='dropdown'>
                                                <div
                                                    className='dropdown-btn'
                                                    onClick={() =>
                                                        setActive(!active)
                                                    }
                                                >
                                                    <div>
                                                        {values?.category
                                                            ? values?.category
                                                            : 'please select a category'}
                                                    </div>
                                                    <div>
                                                        <FaAngleDown />
                                                    </div>
                                                </div>
                                                {active && (
                                                    <div className='dropdown-content'>
                                                        {CATEGORIES_ARRAY?.map(
                                                            (option, index) => (
                                                                <div
                                                                    key={`${index}-category-details-${option?.value}`}
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            'category',
                                                                            option?.value
                                                                        )
                                                                        setActive(
                                                                            false
                                                                        )
                                                                        setSubCategory(
                                                                            option?.value
                                                                        )
                                                                        setFieldValue(
                                                                            'sub_category',
                                                                            ''
                                                                        )
                                                                    }}
                                                                    className='dropdown-item'
                                                                >
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='invalid-feedback d-block'>
                                                {errors?.category}
                                            </div>
                                        </div>
                                    </div> */}
                  {/* <div className='d-flex align-items-center mb-3'>
                                        <p className='modal-organ-add-title'>
                                            SUB CATEGORY
                                        </p>
                                        <div className='modal-manage-titled'>
                                            <div className='dropdown'>
                                                <div
                                                    className='dropdown-btn'
                                                    onClick={() =>
                                                        setSubActive(
                                                            !sub_active
                                                        )
                                                    }
                                                >
                                                    <div>
                                                        {values?.sub_category
                                                            ? values?.sub_category
                                                            : 'select a sub category'}
                                                    </div>
                                                    <div>
                                                        <FaAngleDown />
                                                    </div>
                                                </div>
                                                {sub_active && (
                                                    <div className='dropdown-content'>
                                                        {subCategories?.map(
                                                            (option, index) => (
                                                                <div
                                                                    key={`${index}-sub_categories-${option.name}`}
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            'sub_category',
                                                                            option.value
                                                                        )
                                                                        setSubActive(
                                                                            false
                                                                        )
                                                                    }}
                                                                    className='dropdown-item'
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='invalid-feedback d-block'>
                                                {errors?.sub_category}
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="d-flex align-items-center mb-3 add_org_inputitem">
                    <p className="modal-organ-add-title">CAUSE</p>
                    <div className="modal-manage-titled">
                      <Form.Group controlId="validation03">
                        <Form.Control
                          type="text"
                          placeholder="Enter a cause"
                          name="cause"
                          value={values.cause}
                          onChange={handleChange}
                          isInvalid={!!errors.cause}
                          // onFocus={() => setActive(!active)}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.cause}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center add_org_inputitem">
                    <p className="modal-organ-add-title">DESCRIPTION</p>
                    <div className="modal-manage-titled">
                      <Form.Group controlId="validation04">
                        <Form.Control
                          type="text"
                          placeholder="Enter description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                          as="textarea"
                          rows={4}
                          // onFocus={() => setActive(!active)}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          {errors?.description}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-4">
            <button className={"btn btn-pink"} type="submit">
              {open ? (
                <Fragment>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  <span className="visually-hidden">Loading...</span>{" "}
                </Fragment>
              ) : (
                "CONFIRM"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
