export const domain = process.env.REACT_APP_API_URL || 'http://localhost:8000/v1';
// export const domain = 'http://localhost:8000/v1'

export const API_URLS = {
  login: `${domain}/auth/login`,
  socialLogin: `${domain}/auth/social-login`,
  forgotPassword: `${domain}/auth/forgot-password`,
  verifyCode: `${domain}/auth/verify-code`,
  getUserDetail: `${domain}/users`,
  register: `${domain}/auth/register`,
  contactUs: `${domain}/general/contact-us`,
  addCardDetail: `${domain}/bank/addUserCard`,
  addBankDetail: `${domain}/bank/addUserBank`,
  stripeBankClientSecret: `${domain}/bank/clientSecret`,
  setupIntentSuccess: `${domain}/bank/setupIntentSuccess`,
  getAccount: `${domain}/bank/getUserAccount`,
  getAccounts: `${domain}/bank/getUserAccounts`,
  markCardDefault: `${domain}/bank/markCardDefault`,
  deleteAccount: `${domain}/bank/deleteAccount`,
  getAllFeeds: `${domain}/organisation/allFeeds`,
  getOrgCause: `${domain}/organisation/getCause`,
  userTransactions: `${domain}/transactions/user`,
  updateUserPic: `${domain}/users/updateProfilePic`,
  updateUserPicBase64: `${domain}/users/updateProfilePicBase64`,
  getAllCauses: `${domain}/organisation/getAllCauses`,
  addUserCategories: `${domain}/users/addCategoryTest`,
  chargeSubscription: `${domain}/bank/chargeSubscription`,
  cancelSubscription: `${domain}/bank/cancelSubscription`,
  chargeCustomerOneTime: `${domain}/bank/chargeCustomerOneTime`,
  getOrgDetails: `${domain}/organisation/getOrganisationDetail`,
  getAllOrgCauses: `${domain}/organisation/getCausesByOrganisation`,
  //user feed or cause fav routes
  createFavFeed: `${domain}/user-feed/create-user-feeds`,
  getAllUserFavFeed: `${domain}/user-feed/all-user-feeds`,
  delUserFavFeed: `${domain}/user-feed/delete-user-feed`,
  //basiq routes
  getBasiqRoute: `${domain}/basiq/get-token`,
  getBasiqRoute2: `${domain}/basiq/get-token2`,
  // roundup routes
  createRoundup: `${domain}/roundup/create`,
  getUserRoundup: `${domain}/roundup/details`,
  deleteRoundup: `${domain}/roundup/delete`,
  addRoundupOrg: `${domain}/roundup/add`,
  removeRoundupOrg: `${domain}/roundup/remove`,
  changePassword: `${domain}/auth/change-password`,
  toggleFavCause: `${domain}/users/causes/toggleCause`,
  allFavCausesByUser: `${domain}/users/causes/allFav`,
  interactionOnFeed: `${domain}/interaction/create`,
  getAllInteractionsByFeed: `${domain}/interaction`,
};
