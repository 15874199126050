import React from "react";

//package import

import { Link } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { Box, FormControl, FormHelperText } from "@mui/material";
import { isFile } from "app/utils/organization/Functions";

//component

const GoalModal = ({
  gShow,
  handlegClose,
  handleChange,
  hiddenFileInput,
  handleClick,
  values,
  errors,
  touched,
  goal,
  setFieldValue,
  handleIconClick,
  hiddenIconInput,
}) => {
  const getValue = () => {
    const value = {
      1: values?.micro_goal1_value,
      2: values?.micro_goal2_value,
      3: values?.micro_goal3_value,
    };
    return value[goal];
  };
  const getName = () => {
    const value = {
      1: values?.micro_goal1_name,
      2: values?.micro_goal2_name,
      3: values?.micro_goal3_name,
    };
    return value[goal];
  };

  const getValueError = () => {
    const value = {
      1: errors?.micro_goal1_value,
      2: errors?.micro_goal2_value,
      3: errors?.micro_goal3_value,
    };
    return value[goal];
  };
  const getNameError = () => {
    const value = {
      1: errors?.micro_goal1_name,
      2: errors?.micro_goal2_name,
      3: errors?.micro_goal3_name,
    };
    return value[goal];
  };
  const getIconError = () => {
    const value = {
      1: errors?.micro_goal1_icon,
      2: errors?.micro_goal2_icon,
      3: errors?.micro_goal3_icon,
    };
    return value[goal];
  };

  const getImageError = () => {
    const value = {
      1: errors?.micro_goal1_image,
      2: errors?.micro_goal2_image,
      3: errors?.micro_goal3_image,
    };
    return value[goal];
  };

  //render

  return (
    <Modal show={gShow} onHide={handlegClose} size="lg">
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100 py-3">
          <p> </p>
          <p className="create-post">Add Micro Goal {goal}</p>
          <Link to="#/" onClick={handlegClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <Form.Group
                  className="fields mb-3 mt-4"
                  controlId="validationCustom01"
                >
                  <Form.Label className="width-label">NAME</Form.Label>
                  <Form.Control
                    className="field"
                    type="text"
                    placeholder="Enter name"
                    name={`micro_goal${goal}_name`}
                    value={getName()}
                    onChange={handleChange}
                    isInvalid={!!getNameError()}
                  />

                  <Form.Control.Feedback type={"invalid"}>
                    {getNameError()}
                  </Form.Control.Feedback>
                </Form.Group>
                {!!getNameError() && (
                  <FormControl
                    error={!!getNameError()}
                    fullWidth
                    sx={{ margin: 0, marginBottom: 2 }}
                  >
                    <FormHelperText sx={{ textAlign: "center" }}>
                      {getNameError()}
                    </FormHelperText>
                  </FormControl>
                )}
                {/* <Form.Group
                                    className='fields mb-3'
                                    controlId='validationCustom02'
                                >
                                    <Form.Label className='width-label'>
                                        VALUE
                                    </Form.Label>
                                    <Form.Control
                                        className='field'
                                        type='text'
                                        placeholder='Enter VALUE'
                                        name={`micro_goal${goal}_value`}
                                        value={getValue()}
                                        onChange={handleChange}
                                        isInvalid={!!getValueError()}
                                    />

                                </Form.Group> */}
                {/* {!!getValueError() && (
                                    <FormControl
                                        error={!!getValueError()}
                                        fullWidth
                                        sx={{margin: 0, marginBottom: 2}}
                                    >
                                        <FormHelperText
                                            sx={{textAlign: 'center'}}
                                        >
                                            {getValueError()}
                                        </FormHelperText>
                                    </FormControl>
                                )} */}
                <div className="fields mb-3">
                  <label htmlFor="exampleInputEmail1" className="width-label">
                    Micro-goal image
                  </label>
                  <div className="field">
                    <div className="anime">
                      {values[`micro_goal${goal}_icon`] === "" ? (
                        <img src="/images/primary-anime.svg" alt="creature" />
                      ) : (
                        <img
                          src={
                            isFile(values[`micro_goal${goal}_icon`])
                              ? URL.createObjectURL(
                                  values[`micro_goal${goal}_icon`]
                                )
                              : values[`micro_goal${goal}_icon`]
                          }
                          // src={URL.createObjectURL(
                          //     values[
                          //         `micro_goal${goal}_icon`
                          //     ]
                          // )}
                          alt="creature"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-pink my-3"
                    type="button"
                    onClick={handleIconClick}
                  >
                    UPLOAD
                  </button>
                </div>
                <Form.Group
                  className="fields mb-3"
                  controlId="micro_goal_icon1"
                >
                  <Form.Control
                    type="file"
                    ref={hiddenIconInput}
                    name={`micro_goal${goal}_icon`}
                    accept="image/png, image/jpeg, image/jpg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setFieldValue(
                        `micro_goal${goal}_icon`,
                        e.currentTarget.files[0]
                      );
                    }}
                    isInvalid={!!getIconError()}
                  />
                </Form.Group>
                {!!getIconError() && (
                  <FormControl
                    error={!!getIconError()}
                    fullWidth
                    sx={{ margin: 0, marginBottom: 2 }}
                  >
                    <FormHelperText sx={{ textAlign: "center" }}>
                      {getIconError()}
                    </FormHelperText>
                  </FormControl>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="upload my-3 w-100">
                  <Form.Group
                    className="fields mb-3"
                    controlId="validationCustom01"
                  >
                    {values[`micro_goal${goal}_image`] ? (
                      <Box className=" py-1" width={300} height={200}>
                        <Box
                          component={"img"}
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                          src={
                            isFile(values[`micro_goal${goal}_image`])
                              ? URL.createObjectURL(
                                  values[`micro_goal${goal}_image`]
                                )
                              : values[`micro_goal${goal}_image`]
                          }
                          // src={URL.createObjectURL(
                          //     values[
                          //         `micro_goal${goal}_image`
                          //     ]
                          // )}
                          alt="upload"
                        />
                      </Box>
                    ) : (
                      <button
                        className="btn btn-upload py-1 w-100"
                        onClick={handleClick}
                        type="button"
                      >
                        <p>Add Image/Video</p>
                        <img
                          src="/images/upload.svg"
                          alt="upload"
                          className="py-3"
                        />
                        <p>Upload</p>
                      </button>
                    )}

                    <Form.Control
                      type="file"
                      ref={hiddenFileInput}
                      placeholder="Enter organisation name"
                      accept="image/png, image/jpeg, image/jpg"
                      name={`micro_goal${goal}_image`}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFieldValue(
                          `micro_goal${goal}_image`,
                          e.currentTarget.files[0]
                        );
                      }}
                      isInvalid={!!getImageError()}
                    />
                  </Form.Group>
                  {!!getImageError() && (
                    <FormControl
                      error={!!getImageError()}
                      fullWidth
                      sx={{ margin: 0, marginBottom: 2 }}
                    >
                      <FormHelperText sx={{ textAlign: "center" }}>
                        {getImageError()}
                      </FormHelperText>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-pink my-3" onClick={handlegClose}>
                SAVE
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GoalModal;
