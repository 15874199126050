import { liteThemeColors } from "./color";

export const light = {
  info: {
    main: liteThemeColors?.white,
    dark: liteThemeColors?.black,
    light: liteThemeColors?.infoLite,
  },
  grey: {
    100: liteThemeColors?.[100],
  },
  common: {
    black: liteThemeColors?.black,
    white: liteThemeColors?.white,
  },
  cardShadow: liteThemeColors?.cardShadow,
  mode: "light",
  primary: {
    main: liteThemeColors?.primary,
    light: liteThemeColors?.primaryLite,
    dark: liteThemeColors?.primaryDark,
    contrastText: liteThemeColors?.white,
  },
  secondary: {
    main: liteThemeColors?.secondary,
    light: liteThemeColors?.secondaryLite,
    dark: liteThemeColors?.secondaryDark,
    contrastText: liteThemeColors?.white,
  },
  text: {
    primary: liteThemeColors?.textMain,
    secondary: liteThemeColors?.textLight,
    main: liteThemeColors?.textMain,
    light: liteThemeColors?.textLight,
    dark: liteThemeColors?.textDark,
    grayText: liteThemeColors?.grayText,
    red: liteThemeColors?.redText,
    white: liteThemeColors?.white,
    liteGray: liteThemeColors.liteGrayText,
  },
  divider: liteThemeColors?.divider,
  background: {
    paper: liteThemeColors?.white,
    default: liteThemeColors?.white,
    gradient: liteThemeColors?.bgGradient,
  },
  link: {
    primary: liteThemeColors?.linkPrimary,
    secondary: liteThemeColors?.linkSecondary,
    white: liteThemeColors?.white,
  },
};

export const dark = {
  info: {
    main: "#121212",
    dark: "#151a30",
  },
  cardShadow: "rgba(0, 0, 0, .11)",
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "dark",
  primary: {
    main: liteThemeColors?.darkOrange,
    light: liteThemeColors?.lightOrange,
    dark: liteThemeColors?.darkOrange,
    grayText: liteThemeColors?.darkOrange,
    contrastText: "#fff",
  },
  secondary: {
    main: "#00AB55",
    light: "#33CC70",
    dark: "#009357",
    contrastText: "#fff",
  },
  text: {
    main: liteThemeColors?.darkOrange,
    light: liteThemeColors?.lightOrange,
    dark: liteThemeColors?.darkOrange,
    grayText: liteThemeColors?.darkOrange,
    white: "#ffffff",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  background: {
    paper: "#1f1f1f",
    default: "#222B45",
    dark016: "rgba(65, 120, 170, 0.16)",
    faqBg: `linear-gradient(174.04deg, #0F4475 15.61%, #106D95 44.08%, #11A9C2 75.64%)`,
    black: "#000000",
    level1: "#2D3748",
  },
};
