import React from "react";

//package import

import { Box, useTheme, Container, Typography, Grid } from "@mui/material";

import { HeartIcon, VoiceIcon, GiftIcon } from "app/svg/user/Nfps";
//component

const Donor = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Box
      bgcolor={theme.palette.primary.light}
      paddingY={3}
      sx={{
        backgroundImage: `url(/images/ChangeEllipse.png)`,
        backgroundSize: "25%",
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Typography
          color={"primary.main"}
          align="center"
          fontWeight={"bold"}
          variant="h5"
          paddingBottom={3}
        >
          But what do donors want and how do we connect with them???
        </Typography>
        <Typography color={"white"} fontWeight={"bold"}>
          Don’t just focus on the act of giving, focus on the whole person that
          is actually doing the giving.
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box display={"flex"} justifyContent={"center"}>
                <GiftIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box>
              <Typography
                fontWeight={"bold"}
                color={"white"}
                variant="h5"
                sx={{ marginTop: 3 }}
              >
                -They want to know that their gift made a difference.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{ marginTop: 5 }}
              >
                <HeartIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Box>
              <Box display={"flex"} justifyContent={"center"}>
                <VoiceIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box>
              <Typography
                fontWeight={"bold"}
                color={"white"}
                variant="h5"
                sx={{ marginTop: 3 }}
              >
                -They need to be able to voice their own unique beliefs through
                giving to their chosen cause.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={1}></Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              <Box display={"flex"} justifyContent={"right"}></Box>
              <img
                src={"/images/nfps.png"}
                alt="VisionLink"
                width={"100%"}
                height={"auto"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              <Typography
                fontWeight={"bold"}
                color={"white"}
                variant="h5"
                sx={{ marginTop: 3, paddingLeft: 3 }}
              >
                -They need to be able to connect with people like them,
                passionate about the same cause.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={1}></Grid>
        </Grid>
        <Typography color={"white"} fontWeight={"bold"} sx={{ marginTop: 3 }}>
          Up 4 Change connects people to causes allowing them to join and
          contribute to a community of givers just like them. And we will never
          ask them for more money, only thank them for being generous, awesome
          people!
        </Typography>
        <Typography color={"white"} fontWeight={"bold"} sx={{ marginTop: 3 }}>
          And.. you have the choice to donate to multiple causes you care about.
        </Typography>
        <Typography
          variant="h5"
          color={"white"}
          fontWeight={"bold"}
          sx={{ textAlign: "center", marginTop: 3 }}
        >
          Australians are likely to have a set of up to
        </Typography>
        <Typography
          variant="h5"
          color={"white"}
          fontWeight={"bold"}
          sx={{ textAlign: "center" }}
        >
          three charities that they donate to.
        </Typography>
      </Container>
    </Box>
  );
};

export default Donor;
