import React, { useCallback, useEffect, useState } from "react";

//package import

import {
  Box,
  Container,
  Typography,
  useTheme,
  Backdrop,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

//user import

import OrgCard from "./OrgCard";

import { useParams } from "react-router-dom";
import { API_URLS } from "app/Services/user/routes/API";
import { simpleGet } from "app/Services/user/API";

//component

const Orgprofile = () => {
  //hooks

  const theme = useTheme();
  const { id } = useParams();

  //states

  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //get org details

  const getOrgDetails = useCallback(() => {
    setOpen(true);

    simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${id}`)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && res?.data?.status) {
          setData(res?.data?.organisationDetails);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [id]);

  //life cycle hooks

  useEffect(() => {
    id && getOrgDetails();
  }, [id, getOrgDetails]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <Box padding={2} bgcolor={theme.palette.info.light}>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{
            paddingTop: {
              xs: "60px",
              sm: "60px",
              md: "0px",
              lg: "0px",
            },
          }}
        >
          <Box
            width={120}
            height={120}
            borderRadius={"100%"}
            style={{ border: "4px solid #ff9776" }}
          >
            <Box
              component={"img"}
              src={data?.organisation_image[0] || "/images/OrgLogo.png"}
              alt="org logo"
              width={"100%"}
              borderRadius={"100%"}
              height={"100%"}
            />
          </Box>
        </Box>
        <Typography
          color={"text.red"}
          fontWeight={"bold"}
          align="center"
          paddingTop={1}
        >
          {data?.name}
        </Typography>
        <Typography color={"white"} align="center">
          {data?.cause}
        </Typography>
        {console.log({ data })}
        {data?.causes?.map((item, index) => (
          <Box marginY={4} key={`${index}-${item.id}`}>
            <OrgCard item={item} org={data} />
          </Box>
        ))}
      </Container>

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Orgprofile;
