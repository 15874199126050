import React, { useState, useContext, createRef } from "react";
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Avatar,
  Alert,
  Modal,
  Box,
  IconButton,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";
//user import

import "./profile.css";
import AddBankAccount from "./AddAccount";
import ChangePassword from "./ChangePassword";
import { API_URLS } from "app/Services/organization/Routes/API";

import { headersPost } from "app/Services/organization/API";
import ProfileInformation from "./ProfileInformation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { context } from "app/context";
//component

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "600px",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

function Profile() {
  //hooks

  const { orgDetails, setOrgDetails } = useContext(context);
  const inputRef = createRef(null);
  const { id, organisation_image, category } = orgDetails || {};

  //states

  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [image, setImage] = useState();
  let [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
      setOpen1(true);
    } else if (e.target) {
      setOpen1(true);
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      fileHandlerBase64(cropper.getCroppedCanvas().toDataURL());
    }
  };

  console.log({ cropData });

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [imageUrl, setImageUrl] = useState("");

  const clickHandler = () => {
    inputRef?.current?.click();
  };

  const fileHandler = (event) => {
    const {
      currentTarget: { files },
    } = event;
    if (files?.length > 0 && id) {
      const formData = new FormData();
      formData.append("organisation_image", files[0]);
      setImageUrl(URL.createObjectURL(files[0]));
      setOpen(true);
      headersPost(
        `${API_URLS?.updateOrgProfilePic}?organisationId=${id}`,
        formData
      )
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            orgDetails.organisation_image = res?.data?.org?.organisation_image;
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;

          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const fileHandlerBase64 = (base64) => {
    const data = {
      profile_pic: base64,
    };

    setOpen(true);
    headersPost(`${API_URLS?.updateOrgPicBase64}?organisationId=${id}`, data)
      .then((res) => {
        setOpen(false);
        console.log({ Res: res });
        if (res?.status === 200) {
          setImageUrl(cropData);
          // setImageUrl(res?.data?.org?.organisation_image[0])
          // imageUrl(res?.data?.org?.organisation_image[0])
          // setOrgDetails(res?.data?.org)
          window.location.href = "/org/profile";
        } else {
          // setAlertOpen(true)
          // setAlertMsg(res?.data?.message)
          // setAlertIcon('warning')
        }
      })
      .catch((err) => {
        // const {response} = err
        // setOpen(false)
        // setAlertOpen(true)
        // setAlertMsg(response?.data?.message)
        // setAlertIcon('error')
      });

    setOpen1(false);
  };

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <div style={{ overflowY: "auto", paddingTop: "100px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3">
            <div className="active-title">
              <p>PROFILE</p>
            </div>
            <div className="box">
              <div className="text-center">
                <div className="profile-logo-div mx-auto d-block">
                  {organisation_image?.length > 0 ? (
                    <img
                      src={imageUrl ? imageUrl : organisation_image[0]}
                      alt="organisation-logo"
                      className="profile-logo-div mx-auto d-block"
                      style={{
                        border: "5px solid #FF9776",
                        radius: "50%",
                      }}
                    />
                  ) : (
                    <Avatar>{orgDetails?.name?.slice(0, 1)}</Avatar>
                  )}
                </div>
                {/* <button
                                    className='btn btn-pink my-4'
                                    onClick={clickHandler}
                                >
                                    CHANGE PHOTO
                                </button> */}

                <label for="file-upload" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Change photo
                </label>

                <Box
                  component={"input"}
                  display={"none"}
                  id={"file-upload"}
                  accept="image/png , image/jpeg"
                  type={"file"}
                  name={"org-profile"}
                  onChange={onChange}
                />

                <Modal
                  open={open1}
                  onClose={handleClose1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <Box textAlign={"right"}>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose1}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <div>
                      <div
                        style={{
                          width: "100%",
                          height: "320px",
                          paddingTop: "40px",
                        }}
                      >
                        <Cropper
                          style={{ height: 440, width: "100%" }}
                          zoomTo={0.5}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                          onInitialized={(instance) => {
                            setCropper(instance);
                          }}
                          guides={true}
                        />
                      </div>
                      <div>
                        {/* <div className="box" style={{ width: "50%", paddingTop:"60px", paddingRight:"50px", float: "right" }}>
          <h1>Preview</h1>
          <div
            className="img-preview" 
            style={{ width: "100%", float: "left", height: "200px" , overflow: "hidden"}}
          />
        </div> */}
                        <div
                        //   className="box"
                        //   style={{ width: "50%", float: "right", height: "300px" }}
                        >
                          <div
                            style={{ paddingTop: "200px", marginLeft: "210px" }}
                          >
                            {/* <span>Crop</span> */}
                            <span
                              onClick={getCropData}
                              class="custom-file-upload1"
                            >
                              Upload
                            </span>
                          </div>
                          {/* <img style={{ width: "100%" }} src={cropData} alt="cropped" /> */}
                        </div>
                      </div>
                      <br style={{ clear: "both" }} />
                    </div>
                  </Box>
                </Modal>

                <p className="profile-text pb-2">
                  Choose a file png file with <br />
                  maximum 10MB file size
                </p>
                <ProfileInformation />
                {/* <p className="profile-fname my-4">ACCOUNT INFORMATION</p> */}
              </div>
              {/* <div className="row justify-content-center mt-5">
                <div className="col-lg-11">
                  {/* <AddBankAccount /> */}
              {/* <p className="profile-fname text-center my-4">
                    CHANGE PASSWORD
                  </p>
                  <ChangePassword /> */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3 ">
            <div className="row justify-content-center mt-4 mx-0">
              <div className="box">
                <p className="profile-fname text-center my-4">
                  ACCOUNT INFORMATION
                </p>
                <AddBankAccount />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 my-3 ">
            <div className="row justify-content-center mt-4 mx-0">
              <div className="box">
                <p className="profile-fname text-center my-4">
                  CHANGE PASSWORD
                </p>
                <ChangePassword />
              </div>
            </div>
          </div>
        </div>
      </div>

      <input
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg"
        type={"file"}
        name={"org-profile"}
        className="d-none"
        onChange={(e) => fileHandler(e)}
      />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Profile;
