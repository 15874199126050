import React, { Fragment, useState } from "react";

//package import

import { Box, Typography, useTheme, Button, Modal } from "@mui/material";
import { userRoutes } from "app/Services/user/routes/APP";
import { PopupModal } from "react-calendly";
const NfpsContact = () => {
  //hooks

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  //render

  return (
    <Fragment>
      <Box bgcolor={theme.palette.primary.light}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            To find out more Click Below!
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
            }}
            href={userRoutes?.contactUs}
          >
            CONTACT US
          </Button>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            Want to get your NFP involved with making change?
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          {/* <Button
            variant="contained"
            
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom:5
            }}
           
          >
           <PopupButton
          
          url="https://calendly.com/up4change"
          rootElement={document.getElementById("root")} text="BOOK A CALL!"> BOOK A CALL!</PopupButton>
          </Button> */}

          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom: 5,
            }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            BOOK A CALL!
          </Button>
          <PopupModal
            url="https://calendly.com/up4change"
            onModalClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            rootElement={document.getElementById("root")}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default NfpsContact;
