import React from "react";

//package import

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";

//user import

import Logo from "app/svg/user/Logo";
import { userRoutes } from "app/Services/user/routes/APP";

//contants

const linksArray = [
  {
    id: 200,
    title: "FEED",
    href: userRoutes?.feed,
  },
  {
    id: 201,
    title: "DISCOVER",
    href: userRoutes?.discover,
  },
  {
    id: 202,
    title: "NOT FOR PROFITS's",
    href: userRoutes?.nfps,
  },
  {
    id: 203,
    title: "CHANGE MAKERS",
    href: userRoutes?.changeMakers,
  },
  {
    id: 204,
    title: "GET IN TOUCH",
    href: userRoutes?.contactUs,
  },
  {
    id: 205,
    title: "ABOUT",
    href: userRoutes?.aboutus,
  },
];

//component

const Footer = () => {
  //hooks

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(2),
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={3}>
            <Box display={"flex"} justifyContent={"center"}>
              <Box width={150}>
                <Link href={"/"}>
                  <Logo width={"100%"} height={"100%"} />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Box
              display={{ md: "flex", sm: "flex" }}
              justifyContent={{ md: "center", sm: "center" }}
            >
              {linksArray?.map((link, index) => (
                <Box
                  key={`${index}-link-footer-${link?.id}`}
                  padding={{ md: 3, sm: 2, xs: 1 }}
                >
                  <Link
                    href={link?.href}
                    variant="caption"
                    fontWeight={"bold"}
                    sx={{
                      color: theme.palette.common.white,
                      textDecoration: "none",
                    }}
                    key={`${index}-footer-routes-${link?.id}-${link?.href}`}
                  >
                    {link?.title}
                  </Link>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography color={"white"} variant="caption">
            Copyright &copy; {new Date().getFullYear()} Up4Change. All rights
            reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
