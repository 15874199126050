import React, { Fragment, useState, useContext } from "react";

//package import

import { Formik, Form } from "formik";
import {
  FormHelperText,
  FormControl,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//user imports

import { addValidation } from "app/Services/organization/validations/ChangePasword";
import { headersPost } from "app/Services/organization/API";
import { API_URLS } from "app/Services/organization/Routes/API";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { context } from "app/context";
//constants

const formInitials = {
  old_password: "",
  new_password: "",
  re_new_password: "",
};

const ChangePassword = () => {
  //hooks

  const navigate = useNavigate();
  const { orgDetails: org, setOrgDetails } = useContext(context);
  // const org = useContext(context)

  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //form submit handler

  const submitHandler = (values, { resetForm }) => {
    const data = {
      oldPassword: values.old_password,
      newPassword: values.new_password,
    };
    setOpen(true);
    headersPost(`${API_URLS?.changePassword}?organisation=${org?.id}`, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200 && !res?.data?.error) {
          setAlertOpen(true);
          setAlertMsg("Your password is changed successfully.");
          setAlertIcon("success");
          resetForm();
          navigate(APP_URLS?.org);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        if (response?.status === 401) {
          localStorage.clear();
          navigate(APP_URLS?.root);
        } else {
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        }
      });
  };

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <Fragment>
      <Formik
        initialValues={formInitials}
        onSubmit={submitHandler}
        validationSchema={addValidation}
      >
        {({ errors, touched, handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="fields mb-3">
              <label htmlFor="old_password">OLD PASSWORD</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={touched.old_password && Boolean(errors.old_password)}
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    id="old_password"
                    name="old_password"
                    value={values?.old_password}
                    onChange={handleChange}
                  />
                  <FormHelperText>
                    {touched.old_password && errors.old_password}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="fields mb-3">
              <label htmlFor="new_password">NEW PASSWORD</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={touched.new_password && Boolean(errors.new_password)}
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    id="new_password"
                    name="new_password"
                    value={values?.new_password}
                    onChange={handleChange}
                  />
                  <FormHelperText>
                    {touched.new_password && errors.new_password}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="fields">
              <label htmlFor="re_new_password">CONFIRM PASSWORD</label>
              <div className="field">
                <FormControl
                  fullWidth
                  error={
                    touched.re_new_password && Boolean(errors.re_new_password)
                  }
                >
                  <input
                    type="text"
                    className="form-control w-100"
                    id="re_new_password"
                    name="re_new_password"
                    value={values?.re_new_password}
                    onChange={handleChange}
                  />
                  <FormHelperText>
                    {touched.re_new_password && errors.re_new_password}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="text-center my-5">
              <button className="btn btn-pink py-2">SAVE CHANGES</button>
            </div>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default ChangePassword;
