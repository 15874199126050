import React, { Fragment, useState, useContext } from "react";

//packages import

import { dividerClasses } from "@mui/material/Divider";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useTheme,
  Button,
  Link,
  Divider,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

//user import

import { Facebook, Google } from "app/svg/user/signUp";
import Logo from "app/svg/user/Logo";
import { userRoutes } from "app/Services/user/routes/APP";
import { simplePost } from "app/Services/user/API";
import { StorageKeys } from "app/Services/user/Storage";
import { API_URLS } from "app/Services/user/routes/API";

import { useAuthState } from "react-firebase-hooks/auth";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { singUpValidation } from "app/Services/user/validations/signup";
import { context } from "app/context";
import { signInWithFacebook, signInWithGoogle, auth } from "app/firebase";
//constants

const initialValues = {
  email: "",
  password: "",
  con_password: "",
  name: "",
  surname: "",
  phone_number: "",
};

//component

const SignUpPage = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  const { setUser } = useContext(context);
  const [user, loading, error] = useAuthState(auth);
  // console.log({ user, loading, error });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  //navigate handler

  const clickHandler = (user) => {
    // navigate(userRoutes?.user + userRoutes?.getStarted)
    const { isOnboard } = user;
    setUser(user);
    if (isOnboard) {
      navigate(userRoutes?.user + userRoutes?.discover);
    } else {
      navigate(userRoutes?.user + userRoutes?.getStarted);
    }
  };

  //form submit handler

  const submitHandler = (values) => {
    const { email, password, name, surname, phone_number } = values || {};
    // alert(sname)
    const data = {
      name,
      surname,
      email,
      password,
      role: "user",
      phone_number: phone_number?.toString(),
    };
    setOpen(true);

    simplePost(API_URLS?.register, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          setUser(res?.data?.user);
          localStorage.setItem(
            StorageKeys?.user_info,
            JSON.stringify(res?.data?.user)
          );
          clickHandler(res?.data?.user);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //alert close handler

  const alertCloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSocialLogin = async (google = false, facebook = false) => {
    let response;
    if (google === true) {
      response = await signInWithGoogle();
    }
    if (facebook === true) {
      response = await signInWithFacebook();
    }
    const accessToken = response?.user?.accessToken;
    setOpen(true);
    simplePost(API_URLS?.socialLogin, { accessToken })
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          localStorage.setItem(StorageKeys?.token, res?.data?.tokens);
          clickHandler(res?.data?.user);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
    // if(response) {
    //   console.log(response);
    //   localStorage.setItem(StorageKeys?.token, response?.user?.accessToken);
    //   navigate(userRoutes?.user + userRoutes?.getStarted);
    // }
  };
  //render

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: theme.palette.info.light,
          backgroundImage: `url(/images/EllipseDonut.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: {
            lg: "20%",
            md: "20%",
            xs: "60%",
            sm: "60%",
          },
        }}
      >
        <Box
          padding={theme.spacing(2, 2, 2, 2)}
          component="a"
          display={"block"}
          underline="none"
          href="/"
        >
          <Logo textColor={theme.palette.common.white} />
        </Box>
        <Typography
          color={"common.white"}
          variant="h6"
          align="center"
          sx={{
            marginTop: 5,
          }}
        >
          USER
        </Typography>
        <Typography
          color={"common.white"}
          variant="h4"
          align="center"
          sx={{
            marginTop: 1,
          }}
        >
          SIGN UP
        </Typography>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Box
            sx={{
              border: `1px solid #FFD16B`,
              width: "10%",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.info.light,
          backgroundImage: `url(/images/EllipseSignUp.png)`,
          backgroundPosition: "right bottom",
          backgroundSize: "35%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          <Grid item xs={false} sm={false} md={1}></Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box minHeight={"110vh"}>
              <Container maxWidth={"md"}>
                <Box
                  sx={{
                    paddingX: { md: 10, sm: 4, xs: 2 },
                    paddingY: { md: 2, sm: 2, xs: 2 },
                  }}
                >
                  <Formik
                    initialValues={initialValues}
                    onSubmit={submitHandler}
                    validationSchema={singUpValidation}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Enter First Name
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            placeholder="Enter First Name"
                            name="name"
                            value={values?.fname}
                            onChange={handleChange}
                            helperText={touched.name && errors.name}
                            error={touched.name && Boolean(errors.name)}
                          ></TextField>
                        </Box>
                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Enter Surname
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            placeholder="Enter Surname"
                            name="surname"
                            value={values?.surname}
                            onChange={handleChange}
                            helperText={touched.surname && errors.surname}
                            error={touched.surname && Boolean(errors.surname)}
                          ></TextField>
                        </Box>

                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Enter Email
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            placeholder="Enter Your Email"
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                            error={touched.email && Boolean(errors.email)}
                          ></TextField>
                        </Box>

                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Enter Phone Number
                          </Typography>
                          <TextField
                            // InputProps={{
                            //   startAdornment: (
                            //     <InputAdornment position="start">
                            //       <Typography color={"common.white"}>
                            //         {" "}
                            //         +92 |{" "}
                            //       </Typography>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            fullWidth
                            placeholder="0000 000 000"
                            variant={"outlined"}
                            type={"number"}
                            color="info"
                            name="phone_number"
                            value={values?.phone_number}
                            onChange={handleChange}
                            helperText={
                              touched.phone_number && errors.phone_number
                            }
                            // className={classes.input}
                            error={
                              touched.phone_number &&
                              Boolean(errors.phone_number)
                            }
                          ></TextField>
                        </Box>

                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Create Password
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            // type='password'
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Your Password"
                            name="password"
                            value={values?.password}
                            onChange={handleChange}
                            helperText={touched.password && errors.password}
                            error={touched.password && Boolean(errors.password)}
                            InputProps={{
                              endAdornment: showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ),
                            }}
                          ></TextField>
                        </Box>

                        <Box marginBottom={3}>
                          <Typography
                            color={"common.white"}
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            Confirm Password
                          </Typography>
                          <TextField
                            fullWidth
                            variant={"outlined"}
                            color="info"
                            // type='password'
                            type={showPassword1 ? "text" : "password"}
                            placeholder="Retype Your Password"
                            name="con_password"
                            value={values?.con_password}
                            onChange={handleChange}
                            helperText={
                              touched.con_password && errors.con_password
                            }
                            error={
                              touched.con_password &&
                              Boolean(errors.con_password)
                            }
                            InputProps={{
                              endAdornment: showPassword1 ? (
                                <VisibilityIcon
                                  onClick={() =>
                                    setShowPassword1(!showPassword1)
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() =>
                                    setShowPassword1(!showPassword1)
                                  }
                                />
                              ),
                            }}
                          ></TextField>
                        </Box>

                        <Box display={"flex"} justifyContent={"center"}>
                          <Button
                            variant="contained"
                            sx={{
                              boxShadow: theme.shadows[5],
                              width: "50%",
                            }}
                            type={"submit"}
                          >
                            Sign Up
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>

                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Typography color={"common.white"}>
                      Already have an Account?
                    </Typography>
                    <Link color={"common.white"} href={userRoutes?.logIn}>
                      Log In
                    </Link>
                  </Box>

                  <Box
                    paddingTop={2}
                    color={"common.white"}
                    width={400}
                    margin="auto"
                  >
                    <Divider
                      sx={{
                        [`&.${dividerClasses.root}`]: {
                          "&::before": {
                            borderTop: "thin solid white",
                          },
                          "&::after": {
                            borderTop: "thin solid white",
                          },
                        },
                      }}
                    >
                      OR
                    </Divider>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                      sx={{
                        backgroundColor: "#475993",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "70%",
                          lg: "70%",
                        },
                      }}
                      variant="contained"
                      startIcon={<Facebook />}
                      onClick={() => handleSocialLogin(false, true)}
                    >
                      <Typography>Sign Up via Facebook</Typography>
                    </Button>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                      sx={{
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "70%",
                          lg: "70%",
                        },
                      }}
                      variant="contained"
                      startIcon={<Google />}
                      onClick={() => handleSocialLogin(true, false)}
                    >
                      <Typography>Sign Up via Google</Typography>
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SignUpPage;
