import React from "react";

const Google = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7358 10.6553H23.7415V10.6041H12.632V15.5417H19.6082C18.5904 18.416 15.8556 20.4792 12.632 20.4792C8.54188 20.4792 5.22569 17.163 5.22569 13.0729C5.22569 8.98275 8.54188 5.66656 12.632 5.66656C14.52 5.66656 16.2377 6.3788 17.5455 7.54222L21.037 4.05075C18.8324 1.9961 15.8834 0.729004 12.632 0.729004C5.81511 0.729004 0.288132 6.25598 0.288132 13.0729C0.288132 19.8898 5.81511 25.4168 12.632 25.4168C19.4489 25.4168 24.9759 19.8898 24.9759 13.0729C24.9759 12.2452 24.8907 11.4373 24.7358 10.6553Z"
        fill="#FFC107"
      />
      <path
        d="M1.71129 7.32743L5.76688 10.3017C6.86425 7.5848 9.52189 5.66656 12.6319 5.66656C14.5199 5.66656 16.2376 6.3788 17.5454 7.54222L21.0369 4.05075C18.8323 1.9961 15.8833 0.729004 12.6319 0.729004C7.89064 0.729004 3.77889 3.40578 1.71129 7.32743Z"
        fill="#FF3D00"
      />
      <path
        d="M12.6321 25.417C15.8205 25.417 18.7176 24.1968 20.908 22.2125L17.0876 18.9796C15.8066 19.9538 14.2414 20.4807 12.6321 20.4794C9.4214 20.4794 6.69525 18.4322 5.66824 15.5752L1.6429 18.6766C3.68581 22.6742 7.8346 25.417 12.6321 25.417Z"
        fill="#4CAF50"
      />
      <path
        d="M24.7359 10.6552H23.7416V10.604H12.6321V15.5416H19.6082C19.1214 16.9095 18.2445 18.1049 17.0858 18.98L17.0876 18.9787L20.908 22.2116C20.6377 22.4572 24.976 19.2447 24.976 13.0728C24.976 12.2451 24.8908 11.4372 24.7359 10.6552Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default Google;
