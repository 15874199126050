import React, { Fragment } from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

const NonProfit = () => {
  //hooks

  const theme = useTheme();

  //render

  return (
    <Fragment>
      <Box
        bgcolor={theme.palette.secondary.main}
        paddingY={3}
        sx={{
          backgroundImage: `url(/images/ContactEllipse.png)`,
          backgroundSize: "25%",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Box paddingBottom={4} paddingTop={{xs:5}}>
            <Typography align={"center"}>
              <Typography
                component={"span"}
                variant="h4"
                fontWeight={"bold"}
                color={"primary.light"}
                sx={{
                  textTransform: "uppercase",
                  borderBottom: `2px solid black`,
                  paddingBottom: 1,
                }}
              >
                NFP’s (Not-for-Profits)
              </Typography>
            </Typography>
          </Box>

          <Typography paddingBottom={3} color={"primary.light"}>
            Up 4 Change assists your Not-for-Profit to raise funds without
            having to constantly reach out and ask!
          </Typography>
          <Typography paddingBottom={3} color={"primary.light"}>
            You can create immediate connections with donors by linking and
            connecting them to a curated social feed full of awesome video &amp;
            photo footage, inspiring them to seamlessly act on their support in
            the form of small micro donations.
          </Typography>
          <Box padding={2}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color={"primary.light"}
              gutterBottom
            >
              - People hate being hassled for money. It just doesn’t work!
            </Typography>
            <Typography color={"primary.light"} gutterBottom>
              - 86% of donors cease supporting a charity due to too frequent
              request for money.
            </Typography>
            <Typography color={"primary.light"} gutterBottom>
              - Up 4 Change transforms the current &amp; inefficient outbound
              marketing strategies to an inbound marketing approach by allowing
              you to curate content about the great work you do for users to
              discover, connect with and support. And we allow you to thank them
              through interactive video feeds so they can see exactly where
              their money has gone!
            </Typography>
            <Typography
              color={"primary.light"}
              variant="h5"
              fontWeight={"bold"}
              gutterBottom
            >
              - Let’s bring the love back to charitable giving
            </Typography>
            <Typography color={"primary.light"} gutterBottom>
              When you authentically communicate with donors in their language
              by focusing on them as kind and generous people and always
              thanking them for being wonderful you can begin to break down the
              negative stigma associated with charitable giving.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box bgcolor={theme.palette.primary.dark} paddingY={3}>
        <Container>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            fontStyle={"italic"}
            variant="h6"
            align="center"
            component={"q"}
          >
            According to evidence collected by the Institute of Sustainable
            Philanthropy, NFPs that adopt these principles of loving their
            donors see a doubling of giving within 18 months.
          </Typography>
        </Container>
      </Box>
    </Fragment>
  );
};

export default NonProfit;
