import React from "react";

//package import

import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

//user import

import Round1 from "app/svg/user/Round-1";
import Round2 from "app/svg/user/Round-2";
import LinkRoundUp from "app/svg/user/LinkRoundUp";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "animate.css/animate.min.css";
//component

const SimpleSteps = () => {
  //hooks

  const theme = useTheme();

  //render
  // const Bounce = styled.div`animation: 2s ${keyframes`${bounce}`} infinite`;
  const bounceAnimation = keyframes`${pulse}`;

  const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;
  return (
    <Box
      sx={{
        backgroundImage: "url(/images/MethodDrawImage.png)",
        backgroundSize: "cover",
        padding: 2,
      }}
    >
      <Container maxWidth={"lg"}>
        <Typography variant="h4" color={"primary.light"} align="center">
          Round-up in 4 simple steps.
        </Typography>

        <Box display={"flex"} justifyContent={"center"} marginTop={2}>
          <Box
            sx={{
              borderBottom: `2px solid ${theme.palette.primary.light}`,
              width: "15%",
            }}
          ></Box>
        </Box>
        <Grid container spacing={12} marginTop={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h4" color={"primary.light"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Choose a cause you care about.
              </AnimationOnScroll>
            </Typography>
            <Typography variant="body1" color={"common.black"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Connect with causes you care about through a centralised
                tailored social feed. Add them to your favourites!
              </AnimationOnScroll>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <Round1 />
              </BouncyDiv>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={6}
                animateIn="animate__bounceOutUp"
              >
                <Round2 />
              </AnimationOnScroll>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box padding={2}>
              <BouncyDiv>
                <img src={"/images/Round-Up.png"} alt="Round" width={"100%"} />
              </BouncyDiv>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h4" color={"primary.light"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Tell us what amount you want to round up to.
              </AnimationOnScroll>
            </Typography>
            <Typography variant="body1" color={"common.black"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Select round up limits for each transaction as well as monthly
                limits.You can also choose a one-off donation.
              </AnimationOnScroll>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h4" color={"primary.light"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Securely link your everyday transaction account and spend
                normally!
              </AnimationOnScroll>
            </Typography>
            <Typography variant="body1" color={"common.black"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Up 4 Change seamlessly rounds up your transactions in the
                background to the nearest $1 and automatically donates your
                spare change to your preferred charity.
              </AnimationOnScroll>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box display={"flex"} justifyContent={"center"}>
              <BouncyDiv>
                <LinkRoundUp />
              </BouncyDiv>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box padding={2}>
              <BouncyDiv>
                <img src={"/images/Coin.png"} alt="Round" width={280} />
              </BouncyDiv>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h4" color={"primary.light"} align="right">
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Have a say in where your funds go!
              </AnimationOnScroll>
            </Typography>
            <Typography variant="body1" color={"common.black"}>
              <AnimationOnScroll
                initiallyVisible={false}
                duration={1}
                animateIn="animate__fadeInUp"
              >
                Select round up limits for each transaction as well as monthly
                limits.You can also choose a one-off donation.
              </AnimationOnScroll>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SimpleSteps;
