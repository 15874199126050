import moment from "moment";

export const getArrayFromObject = (obj) => {
  return Object.values(obj).map((item) => ({
    name: item
      ?.replace(/_/g, ' ')
      ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
    value: item,
  }));
};

export const formatCategory = (item) =>
  item
    ?.replace(/_/g, ' ')
    ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const makeQuestionObject = (arr) => {
  return arr?.reduce(
    (o, key) => ({ ...o, [key?.category]: key?.sub_categories }),
    {}
  );
};

export const makeSubCategoryArray = (arr) => {
  return arr?.map((key) => {
    let objkey = Object.keys(key);
    let objName = objkey[0];
    let name = objName?.replace(/_/g, ' ')
      ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    let objValue = Object.values(key);
    let objVal = objValue[0];
    let value = Math.round(objVal * 100) / 100;
    return { name, value };
  });
};

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YY')
}

export const formatDateTime = (date) => {
  return moment(date).format('DD MMM yy hh:mm A')
 
}
export const formatDateTimeOnly = (date) => {
  return moment(date).format('hh:mm A')
 
}