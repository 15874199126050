import React from "react";

//user import

import CompaniesPage from "app/components/user/Companies";
import { Layout } from "app/components/user";

//component

const Companies = () => {
  //render

  return (
    <Layout>
      <CompaniesPage />
    </Layout>
  );
};

export default Companies;
