import React from "react";

//package import

import { Box, Button, Typography, useTheme, Container } from "@mui/material";

//user import

import MotoLike from "app/svg/user/MotoLike";
import { userRoutes } from "app/Services/user/routes/APP";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
//component

const Mission = () => {
  //hooks

  const theme = useTheme();

  //render
  const bounceAnimation = keyframes`${pulse}`;

  const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
      }}
      paddingY={2}
    >
      <Container maxWidth={"md"}>
        <Typography
          variant="h3"
          fontWeight={"bold"}
          align="center"
          color={"common.black"}
          gutterBottom
        >
          Our mission…
        </Typography>
        <Typography align="center" color={"common.black"}>
          We are on a mission to transform pocket change into a big change
          through an engaging digital experience. We connect donations directly
          to impact, breaking down barriers and building up supportive
          communities.
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color={"common.black"}
          fontWeight={"bold"}
          gutterBottom
        >
          But we need your help!
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color={"common.black"}
          fontWeight={"bold"}
          gutterBottom
        >
          Are YOU Up 4 Change??
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <BouncyDiv>
            <MotoLike />
          </BouncyDiv>
        </Box>
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
          <Button variant="contained" href={userRoutes?.logIn}>
            GET STARTED
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Mission;
