import React, { useEffect, useCallback, useState } from "react";
import { Box } from "@mui/material";
//packages imports

import { IoIosCopy } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { headersGet } from "app/Services/admin/API";
import { API_Urls } from "app/Services/admin/Routes/API";

// user imports

// import {headersGet} from '../../Services/API'
// import {API_Urls} from '../../Services/Routes/API'

//component

const OrgBankDetailModal = ({ show, handleClose, id, orgDetail }) => {
  const { company_name, organisation_image } = orgDetail || {};
  //states

  const [bankDetails, setBankDetails] = useState(null);

  //get bank details handler

  const getBankDetails = useCallback(() => {
    headersGet(`${API_Urls?.get_org_bank_details}?organisation=${id}`)
      .then((res) => {
        if (res?.status === 200 && !res?.data?.error) {
          setBankDetails(res?.data?.account);
        } else {
        }
      })
      .catch((err) => {});
  }, [id]);

  useEffect(() => {
    id && getBankDetails();
  }, []);

  //render

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p> </p>
          <Link to="#/" onClick={handleClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md my-4">
          <div className="col-lg-12">
            <div className="m-organ text-center">
              <p className="billing-details pb-3">Billing Details</p>
              <div className="logo-div mx-auto d-block">
                {/* <img
                  src={
                    organisation_image?.length > 0
                      ? organisation_image[0]
                      : "/images/organ-logo.svg"
                  }
                  alt="organisation-logo"
                  width={"100%"}
                /> */}
                <Box
                  component="img"
                  width={{
                    xs: 50,
                    sm: 50,
                    md: "100px",
                    lg: "100px",
                  }}
                  height={{
                    xs: 50,
                    sm: 50,
                    md: "100px",
                    lg: "100px",
                  }}
                  borderRadius={"100%"}
                  border={"5px solid #ff9776"}
                  src={
                    organisation_image?.length > 0
                      ? organisation_image[0]
                      : "/images/organ-logo.svg"
                  }
                  //   alt={organisation.name}
                  alt="organisation-logo"
                />
              </div>
              <div className="billing-name-div">
                <p>{company_name}</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="data-billing">
                <div className="d-flex align-items-center justify-content-center data-bill">
                  <p>NAME : </p>
                  <p>
                    {bankDetails?.authorised_person_contact_name ||
                      "Not Available"}{" "}
                    <IoIosCopy className="copy" />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center data-bill">
                  <p>BANK: </p>
                  <p>
                    {bankDetails?.bank || "Not Available"}{" "}
                    <IoIosCopy className="copy" />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center data-bill">
                  <p>BSB NUMBER: </p>
                  <p>
                    {bankDetails?.bsb_number || "Not Available"}{" "}
                    <IoIosCopy className="copy" />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center data-bill">
                  <p>ACCOUNT NUMBER: </p>
                  <p>
                    {bankDetails?.account_number || "Not Available"}{" "}
                    <IoIosCopy className="copy" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrgBankDetailModal;
