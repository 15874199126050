import React from "react";

const MessageIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "46"}
      height={height ? height : "46"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M36 17.4811V29.0625C36 30.1399 35.572 31.1733 34.8101 31.9351C34.0483 32.697 33.0149 33.125 31.9375 33.125H14.0625C12.9851 33.125 11.9517 32.697 11.1899 31.9351C10.428 31.1733 10 30.1399 10 29.0625V17.4811L22.5873 24.8879C22.7123 24.9616 22.8548 25.0005 23 25.0005C23.1452 25.0005 23.2877 24.9616 23.4127 24.8879L36 17.4811ZM31.9375 12C32.9371 11.9998 33.9017 12.3682 34.6467 13.0347C35.3917 13.7012 35.8649 14.6189 35.9756 15.6124L23 23.245L10.0244 15.6124C10.1351 14.6189 10.6083 13.7012 11.3533 13.0347C12.0983 12.3682 13.0629 11.9998 14.0625 12H31.9375Z"
        fill={color ? color : "#230D4D"}
      />
    </svg>
  );
};

export default MessageIcon;
