import React from "react";

//package import

import { Box, Container, Typography, useTheme } from "@mui/material";

//user import

import {
  FreeGivingIcon,
  GenerosityIcon,
  TogetherIcon,
  TransparencyIcon,
  LivingMomentIcon,
} from "app/svg/user/AboutUs";

//component

const WeBeliev = () => {
  //hooks

  const theme = useTheme();

  //render
  return (
    <div id="What we believe in..">
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          backgroundImage: `url(/images/BelieveEllipse.png)`,
          backgroundSize: "10%",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
          padding: theme.spacing(6, 2, 2, 2),
        }}
      >
        <Typography
          align="center"
          variant="h3"
          color={"common.white"}
          fontWeight={"bold"}
        >
          What we believe in..
        </Typography>

        <Box display={"flex"} justifyContent={"center"} padding={2}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.common.white}`,
              width: "20%",
            }}
          ></Box>
        </Box>
        <Container>
          <Box display={"flex"} padding={1}>
            <Box>
              <FreeGivingIcon />
            </Box>
            <Box paddingLeft={2} paddingTop={2}>
              <Typography
                color={"secondary.main"}
                variant="h5"
                fontWeight={"bold"}
              >
                Hassle Free Giving
              </Typography>
              <Box padding={2}>
                <Typography fontWeight={"bold"} color={"common.white"}>
                  People hate being hassled, especially for money. Up 4 Change
                  is a safe space. Connect with causes YOU care about and give
                  on your terms. We promise we will never hassle you to give us
                  more. Giving should be easy and fun, and you should be
                  celebrated for being YOU. It's people like YOU that make the
                  world go around.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} padding={1}>
            <Box>
              <GenerosityIcon />
            </Box>
            <Box paddingLeft={2} paddingTop={2}>
              <Typography
                color={"secondary.main"}
                variant="h5"
                fontWeight={"bold"}
              >
                Generosity &amp; kindness
              </Typography>
              <Box padding={2}>
                <Typography fontWeight={"bold"} color={"common.white"}>
                  Just be kind. Generosity doesn’t have to be financial. Pay it
                  forward, provide a random act of kindness, pay someone a
                  compliment. Just be the sort of person your dog thinks you are
                  😉
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} marginLeft={4} padding={1}>
            <Box marginRight={2}>
              <TogetherIcon />
            </Box>
            <Box paddingLeft={2} paddingTop={2}>
              <Typography
                color={"secondary.main"}
                variant="h5"
                fontWeight={"bold"}
              >
                Making a difference-together
              </Typography>
              <Box padding={2}>
                <Typography fontWeight={"bold"} color={"common.white"}>
                  Think about this…In just one day if we all rounded up our
                  digital transactions even by just 4c as a nation we would
                  raise just under 1 million $$ PER DAY!!! Small change can make
                  a massive impact.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} marginLeft={2} padding={1}>
            <Box>
              <TransparencyIcon />
            </Box>
            <Box paddingLeft={2} paddingTop={2}>
              <Typography
                color={"secondary.main"}
                variant="h5"
                fontWeight={"bold"}
              >
                Transparency
              </Typography>
              <Box padding={2}>
                <Typography fontWeight={"bold"} color={"common.white"}>
                  {/* Yes we have bills we have to pay, fee's must be charged but you
                can read exactly what they are and how they work in our
                T&amp;C's here. We have built this awesome platform for you so
                that we can all globally connect and make real change. */}
                  We want our changemakers to globally connect and see the
                  difference they have made with their change.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} marginLeft={2} padding={1}>
            <Box>
              <LivingMomentIcon />
            </Box>
            <Box paddingLeft={2} paddingTop={2}>
              <Typography
                color={"secondary.main"}
                variant="h5"
                fontWeight={"bold"}
              >
                Living in the moment
              </Typography>
              <Box padding={2}>
                <Typography fontWeight={"bold"} color={"common.white"}>
                  Don't linger too long or the moment will pass you by. Connect
                  now, make a difference in real time.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default WeBeliev;
