import React from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
//user import
import { formatCategory, formatDate } from "app/common/organization/functions";
import jsPDF from "jspdf";
const TransactionModel = ({
  show = false,
  handleClose = () => {},
  transaction = {},
}) => {
  const { amount, user, organisation, createdAt, charges, id, subscription } =
    transaction || {};

  const { name } = user || {};
  const { category, name: orgName, organisation_image } = organisation || {};

  const mTableArea = () => {
    return (
      <tr key={id}>
        <td>{name || "---"}</td>
        {/* <td>{charges || subscription || "---"}</td> */}
        <td>{charges || "---"}</td>
        <td>{formatDate(createdAt || "")}</td>
        <td>${amount || "---"}</td>
      </tr>
    );
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Donations History";
    const headers = [
      [
        "DATE",
        "Organisation",
        "CATEGORY",
        "CHARITY NAME",
        "TYPE",
        "DONATION AMOUNT",
        "FEES",
        "TOTAL",
      ],
    ];

    const data = [transaction];
    const data1 = data.map((elt) => [
      elt.updatedAt,
      elt.organisation.name,
      elt.organisation.category,
      elt.organisation.company_name,
      elt.type,
      elt.amount,
      elt?.pp_fees,
      elt?.pp_fees ? elt?.amount + elt.pp_fees : elt.amount,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data1,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Donations History.pdf");
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <div className="d-flex justify-content-end">
          <Link to="#/" onClick={handleClose}>
            <img
              src="/images/close.svg"
              alt="close"
              style={{ height: "15px" }}
            />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-md pb-3">
          <div className="col-lg-12">
            <div className="m-organ text-center">
              <div className="logo-div mx-auto d-block">
                {organisation_image && organisation_image?.length > 0 ? (
                  <img
                    src={`${organisation_image[0]}`}
                    alt="organisation-logo"
                  />
                ) : (
                  <Avatar></Avatar>
                )}
              </div>
              <div className="m-name-div">
                <p>{orgName}</p>
              </div>
              <p className="m-fname">{formatCategory(category)}</p>
            </div>

            <div className="my-4 m-table">
              <table className="table">
                <tbody>{mTableArea()}</tbody>
              </table>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-pink py-2" onClick={exportPDF}>
                Export
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModel;
