import React from "react";

const InstagramIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "46"}
      height={height ? height : "46"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M22.9962 18.3313C20.4255 18.3313 18.3277 20.4293 18.3277 23C18.3277 25.5707 20.4255 27.6687 22.9962 27.6687C25.5669 27.6687 27.6648 25.5707 27.6648 23C27.6648 20.4293 25.5669 18.3313 22.9962 18.3313ZM36.9984 23C36.9984 21.0667 37.0159 19.1509 36.9074 17.2211C36.7988 14.9796 36.2875 12.9902 34.6484 11.3511C33.0058 9.70852 31.02 9.20068 28.7785 9.0921C26.8453 8.98353 24.9295 9.00104 22.9997 9.00104C21.0665 9.00104 19.1507 8.98353 17.2209 9.0921C14.9795 9.20068 12.9902 9.71202 11.3511 11.3511C9.70851 12.9937 9.20067 14.9796 9.0921 17.2211C8.98353 19.1544 9.00104 21.0702 9.00104 23C9.00104 24.9298 8.98353 26.8491 9.0921 28.7789C9.20067 31.0204 9.71201 33.0098 11.3511 34.6489C12.9937 36.2915 14.9795 36.7993 17.2209 36.9079C19.1542 37.0165 21.07 36.999 22.9997 36.999C24.933 36.999 26.8488 37.0165 28.7785 36.9079C31.02 36.7993 33.0093 36.288 34.6484 34.6489C36.291 33.0063 36.7988 31.0204 36.9074 28.7789C37.0195 26.8491 36.9984 24.9333 36.9984 23ZM22.9962 30.1834C19.0211 30.1834 15.813 26.9752 15.813 23C15.813 19.0248 19.0211 15.8166 22.9962 15.8166C26.9713 15.8166 30.1795 19.0248 30.1795 23C30.1795 26.9752 26.9713 30.1834 22.9962 30.1834ZM30.4736 17.2001C29.5455 17.2001 28.796 16.4506 28.796 15.5224C28.796 14.5943 29.5455 13.8448 30.4736 13.8448C31.4018 13.8448 32.1513 14.5943 32.1513 15.5224C32.1515 15.7428 32.1083 15.9611 32.0241 16.1648C31.9399 16.3684 31.8163 16.5535 31.6605 16.7093C31.5047 16.8652 31.3196 16.9887 31.116 17.0729C30.9123 17.1572 30.694 17.2004 30.4736 17.2001Z"
        fill={color ? color : "#230D4D"}
      />
    </svg>
  );
};

export default InstagramIcon;
