//routes import

import { userRoutes } from "app/Services/user/routes/APP";

//pages

import BoardingQuestions from "app/containers/user/boardingQs";
import GetStarted from "app/containers/user/get-started";
import AllDone from "app/containers/user/all-done";
import Donut from "app/containers/user/donut";

import Discover from "app/containers/user/discover";
import Profile from "app/containers/user/profile";
import ProfileEdit from "app/containers/user/profileEdit";
import OrgProfile from "app/containers/user/org-profile";
import FeedPage from "app/containers/user/feed";
import OrgCause from "app/containers/user/org-cause";
import DonationHistory from "app/containers/user/donation-history";
import Companies from "app/containers/user/companies";
import MyCause from "app/containers/user/my-cause";
// import PaymentInfo from './pages/payment-info'
import PaymentInfo from "app/containers/user/cards-info";
import PaymentRound from "app/containers/user/payment-round";
import CurrentRound from "app/containers/user/current-round";
import Subscriptions from "app/containers/user/subscriptions";
import BasiqTransactionHistory from "app/containers/user/basiq-transaction-history";

//routes array

const PrivateUserRoutesPaths = [
  {
    id: 22,
    path: userRoutes?.root,
    component: <MyCause />,
    exact: true,
  },
  {
    id: 7,
    path: userRoutes?.onboardingQ1,
    component: <BoardingQuestions />,
    exact: true,
  },
  {
    id: 8,
    path: userRoutes?.getStarted,
    component: <GetStarted />,
    exact: true,
  },
  {
    id: 9,
    path: userRoutes?.allDone,
    component: <AllDone />,
    exact: true,
  },
  {
    id: 10,
    path: userRoutes?.donut,
    component: <Donut />,
    exact: true,
  },

  {
    id: 12,
    path: userRoutes?.discover,
    component: <Discover />,
    exact: true,
  },
  {
    id: 13,
    path: userRoutes?.profile,
    component: <Profile />,
    exact: true,
  },
  {
    id: 14,
    path: userRoutes?.profileEdit,
    component: <ProfileEdit />,
    exact: true,
  },
  {
    id: 15,
    path: userRoutes?.orgProfileWithId,
    component: <OrgProfile />,
    exact: true,
  },
  {
    id: 16,
    path: userRoutes?.feed,
    component: <FeedPage />,
  },
  {
    id: 17,
    path: userRoutes?.orgCauseWithId,
    component: <OrgCause />,
    exact: true,
  },
  {
    id: 18,
    path: userRoutes?.donationHistory,
    component: <DonationHistory />,
    exact: true,
  },

  {
    id: 19,
    path: userRoutes?.basiqTransactionHistory,
    component: <BasiqTransactionHistory />,
    exact: true,
  },

  {
    id: 21,
    path: userRoutes?.companies,
    component: <Companies />,
    exact: true,
  },
  {
    id: 22,
    path: userRoutes?.cause,
    component: <MyCause />,
    exact: true,
  },
  {
    id: 23,
    path: userRoutes?.paymentInfo,
    component: <PaymentInfo />,
    exact: true,
  },
  {
    id: 24,
    path: userRoutes?.paymentRound,
    component: <PaymentRound />,
    exact: true,
  },
  {
    id: 24,
    path: userRoutes?.currentround,
    component: <CurrentRound />,
    exact: true,
  },
  {
    id: 420,
    path: userRoutes?.subscriptions,
    component: <Subscriptions />,
    exact: true,
  },
];

export default PrivateUserRoutesPaths;
