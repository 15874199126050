import React, { useState, useEffect } from "react";

//package import

import useMediaQuery from "@mui/material/useMediaQuery";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { Close as CloseIcon } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Container,
  Button,
  LinearProgress,
  Modal,
} from "@mui/material";

//user import

import Goal1Icon from "app/svg/user/orgProfile/Goal1";
import SupportIcon from "app/svg/user/orgProfile/Support";
import { userRoutes } from "app/Services/user/routes/APP";
import { isLoggedIn } from "app/Services/user/routes/APP/UserPrivateRoute";

//component

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};
const OrgCard = ({ modalOpen, data }) => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleOpen1 = (img) => {
    if (img) {
      if (open1 === false) {
        setOpen1(true);
      }
    } else {
      return;
    }
  };
  const handleOpen2 = (img) => {
    if (img) {
      if (open2 === false) {
        setOpen2(!open2);
      }
    } else {
      return;
    }
  };
  const handleOpen3 = (img) => {
    if (img) {
      if (open3 === false) {
        setOpen3(!open3);
      }
    } else {
      return;
    }
  };
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(!open2);
  const handleClose3 = () => setOpen3(false);
  const matches = useMediaQuery("(min-width:1025px)");
  const matches1 = useMediaQuery("(max-width:700px)");
  const matches2 = useMediaQuery("(max-width:480px)");

  const [countOfProgress, setCountOfProgress] = useState(0);

  const isLogin = isLoggedIn();
  // console.log("====================================");
  // console.log({ isLogin });
  // console.log("====================================");
  //hooks
  const navigate = useNavigate();
  const searchQuery = window.location.search;

  // console.log({ data });
  const theme = useTheme();
  const supportHandler = () => {
    if (isLogin) {
      modalOpen();
    } else {
      navigate(
        `${userRoutes?.logIn}?referBack=/org-cause${searchQuery && searchQuery}`
      );
    }
  };

  //render

  const styles = {
    heroContainer: {
      height: 600,
      backgroundImage: `url(${data?.cause?.cause_image})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
    },
  };

  const calculateGoalPercen = (fund_raising_goal, fund_raised) => {
    if (fund_raising_goal === fund_raised) {
      return 100;
    } else {
      let percen = (fund_raised / fund_raising_goal) * 100;
      if (percen > 100) {
        percen = 100;
        return percen;
      } else {
        return percen;
      }

      // return ( fund_raised/fund_raising_goal)*100 ;
    }
  };
  useEffect(() => {
    let fund_raised = data?.cause?.fund_raised;
    let fund_raising_goal = data?.cause?.fund_raising_goal;

    // let  fund_raised= 10;
    // let  fund_raising_goal= 100;
    setCountOfProgress(calculateGoalPercen(fund_raising_goal, fund_raised));
  }, [data]);

  const video_img_Formats = ["mp4"];
  let apiArray =
    data?.cause?.micro_goal1?.image && data?.cause.micro_goal1.image.split(".");

  let source = apiArray && apiArray.length > 0 && apiArray[apiArray.length - 1];

  let video;
  let img;
  if (video_img_Formats.includes(source)) {
    video = data?.cause?.micro_goal1?.image;
  } else {
    img = data?.cause?.micro_goal1?.image;
  }

  const video_img_Formats1 = ["mp4"];
  let apiArray1 =
    data?.cause?.micro_goal2?.image && data?.cause.micro_goal2.image.split(".");

  let source1 =
    apiArray1 && apiArray1.length > 0 && apiArray1[apiArray1.length - 1];

  let video1;
  let img1;
  if (video_img_Formats1.includes(source1)) {
    video1 = data?.cause?.micro_goal2?.image;
  } else {
    img1 = data?.cause?.micro_goal2?.image;
  }

  const video_img_Formats2 = ["mp4"];
  let apiArray2 =
    data?.cause?.micro_goal3?.image && data?.cause.micro_goal3.image.split(".");

  let source2 =
    apiArray2 && apiArray2.length > 0 && apiArray2[apiArray2.length - 1];

  let video2;
  let img2;
  if (video_img_Formats2.includes(source2)) {
    video2 = data?.cause?.micro_goal3?.image;
  } else {
    img2 = data?.cause?.micro_goal3?.image;
  }

  return (
    <Box
      bgcolor={theme.palette.common.white}
      borderLeft={`2px solid ${theme.palette.primary.main}`}
    >
      <Grid container>
        {/* <Grid item xs={1} sm={1} md={1}></Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <Box
          // display={"flex"}
          // paddingY={1}
          //   alignItems={"center"}
          >
            <Box padding={2}>
              <Box>
                <Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {/* <Box>
                                            {data?.cause?.organisation_tag ===
                                                'community_org' && (
                                                <VerifiedIcon
                                                    width={'20px'}
                                                    height={'20px'}
                                                />
                                            )}
                                            {data?.cause?.organisation_tag ===
                                                'ACNC_verified' && (
                                                <VerifiedIcon
                                                    color={
                                                        theme.palette.secondary
                                                            .main
                                                    }
                                                    width={'20px'}
                                                    height={'20px'}
                                                />
                                            )}
                                            {data?.cause?.organisation_tag ===
                                                'DGR_verified' && (
                                                <VerifiedIcon
                                                    color={
                                                        theme.palette.primary
                                                            .dark
                                                    }
                                                    width={'20px'}
                                                    height={'20px'}
                                                />
                                            )}
                                        </Box> */}
                    {/* <Typography
                                            fontWeight={'bold'}
                                            paddingLeft={1}
                                            sx={{
                                                fontFamily: 'Circular Std',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '12px',
                                                    sm: '12px',
                                                    md: '16px',
                                                    lg: '16px',
                                                },
                                                lineHeight: '10px',
                                                paddingBottom: '8px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {abc(data?.cause?.organisation_tag)}
                                        </Typography> */}
                  </Box>
                </Box>

                {/* <Typography
                                    color={'text.red'}
                                    fontStyle={'italic'}
                                    sx={{
                                        fontFamily: 'Circular Std',
                                        fontWeight: 500,
                                        fontSize: {
                                            xs: '14px',
                                            sm: '14px',
                                            md: '18px',
                                            lg: '18px',
                                        },
                                        lineHeight: '14px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Category -{' '}
                                    {CATEGORIES_OPTIONS[data?.org?.category]}
                                </Typography> */}
              </Box>
              <Typography
                sx={{
                  fontFamily: "Circular Std",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "30px",
                    lg: "30px",
                  },
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              >
                {data?.cause?.title}
                {/* {data?.org?.cause} */}
              </Typography>
              <Box sx={{ marginTop: "10px" }}>
                <Typography color={theme.palette.primary.main} align="center">
                  DONATION GOAL
                </Typography>
              </Box>
              <Box>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    height: {
                      xs: "7px",
                      sm: "7px",
                      md: "17px",
                      lg: "17px",
                    },
                    borderRadius: 5,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor:
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      borderRadius: 5,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    },
                  }}
                  value={countOfProgress}
                />
              </Box>
              <Box display={"flex"} paddingY={1}>
                <Box
                  width={"25%"}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <Box>
                    <Typography
                      align="center"
                      sx={{
                        fontFamily: "Circular Std",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "9px",
                        lineHeight: "11px",
                      }}
                    >
                      Raised
                    </Typography>
                    <Typography
                      align="center"
                      color={theme.palette.primary.main}
                      sx={{
                        fontFamily: "Circular Std",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                    >
                      $
                      {data?.cause?.fund_raised
                        ? parseInt(data?.cause?.fund_raised)
                        : 0}
                    </Typography>
                  </Box>
                </Box>
                <Box width={"75%"} display={"flex"} justifyContent={"flex-end"}>
                  <Box>
                    <Typography
                      align="right"
                      sx={{
                        fontFamily: "Circular Std",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "9px",
                        lineHeight: "11px",
                      }}
                    >
                      Goal
                    </Typography>
                    <Typography
                      align="right"
                      color={theme.palette.primary.main}
                      sx={{
                        fontFamily: "Circular Std",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                    >
                      ${data?.cause?.fund_raising_goal}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box bgcolor={theme.palette.secondary.main} paddingY={1}>
            <Container>
              <Box paddingY={1}>
                <Grid container>
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      onClick={() =>
                        handleOpen1(data?.cause?.micro_goal1?.image)
                      }
                    >
                      <Box>
                        <Box width={35} borderRadius={"100%"}>
                          {data?.cause?.micro_goal1?.icon ? (
                            <img
                              src={data?.cause?.micro_goal1?.icon}
                              alt={data?.cause?.micro_goal1?.value}
                              width={"100%"}
                            />
                          ) : (
                            <Goal1Icon />
                          )}
                        </Box>
                      </Box>
                      <Modal
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose1}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {data?.cause?.micro_goal1?.icon ? (
                                          <img
                                            src={data?.cause?.micro_goal1?.icon}
                                            alt={
                                              data?.cause?.micro_goal1?.value
                                            }
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {data?.cause?.micro_goal1?.value}
                                        {data?.cause?.micro_goal1?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {video && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                          )}
                          {img && (
                            <img
                              src={img}
                              alt="img"
                              width="100%"
                              height="70%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                      <Typography align="center">
                        {data?.cause?.micro_goal1?.value}{" "}
                        {data?.cause?.micro_goal1?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      onClick={() =>
                        handleOpen2(data?.cause?.micro_goal2?.image)
                      }
                    >
                      <Box>
                        <Box width={35} borderRadius={"100%"}>
                          {data?.cause?.micro_goal2?.icon ? (
                            <img
                              src={data?.cause?.micro_goal2?.icon}
                              alt={data?.cause?.micro_goal2?.value}
                              width={"100%"}
                            />
                          ) : (
                            <Goal1Icon />
                          )}
                        </Box>
                      </Box>
                      <Modal
                        open={open2}
                        onClose={handleClose2}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose2}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {data?.cause?.micro_goal2?.icon ? (
                                          <img
                                            src={data?.cause?.micro_goal2?.icon}
                                            alt={
                                              data?.cause?.micro_goal2?.value
                                            }
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {data?.cause?.micro_goal2?.value}
                                        {data?.cause?.micro_goal2?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {video1 && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={video1} type="video/mp4" />
                            </video>
                          )}
                          {img1 && (
                            <img
                              src={img1}
                              alt="img"
                              width="100%"
                              height="70%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                      <Typography align="center">
                        {data?.cause?.micro_goal2?.value}{" "}
                        {data?.cause?.micro_goal2?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      onClick={() =>
                        handleOpen3(data?.cause?.micro_goal3?.image)
                      }
                    >
                      <Box>
                        <Box width={35} borderRadius={"100%"}>
                          {data?.cause?.micro_goal3?.icon ? (
                            <img
                              src={data?.cause?.micro_goal3?.icon}
                              alt={data?.cause?.micro_goal3?.value}
                              width={"100%"}
                            />
                          ) : (
                            <Goal1Icon />
                          )}
                        </Box>
                      </Box>
                      <Modal
                        open={open3}
                        onClose={handleClose3}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box textAlign={"right"}>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose3}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <Typography
                            align="center"
                            fontWeight={"bold"}
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "25px",
                              lineHeight: "40px",

                              paddingBottom: "15px",
                            }}
                          >
                            OUR GOALS
                          </Typography>
                          <Box
                            bgcolor={theme.palette.secondary.main}
                            paddingY={1}
                          >
                            <Container>
                              <Box paddingY={0.2}>
                                <Grid
                                  container
                                  sx={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      // paddingLeft={"250px"}
                                    >
                                      <Box width={35} borderRadius={"100%"}>
                                        {data?.cause?.micro_goal3?.icon ? (
                                          <img
                                            src={data?.cause?.micro_goal3?.icon}
                                            alt={
                                              data?.cause?.micro_goal3?.value
                                            }
                                            width={"100%"}
                                          />
                                        ) : (
                                          <Goal1Icon />
                                        )}
                                      </Box>
                                      <Typography
                                        align="center"
                                        sx={{
                                          fontFamily: "Circular Std",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "11px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        {data?.cause?.micro_goal3?.value}
                                        {data?.cause?.micro_goal3?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Container>
                          </Box>

                          {video2 && (
                            <video
                              width={"100%"}
                              height={"70%"}
                              loop="true"
                              controls="controls"
                              autoplay="autoplay"
                              muted
                            >
                              <source src={video2} type="video/mp4" />
                            </video>
                          )}
                          {img2 && (
                            <img
                              src={img2}
                              alt="img"
                              width="100%"
                              height="65%"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>

                      <Typography align="center">
                        {data?.cause?.micro_goal3?.value}{" "}
                        {data?.cause?.micro_goal3?.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
          <Box
            paddingY={2}
            width={"80%"}
            sx={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Box paddingRight={2}>
                  <Typography
                    color={"text.liteGray"}
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "18px",
                        lg: "18px",
                      },
                      lineHeight: "20px",
                      textAlign: "justify",
                    }}
                  >
                    {data?.cause?.description}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={2}>
                <Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                                            fontWeight={'bold'}
                                            paddingLeft={1}
                                            sx={{
                                                fontFamily: 'Circular Std',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                lineHeight: '10px',
                                                paddingBottom: '8px',
                                            }}
                                        >
                                            {abc(data?.cause?.organisation_tag)}
                                        </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <VerifiedIcon color={theme.palette.secondary.main} />
                    </Box>
                    <Typography fontWeight={"bold"} paddingLeft={1}>
                      ACN Verified
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <VerifiedIcon color={theme.palette.primary.dark} />
                    </Box>
                    <Typography fontWeight={"bold"} paddingLeft={1}>
                      DGR Verified
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Box>
          <Box>
            {/* <img src={data?.cause?.cause_image} alt="Cause" width={"100%"} height={"100%"}></img> */}
            {!matches2 && <Grid item style={styles.heroContainer}></Grid>}
            {!matches && (
              <img
                src={data?.cause?.cause_image}
                width={"100%"}
                height={"100%"}
              />
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={1} sm={1} md={1}>
                    <Box paddingTop={2} textAlign={'center'}>
                        <LoveIcon />
                    </Box>
                </Grid> */}
      </Grid>
      <Box paddingY={1} textAlign={"center"}>
        <Button
          variant="contained"
          startIcon={<SupportIcon />}
          onClick={supportHandler}
        >
          SUPPORT
        </Button>
      </Box>
    </Box>
  );
};

export default OrgCard;
