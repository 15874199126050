import { createContext } from "react";

export const context = createContext({
  id: "",
  company_name: "",
  email: "",
  description: "",
  cause: "",
  category: "",
  abn_number: "",
  isActive: false,
  isFeatured: false,
  isBank: false,
  name: "",
  organisation_type: "",
  organisation_image: [],
  // for user
  setUser: (user) => {},
  user: {
    _id: "",
    name: "",
    phone_number: "",
    profile_pic: "",
    sub_category_percentage: [],
  },
});

context.displayName = "Org Context";
