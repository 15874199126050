import React, { Fragment } from "react";

//package imports

import { useLocation } from "react-router-dom";

//user imports

import CurrentRoundPage from "app/components/user/CurrentRound";
import { Layout } from "app/components/user";

//component

const CurrentRound = () => {
  //hooks

  const { pathname } = useLocation();

  //render

  return (
    <Fragment>
      {pathname?.includes("/user") ? (
        <CurrentRoundPage />
      ) : (
        <Layout>
          <CurrentRoundPage />
        </Layout>
      )}
    </Fragment>
  );
};

export default CurrentRound;
