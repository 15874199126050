import React, { useState } from "react";

//package import

import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
  Modal,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Formik, Form } from "formik";
import { bounce } from "react-animations";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Search as SearchIcon } from "@mui/icons-material";
import { AnimationOnScroll } from "react-animation-on-scroll";
//css import
import "animate.css/animate.min.css";
//user import

import Moto from "app/svg/user/Moto";

import { userRoutes } from "app/Services/user/routes/APP";
import { AUS_STATES, MenuProps } from "app/common/user/constants";
import { CATEGORIES_ARRAY } from "app/common/user/Enums";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 600,
  bgcolor: "#f9cc69",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

const initialValues = {
  location: "",
  category: "",
  name: "",
};
//component

const Hero = () => {
  //hooks

  const theme = useTheme();
  const navigate = useNavigate();
  //states
  const [open, setOpen] = useState(false);

  const bounceAnimation = keyframes`${bounce}`;

  const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const matches = useMediaQuery("(min-width:1025px)");
  const matches1 = useMediaQuery("(max-width:700px)");

  const onSubmit = (values) => {
    const { location, category, name } = values || {};
    window.location.href = `${userRoutes?.discover}?${
      location ? `location=${location}` : ""
    }${category ? `&category=${category}` : ""}${
      name ? `&searchQuery=${name}` : ""
    }`;

    // navigate(
    //     `${userRoutes?.discover}?${location ? `location=${location}` : ''}${
    //         category ? `&category=${category}` : ''
    //     }${name ? `&searchQuery=${name}` : ''}`
    // )
  };
  //render

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
      }}
    >
      <Grid
        container
        display={"flex"}
        direction={"row"}
        alignItems="stretch"
        sx={{
          backgroundImage: `url(/images/herobg.png)`,
          backgroundSize: "60%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              paddingTop: 4,
            }}
          >
            <Container maxWidth={"sm"}>
              <Box paddingY={3}>
                <Box display={"flex"} justifyContent={"center"}>
                  <BouncyDiv>
                    <Moto />
                  </BouncyDiv>
                </Box>
                <Typography
                  variant={"h3"}
                  color={"text.light"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  <AnimationOnScroll
                    initiallyVisible={false}
                    duration={1}
                    animateIn="animate__fadeInUp"
                  >
                    Hey change maker!
                  </AnimationOnScroll>
                </Typography>
                <Typography
                  variant={"h3"}
                  color={"text.light"}
                  fontWeight={"bold"}
                  gutterBottom
                  textAlign={"center"}
                >
                  <AnimationOnScroll
                    initiallyVisible={false}
                    duration={1}
                    animateIn="animate__fadeInUp"
                  >
                    Ready to create global change?
                  </AnimationOnScroll>
                </Typography>
                <Typography
                  fontWeight={"bold"}
                  color={"text.light"}
                  textAlign={"center"}
                >
                  <AnimationOnScroll
                    initiallyVisible={false}
                    duration={1}
                    animateIn="animate__fadeInUp"
                  >
                    Up 4 Change is transforming pocket change into
                  </AnimationOnScroll>
                </Typography>
                <Typography
                  fontWeight={"bold"}
                  color={"text.light"}
                  textAlign={"center"}
                  gutterBottom
                >
                  <AnimationOnScroll
                    initiallyVisible={false}
                    duration={1}
                    animateIn="animate__fadeInUp"
                  >
                    global change through hassle free giving!
                  </AnimationOnScroll>
                </Typography>
                <Box marginTop={3} textAlign={"center"}>
                  <Button variant="contained" href={userRoutes?.logIn}>
                    GET STARTED
                  </Button>
                </Box>
                {/* <Typography
                  fontWeight={"bold"}
                  paddingTop={2}
                  color={"text.light"}
                  align="center"
                  textAlign={"justify"}
                >
                  You can seamlessly donate your small change by connecting your
                  everyday transaction account and simply going about your
                  everyday life! Coffee runs, late night Uber Eats and even
                  paying the phone bill all helps to change the world! All the
                  small change from your purchases, both online and in person is
                  automatically rounded up to the nearest dollar, collated and
                  then donated to the causes YOU care about.
                </Typography> */}
              </Box>
            </Container>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // backgroundColor: theme.palette.common.black,
              paddingTop: "30px",
              paddingRight: {
                xs: "0px",
                sm: "0px",
                md: "20px",
                ld: "20px",
              },
            }}
          >
            <Box
              sx={{
                border: "8px solid #EDEDED",
                borderRadius: "4%",
                backgroundColor: "#ededed",
              }}
            >
              {/* <img
                src={"/images/charityVideo.png"}
                alt="video"
                width={"100%"}
                height={"100%"}
              />   */}
              <video
                width={"100%"}
                height={"100%"}
                loop="true"
                controls="controls"
                autoplay="autoplay"
                muted
              >
                <source
                  src="/images/up_4_change_-_charitable_round_up_app (1080p) (1).mp4"
                  type="video/mp4"
                />
              </video>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box paddingY={3}>
        <Typography
          color={"common.white"}
          align="center"
          fontWeight={"bold"}
          variant="h5"
        >
          {/* SEARCH FOR ORGANISATION TO SUPPORT */}
          Search for an organisation
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            sx={{
              width: "20%",
              marginTop: 1,
              borderBottom: `2px solid ${theme.palette.common.white}`,
            }}
          ></Box>
        </Box>
        <Box paddingY={3}>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, values, handleChange, handleSubmit, touched }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Container>
                  <Box
                    display={{ md: "flex", sm: "flex" }}
                    justifyContent={{ sm: "center" }}
                  >
                    <Box
                      width={{
                        md: "30%",
                        sm: "50%",
                        xs: "100%",
                      }}
                    >
                      <TextField
                        placeholder="KEYWORDS"
                        variant="standard"
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          border: "none",
                          padding: "19.8px 14px",
                          fontFamily: "Circular Std",
                          fontSize: "16px",
                          fontStyle: "italic",
                          fontWeight: "450",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                        name={"name"}
                        value={values.name}
                        onChange={handleChange}
                      ></TextField>
                    </Box>
                    {matches && (
                      <img
                        src="/images/Line 74.svg"
                        style={{
                          backgroundColor: " white",
                        }}
                        alt={"title"}
                      />
                    )}
                    <Box
                      width={{
                        md: "30%",
                        sm: "50%",
                        xs: "100%",
                      }}
                    >
                      <TextField
                        placeholder="-CATEGORY-"
                        variant="standard"
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          border: "none",
                          fontFamily: "Circular Std",
                          fontSize: "16px",
                          fontStyle: "italic",
                          fontWeight: "450",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                          padding: "19.8px 14px",
                        }}
                        select
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            color: "grey",
                          },
                        }}
                        name={"category"}
                        value={values.category}
                        onChange={handleChange}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                      >
                        <MenuItem value={""} disabled>
                          -CATEGORY-
                        </MenuItem>
                        {CATEGORIES_ARRAY.map((category) => {
                          const { name, value } = category || {};
                          return <MenuItem value={value}>{name}</MenuItem>;
                        })}
                      </TextField>
                    </Box>
                    {matches && (
                      <img
                        src="/images/Line 74.svg"
                        style={{
                          backgroundColor: " white",
                        }}
                        alt={"title"}
                      />
                    )}
                    <Box
                      width={{
                        md: "30%",
                        sm: "50%",
                        xs: "100%",
                      }}
                    >
                      <TextField
                        placeholder="-LOCATION-"
                        variant="standard"
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          border: "none",
                          fontFamily: "Circular Std",
                          fontSize: "16px",
                          fontStyle: "italic",
                          fontWeight: "450",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                          padding: "19.8px 14px",
                        }}
                        select
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: MenuProps,
                        }}
                        name={"location"}
                        value={values.location}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      >
                        <MenuItem value={""} disabled>
                          -LOCATION-
                        </MenuItem>
                        {AUS_STATES.map((state) => {
                          const { value, name } = state || {};
                          return <MenuItem value={value}>{name}</MenuItem>;
                        })}
                      </TextField>
                    </Box>
                    <Box
                      width={{
                        md: "10%",
                        sm: "50%",
                        xs: "100%",
                      }}
                    >
                      <Box color={theme.palette.common.white} width={"100%"}>
                        <Button
                          color="primary"
                          variant={"contained"}
                          sx={{
                            padding: 3,
                            width: "100%",
                          }}
                          type={"submit"}
                        >
                          <SearchIcon color="inherit" width={"100%"} />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
        <Typography color={"common.white"} align="center" fontWeight={"bold"}>
          having trouble finding your organisation?{" "}
          <Typography
            component={"span"}
            color={theme.palette.primary.main}
            borderBottom={`2px solid ${theme.palette.primary.main}`}
          >
            {matches1 && <br />}
            <span onClick={handleOpen} style={{ cursor: "pointer" }}>
              Contact us!
            </span>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box textAlign={"right"}>
                  <img
                    src="./images/closepopup.svg"
                    width={"12px"}
                    onClick={handleClose}
                    alt={"title"}
                  />
                </Box>
                <Box textAlign={"center"}>
                  <img
                    src="./images/Up4Change-Logo 1.svg"
                    width={100}
                    alt={"title"}
                  />
                </Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: {
                      xs: 600,
                      sm: 600,
                      md: 700,
                      lg: 700,
                    },
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "24px",
                      lg: "24px",
                    },
                    lineHeight: "34px",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  Transforming pocket change into big change!
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    fontFamily: "Circular Std",
                    fontStyle: "normal",
                    fontWeight: {
                      xs: 600,
                      sm: 600,
                      md: 700,
                      lg: 700,
                    },
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                    lineHeight: "34px",
                    textAlign: "center",
                    color: "#000000",
                    paddingBottom: 3,
                  }}
                >
                  Stay connected and start making change NOW!
                </Typography>
                <Formik>
                  <Form>
                    <Box paddingLeft={18}>
                      <Box marginBottom={3}>
                        <Typography
                          color={"common.black"}
                          fontWeight={700}
                          fontSize={"12px"}
                          gutterBottom
                        >
                          Enter Name
                        </Typography>
                        <TextField
                          variant={"outlined"}
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            width: 300,
                            borderRadius: "4px",
                          }}
                          name="name"
                          size="small"
                        ></TextField>
                      </Box>

                      <Box marginBottom={3}>
                        <Typography
                          color={"common.black"}
                          fontWeight={700}
                          fontSize={"12px"}
                          gutterBottom
                        >
                          Enter Organisation
                        </Typography>
                        <TextField
                          textAlign={"center"}
                          variant={"outlined"}
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            width: 300,
                            borderRadius: "4px",
                          }}
                          name="Enter Organisation"
                          size="small"
                        ></TextField>
                      </Box>

                      <Box marginBottom={3}>
                        <Typography
                          color={"common.black"}
                          fontWeight={700}
                          fontSize={"12px"}
                          gutterBottom
                        >
                          Enter Email
                        </Typography>
                        <TextField
                          textAlign={"center"}
                          variant={"outlined"}
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            width: 300,
                            borderRadius: "4px",
                          }}
                          name="Enter Email"
                          size="small"
                        ></TextField>
                      </Box>
                      <FormGroup>
                        <Box>
                          <FormControlLabel control={<Checkbox />} label="" />
                          <span
                            style={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "20px",
                              textAlign: "center",
                              color: "#000000",
                            }}
                          >
                            I am a Charity or Not for Profit organisation
                            interested in using the Up 4 Change platform.
                          </span>
                        </Box>
                        <Box>
                          <FormControlLabel control={<Checkbox />} label="" />
                          <span
                            style={{
                              fontFamily: "Circular Std",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "20px",
                              textAlign: "center",
                              color: "#000000",
                            }}
                          >
                            I am looking to get involved making change!
                          </span>
                        </Box>
                        <Box marginTop={3} paddingLeft={10}>
                          <Button variant="contained" href={userRoutes?.logIn}>
                            submit
                          </Button>
                        </Box>
                      </FormGroup>
                    </Box>
                  </Form>
                </Formik>
              </Box>
            </Modal>
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
