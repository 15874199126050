import React, { Fragment, useState } from "react";

//package import

import {
  Box,
  Grid,
  Button,
  useTheme,
  Container,
  Typography,
} from "@mui/material";
import { PopupModal } from "react-calendly";
import styled, { keyframes } from "styled-components";
import { tada, bounce, swing, pulse } from "react-animations";

// svgs, routes
import {
  Authorised1,
  Authorised2,
  Authorised3,
  Authorised4,
} from "app/svg/user/AuthorisedOrg";
import { userRoutes } from "app/Services/user/routes/APP";

const AuthOrganisation = () => {
  const theme = useTheme();

  const tadaAnimation = keyframes`${tada}`;
  const TadaDiv = styled.div`
    animation: 1s ${tadaAnimation} infinite;
  `;
  const bounceAnimation = keyframes`${bounce}`;
  const BounceDiv = styled.div`
    animation: 1s ${bounceAnimation} infinite;
  `;
  const swingAnimation = keyframes`${swing}`;
  const SwingDiv = styled.div`
    animation: 1s ${swingAnimation} infinite;
  `;
  const pulseAnimation = keyframes`${pulse}`;
  const PulseDiv = styled.div`
    animation: 1s ${pulseAnimation} infinite;
  `;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <Box
        bgcolor={theme.palette.secondary.main}
        paddingY={3}
        sx={{
          backgroundImage: `url(/images/Ellipse_authorg.png)`,
          backgroundSize: "25%",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Box paddingBottom={4} marginTop={3}>
            <Typography align={"center"}>
              <Typography
                component={"span"}
                variant="h4"
                fontWeight={"bold"}
                color={"primary.light"}
              >
                ARE YOU A SCHOOL, CHURCH OR SPORTING/COMMUNITY CLUB?
              </Typography>
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                sx={{
                  border: `1px solid black`,
                  width: "40%",
                  marginTop: 3,
                }}
              ></Box>
            </Box>
          </Box>

          <Typography paddingBottom={3} color={"primary.light"}>
            Need a fun new way to raise funds for your authorised organisation?
          </Typography>
          <Typography paddingBottom={3} color={"primary.light"}>
            Up 4 Change provides a simple, fun way for your organisation to
            immediately connect with potential donors through an engaging
            digital experience, inspiring them to act on making change in real
            time
          </Typography>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.primary.light} paddingY={3}>
        <Container>
          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12} sm={12} md={2}>
              <Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <SwingDiv>
                    <Authorised1 />
                  </SwingDiv>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Typography
                  fontWeight={"bold"}
                  color={"white"}
                  variant="h6"
                  sx={{ marginTop: 3, paddingLeft: 3 }}
                >
                  A covid safe school fundraiser for your sister school?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <TadaDiv>
                    <Authorised2 />
                  </TadaDiv>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Typography
                  fontWeight={"bold"}
                  color={"white"}
                  variant="h6"
                  sx={{ marginTop: 3, paddingLeft: 3 }}
                >
                  An alternative to passing the donation plate in church and
                  manually collecting and counting coins?
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12} sm={12} md={2}>
              <Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <BounceDiv>
                    <Authorised3 />
                  </BounceDiv>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Typography
                  fontWeight={"bold"}
                  color={"white"}
                  variant="h6"
                  sx={{ marginTop: 3, paddingLeft: 3 }}
                >
                  A fundraiser for your sporting club or simply a means to raise
                  passive financial support post COVID lockdowns?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <PulseDiv>
                    <Authorised4 />
                  </PulseDiv>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Typography
                  fontWeight={"bold"}
                  color={"white"}
                  variant="h6"
                  sx={{ marginTop: 3, paddingLeft: 3 }}
                >
                  Through the use of QR codes real time donors can be connected
                  directly with causes, straight to a centralised social feed
                  that inspires them to act in the form of financial support.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        bgcolor={theme.palette.primary.main}
        paddingY={3}
        sx={{
          backgroundImage: `url(/images/ContactEllipse.png)`,
          backgroundSize: "25%",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Typography align={"center"}>
            <Typography
              component={"span"}
              variant="h6"
              fontWeight={"bold"}
              color="white"
            >
              Connect with and retain your donors long term!
            </Typography>
          </Typography>
          <Typography paddingTop={3} color="white" sx={{ textAlign: "center" }}>
            Reoccuring giving is so important to your organisation and Up 4
            Change can not only connect you with your donors, but it can
          </Typography>
          <Typography color="white" sx={{ textAlign: "center" }}>
            increase the length of the relationship.
          </Typography>
          <Box paddingBottom={4} marginTop={3}>
            <Typography align={"center"}>
              <Typography
                component={"span"}
                variant="h5"
                fontWeight={"bold"}
                color="white"
              >
                18 months is the average length of a reoccurring gift*
              </Typography>
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                sx={{
                  border: `1px solid white`,
                  width: "30%",
                  marginTop: 3,
                }}
              ></Box>
            </Box>
          </Box>

          <Typography
            paddingBottom={3}
            color="white"
            sx={{ textAlign: "center" }}
          >
            *Classy 2020 State of Modern Philanthropy
          </Typography>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.primary.light}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            To find out more Click Below!
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
            }}
            href={userRoutes?.contactUs}
          >
            CONTACT US
          </Button>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"white"}
            fontWeight={"bold"}
            sx={{ textAlign: "center", marginTop: 3 }}
          >
            To discuss your organisations unique requirements BOOK A CALL!
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          {/* <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom: 5,
            }}
          >
            BOOK A CALL!
          </Button> */}
          <Button
            variant="contained"
            sx={{
              boxShadow: theme.shadows[3],
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: 3,
              marginBottom: 5,
            }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            BOOK A CALL!
          </Button>
          <PopupModal
            url="https://calendly.com/up4change"
            onModalClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            rootElement={document.getElementById("root")}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default AuthOrganisation;
