import React from "react";

const Moto = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "534"}
      height={height ? height : "203"}
      viewBox="0 0 534 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_31)">
        <g clipPath="url(#clip1_2_31)">
          <ellipse
            cx="128.131"
            cy="147.167"
            rx="75.0341"
            ry="79.6157"
            fill="#FF9776"
          />
          <path
            d="M143.622 92.0308C146.936 92.0308 149.622 89.1711 149.622 85.6435C149.622 82.1159 146.936 79.2562 143.622 79.2562C140.308 79.2562 137.622 82.1159 137.622 85.6435C137.622 89.1711 140.308 92.0308 143.622 92.0308Z"
            fill="white"
          />
          <path
            d="M119.447 82.8664C121.012 84.4401 122.143 86.4766 122.578 88.976C122.838 90.6422 122.491 91.2902 120.925 91.2902C119.012 91.3828 117.36 91.4753 116.577 88.7908C115.969 86.384 113.881 85.2732 111.534 85.2732C109.186 85.2732 107.446 86.4766 106.403 88.7908C105.62 90.5496 100.75 93.697 100.403 89.9942C100.055 86.2915 103.968 81.7556 107.012 80.5522C111.186 78.7934 116.229 79.7191 119.447 82.8664Z"
            fill="white"
          />
          <path
            d="M158.285 108.638C158.285 121.365 144.887 131.683 128.36 131.683C111.833 131.683 98.4359 121.365 98.4359 108.638"
            stroke="white"
            strokeWidth="3.58272"
            strokeLinecap="round"
          />
        </g>
        <g clipPath="url(#clip2_2_31)">
          <ellipse
            cx="435.79"
            cy="110.455"
            rx="97.7901"
            ry="103.761"
            fill="#7551F1"
          />
          <path
            d="M465.711 65.9293C465.711 77.7242 453.295 87.2859 437.979 87.2859C422.663 87.2859 410.248 77.7242 410.248 65.9293"
            stroke="white"
            strokeWidth="7.75831"
            strokeLinecap="round"
          />
          <path
            d="M424.208 28.0116C426.242 30.0562 427.711 32.7023 428.276 35.9497C428.615 38.1146 428.163 38.9565 426.129 38.9565C423.643 39.0768 421.497 39.197 420.48 35.7091C419.689 32.582 416.977 31.1387 413.927 31.1387C410.876 31.1387 408.616 32.7023 407.26 35.7091C406.243 37.9943 399.916 42.0836 399.464 37.2727C399.012 32.4617 404.097 26.5683 408.051 25.0047C413.475 22.7195 420.028 23.9223 424.208 28.0116Z"
            fill="white"
          />
          <path
            d="M473.579 28.0116C475.613 30.0562 477.081 32.7023 477.646 35.9497C477.985 38.1146 477.533 38.9565 475.5 38.9565C473.014 39.0768 470.867 39.197 469.85 35.7091C469.059 32.582 466.348 31.1387 463.297 31.1387C460.246 31.1387 457.987 32.7023 456.631 35.7091C455.614 37.9943 449.287 42.0836 448.835 37.2727C448.383 32.4617 453.467 26.5683 457.422 25.0047C462.845 22.7195 469.398 23.9223 473.579 28.0116Z"
            fill="white"
          />
          <g clipPath="url(#clip3_2_31)">
            <ellipse
              cx="382.88"
              cy="166.162"
              rx="45.5796"
              ry="48.3626"
              fill="#FF9776"
            />
            <path
              d="M392.291 132.669C394.304 132.669 395.935 130.932 395.935 128.789C395.935 126.646 394.304 124.909 392.291 124.909C390.278 124.909 388.646 126.646 388.646 128.789C388.646 130.932 390.278 132.669 392.291 132.669Z"
              fill="white"
            />
            <path
              d="M377.604 127.102C378.555 128.058 379.242 129.295 379.506 130.813C379.664 131.826 379.453 132.219 378.502 132.219C377.34 132.275 376.336 132.332 375.861 130.701C375.491 129.239 374.224 128.564 372.797 128.564C371.371 128.564 370.315 129.295 369.681 130.701C369.205 131.769 366.247 133.681 366.036 131.432C365.824 129.183 368.202 126.427 370.05 125.696C372.586 124.628 375.65 125.19 377.604 127.102Z"
              fill="white"
            />
            <path
              d="M401.196 142.757C401.196 150.489 393.058 156.756 383.019 156.756C372.98 156.756 364.841 150.489 364.841 142.757"
              stroke="white"
              strokeWidth="2.17632"
              strokeLinecap="round"
            />
          </g>
        </g>
        <g clipPath="url(#clip4_2_31)">
          <ellipse
            cx="266.537"
            cy="146.541"
            rx="80.0908"
            ry="84.981"
            fill="#66C9D2"
          />
          <path
            d="M282.777 88.7723C286.303 88.7723 289.162 85.7293 289.162 81.9755C289.162 78.2217 286.303 75.1786 282.777 75.1786C279.25 75.1786 276.392 78.2217 276.392 81.9755C276.392 85.7293 279.25 88.7723 282.777 88.7723Z"
            fill="white"
          />
          <path
            d="M257.052 79.019C258.718 80.6936 259.921 82.8607 260.383 85.5204C260.661 87.2935 260.291 87.983 258.625 87.983C256.589 88.0815 254.831 88.18 253.998 85.3234C253.35 82.7622 251.13 81.5802 248.631 81.5802C246.133 81.5802 244.282 82.8607 243.171 85.3234C242.338 87.195 237.156 90.5441 236.786 86.6039C236.416 82.6637 240.58 77.837 243.819 76.5564C248.261 74.6848 253.628 75.6698 257.052 79.019Z"
            fill="white"
          />
          <path
            d="M284.918 123.814C284.918 132.117 277.448 139.416 267.501 139.416C257.555 139.416 250.085 132.117 250.085 123.814C250.085 115.511 257.555 108.212 267.501 108.212C277.448 108.212 284.918 115.511 284.918 123.814Z"
            stroke="white"
            strokeWidth="7.62493"
          />
        </g>
        <g clipPath="url(#clip5_2_31)">
          <ellipse
            cx="50.4132"
            cy="150.988"
            rx="50.4132"
            ry="53.4915"
            fill="#7551F1"
          />
          <path
            d="M65.8375 128.035C65.8375 134.116 59.4368 139.045 51.5412 139.045C43.6456 139.045 37.2449 134.116 37.2449 128.035"
            stroke="white"
            strokeWidth="3.9996"
            strokeLinecap="round"
          />
          <path
            d="M44.4423 108.487C45.4908 109.541 46.248 110.905 46.5392 112.58C46.7139 113.696 46.481 114.13 45.4325 114.13C44.1511 114.192 43.0444 114.254 42.5201 112.456C42.1124 110.843 40.7144 110.099 39.1418 110.099C37.5691 110.099 36.4041 110.905 35.7052 112.456C35.1809 113.634 31.9191 115.742 31.6861 113.262C31.4531 110.781 34.0742 107.743 36.1129 106.937C38.9088 105.759 42.2871 106.379 44.4423 108.487Z"
            fill="white"
          />
          <path
            d="M69.8939 108.487C70.9423 109.541 71.6995 110.905 71.9908 112.58C72.1655 113.696 71.9325 114.13 70.8841 114.13C69.6026 114.192 68.4959 114.254 67.9717 112.456C67.564 110.843 66.166 110.099 64.5933 110.099C63.0206 110.099 61.8557 110.905 61.1567 112.456C60.6325 113.634 57.3706 115.742 57.1376 113.262C56.9047 110.781 59.5258 107.743 61.5645 106.937C64.3603 105.759 67.7387 106.379 69.8939 108.487Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_31">
          <rect width="534" height="203" fill="white" />
        </clipPath>
        <clipPath id="clip1_2_31">
          <rect
            width="156.553"
            height="324.36"
            fill="white"
            transform="translate(46.6118 -15.9954)"
          />
        </clipPath>
        <clipPath id="clip2_2_31">
          <rect
            width="195.58"
            height="409"
            fill="white"
            transform="translate(338 -88)"
          />
        </clipPath>
        <clipPath id="clip3_2_31">
          <rect
            width="95.0981"
            height="197.033"
            fill="white"
            transform="translate(333.361 67.0485)"
          />
        </clipPath>
        <clipPath id="clip4_2_31">
          <rect
            width="160.182"
            height="334.974"
            fill="white"
            transform="translate(186.446 -15.9954)"
          />
        </clipPath>
        <clipPath id="clip5_2_31">
          <rect
            width="100.826"
            height="210.85"
            fill="white"
            transform="translate(0 48.6803)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Moto;
