import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
// import { COLORS } from "../../common/constants";
import { COLORS } from "app/common/user/constants";

const renderCustomizedLabel2 = (props, textColor = "#fff") => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  // const getName = () => {
  //   alert(payload.name)
  //   if(payload.name === 'Animals And Wildlife')
  //      return `<img src='/images/Connect1.png'/>`
  // }
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        strokeWidth={4}
        fill="none"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={textColor}
        style={{
          fontWeight: "bold",
        }}
      >
        {`${payload.name}`}
        {/* {getName()} */}
        {/* <div className="tf-hello" dangerouslySetInnerHTML={{__html:getName()}}></div> */}
      </text>
    </g>
  );
};

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={350} height={350}>
          <Pie
            data={this.props?.catsPercentage || []}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={(e) => renderCustomizedLabel2(e, this.props.textColor)}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            innerRadius={40}
          >
            {this?.props?.catsPercentage?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
// transform: rotate(90deg);
//     position: relative;
//     top: -120px;
//     height: 428px;
//     width: 700px;
