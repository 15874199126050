import React from 'react'

//package import

import {
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Button,
    Box,
    useTheme,
} from '@mui/material'
import {Close as CloseIcon, Edit as EditIcon} from '@mui/icons-material'

//component

const ViewEditModal = ({handleClose, open, handleConfirmOpen}) => {
    //hooks

    const theme = useTheme()

    //render

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle sx={{m: 0, p: 2}}>
                <Typography fontWeight={'bold'} align='center'>
                    View/Edit Round Up
                </Typography>
                <Box display={'flex'} justifyContent={'center'} paddingY={1}>
                    <Box
                        sx={{
                            borderBottom: `1px solid ${theme.palette.common.black}`,
                            width: '20%',
                        }}
                    ></Box>
                </Box>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box width={70} height={70}>
                        <img
                            src='/images/charityLogo.png'
                            alt='charity Logo'
                            width={'100%'}
                            height={'100%'}
                        />
                    </Box>
                </Box>
                <Typography fontWeight={'bold'} align='center' paddingY={1}>
                    Organisation Name
                </Typography>

                <Typography
                    align='center'
                    variant='caption'
                    component={'p'}
                    paddingY={1}
                    fontStyle={'italic'}
                    color={'primary.main'}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    Category
                </Typography>
                <Typography fontWeight={'bold'} align='center' paddingY={1}>
                    Wildlife
                </Typography>

                <Typography
                    align='center'
                    variant='caption'
                    component={'p'}
                    paddingY={1}
                    fontStyle={'italic'}
                    color={'primary.main'}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    Charity Name
                </Typography>
                <Typography fontWeight={'bold'} align='center' paddingY={1}>
                    Austrlian Wildlife Conservatory
                </Typography>

                <Typography
                    align='center'
                    variant='caption'
                    component={'p'}
                    paddingY={1}
                    fontStyle={'italic'}
                    color={'primary.main'}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    Payment Type
                </Typography>
                <Typography fontWeight={'bold'} align='center' paddingY={1}>
                    RoundUp
                </Typography>

                <Typography
                    align='center'
                    variant='caption'
                    component={'p'}
                    paddingY={1}
                    fontStyle={'italic'}
                    color={'primary.main'}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    RoundUp Amount
                </Typography>
                <Box display={'flex'} justifyContent={'center'}>
                    <Typography fontWeight={'bold'} align='center' paddingY={1}>
                        $450
                    </Typography>
                    <Box width={20}>
                        <EditIcon />
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'row'}
                >
                    <Box padding={1}>
                        <Button
                            variant='contained'
                            sx={{
                                fontSize: 12,
                                paddingX: 2,
                            }}
                            onClick={handleConfirmOpen}
                        >
                            Cancel Round Up
                        </Button>
                    </Box>
                    <Box padding={1}>
                        <Button
                            variant='contained'
                            sx={{
                                fontSize: 12,
                                paddingX: 2,
                            }}
                            onClick={handleConfirmOpen}
                        >
                            SNOOZE ROUND UP’S for 30 days?
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ViewEditModal
