import React from "react";

const FunnelIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4985 0H1.50174C0.169368 0 -0.502944 1.61662 0.441118 2.56069L12 14.1213V27C12 27.4894 12.2388 27.9481 12.6398 28.2289L17.6398 31.7276C18.6262 32.4181 20 31.7183 20 30.4987V14.1213L31.5592 2.56069C32.5013 1.6185 31.8336 0 30.4985 0Z"
        fill="#C5C5C5"
      />
    </svg>
  );
};

export default FunnelIcon;
