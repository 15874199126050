import React, { useContext } from "react";

//package imports

import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Avatar } from "@mui/material";
import { Nav, NavDropdown, Dropdown } from "react-bootstrap";

//user imports

// import { APP_URLS} from '../Routes/APP'
// import { context } from "../context";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { context } from "app/context";
import "./global.css";

//component

export default function Navbar() {
  //hooks

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { orgDetails, setOrgDetails } = useContext(context);
  const { name, organisation_image } = orgDetails || {};

  //logout handler

  const logoutHandler = () => {
    localStorage.clear();
    navigate(APP_URLS?.root);
  };

  //render

  return (
    <nav className="navbar navbar-expand-lg sticky-top" id="Navbar">
      <a className="navbar-brand" href={APP_URLS?.org}>
        <img src="/images/logo.png" alt="logo" className="logo" />
        <div className="circle"></div>
      </a>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto left">
          <li
            className={
              pathname === APP_URLS?.org + APP_URLS?.root
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link to={APP_URLS?.org + APP_URLS?.root} className="nav-link">
              DASHBOARD
            </Link>
          </li>
          <li
            className={
              pathname === APP_URLS?.org + APP_URLS?.feed
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link to={APP_URLS?.org + APP_URLS?.feed} className="nav-link">
              FEED
            </Link>
          </li>
          <li
            className={
              pathname === APP_URLS?.org + APP_URLS?.cause
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link to={APP_URLS?.org + APP_URLS?.cause} className="nav-link">
              CAUSES
            </Link>
          </li>
        </ul>
      </div>

      <Nav className="ml-auto navbar-nav right org_NavDropdown__main">
        <NavDropdown title={<FaBars />} id="navbarDropdown" alignRight>
          <div className="hide-data">
            <ul className="small-menu">
              <li>
                <Link to={APP_URLS?.org + APP_URLS?.root} className="links">
                  <p>DASHBOARD</p>
                </Link>
              </li>
              <li>
                <Link to={APP_URLS?.org + APP_URLS?.feed} className="links">
                  FEED
                </Link>
              </li>
              <li>
                <Link to={APP_URLS?.org + APP_URLS?.cause} className="links">
                  CAUSES
                </Link>
              </li>
            </ul>
            <Dropdown.Divider />
          </div>
          <div className="profile px-3">
            <p>{name}</p>
            <div className="profile-img">
              {organisation_image?.length > 0 ? (
                <img
                  src={organisation_image[0]}
                  alt="organisation-logo"
                  className="profile-logo-div mx-auto d-block"
                />
              ) : (
                <Avatar>{name?.slice(0, 1)}</Avatar>
              )}
            </div>
          </div>
          <Dropdown.Divider />
          <div className="organisation organisation__menu_list">
            <ul>
              <li>
                <img src="/images/settings.svg" alt="settings" />
                <Link to={APP_URLS?.org + APP_URLS?.profile}>
                  <p className="pl-3">Settings</p>
                </Link>
              </li>
              <li>
                <img src="/images/payment.svg" alt="payment" />
                <Link to={APP_URLS?.org + APP_URLS?.payment}>
                  <p className="pl-3">Payment</p>
                </Link>
              </li>
              <li>
                <img src="/images/history.svg" alt="transaction" />
                <Link to={APP_URLS?.org + APP_URLS?.transaction}>
                  <p className="pl-3">Transaction History</p>
                </Link>
              </li>
              <li onClick={logoutHandler}>
                <img src="/images/logout.svg" alt="logout" />
                <p className="pl-3">Log Out</p>
              </li>
            </ul>
          </div>
        </NavDropdown>
      </Nav>
      {/* <ul className="navbar-nav ml-auto right">
        <li className="nav-item dropdown">
          <a
            className="nav-link"
            href="#/"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FaBars />
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <div className="hide-data">
              <ul className="small-menu">
                <li>
                  <Link to={APP_URLS?.org + APP_URLS?.root} className="links">
                    <p>DASHBOARD</p>
                  </Link>
                </li>
                <li>
                  <Link to={APP_URLS?.org + APP_URLS?.feed} className="links">
                    FEED
                  </Link>
                </li>
                <li>
                  <Link to={APP_URLS?.org + APP_URLS?.cause} className="links">
                    CAUSES
                  </Link>
                </li>
              </ul>
              <div className="dropdown-divider"></div>
            </div>
            <div className="profile px-3">
              <p>{name}</p>
              <div className="profile-img">
                {organisation_image?.length > 0 ? (
                  <img
                    src={organisation_image[0]}
                    alt="organisation-logo"
                    className="profile-logo-div mx-auto d-block"
                  />
                ) : (
                  <Avatar>{name?.slice(0, 1)}</Avatar>
                )}
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <div className="organisation">
              <ul>
                <li>
                  <img src="/images/settings.svg" alt="settings" />
                  <Link to={APP_URLS?.org + APP_URLS?.profile}>
                    <p className="pl-3">Settings</p>
                  </Link>
                </li>
                <li>
                  <img src="/images/payment.svg" alt="payment" />
                  <Link to={APP_URLS?.org + APP_URLS?.payment}>
                    <p className="pl-3">Payment</p>
                  </Link>
                </li>
                <li>
                  <img src="/images/history.svg" alt="transaction" />
                  <Link to={APP_URLS?.org + APP_URLS?.transaction}>
                    <p className="pl-3">Transaction History</p>
                  </Link>
                </li>
                <li onClick={logoutHandler}>
                  <img src="/images/logout.svg" alt="logout" />
                  <p className="pl-3">Log Out</p>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul> */}
    </nav>
  );
}
