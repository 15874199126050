import React from 'react';

//package imports

import PropTypes from 'prop-types';
import { Drawer, useTheme, Box } from '@mui/material';

//user imports

import SidebarNav from './components';

//component

const Sidebar = (props) => {
  const {
    open,
    variant,
    onClose,
    acornOpen,
    handleToggle,
    handleListKeyDown,
    anchorRef,
    handleClose,
    acornOpen1,
    handleToggle1,
    handleListKeyDown1,
    anchorRef1,
    handleClose1,
    signUpHanlder,
    ...rest
  } = props;

  //hooks

  const theme = useTheme();

  return (
    <Drawer
      anchor='left'
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          backgroundColor: theme.palette.common.black,
          maxWidth: { xs: '100%', sm: 400 },
        },
      }}
    >
      <Box
        {...rest}
        sx={{
          height: '100%',
          padding: 1,
        }}
      >
        <SidebarNav
          onClose={onClose}
          signUpHanlder={signUpHanlder}
          open={acornOpen}
          anchorRef1={anchorRef1}
          open1={acornOpen1}
          handleToggle={handleToggle}
          handleClose={handleClose}
          handleListKeyDown={handleListKeyDown}
          handleToggle1={handleToggle1}
          handleClose1={handleClose1}
          handleListKeyDown1={handleListKeyDown1}
        />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,

  signUpHanlder: PropTypes.func.isRequired,
};

export default Sidebar;
