import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

//package import
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  Pagination,
  Tooltip,
  Modal,
} from "@mui/material";
import { buttonClasses } from "@mui/material/Button";
import { paginationItemClasses } from "@mui/material/PaginationItem";

//user import

import {
  formatCategory,
  formatDate,
  formatDateTimeOnly,
} from "app/common/user/functions";

import { headersGet, headersPost, headersUpdate } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import { DownloadIcon, PrintIcon } from "app/svg/user/DonationHistory";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { border } from "@mui/system";
import { Navigate } from "react-router-dom";
import { getFirstDayOfNextMonth } from "app/utils/user/functions";
import { context } from "app/context";

//constants

const headings = [
  "Date",
  "Organisation",
  "Category",
  "Charity Name",
  "Type",
  "Donation Amount",
  "Action",
];
//component

function Index() {
  const theme = useTheme();
  const { user } = useContext(context);
  const { _id } = user || {};

  //states

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [alertIcon, setAlertIcon] = useState("error");
  const [currentDay, setCurrentDay] = useState("last_month");
  const [roundup, setRoundup] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelSubId, setCancelSubId] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    height: 570,
    bgcolor: "#fff",
    borderRadius: "10px",
    p: "8px 20px 32px 32px",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: {xs: "340px" , sm: "340px", md:"600" , lg: "600"},
    // height: {xs: "200" , sm: "200", md:"190" , lg: "190"},
    width: { xs: 300, sm: 300, md: 600, lg: 600 },
    height: { xs: 220, sm: 220, md: 240, lg: 240 },
    bgcolor: "#fff",
    borderRadius: "10px",
    p: "8px 20px 32px 32px",
  };
  const getHistory = useCallback(() => {
    // const data = `page=${page}&limit=${10}&user=${_id}&currentDay=${currentDay}&type=subscription`;
    const data = `page=${page}&limit=${10}&user=${_id}&type=subscription`;

    headersGet(`${API_URLS?.userTransactions}?${data}`)
      .then((res) => {
        const { data, status } = res;
        setOpen(false);
        setIsloading(false);
        if (status === 200) {
          const { results, totalPages: pages } = data || {};
          setTotalPages(pages);
          setData(results?.reverse());
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  }, [page, _id, currentDay]);

  useEffect(() => {
    _id && getHistory();
  }, [_id, getHistory]);

  const [open, setOpen1] = useState(false);
  const handleClose = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [open1, setOpen2] = useState(false);
  const handleClose1 = () => setOpen2(false);
  const handleOpen1 = () => setOpen2(true);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //page change handler

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const matches = useMediaQuery("(min-width:900px)");
  const matches1 = useMediaQuery("(max-width:899px)");

  const handleOpenCancelModal = (subId) => {
    setOpenCancelModal(true);
    setCancelSubId(subId);
  };
  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
    setCancelSubId(null);
  };

  const cancelSubscription = async () => {
    if (cancelSubId) {
      headersPost(`${API_URLS?.cancelSubscription}`, {
        subscription: cancelSubId,
      })
        .then((res) => {
          const { data, status } = res;
          setOpen(false);
          if (status === 200) {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("success");
            setOpenCancelModal(false);
            getHistory();
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    }
  };

  const activeSubscriptions =
    data &&
    data?.filter(
      (row) => row?.subscription?.isActive && row?.type === "subscription"
    );

  return (
    <Box
      bgcolor={theme.palette.info.light}
      // padding={2}
      paddingTop={{ xs: "30px", sm: "30px", md: "80px", lg: "80px" }}
      // paddingTop={10}
      minHeight={"100vh"}
    >
      {matches && (
        <Container>
          <Paper
            sx={{
              padding: 2,
              background: "#FF9776",
              color: "#fff",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              ACTIVE SUBSCRIPTIONS
            </Box>
          </Paper>
          <Paper
            sx={{
              padding: 2,
            }}
          >
            <TableContainer sx={{ marginY: 2 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headings?.map((head, index) => (
                      <TableCell
                        key={`${index}-table-heading-${head}`}
                        align={"center"}
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {head}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {/* <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow> */}
                  {!isLoading && activeSubscriptions?.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No active subscriptions
                      </TableCell>
                    </TableRow>
                  )}
                  {activeSubscriptions &&
                    activeSubscriptions?.map((row, index) => {
                      const { amount, organisation, createdAt, pp_fees, type } =
                        row || {};
                      const { category, company_name, name } = organisation;
                      return (
                        <TableRow key={`${index}-${row?.date}`}>
                          <TableCell align="center">
                            {formatDate(createdAt)}
                            <br></br>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#9094a2",
                              }}
                            >
                              {formatDateTimeOnly(createdAt)}
                            </span>
                          </TableCell>
                          <TableCell align="center">{name}</TableCell>
                          <TableCell align="center">
                            {formatCategory(category)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.cause?.title}
                          </TableCell>
                          <TableCell align="center">
                            {formatCategory(type)}
                          </TableCell>
                          <TableCell align="center">
                            ${amount} + ${pp_fees ? pp_fees : 0} = $
                            {amount + (pp_fees ? pp_fees : 0)}{" "}
                          </TableCell>

                          <TableCell align="center">
                            <span
                              onClick={() =>
                                handleOpenCancelModal(
                                  row?.subscription?.subscriptionId
                                )
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row?.subscription?.isActive ? (
                                "Cancel "
                              ) : (
                                <span
                                  style={{
                                    background: "red",
                                    color: "#fff",
                                  }}
                                >
                                  Canceled
                                </span>
                              )}
                            </span>
                          </TableCell>

                          {/* <TableCell align='center'>
                                                ${pp_fees ? pp_fees : 0} + $
                                                {amount +
                                                    (pp_fees
                                                        ? pp_fees
                                                        : 0)}{' '}
                                                = ${totalAmount?.toFixed(2)}
                                            </TableCell> */}
                          {/* <TableCell align='center'>
                                                $
                                                {amount +
                                                    (pp_fees ? pp_fees : 0)}
                                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {isLoading && (
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton count={1} height="30px" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                                        colSpan={5}
                                        align='right'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ${totalAmount?.toFixed(2)}
                                    </TableCell> */}
                  </TableRow>

                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell
                    colSpan={4}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    This Month
                  </TableCell> */}
                    {/* <TableCell
                    colSpan={5}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    ${totalAmount}
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {openCancelModal && (
              <Modal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <Box textAlign={"right"}>
                    <img
                      src="/images/closepopup.svg"
                      width={"12px"}
                      onClick={handleCloseCancelModal}
                      alt={"close"}
                    />
                  </Box>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    sx={{
                      fontFamily: "Circular Std",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      paddingTop: "20px",
                    }}
                  >
                    Are you sure, you want to cancel this subscription?
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-evenly"}
                    paddingTop={"40px"}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        onClick={cancelSubscription}
                        sx={{
                          padding: {
                            xs: "10px 20px",
                            sm: "10px 20px",
                            md: "10px 50px",
                            lg: "10px 50px",
                          },
                        }}
                      >
                        Confirm
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        onClick={handleCloseCancelModal}
                        sx={{
                          padding: {
                            xs: "10px 20px",
                            sm: "10px 20px",
                            md: "10px 50px",
                            lg: "10px 50px",
                          },
                        }}
                      >
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </Paper>
          {/* <Box paddingY={2} display={'flex'} justifyContent={'center'}>
                    <Pagination
                        count={totalPages}
                        color='primary'
                        page={page}
                        variant='contained'
                        sx={{
                            [`& .${paginationItemClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                                ':hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },
                            [`& .${buttonClasses.root}`]: {
                                backgroundColor: theme.palette.common.white,
                            },
                        }}
                        onChange={handleChangePage}
                    />
                </Box> */}
        </Container>
      )}
      {matches1 && (
        <Box
          bgcolor={theme.palette.info.light}
          padding={0}
          paddingTop={10}
          minHeight={"100vh"}
        >
          <Container style={{ width: "368px", padding: "0px" }}>
            <Paper
              sx={{
                padding: 2,
                background: "#FF9776",
                color: "#fff",
                borderRadius: "0px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                ACTIVE SUBSCRIPTIONS
              </Box>
            </Paper>
            <Paper
              sx={{
                padding: 2,
                borderRadius: "0px",
              }}
            >
              {data?.map((row, index) => {
                const { amount, organisation, createdAt, pp_fees, type } =
                  row || {};
                const { category, company_name, name, organisation_image } =
                  organisation;
                return (
                  <ul
                    style={{
                      listStyleType: "none",
                      borderBottom: "1px solid #c4c4c4",
                      paddingInlineStart: "0px",
                    }}
                  >
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            marginRight: "13px",
                          }}
                        >
                          <img
                            src={organisation_image}
                            style={{
                              width: "44px",
                              height: "44px",
                              border: "1px solid #ff9776",
                              borderRadius: "50%",
                            }}
                          ></img>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Circular Std",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#666666",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              color: "#000",
                              fontWeight: "700",
                            }}
                          >
                            {name}
                          </div>
                          <div>{formatCategory(category)}</div>
                          <div>{formatDate(createdAt)}</div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          fontFamily: "Circular Std",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#666666",
                          // marginLeft: "15px",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          ${amount} + ${pp_fees ? pp_fees : 0} = $
                          {amount + (pp_fees ? pp_fees : 0)}{" "}
                        </div>
                        {/* <div>{row?.cause?.title}</div> */}
                        <div> {formatCategory(type)}</div>
                        <div>
                          <span
                            onClick={() =>
                              handleOpenCancelModal(
                                row?.subscription?.subscriptionId
                              )
                            }
                            style={{
                              cursor: "pointer",
                              color: "#ff9776",
                              fontWeight: "700",
                            }}
                          >
                            {row?.subscription?.isActive ? (
                              "Cancel"
                            ) : (
                              <span
                                style={{
                                  background: "red",
                                  color: "#fff",
                                }}
                              >
                                Canceled
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                );
              })}
            </Paper>
          </Container>
        </Box>
      )}
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Index;
