import { Dialog } from '@mui/material';
import React, { useEffect } from 'react';
// import $ from 'jquery'

const BasiqModal = ({ open = false, closeHandler = () => {}, token = '' }) => {
  window.location.href = `https://consent.basiq.io/home?token=${token}`;

  // useEffect(() => {
  //     setTimeout(() => {
  //         $('#basiq-control').load(
  //             `https://consent.basiq.io/home?token=${token}`
  //         )
  //     }, 2000)
  // }, [token, open])

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth={'sm'} fullWidth>
      {/* <div id="basiq-control"></div> */}
      {/* <object
        type="text/html"
        data={`https://consent.basiq.io/home?token=${token}`}
        width="800px"
        height="600px"
      /> */}
      {/* <embed
        src={`https://consent.basiq.io/home?token=${token}`}
        width={'100%'}
        height={'500'}
        style={{ overflowY: 'auto' }}
      /> */}

      {/* <iframe
        width={'100%'}
        height={'500'}
        title="basiq-consent-link"
        style={{ overflowY: 'auto' }}
        src={`https://consent.basiq.io/home?token=${token}`}
      /> */}
    </Dialog>
  );
};

export default BasiqModal;
