import React, { useState, useEffect, useCallback, useContext } from "react";

//package import

import { Route, Routes, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";

//user imports

import { OrgRoutes as admin_routes } from "app/routes/organization/OrgRoutes";

// import { API_URLS } from "./Routes/API";
import { API_URLS } from "app/Services/organization/Routes/API";
// import { simpleGet } from "./Services/API";
import { simpleGet } from "app/Services/organization/API";
import { StorageKeys } from "app/Services/organization/StorageKeys";
// import { APP_URLS } from "./Routes/APP";
import { ORGANIZATION_APP_URLS as APP_URLS } from "app/Services/organization/Routes/APP";
import { context } from "app/context";

//component

const OrgPrivateRoutePaths = () => {
  //constants
  const { setOrgDetails } = useContext(context);
  //states

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");

  const navigate = useNavigate();

  //get org details

  const getOrgDetails = (id) => {
    setOpen(true);

    simpleGet(`${API_URLS?.getOrgDetails}?organisationId=${id}`)
      .then((res) => {
        if (res?.status === 200) {
          setOrgDetails(res?.data?.organisationDetails);
          setOpen(false);

          console.log({
            isBank: res?.data?.organisationDetails?.isBank,
          });
          if (res?.data?.organisationDetails?.isBank === false) {
            console.log("IF");

            navigate(APP_URLS?.org + APP_URLS?.payment);
          } else {
            console.log("ELSE");
            // navigate(APP_URLS?.org);
            navigate(APP_URLS?.org + APP_URLS?.payment);
          }
        } else {
          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //ger decode token

  const getDecodedToken = useCallback(() => {
    const token = localStorage.getItem(StorageKeys?.token);
    const decode = decodeToken(token);
    getOrgDetails(decode?.sub);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(StorageKeys?.token);
    if (token) {
      getDecodedToken();
    }
  }, [getDecodedToken]);

  //alert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  //render

  return (
    <>
      <Routes>
        {admin_routes?.map((item, index) => (
          <Route
            path={item?.path}
            key={`${index}-admin-routes-${item?.id}`}
            caseSensitive={item?.exact}
            element={item?.element}
          />
        ))}
      </Routes>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrgPrivateRoutePaths;
