import React from "react";

//package import

import { Route, Routes } from "react-router-dom";

//user import

import Navbar from "app/components/admin/Navbar";
import UserManagement from "app/containers/admin/UserManagement/UserManagement";
import OrganisationManagement from "app/containers/admin/OrganisationManagement/OrganisationManagement";
import ContentManagement from "app/containers/admin/ContentManagement/ContentManagement";
import OrganisationBilling from "app/containers/admin/OrganisationManagement/OrganisationBilling";
import Donation from "app/containers/admin/Donation/Donation";
import { ADMIN_URLS } from "app/Services/admin/Routes/APP";

//component

function AdminRoutes() {
  //render

  return (
    <>
      <Navbar />

      <Routes>
        <Route
          path={ADMIN_URLS?.ROOT}
          caseSensitive
          element={<UserManagement />}
        />
        <Route
          path={ADMIN_URLS?.USER_MANAGEMENT}
          caseSensitive
          element={<UserManagement />}
        />
        <Route
          path={ADMIN_URLS?.ORG_MANAGEMENT}
          element={<OrganisationManagement />}
        />
        <Route
          path={ADMIN_URLS?.CONTENT_MANAGEMENT}
          element={<ContentManagement />}
        />
        <Route path={ADMIN_URLS?.DONATION} element={<Donation />} />
        <Route
          path={ADMIN_URLS?.ORG_BILLING_WITH_ID}
          element={<OrganisationBilling />}
        />
      </Routes>
    </>
  );
}

export default AdminRoutes;
