import React, { useContext, useState, useEffect, createRef } from "react";

//package import

import {
  useTheme,
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  Modal,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";

//user import
import { Close as CloseIcon } from "@mui/icons-material";
import { MotoIcon, EditIcon } from "app/svg/user/Profile";
// import { context } from "../../context";

import { headersUpdate, headersPost, simplePost } from "app/Services/user/API";
import { API_URLS } from "app/Services/user/routes/API";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { createObjectURL, base64StringToBlob } from "blob-util";
import "./ProfileEdit.css";
import { useNavigate } from "react-router-dom";
import { userRoutes } from "app/Services/user/routes/APP";
import { context } from "app/context";

//constants
const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const formInitialValues = {
  name: "",
  email: "",
  phone_number: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "600px",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: "8px 20px 32px 32px",
};

//component

const Profile = () => {
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const handleOpen = () => setOpen1(true);

  const [image, setImage] = useState();
  let [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();

  const navigate = useNavigate();

  const clickHandler = () => {
    inputRef?.current?.click();
    console.log("h");
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
      setOpen1(true);
    } else if (e.target) {
      setOpen1(true);
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      fileHandlerBase64(cropper.getCroppedCanvas().toDataURL());
    }
  };

  console.log({ cropData });

  //     const contentType = "image/png";
  //     let cropData1 = cropData.replace("data:image/png;base64,","")
  // const b64Data = cropData1;

  // const blob = base64StringToBlob(b64Data, contentType);
  // console.log(blob);

  // var myUrl = createObjectURL(blob);
  // console.log({myUrl});

  //hooks

  const theme = useTheme();
  const { user } = useContext(context);
  const { profile_pic, name, _id, google, facebook } = user || {};
  const inputRef = createRef(null);

  //states

  const [formData, setFormData] = useState(formInitialValues);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertIcon, setAlertIcon] = useState("error");
  const [imageUrl, setImageUrl] = useState("");

  //life cycle hook

  useEffect(() => {
    user?._id && setFormData((prev) => ({ ...prev, ...user }));

    return () => {
      setFormData(formInitialValues);
    };
  }, [user]);

  //form submit handler

  const submitHandler1 = (values) => {
    console.log({ values });
    const data = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    };

    if (values?.newPassword === values?.confirmPassword) {
      setOpen(true);
      simplePost(`${API_URLS?.changePassword}?userId=${values?._id}`, data)
        .then((res) => {
          setOpen(false);
          if (res?.status === 200) {
            setFormData(res?.data);
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("success");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            navigate(userRoutes?.root);
          } else {
            setAlertOpen(true);
            setAlertMsg(res?.data?.message);
            setAlertIcon("warning");
          }
        })
        .catch((err) => {
          const { response } = err;

          setOpen(false);
          setAlertOpen(true);
          setAlertMsg(response?.data?.message);
          setAlertIcon("error");
        });
    } else {
      setAlertOpen(true);
      setAlertMsg("New and confirm passwords don't match");
      setAlertIcon("warning");
    }
  };

  const submitHandler = (values) => {
    const data = {
      name: values?.name,
      phone_number: values?.phone_number,
    };
    setOpen(true);

    headersUpdate(`${API_URLS?.getUserDetail}/${values?._id}`, data)
      .then((res) => {
        setOpen(false);
        if (res?.status === 200) {
          setFormData(res?.data);
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;
        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });
  };

  //aleert close handler

  const alertCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // const fileHandler = event => {

  // const {
  //     currentTarget: {files},
  // } = event
  // if (files?.length > 0 && _id) {

  //     const formData = new FormData()
  //     formData.append('profile_pic', files[0])
  //     console.log("file",files[0]);
  //     setImageUrl(URL.createObjectURL(files[0]))

  //     console.log({formData:  formData.get('profile_pic')});

  // setOpen(true)
  // headersPost(`${API_URLS?.updateUserPic}?userId=${_id}`, formData)
  //     .then(res => {
  //         setOpen(false)
  //         if (res?.status === 200) {
  //             // orgDetails.organisation_image = res?.data?.org?.organisation_image
  //         } else {
  //             setAlertOpen(true)
  //             setAlertMsg(res?.data?.message)
  //             setAlertIcon('warning')
  //         }
  //     })
  //     .catch(err => {
  //         const {response} = err

  //         setOpen(false)
  //         setAlertOpen(true)
  //         setAlertMsg(response?.data?.message)
  //         setAlertIcon('error')
  //     })
  // }
  // }

  const fileHandlerBase64 = (base64) => {
    const data = {
      profile_pic: base64,
    };

    setOpen(true);
    headersPost(`${API_URLS?.updateUserPicBase64}?userId=${_id}`, data)
      .then((res) => {
        setOpen(false);
        console.log({ Res: res });
        if (res?.status === 200) {
          setImageUrl(cropData);

          window.location.reload();
        } else {
          setAlertOpen(true);
          setAlertMsg(res?.data?.message);
          setAlertIcon("warning");
        }
      })
      .catch((err) => {
        const { response } = err;

        setOpen(false);
        setAlertOpen(true);
        setAlertMsg(response?.data?.message);
        setAlertIcon("error");
      });

    setOpen1(false);
  };

  console.log({ imageUrl });

  //render

  return (
    <Box bgcolor={theme.palette.info.light}>
      <Box paddingY={3}>
        <Container maxWidth={"md"}>
          <Typography color={"text.red"}>My Account - Profile</Typography>
          <Box marginY={2}>
            <Box
              bgcolor={theme.palette.secondary.main}
              padding={theme.spacing(2, 2, 0, 2)}
            >
              <Container>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography variant="h4" color={"black"}>
                        Rounds-ups
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight={"bold"}
                        color={"primary.light"}
                      >
                        {formData?.recent_round_up}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <MotoIcon width={"100%"} height={100} />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box bgcolor={theme.palette.common.white} padding={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                {profile_pic ? (
                  <Box borderRadius={"100%"} border={"3px solid #ff9776"}>
                    <Box
                      width={100}
                      height={"auto"}
                      borderRadius={"100%"}
                      component={"img"}
                      src={imageUrl ? imageUrl : profile_pic}
                      // src={
                      //     imageUrl
                      //         ? imageUrl
                      //         : profile_pic
                      // }
                      alt={name}
                    ></Box>
                  </Box>
                ) : (
                  <Avatar
                    sx={{
                      width: 120,
                      height: 120,
                      fontSize: 50,
                      textTransform: "uppercase",
                    }}
                  >
                    {name?.slice(0, 1)}
                  </Avatar>
                )}
                <Box my={2}>
                  {/* <Button
                                        variant='contained'
                                        onClick={clickHandler} 
                                    >
                                        Change Photo
                                    </Button> */}
                  {/* <input type="file" onChange={onChange} /> */}
                  <label for="file-upload" class="custom-file-upload">
                    <i class="fa fa-cloud-upload"></i> Change photo
                  </label>
                  {/* <input id="file-upload" type="file"/> */}
                  <Box
                    component={"input"}
                    display={"none"}
                    id={"file-upload"}
                    accept="image/png"
                    type={"file"}
                    name={"org-profile"}
                    onChange={onChange}
                  />
                </Box>

                {/* <Box
                                    component={'input'}
                                    display={'none'}
                                    ref={inputRef}
                                    accept='image/png, image/jpeg, image/jpg'
                                    type={'file'}
                                    name={'org-profile'}
                                    onChange={e => fileHandler(e)}
                                /> */}
              </Box>

              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <Box textAlign={"right"}>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose1}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "320px",
                        paddingTop: "40px",
                      }}
                    >
                      <Cropper
                        style={{ height: 440, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                    </div>
                    <div>
                      {/* <div className="box" style={{ width: "50%", paddingTop:"60px", paddingRight:"50px", float: "right" }}>
          <h1>Preview</h1>
          <div
            className="img-preview" 
            style={{ width: "100%", float: "left", height: "200px" , overflow: "hidden"}}
          />
        </div> */}
                      <div
                      //   className="box"
                      //   style={{ width: "50%", float: "right", height: "300px" }}
                      >
                        <div
                          style={{ paddingTop: "200px", marginLeft: "210px" }}
                        >
                          {/* <span>Crop</span> */}
                          <span
                            onClick={getCropData}
                            class="custom-file-upload1"
                          >
                            Upload
                          </span>
                        </div>
                        {/* <img style={{ width: "100%" }} src={cropData} alt="cropped" /> */}
                      </div>
                    </div>
                    <br style={{ clear: "both" }} />
                  </div>
                </Box>
              </Modal>
              <Container>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h4">
                    <Typography
                      variant="h4"
                      component={"span"}
                      fontWeight={"bold"}
                      borderBottom={`3px solid black`}
                    >
                      Account
                    </Typography>
                  </Typography>
                  <Box paddingLeft={1}>
                    <EditIcon />
                  </Box>
                </Box>

                <Box marginY={2}>
                  <Formik
                    initialValues={formData}
                    onSubmit={submitHandler}
                    // validationSchema={addvalidation}
                    enableReinitialize
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            padding={1}
                            sx={{
                              display: "flex",
                              alignItems: {
                                md: "center",
                              },
                              justifyContent: {
                                md: "flex-end",
                                sm: "flex-start",
                                xs: "flex-start",
                              },
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              sx={{
                                textTransform: "uppercase",
                                textAlign: {
                                  md: "right",
                                  sm: "left",
                                  xs: "left",
                                },
                              }}
                            >
                              full Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} padding={1}>
                            <TextField
                              placeholder="John Doe"
                              fullWidth
                              name="name"
                              value={values?.name}
                              onChange={handleChange}
                              helperText={touched.name && errors.name}
                              error={touched.name && Boolean(errors.name)}
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            padding={1}
                            sx={{
                              display: "flex",
                              alignItems: {
                                md: "center",
                              },
                              justifyContent: {
                                md: "flex-end",
                                sm: "flex-start",
                                xs: "flex-start",
                              },
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              sx={{
                                textTransform: "uppercase",
                                textAlign: {
                                  md: "right",
                                  sm: "left",
                                  xs: "left",
                                },
                              }}
                            >
                              email
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} padding={1}>
                            <TextField
                              placeholder="johndoe@gmail.com"
                              fullWidth
                              name="email"
                              disabled
                              value={values?.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={touched.email && Boolean(errors.email)}
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            padding={1}
                            sx={{
                              display: "flex",
                              alignItems: {
                                md: "center",
                              },
                              justifyContent: {
                                md: "flex-end",
                                sm: "flex-start",
                                xs: "flex-start",
                              },
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              sx={{
                                textTransform: "uppercase",
                                textAlign: {
                                  md: "right",
                                  sm: "left",
                                  xs: "left",
                                },
                              }}
                            >
                              phone no
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} padding={1}>
                            <TextField
                              placeholder="+92 | 000000000000"
                              fullWidth
                              name="phone_number"
                              value={values?.phone_number}
                              onChange={handleChange}
                              helperText={
                                touched.phone_number && errors.phone_number
                              }
                              error={
                                touched.phone_number &&
                                Boolean(errors.phone_number)
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={false} sm={false} md={4} />
                          <Grid item xs={12} sm={12} md={6} padding={1}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <Button
                                variant="contained"
                                sx={{
                                  fontWeight: "bold",
                                }}
                                type={"submit"}
                              >
                                SAVE CHANGES
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                  {google || facebook ? null : (
                    <Formik
                      initialValues={formData}
                      onSubmit={submitHandler1}
                      enableReinitialize
                    >
                      {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              padding={1}
                              sx={{
                                display: "flex",
                                alignItems: {
                                  md: "center",
                                },
                                justifyContent: {
                                  md: "flex-end",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                },
                              }}
                            >
                              <Typography
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  textAlign: {
                                    md: "right",
                                    sm: "left",
                                    xs: "left",
                                  },
                                }}
                              >
                                old password
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} padding={1}>
                              <TextField
                                placeholder="********"
                                fullWidth
                                name="oldPassword"
                                value={values?.oldPassword}
                                onChange={handleChange}
                                helperText={
                                  touched.oldPassword && errors.oldPassword
                                }
                                error={
                                  touched.oldPassword &&
                                  Boolean(errors.oldPassword)
                                }
                              ></TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              padding={1}
                              sx={{
                                display: "flex",
                                alignItems: {
                                  md: "center",
                                },
                                justifyContent: {
                                  md: "flex-end",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                },
                              }}
                            >
                              <Typography
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  textAlign: {
                                    md: "right",
                                    sm: "left",
                                    xs: "left",
                                  },
                                }}
                              >
                                new password
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} padding={1}>
                              <TextField
                                placeholder="********"
                                fullWidth
                                name="newPassword"
                                value={values?.newPassword}
                                onChange={handleChange}
                                helperText={
                                  touched.newPassword && errors.newPassword
                                }
                                error={
                                  touched.newPassword &&
                                  Boolean(errors.newPassword)
                                }
                              ></TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              padding={1}
                              sx={{
                                display: "flex",
                                alignItems: {
                                  md: "center",
                                },
                                justifyContent: {
                                  md: "flex-end",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                },
                              }}
                            >
                              <Typography
                                fontWeight={"bold"}
                                sx={{
                                  textTransform: "uppercase",
                                  textAlign: {
                                    md: "right",
                                    sm: "left",
                                    xs: "left",
                                  },
                                }}
                              >
                                confirm password
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} padding={1}>
                              <TextField
                                placeholder="********"
                                fullWidth
                                name="confirmPassword"
                                value={values?.confirmPassword}
                                onChange={handleChange}
                                helperText={
                                  touched.confirmPassword &&
                                  errors.confirmPassword
                                }
                                error={
                                  touched.confirmPassword &&
                                  Boolean(errors.confirmPassword)
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={false} sm={false} md={4}></Grid>
                            <Grid item xs={12} sm={12} md={6} padding={1}>
                              <Box display={"flex"} justifyContent={"center"}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                  type={"submit"}
                                >
                                  CHANGE PASSWORD
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertCloseHandler}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          onClose={alertCloseHandler}
          severity={alertIcon}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
