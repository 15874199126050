//routes import

import { userRoutes } from "app/Services/user/routes/APP";

//pages

import Home from "app/containers/user";
import AboutUs from "app/containers/user/about";
import ContactUs from "app/containers/user/contact";
import Features from "app/containers/user/features";
import ChangeMakers from "app/containers/user/changeMakers";
import RoundUpWork from "app/containers/user/roundUpWork";
// import BoardingQuestions from './pages/boardingQs';
// import GetStarted from './pages/get-started';
// import AllDone from './pages/all-done';
// import Donut from './pages/donut';
import Signup from "app/containers/user/signup";
import Discover from "app/containers/user/discover";
// import Profile from './pages/profile';
// import ProfileEdit from './pages/profileEdit';
import OrgProfile from "app/containers/user/org-profile";
import FeedPage from "app/containers/user/feed";
import OrgCause from "app/containers/user/org-cause";
// import DonationHistory from './pages/donation-history';
import Nfps from "app/containers/user/nfps";
import AuthOrg from "app/containers/user/auth-org";
import Companies from "app/containers/user/companies";
import MyCause from "app/containers/user/my-cause";
import SignIn from "app/containers/user/signIn";

//routes array

const UserPublicRoutesPaths = [
  {
    id: 1,
    path: userRoutes?.root,
    component: <Home />,
    exact: true,
  },
  {
    id: 2,
    path: userRoutes?.aboutus,
    component: <AboutUs />,
    exact: true,
  },
  {
    id: 3,
    path: userRoutes?.contactUs,
    component: <ContactUs />,
    exact: true,
  },
  {
    id: 4,
    path: userRoutes?.features,
    component: <Features />,
    exact: true,
  },
  {
    id: 5,
    path: userRoutes?.changeMakers,
    component: <ChangeMakers />,
    exact: true,
  },
  {
    id: 6,
    path: userRoutes?.roundUpWork,
    component: <RoundUpWork />,
    exact: true,
  },
  {
    id: 11,
    path: userRoutes?.signUp,
    component: <Signup />,
    exact: true,
  },
  {
    id: 12,
    path: userRoutes?.discover,
    component: <Discover />,
    exact: true,
  },
  {
    id: 15,
    path: userRoutes?.orgProfile,
    component: <OrgProfile />,
    exact: true,
  },
  {
    id: 102,
    path: userRoutes?.orgProfileWithId,
    component: <OrgProfile />,
    exact: true,
  },
  {
    id: 16,
    path: userRoutes?.feed,
    component: <FeedPage />,
  },
  {
    id: 17,
    path: userRoutes?.orgCause,
    component: <OrgCause />,
    exact: true,
  },
  {
    id: 19,
    path: userRoutes?.nfps,
    component: <Nfps />,
    exact: true,
  },
  {
    id: 20,
    path: userRoutes?.authOrganisation,
    component: <AuthOrg />,
    exact: true,
  },
  {
    id: 21,
    path: userRoutes?.companies,
    component: <Companies />,
    exact: true,
  },
  {
    id: 22,
    path: userRoutes?.cause,
    component: <MyCause />,
    exact: true,
  },
  // {
  //     id: 100,
  //     path: userRoutes?.orgProfile,
  //     component: <MyCause />,
  //     exact: true,
  // },
  // {
  //   id: 23,
  //   path: userRoutes?.paymentInfo,
  //   component: <PaymentInfo />,
  //   exact: true,
  // },
  // {
  //   id: 24,
  //   path: userRoutes?.paymentRound,
  //   component: <PaymentRound />,
  //   exact: true,
  // },
  // {
  //   id: 24,
  //   path: userRoutes?.currentround,
  //   component: <CurrentRound />,
  //   exact: true,
  // },
  {
    id: 24,
    path: userRoutes?.logIn,
    component: <SignIn />,
    exact: true,
  },
];

export default UserPublicRoutesPaths;
