import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 16.25V13H12.8V9.75H4.8V6.5L0 11.375L4.8 16.25ZM6.4 14.625V21.125H14.4V26L24 21.125V0H6.4V8.125H8V1.625H20.8L14.4 4.875V19.5H8V14.625H6.4Z"
        fill="#F9CC69"
      />
    </svg>
  );
};

export default LogoutIcon;
