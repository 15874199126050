import React from "react";

const Moto = ({ width, height, color, sideColor }) => {
  return (
    <svg
      width={width ? width : "151"}
      height={height ? height : "139"}
      viewBox="0 0 151 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.004 70.1958C105.87 69.1472 105.788 68.018 106.922 66.8889C107.49 66.3242 107.896 65.6789 108.139 64.8723C110.165 63.5012 111.38 61.4847 112.839 59.6295C121.105 48.7405 127.264 36.4803 134.153 24.7849C135.288 22.9297 136.26 21.0745 137.394 19.2194C138.852 16.7996 140.474 14.4605 139.988 11.3954C139.826 10.4276 140.231 9.37899 140.798 8.49174C142.013 6.55592 143.472 4.86209 144.607 2.84562C145.174 1.79705 146.228 1.47442 147.443 1.7164C148.659 1.95836 148.902 3.00694 148.983 4.0555C149.065 5.02341 149.308 5.91065 149.631 6.7979C150.603 9.5403 149.631 12.2827 146.957 13.3313C144.445 14.2992 143.148 16.1544 141.932 18.2515C134.882 30.2697 128.318 42.4492 120.7 54.1446C117.458 59.0649 114.055 63.9044 110.002 68.26C109.192 69.2279 108.382 70.1151 107.004 70.1958Z"
        fill={sideColor ? sideColor : "#230D4D"}
        stroke="#230D4D"
        strokeWidth="0.613532"
      />
      <path
        d="M10.9695 4.37756C11.4556 4.45823 11.5363 4.21625 11.6987 4.05492C12.2665 3.49032 12.6708 2.28043 13.7247 2.84505C14.8593 3.40965 13.9678 4.21625 13.6441 4.94218C13.4011 5.58745 13.6441 5.99074 14.2108 6.4747C15.3454 7.36195 15.1023 8.65248 13.8871 8.73315C9.10588 9.29776 8.70044 13.0081 8.05196 16.4764C6.9174 22.4451 5.86347 28.414 5.62043 34.3827C5.37739 40.2708 6.1065 46.0783 9.34891 51.321C9.91562 52.2083 10.4017 53.2568 9.18652 53.9828C7.97133 54.6281 7.4841 53.4182 7.16044 52.6922C4.32462 46.8041 2.86525 40.5127 3.51373 33.9794C4.16221 27.1234 5.13437 20.348 6.67437 13.734C7.24107 11.5562 7.88954 9.13644 5.45917 7.28128C4.56765 6.55536 5.37739 5.02283 6.75499 5.1035C8.4574 5.26481 8.70044 4.37756 8.78106 3.08702C8.78106 2.68372 8.94347 2.28043 9.10588 1.9578C9.34892 1.39318 9.75321 0.82857 10.4835 0.989891C11.132 1.15121 11.0502 1.79647 11.0502 2.36109C10.8878 3.00636 10.5641 3.73229 10.9695 4.37756Z"
        fill={sideColor ? sideColor : "#230D4D"}
        stroke="#230D4D"
        strokeWidth="0.613532"
      />
      <path
        d="M58.1349 114.639C89.0178 114.639 114.053 89.7214 114.053 58.9843C114.053 28.247 89.0178 3.32959 58.1349 3.32959C27.2519 3.32959 2.2168 28.247 2.2168 58.9843C2.2168 89.7214 27.2519 114.639 58.1349 114.639Z"
        fill={color ? color : "#FF9776"}
      />
      <path
        d="M1.29386 123.877L1.30307 123.862L1.31114 123.847C2.51941 121.601 3.81063 119.434 5.1076 117.254L5.1099 117.251C6.40687 115.032 7.70498 112.792 9.00079 110.554V110.554C10.2978 108.315 11.5936 106.078 12.8906 103.861L12.8998 103.846L12.9078 103.829C13.0564 103.534 13.281 103.16 13.501 102.868L13.5114 102.855L13.5195 102.842C13.7268 102.533 13.9226 102.382 14.0746 102.328C14.1921 102.287 14.3476 102.28 14.5849 102.436L14.5953 102.444L14.6079 102.451C14.8349 102.587 14.8694 102.716 14.866 102.851C14.8602 103.043 14.7611 103.302 14.578 103.594L14.5711 103.605L14.5642 103.616C13.6749 105.186 12.7639 106.757 11.8528 108.33L11.8516 108.331C10.9405 109.902 10.0282 111.477 9.13556 113.051C8.04247 114.945 6.95053 116.818 5.85628 118.693C4.76665 120.561 3.677 122.429 2.58622 124.318C2.51941 124.413 2.45031 124.519 2.38926 124.61C2.35932 124.657 2.33051 124.699 2.30632 124.737C2.21648 124.87 2.14046 124.973 2.05868 125.057C1.93544 125.185 1.78684 125.288 1.52653 125.332C1.17176 125.18 1.05427 124.997 1.01741 124.85C0.969038 124.658 1.00936 124.337 1.29386 123.877Z"
        fill={sideColor ? sideColor : "#230D4D"}
        stroke="#230D4D"
        strokeWidth="0.613532"
      />
      <path
        d="M23.8467 136.672L23.8548 136.658L23.8629 136.643C25.0723 134.397 26.3623 132.229 27.6604 130.05L27.6627 130.047C28.9597 127.827 30.2567 125.588 31.5536 123.35C32.8506 121.111 34.1464 118.874 35.4422 116.657L35.4514 116.641L35.4594 116.625C35.608 116.33 35.8338 115.956 36.0538 115.664L36.063 115.651L36.0722 115.638C36.2796 115.329 36.4754 115.177 36.6263 115.125C36.7449 115.082 36.9004 115.075 37.1365 115.232L37.148 115.24L37.1596 115.247C37.3876 115.383 37.4222 115.512 37.4176 115.647C37.4118 115.839 37.3128 116.098 37.1296 116.39L37.1227 116.401L37.117 116.413C36.2266 117.982 35.3166 119.552 34.4044 121.126L34.4032 121.127C33.4933 122.699 32.5799 124.273 31.6884 125.847C30.5953 127.741 29.5022 129.615 28.408 131.489C27.3184 133.357 26.2287 135.225 25.1379 137.114C25.0711 137.21 25.002 137.314 24.9421 137.406C24.911 137.452 24.8834 137.496 24.858 137.532C24.7694 137.665 24.6922 137.769 24.6104 137.854C24.4872 137.982 24.3386 138.085 24.0783 138.127C23.7235 137.976 23.606 137.793 23.5703 137.647C23.5219 137.454 23.5611 137.133 23.8467 136.672Z"
        fill={sideColor ? sideColor : "#230D4D"}
        stroke="#230D4D"
        strokeWidth="0.613532"
      />
      <path
        d="M15.1702 126.4L15.1714 126.397C16.8715 123.498 18.5739 120.593 20.1957 117.686C20.4537 117.258 20.6414 116.966 20.8499 116.804C20.9398 116.736 21.0146 116.707 21.0883 116.699C21.1632 116.692 21.283 116.701 21.4662 116.784C21.6885 116.905 21.7748 117.011 21.8094 117.077C21.8428 117.139 21.8589 117.218 21.8416 117.341C21.8232 117.472 21.7679 117.63 21.6781 117.826C21.6182 117.955 21.5571 118.073 21.4903 118.201C21.4546 118.271 21.4166 118.343 21.3775 118.422C19.5979 121.521 17.7976 124.621 15.9972 127.722C14.1935 130.828 12.3897 133.933 10.6067 137.04C10.579 137.088 10.5525 137.134 10.5272 137.179C10.3878 137.424 10.2715 137.628 10.1379 137.796C10.0054 137.963 9.9098 138.022 9.83954 138.042C9.35692 138.029 9.17608 137.839 9.10697 137.672C9.01713 137.452 9.02172 137.038 9.33848 136.476C11.2839 133.088 13.2271 129.703 15.1702 126.4Z"
        fill={sideColor ? sideColor : "#230D4D"}
        stroke="#230D4D"
        strokeWidth="0.613532"
      />
      <path
        d="M85.9482 47.3321C88.1194 48.8043 91.0876 48.2405 92.5781 46.0729C94.0686 43.9052 93.5157 40.9544 91.3445 39.4822C89.1733 38.01 86.205 38.5738 84.7145 40.7415C83.2241 42.9091 83.7758 45.8598 85.9482 47.3321Z"
        fill="white"
      />
      <path
        d="M73.6347 25.6687C73.9952 27.3311 73.8754 29.0847 73.1048 30.8137C72.5715 31.9536 72.0705 32.1972 71.0442 31.5017C69.7518 30.7087 68.63 30.0316 69.2519 28.0342C69.8693 26.2848 68.9709 24.6751 67.4332 23.632C65.8943 22.5889 64.2461 22.5556 62.5851 23.5141C61.3285 24.2472 56.8087 24.0177 58.1448 21.5878C59.4809 19.1581 63.9615 18.1093 66.4645 18.7221C69.943 19.4956 72.856 22.3052 73.6347 25.6687Z"
        fill="white"
      />
      <path
        d="M79.5788 55.2769C75.4886 60.2869 66.5504 59.8213 59.6141 54.2371C52.6766 48.6528 50.3683 40.0646 54.4585 35.0547"
        stroke="white"
        strokeWidth="1.22706"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Moto;
