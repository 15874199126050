import React from "react";

//package import

import {
  Avatar,
  IconButton,
  CardMedia,
  Typography,
  Box,
  Button,
  Paper,
  useTheme,
} from "@mui/material";
import {
  MoreHoriz as MoreHorizIcon,
  Favorite as FavoriteIcon,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
//user import

import LikeIcon from "app/svg/user/Like";
import CommentIcon from "app/svg/user/Comment";
import ShareIcon from "app/svg/user/Share";
import { formatCategory, formatDateTime } from "app/common/user/functions";

//component

const CardItem = ({ org }) => {
  //hooks
  const matches = useMediaQuery("(min-width:600px)");

  const theme = useTheme();

  //render

  return (
    <>
      <Paper sx={{ paddingY: 2, paddingX: 0, borderRadius: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "row",
            },
            marginBottom: {
              xs: "30px",
              sm: "30px",
              md: "0px",
              ld: "0px",
            },
            paddingX: {
              xs: "0px",
              sm: "0px",
              md: "24px",
              lg: "24px",
            },
          }}
        >
          <Box display={"flex"}>
            <Box>
              <Avatar
                // sx={{
                //   width: 70,
                //   height: 70,
                // }}
                sx={{
                  width: {
                    xs: "35px",
                    sm: "35px",
                    md: "70px",
                    lg: "70px",
                  },
                  height: {
                    xs: "35px",
                    sm: "35px",
                    md: "70px",
                    lg: "70px",
                  },
                }}
                src={
                  org?.organisation?.organisation_image[0] ||
                  "/images/charityLogo.png"
                }
                alt={"charityLogo"}
              ></Avatar>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              marginLeft={2}
              paddingBottom={2}
            >
              <Typography
                variant="h6"
                color="primary.main"
                fontWeight={"bold"}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "18px",
                    lg: "18px",
                  },
                }}
              >
                {/* Organisation Name */}
                {org?.organisation_name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: {
                    xs: "9px",
                    sm: "9px",
                    md: "14px",
                    lg: "14px",
                  },
                }}
              >
                {/* Cause of the Organisation */}
                {formatCategory(org?.category)}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  fontSize: {
                    xs: "7px",
                    sm: "7px",
                    md: "14px",
                    lg: "14px",
                  },
                }}
              >
                {/* 2 h ago */}
                {formatDateTime(org?.updatedAt)}
              </Typography>
            </Box>
          </Box>
          <Box>
            {/* <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </Box> */}
            {/* <Box display={"flex"} sx={{ paddingLeft: "20px" }}>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    padding: {
                      xs: "5px 10px",
                      sm: "5px 10px",
                      md: "10px 50px",
                      lg: "10px 50px",
                    },
                    fontSize: {
                      xs: "9px",
                      sm: "9px",
                      md: "14px",
                      lg: "14px",
                    },
                  }}
                >
                  Support
                </Button>
              </Box>

              <Box color={theme.palette.primary.main}>
                <IconButton color="inherit">
                  <FavoriteIcon
                    color="inherit"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        sm: "15px",
                        md: "30px",
                        lg: "30px",
                      },
                    }}
                  />
                </IconButton>
              </Box>
            </Box> */}
          </Box>
        </Box>
        <Box paddingBottom={2} paddingX={3}>
          <Typography
            variant="body2"
            color="text.secondary"
            fontWeight={"bold"}
            sx={{ textAlign: "justify" }}
            gutterBottom
          >
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna neque
            tincidunt pharetra ullamcorper et sapien. Arcu maecenas consectetur
            ipsum at egestas. Ac faucibus leo maecenas massa cursus in eu et
            pretium. Volutpat, egestas tellus feugiat eget sit. Ac faucibus leo
            maecenas massa cursus in eu et pretium. Volutpat, egestas tellus
            feugiat eget sit. */}
            {org?.title}
          </Typography>
          {/* new */}
          <Typography
            variant="body2"
            textAlign={"justify"}
            color="text.secondary"
            gutterBottom
          >
            {org?.description}
          </Typography>
          <Typography
            variant="body2"
            textAlign={"justify"}
            fontStyle={"italic"}
            color="text.secondary"
            gutterBottom
          >
            {org?.hashtag}
          </Typography>
        </Box>
        <CardMedia
          component="img"
          // image="/images/rabitVideo.png"
          image={org?.image[0]}
          alt="Paella dish"
          sx={{
            paddingX: "24px",
            maxWidth: "750px",
            maxHeight: "335px",
            objectFit: "contain",
          }}
        />
        {/* <Box
          marginTop={2}
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "flex-start",
            ld: "flex-start",
          }}
          sx={{
            display: { xs: "flex", md: "flex", lg: "flex" },
            paddingX: {
              xs: "0px",
              sm: "0px",
              md: "24px",
              lg: "24px",
            },
          }}
        >
          <Box margin={0.5} backgroundColor={theme.palette.primary.light}>
            <Button
              variant="contained"
              startIcon={<LikeIcon />}
              endIcon={matches && <>133</>}
              sx={{
                paddingY: 1,
                paddingX: 1.5,
                backgroundColor: "inherit",
              }}
            >
              {matches && <>Like</>}
            </Button>
          </Box>
          <Box margin={0.5} backgroundColor={theme.palette.primary.light}>
            <Button
              startIcon={<CommentIcon />}
              sx={{
                paddingY: 1,
                paddingX: 1.5,
                backgroundColor: "inherit",
              }}
              variant="contained"
              endIcon={matches && <>23</>}
            >
              {matches && <>Comment</>}
            </Button>
          </Box>
          <Box margin={0.5} backgroundColor={theme.palette.primary.light}>
            <Button
              startIcon={<ShareIcon />}
              sx={{
                paddingY: 1,
                paddingX: 1.5,
                backgroundColor: "inherit",
              }}
              variant="contained"
              endIcon={matches && <>3</>}
            >
              {matches && <>Share</>}
            </Button>
          </Box>
        </Box> */}
      </Paper>
    </>
  );
};

export default CardItem;
