import React from "react";

//package import

import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { ADMIN_URLS, APP_Urls } from "app/Services/admin/Routes/APP";

import { Nav, NavDropdown } from "react-bootstrap";
// import { MdOutlineArrowDropDown } from "react-icons/md";

//user import

const Navbar = () => {
  //hooks

  const { pathname } = useLocation();
  const navigate = useNavigate();

  //log out handler

  const logoutHandler = () => {
    localStorage.clear();
    navigate(APP_Urls?.login);
  };

  //render

  return (
    <nav className="navbar navbar-expand-lg sticky-top" id="Navbar">
      <a className="navbar-brand" href="/">
        <img src="/images/logo.png" alt="logo" className="logo" />
        <div className="circle"></div>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <i className="fas fa-bars bars-icon-style"></i>
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto left">
          <li
            className={
              pathname === APP_Urls?.admin + ADMIN_URLS?.USER_MANAGEMENT
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              to={APP_Urls?.admin + ADMIN_URLS?.USER_MANAGEMENT}
              className="nav-link"
            >
              USER MANAGEMENT
            </Link>
          </li>
          <li
            className={
              pathname === APP_Urls?.admin + ADMIN_URLS?.ORG_MANAGEMENT
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              to={APP_Urls?.admin + ADMIN_URLS?.ORG_MANAGEMENT}
              className="nav-link"
            >
              ORGANISATION MANAGEMENT
            </Link>
          </li>
          <li
            className={
              pathname === APP_Urls?.admin + ADMIN_URLS?.CONTENT_MANAGEMENT
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              to={APP_Urls?.admin + ADMIN_URLS?.CONTENT_MANAGEMENT}
              className="nav-link"
            >
              CONTENT MANAGEMENT
            </Link>
          </li>
          <li
            className={
              pathname === APP_Urls?.admin + ADMIN_URLS?.DONATION
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              to={APP_Urls?.admin + ADMIN_URLS?.DONATION}
              className="nav-link"
            >
              DONATIONS
            </Link>
          </li>
        </ul>
        {/* <ul className="navbar-nav ml-auto right">
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="right-profile">
                <div className="logout">
                  <img src="/images/mouse.jpg" alt="profile-pic" />
                </div>
                <p>Admin</p>
                <p>
                  <MdOutlineArrowDropDown />
                </p>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <div className="organisation">
                <ul>
                  <li className="btn" onClick={logoutHandler}>
                    <img src="/images/logout.svg" alt="logout" />
                    <p className="pl-3">Log Out</p>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul> */}
        <Nav className="ml-auto right admin__NavDropdown">
          <NavDropdown
            title={
              <div className="right-profile">
                <div className="logout">
                  <img src="/images/mouse.jpg" alt="profile-pic" />
                </div>
                <p>Admin</p>
                <p>
                  <MdOutlineArrowDropDown />
                </p>
              </div>
            }
            id="navbarDropdown"
            alignRight
          >
            <div className="organisation">
              <NavDropdown.Item
                className="btn admin__logout__item"
                onClick={logoutHandler}
              >
                <img src="/images/logout.svg" alt="logout" />
                <p className="pl-3">Log Out</p>
              </NavDropdown.Item>
            </div>
          </NavDropdown>
        </Nav>
      </div>
    </nav>
  );
};

export default Navbar;
